import { AppState } from '@/stores/appStore'
import { IPhoneNumber, PhoneNumber } from './phone'
import { IAddress, Address } from './address'
import { Email, IEmail } from './email'
import { Base, IBaseModel } from './base'
import { ListsEnum } from '../enums'

export interface IContact extends IBaseModel {
  id: number;
  firstName: string;
  lastName: string;
  emailAddresses: Array<IEmail>;
  phoneNumbers: Array<IPhoneNumber>;
  address: IAddress;
  type: number;
}

export class Contact extends Base implements IContact {
  public id = 0
  public firstName = ''
  public lastName = ''
  public emailAddresses = [new Email()]
  public phoneNumbers = [new PhoneNumber()]
  public address = new Address()
  public type = 175

  constructor (props?: Partial<IContact>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'address':
            props[p] = new Address(props[p])
            break
          case 'emailAddresses':
            if (props[p] && (<Array<Email>>props[p]).length) {
              const objs: Array<Email> = <Array<Email>> [];

              (<Array<Email>>props[p]).forEach((e: IEmail, i: number) => {
                e.priority = i
                objs.push(new Email(e))
              })

              props[p] = objs
            }
            break
          case 'phoneNumbers':
            if (props[p] && (<Array<PhoneNumber>>props[p]).length) {
              const objs: Array<PhoneNumber> = [];

              (<Array<PhoneNumber>>props[p]).forEach((p: PhoneNumber, i: number) => {
                p.priority = i
                objs.push(new PhoneNumber(p))
              })

              props[p] = objs
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get fullName () {
    let fullName = this.firstName + ' ' + this.lastName
    if (!fullName.trim().length) {
      fullName = '<Missing Name>'
    }
    return fullName
  }

  public get typeLabel (): string {
    return AppState.listsByName[ListsEnum.CONTACT_TYPES]![this.type]
  }

  public hasAddress () {
    if (!this.address) {
      return false
    }

    if (
      (this.address.street && this.address.street.length) ||
      // @ts-ignore
      (typeof this.address.street2 !== 'undefined' && this.address.street2 && this.address.street2.length) ||
      (this.address.city && this.address.city.length) ||
      // @ts-ignore
      (this.address.state && this.address.state.length) ||
      (this.address.zip && this.address.zip.code && this.address.zip.code.length)
    ) {
      return true
    }

    return false
  }
}
