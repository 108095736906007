
import { Component } from 'vue-property-decorator'
import { Contract } from '@/modules/customers/models/customer/contract'
import EventBus from '@/plugins/eventbus'
import AccountSearchNamebarBase from './AccountSearchBase.vue'

@Component({
  components: {
  }
})
export default class ContractsNamebar extends AccountSearchNamebarBase {
  protected pageIcon = 'contract'
  protected pageTitle = 'Contracts'
  protected actionIcon = 'plus'
  protected actionTitle = 'Create Contract'

  public handleAction () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-contract', componentProps: { contract: new Contract() } })
  }

  public mounted () {

  }
}
