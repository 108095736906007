





















































































import { Component, Mixins, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import EventBus from '@/plugins/eventbus'
import BillingManagementApi from '../../api/billing'
import moment from 'moment'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { AppState } from '@/stores/appStore'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'

@Component({
  components: {
  }
})
export default class MultiQuoteForm extends Mixins(DownloadFile) {
  private fetchingOppAccounts: boolean = false
  private crmCustomerId: number|null = null
  private crmOpportunityId: number|null = null
  private from = moment().subtract(375, 'day')
  private to = moment().subtract(10, 'day')
  private opportunityAccounts: Array<ElectricAccount> = []
  private selectedAccounts: Array<Number> = []
  private fetching: boolean = false
  private noErrors: boolean = true
  private valRules: Rules = Rules
  private errorMessage: string = ''

  private menu = {
    from: false,
    to: false
  }

  private purposes = [
    { text: 'Pricing (Truelight)', value: 0 },
    { text: 'Costs (ISO)', value: 2 }
  ]

  public get formDisabled () {
    return this.fetching || !this.opportunityAccounts?.length
  }

  public get fromLabel () {
    return moment(this.from).format('MMM DD, YYYY')
  }

  public get toLabel () {
    return moment(this.to).format('MMM DD, YYYY')
  }

  public lossFactorList (utilityId: number) {
    return Object.values(AppState.utilities[utilityId].lossFactors).map(r => ({
      text: r.voltage + ': ' + r.lossFactor,
      value: r.id
    })).sort((a, b) => a.text.localeCompare(b.text))
  }

  public rateClasses (utilityId: number) {
    return Object.values(AppState.utilities[utilityId].rateClasses).map(r => ({
      text: r.rateClass + ' -' + r.description,
      value: r.rateClass
    })).sort((a, b) => a.text.localeCompare(b.text))
  }

  public loadOppAccounts (): void {
    if (this.fetchingOppAccounts) {
      return
    }

    this.fetchingOppAccounts = true
    this.opportunityAccounts = []
    this.selectedAccounts = []

    BillingManagementApi.fetchOpportunityAccounts(this.crmCustomerId ?? 0, this.crmOpportunityId ?? 0)
      .then((details: Array<ElectricAccount>) => {
        details.forEach((a: ElectricAccount) => {
          a.annualLoad = Math.round(a.annualLoad)
          this.opportunityAccounts.push(a)

          // select all by default on load
          this.selectedAccounts.push(a.id)
        })
      })
      .catch(err => {
        EventBus.$emit('app-snack', {
          message: err
        })
      })
      .finally(() => {
        this.fetchingOppAccounts = false
      })
  }

  public handleSubmit (): void {
    if (this.fetching) {
      return
    }

    if (!(this.$refs.accountForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.fetching = true
    this.$emit('quote:retrieved', null)

    const reqAccounts = this.opportunityAccounts.filter((o) => this.selectedAccounts.indexOf(o.id) !== undefined)

    BillingManagementApi.fetchMultiQuote(reqAccounts, this.from.format('YYYY-MM-DD'), this.to.format('YYYY-MM-DD'), 1)
      .then((quote) => {
        if (quote.file) {
          this.downloadFile('multi-quote-' + this.crmCustomerId + '-' + this.crmOpportunityId + '.xlsx', quote.file.binaryContents, 'application/xlsx')
        }
        if (quote.exceptions) {
          throw new Error(quote.exceptions.join('<br />'))
        }
      })
      .catch(err => {
        EventBus.$emit('app-snack', {
          message: err
        })
      })
      .finally(() => {
        this.fetching = false
      })
  }

  public mounted () {

  }
}
