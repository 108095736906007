import esalesClient, { checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { CustomerPaymentApplication } from '../models/customer/payment/application'

export class PaymentApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['customers', 'payments']
    this.singular = 'payment'
    this.plural = 'payments'
    this.factory = (...args: any) => new CustomerPayment(...args)
  }

  public async loadAll (customerId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number> | IStringIndexed>>): Promise<Array<CustomerPayment>> {
    return await this.loadAllSpecific(arguments, config) as Array<CustomerPayment>
  }

  public async load (customerId: number, id: number, onlyProps?: Array<string>): Promise<CustomerPayment> {
    return await this.loadSpecific(arguments, onlyProps) as CustomerPayment
  }

  public async save (customerId: number, payment: CustomerPayment, targetInvoiceId?: number) : Promise<CustomerPayment> {
    const data: IStringIndexed = { ...payment }

    const args = Array.from(arguments)
    if (targetInvoiceId) {
      data.invoiceId = targetInvoiceId
    }
    args.pop() // targetInvoiceId is always in the array but as undefined if not set

    return await this.saveSpecific(args, data) as CustomerPayment
  }

  public async delete (payment: CustomerPayment) {
    return await this.deleteSpecific([payment.customerId, payment.id])
  }

  public async applyOpenBalance (payment: CustomerPayment): Promise<CustomerPayment> {
    try {
      const url = this.buildUrl([payment.customerId, payment.id])

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          apply: 1
        }
      })

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async refund (payment: CustomerPayment): Promise<CustomerPayment> {
    try {
      const url = this.buildUrl([payment.customerId, payment.id])

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          refund: 1
        }
      })

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async void (payment: CustomerPayment): Promise<CustomerPayment> {
    try {
      const url = this.buildUrl([payment.customerId, payment.id])

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          void: 1
        }
      })

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async reverseApplication (payment: CustomerPayment, application: CustomerPaymentApplication): Promise<{ payment: CustomerPayment, application: CustomerPaymentApplication, reversal: CustomerPaymentApplication }> {
    try {
      const url = '/customers/' + payment.customerId + '/payments/' + payment.id + '/applications/' + application.id

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          reverse: 1
        }
      })

      if (response.status === 200 && response.data.application) {
        return {
          payment: new CustomerPayment(response.data.payment),
          application: new CustomerPaymentApplication(response.data.application),
          reversal: new CustomerPaymentApplication(response.data.reversal)
        }
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const PaymentsApi = new PaymentApi(esalesClient)
