










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Note } from '@/modules/shared/models/note'
import { ListsEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import Rules from '@/plugins/validations'

@Component
export default class NoteForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true

  @Prop({ default: () => new Note() })
  private note!: Note

  @Prop({ required: true })
  private noteList!: Array<Note>

  @Prop({ required: true })
  private saveMethod!: string

  @Prop({ default: () => { return {} } })
  private saveParams!: object

  private localNote = new Note()

  private noteCategories = mapObjectVuetifySelect(AppState.listsByName[ListsEnum.NOTE_CATEGORIES]!, true)

  @Watch('noteList', { immediate: true, deep: true })
  private noteUpdated (newNotes: Array<Note>, oldNotes: Array<Note>) {
    const match = newNotes.find(n => n.id === this.localNote.id)

    if (match) {
      if (match.updatedAt !== this.localNote.updatedAt) {
        this.errorMessage = 'WARNING: This note has been updated by someone else. And you may overwrite the changes'
      }
    }
  }

  public get rowsByViewSize () {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 5
      case 'sm':
        return 5
      case 'md':
        return 15
      case 'lg':
        return 20
      case 'xl':
        return 25
    }
  }

  public handleSubmit (): void {
    if (this.saving) {
      return
    }

    if (!(this.$refs.noteForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    CustomerState[this.saveMethod]({ ...this.saveParams, note: this.localNote })
      .then((note: Note) => {
        this.$emit('note:updated', note)
        this.handleReset()
      })
      .catch((err: string) => {
        this.errorMessage = err
      })
      .finally(() => {
        this.saving = false
      })
  }

  public handleClose (): void {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localNote = new Note()
  }

  public get mode () {
    return this.localNote.id ? 'Edit' : 'Add'
  }

  public beforeMount () {
    this.localNote = this.note.clone()
    if (!this.localNote.customerId) {
      this.localNote.customerId = CustomerState.customer.id
    }
  }
}
