







import { Component, Vue, Prop } from 'vue-property-decorator'
import ProfileDownload from '../../components/forms/ProfileDownload.vue'
import EpoChannelDownload from '../../components/forms/EpoChannelDownload.vue'

@Component({
  components: {
    'profile-download': ProfileDownload,
    'epo-channel-download': EpoChannelDownload
  }
})
export default class ProfilesPage extends Vue {
  private beforeMount () {

  }
}
