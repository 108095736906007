import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'

export interface IDocumentAttachment extends IStringIndexed {
  id: number;
  documentId: number;
  attachedTo: number;
  field: string;
  operator: string;
  compare: string;
}

export default class DocumentAttachment extends Base implements IDocumentAttachment {
  public id = 0
  public documentId = 0
  public attachedTo = 0
  public field = ''
  public operator = ''
  public compare = ''

  constructor (props: Partial<IDocumentAttachment> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
