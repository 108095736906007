import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse, Method } from 'axios'
import { IStringDictionary, IStringIndexed, INumericDictionary } from '@/modules/shared/types'
import AppUser from '@/modules/auth/models/user'
import DocumentModel from '@/modules/customermanagement/models/document'
import DocumentTemplateModel from '@/modules/customermanagement/models/document/template'
import DocumentAttachmentModel from '@/modules/customermanagement/models/document/attachment'

const baseUrl = 'documents'

export default class DocumentMangaementApi {
  public static async loadAllDocuments (filters?: IStringDictionary<string | Array<string|number>>): Promise<Array<DocumentModel>> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + filtersToQs(filters))

      if (response.status === 200 && response.data.documents) {
        const documents: Array<DocumentModel> = []

        Object.entries(response.data.documents).forEach(([id, i]) => {
          documents.push(new DocumentModel(<Partial<DocumentModel>>i))
        })

        return documents
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadDocument (id: number): Promise<DocumentModel> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + '/' + id.toString())
      if (response.status === 200) {
        return new DocumentModel(response.data.document)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async saveDocument (document: DocumentModel) : Promise<DocumentModel> {
    try {
      let method = 'POST'
      if (document.id) {
        method = 'PUT'
      }

      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl,
        method: <Method> method,
        data: document
      })

      if (response.status === 200 && response.data.document) {
        return new DocumentModel(response.data.document)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadDocumentTemplates (documentId: number): Promise<Array<DocumentTemplateModel>> {
    try {
      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + documentId + '/templates',
        method: 'GET',
        data: {}
      })

      if (response.status === 200 && response.data.templates) {
        const templates: Array<DocumentTemplateModel> = []
        Object.entries(response.data.templates).forEach(([id, i]) => {
          templates.push(new DocumentTemplateModel(<Partial<DocumentTemplateModel>>i))
        })
        return templates
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadDocumentTemplate (documentId: number, templateId: number): Promise<DocumentTemplateModel> {
    try {
      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + documentId + '/templates/' + templateId,
        method: 'GET',
        data: {}
      })

      if (response.status === 200 && response.data.template) {
        return new DocumentTemplateModel(response.data.template as Partial<DocumentTemplateModel>)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async saveDocumentTemplate (documentId: number, template: DocumentTemplateModel) : Promise<DocumentTemplateModel> {
    try {
      let method = 'POST'
      if (template.id) {
        method = 'PUT'
      }

      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + documentId + '/templates/' + template.id,
        method: <Method> method,
        data: template
      })

      if (response.status === 200 && response.data.template) {
        return new DocumentTemplateModel(response.data.template)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadDocumentAttachmentRules (documentId: number): Promise<Array<DocumentAttachmentModel>> {
    try {
      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + documentId + '/attachments',
        method: 'GET',
        data: {}
      })

      if (response.status === 200 && response.data.attachments) {
        const attachments: Array<DocumentAttachmentModel> = []
        Object.entries(response.data.attachments).forEach(([id, i]) => {
          attachments.push(new DocumentAttachmentModel(<Partial<DocumentAttachmentModel>>i))
        })
        return attachments
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadDocumentAttachmentRule (documentId: number, ruleId: number): Promise<DocumentAttachmentModel> {
    try {
      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + documentId + '/attachments/' + ruleId,
        method: 'GET',
        data: {}
      })

      if (response.status === 200 && response.data.template) {
        return new DocumentAttachmentModel(response.data.template as Partial<DocumentAttachmentModel>)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
