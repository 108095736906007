import { Base } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'
import { MAX_RANGE_DATE } from '@/modules/shared/constants'

export interface ISalesUserTaxId extends IStringIndexed {
  id: number;
  salesId: number;
  govId: string;
  hash: string;
  from: string;
  to: string;
}

export default class SalesUserTaxId extends Base implements ISalesUserTaxId {
  public id = 0
  public salesId = 0
  public govId = ''
  public hash = ''
  public from = ''
  public to = MAX_RANGE_DATE

  constructor (props: Partial<ISalesUserTaxId> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'salesId':
            props[p] = parseInt(props[p]?.toString() as string)
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
