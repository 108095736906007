import esalesClient, { checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'

export class BankAccountsApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['customers', 'bankaccounts']
    this.singular = 'bankaccount'
    this.plural = 'bankaccounts'
    this.factory = (...args: any) => new BankAccount(...args)
  }

  public async loadAll (customerId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<BankAccount>> {
    return await this.loadAllSpecific(arguments, config) as Array<BankAccount>
  }

  public async load (customerId: number, id: number, onlyProps?: Array<string>): Promise<BankAccount> {
    return await this.loadSpecific(arguments, onlyProps) as BankAccount
  }

  public async save (customerId: number, bankaccount: BankAccount) : Promise<BankAccount> {
    const data: IStringIndexed = { ...bankaccount }

    return await this.saveSpecific(arguments, data) as BankAccount
  }

  public async delete (bankaccount: BankAccount) {
    return await this.deleteSpecific([bankaccount.customerId, bankaccount.id])
  }

  public async fetchSensitive (bankaccount: BankAccount, businessNeed: string) {
    try {
      const url = this.buildUrl([bankaccount.customerId, bankaccount.id])

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: { includeSensitive: 1, businessNeed: businessNeed }
      })

      if (response.status === 200 && response.data.bankaccount) {
        return {
          accountNumber: response.data.bankaccount.accountNumber,
          routingNumber: response.data.bankaccount.routingNumber
        }
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const BankAccountApi = new BankAccountsApi(esalesClient)
