






















































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import dtExporter from '@/modules/shared/datatablehelper'
import { IStringIndexed } from '@/modules/shared/types'
import DataServicesReportsApi from '@/modules/dataservices/api/reports'

@Component({
  components: {}
})
export default class IdrLogParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true

  private menu = {
    asOf: false
  }

  private asOf = ''
  private resultsSearch = ''
  private records: Array<IStringIndexed> = []
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'Html Code', align: 'left', sortable: true, value: 'html_code' },
    { text: 'Has Usid', align: 'left', sortable: true, value: 'has_USID' },
    { text: 'Account Number', align: 'left', sortable: true, value: 'account_number', exportType: 'text' },
    { text: 'Meter Number', align: 'left', sortable: true, value: 'meter_number' },
    { text: 'Account Id', align: 'left', sortable: true, value: 'account_id', exportType: 'text' },
    { text: 'Business Name', align: 'left', sortable: true, value: 'business_name' },
    { text: 'Idr Log Id', align: 'left', sortable: true, value: 'idr_log_id' },
    { text: 'Transaction Id', align: 'left', sortable: true, value: 'transaction_id' },
    { text: 'Start Date', align: 'left', sortable: true, value: 'start_date' },
    { text: 'End Date', align: 'left', sortable: true, value: 'end_date' },
    { text: 'Quantity', align: 'left', sortable: true, value: 'quantity' },
    { text: 'Sender Duns', align: 'left', sortable: true, value: 'sender_duns' },
    { text: 'Est Updated At', align: 'left', sortable: true, value: 'EST_updated_at' },
    { text: 'Est Created At', align: 'left', sortable: true, value: 'EST_created_at' }
  ]

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true
      this.records = await DataServicesReportsApi.loadIdrReport()
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private exportList () {
    dtExporter(this.headers, this.records, 'IDR API Log Export', 'IDR API Log-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
