























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import { ContractSubmissionsApi } from '@/modules/customermanagement/api/queue/contractsubmissions'
import { ContractSubmission } from '@/modules/customermanagement/models/queue/contractsubmission'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { ContractSubmissionStatuses } from '@/modules/shared/lists'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'

@Component
export default class ContractSubmissionQueue extends Vue {
  @Prop({ default: 0 })
  private itemId!: number

  private selectedItem: ContractSubmission = new ContractSubmission()

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'Name', align: 'left', sortable: true, value: 'data.businessName' },
    { text: 'State', align: 'left', width: 85, sortable: true, value: 'data.address.state' },
    { text: 'Sales Person', align: 'center', width: 130, sortable: true, value: 'data.salesPerson' },
    { text: 'Submitted', align: 'center', width: 120, sortable: true, value: 'submittedAt' },
    { text: 'Analyst', align: 'center', width: 100, sortable: true, value: 'analystName' },
    { text: 'Status', align: 'center', width: 95, sortable: true, value: 'statusLabel' },
    { text: 'Step', align: 'center', width: 80, sortable: true, value: 'step' }
  ]

  private loading = false

  private items: Array<ContractSubmission> = []

  private statusLabels = mapObjectVuetifySelect(new ContractSubmissionStatuses(), true)

  private showFilters = false
  private statusFilters: Array<number> = [0, 1, 10]
  private listSearch: string = ''

  private get filteredItems () {
    return this.items.filter(r => {
      return this.statusFilters.indexOf(r.status) !== -1
    })
  }

  private handleClickRow (item: ContractSubmission) {
    if (!AuthState.user.isAllowed('CUSTOMER_MGMT.QUEUES.CSQ.EDIT')) {
      return
    }
    this.selectedItem = item
    this.$router.push({ name: 'csqitem', params: { itemId: item.id.toString() } }).catch(() => {})
  }

  private handleItemUpdate (item: ContractSubmission) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      Vue.set(this.items, idx, item)
      this.selectedItem = item
    } else {
      this.selectedItem = new ContractSubmission()
    }
  }

  private handleItemCreate (item: ContractSubmission) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx === -1) {
      this.items.push(item)
    } else {
      Vue.set(this.items, idx, item)
    }
  }

  private handleItemFinalize (item: ContractSubmission) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      this.items.splice(idx, 1)
      this.selectedItem = new ContractSubmission()
    }
    this.$router.push({ name: 'contractsubmissionqueue' }).catch(() => {})
  }

  private itemViewedClass (item: ContractSubmission): string {
    if (item.id === this.itemId) {
      return 'blue lighten-3'
    }
    return ''
  }

  private getAnalystName (item: ContractSubmission): string {
    if (!item.userId) {
      return '<Unassigned>'
    }
    if (!AppState.cisUsers[item.userId]) {
      return 'Invalid User ID'
    }

    return AppState.cisUserNames[item.userId]
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  private beforeMount () {
    this.loading = true
    ContractSubmissionsApi.loadAll()
      .then(items => {
        this.items = items
        if (this.itemId) {
          this.selectedItem = this.items.find(i => i.id === this.itemId) || new ContractSubmission()
          if (this.selectedItem.id !== this.itemId) {
            this.$router.push({ name: 'contractsubmissionqueue' })
          }
        }
      })
      .finally(() => {
        this.loading = false
      })

    EventBus.$on('csqitem:update', this.handleItemUpdate)
    EventBus.$on('csqitem:create', this.handleItemCreate)
  }

  private beforeDestroy () {
    EventBus.$off('csqitem:update', this.handleItemUpdate)
    EventBus.$off('csqitem:create', this.handleItemCreate)
  }
}
