



















import { Component } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import NamebarBase from './Base.vue'
import { CustomerState } from '@/modules/customers/store'

@Component({
  components: {
  }
})
export default class FilesNamebar extends NamebarBase {
  protected pageIcon = 'file'
  protected pageTitle = 'Customer Files'
  protected actionIcon = 'import'
  protected actionTitle = 'Upload New File'

  protected menu = [
    { title: 'All Files', shortTitle: 'Fls', icon: 'file', to: { name: 'customerfiles' } },
    { title: 'Tax Exempt Forms', shortTitle: 'TxExForm', icon: 'dollar', to: { name: 'taxexemptforms' } }
  ]

  protected handleAction () {
    EventBus.$emit('open-cust-dialog', { component: 'upload-customer-file', componentProps: { customerId: CustomerState.customer.id }, dialogProps: { width: 'auto' } })
  }

  public mounted () {

  }
}
