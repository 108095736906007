






































import { IStringIndexed } from '@/modules/shared/types'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PaymentUploadDetail from './PaymentUploadDetail.vue'
import { PaymentImportStatusEnum } from '@/modules/shared/enums'

@Component({
  components: {
    'import-detail': PaymentUploadDetail
  }
})
export default class PaymentUploadList extends Vue {
  @Prop({ type: Array, required: true })
  private imports!: Array<IStringIndexed>

  private selectedImports: Array<IStringIndexed> = []

  private tableHeaders: Array<any> = [
    // Get the properties from Import.php and use them here
    { text: 'id', align: 'center', sortable: false, value: 'id' },
    { text: 'fileName', align: 'center', sortable: false, value: 'fileName' },
    { text: 'importDate', align: 'center', sortable: false, value: 'importDate' },
    { text: 'rowCount', align: 'center', sortable: false, value: 'rowCount' },
    { text: 'totalAmount', align: 'center', sortable: false, value: 'totalAmount' },
    { text: 'status', align: 'center', sortable: false, value: 'status' }
  ]

  public rowClasses (item: IStringIndexed) {
    let rowClass = ''

    if (item.voidDate) {
      rowClass += ' text-decoration-line-through'
    }

    return rowClass
  }

  @Watch('imports')
  private onImportsChange () {
    this.selectedImports = []
  }

  public getStatusDescription (status: number) {
    switch (status) {
      case PaymentImportStatusEnum.PENDING:
        return 'Pending'
      case PaymentImportStatusEnum.PROCESSED:
        return 'Processed'
      case PaymentImportStatusEnum.FAILED:
        return 'Failed'
    }
  }

  private handleClickRow (item: IStringIndexed) {
    this.selectedImports.push(item)
  }
}
