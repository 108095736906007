































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { ListsEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { UtilityFieldConfig } from '@/modules/shared/models/utility/fieldconfig'

@Component
export default class ServiceAccountGeneralFormFields extends Vue {
  @PropSync('account', {
    required: true,
    default: () => new ElectricAccount()
  })
  private localAccount!: ElectricAccount

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  @Prop({ default: {} })
  private errors!: IStringIndexed

  @Prop({ default: UtilityFieldConfig.defaultFields })
  private fieldConfig!: IStringIndexed

  @Prop({ default: true })
  private enableTips!: boolean

  private utilitySearch: string = ''

  public getFieldErrors (name: string) {
    return this.errors[name] || []
  }

  public showTipIcon (fieldName: string) {
    if (!this.enableTips || !this.fieldConfig[fieldName] || !this.fieldConfig[fieldName].tip || !this.localAccount.utilityId) {
      return ''
    }

    return '$vuetify.icons.help'
  }

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      utilityId: [Rules.required()],
      accountNumber: [Rules.required()],
      meterNumber: [],
      keyName: [],
      title: []
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p].concat(...this.validations[p])
      }
    }

    return rules
  }

  public get utilityList () {
    const list: Array<{header?: string; divider?: boolean; text?: string; value?: any}> = []

    if (this.localAccount.address.state) {
      list.push({ header: 'State' })
    }

    const byState = []

    for (const id in AppState.utilities) {
      if ((AppState.utilities[id].commodity & this.localAccount.commodity as number) &&
        AppState.utilities[id].state === this.localAccount.address.state &&
        !list.find(i => i.value && i.value.toString() === id)
      ) {
        byState.push({
          text: AppState.utilities[id].displayName,
          value: AppState.utilities[id].id
        })
      }
    }

    byState.sort((a, b) => a.text.localeCompare(b.text))
    list.push(...byState)

    if (this.localAccount.address.state) {
      list.push({ header: 'Others' })
    }

    const others = []
    for (const id in AppState.utilities) {
      if (AppState.utilities[id].commodity & this.localAccount.commodity as number &&
        AppState.utilities[id].state !== this.localAccount.address.state &&
        !list.find(i => i.value === id)
      ) {
        others.push({
          text: AppState.utilities[id].displayName,
          value: AppState.utilities[id].id
        })
      }
    }

    others.sort((a, b) => a.text.localeCompare(b.text))
    list.push(...others)

    return list
  }

  public clearUtilitySearch () {
    this.$nextTick(() => {
      this.utilitySearch = ''
    })
  }

  public mounted () {
  }
}
