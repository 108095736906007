import Customer from '../pages/Customer.vue'
import Overview from '../pages/subpages/Overview.vue'
import CustomerSideNav from '../components/SideNav.vue'
import CustomerBottomNav from '../components/BottomNav.vue'
import CommunicationNamebar from '../components/blocks/namebar/Communication.vue'
import NotesNamebar from '../components/blocks/namebar/Notes.vue'
import InvoicesNamebar from '../components/blocks/namebar/Invoices.vue'
import PaymentsNamebar from '../components/blocks/namebar/Payments.vue'
import ServiceAccountsNamebar from '../components/blocks/namebar/ServiceAccounts.vue'
import ContractsNamebar from '../components/blocks/namebar/Contracts.vue'
import FilesNamebar from '../components/blocks/namebar/Files.vue'
import TabNav from '../components/TabNav.vue'
import Communication from '../pages/subpages/Communication.vue'
import Notes from '../pages/subpages/Notes.vue'
import Invoices from '../pages/subpages/Invoices.vue'
import Payments from '../pages/subpages/Payments.vue'
import Statement from '../pages/subpages/Statement.vue'
import PaymentMethods from '../pages/subpages/PaymentMethods.vue'
import Files from '../pages/subpages/Files.vue'
import TaxExemptForms from '../pages/subpages/TaxExemptForms.vue'
import Contracts from '../pages/subpages/Contracts.vue'
import ContractDetail from '@/modules/customers/components/blocks/ContractDetail.vue'
import ContractAccountDetail from '@/modules/customers/components/blocks/contract/AccountDetail.vue'
import ContractAccounts from '@/modules/customers/components/blocks/contract/Accounts.vue'
import ContractInvoices from '@/modules/customers/components/blocks/contract/Invoices.vue'
import ContractFiles from '@/modules/customers/components/blocks/contract/Files.vue'
import ServiceAccounts from '../pages/subpages/ServiceAccounts.vue'
import ServiceAccountDetail from '@/modules/customers/components/blocks/ServiceAccountDetail.vue'
import CustomerInvoiceDetail from '@/modules/customers/components/blocks/CustomerInvoice.vue'
import CustomerPaymentDetail from '@/modules/customers/components/blocks/CustomerPayment.vue'
import TaxExemptFormDetail from '@/modules/customers/components/blocks/TaxExemptFormDetail.vue'

import { Route } from 'vue-router'

export default [
  {
    name: 'customerbase',
    path: '/customer/:id(\\d+)',
    components: {
      SideNav: CustomerSideNav,
      BottomNav: CustomerBottomNav,
      TabNav: TabNav,
      default: Customer
    },
    props: {
      BottomNamv: false,
      TabNav: false,
      default: (route: Route) => ({
        id: parseInt(route.params.id)
      })
    },
    title: 'View Customer',
    meta: { requiresAuth: 'CUSTOMER.VIEW' },
    children: [
      {
        name: 'customer',
        path: 'overview',
        component: Overview
      },
      {
        name: 'customercommunication',
        path: 'communication',
        components: {
          default: Communication,
          NamebarExtension: CommunicationNamebar
        }
      },
      {
        name: 'customeraccounts',
        path: 'accounts',
        meta: { requiresAuth: 'SERVICEACCOUNTS.VIEW' },
        components: {
          default: ServiceAccounts,
          NamebarExtension: ServiceAccountsNamebar
        },
        children: [
          {
            name: 'customeraccountdetail',
            path: ':accountId(\\d+)',
            components: {
              default: ServiceAccountDetail
            },
            props: {
              default: (route: Route) => ({
                accountId: parseInt(route.params.accountId)
              })
            }
          }
        ]
      },
      {
        name: 'customercontracts',
        path: 'contracts',
        meta: { requiresAuth: 'CUSTOMER.CONTRACTS.VIEW' },
        components: {
          default: Contracts,
          NamebarExtension: ContractsNamebar
        },
        children: [
          {
            name: 'customercontract',
            path: ':contractId(\\d+)',
            components: {
              default: ContractDetail
            },
            props: {
              default: (route: Route) => ({
                contractId: parseInt(route.params.contractId)
              })
            },
            children: [
              {
                name: 'contractdetailinvoices',
                path: 'invoices',
                components: {
                  default: ContractInvoices
                },
                props: {
                  default: (route: Route) => ({
                    contractId: parseInt(route.params.contractId),
                    invoiceId: parseInt(route.params.invoiceId)
                  })
                }
              },
              {
                name: 'contractdetailfiles',
                path: 'files',
                components: {
                  default: ContractFiles
                },
                props: {
                  default: (route: Route) => ({
                    contractId: parseInt(route.params.contractId)
                  })
                }
              },
              {
                name: 'customercontractdetail',
                path: 'accounts',
                components: {
                  default: ContractAccounts
                },
                props: {
                  default: (route: Route) => ({
                    contractId: parseInt(route.params.contractId)
                  })
                },
                children: [
                  {
                    name: 'contractaccountdetail',
                    path: ':accountId(\\d+)',
                    components: {
                      default: ContractAccountDetail
                    },
                    props: {
                      default: (route: Route) => ({
                        contractId: parseInt(route.params.contractId),
                        accountId: parseInt(route.params.accountId)
                      })
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'customernotes',
        path: 'notes',
        meta: { requiresAuth: 'CUSTOMER.NOTES.VIEW' },
        components: {
          default: Notes,
          NamebarExtension: NotesNamebar
        }
      },
      {
        name: 'customerinvoices',
        path: 'invoices',
        meta: { requiresAuth: 'CUSTOMER.INVOICES.SUMMARY.VIEW' },
        components: {
          default: Invoices,
          NamebarExtension: InvoicesNamebar
        },
        props: {
          default: (route: Route) => ({
            invoiceId: parseInt(route.params.invoiceId) ?? 0
          })
        },
        children: [
          {
            name: 'customerinvoicedetail',
            path: ':invoiceId(\\d+)/detail/:detailId(\\d+)?',
            meta: { requiresAuth: 'CUSTOMER.INVOICES.ACCOUNT.VIEW' },
            components: {
              default: CustomerInvoiceDetail
            },
            props: {
              default: (route: Route) => ({
                invoiceId: parseInt(route.params.invoiceId),
                detailId: parseInt(route.params.detailId) ?? 0
              })
            }
          }
        ]
      },
      {
        name: 'customerpayments',
        path: 'payments',
        meta: { requiresAuth: 'CUSTOMER.PAYMENTS.VIEW' },
        components: {
          default: Payments,
          NamebarExtension: PaymentsNamebar
        },
        props: {
          default: (route: Route) => ({
            paymentId: parseInt(route.params.paymentId ?? 0)
          })
        },
        children: [
          {
            name: 'customerpaymentdetail',
            path: ':paymentId(\\d+)',
            components: {
              default: CustomerPaymentDetail
            },
            props: {
              default: (route: Route) => ({
                paymentId: parseInt(route.params.paymentId)
              })
            }
          }
        ]
      },
      {
        name: 'balanceregister',
        path: 'register',
        meta: { requiresAuth: 'CUSTOMER.PAYMENTS.VIEW' },
        components: {
          default: Statement,
          NamebarExtension: PaymentsNamebar
        }
      },
      {
        name: 'customerpaymentmethods',
        path: 'payment-methods',
        meta: { requiresAuth: 'CUSTOMER.PAYMENTMETHODS.VIEW' },
        components: {
          default: PaymentMethods,
          NamebarExtension: PaymentsNamebar
        }
      },
      {
        name: 'customerfiles',
        path: 'files',
        components: {
          default: Files,
          NamebarExtension: FilesNamebar
        }
      },
      {
        name: 'taxexemptforms',
        path: 'tax-exempt-forms',
        components: {
          default: TaxExemptForms,
          NamebarExtension: FilesNamebar
        },
        props: {
          default: (route: Route) => ({
            taxExemptFormId: parseInt(route.params.taxExemptFormId ?? 0)
          })
        },
        children: [
          {
            name: 'taxexemptformdetail',
            path: ':taxExemptFormId(\\d+)',
            components: {
              default: TaxExemptFormDetail
            },
            props: {
              default: (route: Route) => ({
                taxExemptFormId: parseInt(route.params.taxExemptFormId)
              })
            }
          }
        ]
      }
    ]
  }
]
