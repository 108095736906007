import { Base } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'

export interface ISalesUserContract extends IStringIndexed {
  id: number;
  salesId: number;
  contractId: number;
  rate: number;
  effectiveFrom: string;
  effectiveTo: string|null;
  commissionProgramId: number;
}

export default class SalesUserContract extends Base implements ISalesUserContract {
  public id = 0
  public salesId = 0
  public contractId = 0
  public rate = 0.00
  public effectiveFrom = ''
  public effectiveTo: string|null = null
  public commissionProgramId = 1

  constructor (props: Partial<ISalesUserContract> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'salesId':
          case 'contractId':
          case 'commissionProgramId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'rate':
            if (props[p]) {
              props[p] = parseFloat(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
