


















import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import Customer from '../../models/customer'

@Component({
  components: {
  }
})
export default class ServiceAccounts extends Vue {
  private accountSearch = ''
  private selectedItem: number|undefined = -1
  private errorMessage = ''

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  public get accounts () {
    return CustomerState.customer.accounts
  }

  public get utilities () {
    return AppState.utilities
  }

  @Watch('loading', { immediate: true, deep: true })
  private handleCustomerIdChange (newCust: Customer, oldCust: Customer) {
    if (this.loading.accounts === null) {
      CustomerState.LoadAccounts()
        .catch((err) => {
          this.errorMessage = err.message
        })
    }
  }
}
