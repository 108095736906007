





























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { PeriodDetail } from '@/modules/customers/models/account/perioddetail'
import moment from 'moment'

@Component({
  components: {
  }
})
export default class ServiceAccountPeriodDetails extends Vue {
  @Prop({ required: true })
  private account!: IAccount

  private errorMessage = ''

  public get years () {
    const groups: IStringDictionary<IStringDictionary<PeriodDetail>> = {}
    for (const pd of this.account.periodDetails) {
      const period = moment(pd.period)
      if (groups[period.format('YYYY')] === undefined) {
        groups[period.format('YYYY')] = {}
      }
      groups[period.format('YYYY')][period.format('M')] = pd
    }

    return groups
  }

  private getContract (contractId: number) {
    return CustomerState.customer.contracts.find(c => c.id === contractId)
  }

  private getContractDescription (contractId: number) {
    const contract = CustomerState.customer.contracts.find(c => c.id === contractId)

    if (!contract) {
      return 'Belongs to another customer'
    }

    return contract.description
  }

  private getBillGroup (groupId: number) {
    return CustomerState.customer.billGroups.find(g => g.id === groupId)
  }

  private getBillGroupDescription (groupId: number) {
    const billGroup = CustomerState.customer.billGroups.find(g => g.id === groupId)
    if (!billGroup) {
      return 'Belongs to another customer'
    }
    return billGroup.description
  }

  private getInvoice (period: string) {
    const inv = CustomerState.customer.invoices.find(s => s.invoices.find(i => i.accountId === this.account.id && Object.values(i.lineItems).find(l => moment(l.startDate).format('YYYY-MM') === moment(period).format('YYYY-MM'))))
    if (inv) {
      return inv
    }
    return null
  }

  private getDetailInvoice (period: string) {
    const inv = this.getInvoice(period)
    if (inv) {
      return inv.invoices.find(i => Object.values(i.lineItems).find(l => moment(l.startDate).format('YYYY-MM') === moment(period).format('YYYY-MM')))
    }
    return null
  }

  private getRead (period: string) {
    const inv = this.getInvoice(period)
    if (inv) {
      for (const d of inv.invoices) {
        const items = Object.values(d.lineItems).find(l => moment(l.startDate).format('YYYY-MM') === moment(period).format('YYYY-MM'))
        if (items) {
          return items
        }
      }
    }
    return null
  }

  private handleViewBillingGroup (groupId: number) {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-billgroup', componentProps: { billgroup: this.getBillGroup(groupId), readonly: true } })
  }
}
