


















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import EventBus from '@/plugins/eventbus'
import { CommodityEnum } from '@/modules/shared/enums'
import AccountFactory from '@/modules/shared/models/accountfactory'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import Customer from '@/modules/customers/models/customer'
import { Contract } from '@/modules/customers/models/customer/contract'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import SummaryInvoiceList from '../invoice/SummaryList.vue'

@Component({
  components: {
    'summary-list': SummaryInvoiceList
  }
})
export default class ContractInvoices extends Vue {
  @Prop({ required: true })
  private contractId!: number

  @Prop({ required: false })
  private invoiceId!: number

  private accountSearch = ''
  private selectedItem: number|undefined = -1
  private errorMessage = ''

  public handleGoInvoice (item: CustomerInvoice) {
    if (AuthState.user.isAllowed('CUSTOMER.INVOICES.ACCOUNT.VIEW')) {
      this.$router.push({ name: 'customerinvoicedetail', params: { invoiceId: item.id.toString() } })
    }
  }

  public get customer () {
    return CustomerState.customer
  }

  private get contract () {
    if (!this.contractId) {
      this.$router.push({ name: 'customercontracts' }).catch(() => {})
    }
    const contract = CustomerState.customer.contracts.find(a => {
      return a.id === this.contractId
    })

    if (!contract) {
      return new Contract({ customerId: CustomerState.customer.id })
    }
    return contract
  }

  public get loading () {
    return CustomerState.loading
  }

  public get filteredInvoices () {
    return this.customer.invoices.filter(s => s.invoices.find(i => Object.values(i.lineItems).find(l => l.contractId === this.contract.id) !== undefined))
  }

  public async mounted () {
    if (CustomerState.loading.invoices === null) {
      await CustomerState.LoadInvoices()
    }
  }
}
