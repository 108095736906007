
import { Component } from 'vue-property-decorator'
import { Note } from '@/modules/shared/models/note'
import EventBus from '@/plugins/eventbus'
import NamebarBase from './Base.vue'
import { CustomerState } from '@/modules/customers/store'

@Component({
  components: {
  }
})
export default class ServiceAccountsNamebar extends NamebarBase {
  protected pageIcon = 'meter'
  protected pageTitle = 'Service Accounts'
  protected actionIcon = ''
  protected actionTitle = ''

  public mounted () {

  }
}
