





import Landing from '../components/Landing.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    landing: Landing
  }
})
export default class Home extends Vue {
}
