




































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { ListsEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'
import moment from 'moment'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import Customer from '@/modules/customers/models/customer'
import { SearchState } from '../../store'
import { IStringIndexed, IStringDictionary } from '../../../shared/types'
import { Utility } from '../../../shared/models/utility'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class AdvancedSearchForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private showAllFields = false
  private searching = false

  private menu = {
    createdBefore: false,
    createdAfter: false,
    updatedBefore: false,
    updatedAfter: false,
    contractedBefore: false,
    contractedAfter: false
  }

  private filters: IStringIndexed = {
    businessName: {
      term: '',
      startsWith: true,
      endsWith: true,
      width: 12
    },
    accountNumbers: {
      term: '',
      startsWith: false,
      endsWith: true,
      width: 12
    },
    dba: {
      term: '',
      startsWith: true,
      endsWith: true,
      width: 12
    },
    'contacts.firstName': {
      term: '',
      startsWith: false,
      endsWith: true,
      width: 6
    },
    'contacts.lastName': {
      term: '',
      startsWith: false,
      endsWith: true,
      width: 6
    },
    'contacts.emailAddresses': {
      term: '',
      startsWith: true,
      endsWith: true,
      width: 12
    },
    'contacts.phoneNumbers': {
      term: '',
      startsWith: false,
      endsWith: true,
      width: 12
    },
    'serviceAccounts.city': {
      term: '',
      startsWith: false,
      endsWith: false,
      width: 12
    },
    estimatedKwh: {
      term: { min: '', max: '' },
      width: 12
    },
    'address.state': {
      term: [],
      width: 12,
      items: AppState.dividedStateSelect
    },
    utilityId: {
      term: [],
      width: 12,
      items: this.utilityList
    },
    customerType: {
      term: [],
      width: 12,
      items: mapObjectVuetifySelect(Customer.types)
    },
    status: {
      term: [],
      width: 12,
      items: mapObjectVuetifySelect(Customer.statii)
    },
    accountingStatus: {
      term: [],
      width: 12,
      items: mapObjectVuetifySelect(Customer.accountingStatii)
    },
    sic: {
      term: [],
      width: 12,
      items: mapObjectVuetifySelect(AppState.listsByDesc[ListsEnum.SIC]!)
    },
    createdBefore: {
      term: '',
      width: 6
    },
    createdAfter: {
      term: '',
      width: 6
    },
    contractedBefore: {
      term: '',
      width: 6
    },
    contractedAfter: {
      term: '',
      width: 6
    },
    updatedBefore: {
      term: '',
      width: 6
    },
    updatedAfter: {
      term: '',
      width: 6
    }
  }

  private groups: IStringIndexed = {
    Common: ['businessName', 'accountNumbers', 'contacts.firstName', 'contracts.lastName', 'dba'],
    Choices: ['address.state', 'utilityId', 'customerType', 'status', 'accountingStatus', 'sic'],
    Time: ['createdBefore', 'createdAfter', 'updatedBefore', 'updatedAfter', 'contractedBefore', 'contractedAfter'],
    Other: ['contacts.emailAddresses', 'contacts.phoneNumbers', 'serviceAccounts.city', 'estimatedKwh']
  }

  private validateFilters () {
    this.errorMessage = ''
    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.$emit('next', this.filters)
  }

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public get utilityList () {
    const list: Array<{header?: string; divider?: boolean; text?: string; value?: any}> = []
    let currState = ''

    const utilities = Object.values(AppState.utilities).sort((l: Utility, r: Utility) => {
      if (l.state !== r.state) {
        return l.state.localeCompare(r.state)
      }

      return l.displayName.localeCompare(r.displayName)
    })

    for (const utility of utilities) {
      if (utility.state !== currState) {
        list.push({ header: utility.state })
        currState = utility.state
      }

      if (!list.find(i => i.value && i.value.toString() === utility.id)) {
        list.push({
          text: utility.displayName,
          value: utility.id
        })
      }
    }

    return list
  }

  public handleShowAllFields () {
    this.showAllFields = !this.showAllFields
    if (this.showAllFields && this.$vuetify.breakpoint.xs) {
      this.$vuetify.goTo(150, { container: '.v-bottom-sheet' })
    }
  }

  public handleClearSearch () {
    this.setDefaults()
  }

  public async handleSearch () {
    this.errorMessage = ''

    const filters: IStringIndexed = {}

    for (const p in this.filters) {
      let term = this.filters[p].term

      if (typeof term === 'string' || term === null) {
        if (term && (term = term.trim()).length) {
          filters[p] = {
            term: term,
            startsWith: this.filters[p].startsWith,
            endsWith: this.filters[p].endsWith
          }
        }
      } else if (Array.isArray(term)) {
        if (term.length) {
          filters[p] = {
            term: this.filters[p].term,
            startsWith: false,
            endsWith: false
          }
        }
      } else {
        const minMax: IStringDictionary<string> = {}
        if (term.min && term.min.length) {
          minMax.min = term.min
        }
        if (term.max && term.max.length) {
          minMax.max = term.max
        }
        if (Object.keys(minMax).length) {
          filters[p] = {
            term: minMax,
            startsWith: false,
            endsWith: false
          }
        }
      }
    }

    if (!Object.keys(filters).length) {
      this.errorMessage = 'Please enter at least one filter or use the basic search'
      return
    }

    this.searching = true
    try {
      const results = await SearchState.AdvancedSearch({ filters: filters })
      if (results.length) {
        this.$emit('search')
      }
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.searching = false
    }
  }

  @Watch('showAllFields')
  private handleScrollToAdvanced (newVal: boolean, oldVal: boolean) {
    if (newVal) {
      // eslint-disable-next-line
      groupLoop:
      for (const g in this.groups) {
        if (g !== 'Common') {
          for (const p of this.groups[g]) {
            if (this.hasValue(p)) {
              this.$nextTick(() =>
                (this.$refs[p] as Vue).$el.scrollIntoView({ block: 'center' })
              )
            }
          }
        }
      }
    }
  }

  private setDefaults () {
    for (const p in this.filters) {
      if (typeof this.filters[p].term === 'string') {
        this.filters[p].term = ''
      } else if (Array.isArray(this.filters[p].term)) {
        this.filters[p].term = []
      } else {
        this.filters[p].term.min = ''
        this.filters[p].term.max = ''
      }
    }
  }

  private hasValue (inputName: string) {
    if (this.filters[inputName]) {
      if (typeof this.filters[inputName].term === 'string') {
        if (this.filters[inputName].term.length) {
          return true
        }
      } else if (Array.isArray(this.filters[inputName].term)) {
        if (this.filters[inputName].term.length) {
          return true
        }
      } else {
        if (this.filters[inputName].term.min.length || this.filters[inputName].term.max.length) {
          return true
        }
      }
    }
    return false
  }

  private beforeMount () {
    this.errorMessage = ''
    this.setDefaults()

    if (SearchState.searchParam.filters) {
      for (const p in SearchState.searchParam.filters) {
        if (this.filters[p]) {
          this.filters[p].term = SearchState.searchParam.filters[p].term
          this.filters[p].startsWith = SearchState.searchParam.filters[p].startsWith
          this.filters[p].endsWith = SearchState.searchParam.filters[p].endsWith
        }
      }
    }

    // eslint-disable-next-line
    groupLoop:
    for (const g in this.groups) {
      if (g !== 'Common') {
        for (const p of this.groups[g]) {
          if (this.hasValue(p)) {
            this.showAllFields = true
            // eslint-disable-next-line
            break groupLoop
          }
        }
      }
    }
  }
}
