import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse } from 'axios'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { FinanceCommission, FinanceCommissionSummary, IFinanceCommissionSummary } from '../models/commission'
import CommissionPayment from '@/modules/sales/models/salesuser/commissionpayment'

export default class FinanceCommissionsApi {
  public static async loadGcr (filters?: IStringDictionary<any>): Promise<Array<FinanceCommission>> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-commissions' + filtersToQs(filters))
      if (response.status === 200 && response.data.commissions) {
        const commissions = []
        for (const p of response.data.commissions) {
          commissions.push(new FinanceCommission(p))
        }
        return commissions
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadUnpaid (filters: IStringIndexed): Promise<Array<FinanceCommissionSummary>> {
    try {
      filters.paid = 0
      const response: AxiosResponse = await esalesClient.get('finance-commissions' + filtersToQs(filters))
      if (response.status === 200 && response.data.commissions) {
        const commissions = []
        for (const p of response.data.commissions) {
          commissions.push(new FinanceCommission(p))
        }

        return FinanceCommissionSummary.fromList(commissions)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadHistory (filters?: IStringDictionary<any>): Promise<Array<CommissionPayment>> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-commissions-payments' + filtersToQs(filters))
      if (response.status === 200 && response.data.payments) {
        const payments = []
        for (const p of response.data.payments) {
          payments.push(new CommissionPayment(p))
        }
        return payments
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async emailReports (emails: INumericDictionary<string>, filters: IStringIndexed) {
    try {
      const response: AxiosResponse = await esalesClient.put('finance-commissions', {
        email: 1,
        emails: emails,
        filters: filters
      })

      if (response.status === 200 && response.data.results) {
        return response.data.results
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async pay (payPeriod: string, ids: Array<number>): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await esalesClient.post('finance-commissions-payments', {
        payPeriod: payPeriod,
        commissions: ids
      })

      if (response.status === 200 && response.data.payments) {
        return response.data.payments
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
