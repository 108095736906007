import esalesClient, { checkMessageOrThrow } from '@/api/clients/esales'
import { Method, AxiosResponse } from 'axios'
import { IUtility, Utility } from '@/modules/shared/models/utility'
import { UtilityFieldConfig } from '@/modules/shared/models/utility/fieldconfig'
import { IStringDictionary, IStringIndexed } from '../types'

interface IUtilitiyResponse {
  utility: IUtility;
}

interface IMultiUtilityResponse {
  utilities: Array<IUtility>;
}

export default class UtilityApi {
  public static async loadAll (filters?: IStringDictionary<string>): Promise<Array<Utility>> {
    try {
      let query = '?'
      if (filters) {
        Object.entries(filters)
          .forEach(([prop, val], i) => {
            query += (i > 0 ? '&' : '') + prop + '=' + encodeURIComponent(val)
          })
      }

      query += 'additional[]=zoneIds&additional[]=rateClasses&additional[]=validationRules&additional[]=configs&additional[]=settings&additional[]=lossFactors'

      const response: AxiosResponse = await esalesClient.get('utilities' + query)

      if (response.status === 200 && response.data.utilities) {
        const utilities: Array<Utility> = []

        response.data.utilities.forEach((i: IStringIndexed) => {
          if (i.configs) {
            for (const c in i.configs) {
              i.configs[c] = UtilityFieldConfig.fromFormConfig(i.configs[c])
            }
          }
          utilities.push(new Utility(i))
        })

        return utilities
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async load (id: number): Promise<Utility> {
    try {
      const response: AxiosResponse = await esalesClient.get('/utilities' + id.toString())
      if (response.status === 200) {
        return new Utility(response.data.utility)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async save (utility: Utility): Promise<Utility> {
    try {
      let method = 'POST'
      if (utility.id) {
        method = 'PUT'
      }

      const response: AxiosResponse = await esalesClient.request({
        url: '/utilities' + (utility.id ? '/' + utility.id : ''),
        method: <Method> method,
        data: utility
      })

      if (response.status === 200 && response.data.utility) {
        return new Utility((<IUtilitiyResponse> response.data).utility)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async delete (utility: Utility): Promise<Utility> {
    try {
      const method = 'DELETE'

      const response: AxiosResponse = await esalesClient.request({
        url: '/utilities/' + utility.id,
        method: <Method> method,
        data: utility
      })

      if (response.status === 200 && response.data.utility) {
        return new Utility((<IUtilitiyResponse> response.data).utility)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadConfig (utility: Utility): Promise<UtilityFieldConfig> {
    try {
      const response: AxiosResponse = await esalesClient.get('utilities/' + utility.id + '/configs')

      if (response.status === 200 && response.data.configs) {
        return new UtilityFieldConfig(response.data.configs)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
