import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { Method, AxiosResponse } from 'axios'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'

interface IErrorResponse {
  errorMessage: string;
}

export default class InvoiceHourlyApi {
  public static async loadOne (customerId: number, invoiceId: number, accountInvoiceId: number, chargeId: number, filters?: IStringDictionary<string|IStringIndexed>): Promise<IStringDictionary<Array<IStringIndexed>>> {
    try {
      let query = ''
      if (filters) {
        query = filtersToQs(filters)
      }

      query += (query.length ? '&' : '?') + 'accountInvoiceIds[]=' + accountInvoiceId + '&chargeId=' + chargeId

      const response: AxiosResponse = await esalesClient.get(
        'customers/' + customerId + '/invoices/' + invoiceId + '/hourly' + query
      )
      if (response.status === 200 && response.data.hours) {
        return response.data.hours
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadAll (customerId: number, invoiceId: number, filters?: IStringDictionary<string|IStringIndexed>): Promise<IStringDictionary<Array<IStringIndexed>>> {
    try {
      let query = ''
      if (filters) {
        query = filtersToQs(filters)
      }

      query += (query.length ? '&' : '?')

      const response: AxiosResponse = await esalesClient.get(
        'customers/' + customerId + '/invoices/' + invoiceId + '/hourly' + query
      )
      if (response.status === 200 && response.data.hours) {
        return response.data.hours
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
