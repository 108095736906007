






































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import { InvoiceQueuesApi } from '@/modules/billing/api/queue/invoicequeues'
import { UnbilledUsagesApi } from '@/modules/billing/api/queue/unbilledusage'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { BillGroupDeliveryMethodEnum, CustomerInvoiceStatusEnum, InvoiceHoldStatusEnum } from '@/modules/shared/enums'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import dtExporter from '@/modules/shared/datatablehelper'
import moment from 'moment'
import { BillingMeterRead } from '@/modules/billing/models/meterread'
import { MeterRead } from '@/modules/shared/models/account/meterread'

@Component
export default class UnbilledUsageQueue extends Mixins(DownloadFile) {
  @Prop({ default: 0 })
  private itemId!: number

  private selectedItem: MeterRead = new BillingMeterRead()

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'State', align: 'left', sortable: true, value: 'account.address.state' },
    { text: 'Customer', align: 'left', sortable: true, value: 'customer.businessName' },
    { text: 'Account', align: 'left', sortable: true, value: 'account.accountNumber', exportType: 'text' },
    { text: 'Utility', align: 'left', sortable: true, value: 'account.utilityName' },
    { text: 'Start', align: 'center', sortable: true, value: 'startDate' },
    { text: 'End', align: 'center', sortable: true, value: 'endDate' },
    { text: 'KWh', align: 'center', sortable: true, value: 'quantity' },
    { text: 'Received', align: 'center', sortable: true, value: 'received' },
    { text: 'Age', align: 'center', sortable: true, value: 'receivedAge' },
    { text: 'Status', align: 'center', sortable: true, value: 'status' },
    { text: 'Billed Via', align: 'center', sortable: true, value: 'billingSystem' }
  ]

  private loading = false

  private items: Array<BillingMeterRead> = []

  private showFilters = false
  private listSearch: string = ''

  private processing: INumericDictionary<string> = {}

  private get filteredItems () {
    return this.items
  }

  private getItemStatus (item: BillingMeterRead) {
    if (item.lineItemId !== null) {
      return 'In Stage'
    }
    return 'Unstaged'
  }

  private get canEditQueueItem () {
    return AuthState.user.isAllowed('BILLING.QUEUES.INVOICEAPPROVAL.EDIT')
  }

  private canToggleHold (item: CustomerInvoice, toHoldStatus: number) {
    if (toHoldStatus === InvoiceHoldStatusEnum.UNHELD) {
      return this.canEditQueueItem &&
        item.holdStatus === InvoiceHoldStatusEnum.HELD
    } else {
      return this.canEditQueueItem &&
        item.holdStatus !== InvoiceHoldStatusEnum.HELD &&
        item.status < CustomerInvoiceStatusEnum.STATUS_APPROVED
    }
  }

  private handleClickRow (item: BillingMeterRead) {
    this.selectedItem = item
    this.$router.push({ name: 'uuqitem', params: { itemId: item.id.toString() } }).catch(() => {})
  }

  private handleItemUpdate (item: MeterRead) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      Vue.set(this.items, idx, item)
      this.selectedItem = item
    } else {
      this.selectedItem = new MeterRead()
    }
  }

  private handleItemApprove (item: MeterRead) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      this.items.splice(idx, 1)
      this.selectedItem = new MeterRead()
    }
    this.$router.push({ name: 'unbilledusagequeue' }).catch(() => {})
  }

  private async handleFinalize (item: CustomerInvoice, mode: string) {
    this.processing[item.id] = mode
  }

  private exportList () {
    dtExporter(this.headers, this.items, 'Unbilled Reads Export', 'Unbilled-Reads-' + moment().format('YYYYMMDD-HHmm'))
  }

  public async handleHoldUnhold (invoice: CustomerInvoice, holdStatus: number) {
    try {
      Vue.set(this.processing, invoice.id, 'setHoldStatus')

      // invoice.holdStatus = holdStatus
      // const updated = await InvoicesApi.savePartial(invoice, ['holdStatus'])

      // const idx = this.items.findIndex(c => c.id === updated.id)
      // if (idx !== -1) {
      //   this.items[idx] = updated
      // }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processing, invoice.id)
    }
  }

  private itemViewedClass (item: CustomerInvoice): string {
    if (item.holdStatus === InvoiceHoldStatusEnum.HELD) {
      return 'warning lighten-3'
    }

    if (item.id === this.itemId) {
      return 'primary lighten-3'
    }
    return ''
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  private beforeMount () {
    this.loading = true
    UnbilledUsagesApi.loadAll({

    })
      .then(resp => {
        this.items = resp.items
        if (this.itemId) {
          this.selectedItem = this.items.find(i => i.id === this.itemId) || new MeterRead()
          if (this.selectedItem.id !== this.itemId) {
            this.$router.push({ name: 'unbilledusagequeue' })
          }
        }
      })
      .finally(() => { this.loading = false })

    EventBus.$on('uuqitem:update', this.handleItemUpdate)
  }

  private beforeDestroy () {
    EventBus.$off('uuqitem:update', this.handleItemUpdate)
  }
}
