import BillingLandPage from '../pages/Landing.vue'
import InvoiceApprovalQueue from '../pages/subpages/queues/InvoiceApproval.vue'
import SendInvoicesQueue from '../pages/subpages/queues/SendInvoices.vue'
import UnbilledUsageQueue from '../pages/subpages/queues/UnbilledUsage.vue'
import QuoterPage from '../pages/subpages/Quoter.vue'
import ProfilesPage from '../pages/subpages/Profiles.vue'
import IAQItem from '../components/queues/invoice-approval/InvoiceApprovalQueueItem.vue'
import UUQItem from '../components/queues/invoice-approval/UnbilledUsageQueueItem.vue'
import BillingSideNav from '../components/sidenavs/Root.vue'
import { Route } from 'vue-router'

const rootNav = {
  key: 'root'
}

const queuesNav = {
  key: 'queues',
  width: 225
}

export default [
  {
    name: 'billingroot',
    path: '/billing',
    components: {
      SideNav: BillingSideNav,
      default: BillingLandPage
    },
    title: 'Billing Management',
    meta: {
      requiresAuth: 'BILLING.VIEW',
      navs: {
        queues: {
          icon: 'up',
          title: 'Back',
          order: 0
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'billingengine',
    path: '/billing/engine',
    components: {
      default: QuoterPage,
      SideNav: BillingSideNav
    },
    title: 'Quoter',
    meta: {
      requiresAuth: 'BILLING.QUOTER',
      navs: {
        root: {
          icon: 'quote'
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'billingqueues',
    path: '/billing/queues',
    components: {
      default: BillingLandPage,
      SideNav: BillingSideNav
    },
    title: 'Queues',
    meta: {
      requiresAuth: 'BILLING.VIEW',
      navs: {
        root: {
          icon: 'queue'
        }
      }
    },
    props: {
      nav: queuesNav
    }
  },
  {
    name: 'invoiceapprovalqueue',
    path: '/billing/queues/invoice-approval',
    components: {
      default: InvoiceApprovalQueue,
      SideNav: BillingSideNav
    },
    title: 'Approve Invoices',
    meta: {
      requiresAuth: 'BILLING.QUEUES.INVOICEAPPROVAL.VIEW',
      navs: {
        queues: {
          icon: 'complete'
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      }),
      nav: queuesNav
    },
    children: [
      {
        name: 'iaqitem',
        path: ':itemId(\\d+)',
        components: {
          default: IAQItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'sendinvoicesqueue',
    path: '/billing/queues/send-invoices',
    components: {
      default: SendInvoicesQueue,
      SideNav: BillingSideNav
    },
    title: 'Send Invoices',
    meta: {
      requiresAuth: 'BILLING.QUEUES.SENDINVOICES.VIEW',
      navs: {
        queues: {
          icon: 'fulfillmentqueue'
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      }),
      nav: queuesNav
    }
  },
  {
    name: 'unbilledusagequeue',
    path: '/billing/queues/unbilled-usage',
    components: {
      default: UnbilledUsageQueue,
      SideNav: BillingSideNav
    },
    title: 'Unbilled Usage',
    meta: {
      requiresAuth: 'BILLING.QUEUES.UNBILLED.VIEW',
      navs: {
        queues: {
          icon: 'last'
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      }),
      nav: queuesNav
    },
    children: [
      {
        name: 'uuqitem',
        path: ':itemId(\\d+)',
        components: {
          default: UUQItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'profiles',
    path: '/billing/profiles',
    components: {
      default: ProfilesPage,
      SideNav: BillingSideNav
    },
    title: 'Profiles',
    meta: {
      requiresAuth: 'BILLING.QUOTER',
      navs: {
        root: {
          icon: 'chart'
        }
      }
    },
    props: {
      nav: rootNav
    }
  }
]
