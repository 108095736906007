import Vue from 'vue'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store, { priorState } from '@/stores'
import Customer from '../models/customer'
import { ICustomerContact, Contact } from '../models/customer/contact'
import { BankAccount } from '../models/customer/bankaccount'
import CustomerApi from '../api/customer'
import moment from 'moment'
import { ContactsApi } from '../api/contacts'
import { BillGroupsApi } from '../api/billgroups'
import { NotesApi } from '../api/notes'
import { BankAccountApi } from '../api/bankaccounts'
import FilesApi from '../api/files'
import { Note } from '@/modules/customers/models/customer/note'
import { AppState } from '@/stores/appStore'
import MenuItem from '@/modules/shared/menuitem'
import { IFile, File } from '@/modules/shared/models/file'
import AccountsApi from '../api/accounts'
import { ContractsApi } from '../api/contracts'
import { ICustomerContract, Contract } from '../models/customer/contract'
import { InvoicesApi } from '../api/invoices'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { PaymentsApi } from '../api/payments'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { INumericIndexed, IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { SearchState } from '@/modules/search/store'
import { BillGroup, IBillGroup } from '../models/customer/billgroup'
import { CustomerPaymentApplication } from '../models/customer/payment/application'
import { AccountInvoice } from '../models/account/invoice'
import { MeterRead } from '@/modules/shared/models/account/meterread'
import { Transaction } from '@/modules/customers/models/account/transaction'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { TransactionsApi } from '@/modules/customers/api/transactions'
import { TransactionRequestsApi } from '@/modules/customers/api/transactionrequests'
import { CustomerTypes } from '@/modules/shared/lists'
import { TaxExemptForm } from '../models/customer/taxexemptform'
import { TaxExemptFormsApi } from '../api/taxexemptforms'

interface IUtilityGroup {
  id: number;
  name: string;
  state: string;
  commodities: Array<number>;
  accounts: Array<IAccount>;
}

interface ISettings extends IStringIndexed {
  maxTerm: number;
}

export interface ICustomerState extends IStringIndexed {
  customer: Customer;
  loading: INumericIndexed;
  customerCachedAt: string;
  customerCacheExpire: string;
  navItems: Array<MenuItem>;
  settings: ISettings;
}

@Module({ dynamic: true, store, name: 'CustomerState' })
class CustomerStateDef extends VuexModule implements ICustomerState {
  [key: string]: any

  public navItems: Array<MenuItem> = []

  public customer = new Customer()
  public loading: IStringDictionary<boolean|null> = {
    customer: null,
    contacts: null,
    contracts: null,
    billgroups: null,
    notes: null,
    files: null,
    accounts: null,
    banking: null,
    invoices: null,
    payments: null,
    taxexemptforms: null
  }

  public listTotals: IStringDictionary<number|null> = {
    invoices: null
  }

  public maxIds: IStringDictionary<number> = {
    notes: 0,
    files: 0,
    invoices: 0,
    payments: 0
  }

  public settings = {
    maxTerm: 48
  }

  public customerCachedAt = ''
  public customerCacheExpire = ''
  public accountSearchFilter = ''

  constructor (module: any) {
    super(module)

    let priorExpire: moment.Moment
    if (priorState && priorState.CustomerState) {
      for (const p in priorState.CustomerState) {
        switch (p) {
          case 'customer':
            this.customer = new Customer(priorState.CustomerState[p])
            break
          case 'loading':
            for (const l in priorState.CustomerState[p]) {
              this.loading[l] = priorState.CustomerState[p][l]
            }
            break
          case 'listTotals':
            for (const l in priorState.CustomerState[p]) {
              this.listTotals[l] = priorState.CustomerState[p][l]
            }
            break
          case 'customerCachedAt':
            if (!priorState.CustomerState[p]) {
              priorState.CustomerState[p] = moment().subtract('5', 'minute').toISOString()
            }
            this[p] = priorState.CustomerState[p]
            break
          case 'customerCacheExpire':
            priorExpire = (priorState.CustomerState[p] && priorState.CustomerState[p].length) ? moment(priorState.CustomerState[p]) : moment()
            if (moment().isSameOrAfter(priorExpire)) {
              setTimeout(function () { CustomerState.Load({ id: CustomerState.customer.id, ignoreCache: true }) }, 1000)
            }
            this[p] = priorState.CustomerState[p]
            break
          default:
            this[p] = priorState.CustomerState[p]
        }
      }
    }
  }

  @Mutation
  private SET_SETTINGS (settings: ISettings) {
    Vue.set(this, 'settings', settings)
  }

  @Mutation
  private RESET_LOADING (status: boolean|null = null) {
    for (const p in this.loading) {
      this.loading[p] = status
      if (this.listTotals[p]) {
        this.listTotals[p] = null
      }
    }
  }

  @Mutation
  private UPDATE_LOADING (payload: {name: string; status: boolean|null}) {
    this.loading[payload.name] = payload.status
  }

  @Mutation
  private UPDATE_MAXID (payload: {name: string; value: number}) {
    this.maxIds[payload.name] = payload.value
  }

  @Mutation
  private UPDATE_LIST_TOTAL (payload: {name: string; value: number}) {
    this.listTotals[payload.name] = payload.value
  }

  @Mutation
  private SET_ACTIVE_CUSTOMER (customer: Customer) {
    Vue.set(this, 'customer', customer)

    const duration = moment.duration(5, 'minute')
    this.customerCachedAt = moment().toISOString()
    this.customerCacheExpire = moment().add(duration).toISOString()
  }

  @Mutation
  private SET_CUSTOMER_CONTACTS (contacts: Array<ICustomerContact>) {
    Vue.set(this.customer, 'contacts', contacts)
  }

  @Mutation
  private SET_CUSTOMER_BILLGROUPS (billgroups: Array<IBillGroup>) {
    Vue.set(this.customer, 'billGroups', billgroups)
  }

  @Mutation
  private SET_CUSTOMER_CONTRACTS (contracts: Array<ICustomerContract>) {
    Vue.set(this.customer, 'contracts', contracts)
  }

  @Mutation
  private SET_CUSTOMER_NOTES (notes: Array<Note>) {
    Vue.set(this.customer, 'notes', notes)
  }

  @Mutation
  private SET_CUSTOMER_INVOICES (invoices: Array<CustomerInvoice>) {
    Vue.set(this.customer, 'invoices', invoices)
  }

  @Mutation
  private SET_CUSTOMER_PAYMENTS (payments: Array<CustomerPayment>) {
    Vue.set(this.customer, 'payments', payments)
  }

  @Mutation
  private SET_CUSTOMER_BANKACCOUNTS (bankaccounts: Array<BankAccount>) {
    Vue.set(this.customer, 'bankaccounts', bankaccounts)
  }

  @Mutation
  private ADD_CUSTOMER_NOTES (notes: Array<Note>) {
    Vue.set(this.customer, 'notes', this.customer.notes.concat(notes))
  }

  @Mutation
  private ADD_CUSTOMER_INVOICES (invoices: Array<CustomerInvoice>) {
    const newInvs = invoices.filter(i => this.customer.invoices.find(ci => ci.id === i.id) === undefined)
    Vue.set(this.customer, 'invoices', this.customer.invoices.concat(newInvs))
  }

  @Mutation
  private SET_CUSTOMER_FILES (files: Array<IFile>) {
    Vue.set(this.customer, 'files', files)
  }

  @Mutation
  private SET_CUSTOMER_TAXEXEMPTFORMS (taxExemptForms: Array<TaxExemptForm>) {
    Vue.set(this.customer, 'taxExemptForms', taxExemptForms)
  }

  @Mutation
  private SET_CUSTOMER_ACCOUNTS (accounts: Array<IAccount>) {
    Vue.set(this.customer, 'accounts', accounts)
  }

  @Mutation
  private ADD_CUSTOMER_NOTE (note: Note) {
    if (this.customer.id !== note.customerId) {
      return
    }
    this.customer.notes.unshift(note)
  }

  @Mutation
  private UPDATE_CUSTOMER_NOTE (note: Note) {
    if (this.customer.id !== note.customerId) {
      return
    }
    const index = this.customer.notes.findIndex(n => n.id === note.id)
    Vue.set(this.customer.notes, index, note)
  }

  @Mutation
  private DELETE_CUSTOMER_NOTE (note: Note) {
    const index = this.customer.notes.findIndex(n => n.id === note.id)
    this.customer.notes.splice(index, 1)
  }

  @Mutation
  private UPDATE_CUSTOMER_INVOICE (invoice: CustomerInvoice) {
    if (this.customer.id !== invoice.customerId) {
      return
    }
    const index = this.customer.invoices.findIndex(i => i.id === invoice.id)
    Vue.set(this.customer.invoices, index, invoice)
  }

  @Mutation
  private ADD_CUSTOMER_PAYMENT (payment: CustomerPayment) {
    if (this.customer.id !== payment.customerId) {
      return
    }
    this.customer.payments.unshift(payment)
  }

  @Mutation
  private UPDATE_CUSTOMER_PAYMENT (payment: CustomerPayment) {
    if (this.customer.id !== payment.customerId) {
      return
    }
    const index = this.customer.payments.findIndex(p => p.id === payment.id)
    Vue.set(this.customer.payments, index, payment)
  }

  @Mutation
  private DELETE_CUSTOMER_PAYMENT (payment: CustomerPayment) {
    const index = this.customer.payments.findIndex(n => n.id === payment.id)
    this.customer.payments.splice(index, 1)
  }

  @Mutation
  private ADD_CUSTOMER_PAYMENT_APPLICATION (application: CustomerPaymentApplication) {
    const pmtIndex = this.customer.payments.findIndex(p => p.id === application.paymentId)
    if (pmtIndex === -1) {
      return
    }

    if (this.customer.id !== this.customer.payments[pmtIndex].customerId) {
      return
    }
    this.customer.payments[pmtIndex].applications.push(application)
  }

  @Mutation
  private ADD_CUSTOMER_CONTACT (contact: Contact) {
    if (this.customer.id !== contact.customerId) {
      return
    }
    this.customer.contacts.unshift(contact)
  }

  @Mutation
  private UPDATE_CUSTOMER_CONTACT (contact: Contact) {
    if (this.customer.id !== contact.customerId) {
      return
    }
    const index = this.customer.contacts.findIndex(c => c.id === contact.id)
    Vue.set(this.customer.contacts, index, contact)
  }

  @Mutation
  private DELETE_CUSTOMER_CONTACT (contact: Contact) {
    const index = this.customer.contacts.findIndex(c => c.id === contact.id)
    this.customer.contacts.splice(index, 1)
  }

  @Mutation
  private ADD_CUSTOMER_BILLGROUP (billgroup: BillGroup) {
    if (this.customer.id !== billgroup.customerId) {
      return
    }
    this.customer.billGroups.unshift(billgroup)
  }

  @Mutation
  private UPDATE_CUSTOMER_BILLGROUP (billgroup: BillGroup) {
    if (this.customer.id !== billgroup.customerId) {
      return
    }
    const index = this.customer.billGroups.findIndex(g => g.id === billgroup.id)
    Vue.set(this.customer.billGroups, index, billgroup)
  }

  @Mutation
  private DELETE_CUSTOMER_BILLGROUP (billgroup: BillGroup) {
    const index = this.customer.billGroups.findIndex(g => g.id === billgroup.id)
    this.customer.billGroups.splice(index, 1)
  }

  @Mutation
  private ADD_CUSTOMER_CONTRACT (contract: Contract) {
    if (this.customer.id !== contract.customerId) {
      return
    }
    this.customer.contracts.unshift(contract)
  }

  @Mutation
  private UPDATE_CUSTOMER_CONTRACT (contract: Contract) {
    if (this.customer.id !== contract.customerId) {
      return
    }
    const index = this.customer.contracts.findIndex(g => g.id === contract.id)
    Vue.set(this.customer.contracts, index, contract)
  }

  @Mutation
  private DELETE_CUSTOMER_CONTRACT (contract: Contract) {
    const index = this.customer.contracts.findIndex(g => g.id === contract.id)
    this.customer.contracts.splice(index, 1)
  }

  @Mutation
  private ADDUPDATE_CUSTOMER_BANKACCOUNT (account: BankAccount) {
    if (this.customer.id !== account.customerId) {
      return
    }

    const index = this.customer.bankaccounts.findIndex(c => c.id === account.id)

    if (index === -1) {
      this.customer.bankaccounts.unshift(account)
    } else {
      Vue.set(this.customer.bankaccounts, index, account)
    }
  }

  @Mutation
  private UPDATE_CUSTOMER_BANKACCOUNT (account: BankAccount) {
    if (this.customer.id !== account.customerId) {
      return
    }
    const index = this.customer.bankaccounts.findIndex(c => c.id === account.id)
    Vue.set(this.customer.bankaccounts, index, account)
  }

  @Mutation
  private DELETE_CUSTOMER_BANKACCOUNT (account: BankAccount) {
    const index = this.customer.bankaccounts.findIndex(c => c.id === account.id)
    this.customer.bankaccounts.splice(index, 1)
  }

  @Mutation
  private ADD_CUSTOMER_FILE (file: File) {
    this.customer.files.unshift(file)
  }

  @Mutation
  private ADD_CUSTOMER_ACCOUNT (account: IAccount) {
    this.customer.accounts.push(account)
  }

  @Mutation
  private UPDATE_CUSTOMER_ACCOUNT (account: IAccount) {
    const index = this.customer.accounts.findIndex(c => c.id === account.id)
    Vue.set(this.customer.accounts, index, account)
  }

  @Mutation
  private DELETE_CUSTOMER_ACCOUNT (account: IAccount) {
    const index = this.customer.accounts.findIndex(c => c.id === account.id)
    this.customer.accounts.splice(index, 1)
  }

  @Mutation
  private UPDATE_CUSTOMER_ACCOUNT_PROPERTY (payload: { account: IAccount; propertyName: string; value: any }) {
    const { account, propertyName, value } = payload

    const parts = propertyName.split('.')
    const last: any = parts.pop()
    let ref: any = account
    for (const n of parts) {
      ref = ref[n]
    }
    Vue.set(ref, last, value)
  }

  @Mutation
  private SET_ACCOUNT_SEARCH (filterText: string) {
    this.accountSearchFilter = filterText === null ? '' : filterText
  }

  @Action
  public SetSettings (settings: ISettings) {
    this.SET_SETTINGS(settings)
  }

  @Action({ rawError: true })
  public async Load (payload: { id: number, ignoreCache?: boolean }) {
    const id = payload.id
    const ignoreCache = payload.ignoreCache || false

    if (id !== this.customer.id || moment().isSameOrAfter(this.customerCacheExpire) || ignoreCache === true) {
      // Prepopulate some data if we have it
      const searchRes = SearchState.results.find(s => s.id === id)
      let model = new Customer({ id: id })
      if (searchRes) {
        model = Customer.fromSearchResult(searchRes)
      }

      if (id !== this.customer.id) {
        this.SET_ACTIVE_CUSTOMER(model)
      }

      this.RESET_LOADING(null)
      this.UPDATE_LOADING({ name: 'customer', status: true })

      const customer = await CustomerApi.load(id)
      customer.notes = this.customer.notes
      customer.accounts = this.customer.accounts
      this.SET_ACTIVE_CUSTOMER(customer)
      if (customer.contacts) {
        this.UPDATE_LOADING({ name: 'contacts', status: false })
      }
      if (customer.contracts) {
        this.UPDATE_LOADING({ name: 'contracts', status: false })
      }
      this.UPDATE_LOADING({ name: 'customer', status: false })
    }
  }

  @Action({ rawError: true })
  public async Update (customer: Customer) {
    this.SET_ACTIVE_CUSTOMER(customer)
  }

  @Action({ rawError: true })
  public async LoadNotes (ignoreCache = false, loadMore = false) {
    if (ignoreCache || this.loading.notes === null || loadMore === true) {
      // this.SET_CUSTOMER_NOTES([])
      this.UPDATE_LOADING({ name: 'notes', status: true })
      const notes = await NotesApi.loadAll(this.customer.id, loadMore ? { filters: { startingAt: this.maxIds.notes.toString() } } : undefined)
      if (!loadMore) {
        this.SET_CUSTOMER_NOTES(notes)
        this.UPDATE_MAXID({ name: 'notes', value: 0 })
      } else {
        this.ADD_CUSTOMER_NOTES(notes)
      }
      let maxId = this.maxIds.notes
      for (const n of this.customer.notes) {
        if (n.id > maxId) {
          maxId = n.id
        }
      }
      this.UPDATE_MAXID({ name: 'notes', value: maxId })
    }
    this.UPDATE_LOADING({ name: 'notes', status: false })
  }

  @Action({ rawError: true })
  public async SaveNote (payload: { note: Note }) {
    const isNew = !(payload.note.id > 0)

    const note = await NotesApi.save(payload.note.customerId, payload.note)
    if (isNew) {
      this.ADD_CUSTOMER_NOTE(note)
    } else {
      this.UPDATE_CUSTOMER_NOTE(note)
    }

    return note
  }

  @Action({ rawError: true })
  public async DeleteNote (note: Note) {
    await NotesApi.delete(note)
    this.DELETE_CUSTOMER_NOTE(note)
  }

  @Action({ rawError: true })
  public async LoadContacts (ignoreCache = false) {
    if (ignoreCache || this.loading.contacts === null) {
      // this.SET_CUSTOMER_CONTACTS([])
      this.UPDATE_LOADING({ name: 'contacts', status: true })
      const contacts = await ContactsApi.loadAll(this.customer.id)
      this.SET_CUSTOMER_CONTACTS(contacts)
    }
    this.UPDATE_LOADING({ name: 'contacts', status: false })
  }

  @Action({ rawError: true })
  public async SaveContact (saveContact: Contact) {
    const isNew = !(saveContact.id > 0)

    const contact = await ContactsApi.save(saveContact.customerId, saveContact)
    if (isNew) {
      this.ADD_CUSTOMER_CONTACT(contact)
    } else {
      this.UPDATE_CUSTOMER_CONTACT(contact)
    }

    return contact
  }

  @Action({ rawError: true })
  public async DeleteContact (contact: Contact) {
    await ContactsApi.delete(contact)
    this.DELETE_CUSTOMER_CONTACT(contact)
  }

  @Action({ rawError: true })
  public async LoadBillGroups (ignoreCache = false) {
    if (ignoreCache || this.loading.billgroups === null) {
      this.UPDATE_LOADING({ name: 'billgroups', status: true })
      const billgroups = await BillGroupsApi.loadAll(this.customer.id)
      this.SET_CUSTOMER_BILLGROUPS(billgroups)
    }
    this.UPDATE_LOADING({ name: 'billgroups', status: false })
  }

  @Action({ rawError: true })
  public async SaveBillGroup (saveBillGroup: BillGroup) {
    const isNew = !(saveBillGroup.id > 0)

    const billgroup = await BillGroupsApi.save(saveBillGroup.customerId, saveBillGroup)
    if (isNew) {
      this.ADD_CUSTOMER_BILLGROUP(billgroup)
    } else {
      this.UPDATE_CUSTOMER_BILLGROUP(billgroup)
    }

    return billgroup
  }

  @Action({ rawError: true })
  public async DeleteBillGroup (billgroup: BillGroup) {
    await BillGroupsApi.delete(billgroup)
    this.DELETE_CUSTOMER_BILLGROUP(billgroup)
  }

  @Action({ rawError: true })
  public async LoadContracts (ignoreCache = false) {
    if (ignoreCache || this.loading.contracts === null) {
      this.UPDATE_LOADING({ name: 'contracts', status: true })
      const contracts = await ContractsApi.loadAll(this.customer.id)
      this.SET_CUSTOMER_CONTRACTS(contracts)
    }
    this.UPDATE_LOADING({ name: 'contracts', status: false })
  }

  @Action({ rawError: true })
  public async SaveContract (saveContract: Contract) {
    const isNew = !(saveContract.id > 0)

    const contract = await ContractsApi.save(saveContract.customerId, saveContract)
    if (isNew) {
      this.ADD_CUSTOMER_CONTRACT(contract)
    } else {
      this.UPDATE_CUSTOMER_CONTRACT(contract)
    }

    return contract
  }

  @Action({ rawError: true })
  public async DeleteContract (contract: Contract) {
    await ContractsApi.delete(contract)
    this.DELETE_CUSTOMER_CONTRACT(contract)
  }

  @Action({ rawError: true })
  public async LoadBankAccounts (ignoreCache = false) {
    if (ignoreCache || this.loading.banking === null) {
      this.UPDATE_LOADING({ name: 'banking', status: true })
      const accounts = await BankAccountApi.loadAll(this.customer.id)
      this.SET_CUSTOMER_BANKACCOUNTS(accounts)
    }
    this.UPDATE_LOADING({ name: 'banking', status: false })
  }

  @Action({ rawError: true })
  public async SaveBankAccount (saveAccount: BankAccount) {
    const account = await BankAccountApi.save(saveAccount.customerId, saveAccount)
    this.ADDUPDATE_CUSTOMER_BANKACCOUNT(account)

    return account
  }

  @Action({ rawError: true })
  public async DeleteBankAccount (account: BankAccount) {
    await BankAccountApi.delete(account)
    this.DELETE_CUSTOMER_BANKACCOUNT(account)
  }

  @Action({ rawError: true })
  public async UpdateBankAccountInMemory (saveAccount: BankAccount) {
    this.ADDUPDATE_CUSTOMER_BANKACCOUNT(saveAccount)
    return saveAccount
  }

  @Action({ rawError: true })
  public async LoadInvoices (payload: { ignoreCache?: boolean, loadMore?: boolean } = { ignoreCache: false, loadMore: false }) {
    let resp: number|null = null
    const { ignoreCache, loadMore } = payload

    if (ignoreCache || this.loading.invoices === null || loadMore === true) {
      this.UPDATE_LOADING({ name: 'invoices', status: true })

      const invoices = await InvoicesApi.loadAll(this.customer.id, loadMore ? { filters: { startingAt: moment.unix(this.maxIds.invoices).format('YYYY-MM-DD') } } : undefined)
      const invCnt = this.customer.invoices.length
      if (!loadMore) {
        this.SET_CUSTOMER_INVOICES(invoices.items)
        this.UPDATE_LIST_TOTAL({ name: 'invoices', value: invoices.total })
        this.UPDATE_MAXID({ name: 'invoices', value: 9999999999 })
      } else {
        this.ADD_CUSTOMER_INVOICES(invoices.items)
      }

      if (this.customer.invoices) {
        resp = this.customer.invoices.length - invCnt
      } else {
        resp = invCnt
      }

      let maxId = this.maxIds.invoices
      for (const n of this.customer.invoices) {
        const invDate = moment(n.invoiceDate).unix()
        if (maxId > invDate) {
          maxId = invDate
        }
      }
      this.UPDATE_MAXID({ name: 'invoices', value: maxId })
    }
    this.UPDATE_LOADING({ name: 'invoices', status: false })
    return resp
  }

  @Action({ rawError: true })
  public async VoidCustomerInvoice (invoice: CustomerInvoice) {
    await InvoicesApi.void(invoice)
    this.UPDATE_CUSTOMER_INVOICE(invoice)
    if (invoice.openBalance !== invoice.grandTotal) {
      this.LoadPayments({ ignoreCache: true })
    }
  }

  @Action({ rawError: true })
  public async VoidAccountInvoice (payload: { invoice: CustomerInvoice, accountInvoice: AccountInvoice }) {
    const resp = await InvoicesApi.voidAccount(payload.invoice, payload.accountInvoice)
    this.UPDATE_CUSTOMER_INVOICE(resp.customerInvoice)
    if (payload.accountInvoice.openBalance !== payload.accountInvoice.invoiceTotal) {
      this.LoadPayments({ ignoreCache: true })
    }
  }

  @Action({ rawError: true })
  public async DisputeAccountInvoice (payload: { invoice: CustomerInvoice, accountInvoice: AccountInvoice }) {
    const resp = await InvoicesApi.dispute(payload.invoice, payload.accountInvoice, 1)
    this.UPDATE_CUSTOMER_INVOICE(resp.customerInvoice)
  }

  @Action({ rawError: true })
  public async CancelDisputeAccountInvoice (payload: { invoice: CustomerInvoice, accountInvoice: AccountInvoice }) {
    const resp = await InvoicesApi.dispute(payload.invoice, payload.accountInvoice, 0)
    this.UPDATE_CUSTOMER_INVOICE(resp.customerInvoice)
  }

  @Action({ rawError: true })
  public async LoadPayments (payload: { ignoreCache?: boolean, loadMore?: boolean } = { ignoreCache: false, loadMore: false }) {
    const { ignoreCache, loadMore } = payload

    if (ignoreCache || this.loading.payments === null || loadMore === true) {
      this.UPDATE_LOADING({ name: 'payments', status: true })
      const payments = await PaymentsApi.loadAll(this.customer.id, loadMore ? { filters: { startingAt: this.maxIds.payments.toString() } } : undefined)
      if (!loadMore) {
        this.SET_CUSTOMER_PAYMENTS(payments)
        this.UPDATE_MAXID({ name: 'payments', value: 0 })
      } else {
        this.ADD_CUSTOMER_PAYMENTS(payments)
      }

      let maxId = this.maxIds.payments
      for (const n of this.customer.payments) {
        if (n.id > maxId) {
          maxId = n.id
        }
      }
      this.UPDATE_MAXID({ name: 'payments', value: maxId })
    }
    this.UPDATE_LOADING({ name: 'payments', status: false })
  }

  @Action({ rawError: true })
  public async SavePayment (payload: { payment: CustomerPayment, targetInvoiceId?: number }) {
    const isNew = !(payload.payment.id > 0)

    const newPmt = await PaymentsApi.save(payload.payment.customerId, payload.payment, payload.targetInvoiceId)
    if (isNew) {
      this.ADD_CUSTOMER_PAYMENT(newPmt)
    } else {
      this.UPDATE_CUSTOMER_PAYMENT(newPmt)
    }

    this.LoadInvoices({ ignoreCache: true })

    return newPmt
  }

  @Action({ rawError: true })
  public async ApplyPayment (payment: CustomerPayment) {
    const pmt = await PaymentsApi.applyOpenBalance(payment)
    this.UPDATE_CUSTOMER_PAYMENT(pmt)
    this.LoadInvoices({ ignoreCache: true })
  }

  @Action({ rawError: true })
  public async RefundPayment (payment: CustomerPayment) {
    const pmt = await PaymentsApi.refund(payment)
    this.UPDATE_CUSTOMER_PAYMENT(pmt)
  }

  @Action({ rawError: true })
  public async VoidPayment (payment: CustomerPayment) {
    const pmt = await PaymentsApi.void(payment)
    this.UPDATE_CUSTOMER_PAYMENT(pmt)
    this.LoadInvoices({ ignoreCache: true })
  }

  @Action({ rawError: true })
  public async ReversePaymentApplication (payload: { payment: CustomerPayment, application: CustomerPaymentApplication }) {
    const resp = await PaymentsApi.reverseApplication(payload.payment, payload.application)
    this.UPDATE_CUSTOMER_PAYMENT(resp.payment)
    if (payload.application.invoiceId) {
      this.LoadInvoices({ ignoreCache: true })
    }
  }

  @Action({ rawError: true })
  public async GenerateCredits (invoice: CustomerInvoice) {
    const resp = await InvoicesApi.generateCredits(invoice)
    this.UPDATE_CUSTOMER_INVOICE(resp.invoice)
    this.LoadInvoices({ ignoreCache: true })
    this.LoadPayments({ ignoreCache: true })
  }

  @Action({ rawError: true })
  public async LoadFiles (ignoreCache = false) {
    if (ignoreCache || this.loading.files === null) {
      // this.SET_CUSTOMER_FILES([])
      this.UPDATE_LOADING({ name: 'files', status: true })
      const files = await FilesApi.loadAll(this.customer.id)
      this.SET_CUSTOMER_FILES(files)
      this.UPDATE_LOADING({ name: 'files', status: false })
    }
  }

  @Action({ rawError: true })
  public async AddFile (file: File) {
    this.ADD_CUSTOMER_FILE(file)
  }

  @Action({ rawError: true })
  public async LoadTaxExemptForms (ignoreCache = false) {
    if (ignoreCache || this.loading.taxexemptforms === null) {
      this.UPDATE_LOADING({ name: 'taxexemptforms', status: true })
      const items = await TaxExemptFormsApi.loadAll(this.customer.id)
      this.SET_CUSTOMER_TAXEXEMPTFORMS(items)
      this.UPDATE_LOADING({ name: 'taxexemptforms', status: false })
    }
  }

  @Action({ rawError: true })
  public async LoadAccounts (ignoreCache = false) {
    if (ignoreCache || this.loading.accounts === null) {
      if (!this.customer.accountIds || !this.customer.accountIds.length) {
        return []
      }
      // this.SET_CUSTOMER_ACCOUNTS([])
      this.UPDATE_LOADING({ name: 'accounts', status: true })
      const accounts = await AccountsApi.loadAll({
        id: { IN: this.customer.accountIds }
      })
      this.SET_CUSTOMER_ACCOUNTS(accounts)
      this.UPDATE_LOADING({ name: 'accounts', status: false })
    }
    return this.customer.accounts
  }

  @Action({ rawError: true })
  public async SaveAccount (payload: { customerId: number, customerType: keyof CustomerTypes, saveAccount: IAccount }) {
    return AccountsApi.save(payload.customerId, payload.saveAccount)
      .then((result: { account: IAccount; warnings: Array<string> }) => {
        if (this.customer.accounts.some(i => i.id === result.account.id) === false) {
          if (payload.customerId === this.customer.id) {
            this.ADD_CUSTOMER_ACCOUNT(result.account)
          }
        } else {
          this.UPDATE_CUSTOMER_ACCOUNT(result.account)
        }

        return result
      })
  }

  @Action({ rawError: true })
  public async LoadSummaryReads (account: IAccount) {
    const reads: Array<MeterRead> = await AccountsApi.loadSummaryReads(account.id)
    this.UPDATE_CUSTOMER_ACCOUNT_PROPERTY({ account: account, propertyName: 'reads', value: reads })
  }

  @Action({ rawError: true })
  public async LoadTransactions (account: IAccount) {
    const transactions: Array<Transaction> = await TransactionsApi.loadAll(account.id)
    this.UPDATE_CUSTOMER_ACCOUNT_PROPERTY({ account: account, propertyName: 'transactions', value: transactions })
  }

  @Action({ rawError: true })
  public async LoadPending (account: IAccount) {
    const pending: Array<TransactionRequest> = await TransactionRequestsApi.loadAll(account.id, { filters: { unsent: '1' } })
    this.UPDATE_CUSTOMER_ACCOUNT_PROPERTY({ account: account, propertyName: 'pending', value: pending })
  }

  @Action({ rawError: true })
  public async LoadUsageHistory (account: IAccount) {
    const usage: IStringIndexed = await AccountsApi.loadUsageHistory(account.id)
    this.UPDATE_CUSTOMER_ACCOUNT_PROPERTY({ account: account, propertyName: 'usage', value: usage })
  }

  @Action({ rawError: true })
  public async UpdateAccountSearch (searchText: string) {
    this.SET_ACCOUNT_SEARCH(searchText)
  }

  public get customerMenu () {
    if (!this.customer.id) {
      return []
    }

    return [
      { title: 'Overview', icon: 'contact', to: { name: 'customer', params: { id: this.customer.id } } },
      { title: 'Communication', icon: 'communication', permissions: ['CUSTOMER.CONTACTS.VIEW', 'CUSTOMER.BILLGROUPS.VIEW'], to: { name: 'customercommunication', params: { id: this.customer.id } } },
      { title: 'Contracts', icon: 'contract', permission: 'CUSTOMER.CONTRACTS.VIEW', to: { name: 'customercontracts', params: { id: this.customer.id } } },
      { title: 'Notes', icon: 'notes', permission: 'CUSTOMER.NOTES.VIEW', to: { name: 'customernotes', params: { id: this.customer.id } } },
      { title: 'Invoices', icon: 'billing', permission: 'CUSTOMER.INVOICES.SUMMARY.VIEW', to: { name: 'customerinvoices', params: { id: this.customer.id } } },
      { title: 'Payments', icon: 'payments', permission: 'CUSTOMER.PAYMENTS.VIEW', to: { name: 'customerpayments', params: { id: this.customer.id } } },
      { title: 'Accounts', icon: 'meter', permission: 'CUSTOMER.ACCOUNTS.VIEW', to: { name: 'customeraccounts', params: { id: this.customer.id } } },
      { title: 'Files', icon: 'files', permission: 'CUSTOMER.FILES.VIEW', to: { name: 'customerfiles', params: { id: this.customer.id } } }
      // { title: 'Feed', icon: 'feed', to: { name: 'customerfeed', params: { id: this.customer.id } } }
    ]
  }

  public get accountsByUtility () {
    const utilities: Array<IUtilityGroup> = []

    this.customer.accounts.forEach(i => {
      let util = utilities.find(u => u.id === i.utilityId)
      if (!util) {
        util = {
          id: i.utilityId,
          name: i.utilityName,
          state: AppState.utilities[i.utilityId]!.state,
          commodities: [],
          accounts: []
        }
        utilities.push(util)
      }
      const commodity = parseInt(<string> i.commodity)

      if (!util.commodities.find(c => c === commodity)) {
        util.commodities.push(commodity)
      }
      util.accounts.push(i)
    })

    utilities.sort((a: IUtilityGroup, b: IUtilityGroup) => {
      const stateComp = a.state.localeCompare(b.state)
      if (stateComp === 0) {
        return a.name.localeCompare(b.name)
      }
      return stateComp
    })

    return utilities
  }

  public get filteredAccounts () {
    if (!this.accountSearchFilter.length) {
      return []
    }
    return this.customer.accounts.filter(a => a.accountNumber.indexOf(this.accountSearchFilter) !== -1)
  }
}

export const CustomerState = getModule(CustomerStateDef)
