import _Vue, { PluginObject, PluginFunction } from 'vue'
import moment from 'moment'
import { IStringIndexed } from '@/modules/shared/types'

export default class MomentPlugin<T> implements PluginObject<T>, IStringIndexed {
  public install (Vue: typeof _Vue, options?: T): void {
    Vue.filter('formatDate', function (value: string, format = 'MM/DD/YYYY hh:mm'): string {
      return moment(String(value)).format(format)
    })

    Vue.filter('formatDateLocal', function (value: string, format = 'MM/DD/YYYY hh:mm'): string {
      return moment.utc(String(value)).local().format(format)
    })
  }
}
