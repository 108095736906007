







































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'
import AccountsApi from '@/modules/shared/api/accounts'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import BillingManagementApi from '../../api/billing'

@Component({
  components: {
  }
})
export default class EpoChannelDownload extends Mixins(DownloadFile) { // 3841666012, 2969513015
  private errorMessage = ''

  private fetching = false
  private loading = false

  private accountsField = ''
  private accounts: IAccount[] = []
  private selectedAccounts: number[] = []

  private from = moment().subtract(13, 'months').format('YYYY-MM-DD')
  private to = moment().format('YYYY-MM-DD')

  private menu = {
    from: false,
    to: false
  }

  private handleSubmit () {
    this.loading = true

    const acct = this.accountsField.trim()

    AccountsApi.loadAll({ accountNumber: acct })
      .then((responseAccounts) => {
        this.accounts = responseAccounts
        this.selectedAccounts = [responseAccounts[0].id]

        BillingManagementApi.fetchEpoChannelReport(this.selectedAccounts[0], 'epo-channels', this.from, this.to)
          .then((document) => {
            this.downloadFile('epo-channels-' + this.accountsField + '-' + this.from + '-' + this.to + '.xlsx', document.file.binaryContents, 'application/xlsx')
          }).finally(() => {
            this.loading = false
          })
      }).finally(() => {
        this.fetching = false
      })
  }

  private beforeMount () {

  }
}
