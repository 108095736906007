













































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { FinanceUsageResettlementSummary, UsageResettlement } from '../../models/usageresettlement'
import { SettlementsApprovalApi } from '../../api/settlementsapproval'
import moment from 'moment'
import ResettledBucketCompare from '../../components/blocks/ResettledBucketCompare.vue'
import EventBus from '@/plugins/eventbus'
import { ResettlementBillableStatusEnum } from '../../../shared/enums'
import dtExporter from '@/modules/shared/datatablehelper'
import XLSX from 'xlsx'
import { ResettlementBillableStatuses } from '@/modules/shared/lists'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'

@Component({
  components: {
    'resettled-line-items': ResettledBucketCompare
  }
})
export default class SettlementsApproval extends Vue {
  private processingList: IStringDictionary<string> = {}

  private errorMessage = ''
  private loading: boolean = true

  private listHeaders: Array<IStringIndexed> = [
    { text: 'Customer', align: 'left', sortable: true, value: 'customerName' },
    { text: 'Account', align: 'left', sortable: true, value: 'accountNumber' },
    { text: 'Meter', align: 'left', sortable: true, value: 'meterNumber' },
    { text: 'Acct Status', align: 'left', sortable: true, value: 'accountStatusLabel' },
    { text: 'Usage Period', align: 'left', sortable: true, value: 'usagePeriod' },
    { text: 'Quantity', align: 'left', sortable: true, value: 'quantity' },
    { text: 'Version', align: 'left', sortable: true, value: 'settlementNumber' },
    { text: 'Net Charged', align: 'left', sortable: true, value: 'netCharged' },
    { text: 'Resettled', align: 'left', sortable: true, value: 'resettlePrice' },
    { text: '$ Diff', align: 'left', sortable: true, value: 'resettleDelta' },
    { text: '% Diff', align: 'left', sortable: true, value: 'deltaPercent' },
    { text: 'Status', align: 'left', sortable: true, value: 'billableStatusLabel' }
    // { text: 'Action', align: 'center', sortable: false, value: 'actions' }
  ]

  private exportHeaders: Array<IStringIndexed> = [
    { text: 'ID', align: 'left', sortable: true, value: 'id' },
    { text: 'Customer', align: 'left', sortable: true, value: 'customerName' },
    { text: 'Account', align: 'left', sortable: true, value: 'accountNumber', exportType: 'text' },
    { text: 'Meter', align: 'left', sortable: true, value: 'meterNumber', exportType: 'text' },
    { text: 'Acct Status', align: 'left', sortable: true, value: 'accountStatusLabel' },
    { text: 'Usage Period', align: 'left', sortable: true, value: 'usagePeriod' },
    { text: 'Quantity', align: 'left', sortable: true, value: 'quantity', exportType: 'quantity' },
    { text: 'Version', align: 'left', sortable: true, value: 'settlementNumber' },
    { text: 'Net Charged', align: 'left', sortable: true, value: 'netCharged', exportType: 'rate' },
    { text: 'Resettled', align: 'left', sortable: true, value: 'resettlePrice', exportType: 'rate' },
    { text: '$ Diff', align: 'left', sortable: true, value: 'resettleDelta', exportType: 'rate' },
    { text: '% Diff', align: 'left', sortable: true, value: 'deltaPercent' },
    { text: 'Status', align: 'left', sortable: true, value: 'billableStatusLabel' },
    { text: 'Approved By', align: 'left', sortable: true, value: 'approvedByName' },
    { text: 'Approved At', align: 'left', sortable: true, value: 'approvedAt' }
  ]

  private filters: IStringIndexed = {
    billableStatus: ResettlementBillableStatusEnum.UNDECIDED,
    diffThreshold: 'All'
  }

  private yesNo = [{ text: 'No', value: false }, { text: 'Yes', value: true }]
  private billableStatuses = mapObjectVuetifySelect(new ResettlementBillableStatuses(), true)
  private diffThresholds = ['All', '< 10%', '> 10%']

  private menu = {
    from: false,
    to: false
  }

  private filterFrom = null
  private filterTo = null
  private search = ''

  private items: Array<FinanceUsageResettlementSummary> = []
  private selectedDetail: Array<FinanceUsageResettlementSummary> = []
  private selectedItems: Array<FinanceUsageResettlementSummary> = []

  private filterDiffThreshold (i: FinanceUsageResettlementSummary): boolean {
    switch (this.filters.diffThreshold) {
      case '< 10%':
        return i.deltaPercent > 10
      case '> 10%':
        return i.deltaPercent < 10
    }
    return true
  }

  private filterBillableStatus (r: UsageResettlement): boolean {
    return this.filters.billableStatus === r.billableStatus
  }

  private filterDateRange (r: UsageResettlement): boolean {
    if (this.filters.from !== '' && this.filters.from >= r.createdAt) {
      return false
    }
    if (this.filters.to !== '' && this.filters.to <= r.createdAt) {
      return false
    }
    return true
  }

  private get filteredItems () {
    return this.items.filter(r => this.filterDiffThreshold(r) &&
      this.filterBillableStatus(r) &&
      this.filterDateRange(r)
    )
  }

  private handleExportList () {
    const nameExt = 'billableStatus=' + this.billableStatuses.find(i => i.value === this.filters.billableStatus)?.text +
      '&diffTheshold=' + this.filters.diffTheshold
    dtExporter(
      this.exportHeaders,
      this.filteredItems,
      'Resettlements',
      'Resettlements Export-' + nameExt + '&at=' + moment().format('YYYYMMDD-HHmm'),
      { bookType: 'xlsx' }
    )
  }

  private async handleExportHourly (item: UsageResettlement) {
    this.processingList[item.id] = 'download-hourly'
    try {
      const details = await SettlementsApprovalApi.loadHourly(item.id)

      // transform and do casts here (php cast turns some like "rate": "-0.0000162075", into -1.6E-05 )
      const floats = ['rate', 'profileVolume', 'share', 'lossFactor']
      for (const i in details) {
        for (const f in floats) {
          details[i][floats[f]] = parseFloat(details[i][floats[f]])
        }
      }

      const workbook = XLSX.utils.book_new()
      const filename = 'resettlement-hourly-download-' + item.id
      const dataSheet = XLSX.utils.json_to_sheet(details)
      XLSX.utils.book_append_sheet(workbook, dataSheet, filename.replace('/', ''))

      XLSX.writeFile(workbook, filename + '.xlsx')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err,
        timeout: 5000
      })
    } finally {
      Vue.delete(this.processingList, item.id)
    }
  }

  private saveStatusUpdate (toStatus: number) {
    for (const i in this.selectedItems) {
      const item = this.selectedItems[i]
      this.processingList[item.id] = 'statuschange'
      item.billableStatus = toStatus

      SettlementsApprovalApi.savePartial(item, ['billableStatus'])
        .catch((err: string) => {
          EventBus.$emit('app-snack', { message: err })
        })
        .finally(() => {
          this.selectedItems = []
          Vue.delete(this.processingList, item.id)
        })
    }
  }

  private handleApprove () {
    this.saveStatusUpdate(ResettlementBillableStatusEnum.BILLABLE)
  }

  private handleWriteoff () {
    this.saveStatusUpdate(ResettlementBillableStatusEnum.WRITE_OFF)
  }

  public rowClasses (item: UsageResettlement) {
    let rowClass = ''

    if (item.billableStatus) {
      rowClass += '' // ' text-decoration-line-through'
    }

    return rowClass
  }

  // todo: dont load everything at once, and paginate billed since it will grow
  // @Watch('filters', { immediate: true, deep: true })
  private filterChange (oldFilters: any, newFilters: any) {
    this.fetchStatus(this.filters.billableStatus)
  }

  private allEligible (toStatus: ResettlementBillableStatusEnum): Boolean {
    return this.selectedItems.length > 0 &&
      this.selectedItems.filter((i: FinanceUsageResettlementSummary) => i.billableStatus !== ResettlementBillableStatusEnum.BILLED && i.billableStatus !== toStatus).length === this.selectedItems.length
  }

  private fetchStatus (status: number) {
    this.loading = true

    SettlementsApprovalApi.loadAll({
      filters: {}, // { billableStatus: status },
      additional: {
        0: 'serviceAccountUsage',
        1: 'usageLineItems',
        serviceAccountUsage: ['account'],
        usageLineItems: {
          0: 'invoice',
          invoice: ['customer']
        }
      }
    })
      .then((resp: { total: number, items: Array<FinanceUsageResettlementSummary> }) => {
        this.items = resp.items
      }).catch((err: Error) => {
        console.log(err)
        this.errorMessage = err.message
      }).finally(() => {
        this.loading = false
      })
  }

  private beforeMount () {
    this.fetchStatus(ResettlementBillableStatusEnum.UNDECIDED)
  }
}
