






import { Component, Vue } from 'vue-property-decorator'
import SearchResults from '../components/SearchResults.vue'
import { SearchState } from '../store'

@Component({
  components: {
    results: SearchResults
  }
})
export default class SearchPage extends Vue {
  public get results () {
    return SearchState.results
  }

  public mounted () {

  }

  public created () {
  }

  public activated () {
  }

  public updated () {
  }

  public destroyed () {
  }

  public deactivated () {

  }
}
