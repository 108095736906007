
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DownloadFile extends Vue {
  protected downloadFile (name: string, contents: Uint8Array, mimeType: string) {
    const blob = new Blob([contents], { type: mimeType })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = name
    link.click()
  }
}
