import axios, { AxiosRequestConfig } from 'axios'
import { AuthState } from '@/modules/auth/store'
import { validateJsonToken } from '@/api/app'

const settlementsClient = axios.create({
  baseURL: process.env.VUE_APP_SETTLEMENTS_REST_URL
})

settlementsClient.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const now = new Date()
  if (!AuthState.services.settlements.token?.length || !validateJsonToken(AuthState.services.settlements.refreshToken)) {
    await AuthState.ServiceLogin('settlements')
  }

  if ((<Date>AuthState.services.settlements?.logoutAt).getTime() < (now.getTime() - 3)) {
    await AuthState.RefreshServiceLogin('settlements')
  }

  config.headers.Authorization = 'Bearer ' + AuthState.services.settlements.token
  return config
})

export default settlementsClient
