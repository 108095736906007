































































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import { InvoiceQueuesApi } from '@/modules/billing/api/queue/invoicequeues'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { BillGroupDeliveryMethodEnum, CustomerInvoiceStatusEnum, InvoiceHoldStatusEnum } from '@/modules/shared/enums'
import { InvoicesApi } from '@/modules/customers/api/invoices'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'

@Component
export default class InvoiceApprovalQueue extends Mixins(DownloadFile) {
  @Prop({ default: 0 })
  private itemId!: number

  private selectedItem: CustomerInvoice = new CustomerInvoice()

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'State', align: 'left', width: 85, sortable: true, value: 'customer.address.state' },
    { text: 'Invoice #', align: 'left', width: '95', sortable: true, value: 'invoiceNumber' },
    { text: 'Customer', align: 'left', width: 'auto', sortable: true, value: 'customer.businessName' },
    { text: 'Accounts', align: 'left', width: 120, sortable: true, value: 'numAccounts' },
    { text: 'Settlements', align: 'center', width: 160, sortable: true, value: 'hasSettlements' },
    { text: 'Invoiced', align: 'center', width: 120, sortable: true, value: 'invoiceDate' },
    { text: 'System', align: 'center', width: 120, sortable: true, value: 'billingSystem' },
    { text: 'Due', align: 'center', width: 95, sortable: true, value: 'dueDate' },
    { text: 'Total', align: 'right', width: 100, sortable: true, value: 'grandTotal' },
    { text: 'Rate(s)', align: 'center', width: 100, sortable: false, value: 'rateRange' },
    { text: 'Actions', align: 'center', width: 220, sortable: false, value: 'actions' }
  ]

  private loading = false

  private items: Array<CustomerInvoice> = []

  private showFilters = false
  private listSearch: string = ''

  private processing: INumericDictionary<string> = {}

  private get filteredItems () {
    return this.items
  }

  private get canEditQueueItem () {
    return AuthState.user.isAllowed('BILLING.QUEUES.INVOICEAPPROVAL.EDIT')
  }

  private canToggleHold (item: CustomerInvoice, toHoldStatus: number) {
    if (toHoldStatus === InvoiceHoldStatusEnum.UNHELD) {
      return this.canEditQueueItem &&
        item.holdStatus === InvoiceHoldStatusEnum.HELD
    } else {
      return this.canEditQueueItem &&
        item.holdStatus !== InvoiceHoldStatusEnum.HELD &&
        item.status < CustomerInvoiceStatusEnum.STATUS_APPROVED
    }
  }

  private hasDeliveryDestination (invoice: CustomerInvoice) {
    if (invoice.billGroup?.billDeliveryMethod === BillGroupDeliveryMethodEnum.EMAIL &&
      (!invoice.billGroup?.emailTo?.length && !invoice.billGroup?.ccTo?.length && !invoice.billGroup?.bccTo?.length)
    ) {
      return false
    }

    if (invoice.billGroup?.billDeliveryMethod === BillGroupDeliveryMethodEnum.FAX && !invoice.billGroup?.fax?.length) {
      return false
    }

    return true
  }

  private handleClickRow (item: CustomerInvoice) {
    this.selectedItem = item
    this.$router.push({ name: 'iaqitem', params: { itemId: item.id.toString() } }).catch(() => {})
  }

  private handleItemUpdate (item: CustomerInvoice) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      Vue.set(this.items, idx, item)
      this.selectedItem = item
    } else {
      this.selectedItem = new CustomerInvoice()
    }
  }

  private handleItemApprove (item: CustomerInvoice) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      this.items.splice(idx, 1)
      this.selectedItem = new CustomerInvoice()
    }
    this.$router.push({ name: 'invoiceapprovalqueue' }).catch(() => {})
  }

  private async handleFinalize (item: CustomerInvoice, mode: string) {
    try {
      this.processing[item.id] = mode

      const resp = await InvoiceQueuesApi.performAction(item, mode)
      if (resp.message) {
        this.handleItemUpdate(resp.invoice)
        EventBus.$emit('app-snack', {
          message: resp.message,
          color: 'secondary',
          timeout: 5000
        })
      } else {
        this.handleItemApprove(resp.invoice)
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err,
        timeout: 5000
      })
    } finally {
      Vue.delete(this.processing, item.id)
    }
  }

  public async handleDownloadPdf (invoice: CustomerInvoice) {
    try {
      Vue.set(this.processing, invoice.id, 'download')
      const file = await InvoicesApi.getPdf(invoice)
      this.downloadFile(file.name, file.binaryContents, 'application/pdf')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processing, invoice.id)
    }
  }

  public async handleHoldUnhold (invoice: CustomerInvoice, holdStatus: number) {
    try {
      Vue.set(this.processing, invoice.id, 'setHoldStatus')

      invoice.holdStatus = holdStatus
      const updated = await InvoicesApi.savePartial(invoice, ['holdStatus'])

      const idx = this.items.findIndex(c => c.id === updated.id)
      if (idx !== -1) {
        this.items[idx] = updated
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processing, invoice.id)
    }
  }

  private itemViewedClass (item: CustomerInvoice): string {
    if (item.holdStatus === InvoiceHoldStatusEnum.HELD) {
      return 'warning lighten-3'
    }

    if (item.id === this.itemId) {
      return 'primary lighten-3'
    }
    return ''
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  private beforeMount () {
    this.loading = true
    InvoiceQueuesApi.loadAll({
      status: CustomerInvoiceStatusEnum.STATUS_PENDING_APPROVAL.toString()
    })
      .then(resp => {
        this.items = resp.items
        if (this.itemId) {
          this.selectedItem = this.items.find(i => i.id === this.itemId) || new CustomerInvoice()
          if (this.selectedItem.id !== this.itemId) {
            this.$router.push({ name: 'invoiceapprovalqueue' })
          }
        }
      })
      .finally(() => { this.loading = false })

    EventBus.$on('iaqtem:update', this.handleItemUpdate)
  }

  private beforeDestroy () {
    EventBus.$off('iaqtem:update', this.handleItemUpdate)
  }
}
