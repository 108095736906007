import authClient from './client'
import { AxiosResponse } from 'axios'

const baseUrl = 'service-auth'

export interface IAuthLoginResponse {
  token: string;
  refreshToken: string;
}

export default class ServiceApi {
  public static async login (serviceName: string): Promise<IAuthLoginResponse> {
    try {
      const response = await authClient.post('service-auth', { grantType: 'new', service: serviceName })

      if (response.status === 200) {
        return response.data as IAuthLoginResponse
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.message) {
          throw new Error(err.response.data.message)
        } else {
          throw new Error('Unknown application error,  unable to login')
        }
      } else {
        throw err
      }
    }
  }

  public static async refresh (serviceName: string, token: string): Promise<IAuthLoginResponse> {
    try {
      const response: AxiosResponse = await authClient.post('service-auth', { grantType: 'refresh', service: serviceName, assertion: token })
      if (response.status === 200) {
        return (response.data as IAuthLoginResponse)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      console.log(err) // avoid no-useless-catch lint
      throw err
    }
  }
}
