
































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import Customer from '@/modules/customers/models/customer'
import CustomerApi from '@/modules/customers/api/customer'
import { TaxExemptItemCchTypeEnum, TaxExemptItemStatusEnum, UserGroupsEnum } from '@/modules/shared/enums'
import { Note } from '@/modules/shared/models/note'
import EditCustomerNote from '@/modules/customers/components/forms/Note.vue'
import DialogHost from '@/modules/shared/mixins/DialogHost.vue'
import ConfirmDialog from '@/modules/shared/components/ConfirmDialog.vue'
import { TaxExemptFormsApi } from '@/modules/finance/api/queue/taxexemptqueue'
import CustomerOverview from '../../../../dataservices/components/queues/CustomerOverview.vue'
import CustomerNotes from '../../../../dataservices/components/queues/CustomerNotes.vue'
import AssignItemForm from '@/modules/finance/components/forms/exemptqueue/AssignItemForm.vue'
import SelectServiceAccounts from '@/modules/shared/components/forms/SelectServiceAccounts.vue'
import { AuthState } from '@/modules/auth/store'
import FileUploadForm from '@/modules/finance/components/forms/creditqueue/FileUpload.vue'
import { File } from '@/modules/shared/models/file'
import { TaxExemptForm } from '@/modules/customers/models/customer/taxexemptform'
import FilesApi from '@/modules/customers/api/files'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import TaxExemptFormForm from './TaxExemptFormForm.vue'

@Component({
  components: {
    'customer-overview': CustomerOverview,
    'tax-exempt-form': TaxExemptFormForm,
    'customer-notes': CustomerNotes,
    'assign-worker': AssignItemForm,
    'edit-customer-note': EditCustomerNote,
    'confirm-dialog': ConfirmDialog,
    'service-accounts': SelectServiceAccounts,
    'upload-files': FileUploadForm
  }
})
export default class TaxExemptItem extends Mixins(DialogHost, DownloadFile) {
  @Prop({ required: true, default: () => new TaxExemptForm() })
  private item!: TaxExemptForm

  @Prop({ default: 'finance' })
  private context!: string

  private customer: Customer = new Customer()

  private fileDialog: boolean = false
  private loadingFile = false
  private loadingAccounts = false
  private effectiveMenu = false
  private expireMenu = false
  private errorMessage = ''
  private collapseNotes: boolean = true

  public statuses = mapObjectVuetifySelect(TaxExemptForm.statuses, true)
  public cchReasons = mapObjectVuetifySelect(TaxExemptForm.cchReasonList, true)
  public cchTypes = mapObjectVuetifySelect(TaxExemptForm.cchTypeList, true)

  public get listCchTypes () {
    return this.cchTypes.sort((a, b) => (a.text <= b.text) ? -1 : 1)
  }

  public get selectedTypesLength () {
    return this.item.cchTypes.length
  }

  private get assignPermissionName () {
    return 'FINANCE.QUEUES.TAXEXEMPT.ASSIGN'
  }

  private get isRemovable () {
    return [
      TaxExemptItemStatusEnum.NEW,
      TaxExemptItemStatusEnum.INPROGRESS,
      TaxExemptItemStatusEnum.ONHOLD,
      TaxExemptItemStatusEnum.DENIED,
      TaxExemptItemStatusEnum.RENEWED
    ].includes(this.item.status) && this.item.userId
  }

  private get isApprovable () {
    return ![
      TaxExemptItemStatusEnum.REMOVED,
      TaxExemptItemStatusEnum.DENIED,
      TaxExemptItemStatusEnum.RENEWED,
      TaxExemptItemStatusEnum.APPROVED
    ].includes(this.item.status) &&
      this.item.serviceAccounts.length !== 0
  }

  private get isHoldable () {
    return [
      TaxExemptItemStatusEnum.NEW,
      TaxExemptItemStatusEnum.INPROGRESS
    ].includes(this.item.status) && this.item.userId
  }

  private get isReopenable () {
    return [
      TaxExemptItemStatusEnum.APPROVED,
      TaxExemptItemStatusEnum.DENIED
    ].includes(this.item.status)
  }

  private get canAttachFile () {
    return this.context !== 'finance'
  }

  private get dataservicesGroupId () {
    return UserGroupsEnum.DATA_SERVICES
  }

  private async handleCancelItem () {
    this.handleDialog({
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      props: {
        title: 'Confirm Item Removal',
        message: 'Are you sure you want to remove this item from the queue? Account exemption status will be unchanged.',
        onConfirm: this.cancelItem
      }
    })
  }

  private async handleDeny () {
    this.handleDialog({
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      props: {
        title: 'Confirm Exemption Denied',
        message: 'The accounts selected will have their tax exempt status removed',
        onConfirm: () => {
          this.item.status = TaxExemptItemStatusEnum.DENIED
          this.handleItemEdited()
        }
      }
    })
  }

  private async cancelItem () {
    try {
      this.item.status = TaxExemptItemStatusEnum.REMOVED
      this.$emit('item:finalized', await TaxExemptFormsApi.savePartial(this.item, ['status']))
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private handleNoteUpdate (note: Note) {
    this.customer.notes.unshift(note)
  }

  private async handleItemEdited () {
    const updatedItem = await TaxExemptFormsApi.save(this.item)
    this.$emit('item:updated', updatedItem)
  }

  private async handleFileUpload (upload: { reading: boolean; completed: number; file: File }) {
    const newItem = await TaxExemptFormsApi.attachFile(this.item, upload.file, (e: ProgressEvent) => {
      upload.reading = true
      upload.completed = Math.round((e.loaded * 100) / e.total)
    })

    this.$emit('item:update', newItem)
  }

  private async handleFileDownload () {
    this.loadingFile = true
    const item = this.item

    try {
      if (item.fileId === null || item.fileId === null) {
        throw new Error('No file attached')
      }
      const file = await FilesApi.load(item.customerId, item.fileId)
      this.downloadFile(file.name, file.binaryContents, 'application/pdf')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.loadingFile = false
    }
  }

  private handleAddNote () {
    this.handleDialog({
      component: 'edit-customer-note',
      dialogProps: {
        width: 'auto'
      },
      props: {
        note: new Note({ customerId: this.customer.id }),
        noteList: this.customer.notes,
        saveMethod: 'SaveNote'
      }
    })
  }

  @Watch('item.id', { immediate: true, deep: true })
  private async handleItemChange () {
    if (this.item.id) {
      this.customer = await CustomerApi.load(this.item.customer.id, ['notes', 'serviceAccounts'])
    }
  }
}
