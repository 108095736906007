import { AppState } from '@/stores/appStore'
import moment from 'moment'
import { IStringIndexed } from '../types'

export interface IBaseModel extends IStringIndexed {
  active: boolean;
  createdAt: number;
  createdBy: number;
  updatedAt: number;
  updatedBy: number;

}

export abstract class Base implements IBaseModel, IStringIndexed {
  [key: string]: any

  public active = true

  public createdAt = moment.utc().unix()
  public createdBy = 0
  public updatedAt = moment.utc().unix()
  public updatedBy = 0

  constructor (props?: Partial<IBaseModel>) {
    if (props) {
      Object.assign(this, props)
    }
  }

  public get created () {
    return moment.unix(this.createdAt)
  }

  public get updated () {
    return moment.unix(this.updatedAt)
  }

  public get createdByName () {
    return AppState.cisUsers[this.createdBy]?.detailName || ''
  }

  public get updatedByName () {
    return AppState.cisUsers[this.updatedBy]?.detailName || ''
  }

  public clone () {
    const copy = Object.assign(Object.create(Object.getPrototypeOf(this)), this)
    for (const p of Object.keys(this)) {
      if (this[p] instanceof Base) {
        copy[p] = this[p].clone()
      } else if (Array.isArray(this[p])) {
        copy[p] = []
        for (const i in this[p]) {
          if (this[p][i] instanceof Base) {
            copy[p][i] = this[p][i].clone()
          } else {
            copy[p][i] = this[p][i]
          }
        }
      } else if (typeof this[p] === 'object' && !!this[p]) {
        copy[p] = Object.assign(Object.create(Object.getPrototypeOf(this[p])), true)
        for (const sp of Object.keys(this[p])) {
          copy[p][sp] = this[p][sp]
        }
      }
    }
    return copy
  }
}
