














































import { Component, Vue } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'
import DocumentForm from '@/modules/customermanagement/components/fulfillment/Document.vue'
import DocumentModel from '@/modules/customermanagement/models/document'
import documentApi from '@/modules/customermanagement/api/documentmanagement'

@Component({
  components: {
    document: DocumentForm
  }
})
export default class DocumentManager extends Vue {
  private documents: Array<DocumentModel> = []
  private loading: boolean = false

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Description', align: 'left', sortable: true, value: 'description' },
    { text: 'Actions', align: 'center', sortable: false, filterable: false, value: 'action' }
  ]

  private editedItem: DocumentModel = new DocumentModel()

  private errorMessage: string = ''

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get canCreate () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.CREATE')
  }

  private get canEdit () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.EDIT')
  }

  private get canViewTemplates () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.TEMPLATES.VIEW')
  }

  private get canViewAttachments () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.ATTACHMENTS.VIEW')
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.text !== 'Actions' })
    }

    return headers
  }

  private handleNewDocument () {
    alert('Not yet built')
  }

  private async loadData () {
    this.errorMessage = ''
    this.loading = true
    try {
      this.documents = await documentApi.loadAllDocuments()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }

  public created () {
    this.loadData()
  }
}
