import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse } from 'axios'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { File as FileModel } from '@/modules/shared/models/file'

export default class DataServicesReportsApi {
  public static async loadActiveCustomers (filters?: IStringDictionary<any>): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await esalesClient.get('dataservices-reporting-activecustomers' + filtersToQs(filters))
      if (response.status === 200 && response.data.records) {
        return Object.values(response.data.records)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadIdrReport (filters?: IStringDictionary<any>): Promise<Array<IStringIndexed>> {
    try {
      filters = filters || {}

      const response: AxiosResponse = await esalesClient.get('dataservices-reporting-idr' + filtersToQs(filters))
      if (response.status === 200 && response.data.records) {
        return Object.values(response.data.records)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
