import { Base } from './base'
import { AppState } from '@/stores/appStore'
import { ListsEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '../types'

export interface IPhoneNumber extends IStringIndexed {
  type?: number | null;
  number: string;
  extension?: string | null;
  priority: number;
}

export class PhoneNumber extends Base implements IPhoneNumber {
  public static maskedProps = ['number']

  public id: number = 0
  public type = null
  public number = ''
  public extension = null
  public priority = 0

  constructor (props?: Partial<IPhoneNumber>) {
    super(props)

    Object.assign(this, props)
  }

  public get typeAbbrev () {
    return AppState.listsByName[ListsEnum.PHONE_TYPES]![this.type!][0]
  }

  public get icon (): string {
    switch (AppState.listsByName[ListsEnum.PHONE_TYPES]![this.type!]) {
      case 'Work':
        return 'businessphone'
      case 'Cell':
        return 'cell'
      case 'Fax':
        return 'fax'
      default:
        return 'phone'
    }
  }

  public toJSON () {
    return {
      ...this,
      number: this.number.replace(/[^0-9]/g, '')
    }
  }
}
