






















































































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import FinanceReportsApi from '@/modules/finance/api/reports'
import dtExporter from '@/modules/shared/datatablehelper'
import { IStringIndexed } from '@/modules/shared/types'

@Component({
  components: {}
})
export default class AgingDetailParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true

  private menu = {
    asOf: false
  }

  private asOf = ''
  private resultsSearch = ''
  private records: Array<IStringIndexed> = []
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'State', align: 'left', sortable: true, value: 'state', exportType: 'text' },
    { text: 'Customer Id', align: 'left', sortable: true, value: 'customerId' },
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName', exportType: 'text' },
    { text: 'Customer Status', align: 'left', sortable: true, value: 'customerStatus', exportType: 'text' },
    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber' },
    { text: 'Utility', align: 'left', sortable: true, value: 'utility', exportType: 'text' },
    { text: 'LDC #', align: 'left', sortable: true, value: 'accountNumber', exportType: 'text' },
    { text: 'LDC Status', align: 'left', sortable: true, value: 'accountStatus', exportType: 'text' },
    { text: 'Invoice Date', align: 'left', sortable: true, value: 'invoiceDate', exportType: 'date' },
    { text: 'Due Date', align: 'left', sortable: true, value: 'dueDate', exportType: 'date' },

    { text: 'Invoice Amount', align: 'right', sortable: true, value: 'invoiceTotal', exportType: 'money' },
    { text: 'Balance', align: 'right', sortable: true, value: 'openBalance', exportType: 'money' },
    { text: '0 - 30', align: 'left', sortable: true, value: '0 - 30', exportType: 'money' },
    { text: '31 - 60', align: 'left', sortable: true, value: '31 - 60', exportType: 'money' },
    { text: '61 - 90', align: 'left', sortable: true, value: '61 - 90', exportType: 'money' },
    { text: '91 - 120', align: 'left', sortable: true, value: '91 - 120', exportType: 'money' },
    { text: '120+', align: 'left', sortable: true, value: '120+', exportType: 'money' }
  ]

  private buckets = ['0 - 30', '31 - 60', '61 - 90', '91 - 120', '120+', 'Total']

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true
      this.records = await FinanceReportsApi.loadAgingDetails({ asOf: this.asOf })
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  public get stateSummary () {
    const states: IStringIndexed = {}
    const total: IStringIndexed = { title: 'Total', '0 - 30': 0, '31 - 60': 0, '61 - 90': 0, '91 - 120': 0, '120+': 0, Total: 0 }
    for (const r of this.records) {
      const i = states[r.state] || { title: r.state, '0 - 30': 0, '31 - 60': 0, '61 - 90': 0, '91 - 120': 0, '120+': 0, Total: 0 }

      i[r.age] += parseFloat(r.openBalance)
      i.Total += parseFloat(r.openBalance)

      total[r.age] += parseFloat(r.openBalance)
      total.Total += parseFloat(r.openBalance)

      states[r.state] = i
    }

    const summary = Object.values(states).sort((l, r) => l.title.localeCompare(r.title))
    summary.push({ divider: true }, total)

    return summary
  }

  private exportList () {
    dtExporter(this.headers, this.records, 'Aging Invoice Detail', 'Aging Invoice Details-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
