





























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import Customer from '@/modules/customers/models/customer'
import EventBus from '@/plugins/eventbus'
import { CustomerInvoice } from '../../../models/customer/invoice'
import { AccountInvoice } from '../../../models/account/invoice'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import AccountInvoiceOverview from '../invoice/AccountOverview.vue'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import InvoiceHourlyApi from '../../../api/invoicehourly'
import XLSX, { WorkSheet } from 'xlsx'
import { AccountInvoiceSummary } from '../../../models/account/summary'
import { MeterRead } from '@/modules/shared/models/account/meterread'
import { CommodityAccount } from '@/modules/shared/models/account/base'

@Component({
  components: {
    'account-invoice-overview': AccountInvoiceOverview
  }
})
export default class AccountOverviews extends Vue {
  @Prop({ required: true })
  private invoiceId!: number

  @Prop({ required: false })
  private detailId!: number

  @Prop({ required: true })
  private selectedSummary!: AccountInvoiceSummary

  private selectedDetail: Array<AccountInvoice> = []
  private processingList: INumericDictionary<string> = {}

  private accountHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Account #', align: 'left', sortable: true, value: 'accountNumber' },
    { text: 'Charge Type', align: 'left', sortable: true, value: 'chargesDescription' },
    { text: 'Net Consumption', align: 'right', sortable: true, value: 'netConsumption' },
    { text: 'Cxl Status', align: 'right', sortable: true, value: 'cancelStatus' },
    { text: 'Commodity', align: 'right', sortable: true, value: 'commodityTotal' },
    { text: 'Other', align: 'right', sortable: true, value: 'otherTotal' },
    { text: 'Taxes', align: 'right', sortable: true, value: 'taxTotal' },
    { text: 'Total', align: 'right', sortable: true, value: 'invoiceTotal' },
    { text: 'Open Bal.', align: 'right', sortable: true, value: 'openBalance' },
    { text: 'Status', align: 'right', sortable: true, value: 'statusLabel' },
    { text: '', align: 'left', sortable: true, value: 'actions' },
    { text: '', align: 'left', sortable: true, value: 'data-table-expand' }
  ]

  private get canViewCharges () {
    return AuthState.user.isAllowed('CUSTOMER.INVOICES.ACCOUNT.VIEW')
  }

  private get canDisputeInvoice () {
    return AuthState.user.isAllowed('CUSTOMER.INVOICES.ACCOUNT.DISPUTE')
  }

  private getCancelStatus (item: AccountInvoice) {
    if (item.parentLineItem?.usageId === null) {
      return
    }
    const a: IAccount = CustomerState.customer.accounts.find((a: IAccount) => a.id === item.accountId) ?? new CommodityAccount()
    const u: MeterRead = a.reads.find((r: MeterRead) => r.id === item.parentLineItem?.usageId) ?? new MeterRead()

    if (u.isCancel) {
      return 'Cancel'
    }

    if (a.reads.findIndex(r => r.cancels(u)) !== -1) {
      return 'Canceled'
    }

    if (a.reads.findIndex(r => u.rebills(r)) !== -1) {
      return 'Rebill'
    }

    return 'Original'
  }

  public async handleDispute (invoice: AccountInvoice) {
    try {
      Vue.set(this.processingList, invoice.id, 'disputing')
      await CustomerState.DisputeAccountInvoice({ invoice: this.invoice, accountInvoice: invoice })
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id)
    }
  }

  public async handleCancelDispute (invoice: AccountInvoice) {
    try {
      Vue.set(this.processingList, invoice.id, 'disputing')
      await CustomerState.CancelDisputeAccountInvoice({ invoice: this.invoice, accountInvoice: invoice })
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id)
    }
  }

  public async handleDownloadHourly (invoice: AccountInvoice) {
    try {
      Vue.set(this.processingList, invoice.id, 'download')
      const hours = await InvoiceHourlyApi.loadOne(this.invoice.customerId, this.invoiceId, invoice.id, invoice.parentLineItem!.chargeId)

      const workbook = XLSX.utils.book_new()
      for (const transId in hours) {
        const dataSheet = XLSX.utils.json_to_sheet(hours[transId])
        const matching = hours[transId][0].active === 1

        // new @todo: solved these issues in Portal/AccountInvoices::handleDownloadHourly

        // nice-to-have: add a sum below every numeric column, (and freeze top row)
        // const sumsRow = hours[transId].length + 2
        // todo: why dont these work: XLSX.utils.sheet_set_array_formula(dataSheet, 'B' + sumsRow, 'SUM(B2:B' + (sumsRow - 1) + ')')
        // XLSX.utils.sheet_set_array_formula(dataSheet, 'B770', 'SUM(B2:B769)')

        // remove active col / alternatively, filter out of json before creating sheet
        // const lastCol = String.fromCharCode('A'.charCodeAt(0) + (hours[transId][0].length - 1))
        // const newlastCol = String.fromCharCode('A'.charCodeAt(0) + (hours[transId][0].length - 2))
        // if (dataSheet['!ref'] !== undefined) {
        //   dataSheet['!ref'] = dataSheet['!ref'].replace(lastCol, newlastCol)
        // }

        XLSX.utils.book_append_sheet(workbook, dataSheet, transId + (matching ? '' : '-DISCREP'))
      }
      XLSX.writeFile(workbook, 'hourly-' + this.invoice.invoiceNumber + '-' + invoice.id + '.xlsx')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id)
    }
  }

  private get invoice () {
    const inv = CustomerState.customer.invoices.find(i => i.id === this.invoiceId)
    if (inv) {
      return inv
    }
    return new CustomerInvoice({ invoiceNumber: 'Not Found' })
  }

  public processingAction (invoice: AccountInvoice) {
    return this.processingList[invoice.id] || false
  }

  public rowClasses (item: AccountInvoice) {
    let rowClass = ''

    if (item.voidDate) {
      rowClass += ' text-decoration-line-through'
    }

    return rowClass
  }

  private getAccountNumber (invoice: AccountInvoice) {
    const acct = CustomerState.customer.accounts.find((a: IAccount) => a.id === invoice.accountId)
    if (acct) {
      return acct.accountNumber
    }
    return ''
  }

  @Watch('invoiceId')
  private handleInvoiceChangeView (newId: number, oldId: number) {
    if (newId !== oldId && this.canViewCharges && this.invoice.invoices.length === 1) {
      this.selectedDetail.push(this.invoice.invoices[0])
    }
  }

  private mounted () {
    if (this.canViewCharges) {
      if (this.detailId) {
        this.selectedDetail.push(this.invoice.invoices.find(i => i.id === this.detailId)!)
      } else {
        this.selectedDetail.push(this.invoice.invoices[0])
      }
    }
  }

  private rowSelected (item: AccountInvoiceSummary) {
    return item.accountId === this.selectedSummary.accountId ? 'primary lighten-3' : ''
  }

  private handleClickRow (item: AccountInvoiceSummary) {
    this.selectedSummary = item
  }
}
