var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" Settlements Approval: Resettled Usages ")]),_c('v-col',{staticClass:"d-flex align-right justify-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","title":"Export List","loading":_vm.processingList['export-list'] !== undefined,"disabled":_vm.processingList['export-list'] !== undefined},on:{"click":function($event){return _vm.handleExportList()}}},[_c('v-icon',[_vm._v("$vuetify.icons.export")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.listHeaders,"items":_vm.filteredItems,"item-key":"id","dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"show-expand":true,"expanded":_vm.selectedDetail,"item-class":_vm.rowClasses,"search":_vm.search,"show-select":"","loading-text":"Loading...please wait","loading":_vm.loading},on:{"update:expanded":function($event){_vm.selectedDetail=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.filterFrom,"label":"Resettled From","prepend-icon":"$vuetify.icons.activityday","readonly":"","outlined":"","dense":""}},on))]}}],null,true),model:{value:(_vm.menu.from),callback:function ($$v) {_vm.$set(_vm.menu, "from", $$v)},expression:"menu.from"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","scrollable":""},on:{"input":function($event){_vm.menu.from = false}},model:{value:(_vm.filterFrom),callback:function ($$v) {_vm.filterFrom=$$v},expression:"filterFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.filterTo,"label":"To","prepend-icon":"$vuetify.icons.activityday","readonly":"","outlined":"","dense":""}},on))]}}],null,true),model:{value:(_vm.menu.to),callback:function ($$v) {_vm.$set(_vm.menu, "to", $$v)},expression:"menu.to"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","scrollable":""},on:{"input":function($event){_vm.menu.to = false}},model:{value:(_vm.filterTo),callback:function ($$v) {_vm.filterTo=$$v},expression:"filterTo"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.billableStatuses,"label":"Billable Status","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filters.billableStatus),callback:function ($$v) {_vm.$set(_vm.filters, "billableStatus", $$v)},expression:"filters.billableStatus"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.diffThresholds,"label":"Diffference","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filters.diffThreshold),callback:function ($$v) {_vm.$set(_vm.filters, "diffThreshold", $$v)},expression:"filters.diffThreshold"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"append-icon":"$vuetify.icons.search","label":"Search","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"align-center",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":!_vm.allEligible(1)},on:{"click":_vm.handleApprove}},[_vm._v("Approve")])],1),_c('v-col',{staticClass:"align-center",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":!_vm.allEligible(5)},on:{"click":_vm.handleWriteoff}},[_vm._v("Write-Off")])],1)],1)]}},{key:"item.usagePeriod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serviceAccountUsage.startDate)),_c('br'),_vm._v(_vm._s(item.serviceAccountUsage.endDate)+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US').format(item.quantity))+" ")]}},{key:"item.resettleDelta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.resettleDelta))+" ")]}},{key:"item.netCharged",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.netCharged))+" ")]}},{key:"item.resettlePrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.resettlePrice))+" ")]}},{key:"item.deltaPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.deltaPercent).toFixed(2))+"% ")]}},{key:"item.billableStatusLabel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billableStatusLabel)+" "),(item.getApprovedBy != null)?_c('div',[_vm._v("at: "+_vm._s(item.getApprovedByName))]):_vm._e(),(item.getApprovedAt != null)?_c('div',[_vm._v("by: "+_vm._s(item.getApprovedAt))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id)?[_c('v-btn',{attrs:{"loading":_vm.processingList[item.id] === 'download-hourly',"icon":"","title":"Download Hourly"},on:{"click":function($event){return _vm.handleExportHourly(item)}}},[_c('v-icon',{staticStyle:{"width":"1em"}},[_vm._v("$vuetify.icons.export")])],1)]:_c('v-progress-circular',{attrs:{"indeterminate":true}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"20"}},[_c('resettled-line-items',{attrs:{"usageResettlement":item}})],1)]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }