



























































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import FinanceReportsApi from '@/modules/finance/api/reports'
import dtExporter from '@/modules/shared/datatablehelper'
import { IStringIndexed } from '@/modules/shared/types'

@Component({
  components: {}
})
export default class AgingCustomerParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true

  private menu = {
    asOf: false
  }

  private asOf = ''
  private resultsSearch = ''
  private records: Array<IStringIndexed> = []
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'State', align: 'left', sortable: true, value: 'state', exportType: 'text' },
    { text: 'Customer Id', align: 'left', sortable: true, value: 'customerId' },
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName', exportType: 'text' },
    { text: 'Customer Status', align: 'left', sortable: true, value: 'customerStatus', exportType: 'text' },
    { text: 'Credit Balance', align: 'left', sortable: true, value: 'creditBalance', exportType: 'money' },
    { text: 'Num Invoices', align: 'left', sortable: true, value: 'numInvoices' },
    { text: '0 - 30', align: 'left', sortable: true, value: '0 - 30', exportType: 'money' },
    { text: '31 - 60', align: 'left', sortable: true, value: '31 - 60', exportType: 'money' },
    { text: '61 - 90', align: 'left', sortable: true, value: '61 - 90', exportType: 'money' },
    { text: '91 - 120', align: 'left', sortable: true, value: '91 - 120', exportType: 'money' },
    { text: '120+', align: 'left', sortable: true, value: '120+', exportType: 'money' },
    { text: 'Total', align: 'left', sortable: true, value: 'total', exportType: 'money' }
  ]

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true
      this.records = await FinanceReportsApi.loadAgingByCustomer({ asOf: this.asOf })
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private exportList () {
    dtExporter(this.headers, this.records, 'Aging Customer Export', 'Aging Customers-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
