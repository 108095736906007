




















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import moment from 'moment'
import Rules from '@/plugins/validations'
import { File as FileModel } from '@/modules/shared/models/file'
import VolumesApi from '@/modules/wholesale/api/volumes'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import dtExporter from '@/modules/shared/datatablehelper'

@Component
export default class Volumes extends Vue {
  private errorMessage = ''
  private noErrors = true
  private loading: boolean = false
  private from: string = moment().subtract('10', 'd').format('YYYY-MM-DD')
  private to: string = moment().format('YYYY-MM-DD')
  private company: number = 1
  private type: string = 'RT'
  private mode: string = 'raw'
  private interval: string = 'hourly'
  private fetchType: string = 'RT'
  private reportType: string = 'Hourly'

  private valRules: Rules = Rules

  private recs: Array<IStringIndexed> = []

  private menu = {
    from: false,
    to: false
  }

  private types = [
    { value: 'RT', text: 'Real Time' },
    { value: 'DA', text: 'Day Ahead' } //,
    // { value: 'CO', text: 'Combined' }
  ]

  private intervals = [
    { value: 'hourly', text: 'Hourly' },
    { value: 'daily', text: 'Daily' },
    { value: 'monthly', text: 'Monthly' }
  ]

  public companies = [
    { value: 1, text: 'Actual' },
    { value: 0, text: 'Truelight' }
  ]

  public async handleFetchRecords () {
    if (this.loading) {
      return
    }
    this.errorMessage = ''
    this.loading = true

    if (moment(this.to).isBefore(this.from)) {
      const temp = this.from
      this.from = this.to
      this.to = temp
    }

    if (!moment(this.from).isAfter('2020-01-01')) {
      this.errorMessage = 'Actual Energy has no data prior to 2020'
    }

    if (this.errorMessage) {
      return
    }

    try {
      this.loading = true

      this.recs = await VolumesApi.loadVolumes(this.type, this.company, this.from, this.to)
      this.fetchType = this.type
      Vue.set(this, 'selectedZones', this.zones)
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private handleExportList () {
    let label = 'SettledLoad-from-' + this.from + '-to-' + this.to + '-'
    switch (this.mode) {
      case 'zonal':
        label += 'ZonalPivot-'
        break
      case 'compare':
        label += 'ResettleCompare-'
        break
      default:
        label += 'RawData-'
    }
    label += this.intervals.find(i => i.value === this.interval)?.text + 'Interval-' + moment().format('YYYYMMDD-HHmm')

    dtExporter(this.columnHeaders, this.filteredRecs, 'Settled Load', label)
  }

  private columnPresets = [
    { value: 'finance', text: 'Finance' },
    { value: 'wholesale', text: 'Wholesale' }
  ]

  private columnConfig: IStringIndexed = {
    RT: {
      available: [
        { text: 'DA Scheduled Load', value: 'calculatedDALoad' },
        { text: 'Load Obligation', value: 'realTimeLoadObligation' },
        { text: 'Revenue Metered Load', value: 'revenueMeteredLoad' },
        { text: 'Adjusted Net Interchange', value: 'realTimeAdjustedNetInterchange' },
        { text: 'Adjusted Net Interchange Deviation', value: 'adjustedNetInterchangeDeviation' },
        { text: 'Demand Reduction Obligation Deviation', value: 'demandReductionObligationDeviation' },

        { text: 'Energy Component', value: 'realTimeEnergyComponent' },

        { text: 'Energy Charge Credit', value: 'realTimeEnergyChargeCredit' },
        { text: 'Congestion Charge Credit', value: 'realTimeCongestionChargeCredit' },
        { text: 'Loss Charge Credit', value: 'realTimeLossChargeCredit' },

        { text: 'Charge Total', value: 'chargeTotal' }
      ],
      presets: {
        finance: ['realTimeAdjustedNetInterchange', 'adjustedNetInterchangeDeviation', 'chargeTotal'],
        wholesale: ['realTimeLoadObligation', 'adjustedNetInterchangeDeviation', 'realTimeEnergyComponent', 'chargeTotal']
      }
    },
    DA: {
      available: [
        { text: 'Load Obligation', value: 'dayAheadLoadObligationHEFG' },
        { text: 'Adjusted Load Obligation', value: 'dayAheadAdjustedLoadObligationKHIJ' },
        { text: 'Adjusted Net Interchange', value: 'dayAheadAdjustedNetInterchangeLDK' },

        { text: 'Demand Reduction Obligation', value: 'dayAheadDemandReductionObligation' },

        { text: 'Energy Component', value: 'dayAheadEnergyComponent' },

        { text: 'Energy Charge Credit', value: 'dayAheadEnergyChargeCredit' },
        { text: 'Congestion Charge Credit', value: 'dayAheadCongestionChargeCredit' },
        { text: 'Loss Charge Credit', value: 'dayAheadLossChargeCredit' },

        { text: 'Charge Total', value: 'chargeTotal' }
      ],
      presets: {
        finance: ['dayAheadAdjustedNetInterchangeLDK', 'chargeTotal'],
        wholesale: ['dayAheadLoadObligationHEFG', 'dayAheadEnergyComponent', 'chargeTotal']
      }
    }
  }

  private selectedColumns: IStringDictionary<Array<string>> = { RT: [], DA: [] }

  private selectedPresets = {
    RT: AuthState.user.isAllowed('FINANCE.COSTS.VIEW') ? 'finance' : 'wholesale',
    DA: AuthState.user.isAllowed('FINANCE.COSTS.VIEW') ? 'finance' : 'wholesale'
  }

  private selectedZones: Array<string> = []
  private selectedPeriods: string|Array<string> = '1) Original Version'
  private selectAllZones = 1

  private getSettlementKey (rec: IStringIndexed) {
    return rec.settlementNumber + ') ' + (rec.settlementNumber === 1 ? 'Original Version' : moment(rec.version).format('MM/DD/YYYY'))
  }

  private getFlowKey (rec: IStringIndexed) {
    if (this.interval === 'monthly') {
      return moment(rec.flowDate).format('YYYY-MM')
    }
    let key = rec.flowDate
    if (this.interval === 'hourly') {
      key += ' ' + rec.tradingInterval
    }
    return key
  }

  private get filteredRecs () {
    let recs = Array.from(this.recs)

    if (this.selectedZones.length) {
      recs = this.recs.filter(f => this.selectedZones.indexOf(f.zoneAlias) !== -1)
    }

    if (this.selectedPeriods && this.selectedPeriods.length >= 1 && this.settlementPeriods.length > 1) {
      recs = recs.filter(f => {
        return this.selectedPeriods.indexOf(this.getSettlementKey(f)) !== -1
      })
    }

    if (this.mode === 'zonal') {
      const pivoted: Array<IStringIndexed> = []
      for (const r of recs) {
        const key = this.getFlowKey(r)
        let existing = pivoted.find(p => p.key === key)

        if (!existing) {
          existing = {
            key: key,
            flowDate: this.interval === 'monthly' ? moment(r.flowDate).format('YYYY-MM-DD') : r.flowDate,
            tradingInterval: r.tradingInterval
          }
          pivoted.push(existing)
        }
        for (const t in this.selectedColumnConfig) {
          for (const c of this.selectedColumns[t]) {
            const col = this.columnConfig[t].available.find((i: { text: string, value: string}) => i.value === c)
            if (!existing[r.zoneAlias + col.value]) {
              existing[r.zoneAlias + col.value] = 0
            }

            existing[r.zoneAlias + col.value] = (parseFloat(existing[r.zoneAlias + col.value]) + parseFloat(r[col.value])).toFixed(9)

            if (!existing[col.value + 'Total']) {
              existing[col.value + 'Total'] = 0
            }
            existing[col.value + 'Total'] = (parseFloat(existing[col.value + 'Total']) + parseFloat(r[col.value])).toFixed(9)
          }
        }
      }
      return pivoted
    } else if (this.mode === 'compare') {
      const pivoted: Array<IStringIndexed> = []

      for (const r of recs) {
        const key = this.getFlowKey(r) + r.zoneAlias
        let existing = pivoted.find(p => p.key === key)

        if (!existing) {
          existing = {
            key: key,
            flowDate: this.interval === 'monthly' ? moment(r.flowDate).format('YYYY-MM-DD') : r.flowDate,
            tradingInterval: r.tradingInterval,
            zoneAlias: r.zoneAlias,
            settlementNumber: r.settlementNumber,
            version: r.version
          }
          pivoted.push(existing)
        }
        const versionKey = this.getSettlementKey(r)

        for (const t in this.selectedColumnConfig) {
          for (const c of this.selectedColumns[t]) {
            const col = this.columnConfig[t].available.find((i: { text: string, value: string}) => i.value === c)
            if (!existing[versionKey + col.value]) {
              existing[versionKey + col.value] = 0
            }

            existing[versionKey + col.value] = (parseFloat(existing[versionKey + col.value]) + parseFloat(r[col.value])).toFixed(9)
          }
        }
      }
      for (const p of pivoted) {
        for (const t in this.selectedColumnConfig) {
          for (const c of this.selectedColumns[t]) {
            const col = this.columnConfig[t].available.find((i: { text: string, value: string}) => i.value === c)
            let total = ''
            for (const s of this.selectedPeriods) {
              if (p[s + col.value] === undefined) {
                continue
              }

              if (!total.length) {
                total = p[s + col.value]
              } else {
                total = (parseFloat(total) - parseFloat(p[s + col.value])).toFixed(9)
              }
            }
            p[col.value + 'Difference'] = total
          }
        }
      }
      return pivoted
    } else {
      if (this.interval !== 'hourly') {
        const pivoted: Array<IStringIndexed> = []

        for (const r of recs) {
          const versionKey = this.getSettlementKey(r)
          const key = this.getFlowKey(r) + r.zoneAlias + versionKey
          let existing = pivoted.find(p => p.key === key)

          if (!existing) {
            existing = {
              key: key,
              flowDate: this.interval === 'monthly' ? moment(r.flowDate).format('YYYY-MM-DD') : r.flowDate,
              tradingInterval: r.tradingInterval,
              zoneAlias: r.zoneAlias,
              version: r.version
            }
            pivoted.push(existing)
          }

          for (const t in this.selectedColumnConfig) {
            for (const c of this.selectedColumns[t]) {
              const col = this.columnConfig[t].available.find((i: { text: string, value: string}) => i.value === c)
              if (!existing[col.value]) {
                existing[col.value] = 0
              }
              existing[col.value] = (parseFloat(existing[col.value]) + parseFloat(r[col.value])).toFixed(9)
            }
          }
        }
        return pivoted
      }
    }

    return recs
  }

  public get columnHeaders () {
    const headers: Array<{ text: string, align?: string, sortable?: boolean, value: string, exportType?: string }> = [{ text: 'Flow Date', align: 'center', sortable: true, value: 'flowDate', exportType: 'date' }]

    if (this.interval === 'hourly') {
      headers.push({ text: 'Hour', align: 'center', sortable: true, value: 'tradingInterval' })
    }

    if (this.mode !== 'zonal') {
      headers.push({ text: 'Zone', align: 'left', sortable: true, value: 'zoneAlias' })
    }

    for (const t in this.selectedColumnConfig) {
      for (const c of this.selectedColumns[t]) {
        const col = this.columnConfig[t].available.find((i: { text: string, value: string}) => i.value === c)
        if (this.mode === 'zonal') {
          for (const z of this.zones) {
            headers.push({
              text: z + ' - ' + (this.fetchType === 'CO' ? '(' + t + ') ' : '') + col.text,
              value: z + col.value,
              align: 'right',
              sortable: true
            })
          }
          headers.push({
            text: (this.fetchType === 'CO' ? '(' + t + ') ' : '') + col.text + ' Total',
            value: col.value + 'Total',
            align: 'right',
            sortable: true
          })
        } else if (this.mode === 'compare') {
          for (const p of this.selectedPeriods) {
            headers.push({
              text: p + ' - ' + (this.fetchType === 'CO' ? '(' + t + ') ' : '') + col.text,
              value: p + col.value,
              align: 'right',
              sortable: true
            })
          }
          headers.push({
            text: (this.fetchType === 'CO' ? '(' + t + ') ' : '') + col.text + ' Difference',
            value: col.value + 'Difference',
            align: 'right',
            sortable: true
          })
        } else {
          headers.push({
            text: (this.fetchType === 'CO' ? '(' + t + ') ' : '') + col.text,
            value: col.value,
            align: 'right',
            sortable: true
          })
        }
      }
    }

    return headers
  }

  private get zones () {
    return this.recs.map(f => f.zoneAlias).filter((t, i, s) => s.indexOf(t) === i).sort()
  }

  private get settlementPeriods () {
    const periods: Array<string> = ['1) Original Version']

    for (const r of this.recs) {
      if (r.settlementNumber !== 1) {
        const label = this.getSettlementKey(r)
        if (periods.indexOf(label) === -1) {
          periods.push(label)
        }
      }
    }

    return periods
  }

  private get allZonesSelected () {
    if (!this.selectedZones.length) {
      return false
    }

    return this.selectedZones.length !== this.zones.length
  }

  private get selectedColumnConfig () : IStringDictionary<IStringIndexed> {
    if (this.fetchType === 'CO') {
      return this.columnConfig
    }
    return { [this.fetchType]: this.columnConfig[this.fetchType] }
  }

  private handleSelectAllZones () {
    if (this.selectAllZones) {
      Vue.set(this, 'selectedZones', this.zones)
    } else {
      Vue.set(this, 'selectedZones', [])
    }
  }

  @Watch('mode')
  private handleModeChange (newVal: string) {
    if (newVal === 'compare') {
      this.selectedPeriods = [this.selectedPeriods as string]
    } else {
      if (Array.isArray(this.selectedPeriods)) {
        this.selectedPeriods = this.selectedPeriods[0]
      }
    }
  }

  @Watch('selectedPresets.RT', { immediate: true })
  private handleRTPresetChange (newVal: string) {
    Vue.set(this.selectedColumns, 'RT', this.columnConfig.RT.presets[newVal])
  }

  @Watch('selectedPresets.DA', { immediate: true })
  private handleDAPresetChange (newVal: string) {
    Vue.set(this.selectedColumns, 'DA', this.columnConfig.DA.presets[newVal])
  }
}
