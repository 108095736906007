


























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import CustomerNote from '@/modules/customers/components/blocks/Note.vue'
import { Note } from '@/modules/shared/models/note'
import { Contact } from '@/modules/shared/models/contact'
import CustomerContacts from '@/modules/customers/components/blocks/Contacts.vue'
import UserChip from '@/modules/shared/components/UserChip.vue'
import CardList from '@/modules/shared/components/CardList.vue'
import EventBus from '@/plugins/eventbus'
import AddressLink from '@/modules/shared/components/AddressLink.vue'
import NameBar from '../../components/blocks/NameBar.vue'
import { CustomerFlags, TaxCategories } from '../../../shared/lists'

@Component({
  components: {
    'name-bar': NameBar,
    'customer-note': CustomerNote,
    'customer-contact': CustomerContacts,
    'user-chip': UserChip,
    'card-list': CardList,
    'address-link': AddressLink
  }
})
export default class CustomerOverview extends Vue {
  private errorMessage = ''

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  public get sicLabels () {
    return AppState.sicLabels
  }

  public get taxCatLabels () {
    return new TaxCategories()
  }

  public get customerFlags () {
    return new CustomerFlags()
  }

  private get canEditCustomer () {
    return AuthState.user.isAllowed('CUSTOMER.EDIT')
  }

  private get canCreateNote () {
    return AuthState.user.isAllowed('CUSTOMER.NOTES.CREATE')
  }

  private handleEditCustomerInfo () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-info', componentProps: { customer: CustomerState.customer } })
  }

  private handleNewNote () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-note', componentProps: { note: new Note(), noteList: CustomerState.customer.notes, saveMethod: 'SaveNote' }, dialogProps: { width: '100%' } })
  }

  private handleNewContact () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-contact', componentProps: { contact: new Contact() } })
  }

  private getAppUserName (userId: number): string {
    return AppState.cisUsers[userId].detailName
  }

  @Watch('loading', { immediate: true, deep: true })
  public handleCustChange () {
    if (CustomerState.loading.notes === null) {
      CustomerState.LoadNotes()
    }
  }
}
