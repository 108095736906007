import { AppState } from '@/stores/appStore'

import { StateAbbreviationsEnum, StatesAbbrevType, CommodityEnum } from '../../enums'
import { Commodities } from '../../lists'
import { IStringIndexed, IStringDictionary } from '../../types'

export class TipConfig {
  x: number|string = 0
  y: number|string = 0
  height: number|string = 10
  width: number|string = 10
  page = 0
  tag = ''
  tip = ''
}

export class UtilityFieldConfig {
  public static defaultFields: IStringIndexed = {
    accountNumber: {
      enabled: true,
      type: 'text',
      required: true,
      label: 'Account #',
      tip: null
    },
    meterNumber: {
      enabled: false,
      type: 'text',
      required: false,
      label: 'Meter #',
      tip: null
    },
    keyName: {
      enabled: false,
      type: 'text',
      required: false,
      label: 'Name Key',
      tip: null
    },
    rateClass: {
      enabled: false,
      type: 'select',
      required: false,
      label: 'Rate Class',
      tip: null
    },
    zone: {
      enabled: false,
      type: 'select',
      required: false,
      label: 'Zone',
      tip: null
    },
    capacityTag: {
      enabled: true,
      type: 'select',
      required: false,
      label: 'Cap Tag',
      tip: null
    },
    transmissionTag: {
      enabled: false,
      type: 'select',
      required: false,
      label: 'Trans Tag',
      tip: null
    },
    street: {
      enabled: true,
      type: 'text',
      required: true,
      label: 'Street',
      tip: null
    },
    street2: {
      enabled: true,
      type: 'select',
      required: false,
      label: 'Street',
      tip: null
    },
    city: {
      enabled: true,
      type: 'text',
      required: true,
      label: 'city',
      tip: null
    },
    state: {
      enabled: true,
      type: 'select',
      required: true,
      label: 'State',
      tip: null
    },
    zip: {
      enabled: true,
      type: 'text',
      required: true,
      label: 'Zip',
      tip: null
    },
    plus4: {
      enabled: false,
      type: 'text',
      required: false,
      label: '+4',
      tip: null
    }
  }

  public fields: IStringIndexed = {}
  public images: IStringDictionary<string> = {}

  constructor (props?: Partial<UtilityFieldConfig>) {
    if (props) {
      Object.assign(this, props)
    }
  }

  public static fromFormConfig (formConfigs: IStringIndexed) {
    const fieldConfig = formConfigs.fieldConfig
    const tipConfig = formConfigs.tipConfig
    const inst = new UtilityFieldConfig()

    for (const f in UtilityFieldConfig.defaultFields) {
      const field = Object.assign({}, UtilityFieldConfig.defaultFields[f])

      if (fieldConfig[f] !== undefined) {
        if (!fieldConfig[f].v) {
          field.enabled = false
        } else {
          field.enabled = true
          field.required = fieldConfig[f].r === 1

          if (fieldConfig[f].l) {
            field.label = fieldConfig[f].l
          }

          if (fieldConfig[f].t) {
            field.tip = tipConfig.fields[f]
          }
        }
      }
      inst.fields[f] = field
    }

    inst.images = tipConfig.images

    return inst
  }
}
