
































































































































import { Component, Vue, Prop, Watch, Ref, Mixins } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { InvoicesApi } from '@/modules/customers/api/invoices'
import EventBus from '@/plugins/eventbus'
import InvoiceDetail from './Invoices.vue'
import { InvoiceQueuesApi } from '@/modules/billing/api/queue/invoicequeues'
import { PaymentsApi } from '@/modules/customers/api/payments'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import { IStringDictionary } from '@/modules/shared/types'
import { AuthState } from '@/modules/auth/store'
import { BillGroupDeliveryMethodEnum, InvoiceHoldStatusEnum } from '@/modules/shared/enums'
import CustomerApi from '@/modules/customers/api/customer'
import { Note } from '@/modules/customers/models/customer/note'
import CustomerNote from '@/modules/customers/components/blocks/Note.vue'
// import NoteForm from '@/modules/billing/components/forms/Note.vue'
import EditCustomerNote from '@/modules/customers/components/forms/Note.vue'
import DialogHost from '../../../../shared/mixins/DialogHost.vue'

@Component({
  components: {
    invoices: InvoiceDetail,
    'customer-note': CustomerNote,
    'edit-customer-note': EditCustomerNote
  }
})
export default class InvoiceApprovalQueueItem extends Mixins(DialogHost) {
  @Prop({ required: true })
  private item!: CustomerInvoice

  private valRules: Rules = Rules
  private finalizeMode = ''

  private localItem: CustomerInvoice = new CustomerInvoice()
  private payments: IStringDictionary<number> = { Account: 0, 'Bill Group': 0, General: 0 }

  private processing = {
    load: false,
    payments: false,
    finalize: false,
    hold: false,
    customerHold: false,
    fetchNotes: false
  }

  private show = {
    editNote: false
  }

  private get canEditQueueItem () {
    return AuthState.user.isAllowed('BILLING.QUEUES.INVOICEAPPROVAL.EDIT')
  }

  private get holdLabel () {
    return this.localItem.customer?.flags.holdApprovals === 1 ? 'Held' : 'Off'
  }

  private itemIsHeld (item: CustomerInvoice) {
    return item.holdStatus === InvoiceHoldStatusEnum.HELD
  }

  private getAssemblyLog (messages: Array<string>) {
    return '* ' + messages.join('<br />* ')
  }

  private handleUnviewItem () {
    this.$router.push({ name: 'invoiceapprovalqueue' })
    Vue.set(this, 'localItem', new CustomerInvoice())
  }

  private async handleFinalize (mode: string) {
    try {
      this.finalizeMode = mode
      this.processing.finalize = false

      const resp = await InvoiceQueuesApi.performAction(this.localItem, this.finalizeMode)
      if (resp.message) {
        this.handleItemUpdated(resp.invoice)
        EventBus.$emit('app-snack', {
          message: resp.message,
          color: 'secondary',
          timeout: 5000
        })
      } else {
        this.$emit('item:finalize', resp.invoice)
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err,
        timeout: 5000
      })
    } finally {
      this.processing.finalize = false
    }
  }

  private handleItemUpdated (item: CustomerInvoice) {
    Vue.set(this, 'localItem', item.clone())
    this.$emit('item:update', item)
  }

  public async handleHoldUnhold (invoice: CustomerInvoice, holdStatus: number) {
    try {
      this.processing.hold = true

      invoice.holdStatus = holdStatus
      const updated = await InvoicesApi.savePartial(invoice, ['holdStatus'])
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.processing.hold = false
    }
  }

  public async handleCustomerHoldChange () {
    if (this.localItem.customer === undefined) {
      return
    }
    this.processing.customerHold = true

    // v-switch sets true/false
    this.localItem.customer.flags.holdApprovals = Number(this.localItem.customer.flags.holdApprovals)

    CustomerApi.savePartial(this.localItem.customer, ['flags'])
      .finally(() => (this.processing.customerHold = false))
  }

  private handleAddNote () {
    this.handleDialog({
      component: 'edit-customer-note',
      dialogProps: {
        width: 'auto'
      },
      props: {
        note: new Note({ customerId: this.item.customerId, categoryId: 166 }),
        noteList: this.item?.customer?.notes,
        saveMethod: 'SaveNote'
      }
    })
  }

  private handleNoteUpdate (note: Note) {
    this.localItem?.customer?.notes?.push(note)
    this.handleCloseDialog()
  }

  private handleDialogClose () {
    this.finalizeMode = ''
  }

  @Watch('item.id', { immediate: true })
  private async handleItemChange (newId: number, oldId?: number) {
    if (this.item.id && newId && newId !== oldId) {
      this.processing.load = true
      Vue.set(this, 'localItem', this.item.clone())
      InvoicesApi.load(this.item.customerId, this.item.id, undefined, { 0: 'invoices', invoices: { 0: 'lineItems', 1: 'account', lineItems: ['endDate', 'startDate', 'usage', 'chargeDetails'] } })
        .then(invoice => {
          Vue.set(this.localItem, 'invoices', invoice.invoices)
        })
        .catch(err => {
          EventBus.$emit('app-snack', {
            message: err,
            timeout: 5000
          })
        }).finally(() => { this.processing.load = false })

      this.processing.payments = true
      PaymentsApi.loadAll(this.item.customerId, {
        filters: {
          voidDate: { 'IS NULL': true },
          openBalance: { '>': '0' },
          billGroupId: {
            OR: {
              'IS NULL': true,
              '=': this.localItem.billGroupId
            }
          },
          accountId: {
            OR: {
              'IS NULL': true,
              IN: this.localItem.accountIds
            }
          }
        }
      })
        .then(pmts => {
          const balances = {
            Account: 0,
            'Bill Group': 0,
            General: 0
          }

          for (const p of pmts as Array<CustomerPayment>) {
            if (p.accountId) {
              balances.Account += p.openBalance
            } else if (p.billGroupId) {
              balances['Bill Group'] += p.openBalance
            } else {
              balances.General += p.openBalance
            }
          }
          Vue.set(this, 'payments', balances)
        })
        .finally(() => (this.processing.payments = false))
    }
  }

  private mounted () {

  }
}
