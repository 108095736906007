var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Reads")])],1),_c('v-divider'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-navigation",attrs:{"headers":_vm.headers,"items":_vm.reads,"sort-by":"endDate","sort-desc":true,"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"loading":_vm.loading,"loading-text":"Loading...please wait"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:_vm.rowClass(item),on:{"click":function($event){return _vm.handleSelectRead(item)},"mouseenter":function($event){return _vm.handleMouseOverRow(item)},"mouseleave":function($event){_vm.overlapping = []}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.senderTransactionId))]),_c('td',[_vm._v(_vm._s(item.meterNumber))]),_c('td',[_vm._v(_vm._s(item.start.format('MM/DD/YYYY')))]),_c('td',[_vm._v(_vm._s(item.end.format('MM/DD/YYYY')))]),_c('td',[_vm._v(_vm._s(Intl.NumberFormat('en-US').format(item.quantity)))]),_c('td',[_vm._v(_vm._s(item.isHistorical ? 'Y': 'N'))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.getCxlStatus(item) === 'X')?_c('v-icon',[_vm._v("$vuetify.icons.usagecanceled")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Canceled Original")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.getCxlStatus(item) === 'C')?_c('v-icon',[_vm._v("$vuetify.icons.usagecancel")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Cancel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.getCxlStatus(item) === 'R')?_c('v-icon',[_vm._v("$vuetify.icons.usagerebill")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Rebill")])])],1),_c('td',[(!item.isHistorical)?_c('router-link',{attrs:{"to":{ name: 'customerinvoicedetail', params: { invoiceId: _vm.getInvoice(item).id, detailId: _vm.getDetailInvoice(item).id } }}},[_vm._v(_vm._s(_vm.getInvoice(item).invoiceNumber))]):_vm._e()],1),(_vm.getDetailInvoice(item).id)?_c('td',[_vm._v(_vm._s(_vm.getDetailInvoice(item).invoiceDateMoment.format('MM/DD/YYYY')))]):_c('td'),(_vm.getDetailInvoice(item).id)?_c('td',[_vm._v(_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(_vm.getDetailInvoice(item).commodityTotal)))]):_c('td')])]}}])})],1)],1),(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1),(_vm.selectedItem)?_c('v-col',{staticClass:"align-center pb-0",attrs:{"cols":"12","lg":"12"}},[_c('usage-chart',{attrs:{"account":_vm.account,"data":_vm.interval,"loading":_vm.loadingInterval,"chartTitle":"Read Shape"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }