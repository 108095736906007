






































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import moment from 'moment'
import invoiceApi from '@/modules/finance/api/payments'
import dtExporter from '@/modules/shared/datatablehelper'

export interface IFinanceAchHistoryTrans {
  [key: string]: number|string;
  id: number;
  fileId: number;
  paymentId: number;
  statusLabel: string;
  transationDate: string;

  customerId: number;
  customerName: string;
  customerStatus: string;

  invoiceId: number;
  invoiceNumber: string;
  dueDate: string;
  amount: number;

  pmtInstitution: string;
  pmtAccountNumber: string;
}

@Component
export default class AchHistory extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private showAllFields = false
  private saving = false
  private loading = false
  private loaded = false

  private draftAmounts: INumericDictionary<number> = {}

  private menu = {
    from: false,
    to: false,
    ach: false
  }

  private from = moment().format('YYYY-MM-DD')
  private to = moment().format('YYYY-MM-DD')
  private achDate = moment().add(1, 'day').format('YYYY-MM-DD')

  private headers: Array<IStringIndexed> = [
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName' },
    { text: 'Customer Status', align: 'left', sortable: true, value: 'customerStatus', width: 100 },
    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber', width: 150 },
    { text: 'Due Date', align: 'center', sortable: true, value: 'dueDate', width: 150, exportType: 'date' },
    { text: 'Bank', align: 'left', sortable: true, value: 'pmtInstitutionName' },
    { text: 'Account #', align: 'center', sortable: true, value: 'pmtAccountNumber' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount', width: 150, exportType: 'money' },
    { text: 'Sent', align: 'center', sortable: true, value: 'transactionDate', width: 150, exportType: 'date' },
    { text: 'Status', align: 'center', sortable: true, value: 'statusLabel', width: 180 }
  ]

  private resultsSearch = ''

  private transactions: Array<IFinanceAchHistoryTrans> = []

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loaded = true
      this.loading = true
      this.transactions = []
      this.transactions = await invoiceApi.loadHistoryAch({ from: this.from, to: this.to }) as Array<IFinanceAchHistoryTrans>
      Vue.set(this, 'draftAmounts', {})
      this.transactions.forEach(i => {
        if (!i.haltPayments && !i.errorMessage) {
          Vue.set(this.draftAmounts, i.invoiceId, i.balance)
        }
      })
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private async handleQueueDraft () {
    try {
      this.saving = true
      const pmts = await invoiceApi.queueAchPayments(this.draftAmounts, this.achDate)
      this.handleSearch()
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.saving = false
    }
  }

  private handleExportList () {
    dtExporter(this.headers, this.transactions, 'Historic Transactions', 'ACH-History-from-' + this.from + '-to-' + this.to + '-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
