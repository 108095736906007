





































































import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import { UserGroupsEnum } from '@/modules/shared/enums'
import { TaxExemptForm } from '@/modules/customers/models/customer/taxexemptform'
import { TaxExemptFormsApi } from '../../api/taxexemptforms'
import SelectServiceAccounts from '@/modules/shared/components/forms/SelectServiceAccounts.vue'
import Customer from '../../models/customer'

@Component({
  components: {
    'service-accounts': SelectServiceAccounts
  }
})
export default class TaxExemptFormDetail extends Vue {
  @Prop({ required: true })
  private taxExemptFormId!: number

  @Prop({ default: 'finance' })
  private context!: string

  private loadingFile = false
  private loadingAccounts = false
  private errorMessage = ''

  private get dataservicesGroupId () {
    return UserGroupsEnum.DATA_SERVICES
  }

  private get item (): TaxExemptForm {
    const item = CustomerState.customer.taxExemptForms.find(i => i.id === this.taxExemptFormId)
    if (item) {
      return item
    }
    return new TaxExemptForm()
  }

  private get customer (): Customer {
    return CustomerState.customer
  }

  private async handleItemEdited () {
    const updatedItem = await TaxExemptFormsApi.save(this.item)
    this.$emit('item:updated', updatedItem)
  }
}
