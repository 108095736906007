





























































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import AddressLink from '@/modules/shared/components/AddressLink.vue'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'
import { ElectricAccount as ElectricAccountModel } from '@/modules/shared/models/account/electric'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { CommodityEnum, CustomerTypeEnum, ServiceAccountStatusEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'
import { CustomerTypeToUtilityConfig } from '@/modules/shared/lists'
import { AuthState } from '@/modules/auth/store'
import Customer from '@/modules/customers/models/customer'
import { TaxExemptForm } from '@/modules/customers/models/customer/taxexemptform'

@Component({
  components: {
    'address-link': AddressLink
  }
})
export default class ServiceAccountInfo extends Vue {
  @Prop({ required: true })
  private account!: IAccount

  @Prop({ required: true })
  private customer!: Customer

  private errorMessage = ''
  private fieldErrors: IStringIndexed = []
  private loadingBrokerRec = false;

  private handleEditServiceAccount () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-serviceaccount', componentProps: { account: this.account, customerType: this.customer.type, customerId: this.customer.id } })
  }

  public getFieldErrors (name: string) {
    if (!this.fieldErrors[name]) {
      return false
    }
    return this.fieldErrors[name].join('<br />')
  }

  private get canEditServiceAccount () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.EDIT')
  }

  private get fieldConfig () {
    const mapConfig = new CustomerTypeToUtilityConfig()
    const customerType = mapConfig[CustomerState.customer.type]

    if (!AppState.utilities[this.account.utilityId]?.configs[customerType]) {
      return {}
    }

    return AppState.utilities[this.account.utilityId].configs[customerType].fields
  }

  public get datasource (): string {
    return 'Set on credential, but credential doesn\'t exist in CIS yet'
  }

  public get lossFactorName () {
    if (!this.account.lossFactorId) {
      return '<Rate Class Default>'
    }
    return AppState.utilities[this.account.utilityId]?.lossFactors[this.account.lossFactorId]?.voltage || 'No match'
  }

  public get flowColor () {
    switch (this.account.status) {
      case ServiceAccountStatusEnum.NEW:
        return 'blue--text'
      case ServiceAccountStatusEnum.PENDING_ENROLLMENT:
        return 'yellow--text'
      case ServiceAccountStatusEnum.ACTIVE:
        return 'green--text'
      case ServiceAccountStatusEnum.PENDING_DROP:
        return 'orange--text'
      case ServiceAccountStatusEnum.BAD:
        return 'red--text'
      default:
        return ''
    }
  }

  public get activeExemptionId () {
    CustomerState.LoadTaxExemptForms()
    const acctId: number = this.account ? this.account.id : 0
    const forms: TaxExemptForm[] = CustomerState.customer.taxExemptForms || []
    const acctForm = forms.find(c => c.serviceAccounts.indexOf(acctId) !== -1)
    return acctForm !== undefined ? acctForm.id : 0
  }
}
