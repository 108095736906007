import Vue from 'vue'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { RawLocation } from 'vue-router'
import store, { priorState } from '@/stores'
import router from '@/router'
import { AppState } from '@/stores/appStore'
import { SalesState } from '@/modules/sales/store'
import authApi, { IAuthLoginResponse } from '../api/auth'
import AppUser from '../models/user'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import serviceApi, { IAuthLoginResponse as ServiceLogin } from '../api/service'
import moment from 'moment'
import EventBus from '@/plugins/eventbus'
import { validateJsonToken } from '@/api/app'
import UsersApi from '@/modules/users/api/users'

export interface IAuthState {
  user: AppUser | null;
  authToken: string;
  refreshToken: string;
  logoutAt: string | Date;
  masterUser?: {
    user: AppUser;
    authToken: string;
    refreshToken: string;
    logoutAt: string | Date;
  };
  services: IStringDictionary<ServiceLogin & { logoutAt: string | Date }>
}

const emptyUser = new AppUser({
  id: 0,
  firstName: null,
  lastName: null,
  emailAddress: null,
  configuration: {}
})

@Module({ dynamic: true, store, name: 'AppUser' })
class AuthStateDef extends VuexModule implements IAuthState, IStringIndexed {
  [key: string]: any
  public user = emptyUser
  public authToken = ''
  public refreshToken = ''
  public logoutAt = new Date()
  public masterUser = {
    user: emptyUser,
    authToken: '',
    refreshToken: '',
    logoutAt: new Date(),
    configuration: {}
  }

  public services: IStringDictionary<ServiceLogin & { logoutAt: string|Date }> = {
    crm: {
      token: '',
      refreshToken: '',
      logoutAt: new Date()
    },
    accountbroker: {
      token: '',
      refreshToken: '',
      logoutAt: new Date()
    },
    billingengine: {
      token: '',
      refreshToken: '',
      logoutAt: new Date()
    },
    settlements: {
      token: '',
      refreshToken: '',
      logoutAt: new Date()
    }
  }

  constructor (module: any) {
    super(module)

    if (priorState && priorState.AppUser) {
      for (const p in priorState.AppUser) {
        switch (p) {
          case 'user':
            this.user = new AppUser(priorState.AppUser[p])
            break
          case 'logoutAt':
            this.logoutAt = new Date(priorState.AppUser[p])
            break
          case 'masterUser':
            this.masterUser.user = new AppUser(priorState.AppUser[p].user)
            this.masterUser.authToken = priorState.AppUser[p].authToken
            this.masterUser.refreshToken = priorState.AppUser[p].refreshToken
            this.masterUser.logoutAt = new Date(priorState.AppUser[p].logoutAt)
            break
          case 'services':
            for (const s in priorState.AppUser[p]) {
              priorState.AppUser[p][s].logoutAt = new Date(priorState.AppUser[p][s].logoutAt)
              this[p][s] = priorState.AppUser[p][s]
            }
            break
          default:
            this[p] = priorState.AppUser[p]
        }
      }
      // Commented out for now. Only needed if the main token is extended to more than a few seconds.
      // Otherwise, the call to get all of the app users will trigger this anyways
      // this.RefreshLogin()
    }
  }

  @Mutation
  private LOGIN_USER (tokenData: IAuthLoginResponse) {
    const token = JSON.parse(atob(tokenData.token.split('.')[1]))
    const now = new Date()
    now.setTime(token.exp * 1000)

    this.user = new AppUser(token.user)
    this.authToken = tokenData.token
    this.refreshToken = tokenData.refreshToken
    this.logoutAt = now
  }

  @Mutation
  private LOGOUT_USER () {
    this.user = emptyUser
    this.authToken = ''
    this.refreshToken = ''
    this.logoutAt = new Date()
    this.activities = []
  }

  @Mutation
  private UPDATE_PREFERENCE (payload: {preferenceName: string; value: any}) {
    const { preferenceName, value } = payload
    const parts = preferenceName.split('.')
    const last: any = parts.pop()
    let ref: any = this.user.preferences
    for (const n of parts) {
      ref = ref[n]
    }
    Vue.set(ref, last, value)
  }

  @Mutation
  private STORE_MASTER_USER () {
    this.masterUser.user = this.user
    this.masterUser.authToken = this.authToken
    this.masterUser.refreshToken = this.refreshToken
    this.masterUser.logoutAt = this.logoutAt
  }

  @Mutation
  private RESTORE_MASTER_USER () {
    this.user = this.masterUser.user
    this.authToken = this.masterUser.authToken
    this.refreshToken = this.masterUser.refreshToken
    this.logoutAt = this.masterUser.logoutAt

    this.masterUser.user = emptyUser
    this.masterUser.authToken = ''
    this.masterUser.refreshToken = ''
    this.masterUser.logoutAt = new Date()
  }

  @Mutation
  private SET_USER_PERMISSIONS (permissions: IStringIndexed) {
    Vue.set(this.user, 'acl', permissions)
  }

  @Mutation
  private LOGIN_SERVICE (payload: { serviceName: string, tokenData: ServiceLogin }) {
    const token = JSON.parse(atob(payload.tokenData.token.split('.')[1]))
    const now = new Date()
    now.setTime(token.exp * 1000)

    Vue.set(this.services, payload.serviceName, { ...payload.tokenData, logoutAt: now })
  }

  @Action({ rawError: true })
  public async Login (userInfo: { username: string; password: string }) {
    const tokenData: IAuthLoginResponse = await authApi.login(userInfo.username, userInfo.password)
    this.LOGIN_USER(tokenData)

    const permissions: IStringIndexed = await UsersApi.loadPermissions(this.user.id!, true)
    this.SET_USER_PERMISSIONS(permissions)

    AppState.SetLayout('app')
    AppState.CacheAppData(true)

    let redirectTo: RawLocation = '/'
    if (router.currentRoute.query.redirect && router.currentRoute.query.redirect !== '/') {
      redirectTo = router.currentRoute.query.redirect as RawLocation
    }
    router.replace(redirectTo)
  }

  @Action({ rawError: true })
  public async RefreshLogin () {
    if (!this.refreshToken || !this.refreshToken.length) {
      throw new Error('Unauthenticated')
    }

    try {
      const tokenData = await authApi.refresh(this.refreshToken)
      this.LOGIN_USER(tokenData)

      const permissions: IStringIndexed = await UsersApi.loadPermissions(this.user.id!, true)
      this.SET_USER_PERMISSIONS(permissions)

      AppState.CacheAppData(false)
    } catch (error) {
      AuthState.Logout()
    }
  }

  @Action({ rawError: true })
  public Logout () {
    this.LOGOUT_USER()
    AppState.SetLayout('bare')
    AppState.ClearCacheData()
    SalesState.ClearCacheData()
  }

  @Action({ rawError: true })
  public UpdatePreference (payload: {preferenceName: string; value: any}) {
    this.UPDATE_PREFERENCE(payload)
  }

  @Action({ rawError: true })
  public async Impersonate (userId: number) {
    const tokenData: IAuthLoginResponse = await authApi.impersonate(userId)
    this.STORE_MASTER_USER()

    this.LOGIN_USER(tokenData)

    const permissions: IStringIndexed = await UsersApi.loadPermissions(this.user.id!, true)
    this.SET_USER_PERMISSIONS(permissions)

    AppState.CreateMenu()
  }

  @Action({ rawError: true })
  public async StopImpersonation () {
    this.RESTORE_MASTER_USER()
    AppState.CreateMenu()
  }

  @Action({ rawError: true })
  public async ServiceLogin (serviceName: string) {
    const tokenData: ServiceLogin = await serviceApi.login(serviceName)

    this.LOGIN_SERVICE({ serviceName, tokenData })
  }

  @Action({ rawError: true })
  public async RefreshServiceLogin (serviceName: string) {
    const tokenData: ServiceLogin = await serviceApi.refresh(serviceName, this.services[serviceName].refreshToken)
    this.LOGIN_SERVICE({ serviceName, tokenData })
  }

  public get isLoggedIn (): boolean {
    return this.authToken.length > 0 && this.logoutAt > new Date()
  }

  public get refreshTokenValid (): boolean {
    return validateJsonToken(this.refreshToken)
  }

  public get isImpersonating (): boolean {
    return this.masterUser.user.id! > 0
  }
}

export const AuthState = getModule(AuthStateDef)
