




































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { mask } from 'vue-the-mask'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { ListsEnum } from '@/modules/shared/enums'
import { AppState } from '@/stores/appStore'
import { Contract } from '@/modules/customers/models/customer/contract'
import { BillMethods } from '@/modules/shared/lists'

@Component({
  directives: {
    mask
  }
})
export default class ContractBillingFormFields extends Vue {
  @PropSync('contract', {
    required: true,
    default: () => new Contract()
  })
  private localContract!: Contract

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  private products = mapObjectVuetifySelect(AppState.listsByName[ListsEnum.PRODUCTS_ELECTRIC]!, true)

  private billMethods = mapObjectVuetifySelect(new BillMethods())

  private get fieldRules () : IStringDictionary<Array<Function>> {
    const rules: IStringDictionary<Array<Function>> = {
      productId: [Rules.required()],
      term: [Rules.required()],
      netDueDays: [Rules.required()]
    }

    for (const p in this.localContract.validations) {
      if (!rules[p]) {
        rules[p] = []
      }

      rules[p] = rules[p].concat(...this.localContract.validations[p])
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {
    if (this.products.length === 1) {
      this.localContract.productId = this.products[0].value as number
    }
  }
}
