

















import { Component, Vue } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import Rules from '@/plugins/validations'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import { SearchState } from '../../store'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class SearchForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private searching = false

  private searchTerm = ''

  public async handleSearch () {
    try {
      this.searching = true
      await SearchState.Search({ term: this.searchTerm })
      this.$emit('submit')
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.searching = false
    }
  }

  private beforeMount () {
    if (SearchState.searchParam.term) {
      this.searchTerm = SearchState.searchParam.term
    }
  }
}
