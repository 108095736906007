import esalesClient from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance } from 'axios'
import { BankAccountLog } from '@/modules/customers/models/customer/bankaccount/log'
import { IStringDictionary } from '@/modules/shared/types'

export class BankAccountLogsApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['customers', 'bankaccounts', 'logs']
    this.singular = 'log'
    this.plural = 'logs'
    this.factory = (...args: any) => new BankAccountLog(...args)
  }

  public async loadAll (customerId: number, accountId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<BankAccountLog>> {
    return await this.loadAllSpecific(arguments, config) as Array<BankAccountLog>
  }
}

export const BankAccountLogApi = new BankAccountLogsApi(esalesClient)
