import { Base } from './models/base'
import { IStringIndexed } from './types'
import moment from 'moment'

export function mapObjectVuetifySelect (obj: object, valueIsInt = false) {
  const res = []
  for (const key in obj) {
    res.push({ text: (<any>obj)[key], value: valueIsInt ? parseInt(key) : key })
  }
  return res
}

interface RangeItem {
  start: string,
  end: string,
  items: Array<Base>
}

export function monthListToRanges (list: Array<Base>, dateProp: string) {
  const ranges: Array<RangeItem> = []

  const listCopy = [...list].sort((a, b) => {
    const am = moment(a[dateProp])
    const bm = moment(b[dateProp])
    if (am.isBefore(bm)) {
      return -1
    } else if (am.isSame(bm)) {
      return 0
    } else {
      return 1
    }
  })

  if (!listCopy.length) {
    return ranges
  }

  const first: Base = listCopy.shift()!

  let rangeItem: RangeItem = {
    start: first[dateProp],
    end: first[dateProp],
    items: [first]
  }

  let startM = moment(rangeItem.start)
  for (const i of listCopy) {
    if (startM.add(1, 'month').isSame(moment(i[dateProp]))) {
      rangeItem.end = i[dateProp]
      rangeItem.items.push(i)
    } else {
      ranges.push(rangeItem)

      rangeItem = {
        start: i[dateProp],
        end: i[dateProp],
        items: [i]
      }
    }
    startM = moment(i[dateProp])
  }

  ranges.push(rangeItem)

  return ranges
}
