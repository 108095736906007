




























import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { CustomerState } from '@/modules/customers/store'
import { ICustomerContact } from '@/modules/customers/models/customer/contact'

@Component
export default class BillGroupGeneralFormFields extends Vue {
  @PropSync('billGroup', {
    required: true,
    default: () => new BillGroup()
  })
  private localBillGroup!: BillGroup

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  @Prop({ required: true, default: () => [] })
  private contacts!: Array<ICustomerContact>

  public get contactChoices () {
    return Object.values(this.contacts).map((u: ICustomerContact) => {
      return {
        text: u.fullName,
        value: u.id
      }
    })
  }

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      label: [Rules.required()],
      contactId: [Rules.required()]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {
  }
}
