import { CommodityAccount } from './base'

export class ElectricAccount extends CommodityAccount {
  public capacityTag: number|null = null
  public transmissionTag: number|null = null
  public zone = ''

  constructor (props?: Partial<ElectricAccount>) {
    super(props)
    if (props) {
      for (const p in props) {
        switch (p) {
          case 'capacityTag':
          case 'transmissionTag':
            this[p] = parseFloat(props[p]?.toString() ?? '0')
            break
          case 'zone':
            this.zone = props[p] ?? ''
            break
          default:
        }
      }
    }
  }

  public get icon () {
    return 'electricity'
  }

  public get loadFactor () {
    if (!this.capacityTag || !this.annualLoad) {
      return '-'
    }

    if ((this.annualLoad || 0) <= 0) {
      return 'No HU for '
    }

    return (this.annualLoad / (this.capacityTag * 8760)) * 100
  }
}
