























import { Component, Vue, Watch } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'

@Component({
  components: {
  }
})
export default class AccountSearchNamebarBase extends Vue {
  protected pageIcon = ''
  protected pageTitle = ''
  protected actionIcon = ''
  protected actionTitle = ''

  private accountSearch = CustomerState.accountSearchFilter

  @Watch('accountSearch')
  private handleAccountSearch () {
    CustomerState.UpdateAccountSearch(this.accountSearch)
  }
}
