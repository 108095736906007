











































































import { Component, Vue, Prop, Watch, Provide, PropSync } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import EventBus from '@/plugins/eventbus'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import UsageChart from '@/modules/customers/components/blocks/serviceaccount/detail/UsageChart.vue'
import { IStringIndexed } from '@/modules/shared/types'
import AccountsApi from '@/modules/customers/api/accounts'
import { MeterRead } from '@/modules/shared/models/account/meterread'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { AccountInvoice } from '@/modules/customers/models/account/invoice'

@Component({
  components: {
    'usage-chart': UsageChart
  }
})
export default class MeterReadList extends Vue {
  @Prop({ required: true })
  private account!: IAccount

  @Prop({ default: 'customer' })
  private context!: string

  private errorMessage = ''
  private loading = false

  private reads: Array<MeterRead> = []
  private interval: IStringIndexed = {}
  private selectedItem: MeterRead | null = null
  private loadingInterval = false
  private overlapping: Array<string> = []

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Transaction ID', align: 'left', sortable: true, value: 'senderTransactionId' },
    { text: 'Meter #', align: 'left', sortable: true, value: 'meterNumber' },
    { text: 'Start Date', align: 'left', sortable: true, value: 'startDate' },
    { text: 'End Date', align: 'left', sortable: true, value: 'endDate' },
    { text: 'Quantity', align: 'left', sortable: true, value: 'quantity' },
    { text: 'Historical', align: 'left', sortable: true, value: 'isHistorical' },
    { text: 'Orig/Cxl/Re', align: 'left', sortable: true, value: 'origCxlRebill' },
    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber' },
    { text: 'Invoice Date', align: 'left', sortable: true, value: 'invoiceDate' },
    { text: 'Charge', align: 'left', sortable: true, value: 'invoiceCharge' }
  ]

  private async handleMouseOverRow (item: MeterRead) {
    this.overlapping = this.reads.filter(r => r.startDate === item.startDate && r.meterNumber === item.meterNumber && r.isHistorical === 0 && item.isHistorical === 0)
      .map((v) => v.senderTransactionId)
  }

  private rowClass (item: MeterRead) {
    return this.overlapping.indexOf(item.senderTransactionId) === -1 ? '' : 'overlapping'
  }

  private async handleSelectRead (item: MeterRead) {
    this.selectedItem = item
    if (item) {
      try {
        this.loadingInterval = true
        this.interval = {}

        this.interval = await AccountsApi.loadSummaryShape(item)
      } catch (err) {
        EventBus.$emit('app-snack', { message: err })
      } finally {
        this.loadingInterval = false
      }
    }
  }

  private getInvoice (item: MeterRead) {
    const inv = CustomerState.customer.invoices.find(s => s.invoices.find(i => Object.values(i.lineItems).find(l => l.usageId === item.id)))
    if (inv) {
      return inv
    }
    return new CustomerInvoice()
  }

  private getDetailInvoice (item: MeterRead) {
    const detailInv = this.getInvoice(item).invoices.find((i: AccountInvoice) => Object.values(i.lineItems).find(l => l.usageId === item.id))
    if (detailInv) {
      return detailInv
    }
    return new AccountInvoice()
  }

  private getCxlStatus (item: MeterRead) {
    if (item.isCancel) {
      return 'C'
    }

    if (this.reads.findIndex(r => r.cancels(item)) !== -1) {
      return 'X'
    }

    if (this.reads.findIndex(r => item.rebills(r)) !== -1) {
      return 'R'
    }

    return 'O'
  }

  @Watch('account.id', { deep: true, immediate: true })
  protected handleAccountChange () {
    this.loadReads()
  }

  protected async loadReads () {
    try {
      if (this.account.id === undefined) {
        return
      }
      this.loading = true

      if (this.context === 'customer') {
        if (!this.account.reads?.length) {
          await CustomerState.LoadSummaryReads(this.account)
        }
      } else {
        AccountsApi.loadSummaryReads(this.account.id)
          .then(reads => {
            this.account.reads = reads
          })
          .catch(err => {
            EventBus.$emit('app-snack', {
              message: err,
              timeout: 5000
            })
          })
      }
      this.reads = this.account.reads
    } catch (err) {
      EventBus.$emit('app-snack', { message: err })
    } finally {
      this.loading = false
    }
  }

  public mounted () {
    this.loadReads()
    if (CustomerState.loading.invoices === null) {
      if (this.context === 'customer') {
        CustomerState.LoadInvoices()
      }
    }
  }
}
