





































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { Contract } from '@/modules/customers/models/customer/contract'

@Component
export default class ContractGeneralFormFields extends Vue {
  @PropSync('contract', {
    required: true,
    default: () => new Contract()
  })
  private localContract!: Contract

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  private menu = {
    dateSigned: false,
    startMonth: false
  }

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      dateSigned: [Rules.required()],
      startMonth: [Rules.required()]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  @Watch('localContract.startMonth')
  private handleStartMonthUpdate () {
    if (this.localContract.startMonth.length === 7) {
      this.localContract.startMonth += '-01'
    }
  }
}
