import { AppState } from '@/stores/appStore'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { CreditCheckApprovalEnum, CreditCheckStatusEnum, ListsEnum } from '@/modules/shared/enums'
import { CreditCheckApprovals, CreditCheckStatuses } from '@/modules/shared/lists'
import { IStringIndexed } from '@/modules/shared/types'

export interface ICreditCheck extends IBaseModel {
  id: number;
  crmOppId: number;
  customerId: number;
  requestedAt: string;
  status: keyof CreditCheckStatuses;
  approval: keyof CreditCheckApprovals;
  data: {
    businessName: string,
    salesId: string,
    salesPerson: string,
    expectedStartMonth: string,
    desiredPaymentMethodLabel: string,
    taxExempt: number,
    crmAccountIds: Array<number>,
    crmFileIds: Array<number>,
    notes: Array<IStringIndexed>,
    contact: IStringIndexed,
    address: IStringIndexed
  };
  fields: IStringIndexed;
}

export class CreditCheck extends Base implements ICreditCheck {
  public static statuses = new CreditCheckStatuses()

  public id = 0
  public crmOppId = 0
  public customerId = 0
  public status = CreditCheckStatusEnum.NEW
  public requestedAt = ''
  public approval = CreditCheckApprovalEnum.NEW
  public data = {
    businessName: '',
    salesId: '',
    salesPerson: '',
    taxExempt: 0,
    expectedStartMonth: '',
    crmAccountIds: [],
    crmFileIds: [],
    cisFileIds: [],
    notes: [],
    contact: {},
    address: {},
    actualRating: '',
    desiredPaymentMethodLabel: ''
  }

  public fields = {}

  constructor (props?: Partial<ICreditCheck>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'crmOppId':
          case 'customrId':
          case 'status':
          case 'approval':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get statusLabel () {
    return CreditCheck.statuses[this.status]
  }
}
