

































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { IStringIndexed } from '@/modules/shared/types'
import { Transaction } from '@/modules/customers/models/account/transaction'
import TransactionDetail from './TransactionDetail.vue'
import TransactionProcessLog from './TransactionProcessLog.vue'
import TransactionRequestForm from '@/modules/customers/components/forms/serviceaccount/TransactionRequestForm.vue'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { AuthState } from '@/modules/auth/store'
import moment from 'moment'
import { TransactionsApi } from '@/modules/customers/api/transactions'
import { TransactionRequestsApi } from '@/modules/customers/api/transactionrequests'
import { EsgTransactionStatusEnum, TransactionRequestTypeEnum } from '@/modules/shared/enums'

@Component({
  components: {
    'transaction-detail': TransactionDetail,
    'transaction-request': TransactionRequestForm,
    'transaction-processlog': TransactionProcessLog
  }
})
export default class TransactionList extends Vue {
  @Prop({ required: true })
  private account!: IAccount

  private errorMessage = ''
  private loading = false
  private search = ''

  private transactions: Array<Transaction> = []
  private detail: IStringIndexed = {}
  private selectedItem: Transaction | null = null

  private pending: Array<TransactionRequest> = []
  private loadingPending: boolean = false

  private requestStarted = false

  private dialog: boolean = false
  private dialogComponent = 'transaction-request'
  private dialogProps: IStringIndexed = {}
  private componentProps: IStringIndexed = {}

  private headers: Array<any> = [
    { text: 'ID', align: 'left', width: 100, sortable: false, value: 'id' },
    { text: 'Transaction Date', width: 200, align: 'center', sortable: true, value: 'date' },
    { text: 'Action ID', width: 110, align: 'left', sortable: true, value: 'actionId' },
    { text: 'Transaction ID', width: 350, align: 'left', sortable: true, value: 'transactionId' },
    { text: 'Detail', align: 'left', sortable: true, value: 'keyMessage' },
    { text: 'Actions', align: 'center', width: 100, sortable: false, value: 'actions' }
  ]

  private transactionTypesEnum = TransactionRequestTypeEnum

  @Watch('account.id', { immediate: true })
  protected handleAccountChange () {
    this.loadTransactions()
    this.loadPending()
  }

  protected get accountLoading () {
    return CustomerState.loading.accounts
  }

  @Watch('accountLoading')
  protected handleStateReload () {
    this.loadTransactions()
    this.loadPending()
  }

  protected async loadTransactions () {
    try {
      this.loading = true

      this.transactions = await TransactionsApi.loadAll(this.account.id)
    } catch (err) {
      EventBus.$emit('app-snack', { message: err })
    } finally {
      this.loading = false
    }
  }

  protected async loadPending () {
    if (!this.canViewRequests) {
      return
    }

    try {
      this.loadingPending = true

      this.pending = await TransactionRequestsApi.loadAll(this.account.id, { filters: { unsent: '1' } })
    } catch (err) {
      EventBus.$emit('app-snack', { message: err })
    } finally {
      this.loadingPending = false
    }
  }

  protected get mergedTransactionList () {
    const merged = [...this.transactions]

    this.pending.forEach(pend => {
      var transDate = pend.scheduledAt
      if (!transDate) {
        transDate = moment().format('YYYY-MM-DD HH:mm:ss')
      }

      merged.push(new Transaction({
        id: '[PENDING ID: ' + pend.id + ']',
        actionId: '[' + pend.typeLabel + ']',
        transactionDateTime: moment(transDate, 'YYYY-MM-DD HH:mm:ss').unix(),
        transactionId: '[PENDING]',
        linkedReq: pend
      }))
    })
    return merged
  }

  private async inspectTransaction (item: Transaction) {
    this.selectedItem = item
    if (item) {
      try {
        if (item.actionId === '') {
          // no action means transRequest.. link from tr to correct item in pending
          if (this.canEditRequests) {
            this.handleEdit(item.linkedReq!)
          }
          return
        }

        this.handleDialog({
          component: 'transaction-detail',
          props: {
            title: 'Transaction Detail: ' + item.transactionId,
            loadDetails: { accountId: this.account.id, transId: item.id },
            isDialog: true
          }
        })
      } catch (err) {
        EventBus.$emit('app-snack', { message: err })
      }
    }
  }

  public async startRequest (type: number) {
    this.requestStarted = true
    this.handleDialog({
      component: 'transaction-request',
      props: {
        request: new TransactionRequest({
          transactionType: type,
          serviceAccountId: this.account.id,
          details: {
            source: 'CIS'
          }
        }),
        account: this.account,
        isDialog: true
      }
    })
  }

  public async cancelRequest () {
    this.requestStarted = false
  }

  public mounted () {
  }

  private handleEdit (transReq: TransactionRequest) {
    this.dialog = true
    this.handleDialog({
      component: 'transaction-request',
      props: {
        request: transReq,
        account: this.account,
        isDialog: true
      }
    })
  }

  private handleDialog (dialogConfig: IStringIndexed) {
    this.dialogComponent = dialogConfig.component
    this.componentProps = dialogConfig.props || {}
    this.dialogProps = dialogConfig.dialogProps || { maxWidth: '50%', minHeight: '80%' }

    this.dialog = true
  }

  private handleCloseDialog () {
    this.dialog = false
    if (this.dialogComponent === 'transaction-request') {
      this.loadPending()
    }
    this.dialogComponent = ''
  }

  private handleViewProcessingLog (item: Transaction) {
    this.handleDialog({
      component: 'transaction-processlog',
      dialogProps: {
        width: '100%'
      },
      props: {
        title: 'Processing Log',
        transaction: item,
        isDialog: true
      }
    })
  }

  private rowClass (item: Transaction) {
    if (item.status >= EsgTransactionStatusEnum.ERROR_GENERAL) {
      return 'red lighten-3'
    }
    if (item.status === EsgTransactionStatusEnum.PARTIAL) {
      return 'yellow lighten-5'
    }

    if (item.status === EsgTransactionStatusEnum.PROCESSED) {
      return ''
    }

    return 'info lighten-5'
  }

  private get canRequestHu () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.TRANSACTIONREQUESTS.HU')
  }

  private get canRequestChange () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.TRANSACTIONREQUESTS.CHANGE')
  }

  private get canRequestEnrollment () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.TRANSACTIONREQUESTS.ENROLLMENT')
  }

  private get canRequestDrop () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.TRANSACTIONREQUESTS.DROP')
  }

  private get canEditRequests () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.TRANSACTIONREQUESTS.EDIT')
  }

  private get canViewRequests () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.TRANSACTIONREQUESTS.VIEW')
  }
}
