







































import { Component, Vue, Mixins } from 'vue-property-decorator'
// import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import moment from 'moment'
import Rules from '@/plugins/validations'
import { File as FileModel } from '@/modules/shared/models/file'
import ReportsApi from '@/modules/finance/api/reports'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'

@Component
export default class AniReportForm extends Mixins(DownloadFile) {
private errorMessage = ''
private loading: boolean = false
private from: string = moment().subtract('7', 'd').format('YYYY-MM-DD')
private to: string = moment().subtract('1', 'd').format('YYYY-MM-DD')
private valRules: Rules = Rules

private menu = {
  from: false,
  to: false
}

public get companies () {
  return [
    'Actual Energy',
    'Truelight'
  ]
}

public get reportTypes () {
  return [
    'Daily',
    'Hourly'
  ]
}

public async onSubmit () {
  if (this.loading) {
    return
  }
  this.errorMessage = ''
  this.loading = true

  if (!moment(this.to).isAfter(this.from)) {
    this.errorMessage = '"To" date must be later than "From" date'
  }

  if (!moment(this.from).isAfter('2020-01-01')) {
    this.errorMessage = 'Actual Energy has no data prior to 2020'
  }

  const filters = {
    from: this.from,
    to: this.to
  }

  ReportsApi.fetchInvoiceOpenBalance(this.from, this.to)
    .then(file => {
      this.downloadFile(file.name, file.binaryContents, file.mimeType)
      // this.send()
    }).catch(err => {
      this.errorMessage = err.message
    }).finally(() => {
      this.loading = false
    })
}
}
