




























import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { CustomerTypes, TaxCategories } from '@/modules/shared/lists'
import { AppState } from '@/stores/appStore'
import { ListsEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'
import Customer from '@/modules/customers/models/customer'
import Rules from '@/plugins/validations'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'

@Component
export default class CustomerClassificationFormFields extends Vue {
  @PropSync('customer', {
    required: true,
    default: () => new Customer()
  })
  private localCustomer!: IStringIndexed

  @Prop({ default: () => null })
  private validations!: IStringIndexed|null

  private customerTypes = mapObjectVuetifySelect(new CustomerTypes(), true)
  private taxTypes = mapObjectVuetifySelect(new TaxCategories(), true)
  private sicCodes = mapObjectVuetifySelect(AppState.listsByDesc[ListsEnum.SIC]!, true)
  private sicSearch = ''

  public clearSicSearch () {
    this.$nextTick(() => {
      this.sicSearch = ''
    })
  }

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      type: [Rules.required()],
      taxClassification: [Rules.required()],
      sic: []
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {
  }
}
