
































































import { Component, Vue, Prop, Watch, Ref, Mixins } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import EventBus from '@/plugins/eventbus'
import { IStringDictionary } from '@/modules/shared/types'
import { AuthState } from '@/modules/auth/store'
import CustomerApi from '@/modules/customers/api/customer'
import { Note } from '@/modules/customers/models/customer/note'
import CustomerNote from '@/modules/customers/components/blocks/Note.vue'
import EditCustomerNote from '@/modules/customers/components/forms/Note.vue'
import DialogHost from '@/modules/shared/mixins/DialogHost.vue'
import { MeterRead } from '@/modules/shared/models/account/meterread'
import { UnbilledUsagesApi } from '@/modules/billing/api/queue/unbilledusage'
import MeterReadList from '@/modules/customers/components/blocks/serviceaccount/detail/MeterReadList.vue'
import AccountsApi from '@/modules/customers/api/accounts'
import { CommodityAccount } from '@/modules/shared/models/account/base'

@Component({
  components: {
    'customer-note': CustomerNote,
    'edit-customer-note': EditCustomerNote
  }
})
export default class UnbilledUsageQueueItem extends Mixins(DialogHost) {
  @Prop({ required: true })
  private item!: MeterRead

  private valRules: Rules = Rules
  private finalizeMode = ''

  private localItem: MeterRead = new MeterRead()

  private responseMessages: Array<string> = []

  private processing = {
    load: false,
    doAction: false,
    actionDone: false,
    customerHold: false
  }

  private get canEditQueueItem () {
    return this.localItem.p2cId === null
  }

  private get holdLabel () {
    return this.localItem.customer?.flags.holdApprovals === 1 ? 'Held' : 'Off'
  }

  private itemIsStaged (item: MeterRead) {
    return item.lineItemId !== null
  }

  private itemIsAssembled (item: MeterRead) {
    return item.lineItem?.invoice?.customerInvoiceId !== null
  }

  private handleUnviewItem () {
    this.$router.push({ name: 'unbilledusagequeue' })
    Vue.set(this, 'localItem', new MeterRead())
  }

  private async handleAction (item: MeterRead, action: string) {
    try {
      this.processing.doAction = false
      const resp = await UnbilledUsagesApi.performAction(this.localItem, action)
      if (resp.messages) {
        this.responseMessages = resp.messages
      } else {
        // todo: ideally update queue item / remove if resolved
        this.$emit('item:finalize', resp.item)
      }
    } catch (err) {
      this.responseMessages = [err]
    } finally {
      this.processing.doAction = false
      this.processing.actionDone = true
    }
  }

  private handleItemUpdated (item: MeterRead) {
    Vue.set(this, 'localItem', item.clone())
    this.$emit('item:update', item)
  }

  public async handleCustomerHoldChange () {
    if (this.localItem.customer === undefined) {
      return
    }
    this.processing.customerHold = true

    // v-switch sets true/false
    this.localItem.customer.flags.holdApprovals = Number(this.localItem.customer.flags.holdApprovals)

    CustomerApi.savePartial(this.localItem.customer, ['flags'])
      .finally(() => (this.processing.customerHold = false))
  }

  private handleDialogClose () {
    this.finalizeMode = ''
  }

  @Watch('item.id', { immediate: true })
  private async handleItemChange (newId: number, oldId?: number) {
    if (this.item.id && newId && newId !== oldId) {
      Vue.set(this, 'localItem', this.item.clone())
    }
  }

  private mounted () {

  }
}
