















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { IStringIndexed } from '@/modules/shared/types'
import { CreditCheckApi, CreditChecksApi } from '@/modules/finance/api/creditchecks'
import { CreditCheck } from '../../models/creditcheck'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { CreditCheckStatuses } from '@/modules/shared/lists'

@Component
export default class CreditQueue extends Vue {
  @Prop({ default: 0 })
  private itemId!: number

  private selectedItem: CreditCheck = new CreditCheck()

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Requested', align: 'center', width: 50, sortable: true, value: 'requestedAt' },
    { text: 'Status', align: 'center', width: 50, sortable: true, value: 'statusLabel' }
  ]

  private loading = false

  private items: Array<CreditCheck> = []

  private statusLabels = mapObjectVuetifySelect(new CreditCheckStatuses(), true)

  private showFilters = false
  private statusFilters: Array<number> = [0, 1]
  private listSearch: string = ''

  private get filteredItems () {
    return this.items.filter(r => {
      return this.statusFilters.indexOf(r.status) !== -1
    })
  }

  private handleClickRow (item: CreditCheck) {
    this.selectedItem = item
    this.$router.push({ name: 'creditqueueitem', params: { itemId: item.id.toString() } }).catch(() => {})
  }

  private handleItemUpdate (item: CreditCheck) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      Vue.set(this.items, idx, item)
      this.selectedItem = item
    }
  }

  private handleItemFinalize (item: CreditCheck) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      this.items.splice(idx, 1)
      this.selectedItem = new CreditCheck()
    }
  }

  private itemViewedClass (item: CreditCheck): string {
    if (item.id === this.itemId) {
      return 'blue lighten-3'
    }
    return ''
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  private beforeMount () {
    CreditChecksApi.loadAll()
      .then(items => {
        this.items = items
        if (this.itemId) {
          this.selectedItem = this.items.find(i => i.id === this.itemId) || new CreditCheck()
          if (this.selectedItem.id !== this.itemId) {
            this.$router.push({ name: 'creditqueue' })
          }
        }
      })
  }
}
