




































































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import BillGroupGeneralFormFields from '@/modules/customers/components/forms/billgroup/General.vue'
import BillGroupCommunicationFormFields from '@/modules/customers/components/forms/billgroup/Communication.vue'
import BillGroupPaymentFormFields from '@/modules/customers/components/forms/billgroup/Payment.vue'
import BillGroupServiceAccountsForm from '@/modules/customermanagement/components/forms/BillGroupServiceAccounts.vue'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import Rules from '@/plugins/validations'
import { INumericDictionary, INumericIndexed, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { BillGroupDeliveryMethodEnum, ContactTypeEnum, PaymentMethodEnum } from '@/modules/shared/enums'
import BillGroupBillingFormFields from '@/modules/customers/components/forms/billgroup/Billing.vue'

@Component({
  components: {
    'billgroup-general-fields': BillGroupGeneralFormFields,
    'billgroup-communication-fields': BillGroupCommunicationFormFields,
    'billgroup-billing-fields': BillGroupBillingFormFields,
    'billgroup-payment-fields': BillGroupPaymentFormFields,
    'billgroup-service-accounts': BillGroupServiceAccountsForm
  }
})
export default class CSQBillGroupsStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  @Prop({ required: true })
  private accounts!: Array<ElectricAccount>

  private noErrors: boolean = true
  private errorMessage = ''

  private selectedBillGroup: BillGroup|null = null

  private newId = -2

  private fieldLabels: IStringIndexed = {
    label: 'Label',
    contactId: 'Contact',
    paymentMethodId: 'Payment Method',
    bankAccountId: 'Bank Account',
    creditCardId: 'Credit Card',
    emailTo: 'Email Address',
    fax: 'Fax Number'
  }

  // private bgMap: INumericDictionary<Array<number>> = {}
  private accountMap: INumericDictionary<number> = {}

  private handleAddBillGroup () {
    this.localItem.customerShell!.billGroups.push(new BillGroup({
      id: this.newId,
      contactId: this.localItem.customerShell?.contacts.find(c => c.type === ContactTypeEnum.BILLING)?.id ??
        this.localItem.customerShell?.contacts.find(c => c.type === ContactTypeEnum.PRIMARY)!.id
    }))

    Vue.set(this, 'selectedBillGroup', this.localItem.customerShell!.billGroups.find(g => g.id === this.newId))
    this.newId--
  }

  // clone first bg for every unassigned account
  private handleAddEveryBillGroup () {
    const assignedIds = Array.prototype.concat(...Object.values(this.bgMap))
    const missing = this.localItem.customerShell?.accountIds.filter(a => !assignedIds.includes(a)) ?? []
    const cloneBg = this.localItem.customerShell!.billGroups[0]

    for (const a of missing) {
      const acctNum = this.accounts.find(acct => acct.id === a)?.accountNumber
      if (acctNum === undefined) {
        continue
      }
      const newBg = new BillGroup({
        ...cloneBg,
        id: this.newId,
        label: 'BG: ' + acctNum,
        accountIds: [a],
        p2cId: ''
      })

      this.localItem!.customerShell!.billGroups!.push(newBg)
      this.newId--

      // updates corresponding periodDetails
      this.handleAccountAssignment(newBg.accountIds, newBg.id)
    }
  }

  private handleEditBillGroup (group: BillGroup) {
    Vue.set(this, 'selectedBillGroup', group)
  }

  private getBillGroupClasses (group: BillGroup) {
    let classes = this.billGroupIsValid(group) !== true ? 'red lighten-3' : ''

    if (!classes.length && this.selectedBillGroup?.id === group.id) {
      classes += ' primary lighten-3'
    }

    if (group.id > 0) {
      classes += ' clickable'
    }

    return classes
  }

  private handleRemoveBillGroup (group: BillGroup) {
    const idx = this.localItem.customerShell!.billGroups.findIndex(c => c.id === group.id)
    if (idx !== -1) {
      this.localItem.customerShell!.billGroups.splice(idx, 1)
    }
    if (group.id === this.selectedBillGroup?.id) {
      Vue.set(this, 'selectedBillGroup', null)
    }
  }

  @Watch('localItem.customerShell.billGroups')
  private handleBillGroupListUpdate () {
    if (!this.localItem.customerShell?.billGroups.findIndex(g => g.id === this.selectedBillGroup?.id)) {
      Vue.set(this, 'selectedBillGroup', this.localItem.customerShell?.billGroups[0])
    }
  }

  @Watch('selectedBillGroup', { deep: true, immediate: true })
  private handleBillGroupSelect () {
    Vue.nextTick()
      .then(() => {
        if (this.$refs.billGroupForm) {
          (this.$refs.billGroupForm as Vue & { validate: () => boolean }).validate()
        }
      })
  }

  private billGroupIsValid (group: BillGroup) {
    const rules = this.getGroupRules(group)
    for (const p in rules) {
      const val = group[p]

      for (const r of rules[p]) {
        if (r(val) !== true) {
          return this.fieldLabels[p] + ': ' + r(val)
        }
      }
    }

    if (group.id < 0 && !this.bgMap[group.id].length) {
      return 'At least one account is required, otherwise remove the bill group'
    }
    return true
  }

  public get filteredAccounts () {
    return this.accounts.filter((a) => this.localItem.customerShell?.accountIds.includes(a.id))
  }

  private get stepValid () {
    this.errorMessage = ''
    if (!this.localItem.customerShell?.billGroups.length) {
      this.errorMessage = 'At least one bill group is required'
      return false
    }

    for (const b of this.localItem.customerShell.billGroups) {
      const isValid = this.billGroupIsValid(b)
      if (isValid !== true) {
        this.errorMessage = (b.label || '<No Label>') + ' has Errors - ' + isValid
        return false
      }
    }

    if (!(this.$refs.billGroupForm as Vue & { validate: () => boolean }).validate()) {
      this.errorMessage = 'Group has errors, see above'
      return false
    }

    const assignedIds = Array.prototype.concat(...Object.values(this.bgMap))
    const missing = this.localItem.customerShell.accountIds.filter(a => !assignedIds.includes(a))

    if (missing.length) {
      const acctNums = this.accounts.filter(a => missing.includes(a.id)).map(a => a.accountNumber).join(', ')
      this.errorMessage = 'The following accounts need to be assigned to a bill group: ' + acctNums
      return false
    }

    return true
  }

  private getGroupRules (group: BillGroup): IStringDictionary<Array<Function>> {
    return {
      label: [Rules.required()],
      contactId: [Rules.required()],
      paymentMethodId: [Rules.required()],
      bankAccountId: [Rules.requiredIf(group.paymentMethodId === PaymentMethodEnum.ACH ? '1' : '')],
      creditCardId: [Rules.requiredIf(group.paymentMethodId === PaymentMethodEnum.CREDIT_CARD ? '1' : '')],
      billDeliveryMethod: [Rules.required()],
      attnOverride: [],
      emailTo: [Rules.email(), Rules.requiredIf(group.billDeliveryMethod === BillGroupDeliveryMethodEnum.EMAIL ? '1' : '')],
      ccTo: [Rules.email()],
      bccTo: [Rules.email()],
      fax: [Rules.requiredIf(group.billDeliveryMethod === BillGroupDeliveryMethodEnum.FAX ? '1' : '')]
    }
  }

  public get bgMap () {
    const map: INumericDictionary<Array<number>> = {}

    for (const bg of this.localItem.customerShell!.billGroups) {
      map[bg.id] = []
    }

    for (const a of this.localItem.customerShell!.accounts) {
      for (const pd of a.periodDetails) {
        if (map[pd.billGroupId] && !map[pd.billGroupId].includes(a.id)) {
          map[pd.billGroupId].push(a.id)
        }
      }
    }
    return map
  }

  public get bankaccounts () {
    return this.localItem.customerShell!.bankaccounts.map(b => ({
      text: b.bankName + ' - ' + b.accountNumber,
      value: b.id
    }))
  }

  private handleAccountAssignment (newIds: Array<number>, currBgId?: number) {
    if (currBgId === undefined) {
      currBgId = this.selectedBillGroup!.id
      if (!currBgId) {
        return
      }
    }

    for (const a of this.localItem.customerShell!.accounts) {
      for (const pd of a.periodDetails) {
        if (pd.billGroupId === currBgId && !newIds.includes(a.id)) {
          pd.billGroupId = 0
        } else if (pd.billGroupId !== currBgId && newIds.includes(a.id)) {
          pd.billGroupId = currBgId
        }
      }
    }
  }

  private mounted () {

  }
}
