


















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { MeterReadsApi } from '@/modules/shared/api/meterreads'
import moment from 'moment'
import { AppState } from '@/stores/appStore'
import { TransactionRequestTypes } from '@/modules/shared/lists'
import { TransactionRequestTypeEnum } from '@/modules/shared/enums'

@Component({
  components: {
  }
})
export default class ScheduleTransactionField extends Vue {
  @Prop({ required: true })
  private account!: ElectricAccount

  @Prop({ default: TransactionRequestTypeEnum.ENROLLMENT })
  private transactionType!: keyof TransactionRequestTypes

  @Prop({ default: '' })
  public value!: string

  private localValue: string = ''
  private loading: boolean = false

  private menu = false
  private menuLabel = 'Wait to send [leave blank for immediate]'
  private delayIsSet = false

  private blackoutDates: Array<string> = []

  private isBlackedOut (val: string) {
    return this.blackoutDates.indexOf(val) === -1
  }

  private handleChange (newValue: string) : void {
    this.menu = false
    this.$emit('input', newValue)
    this.delayIsSet = newValue !== ''
  }

  @Watch('account.id', { immediate: true })
  private async handleAccountChange () {
    Vue.set(this, 'blackoutDates', [])
    if (this.account && this.account.id && this.account.readCycle?.length) {
      const utility = AppState.utilities[this.account.utilityId]

      const reads = await MeterReadsApi.loadAll({
        filters: {
          utilityId: this.account.utilityId,
          cycleCode: this.account.readCycle,
          period: { AND: { '>=': moment().format('YYYY-MM-01'), '<=': moment().add(12, 'month').format('YYYY-MM-01') } }
        }
      })

      let settingName = 'blackout_enrollment'
      if (this.transactionType === TransactionRequestTypeEnum.CHANGE) {
        settingName = 'blackout_change'
      }

      let setting = utility.getSetting(settingName) as IStringIndexed
      if (!setting) {
        if (settingName === 'blackout_enrollment') {
          setting = {
            dayType: 'business',
            before: 2,
            after: 0
          }
        } else {
          setting = {
            dayType: 'business',
            before: 2,
            after: 5
          }
        }
      }

      const blackouts: Array<string> = []
      for (const r of reads) {
        blackouts.push(...this.getRange(r.readDate, setting.before || 0, setting.after || 0, setting.dayType || 'calendar'))
      }

      Vue.set(this, 'blackoutDates', blackouts)
    }
  }

  private getRange (startDate: string, before: number, after: number, dayType: string) {
    const days = []
    if (dayType === 'calendar') {
      const from = moment(startDate, 'YYYY-MM-DD').subtract(before as number, 'days')
      const to = moment(startDate, 'YYYY-MM-DD').add(after as number || 0, 'days')
      do {
        days.push(from.format('YYYY-MM-DD'))
      } while (from.add(1, 'day').isSameOrBefore(to))
    } else { // business
      let from = moment(startDate, 'YYYY-MM-DD')
      do {
        days.push(from.format('YYYY-MM-DD'))
        if (from.isoWeekday() <= 5) {
          before--
        }
        from.subtract(1, 'days')
      } while (before >= 0)

      from = moment(startDate, 'YYYY-MM-DD').add(1, 'day')
      while (after > 0) {
        days.push(from.format('YYYY-MM-DD'))
        if (from.isoWeekday() <= 5) {
          after--
        }
        from.add(1, 'days')
      }
    }
    return days
  }
}
