import { Base } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'
import { ListBase } from '@/modules/shared/lists'

export enum CommissionPaymentStatusEnum {
  ['STATUS_ASSEMBLED'] = 0,
  ['STATUS_PAID'] = 1
}

export class CommissionPaymentStatii extends ListBase {
  readonly [CommissionPaymentStatusEnum.STATUS_ASSEMBLED] = 'Assembled'
  readonly [CommissionPaymentStatusEnum.STATUS_PAID] = 'Paid'
}

export interface ICommissionPayment extends IStringIndexed {
  id: number;
  salesId: number;
  glId: string;
  status: keyof CommissionPaymentStatii;
  payPeriod: string;
  totalPayment: number;
}

export default class CommissionPayment extends Base implements ICommissionPayment {
  public id = 0
  public salesId = 0
  public glId = ''
  public status = CommissionPaymentStatusEnum.STATUS_PAID
  public payPeriod = ''
  public totalPayment = 0

  constructor (props: Partial<ICommissionPayment> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'salesId':
          case 'status':
            props[p] = parseInt(props[p]!.toString())
            break
          case 'totalPayment':
            props[p] = parseFloat(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
