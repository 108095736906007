






























































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'
import DocumentForm from '@/modules/customermanagement/components/fulfillment/Document.vue'
import DocumentModel from '@/modules/customermanagement/models/document'
import DocumentTemplateModel, { TemplateBuildMethodsEnum, TemplateBuildMethods } from '@/modules/customermanagement/models/document/template'
import DocumentTemplateRuleModel from '@/modules/customermanagement/models/document/templaterule'
import documentApi from '@/modules/customermanagement/api/documentmanagement'

@Component({
  components: {
    documentinfo: DocumentForm
  }
})
export default class Document extends Vue {
  @Prop({ required: true })
  private documentId!: number

  @Prop({ default: () => new DocumentModel() })
  private document!: DocumentModel

  private localDocument: DocumentModel = new DocumentModel()

  private templates: Array<DocumentTemplateModel> = []
  private dialog: boolean = false
  private loading: boolean = false

  private localTemplate: DocumentTemplateModel|null = null

  private rules: Array<DocumentTemplateRuleModel> = []

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Version', align: 'left', sortable: true, value: 'version' }
  ]

  private ruleHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Field', align: 'left', sortable: true, value: 'field' },
    { text: 'Operator', align: 'left', sortable: true, value: 'operatorLabel' },
    { text: 'Match', align: 'left', sortable: true, value: 'compare' },
    { text: 'Edit', align: 'left', sortable: true, value: 'edit' }
  ]

  private editedItem: DocumentTemplateModel = new DocumentTemplateModel()

  private errorMessage: string = ''

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get canEdit () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.EDIT')
  }

  private get canViewTemplates () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.TEMPLATES.VIEW')
  }

  private get canAddTemplate () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.TEMPLATES.CREATE')
  }

  private get canEditTemplate () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.TEMPLATES.EDIT')
  }

  private get canViewTemplateRules () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.TEMPLATES.RULES.VIEW')
  }

  private get canAddTemplateRule () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.TEMPLATES.RULES.CREATE')
  }

  private get canEditTemplateRule () {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.TEMPLATES.RULES.EDIT')
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.text !== 'Actions' })
    }

    return headers
  }

  private get getRuleHeaders () {
    let headers = this.ruleHeaders

    if (this.isDense || !this.canEditTemplateRule) {
      headers = headers.filter(i => { return i.text !== 'Edit' })
    }

    return headers
  }

  private async handleEdit () {
    // this.dialog = true
  }

  private handleItemUpdated () {

  }

  private handleDialogClose () {
    this.dialog = false
  }

  private handleLoadTemplate (template: DocumentTemplateModel) {
    this.localTemplate = template
  }

  private handleEditTemplateInfo () {

  }

  private handleEditTemplateRule () {

  }

  private isPdf (buildMethod: keyof TemplateBuildMethods) {
    return buildMethod === TemplateBuildMethodsEnum.BUILD_METHOD_PDF_FILLED || buildMethod === TemplateBuildMethodsEnum.BUILD_METHOD_PHYSICAL_FILE
  }

  private isHtml (buildMethod: keyof TemplateBuildMethods) {
    return buildMethod === TemplateBuildMethodsEnum.BUILD_METHOD_HTML || buildMethod === TemplateBuildMethodsEnum.BUILD_METHOD_PDF
  }

  private async loadData () {
    this.errorMessage = ''
    this.loading = true
    try {
      if (this.document.id !== this.documentId) {
        this.localDocument = await documentApi.loadDocument(this.documentId)
      } else {
        this.localDocument = this.document
      }

      this.templates = await documentApi.loadDocumentTemplates(this.documentId)
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }

  public mounted () {
    this.loadData()
  }
}
