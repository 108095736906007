






































import { Component, Vue, PropSync } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'

@Component
export default class CustomerFlagFormFields extends Vue {
  @PropSync('flags', {
    required: true,
    default: () => {}
  })
  private localFlags!: IStringIndexed

  public mounted () {

  }
}
