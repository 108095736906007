



















import { Component, Vue, PropSync } from 'vue-property-decorator'
import ContractSalesCommission from '@/modules/customers/components/forms/contract/Commission.vue'
import { ContractSubmission } from '../../models/queue/contractsubmission'

@Component({
  components: {
    'commission-row': ContractSalesCommission
  }
})
export default class CSQCommissionsStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  private noErrors: boolean = true

  private mounted () {
  }
}
