import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { AuthState } from '@/modules/auth/store'
import { validateJsonToken } from '@/api/app'

const crmClient = axios.create({
  baseURL: process.env.VUE_APP_CRM_REST_URL
})

crmClient.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const now = new Date()

  if (!AuthState.services.crm.token.length || !validateJsonToken(AuthState.services.crm.refreshToken)) {
    await AuthState.ServiceLogin('crm')
  }

  if ((<Date>AuthState.services.crm.logoutAt).getTime() < (now.getTime() - 3)) {
    await AuthState.RefreshServiceLogin('crm')
  }

  config.headers.Authorization = 'Bearer ' + AuthState.services.crm.token
  return config
})

export default crmClient
