











































import { Component, Vue, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'

@Component
export default class QuoteSummary extends Vue {
  @Prop({ default: 'Unknown Type' })
  private title!: string

  @Prop({ required: true })
  private quote!: IStringIndexed
}
