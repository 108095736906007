import { AppState } from '@/stores/appStore'
import { Base } from './base'
import { StateAbbreviationsEnum, StatesAbbrevType, CommodityEnum } from '../enums'
import { Commodities } from '../lists'
import { UtilityFieldConfig } from './utility/fieldconfig'
import { IStringIndexed, IStringDictionary, INumericDictionary } from '../types'
import { LossFactor } from './utility/lossfactor'
import { RateClass } from './utility/rateclass'

export interface IUtility extends IStringIndexed {
  id: number;
  name: string;
  state: StatesAbbrevType;
  commodity: number;
  configs: IStringDictionary<UtilityFieldConfig>;
  rateClasses: INumericDictionary<RateClass>;
  zoneIds: Array<number>;
  validations: INumericDictionary<IStringDictionary<Array<{ regex: string; message: string }>>>;
  lossFactors: INumericDictionary<LossFactor>;
  settings: IStringDictionary<IStringIndexed|string|number>;
}

export class Utility extends Base implements IUtility {
  public id = 0
  public name = ''
  public state = StateAbbreviationsEnum.FL
  public commodity = CommodityEnum.E
  public configs: IStringDictionary<UtilityFieldConfig> = {}
  public rateClasses: INumericDictionary<RateClass> = {}
  public zoneIds: Array<number> = []
  public validations: INumericDictionary<IStringDictionary<Array<{ regex: string; message: string }>>> = {}
  public lossFactors: INumericDictionary<LossFactor> = {}
  public settings: IStringDictionary<IStringIndexed|string|number> = {}
  public cisCutoverAt!: ''

  constructor (props?: Partial<IUtility>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'rateClasses':
            if (props[p]) {
              const factors: INumericDictionary<RateClass> = {}
              for (const f in props[p]!) {
                factors[props[p]![f].id] = new RateClass(props[p]![f])
              }
              props[p] = factors
            }
            break
          case 'lossFactors':
            if (props[p]) {
              const factors: INumericDictionary<LossFactor> = {}
              for (const f in props[p]!) {
                factors[props[p]![f].id] = new LossFactor(props[p]![f])
              }
              props[p] = factors
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get displayName () {
    return '(' + this.state + ') ' + this.name
  }

  public hasCommodity (commodity: keyof Commodities) {
    return this.commodity & <number>commodity
  }

  public getSetting (name: string) {
    if (this.settings[name] || false) {
      return this.settings[name]
    }
    return null
  }
}
