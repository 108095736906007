import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import abClient from '@/api/clients/accountbroker'
import { Method, AxiosResponse } from 'axios'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import AccountFactory from '@/modules/shared/models/accountfactory'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { CustomerTypeEnum, UsageSourcesEnum } from '@/modules/shared/enums'
import moment from 'moment'
import { MeterRead } from '@/modules/shared/models/account/meterread'
import { PeriodDetail } from '@/modules/customers/models/account/perioddetail'
import { CustomerTypes } from '@/modules/shared/lists'
import { RatePlan } from '@/modules/customers/models/rateplan'

interface IErrorResponse {
  errorMessage: string;
}

interface IAccountResponse {
  meter: IAccount;
}

interface IMultiAccountResponse {
  accounts: Array<Partial<IAccount>>;
}

export default class AccountsApi {
  public static async loadAll (filters?: IStringDictionary<string|IStringIndexed>): Promise<Array<IAccount>> {
    try {
      let query = ''
      if (filters) {
        query = filtersToQs(filters)
      }

      query += (query.length ? '&' : '?') + 'additional[]=periodDetails&additional[]=marketDetails&' +
        'additional[periodDetails][]=customerId&additional[periodDetails][]=customerName&' +
        'additional[]=reads'

      const response: AxiosResponse = await esalesClient.get('serviceaccounts' + query)
      if (response.status === 200 && (<IMultiAccountResponse>response.data).accounts) {
        const accounts: Array<IAccount> = []

        response.data.accounts.forEach((i: Partial<IAccount>) => {
          accounts.push(AccountFactory.getAccountFromJson(i))
        })
        return accounts
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async load (id: number): Promise<IAccount> {
    try {
      const response: AxiosResponse = await esalesClient.get('serviceaccounts/' + id.toString())
      if (response.status === 200) {
        return AccountFactory.getAccountFromJson(response.data.account)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async save (customerType: keyof CustomerTypes, account: IAccount): Promise<{ account: IAccount; warnings: Array<string> }> {
    try {
      let method = 'POST'
      if (account.id) {
        method = 'PUT'
      }

      const response: AxiosResponse = await esalesClient.request({
        url: 'serviceaccounts' + (account.id ? '/' + account.id : '') + '?customerType=' + customerType,
        method: <Method> method,
        data: account
      })

      if (response.status === 200 && response.data.account) {
        return {
          account: AccountFactory.getAccountFromJson(response.data.account),
          warnings: response.data.warnings || []
        }
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadUsageHistory (id: number): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await abClient.get('accounts/' + id + '/profiles', {
        params: {
          from: moment.utc().subtract(365, 'days').format('YYYY/MM/DD'),
          to: moment.utc().format('YYYY/MM/DD'),
          source: UsageSourcesEnum.BEST_MATCH,
          raw: 1
        }
      })

      if (response.status === 200 && response.data.profile) {
        const usage: IStringIndexed = {}

        for (const d in response.data.profile) {
          for (const hm in response.data.profile[d]) {
            usage[d + ' ' + hm + ':00'] = response.data.profile[d][hm]
          }
        }

        return usage
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response?.status === 416) {
        return {}
      }

      return checkMessageOrThrow(err)
    }
  }

  public static async loadSummaryReads (accountId: number): Promise<Array<MeterRead>> {
    try {
      const response: AxiosResponse = await esalesClient.get('serviceaccounts/' + accountId + '/summaryreads')

      if (response.status === 200 && response.data.reads) {
        const reads: Array<MeterRead> = []

        response.data.reads.forEach((i: Partial<MeterRead>) => {
          reads.push(new MeterRead(i))
        })
        return reads
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response?.status === 416) {
        return []
      }
      return checkMessageOrThrow(err)
    }
  }

  public static async loadSummaryShape (summary: MeterRead): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await abClient.get('accounts/' + summary.accountId + '/profiles', {
        params: {
          from: summary.startDate,
          to: summary.endDate,
          source: UsageSourcesEnum.PROFILED,
          raw: 1
        }
      })

      if (response.status === 200 && response.data.profile) {
        const usage: IStringIndexed = {}

        for (const d in response.data.profile) {
          for (const hm in response.data.profile[d]) {
            usage[d + ' ' + hm + ':00'] = response.data.profile[d][hm]
          }
        }

        return usage
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response?.status === 416) {
        return {}
      }
      return checkMessageOrThrow(err)
    }
  }

  public static async loadPeriodDetails (accountId: number): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await abClient.get('accounts/' + accountId + '/perioddetails', {
        params: {
        }
      })

      if (response.status === 200 && response.data.details) {
        const details: Array<PeriodDetail> = []

        response.data.details.forEach((i: Partial<PeriodDetail>) => {
          details.push(new PeriodDetail(i))
        })
        return details
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response?.status === 416) {
        return {}
      }
      return checkMessageOrThrow(err)
    }
  }

  public static async loadAvailableRatePlans (accountIds: Array<number>): Promise<Array<RatePlan>> {
    try {
      const query = filtersToQs({ accountIds: accountIds })
      const response: AxiosResponse = await esalesClient.get('rate-plans/' + query)

      if (response.status === 200 && response.data.ratePlans) {
        const res: Array<RatePlan> = []

        for (const i in response.data.ratePlans) {
          res.push(new RatePlan(response.data.ratePlans[i]))
        }
        return res
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response?.status === 416) {
        return []
      }
      return checkMessageOrThrow(err)
    }
  }
}
