import { AppState } from '@/stores/appStore'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { ListsEnum } from '@/modules/shared/enums'

export interface IFinanceInvoice extends IBaseModel {
  customerId: number;
  customerName: string;
  customerStatus: string;
  haltPayments: number;
  invoiceId: number;
  invoiceNumber: string;
  dueDate: string;
  amount: number;
  balance: number;
  billGroupId: number;
  paymentMethod: string;
  pmtInstitution: string;
  pmtAccountNumber: string;
  serviceAccountList: string;
  serviceAccounts: Array<string>;
}

export class FinanceInvoice extends Base implements IFinanceInvoice {
  public customerId = 0
  public customerName = ''
  public customerStatus = ''
  public haltPayments = 0
  public invoiceId = 0
  public invoiceNumber = ''
  public dueDate = ''
  public amount = 0
  public balance = 0
  public billGroupId = 0
  public paymentMethod = ''
  public pmtInstitution = ''
  public pmtAccountNumber = ''
  public serviceAccountList = ''
  public serviceAccounts = []

  constructor (props?: Partial<IFinanceInvoice>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'customerId':
          case 'haltPayments':
          case 'invoiceId':
          case 'billGroupId':
            props[p] = parseInt(props[p]!.toString())
            break
          case 'amount':
          case 'balance':
            props[p] = parseFloat(props[p]!.toString())
            break
          case 'serviceAccounts':
            if (props[p] instanceof Array) {
              props.serviceAccountList = props[p]!.join(',')
            } else {
              props[p] = props[p]!
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get errorMessage () {
    if (!this.pmtAccountNumber) {
      return 'Missing Account #'
    }
    return ''
  }
}
