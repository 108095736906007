










import { Vue, Component } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store'

@Component({
  components: {
  }
})
export default class Payments extends Vue {
  protected menu = [
    { title: 'Payments', shortTitle: 'Pmts', icon: 'payments', to: { name: 'customerpayments' } },
    { title: 'Register', shortTitle: 'Rgstr', icon: 'statement', to: { name: 'balanceregister' } },
    { title: 'Bank Accounts', shortTitle: 'Bank', icon: 'finance', to: { name: 'customerpaymentmethods' } }
  ]

  public mounted () {

  }
}
