











































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { Contact } from '@/modules/customers/models/customer/contact'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask
  }
})
export default class ContactCommunicationFormFields extends Vue {
  @PropSync('contact', {
    required: true,
    default: () => new Contact()
  })
  private localContact!: Contact

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      primaryEmail: [Rules.email()],
      secondaryEmail: [Rules.email()],
      workPhone: [],
      workExtension: [],
      otherPhone: [],
      otherExtension: [],
      cellPhone: [],
      fax: []
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {
  }
}
