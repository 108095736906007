






















































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import dtExporter from '@/modules/shared/datatablehelper'
import { IStringIndexed } from '@/modules/shared/types'
import DataServicesReportsApi from '@/modules/dataservices/api/reports'

@Component({
  components: {}
})
export default class ActiveCustomerParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true

  private menu = {
    asOf: false
  }

  private asOf = ''
  private resultsSearch = ''
  private records: Array<IStringIndexed> = []
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'Rate Class', align: 'left', sortable: true, value: 'rate_class' },
    { text: 'Customer Name', align: 'left', sortable: true, value: 'business_name', exportType: 'text' },
    { text: 'Customer Id', align: 'left', sortable: true, value: 'customer_id' },
    { text: 'Customer P2C Id', align: 'left', sortable: true, value: 'Customer_p2c_id' },
    { text: 'Account Number', align: 'left', sortable: true, value: 'account_number' },
    { text: 'Account Numberxl', align: 'left', sortable: true, value: 'account_numberXL' },
    { text: 'Meter Number', align: 'left', sortable: true, value: 'meter_number' },
    { text: 'Account Id', align: 'left', sortable: true, value: 'account_id' },
    { text: 'Annual Load', align: 'left', sortable: true, value: 'annual_load' },
    { text: 'Annual Load Units', align: 'left', sortable: true, value: 'annual_load_units' },
    { text: 'Account Status', align: 'left', sortable: true, value: 'account_status' },
    { text: 'Serviceaccount P2c Id', align: 'left', sortable: true, value: 'ServiceAccount_p2c_id' },
    { text: 'Sales Agents', align: 'left', sortable: true, value: 'sales_agents' },
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Zone', align: 'left', sortable: true, value: 'zone' },
    { text: 'Read Cycle', align: 'left', sortable: true, value: 'read_cycle' },
    { text: 'Load Profile', align: 'left', sortable: true, value: 'load_profile' },
    { text: 'Enrollment Start Date', align: 'left', sortable: true, value: 'enrollment_start_date' },
    { text: 'Drop Date', align: 'left', sortable: true, value: 'drop_date' },
    { text: 'Capacity Tag', align: 'left', sortable: true, value: 'capacity_tag' },
    { text: 'Usage P2c Id', align: 'left', sortable: true, value: 'Usage_p2c_id' },
    { text: 'Sender Transaction Id', align: 'left', sortable: true, value: 'sender_transaction_id' },
    { text: 'Usage Created Date', align: 'left', sortable: true, value: 'usage_created_date' },
    { text: 'Usage Updated Date', align: 'left', sortable: true, value: 'usage_updated_date' },
    { text: 'Usage Start Date', align: 'left', sortable: true, value: 'usage_start_date' },
    { text: 'Usage End Date', align: 'left', sortable: true, value: 'usage_end_date' },
    { text: 'Usage Quantity', align: 'left', sortable: true, value: 'usage_quantity' },
    { text: 'Invoice_Quantity', align: 'left', sortable: true, value: 'invoice_quantity' },
    { text: 'Invoicedetail Created At', align: 'left', sortable: true, value: 'invoicedetail_created_at' },
    { text: 'Invoicedetail Updated At', align: 'left', sortable: true, value: 'invoicedetail_updated_at' },
    { text: 'Invoice Total', align: 'left', sortable: true, value: 'invoice_total' },
    { text: 'Invoice Number', align: 'left', sortable: true, value: 'invoice_number' },
    { text: 'Invoice Date', align: 'left', sortable: true, value: 'invoice_date' },
    { text: 'Due Date', align: 'left', sortable: true, value: 'due_date' },
    { text: 'Grand Total', align: 'left', sortable: true, value: 'grand_total' },
    { text: 'Open Balance', align: 'left', sortable: true, value: 'open_balance' },
    { text: 'Invoice Status', align: 'left', sortable: true, value: 'invoice_status' },
    { text: 'Days To Get Usage', align: 'left', sortable: true, value: 'days_to_get_usage' },
    { text: 'Days Since Last Received Usage', align: 'left', sortable: true, value: 'days_since_last_received_usage' },
    { text: 'Days Invoice Not Sent', align: 'left', sortable: true, value: 'Days_Invoice_NOT_Sent' }
  ]

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true
      this.records = await DataServicesReportsApi.loadActiveCustomers()
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private exportList () {
    dtExporter(this.headers, this.records, 'Active Customers Export', 'Active Customers-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
