







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { SearchScopes } from '@/modules/shared/lists'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { SearchState } from '@/modules/search/store'
import SearchApi from '../api/search'
import router from '@/router'

@Component({
  components: {
  }
})
export default class SearchWidget extends Vue {
  private searchText = ''

  private searching = false
  private searchMessage = ''
  private showSearchMsg = false
  private searchMsgType = 'error'

  @Prop({ type: Boolean, default: false })
  readonly showRecent!: boolean

  public getRecentlyViewed () {
    return AppState.recentlyViewed
  }

  public get hasRecent () {
    return AppState.recentlyViewed.length
  }

  public async handleSearch () {
    try {
      if (!this.searchText.length) {
        try {
          await router.push({ name: 'search' })
        } catch {}
        return
      }

      this.searching = true
      const results = await SearchState.Search({ term: this.searchText })

      if (results.length === 0) {
        this.searchMessage = 'No results found'
        this.searchMsgType = 'warning'
        this.showSearchMsg = true
      } else {
        if (router.currentRoute.name !== 'search') {
          router.push({ name: 'search' })
        }
      }
    } catch (err) {
      this.searchMessage = err.message
      this.showSearchMsg = true
      this.searchMsgType = 'error'
    } finally {
      this.searching = false
    }
  }
}
