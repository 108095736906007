





























































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import GenericQuoteForm from '../../components/forms/GenericQuote.vue'
import AccountQuoteForm from '../../components/forms/AccountQuote.vue'
import MultiQuoteForm from '../../components/forms/MultiQuote.vue'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import QuoteSummary from '../../components/quoter/Summary.vue'
import QuoteDetail from '../../components/quoter/Detail.vue'
import { UsageSources } from '@/modules/shared/lists'
import moment from 'moment'
import XLSX from 'xlsx'
import { sum } from '@syncfusion/ej2-charts'

@Component({
  components: {
    'generic-form': GenericQuoteForm,
    'account-form': AccountQuoteForm,
    'multi-form': MultiQuoteForm,
    'quote-summary': QuoteSummary,
    'quote-detail': QuoteDetail
  }
})
export default class QuoterPage extends Mixins(DownloadFile) {
  private errorMessage = ''

  private loading = false

  private quoteTypeTab = 0
  private shapeTab = 0

  private quote: null|IStringIndexed = null
  private utilityQuote: null|IStringIndexed = null

  private handleQuoteRetrieved (quote: IStringIndexed) {
    Vue.set(this, 'quote', quote)
  }

  private handleUtilityQuoteReceived (quote: IStringIndexed) {
    Vue.set(this, 'utilityQuote', quote)
  }

  private handleSetTab (tabNumber: number) {
    this.shapeTab = tabNumber
    this.$vuetify.goTo('#shapeTabs')
  }

  private sourceLabel (sourceId: keyof UsageSources) {
    if (sourceId === 'utilityprofile') {
      return 'Utility Profile'
    }

    return new UsageSources()[sourceId]
  }

  public handleExportQuotes () {
    if (!this.utilityQuote) {
      EventBus.$emit('app-snack', {
        message: 'Unable to export, no quote'
      })
      return
    }

    const wb = XLSX.utils.book_new()

    const bucketOrder = [6, 4, 3, 7, 2, 1, 5]
    const buckets: Array<string> = []
    const summaryQuote = this.quote ?? this.utilityQuote

    bucketOrder.forEach(bi => {
      if (summaryQuote.bucketInfo[bi]) {
        buckets.push(summaryQuote.bucketInfo[bi])
      }
    })

    const summaryRows: Array<Array<string|number>> = [
      ['Total Consumption', 'Average Rate', 'Total'],
      [parseFloat(summaryQuote.grandTtlUsage), (parseFloat(summaryQuote.grand) / parseFloat(summaryQuote.grandTtlUsage)), parseFloat(summaryQuote.grand)],
      [],
      [],
      ['Month', 'Consumption', ...buckets, 'Total']
    ]

    for (const c in summaryQuote.byCalendarMonth) {
      const row = [c, parseFloat(summaryQuote.byCalendarMonth[c].grandTtlUsage)]
      bucketOrder.forEach(b => {
        if (summaryQuote.byCalendarMonth[c].byBucket[b]) {
          row.push(parseFloat(summaryQuote.byCalendarMonth[c].byBucket[b]))
        }
      })
      row.push(parseFloat(summaryQuote.byCalendarMonth[c].grand))

      summaryRows.push(row)
    }

    const sumSheet = XLSX.utils.aoa_to_sheet(summaryRows)

    sumSheet[XLSX.utils.encode_cell({ r: 1, c: XLSX.utils.decode_col('A') })].z = '#,##0.00'
    sumSheet[XLSX.utils.encode_cell({ r: 1, c: XLSX.utils.decode_col('B') })].z = '0.000000'
    sumSheet[XLSX.utils.encode_cell({ r: 1, c: XLSX.utils.decode_col('C') })].z = '"$"#,##0.00'

    for (const c of ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']) {
      const colNum = XLSX.utils.decode_col(c)
      let fmt = '"$"#,##0.00'
      if (c === 'B') {
        fmt = '#,##0.00'
      }

      /* get worksheet range */
      const range = XLSX.utils.decode_range(sumSheet['!ref']!)
      for (let i = range.s.r + 5; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum })

        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!sumSheet[ref]) {
          continue
        }
        /* `.t == "n"` for number cells */
        if (sumSheet[ref].t !== 'n') {
          continue
        }
        /* assign the `.z` number format */
        sumSheet[ref].z = fmt
      }
    }

    XLSX.utils.book_append_sheet(wb, sumSheet, 'Summary')

    const keys: Array<string> = []

    const hrs: IStringIndexed = {}
    const pivotHeaders = []
    for (let i = 0; i < 24; i++) {
      const k = (i < 10 ? '0' : '') + i.toString() + ':00'
      hrs[k] = 0
      pivotHeaders.push(k)

      if (i === 2) {
        hrs['02:59'] = 0
        pivotHeaders.push('02:59')
      }
    }

    const verticalRows: Array<Array<string|number>> = [['Date', 'Time', 'Actual Qty', 'Utility Qty']]

    const pivoted: IStringDictionary<IStringDictionary<number>> = {}

    for (const dt in this.utilityQuote!.byHour.usage) {
      const m = moment(dt)

      verticalRows.push([m.format('MM/DD/YYYY'), m.format('HH:mm'), (this.quote!.byHour.usage[dt] ? parseFloat(this.quote!.byHour.usage[dt]) : '-'), parseFloat(this.utilityQuote.byHour.usage[dt])])

      const mo = m.format('YYYY-MM-DD')
      if (!pivoted[mo]) {
        pivoted[mo] = Object.assign({}, hrs)
      }
      pivoted[mo][m.format('HH:mm')] += this.quote!.byHour.usage[dt] ? parseFloat(this.quote!.byHour.usage[dt]) : parseFloat(this.utilityQuote.byHour.usage[dt])
    }

    const pivotRows: Array<Array<string|number>> = [['Date', ...pivotHeaders]]
    for (const d in pivoted) {
      const row = []
      row.push(d)
      for (const q of pivotHeaders) {
        row.push(pivoted[d][q] || '-')
      }
      pivotRows.push(row)
    }

    const ws = XLSX.utils.aoa_to_sheet(verticalRows)

    for (const c of ['C', 'D']) {
      const colNum = XLSX.utils.decode_col(c)
      const fmt = '0.000000' // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

      /* get worksheet range */
      const range = XLSX.utils.decode_range(ws['!ref']!)
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum })
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) {
          continue
        }
        /* `.t == "n"` for number cells */
        if (ws[ref].t !== 'n') {
          continue
        }
        /* assign the `.z` number format */
        ws[ref].z = fmt
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, 'Interval Data')

    const wsp = XLSX.utils.aoa_to_sheet(pivotRows)
    for (const c of ['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']) {
      const colNum = XLSX.utils.decode_col(c)
      const fmt = '0.000000' // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

      /* get worksheet range */
      const range = XLSX.utils.decode_range(wsp['!ref']!)
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum })
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!wsp[ref]) {
          continue
        }
        /* `.t == "n"` for number cells */
        if (wsp[ref].t !== 'n') {
          continue
        }
        /* assign the `.z` number format */
        wsp[ref].z = fmt
      }
    }
    XLSX.utils.book_append_sheet(wb, wsp, 'Interval Data - Pivoted')

    XLSX.writeFile(wb, (this.quote ? 'Account' : 'Generic') + '-Quoter-Export-' + moment().format('YYYYMMDD-HHmm') + '.xlsx')
  }

  // public async handleDownloadPdf (invoice: CustomerInvoice) {
  //   try {
  //     Vue.set(this.processing, invoice.id, 'download')
  //     const file = await InvoicesApi.getPdf(invoice)
  //     this.downloadFile(file.name, file.binaryContents, 'application/pdf')
  //   } catch (err) {
  //     EventBus.$emit('app-snack', {
  //       message: err
  //     })
  //   } finally {
  //     Vue.delete(this.processing, invoice.id)
  //   }
  // }

  private beforeMount () {

  }
}
