
























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import Customer from '@/modules/customers/models/customer'
import { ContactTypeEnum } from '@/modules/shared/enums'
import { Contact } from '@/modules/customers/models/customer/contact'

@Component
export default class CustomerOverview extends Vue {
  @Prop({ required: true })
  private customer!: Customer

  @Prop({ default: false })
  private readOnly!: boolean

  private get canEditCustomer () {
    return !this.readOnly &&
      AuthState.user.isAllowed('CUSTOMER.EDIT')
  }

  private get primaryContact () {
    return this.customer?.contacts?.find(c => c.type === ContactTypeEnum.PRIMARY) ?? new Contact()
  }

  public mounted () {
  }
}
