import { Base, IBaseModel } from '@/modules/shared/models/base'
import { BillGroupDeliveryMethods, InvoiceAssemblyTypes, PaymentMethods } from '@/modules/shared/lists'
import { BillGroupDeliveryMethodEnum, InvoiceAssemblyTypeEnum, PaymentMethodEnum } from '@/modules/shared/enums'
import { CustomerPayment } from './payment'
import { Contact } from './contact'

export interface IBillGroup extends IBaseModel {
  id: number;
  customerId: number;
  contactId: number;
  paymentMethodId: keyof PaymentMethods;
  bankAccountId: number|null;
  creditCardId: number|null;
  billDeliveryMethod: keyof BillGroupDeliveryMethods;
  assemblyType: keyof InvoiceAssemblyTypes;
  assemblyDayOfMonth: number|null;
  label: string;
  attnOverride: string;
  emailTo: string;
  ccTo: string;
  bccTo: string;
  fax: string;
  p2cId: string;
  accountIds: Array<number>
}

export class BillGroup extends Base implements IBillGroup {
  public static deliveryMethods = new BillGroupDeliveryMethods()
  public static assemblyTypes = new InvoiceAssemblyTypes()

  public id = 0
  public customerId = 0
  public contactId = 0
  public paymentMethodId = PaymentMethodEnum.ACH
  public bankAccountId: number|null = null
  public creditCardId = null
  public billDeliveryMethod = BillGroupDeliveryMethodEnum.EMAIL
  public assemblyType = InvoiceAssemblyTypeEnum.DAILY
  public assemblyDayOfMonth = null

  public label = ''
  public attnOverride = ''
  public emailTo = ''
  public ccTo = ''
  public bccTo = ''
  public fax = ''

  public p2cId = ''

  public accountIds: Array<number> = []
  public contact?: Contact

  constructor (props?: Partial<IBillGroup>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p as string) {
          case 'id':
          case 'customerId':
          case 'paymentMethodId':
          case 'bankAccountId':
          case 'creditCardId':
          case 'billDeliveryMethod':
          case 'assemblyType':
          case 'assemblyDayOfMonth':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'accountIds':
            if (props[p]) {
              const ids = []
              for (const n of props[p]) {
                ids.push(parseInt(n))
              }
              props[p] = ids
            }
            break
          case 'contact':
            if (props[p]) {
              props[p] = new Contact(props[p])
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get deliveryMethodLabel (): string {
    return BillGroup.deliveryMethods[this.billDeliveryMethod]
  }

  public get paymentMethodLabel (): string {
    return CustomerPayment.paymentMethods[this.paymentMethodId]
  }

  public get assemblyTypeLabel (): string {
    return BillGroup.assemblyTypes[this.assemblyType]
  }

  public get requiresAch () {
    return this.paymentMethodId === PaymentMethodEnum.ACH
  }

  public get requiresCreditCard () {
    return this.paymentMethodId === PaymentMethodEnum.CREDIT_CARD
  }

  public get requiresDayOfMonth () {
    return this.assemblyType === InvoiceAssemblyTypeEnum.DAY_OF_MONTH
  }

  public get description () {
    return this.label + ' (' + this.paymentMethodLabel + ')'
  }
}
