





























import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import router from '@/router'
import { UserCustomerNavsEnum } from '@/modules/shared/enums'

@Component
export default class BottomNav extends Vue {
  private activeNavBtn = ''
  private sheet = false

  private get numItems () {
    if (this.$vuetify.breakpoint.xs) {
      return 3
    } else if (this.$vuetify.breakpoint.sm) {
      return 7
    }
    return -1
  }

  private get quickItems () {
    return CustomerState.customerMenu.slice(0, this.numItems)
  }

  private get menu () {
    return CustomerState.customerMenu.slice(this.numItems)
  }

  public get userWantsBottomNav () {
    return !AppState.enableTabNav && this.$vuetify.breakpoint.smAndDown
  }
}
