import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse, Method } from 'axios'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { FinanceInvoice } from '../models/invoice'
import { File as FileModel } from '@/modules/shared/models/file'

export default class FinancePaymentsApi {
  public static async loadPayable (filters?: IStringDictionary<any>): Promise<Array<FinanceInvoice>> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-customer-invoices' + filtersToQs(filters))
      if (response.status === 200 && response.data.invoices) {
        const invoices = []
        for (const p of response.data.invoices) {
          invoices.push(new FinanceInvoice(p))
        }
        return invoices
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadPendingAch (): Promise<Array<IStringIndexed>> {
    try {
      const filters = {
        status: { IN: [0, 1] }
      }
      const response: AxiosResponse = await esalesClient.get('finance-customer-payments' + filtersToQs(filters))
      if (response.status === 200 && response.data.transactions) {
        return response.data.transactions
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadHistoryAch (filters?: IStringDictionary<any>): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-customer-payments' + filtersToQs(filters))
      if (response.status === 200 && response.data.transactions) {
        return response.data.transactions
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async updateAchTransaction (id: number, status: number): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await esalesClient.put('finance-customer-payments/' + id.toString(), {
        status: status
      })

      if (response.status === 200 && response.data.transaction) {
        return response.data.transaction
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async queueAchPayments (invoices: INumericDictionary<number>, achOn: string) {
    try {
      const response: AxiosResponse = await esalesClient.post('finance-customer-invoices', {
        invoices: invoices,
        transactionDate: achOn
      })

      if (response.status === 200 && response.data.payments) {
        const invoices = []
        for (const p of response.data.payments) {
          invoices.push(new FinanceInvoice(p))
        }
        return invoices
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async getPaymentImports (): Promise<Array<IStringIndexed>> {
    const method = 'POST'

    const response: AxiosResponse = await esalesClient.request(
      {
        url: 'payment-imports',
        method: <Method> method,
        headers: {
          'X-HTTP-Method-Override': 'GET'
        }
      })

    if (response.status === 200 && response.data) {
      return response.data.imports
    }

    throw new Error('Unexpected response format')
  }

  public static async createPaymentImport (file: FileModel, callback?: Function): Promise<File> {
    try {
      const method = 'POST'

      const data = { ...file }
      data.contents = btoa(file.contents)

      const response: AxiosResponse = await esalesClient.request(
        {
          url: 'payment-imports/files',
          method: <Method> method,
          data: data,
          onUploadProgress: (e) => {
            if (callback) {
              callback(e)
            }
          }
        }
      )

      return response.data
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
