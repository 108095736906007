





























import { Component, Vue, Prop, Ref } from 'vue-property-decorator'

@Component
export default class CardList extends Vue {
  @Prop({ default: false })
  private loading!: boolean|string

  @Prop({ default: '' })
  private title!: string

  @Prop()
  private icon!: string|undefined

  @Prop()
  private toolbarActionIcon!: string|undefined

  @Prop({ default: '100%' })
  private height!: string|number

  @Prop()
  private sm!: string|undefined

  @Prop()
  private md!: string|undefined

  @Prop()
  private lg!: string|undefined

  @Prop()
  private xl!: string|undefined

  @Prop({ default: 'column' })
  private direction!: string

  @Prop({ default: () => { return {} } })
  private toolbarProps!: object

  @Ref()
  private overflowableContainer!: HTMLDivElement

  private overflowed = false

  private get breakpoint () {
    return this.$vuetify.breakpoint
  }

  private get flexDirection () {
    return 'flex-' + this.direction
  }

  private get setMaxHeight () {
    let height: string|number = 'auto'
    if (this.$vuetify.breakpoint.xs && this.height) {
      height = this.height
    }

    if (this.$vuetify.breakpoint.smAndUp && this.sm) {
      height = this.sm
    }

    if (this.$vuetify.breakpoint.mdAndUp && this.md) {
      height = this.md
    }

    if (this.$vuetify.breakpoint.lgAndUp && this.lg) {
      height = this.lg
      // height = this.$vuetify.breakpoint.height + 'px'
    }

    if (this.$vuetify.breakpoint.xl && this.xl) {
      height = this.xl
    }

    return 'max-height:' + height + ';height: ' + height + ';'
  }

  private checkOverflow () {
    this.$nextTick(() => {
      this.overflowed = this.overflowableContainer.clientWidth !== this.overflowableContainer.scrollWidth
    })
  }

  public mounted () {
    this.checkOverflow()
  }

  public updated () {
    this.checkOverflow()
  }

  public created () {
    window.addEventListener('resize', this.checkOverflow)
  }

  public destroyed () {
    window.removeEventListener('resize', this.checkOverflow)
  }
}
