import esalesClient, { filtersToQs, checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { DataServicesTransaction } from '@/modules/customers/models/account/transaction'

export class DataServicesTransactionApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['dataservices-transactions']
    this.singular = 'transaction'
    this.plural = 'transactions'
    this.factory = (...args: any) => new DataServicesTransaction(...args)
  }

  public async loadAll (config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<DataServicesTransaction>> {
    return await this.loadAllSpecific(arguments, config) as Array<DataServicesTransaction>
  }

  // public async loadAll (config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>, ...args: any): Promise<Array<Transaction>> {
  // return await this.loadAllSpecific(args, config) as Array<Transaction>
  // }

  public async load (id: number, onlyProps?: Array<string>): Promise<DataServicesTransaction> {
    return await this.loadSpecific(arguments, onlyProps) as DataServicesTransaction
  }
}

export const DataServicesTransactionsApi = new DataServicesTransactionApi(esalesClient)
