































import { Component, Vue } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '../store'

@Component
export default class Login extends Vue {
  private username = ''
  private password = ''
  private BACKEND_URL: string = process.env.VUE_APP_BACKEND_URL
  private errorMessage = ''

  public get heightBySize () {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 200
    }
    return 250
  }

  public async onSubmit () {
    this.errorMessage = ''
    try {
      await AuthState.Login({
        username: this.username,
        password: this.password
      })
    } catch (err) {
      this.errorMessage = err.message
    }
  }

  public beforeMount () {
    AppState.SetLayout('bare')
  }
}
