


























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import EventBus from '@/plugins/eventbus'
import { CustomerInvoice } from '../../models/customer/invoice'
import SummaryInvoiceList from '../../components/blocks/invoice/SummaryList.vue'
import { INumericIndexed } from '@/modules/shared/types'
import { AccountInvoiceStatusEnum } from '@/modules/shared/enums'

@Component({
  components: {
    'summary-list': SummaryInvoiceList
  }
})
export default class CustomerInvoices extends Vue {
  @Prop({ required: false })
  private invoiceId!: number

  private errorMessage = ''

  public goToInvoice (item: CustomerInvoice) {
    if (AuthState.user.isAllowed('CUSTOMER.INVOICES.ACCOUNT.VIEW') && this.invoiceId !== item.id) {
      this.$router.push({ name: 'customerinvoicedetail', params: { invoiceId: item.id.toString() } })
    }
  }

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  public get invoiceTotal () {
    return CustomerState.listTotals.invoices
  }

  public get totalOpenBalance () {
    return this.customer.invoices.reduce((a, i) => i.isVoided ? 0 : a + i.openBalance, 0) || 0
  }

  public get accountBalances () {
    const balances: INumericIndexed = {}

    this.customer.invoices.forEach(ci => {
      ci.invoices.forEach(si => {
        if (si.voidDate) {
          return
        }
        if (!balances[si.accountId]) {
          balances[si.accountId] = {
            pending: 0,
            billed: 0
          }
        }

        if (si.status === AccountInvoiceStatusEnum.STATUS_PENDING) {
          balances[si.accountId].pending += si.openBalance
        } else {
          balances[si.accountId].billed += si.openBalance
        }
      })
    })

    const result = []
    for (const id in balances) {
      result.push({
        accountNumber: this.customer.accounts.find(a => a.id.toString() === id)?.accountNumber,
        balance: balances[id]
      })
    }

    return result.filter(i => i.balance !== 0)
  }

  public get filteredInvoices () {
    return this.customer.invoices // .filter(ci => !ci.invoices.find(si => si.status === AccountInvoiceStatusEnum.STATUS_PENDING))
  }

  public getAccountNumber (accountId: string) {
    return this.customer.accounts.find(a => a.id.toString() === accountId)?.accountNumber
  }

  @Watch('loading.invoices')
  private handleLoadingChange () {
    if (!this.loading.invoices) {
      if (CustomerState.loading.payments === null) {
        CustomerState.LoadPayments()
      }

      if (CustomerState.loading.accounts === null) {
        CustomerState.LoadAccounts()
      }

      if (CustomerState.loading.invoices === null) {
        CustomerState.LoadInvoices()
      }
    }
  }

  public async mounted () {
    if (CustomerState.loading.payments === null) {
      await CustomerState.LoadPayments()
    }

    if (CustomerState.loading.accounts === null) {
      await CustomerState.LoadAccounts()
    }

    if (CustomerState.loading.invoices === null) {
      await CustomerState.LoadInvoices()
    }

    if (this.invoiceId) {
      let loop = true
      while (loop) {
        const idx = this.customer.invoices.findIndex(i => i.id === this.invoiceId)
        if (idx > -1) {
          loop = false
        } else {
          if (!await CustomerState.LoadInvoices({ ignoreCache: false, loadMore: true })) {
            loop = false
          }
        }
      }
    }
  }
}
