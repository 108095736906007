import esalesClient, { checkMessageOrThrow } from '@/api/clients/esales'
import { Method, AxiosResponse } from 'axios'
import { IFile, File } from '@/modules/shared/models/file'
import { IStringDictionary } from '@/modules/shared/types'
import { Contract } from '../models/customer/contract'

interface IErrorResponse {
  errorMessage: string;
}

interface IFileResponse {
  file: IFile;
}

interface IMultiFileResponse {
  files: Array<IFile>;
}

export default class FilesApi {
  public static async loadAll (customerId: number, filters?: IStringDictionary<string>): Promise<Array<File>> {
    try {
      let query = ''
      if (filters) {
        Object.entries(filters)
          .forEach(([prop, val], i) => {
            query += (i > 0 ? '&' : '') + prop + '=' + encodeURIComponent(val)
          })
      }

      const response: AxiosResponse = await esalesClient.get('customers/' + customerId + '/files' + query)

      if (response.status === 200 && response.data.files) {
        const users: Array<File> = []

        response.data.files.forEach((i: object) => {
          users.push(new File(i))
        })

        return users
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadByContract (contract: Contract, filters?: IStringDictionary<string>): Promise<Array<File>> {
    try {
      let query = ''
      if (filters) {
        Object.entries(filters)
          .forEach(([prop, val], i) => {
            query += (i > 0 ? '&' : '') + prop + '=' + encodeURIComponent(val)
          })
      }

      const response: AxiosResponse = await esalesClient.get('customers/' + contract.customerId + '/contracts/' + contract.id + '/files' + query)

      if (response.status === 200 && response.data.files) {
        const users: Array<File> = []

        response.data.files.forEach((i: object) => {
          users.push(new File(i))
        })

        return users
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async load (customerId: number, id: number): Promise<File> {
    try {
      const response: AxiosResponse = await esalesClient.get('customers/' + customerId + '/files/' + id.toString())
      if (response.status === 200) {
        return new File(response.data.file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async save (customerId: number, file: File): Promise<File> {
    try {
      let method = 'POST'
      if (file.id) {
        method = 'PUT'
      }

      const response: AxiosResponse = await esalesClient.request({
        url: 'customers/' + customerId + '/files' + (file.id ? '/' + file.id : ''),
        method: <Method> method,
        data: file
      })

      if (response.status === 200 && response.data.file) {
        return new File((<IFileResponse> response.data).file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async delete (customerId: number, file: File): Promise<File> {
    try {
      const method = 'DELETE'

      const response: AxiosResponse = await esalesClient.request({
        url: 'customers/' + customerId + '/files/' + file.id,
        method: <Method> method,
        data: file
      })

      if (response.status === 200 && response.data.file) {
        return new File((<IFileResponse> response.data).file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async upload (customerId: number, file: File, callback?: Function): Promise<File> {
    try {
      const method = 'POST'

      const data = { ...file }
      data.contents = btoa(file.contents)

      const response: AxiosResponse = await esalesClient.request(
        {
          url: 'customers/' + customerId + '/files/',
          method: <Method> method,
          data: data,
          onUploadProgress: (e) => {
            if (callback) {
              callback(e)
            }
          }
        }
      )

      if (response.status === 200 && response.data.file) {
        response.data.file.contents = ''
        return new File((<IFileResponse> response.data).file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async uploadToContract (customerId: number, contractId: number, file: File, callback?: Function): Promise<File> {
    try {
      const method = 'POST'

      const data = { ...file }
      data.contents = btoa(file.contents)

      const response: AxiosResponse = await esalesClient.request(
        {
          url: 'customers/' + customerId + '/contracts/' + contractId + '/files/',
          method: <Method> method,
          data: data,
          onUploadProgress: (e) => {
            if (callback) {
              callback(e)
            }
          }
        }
      )

      if (response.status === 200 && response.data.file) {
        response.data.file.contents = ''
        return new File((<IFileResponse> response.data).file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
