
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import Customer from '@/modules/customers/models/customer'
import EventBus from '@/plugins/eventbus'
import { CustomerInvoice } from '../../models/customer/invoice'
import { CustomerPayment } from '../../models/customer/payment'
import { CustomerPaymentApplication } from '../../models/customer/payment/application'
import { AccountInvoice } from '../../models/account/invoice'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { INumericDictionary } from '@/modules/shared/types'
import { CustomerPaymentApplicationStatusEnum, CustomerPaymentApplicationTypeEnum } from '@/modules/shared/enums'

@Component({
  components: {
  }
})
export default class CustomerPaymentDetail extends Vue {
  @Prop({ required: true })
  private paymentId!: number

  private applicationHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: true, value: 'id' },
    { text: 'Type', align: 'left', sortable: true, value: 'typeLabel' },
    { text: 'Applied On', align: 'center', sortable: true, value: 'applicationDate' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount' },
    { text: 'Ref #', align: 'center', sortable: true, value: 'referenceNumber' },
    { text: 'Invoice', align: 'center', sortable: true, value: 'invoiceId' },
    { text: 'Account #', align: 'center', sortable: true, value: 'accountNum' },
    { text: 'Status', align: 'left', sortable: true, value: 'statusLabel' },
    { text: '', align: 'center', sortable: false, value: 'actions' }
  ]

  private processingList: INumericDictionary<string> = {}

  private showReversed = false
  private yesNo = [{ text: 'No', value: false }, { text: 'Yes', value: true }]

  private get filteredApplications () {
    return Object.values(this.payment.applications).filter(a => {
      let res = true

      let rel
      if (a.relatedApplicationId) {
        rel = this.payment.applications.find(ap => ap.id === a.relatedApplicationId)
      }

      if (!this.showReversed && (a.status === CustomerPaymentApplicationStatusEnum.REVERSED || (a.type === CustomerPaymentApplicationTypeEnum.REVERSAL && (!rel || rel.type !== CustomerPaymentApplicationTypeEnum.REFUND)))) {
        res = false
      }
      return res
    })
  }

  private get canViewAccountInvoices () {
    return AuthState.user.isAllowed('CUSTOMER.INVOICES.ACCOUNT.VIEW')
  }

  private get canReverseApplication () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.APPLICATIONS.REVERSE')
  }

  private get payment () {
    const inv = CustomerState.customer.payments.find(i => i.id === this.paymentId)
    if (inv) {
      return inv
    }
    return new CustomerPayment({ referenceNumber: 'Not Found' })
  }

  public processingAction (payment: CustomerPayment) {
    return this.processingList[payment.id] || false
  }

  private colorizeRow (item: CustomerPaymentApplication) {
    let rel
    if (item.relatedApplicationId) {
      rel = this.payment.applications.find(ap => ap.id === item.relatedApplicationId)
    }

    if (item.status === CustomerPaymentApplicationStatusEnum.REVERSED ||
      (item.type === CustomerPaymentApplicationTypeEnum.REVERSAL && (!rel || rel.type !== CustomerPaymentApplicationTypeEnum.REFUND))
    ) {
      return 'grey--text text--lighten-1'
    }
    return 'black--text'
  }

  public handleReverseApplication (item: CustomerPaymentApplication) {
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Application Reversal',
        message: '<b>Are you sure you want to reverse this payment application?</b><br/><br/>This will increase the open balance of the payment so that it may be applied to other invoices.',
        onConfirm: () => this.reverseApplication(item)
      }
    })
  }

  private async reverseApplication (item: CustomerPaymentApplication) {
    try {
      Vue.set(this.processingList, item.id, 'reverse')
      await CustomerState.ReversePaymentApplication({ payment: this.payment, application: item })
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, item.id)
    }
  }

  private getInvoice (app: CustomerPaymentApplication) {
    const inv = CustomerState.customer.invoices.find((ci: CustomerInvoice) => ci.invoices.find(ai => ai.id === app.invoiceId))
    if (inv) {
      return inv
    }
    return new CustomerInvoice()
  }

  private getAccountNumber (invoice: CustomerInvoice, item: CustomerPaymentApplication) {
    const accountInv = invoice.invoices.find(i => i.id === item.invoiceId)
    if (accountInv) {
      const acct = CustomerState.customer.accounts.find((a: IAccount) => a.id === accountInv.accountId)
      if (acct) {
        return acct.accountNumber
      }
    }
    return ''
  }
}
