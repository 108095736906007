import { AxiosInstance } from 'axios'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { Transaction } from '@/modules/customers/models/account/transaction'
import ModelApi from '@/api/model'
import esalesClient from '@/api/clients/esales'
import { ElectricAccount } from '@/modules/shared/models/account/electric'

export class TransactionApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['serviceaccounts', 'transactions']
    this.singular = 'transaction'
    this.plural = 'transactions'
    this.factory = (...args: any) => new Transaction(...args)
  }

  public async loadAll (accountId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<Transaction>> {
    return await this.loadAllSpecific(arguments, config) as Array<Transaction>
  }

  public async load (accountId: number, id: number, onlyProps?: Array<string>): Promise<Transaction> {
    return await this.loadSpecific(arguments, onlyProps) as Transaction
  }
}

export const TransactionsApi = new TransactionApi(esalesClient)
