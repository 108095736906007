import { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import settlementsClient from '@/api/clients/settlements'
import { AxiosResponse } from 'axios'
import { IStringIndexed } from '@/modules/shared/types'

export default class CostsApi {
  public static async loadCosts (costMonth: string, flowMonth: string, settlementNumber: number): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await settlementsClient.get('normalized-costs', {
        params: {
          output: 'computed',
          costMonth,
          settlementNumber,
          flowMonth
        }
      })

      if (response.status === 200 && response.data.records) {
        const recs: Array<IStringIndexed> = []

        for (const d of response.data.records) {
          recs.push(d)
        }

        return recs
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
