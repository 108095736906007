import Vue from 'vue'
// @ts-ignore
import Vuetify from 'vuetify/lib'
import icons from './icons'

Vue.use(Vuetify)

// @ts-ignore
export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#f93000',
        secondary: '#000000',
        accent: '#797979',
        error: '#FF5252',
        warning: '#FB8C00',
        info: '#2196F3',
        success: '#4CAF50'
      },
      dark: {
        primary: '#f93000',
        secondary: '#000000',
        accent: '#797979',
        error: '#FF5252',
        warning: '#FB8C00',
        info: '#2196F3',
        success: '#4CAF50'
      }/* AE
      {
        primary: '#E95420',
        secondary: '#AEA79F',
        accent: '#82B1FF',
        error: '#DF382C',
        info: '#17a2b8',
        success: '#38B44A',
        warning: '#EFB73E'
      } */
    }
  },
  icons: {
    iconfont: 'faSvg',
    values: icons
  }
})
