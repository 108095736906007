import { MarketDetailStatuses } from '@/modules/shared/lists'
import { MarketDetailStatusEnum } from '@/modules/shared/enums'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import moment from 'moment'
import { IStringIndexed } from '@/modules/shared/types'

export interface IMarketDetail extends IBaseModel {
  id: number;
  accountId: number;
  dropDate: null|string;
  dropRequestedAt: null|string;
  dropTransactionId: null|string;
  dropWasSeamlessMove: null|string;
  enrollmentRequestedAt: null|string;
  enrollmentStartDate: null|string;
  enrollmentTransactionId: null|string;
  lastReadDate: null|string;
  status: keyof MarketDetailStatuses
}

export class MarketDetail extends Base {
  public static statuses = new MarketDetailStatuses()

  public id = 0
  public accountId = 0
  public contractId = 0
  public dropDate: null
  public dropRequestedAt: null
  public dropTransactonId: null
  public dropWasSeamlessMove: null
  public enrollmentRequestedAt: null
  public enrollmentStartDate: null
  public enrollmentTransactionId: null
  public lastReadDate: null
  public status = MarketDetailStatusEnum.CURRENT

  constructor (props?: Partial<IMarketDetail>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'accountId':
          case 'status':
            props[p] = parseInt(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get statusLabel () {
    return MarketDetail.statuses[this.status]
  }
}
