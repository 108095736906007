import DataservicesRoot from '../pages/Dataservices.vue'
import EnrollmentQueue from '../pages/subpages/EnrollmentQueue.vue'
import EnrollmentQueueItem from '../components/EnrollmentQueueItem.vue'
import HistoricUsageQueue from '../pages/subpages/HistoricUsageQueue.vue'
import HUQueueItem from '../components/HUQueueItem.vue'
import DropQueue from '../pages/subpages/DropQueue.vue'
import DropQueueItem from '../components/DropQueueItem.vue'
import ChangeQueue from '../pages/subpages/ChangeRequestQueue.vue'
import GaacQueueItem from '../components/GaacQueueItem.vue'
import TransactionList from '../pages/subpages/TransactionList.vue'
import RootSideNav from '../components/sidenavs/Root.vue'
import { Route } from 'vue-router'
import Reports from '../pages/subpages/Reports.vue'
import ReportViewer from '@/modules/shared/components/ReportViewer.vue'

const rootNav = {
  key: 'root'
}

export default [
  {
    name: 'dataservicesroot',
    path: '/dataservices',
    components: {
      SideNav: RootSideNav,
      default: DataservicesRoot
    },
    title: 'Data Services',
    meta: {
      requiresAuth: 'DATA_SERVICES.VIEW',
      navs: {
        queues: {
          icon: 'up',
          title: 'Back'
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'enrollmentqueue',
    path: '/dataservices/enrollment-queue',
    components: {
      SideNav: RootSideNav,
      default: EnrollmentQueue
    },
    title: 'Enrollments',
    meta: {
      requiresAuth: 'DATA_SERVICES.QUEUES.ENROLL.VIEW',
      navs: {
        root: {
          icon: 'enroll',
          order: 0
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      }),
      nav: rootNav
    },
    children: [
      {
        name: 'enrollqitem',
        path: ':itemId(\\d+)',
        components: {
          default: EnrollmentQueueItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'historicusagequeue',
    path: '/dataservices/historic-usage-queue',
    components: {
      SideNav: RootSideNav,
      default: HistoricUsageQueue
    },
    title: 'Historic Usage',
    meta: {
      requiresAuth: 'DATA_SERVICES.QUEUES.HISTORICUSAGE.VIEW',
      navs: {
        root: {
          icon: 'hu',
          order: 1
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      }),
      nav: rootNav
    },
    children: [
      {
        name: 'huqitem',
        path: ':itemId(\\d+)',
        components: {
          default: HUQueueItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'changequeue',
    path: '/dataservices/change-queue',
    components: {
      SideNav: RootSideNav,
      default: ChangeQueue
    },
    title: 'Change Requests',
    meta: {
      requiresAuth: 'DATA_SERVICES.QUEUES.CHANGEREQUESTS.VIEW',
      navs: {
        root: {
          icon: 'gaac',
          order: 2
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      }),
      nav: rootNav
    },
    children: [
      {
        name: 'gaacqitem',
        path: ':itemId(\\d+)',
        components: {
          default: GaacQueueItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'dataservicesreports',
    path: '/dataservices/reports',
    components: {
      SideNav: RootSideNav,
      default: Reports
    },
    title: 'Reports',
    meta: {
      requiresAuth: 'DATA_SERVICES.REPORTS',
      navs: {
        root: {
          icon: 'report',
          order: 5
        }
      }
    },
    props: {
      default: (route: Route) => ({
        reportName: route.params.reportName
      }),
      nav: rootNav
    },
    children: [
      {
        name: 'dataservicesreportviewer',
        path: ':reportName',
        components: {
          default: ReportViewer
        },
        meta: {
          navs: {
            root: {
              icon: 'report'
            }
          }
        },
        props: {
          nav: {
            key: 'root'
          },
          default: (route: Route) => ({
            reportName: route.params.reportName
          })
        }
      }
    ]
  },
  {
    name: 'dropqueue',
    path: '/dataservices/drop-queue',
    components: {
      SideNav: RootSideNav,
      default: DropQueue
    },
    title: 'Drops',
    meta: {
      requiresAuth: 'DATA_SERVICES.QUEUES.DROP.VIEW',
      navs: {
        root: {
          icon: 'drop',
          order: 3
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      }),
      nav: rootNav
    },
    children: [
      {
        name: 'dropqitem',
        path: ':itemId(\\d+)',
        components: {
          default: DropQueueItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'dataservicestransactions',
    path: '/dataservices/transactions',
    components: {
      SideNav: RootSideNav,
      default: TransactionList
    },
    title: 'Transactions',
    meta: {
      requiresAuth: 'DATA_SERVICES.TRANSACTIONLIST',
      navs: {
        root: {
          icon: 'transactions',
          order: 4
        }
      }
    },
    props: {
      nav: rootNav
    }
  }
]
