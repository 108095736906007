import esalesClient from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance } from 'axios'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'

export class BillGroupApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['customers', 'billgroups']
    this.singular = 'billgroup'
    this.plural = 'billgroups'
    this.factory = (...args: any) => new BillGroup(...args)
  }

  public async loadAll (customerId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<BillGroup>> {
    return await this.loadAllSpecific(arguments, config) as Array<BillGroup>
  }

  public async load (customerId: number, id: number, onlyProps?: Array<string>): Promise<BillGroup> {
    return await this.loadSpecific(arguments, onlyProps) as BillGroup
  }

  public async save (customerId: number, billgroup: BillGroup) : Promise<BillGroup> {
    const data: IStringIndexed = { ...billgroup }

    return await this.saveSpecific(arguments, data) as BillGroup
  }

  public async delete (billgroup: BillGroup) {
    return await this.deleteSpecific([billgroup.customerId, billgroup.id])
  }
}

export const BillGroupsApi = new BillGroupApi(esalesClient)
