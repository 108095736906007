











































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { IStringIndexed } from '@/modules/shared/types'
import { DataServicesTransaction } from '@/modules/customers/models/account/transaction'
import TransactionDetail from '@/modules/customers/components/blocks/serviceaccount/detail/TransactionDetail.vue'
import TransactionProcessLog from '@/modules/customers/components/blocks/serviceaccount/detail/TransactionProcessLog.vue'
import TransactionListFilterForm from '@/modules/dataservices/components/forms/TransactionListFilter.vue'
import { DataServicesTransactionsApi } from '@/modules/dataservices/api/transactions'
import { EsgTransactionStatusEnum } from '@/modules/shared/enums'
import dtExporter from '@/modules/shared/datatablehelper'
import moment from 'moment'
import EnrollmentQueueItem from '../../components/EnrollmentQueueItem.vue'
import HuQueueItem from '../../components/HUQueueItem.vue'
import { TransactionRequestsQueueApi } from '../../api/queue/transactionrequests'

@Component({
  components: {
    'transaction-detail': TransactionDetail,
    'transaction-processlog': TransactionProcessLog,
    'filter-form': TransactionListFilterForm,
    'enroll-queue-item': EnrollmentQueueItem,
    'hu-queue-item': HuQueueItem
  }
})
export default class TransactionList extends Vue {
  private searchText = ''

  private errorMessage = ''
  private loading = false
  private exporting = false

  private transactions: Array<DataServicesTransaction> = []

  private dialog: boolean = false
  private dialogComponent = 'transaction'
  private dialogProps: IStringIndexed = {}
  private componentProps: IStringIndexed = {}

  private headers: Array<any> = [
    { text: 'ID', align: 'left', width: 100, sortable: true, value: 'id' },
    { text: 'Customer', align: 'left', width: 300, sortable: true, value: 'customerName' },
    { text: 'Account', align: 'left', width: 100, sortable: true, value: 'accountNumber', exportType: 'text' },
    { text: 'Direction', align: 'left', width: 100, sortable: true, value: 'direction' },
    { text: 'Transaction Date', width: 200, align: 'center', sortable: true, value: 'dateTime' },
    { text: 'Action ID', width: 110, align: 'left', sortable: true, value: 'actionId' },
    { text: 'Transaction ID', width: 200, align: 'left', sortable: true, value: 'transactionId', exportType: 'text' },
    { text: 'Detail', align: 'left', sortable: true, value: 'keyMessage' },
    { text: 'Actions', align: 'center', width: 100, sortable: false, value: 'actions' }
  ]

  protected handleFormSubmit (fields: any) {
    this.loadTransactions(fields)
  }

  protected async loadTransactions (filters: any) {
    try {
      this.loading = true
      this.transactions = await DataServicesTransactionsApi.loadAll({ filters: filters })
    } catch (err) {
      EventBus.$emit('app-snack', { message: err })
    } finally {
      this.loading = false
    }
  }

  private async handleShowQueueItem (item: DataServicesTransaction) {
    let comp = ''
    if (item.actionId === 'GAAE') {
      comp = 'enroll-queue-item'
    } else if (item.actionId === 'GAAH') {
      comp = 'hu-queue-item'
    } else {
      return
    }

    try {
      const response = await TransactionRequestsQueueApi.loadAll({ filters: { requestTransactionId: item.transactionId } })
      if (response.items.length === 0) {
        throw new Error('No matching request found')
      }

      this.handleDialog({
        component: comp,
        props: {
          title: 'Transaction Detail: ' + item.transactionId,
          item: response.items[0],
          isDialog: true
        }
      })
    } catch (err) {
      EventBus.$emit('app-snack', { message: err })
    }
  }

  private async handleRowClick (item: DataServicesTransaction) {
    if (item) {
      try {
        this.handleDialog({
          component: 'transaction-detail',
          props: {
            title: 'Transaction Detail: ' + item.transactionId,
            loadDetails: { accountId: item.accountId, transId: item.id },
            isDialog: true
          }
        })
      } catch (err) {
        EventBus.$emit('app-snack', { message: err })
      }
    }
  }

  public mounted () {
  }

  private handleDialog (dialogConfig: IStringIndexed) {
    this.dialogComponent = dialogConfig.component
    this.componentProps = dialogConfig.props || {}
    this.dialogProps = dialogConfig.dialogProps || { maxWidth: '50%', minHeight: '80%' }

    this.dialog = true
  }

  private handleCloseDialog () {
    this.dialog = false
    this.dialogComponent = ''
  }

  private handleViewProcessingLog (item: DataServicesTransaction) {
    this.handleDialog({
      component: 'transaction-processlog',
      dialogProps: {
        width: '100%'
      },
      props: {
        title: 'Processing Log',
        transaction: item,
        isDialog: true
      }
    })
  }

  private rowClass (item: DataServicesTransaction) {
    if (item.status >= EsgTransactionStatusEnum.ERROR_GENERAL) {
      return 'red lighten-3'
    }
    if (item.status === EsgTransactionStatusEnum.PARTIAL) {
      return 'yellow lighten-5'
    }

    if (item.status === EsgTransactionStatusEnum.PROCESSED) {
      return ''
    }

    return 'info lighten-5'
  }

  private handleExport () {
    this.exporting = true
    dtExporter(this.exportColumnHeaders, this.transactions, 'Transactions Export', 'Transactions-' + moment().format('YYYYMMDD-HHmm'))
    this.exporting = false
  }

  public get exportColumnHeaders () {
    return [
      ...this.headers.filter((i) => i.text !== 'Actions'),
      { text: 'Response Type', value: 'responseType' },
      { text: 'Rejection Reasons', value: 'rejectReasons' },
      { text: 'Start Date', value: 'startDate' },
      { text: 'End Date', value: 'endDate' },
      { text: 'Icap', value: 'icap' },
      { text: 'Rate Class', value: 'rateClass' },
      { text: 'Read Cycle', value: 'readCycle' },
      { text: 'Zone', value: 'zone' },
      { text: 'Net Meter', value: 'netMeter' }
    ]
  }
}
