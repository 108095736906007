import esalesClient, { filtersToQs, checkMessageOrThrow } from '@/api/clients/esales'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { InvoicesApi } from '@/modules/customers/api/invoices'
import { RequestParam } from '@/api/model'
import { File as FileModel } from '@/modules/shared/models/file'
import { MeterRead } from '@/modules/shared/models/account/meterread'
import { BillingMeterRead } from '../../models/meterread'

const loadAllParams = {
  additional: {
    0: 'account',
    1: 'customer',
    2: 'lineItem',
    3: 'billGroup',
    lineItem: {
      0: 'invoice'
    }
  }
}

export class UnbilledUsageApi {
  public async loadAll (filters?: RequestParam): Promise<{ total: number, items: Array<BillingMeterRead> }> {
    try {
      const url = 'billing-unbilled'

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: { filters: filters, ...loadAllParams }
      })

      if (response.status === 200 && response.data.usages) {
        const list: Array<BillingMeterRead> = []

        Object.values(response.data.usages as INumericDictionary<IStringIndexed>).forEach((i: IStringIndexed) => {
          list.push(new BillingMeterRead(i))
        })

        return { total: response.data.total, items: list }
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async performAction (item: MeterRead, doAction: string, data ?: IStringIndexed): Promise<{ item: MeterRead, messages?: Array<string> }> {
    try {
      const url = 'billing-unbilled/' + item.id

      if (!data) {
        data = {}
      }

      data.do = doAction
      data.snapshotAt = item.updatedAt

      const response: AxiosResponse = await esalesClient.put(url, { ...data, ...loadAllParams })

      // maybe 202(accepted) on success?
      if (response.status === 205) {
        return {
          item: item, // new MeterRead(await InvoicesApi.load(invoice.customerId, invoice.id, undefined, loadAllParams.additional)),
          messages: response.data.messages
        }
      } else if (response.status === 200 && response.data.usage) {
        return {
          item: new MeterRead(response.data.usage),
          messages: response.data.messages
        }
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async sendInvoices (invoices: Array<CustomerInvoice>): Promise<{ statusUpdates: IStringDictionary<string>, downloadFile?: FileModel, errorMessage?: string }> {
    try {
      const url = 'billing-invoices'
      const foo: Array<number> = []

      const data = {
        invoiceIds: invoices.reduce((c : Array<number>, i: CustomerInvoice) => { c.push(i.id); return c }, [] as Array<number>)
      }

      const response: AxiosResponse = await esalesClient.post(url, data)

      if (response.status === 200) {
        const resp: { statusUpdates: IStringDictionary<string>, downloadFile?: FileModel, errorMessage?: string } = {
          statusUpdates: response.data.statusUpdates
        }

        if (response.data.downloadDocument) {
          resp.downloadFile = new FileModel({ contents: response.data.downloadDocument })
        }

        if (response.data.error) {
          resp.errorMessage = response.data.error
        }

        return resp
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const UnbilledUsagesApi = new UnbilledUsageApi()
