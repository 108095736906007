import FinanceRoot from '../pages/Finance.vue'
import Reports from '../pages/subpages/Reports.vue'
import AchPayments from '../pages/subpages/AchPayments.vue'
import SalesCommissions from '../pages/subpages/SalesCommissions.vue'
import AchNew from '../components/payments/AchNew.vue'
import AchPending from '../components/payments/Pending.vue'
import AchHistory from '../components/payments/History.vue'
import GlobalCommReport from '../components/salescomms/GlobalCommReport.vue'
import PayCommissions from '../components/salescomms/Pay.vue'
import CommissionHistory from '../components/salescomms/PaymentHistory.vue'
import ReportViewer from '@/modules/shared/components/ReportViewer.vue'
import CreditQueue from '../pages/subpages/CreditQueue.vue'
import CreditQueueItem from '../components/CreditQueueItem.vue'
import RootSideNav from '../components/sidenavs/Root.vue'
import { Route } from 'vue-router'
import SettlementsApproval from '../pages/subpages/SettlementsApproval.vue'
import TaxExemptQueue from '../pages/subpages/TaxExemptQueue.vue'
import TaxExemptQueueItem from '../components/forms/exemptqueue/TaxExemptQueueItem.vue'
import PaymentUploads from '../pages/subpages/PaymentUploads.vue'

const rootNav = {
  key: 'root',
  width: 225
}

const pmtNav = {
  key: 'custpmts'
}

const commissionsNav = {
  key: 'salesComms',
  width: 200
}

export default [
  {
    name: 'financeroot',
    path: '/finance',
    components: {
      SideNav: RootSideNav,
      default: FinanceRoot
    },
    title: 'Finance',
    meta: {
      requiresAuth: 'FINANCE.VIEW',
      navs: {
        custpmts: {
          icon: 'up',
          title: 'Back',
          order: 0
        },
        salesComms: {
          icon: 'up',
          title: 'Back',
          order: 0
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'financecustomerpayments',
    path: '/finance/customer-payments',
    components: {
      SideNav: RootSideNav,
      default: FinanceRoot
    },
    title: 'Customer Payments',
    meta: {
      requiresAuth: 'FINANCE.PAYMENTS.CUSTOMER.VIEW',
      navs: {
        root: {
          icon: 'payments'
        }
      }
    },
    props: {
      nav: pmtNav
    }
  },
  {
    name: 'financecustomerach',
    path: '/finance/customer-payments/ach',
    title: 'ACH',
    meta: {
      requiresAuth: 'FINANCE.PAYMENTS.CUSTOMER.VIEW',
      navs: {
        custpmts: {
          icon: 'pay'
        }
      }
    },
    redirect: { name: 'financecustomerachnew' },
    components: {
      SideNav: RootSideNav,
      default: AchPayments
    },
    props: {
      nav: pmtNav
    },
    children: [
      {
        name: 'financecustomerachnew',
        path: '',
        meta: {
          requiresAuth: 'FINANCE.PAYMENTS.CUSTOMER.VIEW',
          navs: {
            custpmts2: {
              icon: 'pay'
            }
          }
        },
        components: {
          default: AchNew
        }
      },
      {
        name: 'financecustomerachpending',
        path: 'pending',
        meta: {
          requiresAuth: 'FINANCE.PAYMENTS.CUSTOMER.VIEW',
          navs: {
            custpmts2: {
              icon: 'pay'
            }
          }
        },
        components: {
          default: AchPending
        }
      },
      {
        name: 'financecustomerachhistory',
        path: 'history',
        meta: {
          requiresAuth: 'FINANCE.PAYMENTS.CUSTOMER.VIEW',
          navs: {
            custpmts2: {
              icon: 'pay'
            }
          }
        },
        components: {
          default: AchHistory
        }
      }
    ]
  },
  {
    name: 'financecustomercreditcard',
    title: 'Credit Card',
    path: '/finance/customer-payments/credit-card',
    meta: {
      requiresAuth: 'FINANCE.PAYMENTS.CUSTOMER.VIEW',
      navs: {
        custpmts: {
          icon: 'creditcard'
        }
      }
    },
    components: {
      SideNav: RootSideNav,
      default: FinanceRoot
    },
    props: {
      nav: pmtNav
    }
  },
  {
    name: 'financecustomerpaymentupload',
    title: 'Uploads',
    path: '/finance/customer-payments/uploads',
    meta: {
      requiresAuth: 'FINANCE.PAYMENTS.IMPORTS.VIEW',
      navs: {
        custpmts: {
          icon: 'import'
        }
      }
    },
    components: {
      SideNav: RootSideNav,
      default: PaymentUploads
    },
    props: {
      nav: pmtNav
    }
  },
  {
    name: 'financesalescommissions',
    path: '/finance/sales-commissions',
    components: {
      SideNav: RootSideNav,
      default: SalesCommissions
    },
    title: 'Sales Commissions',
    meta: {
      requiresAuth: 'FINANCE.COMMISSIONS.VIEW',
      navs: {
        root: {
          icon: 'commissions'
        }
      }
    },
    props: {
      nav: commissionsNav
    },
    children: [
      {
        name: 'financesalescommsdetail',
        path: 'global-commission-report',
        title: 'GCR',
        meta: {
          requiresAuth: 'FINANCE.COMMISSIONS.GLOBALCOMMISSIONREPORT.VIEW',
          navs: {
            salesComms: {
              icon: 'atlas'
            }
          }
        },
        components: {
          default: GlobalCommReport
        }
      },
      {
        name: 'financesalescommspay',
        path: 'pay',
        title: 'Pay Commissions',
        meta: {
          requiresAuth: 'FINANCE.COMMISSIONS.PAY',
          navs: {
            salesComms: {
              icon: 'payments'
            }
          }
        },
        components: {
          default: PayCommissions
        }
      },
      {
        name: 'financesalescommshistory',
        path: 'payment-history',
        title: 'Payment History',
        meta: {
          requiresAuth: 'FINANCE.COMMISSIONS.PAY',
          navs: {
            salesComms: {
              icon: 'hourglasshollow'
            }
          }
        },
        components: {
          default: CommissionHistory
        }
      }
    ]
  },
  {
    name: 'financereports',
    path: '/finance/reports',
    components: {
      SideNav: RootSideNav,
      default: Reports
    },
    title: 'Reports',
    meta: {
      requiresAuth: 'FINANCE.REPORTS.VIEW',
      navs: {
        root: {
          icon: 'report'
        }
      }
    },
    props: {
      nav: rootNav,
      default: (route: Route) => ({
        reportName: route.params.reportName
      })
    },
    children: [
      {
        name: 'financereportviewer',
        path: ':reportName',
        components: {
          default: ReportViewer
        },
        meta: {
          navs: {
            root: {
              icon: 'report'
            }
          }
        },
        props: {
          nav: {
            key: 'root'
          },
          default: (route: Route) => ({
            reportName: route.params.reportName
          })
        }
      }
    ]
  },
  {
    name: 'creditqueue',
    path: '/finance/credit-queue',
    components: {
      SideNav: RootSideNav,
      default: CreditQueue
    },
    title: 'Credit Check Queue',
    meta: {
      requiresAuth: 'FINANCE.CREDITQUEUE.VIEW',
      navs: {
        root: {
          icon: 'queue'
        }
      }
    },
    props: {
      nav: rootNav,
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      })
    },
    children: [
      {
        name: 'creditqueueitem',
        path: ':itemId(\\d+)',
        components: {
          default: CreditQueueItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'taxexemptqueue',
    path: '/dataservices/tax-exempt-queue',
    components: {
      SideNav: RootSideNav,
      default: TaxExemptQueue
    },
    title: 'Tax Exemption Queue',
    meta: {
      requiresAuth: 'FINANCE.QUEUES.TAXEXEMPT.VIEW',
      navs: {
        root: {
          icon: 'quote'
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
        // requestedStatusFilters: [TaxExemptItemStatusEnum.NEW, TaxExemptItemStatusEnum.INPROGRESS, TaxExemptItemStatusEnum.ONHOLD]
      }),
      nav: rootNav
    },
    children: [
      {
        name: 'taxexemptqitem',
        path: ':itemId(\\d+)',
        components: {
          default: TaxExemptQueueItem
        },
        meta: {
        },
        props: { }
      }
    ]
  },
  {
    name: 'settlementsapproval',
    path: '/finance/settlements-approval',
    components: {
      SideNav: RootSideNav,
      default: SettlementsApproval
    },
    title: 'Settlements Approval',
    meta: {
      requiresAuth: 'FINANCE.RESETTLEMENTS.VIEW',
      navs: {
        root: {
          icon: 'utility'
        }
      }
    },
    props: {
      nav: rootNav
    }
  }
]
