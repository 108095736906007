





import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'

@Component
export default class HistoricUsageFields extends Vue {
  @PropSync('request', { required: true })
  public localRequest!: TransactionRequest

  @Prop({ required: true })
  public account!: IAccount
}
