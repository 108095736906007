










import { Component, Vue } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import CustomerNote from '@/modules/customers/components/blocks/Note.vue'
import { Note } from '@/modules/shared/models/note'
import EventBus from '@/plugins/eventbus'

@Component({
  components: {
    'customer-note': CustomerNote
  }
})
export default class CustomerNotes extends Vue {
  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  private handleNewNote () {
    EventBus.$emit('open-app-dialog', { component: 'edit-customer-note', componentProps: { note: new Note(), noteList: CustomerState.customer.notes, saveMethod: 'SaveNote' }, dialogProps: { width: '100%' } })
  }

  public mounted () {
    if (CustomerState.loading.notes === null) {
      CustomerState.LoadNotes()
    }
  }
}
