










import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import router from '@/router'
import { UserCustomerNavsEnum } from '@/modules/shared/enums'

@Component
export default class TabNav extends Vue {
  public get customer () {
    return CustomerState.customer
  }

  public get menu () {
    return CustomerState.customerMenu
  }

  public get userWantsTabNav () {
    return AuthState.user.preferences.customer.nav === UserCustomerNavsEnum.TOP_TABS
  }

  private handleMenu (i: any) {
    if (i.click) {
      i.click()
    }
  }
}
