import { AppUserTypeEnum, StateAbbreviationsEnum, StatesAbbrevType, TimezoneType, UserCustomerNavsEnum } from '@/modules/shared/enums'
import { AppUserTypes } from '@/modules/shared/lists'
import { IStringDictionary, INumericDictionary, IStringIndexed } from '@/modules/shared/types'

export default class AppUser {
  public id!: number | null
  public firstName!: string | null
  public lastName!: string | null
  public emailAddress!: string | null
  public userType: keyof AppUserTypes = AppUserTypeEnum.USER
  public timezone: TimezoneType = 'America/New_York'
  public acl: IStringDictionary<boolean> = {}
  public configuration: IStringIndexed = {}
  public groupIds: Array<number> = []
  public preferences = {
    customer: {
      nav: UserCustomerNavsEnum.TOP_TABS
    }
  }

  constructor (props: Partial<AppUser>) {
    if (props) {
      if (typeof props.userType === 'number') {
        props.userType = props.userType.toString()
      }

      if (props.groupIds?.length) {
        props.groupIds = props.groupIds.map((i: number|string) => {
          if (typeof i === 'string') {
            return parseInt(i)
          }
          return i
        })
      }
    }

    Object.assign(this, props)
  }

  public get isAdmin (): boolean {
    return this.userType === AppUserTypeEnum.ADMIN
  }

  public get isSalesAdmin (): boolean {
    return this.userType === AppUserTypeEnum.USERADMIN
  }

  public get fullname () {
    return this.firstName + ' ' + this.lastName
  }

  public isAllowed (perm: string): boolean {
    if (this.acl[perm]) {
      return this.acl[perm]
    }
    return false
  }
}
