





























import { Component, Vue } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import router from '@/router'
import EventBus from '@/plugins/eventbus'
import { IStringIndexed } from '@/modules/shared/types'

@Component({
  components: {}
})
export default class SideNav extends Vue {
  private drawer = true
  private mini = true

  private get menu () {
    const menu: Array<IStringIndexed> = []

    CustomerState.customerMenu.forEach((m: IStringIndexed) => {
      if (m.permission) {
        if (AuthState.user.isAllowed(m.permission)) {
          menu.push(m)
        }
      } else if (m.permissions) {
        for (const p of m.permissions) {
          if (AuthState.user.isAllowed(p)) {
            menu.push(m)
            break
          }
        }
      } else {
        menu.push(m)
      }
    })

    return menu
  }
}
