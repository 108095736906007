















import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import EnrollAccountOptions from '@/modules/customermanagement/components/forms/EnrollAccountOptions.vue'
import { RatePlan } from '@/modules/customers/models/rateplan'
import AccountsApi from '@/modules/customers/api/accounts'

@Component({
  components: {
    'account-options': EnrollAccountOptions
  }
})
export default class EnrollmentFields extends Vue {
  @PropSync('request', { required: true })
  public localRequest!: TransactionRequest

  @Prop({ required: true })
  public account!: IAccount

  private availableRatePlans: Array<RatePlan> = []

  private asIntervalOptions = [
    { value: 'N', text: 'No' },
    { value: 'Y', text: 'Yes' }
  ]

  private secondaryHuOptions = [
    { value: 'N', text: 'No' },
    { value: 'Y', text: 'Summary' },
    { value: 'I', text: 'Interval' }
  ]

  protected set secondaryHuOption (selected: string) {
    this.localRequest.details.transactionConfig.secondaryRequestHu = selected === 'N' ? 'N' : 'Y'
    this.localRequest.details.transactionConfig.secondaryHuAsInterval = selected === 'I' ? 'Y' : 'N'
  }

  protected get secondaryHuOption () {
    if (this.localRequest.details.transactionConfig.secondaryRequestHu === 'N') {
      return 'N'
    }

    if (this.localRequest.details.transactionConfig.secondaryHuAsInterval === 'Y') {
      return 'I'
    }

    return 'Y'
  }

  private beforeMount () {
    if (!this.localRequest.details.transactionConfig?.asInterval) {
      this.localRequest.details.transactionConfig.asInterval = 'N'
    }
    if (!this.localRequest.details.transactionConfig?.secondaryRequestHu) {
      this.secondaryHuOption = 'Y'
    }
    this.availableRatePlans = []
    if (this.account.isP2c) {
      AccountsApi.loadAvailableRatePlans([this.localRequest.accountId]).then(resp => {
        this.availableRatePlans = resp
      })
    }
  }

  private async enrollmentOptions () {
    // ContractSubmissionsApi.getEnrollmentOptions(this.localRequest.id).then(resp => {
    //     this.enrollmentOptions = resp
    // })
  }
}
