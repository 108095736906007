
import { Component } from 'vue-property-decorator'
import { Note } from '@/modules/shared/models/note'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store'
import AccountSearchNamebarBase from './AccountSearchBase.vue'

@Component({
  components: {
  }
})
export default class InvoicesNamebar extends AccountSearchNamebarBase {
  protected pageIcon = 'billing'
  protected pageTitle = 'Invoices'
  protected actionIcon = ''
  protected actionTitle = ''

  protected handleAction () {
  }

  public mounted () {

  }
}
