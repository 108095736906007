


































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import Customer from '@/modules/customers/models/customer'

@Component
export default class CustomerNotes extends Vue {
  @Prop({ required: true })
  private customer!: Customer

  private get canAddNote () {
    return AuthState.user.isAllowed('CUSTOMER.NOTES.CREATE')
  }

  public mounted () {
  }
}
