






























import { Component, Vue } from 'vue-property-decorator'
// import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'

@Component
export default class AchPayments extends Vue {
  private errorMessage = ''

  public mounted () {

  }
}
