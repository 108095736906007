var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Certificate File")]),_c('v-card-text',{attrs:{"dense":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Status: ")]),_c('v-col',[_c('span',[_vm._v(" "+_vm._s(_vm.item.statusLabel)+" ")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Effective From: ")]),_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.item.effective,"label":"Effective Date","prepend-inner-icon":"$vuetify.icons.activityday","outlined":"","dense":"","readonly":""}},on))]}}]),model:{value:(_vm.effectiveMenu),callback:function ($$v) {_vm.effectiveMenu=$$v},expression:"effectiveMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","scrollable":""},on:{"input":function($event){_vm.effectiveMenu = false}},model:{value:(_vm.item.effective),callback:function ($$v) {_vm.$set(_vm.item, "effective", $$v)},expression:"item.effective"}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Expiration: ")]),_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.item.expires,"label":"Expiration Date","prepend-inner-icon":"$vuetify.icons.activityday","outlined":"","dense":"","readonly":""}},on))]}}]),model:{value:(_vm.expireMenu),callback:function ($$v) {_vm.expireMenu=$$v},expression:"expireMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","scrollable":""},on:{"input":function($event){_vm.expireMenu = false}},model:{value:(_vm.item.expires),callback:function ($$v) {_vm.$set(_vm.item, "expires", $$v)},expression:"item.expires"}})],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Reason: ")]),_c('v-col',[_c('v-select',{attrs:{"items":_vm.cchReasons,"dense":""},model:{value:(_vm.item.cchReason),callback:function ($$v) {_vm.$set(_vm.item, "cchReason", $$v)},expression:"item.cchReason"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" Type: ")]),_c('v-col',[_c('v-select',{attrs:{"items":_vm.listCchTypes,"multiple":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectedTypesLength === 1)?_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]):(index === 0)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" ["+_vm._s(_vm.selectedTypesLength)+" types selected] ")]):_vm._e()]}}]),model:{value:(_vm.item.cchTypes),callback:function ($$v) {_vm.$set(_vm.item, "cchTypes", $$v)},expression:"item.cchTypes"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" File: ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{on:{"click":_vm.handleFileDownload}},[_c('v-icon',[_vm._v("$vuetify.icons.report")]),_c('span',[_vm._v("  Click to view: "+_vm._s(_vm.item.fileId))])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }