import { Base } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'

export interface ISettlementFile extends IStringIndexed {
  id: number;
  customer: string;
  name: string;
  type: string;
  version: string;
  date: string;
  group: string;
  lastError: string
  dataRows: number;
}

export default class SettlementFile extends Base implements ISettlementFile {
  public id = 0
  public customer = ''
  public dataRows = 0
  public date = ''
  public group = ''
  public lastError = ''
  public name = ''
  public type = ''
  public version = ''

  constructor (props: Partial<ISettlementFile> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'dataRows':
            props[p] = parseInt(props[p]?.toString() || '0')
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get simpleVersion () {
    return moment(this.version).format('YYYY-MM-DD')
  }
}
