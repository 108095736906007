import { Commodities, ServiceAccountStatuses, ServiceAccountHuStatuses } from '../../lists'
import { CommodityEnum, ServiceAccountStatusEnum, ServiceAccountHuStatusEnum } from '../../enums'
import { Base } from '../base'
import { Address } from '../address'
import { IAccount } from '../interfaces/account'
import { IStringDictionary, IStringIndexed } from '../../types'
import { PeriodDetail } from '@/modules/customers/models/account/perioddetail'
import { MarketDetail } from '@/modules/customers/models/account/marketdetail'
import { MeterRead } from './meterread'
import { Transaction } from '@/modules/customers/models/account/transaction'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { Utility } from '../utility'
import { AppState } from '@/stores/appStore'
import moment from 'moment'

export class CommodityAccount extends Base implements IAccount {
  static flowStatii = new ServiceAccountStatuses()
  static huStatii = new ServiceAccountHuStatuses()

  public id: number = 0
  public p2cId: number = 0
  public accountNumber = ''
  public annualLoad = 0
  public annualLoadUnits = 'kWh'
  public billCopyId = 0
  public billMonth = ''
  public address: Address = new Address()
  public commodity: keyof Commodities = CommodityEnum.E
  public desiredSwitch = ''
  public keyName = ''
  public meterNumber = ''
  public rateClass = ''
  public utilityId = 0
  public readCycle = ''
  public status: keyof ServiceAccountStatuses = ServiceAccountStatusEnum.NEW
  public usageStatus: keyof ServiceAccountHuStatuses = ServiceAccountHuStatusEnum.INACTIVE
  public usage: IStringIndexed = {}
  public reads: Array<MeterRead> = []
  public transactions: Array<Transaction> = []
  public pending: Array<TransactionRequest> = []
  public periodDetails: Array<PeriodDetail> = []
  public marketDetails: MarketDetail = new MarketDetail()
  public transactionRequests: Array<TransactionRequest> = []

  constructor (props?: Partial<IAccount>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'annualLoadUnits':
            if (!props[p]) {
              props[p] = 'kWh'
            }
            break
          case 'address':
            props[p] = new Address(props[p])
            break
          case 'marketDetails':
            props[p] = new MarketDetail(props[p])
            break
          case 'periodDetails':
            if (props[p]) {
              let list = props[p]
              if (!Array.isArray(list)) {
                list = Object.values(props[p] as IStringIndexed)
              }
              const pds: Array<PeriodDetail> = []
              for (const pd of list) {
                pds.push(new PeriodDetail(pd))
              }
              props[p] = pds
            }
            break
          case 'reads':
            if (props[p]) {
              let list = props[p]
              if (!Array.isArray(list)) {
                list = Object.values(props[p] as IStringIndexed)
              }
              const pds: Array<MeterRead> = []
              for (const pd of list) {
                pds.push(new MeterRead(pd))
              }
              props[p] = pds
            }
            break
          case 'transactions':
            if (props[p]) {
              let list = props[p]
              if (!Array.isArray(list)) {
                list = Object.values(props[p] as IStringIndexed)
              }
              const trs: Array<Transaction> = []
              for (const tr of list) {
                trs.push(new Transaction(tr))
              }
              props[p] = trs
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get isElectric (): boolean {
    return this.commodity === CommodityEnum.E
  }

  public get statusLabel (): string {
    return CommodityAccount.flowStatii[this.status]
  }

  public get usageStatusLabel (): string {
    return CommodityAccount.huStatii[this.usageStatus]
  }

  public get isFlowing (): boolean {
    return this.status === ServiceAccountStatusEnum.ACTIVE || this.status === ServiceAccountStatusEnum.PENDING_DROP
  }

  public get isP2c () {
    return this.p2cId > 0 && moment(this.utility.cisCutoverAt) < moment()
  }

  public get utility (): Utility {
    return AppState.utilities[this.utilityId]
  }

  public get canceledReads () {
    return this.reads.filter((a: MeterRead) => this.reads.find((b: MeterRead) => b.cancels(a)) !== undefined)
  }

  public get rebillReads () {
    return this.reads.filter((a: MeterRead) => this.reads.find((b: MeterRead) => a.rebills(b)) !== undefined)
  }
}
