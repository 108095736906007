export default class FSCValidation {
  public static required (ignoreVal?: any, msg = ''): (v: string) => string|boolean {
    if (!msg.length) {
      msg = 'This field is required'
    }

    return (v: string) => {
      if (v === null || v === undefined) {
        return msg
      }

      if (typeof v !== 'string') {
        v = (<any>v).toString()
      }

      if (v === ignoreVal) {
        return msg
      }

      return !!v.trim() || msg
    }
  }

  public static requiredIf (otherValue: string, msg = ''): (v: string) => string|boolean {
    if (!msg.length) {
      msg = 'This field is required'
    }

    return (v: string) => {
      if (typeof (v || '') !== 'string') {
        v = (<any>v).toString()
      }

      return !(otherValue?.toString() || '').trim() || ((otherValue?.toString() || '').trim() && !!(v || '').trim()) || msg
    }
  }

  public static requiredIfNot (otherValue: string, msg = ''): (v: string) => string|boolean {
    if (!msg.length) {
      msg = 'This field is required'
    }

    return (v: string) => {
      if (typeof (v || '') !== 'string') {
        v = (<any>v).toString()
      }

      return (!!(otherValue || '').trim() || !!(v || '').trim()) || msg
    }
  }

  public static minDigits (len: number, allowEmpty = true, msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'This field must be longer than ' + len + ' digits'
    }

    return (v: string) => ((allowEmpty && !v.length) || v.replace(/[^0-9]/g, '').length >= len) || msg
  }

  public static maxDigits (len: number, allowEmpty = true, msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'This field must be no longer than ' + len + ' digits'
    }

    return (v: string) => ((allowEmpty && !v.length) || v.replace(/[^0-9]/g, '').length <= len) || msg
  }

  public static min (len: number, allowEmpty = true, msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'This field must be longer than ' + len + ' characters'
    }

    return (v: string) => ((allowEmpty && !v.length) || v.length >= len) || msg
  }

  public static max (len: number, msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'This field must be less than ' + len + ' characters long'
    }

    return (v: string) => v.length <= len || msg
  }

  public static between (min: number, max: number, msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'This field must be between ' + min + ' and ' + max + ' characters long'
    }

    return (v: string) => (v.length >= min && v.length <= max) || msg
  }

  public static lessThan (amt: number, msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'This field must be less than ' + amt
    }

    return (v: string) => parseFloat(v) < amt || msg
  }

  public static greaterThan (amt: number, msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'This field must be greater than ' + amt
    }

    return (v: string) => parseFloat(v) > amt || msg
  }

  public static email (msg = ''): (v: string) => string | boolean {
    if (!msg.length) {
      msg = 'Please enter a valid email address'
    }

    return (v: string) => !v?.trim().length || /(.+)@(.+){2,}\.(.+){2,}/.test(v) || msg
  }

  public static regex (regex: string|RegExp, msg = ''): (v: string) => string | boolean {
    if (!regex) {
      throw new Error('You must specify a regex for this validator')
    }

    if (!msg.length) {
      msg = 'Value must match the pattern: ' + regex
    }

    return (v: string) => {
      if (!v) { return true }
      const m = v.match(regex)

      return (m && m.length > 0) || msg
    }
  }

  public static match (otherValue: string, msg = ''): (v: string) => string|boolean {
    if (!msg.length) {
      msg = 'This field must match the prior field'
    }

    return (v: string) => {
      if (typeof (v || '') !== 'string') {
        v = (<any>v).toString()
      }

      return otherValue === v || msg
    }
  }
}
