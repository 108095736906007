




















import { Component, Vue } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'

@Component
export default class Logout extends Vue {
  public get heightBySize () {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 200
    }
    return 250
  }

  public created () {
    AuthState.Logout()
  }
}
