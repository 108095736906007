






































import { Component, Vue, Prop } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import Customer from '@/modules/customers/models/customer'
import { AuthState } from '@/modules/auth/store'
import Rules from '@/plugins/validations'
import customerApi from '@/modules/customers/api/customer'
import AddressFormFields from './fields/Address.vue'
import CustomerFlagFormFields from './fields/Flags.vue'
import CustomerClassificationFormFields from './fields/Classifications.vue'
import CustomerStatusFormFields from './fields/Status.vue'
import CustomerGeneralFormFields from './fields/General.vue'

@Component({
  components: {
    'general-fields': CustomerGeneralFormFields,
    'address-fields': AddressFormFields,
    'flag-fields': CustomerFlagFormFields,
    'status-fields': CustomerStatusFormFields,
    'classification-fields': CustomerClassificationFormFields
  }
})
export default class CustomerInfoForm extends Vue {
  @Prop({ required: true })
  private customer!: Customer

  private errorMessage = ''
  private saving = false
  private noErrors = true

  private localCustomer: Customer = new Customer()

  private formRules = {
    address: {
      street: [Rules.required()],
      city: [Rules.required()],
      state: [Rules.required()],
      zip: [Rules.required()]
    }
  }

  private get canEditCustomer () {
    return AuthState.user.isAllowed('CUSTOMER.EDIT')
  }

  public handleSubmit (): void {
    if (this.saving) {
      return
    }

    if (!(this.$refs.editCustomerInfoForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    customerApi.save(this.localCustomer)
      .then((person: Customer) => {
        person.activities = this.localCustomer.activities
        person.contacts = this.localCustomer.contacts
        person.notes = this.localCustomer.notes

        CustomerState.Update(person)
        this.$emit('customer:updated', person)
        this.handleClose()
      })
      .catch(err => {
        this.errorMessage = err
      })
      .finally(() => {
        this.saving = false
      })
  }

  public get canSave () {
    if (this.localCustomer.id) {
      return AuthState.user.isAllowed('CUSTOMER.EDIT')
    }
    return AuthState.user.isAllowed('CUSTOMER.CREATE')
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localCustomer = this.customer.clone()
  }

  public mounted () {
    this.localCustomer = this.customer.clone()
  }
}
