





















import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import Rules from '@/plugins/validations'
import { AppState } from '@/stores/appStore'
import { IStringIndexed } from '@/modules/shared/types'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { BillMethods } from '@/modules/shared/lists'

@Component
export default class ChangeFields extends Vue {
  @PropSync('request', { required: true })
  public localRequest!: TransactionRequest

  @Prop({ required: true })
  public account!: IAccount

  private valRules = Rules

  private billTypes = mapObjectVuetifySelect(new BillMethods(), true)

  private changeReasons = [
    { value: 'billType', text: 'Change Bill Type', optionType: 'list', optionList: this.billTypes },
    { value: 'espRateCode', text: 'Change ESP Rate Code', optionType: 'list', optionList: this.espRateCodes },
    { value: 'espAccountNumber', text: 'Change ESP Account Number', optionType: 'string' },
    { value: 'taxExemptPercent', text: 'Change Tax Exempt Percentage', optionType: 'decimal' },
    { value: 'pricingCategory', text: 'Change Pricing Category', optionType: 'list', optionList: this.pricingCategories }
  ]

  /** @todo: load these along with utility options */
  private get espRateCodes () {
    return [{ value: '071' }, { value: '072' }, { value: '073' }]
  }

  private get pricingCategories () {
    return [{ value: '071' }, { value: '072' }, { value: '073' }]
  }

  private get filteredChangeReasons () {
    const utilCodes: IStringIndexed|null = AppState.utilities[this.account.utilityId].getSetting('change_reason_codes') as IStringIndexed|null

    if (!utilCodes) {
      return this.changeReasons
    }

    if (utilCodes.list) {
      return this.changeReasons.filter(c => utilCodes.list.indexOf(c.value) !== -1)
    }

    if (utilCodes.exclude) {
      return this.changeReasons.filter(c => utilCodes.exclude.indexOf(c.value) === -1)
    }
    return this.changeReasons
  }

  private beforeMount () {

  }
}
