


















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import { IStringIndexed } from '@/modules/shared/types'
import { AccountInvoiceStatusEnum, ChargesEnum, CustomerInvoiceStatusEnum, CustomerPaymentApplicationStatusEnum, CustomerPaymentApplicationTypeEnum, CustomerPaymentTypeEnum } from '@/modules/shared/enums'
import { CustomerInvoice } from '../../models/customer/invoice'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import dtExporter from '@/modules/shared/datatablehelper'
import moment from 'moment'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class Statement extends Vue {
  @Prop({ required: false })
  private paymentId!: number

  private errorMessage = ''

  private onlyOpen = true
  private inclPmtHistory = false
  private targetPayment = null

  private itemsPerPage = -1

  private headers: Array<any> = [
    { text: 'Invoice #', align: 'center', sortable: false, value: 'invoiceNumber' },
    { text: 'Date', align: 'center', sortable: false, value: 'itemDate', exportType: 'date' },
    { text: 'Type', align: 'center', sortable: false, value: 'itemType' },
    { text: 'Bill Method', align: 'right', sortable: false, value: 'billMethod' },
    { text: 'ID', align: 'right', sortable: false, value: 'itemId' },
    { text: 'Parent #', align: 'right', sortable: false, value: 'parentId' },
    { text: 'Ref #', align: 'right', sortable: false, value: 'itemReference', exportType: 'text' },
    { text: 'LDC #', align: 'right', sortable: false, value: 'accountNumber', exportType: 'text' },
    { text: 'Due Date', align: 'left', sortable: false, value: 'dueDate', exportType: 'date' },
    { text: 'Charge', align: 'right', sortable: false, value: 'chargeAmt', exportType: 'money' },
    { text: 'Payment', align: 'right', sortable: false, value: 'paymentAmt', exportType: 'money' },
    { text: 'Balance', align: 'right', sortable: false, value: 'balanceAmt', exportType: 'money' },
    { text: 'Start Date', align: 'right', sortable: false, value: 'usageStart', exportType: 'date' },
    { text: 'End Date', align: 'right', sortable: false, value: 'usageEnd', exportType: 'date' }
  ]

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  public get customerPayments () {
    return this.customer.payments.map(p => ({
      value: p.id,
      text: '(' + p.id + ') ' + p.referenceNumber + ' (' + p.payDate + ')'
    }))
  }

  public get filteredItems () {
    const allPmts: Array<IStringIndexed> = []
    const allItems: Array<IStringIndexed> = []
    const targetInvs: Array<number> = []

    this.customer.payments.forEach(cp => {
      cp.applications.forEach(a => {
        if (!this.inclPmtHistory && (a.type === CustomerPaymentApplicationTypeEnum.REVERSAL || a.status === CustomerPaymentApplicationStatusEnum.REVERSED)) {
          return
        }

        if (this.targetPayment) {
          if (cp.id === this.targetPayment && targetInvs.indexOf(a.invoiceId as number) === -1) {
            targetInvs.push(a.invoiceId as number)
          }
        }

        allPmts.push({
          itemId: a.id,
          itemDate: a.applicationDate,
          itemType: this.getPmtType(cp.type),
          typeId: a.type,
          status: a.status,
          parentId: cp.id,
          itemReference: a.referenceNumber,
          paymentAmt: a.amount,
          void: cp.voidDate,
          invoiceId: a.invoiceId,
          createdAt: a.createdAt
        })
      })
    })

    if (this.targetPayment && !targetInvs.length) {
      return []
    }

    const sortedPmts = allPmts.sort((a, b) => {
      if (a.createdAt === b.createdAt) {
        return 0
      }
      return b.createdAt > a.createdAt ? 1 : -1
    })

    this.customer.invoices.forEach((ci: CustomerInvoice) => {
      if (ci.status < CustomerInvoiceStatusEnum.STATUS_APPROVED) {
        return
      }
      ci.invoices.forEach(si => {
        if (this.onlyOpen && si.openBalance === 0) {
          return
        }
        if (this.onlyOpen && si.isVoid) {
          return
        }

        if (targetInvs.length && targetInvs.indexOf(si.id) === -1) {
          return
        }

        allItems.push({
          itemId: si.id,
          itemDate: si.invoiceDate,
          itemType: 'INV',
          billMethod: si.billMethodLabel,
          parentId: ci.id,
          accountNumber: this.customer.accounts.find(a => a.id === si.accountId)?.accountNumber,
          dueDate: si.dueDate,
          chargeAmt: si.invoiceTotal,
          balanceAmt: si.openBalance,
          void: si.voidDate,
          usageStart: si.lineItems.find(l => l.chargeId === ChargesEnum.ENERGY)?.startDate || '',
          usageEnd: si.lineItems.find(l => l.chargeId === ChargesEnum.ENERGY)?.endDate || '',
          status: si.status,
          invoiceNumber: ci.invoiceNumber
        })

        allItems.push(...sortedPmts.filter(a => a.invoiceId === si.id))
      })
    })

    return allItems
  }

  public get totalOpenBalance () {
    return this.filteredItems.reduce((ob, i) => ob + (i.void ? 0 : (i.balanceAmt || 0)), 0)
  }

  public get totalPastDueBalance () {
    return this.filteredItems.filter(i => i.itemType === 'INV' && i.status === AccountInvoiceStatusEnum.STATUS_PASTDUE)
      .reduce((ob, i) => ob + (i.balanceAmt || 0), 0)
  }

  public get totalCreditBalance () {
    return this.customer.payments.reduce((ob, cp) => ob + (cp.void || cp.isPrepayment ? 0 : (cp.openBalance || 0)), 0)
  }

  public get prePaymentBalance () {
    return this.customer.payments.reduce((ob, cp) => ob + (!cp.isPrepayment ? 0 : (cp.openBalance || 0)), 0)
  }

  public calcRowClass (item: IStringIndexed) {
    let rowClass = ''
    if (item.itemType === 'INV') {
      if (item.void) {
        rowClass += ' text-decoration-line-through'
      }
    } else if (item.itemType === 'PMT') {
      rowClass = 'grey lighten-2'
      if (item.typeId === CustomerPaymentApplicationTypeEnum.REVERSAL || item.status === CustomerPaymentApplicationStatusEnum.REVERSED) {
        rowClass += ' text-decoration-line-through'
      }
    } else if (item.itemType === 'TTL') {
      rowClass = 'blue lighten-5'
    }

    return rowClass
  }

  public getPmtType (type: number) {
    switch (type) {
      case CustomerPaymentTypeEnum.PAYMENT:
        return 'PMT'
      case CustomerPaymentTypeEnum.CREDIT:
        return 'CRD'
      case CustomerPaymentTypeEnum.WRITEOFF:
        return 'WBD'
    }
  }

  private exportList () {
    const allItems = this.filteredItems
    allItems.push(
      { paymentAmt: 'Total Open Balance', balanceAmt: this.totalOpenBalance },
      { paymentAmt: 'Total PD Balance', balanceAmt: this.totalPastDueBalance },
      { paymentAmt: 'Credit Balance', balanceAmt: this.totalCreditBalance }
    )
    dtExporter(this.headers, allItems, 'Customer Register Export', 'Customer-Register-' + moment().format('YYYYMMDD-HHmm'))
  }

  @Watch('loading.payments')
  private handleLoadingChange () {
    if (!this.loading.payments) {
      if (CustomerState.loading.payments === null) {
        CustomerState.LoadPayments()
      }

      if (CustomerState.loading.invoices === null) {
        CustomerState.LoadInvoices()
      }
    }
  }

  public async mounted () {
    if (CustomerState.loading.payments === null) {
      await CustomerState.LoadPayments()
    }

    if (CustomerState.loading.invoices === null) {
      await CustomerState.LoadInvoices()
    }
  }
}
