import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosInstance, AxiosResponse } from 'axios'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import ModelApi from '@/api/model'
import { FinanceUsageResettlementSummary, UsageResettlement } from '../models/usageresettlement'

export class SettlementApprovalApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['finance-resettlements']
    this.singular = 'resettlement'
    this.plural = 'resettlements'
    this.factory = (...args: any) => new UsageResettlement(...args)
  }

  public async loadAll (config?: IStringDictionary<IStringDictionary<string|number|Array<string|number> | IStringDictionary<string|number|Array<string|number>>>>): Promise<{ total: number, items: Array<FinanceUsageResettlementSummary> }> {
    const response = await this.loadAllSpecific(arguments, config) as { total: number, items: Array<UsageResettlement> }

    return {
      total: response.total,
      items: FinanceUsageResettlementSummary.fromList(response.items)
    }
  }

  public async load (id: number, onlyProps?: Array<string>): Promise<UsageResettlement> {
    return await this.loadSpecific(arguments, onlyProps) as UsageResettlement
  }

  public async save (item: UsageResettlement, noteText?: string) : Promise<UsageResettlement> {
    const data: IStringIndexed = { ...item }
    const args = Array.from(arguments).slice(0, -1)

    return await this.saveSpecific(args, data) as UsageResettlement
  }

  public async savePartial (item: UsageResettlement, props: Array<string>) : Promise<UsageResettlement> {
    const data: IStringIndexed = { ...item }

    return await this.savePartialSpecific([item.id], data, props) as UsageResettlement
  }

  public async loadHourly (resettlementId: Number) : Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-resettlements/' + resettlementId + '/hourly')
      if (response.status === 200 && response.data.details) {
        return response.data.details
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const SettlementsApprovalApi = new SettlementApprovalApi(esalesClient)
