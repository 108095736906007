import { Base } from './../base'
import { IStringIndexed } from '../../types'

export interface IRateClass extends IStringIndexed {
  id: number;
  utilityId: number;
  rateClass: string;
  description: string;
  aliases: string;
  isComm: number;
  isResi: number;
  isLighting: number;
  lossFactorId: number;
}

export class RateClass extends Base implements IRateClass {
  public id = 0
  public utilityId = 0
  public rateClass = ''
  public description = ''
  public aliases = ''
  public isComm = 0
  public isResi = 0
  public isLighting = 0
  public lossFactorId = 0

  constructor (props?: Partial<IRateClass>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'utilityId':
          case 'isComm':
          case 'isResi':
          case 'isLighting':
          case 'lossFactorId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
