


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import { BankAccountLog } from '@/modules/customers/models/customer/bankaccount/log'
import { BankAccountLogApi } from '@/modules/customers/api/bankaccountlogs'
import EventBus from '@/plugins/eventbus'
import moment from 'moment'

@Component({
  components: {
  }
})
export default class ViewLog extends Vue {
  @Prop({ required: true })
  private account!: BankAccount

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'When', align: 'left', sortable: true, value: 'viewedAt' },
    { text: 'Who', align: 'left', sortable: true, value: 'viewedBy' },
    { text: 'Why', align: 'left', sortable: false, value: 'businessNeed', width: '70%' }
  ]

  private loading: boolean = false

  private items: Array<BankAccountLog> = []

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.value !== 'Actions' })
    }

    return headers
  }

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private viewedAt (time: number) {
    return moment.unix(time).format('dddd, MMM Do YYYY @ h:mm A')
  }

  private viewedBy (id: number) {
    return AppState.cisUserNames[id]
  }

  public handleClose (): void {
    this.$emit('dialog:close')
  }

  public async mounted () {
    try {
      this.loading = true

      this.items = await BankAccountLogApi.loadAll(this.account.customerId, this.account.id)
    } catch (err) {
      EventBus.$emit('app-snack', { message: err })
    } finally {
      this.loading = false
    }
  }
}
