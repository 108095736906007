import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse } from 'axios'
import { ISearchResult } from '../models/searchresult'
import { IStringIndexed } from '@/modules/shared/types'

export default class CustomerSearchApi {
  public static async search (term: string): Promise<Array<ISearchResult>> {
    try {
      const response: AxiosResponse = await esalesClient.get('customers', {
        params: {
          term: term
        }
      })
      if (response.status === 200 && <Array<ISearchResult>> response.data.results) {
        return <Array<ISearchResult>> response.data.results
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async advancedSearch (filters: IStringIndexed): Promise<Array<ISearchResult>> {
    try {
      const response: AxiosResponse = await esalesClient.get('customers' + filtersToQs(filters))

      if (response.status === 200 && <Array<ISearchResult>> response.data.results) {
        return <Array<ISearchResult>> response.data.results
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
