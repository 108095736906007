import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { CircleOption } from '@syncfusion/ej2-vue-charts'

export interface IPermission extends IStringIndexed {
  id: string
  name: string;
  description: string;
}

export default class Permission extends Base implements IPermission {
  public id = ''
  public name = ''
  public description = ''

  constructor (props: Partial<IPermission> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  /**
   * USERS.ACCESS
   * USERS.BANKING.ACCESS
   * CUSTOMER.FINANCE.PAYMENTS.ACCESS
   * CUSTOMER.PAYMENTS.CREDITCARD.VIEW
   * @param permissions
   */
  public static groupPermissions (permissions: Array<Permission>) : Array<IStringIndexed> {
    const getChildren = (id: string) : Array<IStringIndexed> => {
      const matches = permissions.filter(p => p.id.indexOf(id + '.') === 0)
      if (!matches) {
        return []
      }

      const dotPos = id.length + 1

      const children: Array<IStringIndexed> = []
      matches.forEach(m => {
        const rem = m.id.substr(dotPos).split('.')

        if (rem.length === 1) {
          const item = {
            id: m.id,
            name: rem[0] + ' - ' + m.name,
            group: false,
            disabled: m.disabled ?? false,
            children: []
          }

          children.push(item)
        } else {
          const subkey = id + '.' + rem[0]
          if (!children.find(c => c.id === subkey)) {
            const item = {
              id: subkey,
              name: rem[0],
              group: rem.length > 1,
              disabled: m.disabled ?? false,
              children: rem.length === 1 ? [] : getChildren(subkey)
            }

            item.children.sort((l, r) => l.id.localeCompare(r.id))

            children.push(item)
          }
        }
      })
      return children
    }

    permissions.sort((l, r) => l.id.split('.').length - r.id.split('.').length)

    const groups: Array<IStringIndexed> = []

    permissions.forEach((i: Permission) => {
      const parts = i.id.split('.')
      if (parts.length === 1) {
        groups.push({
          id: i.id,
          name: i.name,
          group: false,
          disabled: i.disabled ?? false,
          children: []
        })
      } else {
        if (!groups.find(g => g.id === parts[0])) {
          groups.push({
            id: parts[0],
            name: parts[0],
            group: true,
            disabled: i.disabled ?? false,
            children: getChildren(parts[0])
          })
        }
      }
    })

    groups.sort((l, r) => l.id.localeCompare(r.id))

    return groups
  }
}
