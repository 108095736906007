












































import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator'
import { ElectricAccount } from '@/modules/shared/models/account/electric'

@Component
export default class SelectServiceAccountsForm extends Vue {
  @Prop({ default: () => 0 })
  private parentId!: number

  @Prop({ default: () => [] })
  private value!: Array<number>

  @Prop({ default: () => [] })
  private allAccounts!: Array<ElectricAccount>

  @Prop({ default: () => false })
  private locked!: boolean

  private accountIds: Array<number> = []
  private originalAccountIds: Array<number> = []

  @Watch('accountIds')
  private handleAssignmentChange () {
    this.$emit('input', this.accountIds)
  }

  @Watch('parentId')
  private handleParentChanged (oldId: number, newId: number) {
    Vue.set(this, 'originalAccountIds', this.value)
    Vue.set(this, 'accountIds', this.value)
  }

  public mounted () {
    Vue.set(this, 'originalAccountIds', this.value)
    Vue.set(this, 'accountIds', this.value)
  }
}
