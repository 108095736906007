import esalesClient, { filtersToQs, checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import crmClient from '@/api/clients/crm'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { ContractSubmission } from '@/modules/customermanagement/models/queue/contractsubmission'
import { INumericDictionary, INumericIndexed, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { IFile, File } from '@/modules/shared/models/file'
import { PaymentMethods } from '@/modules/shared/lists'
import { Contract } from '@/modules/customers/models/customer/contract'
import { ITransactionRequest, TransactionRequest } from '@/modules/customers/models/account/transactionrequest'

interface IFileResponse {
  file: IFile;
}

export interface IOnespan {
  package: IStringIndexed,
  fields: Array<IStringIndexed>,
  files: Array<File>
}

export interface EnrollmentOptions {
  accountMessages: Map<number, string>,
  requests: Map<number, TransactionRequest>,
  options: Map<number, {
    skip: Array<string>,
    earliest: string
  }>
}

export class ContractSubmissionApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['queue-contract-submissions']
    this.singular = 'item'
    this.plural = 'items'
    this.factory = (...args: any) => new ContractSubmission(...args)
  }

  public async loadAll (config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<ContractSubmission>> {
    return await this.loadAllSpecific(arguments, config) as Array<ContractSubmission>
  }

  public async load (id: number, onlyProps?: Array<string>): Promise<ContractSubmission> {
    return await this.loadSpecific(arguments, onlyProps) as ContractSubmission
  }

  public async save (item: ContractSubmission, noteText?: string) : Promise<ContractSubmission> {
    const data: IStringIndexed = { ...item }

    if (noteText) {
      data.note = {
        source: 'CIS',
        body: noteText
      }
    }

    const args = Array.from(arguments).slice(0, -1)

    return await this.saveSpecific(args, data) as ContractSubmission
  }

  public async savePartial (item: ContractSubmission, props: Array<string>) : Promise<ContractSubmission> {
    const data: IStringIndexed = { ...item }

    return await this.savePartialSpecific([item.id], data, props) as ContractSubmission
  }

  public async split (item: ContractSubmission, noteText?: string) : Promise<{ item: ContractSubmission, items?: Array<ContractSubmission>}> {
    try {
      const data = { ...item }

      if (noteText) {
        data.note = {
          source: 'CIS',
          body: noteText
        }
      }

      let method = 'POST'
      if (data[this.idCol]) {
        method = 'PUT'
      }

      const arrArgs = Array.from(arguments).slice(0, -2)

      const response: AxiosResponse = await this.client.request({
        url: this.buildUrl(arrArgs),
        method: <Method> method,
        data: data
      })

      if (response.status === 200) {
        if (response.data[this.singular]) {
          const payload: { item: ContractSubmission, items?: Array<ContractSubmission>} = {
            item: this.factory(response.data[this.singular])
          }

          if (response.data[this.plural]) {
            const items: Array<ContractSubmission> = []
            for (const i of response.data[this.plural]) {
              items.push(this.factory(i))
            }
            payload.items = items
          }

          return payload
        }
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async finalize (item: ContractSubmission, type: string, note?: string) : Promise<ContractSubmission> {
    const data: IStringIndexed = { ...item }
    data.finalized = type

    const props = ['id', 'step', 'customerShell', 'finalized']

    if (note) {
      data.note = { body: note }
      props.push('note')
    }

    return await this.savePartialSpecific([item.id], data, props) as ContractSubmission
  }

  public async saveNote (item: ContractSubmission, noteBody: string) : Promise<ContractSubmission> {
    try {
      const response: AxiosResponse = await this.client.request({
        url: 'queue-contract-submissions/' + item.id + '/notes',
        method: <Method> 'POST',
        data: {
          note: noteBody,
          source: 'CIS'
        }
      })

      if (response.status === 200 && response.data.note) {
        item.notes.unshift(response.data.note)
        return item
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async attachFile (itemId: number, file: File, callback?: Function): Promise<File> {
    try {
      const method = 'POST'

      const data = { ...file }
      data.contents = btoa(file.contents)

      const response: AxiosResponse = await this.client.request(
        {
          url: 'queue-contract-submissions/' + itemId + '/files',
          method: <Method> method,
          data: data,
          onUploadProgress: (e) => {
            if (callback) {
              callback(e)
            }
          }
        }
      )

      if (response.status === 200 && response.data.file) {
        response.data.file.contents = ''
        return new File((<IFileResponse> response.data).file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCrmMeters (accountIds: Array<number>): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await crmClient.get('meters' + filtersToQs({ id: accountIds }))
      if (response.status === 200) {
        return response.data.meters
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCrmFiles (fileIds: Array<number>): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await crmClient.get('files' + filtersToQs({ id: fileIds }) + '&additional[]=categoryLabel')
      if (response.status === 200) {
        return response.data.files
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCisFiles (itemId: number, fileIds: Array<number>): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await this.client.get('queue-contract-submissions/' + itemId + '/files' + filtersToQs({ id: fileIds }) + '&additional[]=categoryLabel')
      if (response.status === 200) {
        const files = []
        for (const d of response.data.files) {
          files.push(new File(d))
        }
        return files
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCrmFile (fileId: number): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await crmClient.get('files/' + fileId.toString())
      if (response.status === 200) {
        return response.data
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCisFile (checkId: number, fileId: number): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await this.client.get('queue-contract-submissions/' + checkId + '/files/' + fileId.toString())
      if (response.status === 200) {
        return response.data.file
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadEsignFiles (itemId: number, packageId: string): Promise<IOnespan> {
    try {
      const response: AxiosResponse = await this.client.get('queue-contract-submissions/' + itemId + '/esign/' + packageId)
      if (response.status === 200) {
        const files = []

        for (const i in response.data.documents) {
          files.push(new File({ id: i, ...response.data.documents[i] }))
        }
        return {
          package: response.data.package,
          fields: response.data.fields,
          files: files
        } as IOnespan
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async esignPackage (itemId: number, packageId: string): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await this.client.request({
        url: 'queue-contract-submissions/' + itemId + '/esign/' + packageId,
        method: <Method> 'POST',
        data: { }
      })

      if (response.status === 200) {
        return response.data.package
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async enroll (itemId: number, formOptions: Array<ITransactionRequest>): Promise<Array<TransactionRequest>> {
    try {
      const response: AxiosResponse = await this.client.request({
        url: 'queue-contract-submissions/' + itemId + '/enrollments',
        method: <Method> 'POST',
        data: { formOptions }
      })

      if (response.status === 200) {
        const requests: Array<TransactionRequest> = []

        Object.entries(response.data.enrollments).forEach(([id, i]) => {
          requests.push(new TransactionRequest(<Partial<TransactionRequest>> i))
        })

        return requests
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async getEnrollmentOptions (itemId: number): Promise<EnrollmentOptions> {
    try {
      const response: AxiosResponse = await this.client.request({
        url: 'queue-contract-submissions/' + itemId + '/enrollments',
        method: <Method> 'GET'
      })

      if (response.status === 200) {
        const options: EnrollmentOptions = {
          accountMessages: new Map(),
          requests: new Map(),
          options: new Map()
        }

        for (const acctId in response.data.requests) {
          options.requests.set(parseInt(acctId), new TransactionRequest(response.data.requests[acctId]))
        }

        for (const acctId in response.data.accountMessages) {
          options.accountMessages.set(parseInt(acctId), response.data.accountMessages[acctId])
        }

        for (const acctId in response.data.enrollmentOptions) {
          options.options.set(parseInt(acctId), response.data.enrollmentOptions[acctId])
        }

        return options
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const ContractSubmissionsApi = new ContractSubmissionApi(esalesClient)
