






















































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { CustomerState } from '@/modules/customers/store'
import { PeriodDetail } from '@/modules/customers/models/account/perioddetail'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import moment from 'moment'

@Component
export default class BillGroupServiceAccountsForm extends Vue {
  @PropSync('billGroup', {
    required: true,
    default: () => new BillGroup()
  })
  private localBillGroup!: BillGroup

  private originalAccountIds: Array<number> = []

  public get allAccounts () {
    return CustomerState.customer.accounts
  }

  public mounted () {
    this.originalAccountIds = this.getAccounts()
    this.localBillGroup.accountIds = this.originalAccountIds
  }

  public handleSave (): void {
    if (this.localBillGroup.accountIds !== this.originalAccountIds) {
      const thisBillPeriod = moment().format('YYYY-MM-01')

      // needed? update cust state pd recs for accts that got moved
      // CustomerState.customer.accounts.forEach((acct: IAccount) => {
      //   acct.periodDetails.filter((p) => p.billGroupId === this.localBillGroup.id && p.period === thisBillPeriod).forEach((pd: PeriodDetail) => {
      //     // accounts.push(acct.id)
      //   })
      // })

      // perform save
      CustomerState.SaveBillGroup(this.localBillGroup).then((bg) => {
        this.originalAccountIds = this.localBillGroup.accountIds
        this.handleClose()
      })
    }
  }

  public handleClose (): void {
    this.$emit('dialog:close')
  }

  private getAccounts () {
    if (this.localBillGroup.accountIds.length) {
      return this.localBillGroup.accountIds
    }
    const accounts: Array<number> = []
    const thisBillPeriod = moment().format('YYYY-MM-01')
    CustomerState.customer.accounts.forEach((acct: IAccount) => {
      acct.periodDetails.filter((p) => p.billGroupId === this.localBillGroup.id && p.period === thisBillPeriod).forEach((pd: PeriodDetail) => {
        accounts.push(acct.id)
      })
    })
    return accounts
  }
}
