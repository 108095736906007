












































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { IStringIndexed } from '@/modules/shared/types'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { TransactionRequestsQueueApi } from '../../api/queue/transactionrequests'
import { TransactionRequestStatusEnum, TransactionRequestTypeEnum } from '@/modules/shared/enums'

@Component
export default class HistoricalUsageQueue extends Vue {
  @Prop({ default: 0 })
  private itemId!: number

  private selectedItem: TransactionRequest = new TransactionRequest()

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'Customer', align: 'left', sortable: true, value: 'businessName' },
    { text: 'Account #', align: 'left', sortable: true, value: 'accountNumber' },
    { text: 'Assigned To', align: 'center', width: 50, sortable: true, value: 'assignedToName' },
    { text: 'Status', align: 'center', width: 50, sortable: true, value: 'statusLabel' },
    { text: 'Date', align: 'center', width: 120, sortable: true, value: 'effectiveDate' }
  ]

  private loading = false

  private items: Array<TransactionRequest> = []

  private statusLabels = mapObjectVuetifySelect(TransactionRequest.statuses, true)

  private showFilters = false
  private statusFilters: Array<number> = TransactionRequest.errorStatuses

  private listSearch: string = ''

  private get filteredItems () {
    // Todo give some sort of date filter?
    return this.items
    // .filter(r => {return 1})
  }

  private handleClickRow (item: TransactionRequest) {
    this.selectedItem = item
    this.$router.push({ name: 'huqitem', params: { itemId: item.id.toString() } }).catch(() => {})
  }

  private handleItemCreated (item: TransactionRequest) {
    if (this.statusFilters.indexOf(item.status) !== -1) {
      this.items.push(item)
    }
  }

  private handleItemUpdated (item: TransactionRequest) {
    const idx = this.items.findIndex(c => c.id === item.id)
    if (idx !== -1) {
      Vue.set(this.items, idx, item)
      this.selectedItem = item
    } else {
      if (this.statusFilters.indexOf(item.status) !== -1) {
        this.items.push(item)
      }
    }
  }

  private handleItemFinalize (item: TransactionRequest) {
    if (this.statusFilters.indexOf(item.status) !== -1) {
      this.handleItemUpdated(item)
    } else {
      const idx = this.items.findIndex(c => c.id === item.id)
      if (idx !== -1) {
        this.items.splice(idx, 1)
        this.selectedItem = new TransactionRequest()
      }
    }
    EventBus.$emit('app-snack', {
      color: 'green',
      message: 'Transaction Request Resolved',
      timeout: 2000
    })
  }

  private itemViewedClass (item: TransactionRequest): string {
    if (item.id === this.itemId) {
      return 'blue lighten-3'
    }
    return ''
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  @Watch('statusFilters', { immediate: true, deep: true })
  private handleStatusFilterChange () {
    this.handleLoadItems()
  }

  private async handleLoadItems () {
    try {
      this.loading = true
      this.items = []

      this.errorMessage = ''

      if (!this.statusFilters.length) {
        throw new Error('Please select at least 1 status')
      }

      const config: IStringIndexed = {
        filters: {
          transactionType: TransactionRequestTypeEnum.HU,
          status: { IN: this.statusFilters }
        },
        additional: {
          0: 'account'
        },
        include: { account: ['accountNumber'] }
      }

      const items = await TransactionRequestsQueueApi.loadAll(config)

      this.items = items.items

      if (this.itemId) {
        this.selectedItem = this.items.find(i => i.id === this.itemId) || new TransactionRequest()
        if (this.selectedItem.id !== this.itemId) {
          this.$router.push({ name: 'huqitem' }).catch(() => {})
        }
      }
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }

  private beforeMount () {

  }
}
