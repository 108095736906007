import { Base, IBaseModel } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'
import { RatePlan } from '@/modules/customers/models/rateplan'
import { TransactionRequestStatusEnum, TransactionRequestTypeEnum } from '@/modules/shared/enums'
import { TransactionRequestStatuses, TransactionRequestTypes } from '@/modules/shared/lists'
import { AppState } from '@/stores/appStore'
import { ThumbSettings } from '@syncfusion/ej2-vue-charts'

export interface ITransactionRequest extends IBaseModel {
  id: number;
  accountId: number;
  status: number;
  transactionType: number;
  scheduledAt: string|null;
  queuedAt: string|null;
  sentAt: string|null;
  responseAt: string|null;
  statusAt: string|null;
  requestTransactionId: null|string;
  responseTransactionId: null|string;
  details: IStringIndexed;
  error: null|string;
}

export class TransactionRequest extends Base implements ITransactionRequest {
  public static statuses = new TransactionRequestStatuses()
  public static types = new TransactionRequestTypes()
  public static errorStatuses = [TransactionRequestStatusEnum.REJECTED, TransactionRequestStatusEnum.FAILED, TransactionRequestStatusEnum.UNACKED]

  public id = 0
  public accountId = 0
  public transactionType = TransactionRequestTypeEnum.HU
  public status = TransactionRequestStatusEnum.NEW
  public scheduledAt: string|null = null
  public queuedAt: string|null = null
  public sentAt: string|null = null
  public responseAt: string|null = null
  public statusAt: string|null = null
  public requestTransactionId = null
  public responseTransactionId = null
  public error:string|null = null
  public details: IStringIndexed = {}
  public availableRatePlans: Array<IStringIndexed> = []

  constructor (props?: Partial<ITransactionRequest>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'accountId':
          case 'transactionType':
          case 'status':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'serviceAccountId':
            if (props[p]) {
              props.accountId = parseInt(props[p]!.toString())
            }
            break
          case 'scheduledAt':
            if (props[p] === '') {
              props.scheduledAt = null
            }
            props.scheduledAt = props.scheduledAt?.substring(0, 10)
            break
          case 'active':
            props.active = !!props.active
            break
          default:
        }
      }

      Object.assign(this, props)

      if (!this.details.transactionConfig) {
        this.details.transactionConfig = {}
      }
    }
  }

  public get date () {
    return moment(this.scheduledAt, 'YYYYMMDD')
  }

  public get statusLabel () {
    return TransactionRequest.statuses[this.status]
  }

  public get typeLabel () {
    return TransactionRequest.types[this.transactionType]
  }

  public get isEditable () {
    return [TransactionRequestStatusEnum.NEW, TransactionRequestStatusEnum.SCHEDULED, TransactionRequestStatusEnum.FAILED].indexOf(this.status) !== -1
  }

  public get isUnsent () {
    return [TransactionRequestStatusEnum.NEW, TransactionRequestStatusEnum.SCHEDULED, TransactionRequestStatusEnum.QUEUED, TransactionRequestStatusEnum.FAILED].indexOf(this.status) !== -1
  }

  public get hasError () {
    return TransactionRequest.errorStatuses.indexOf(this.status) !== -1
  }

  public get assignedToName () {
    if (!this.details.assignedTo) {
      return ''
    }
    return AppState.cisUsers[this.details.assignedTo]?.detailName || ''
  }

  public get finalizedByName () {
    if (!this.details.finalizedBy) {
      return ''
    }
    return AppState.cisUsers[this.details.finalizedBy]?.detailName || ''
  }

  public get businessName () {
    return this.details.customer.businessName
  }

  public get accountNumber () {
    return this.account.accountNumber
  }

  public get effectiveDate () {
    return this.status === TransactionRequestStatusEnum.SCHEDULED ? this.scheduledAt : this.statusAt
  }

  public static getTransactionTypeName (type: keyof TransactionRequestTypes) {
    return TransactionRequest.types[type].replace(/[^a-zA-z]/, '').toLocaleLowerCase()
  }
}
