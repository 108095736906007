
































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import EventBus from '@/plugins/eventbus'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'

@Component({
  components: {

  }
})
export default class Payments extends Vue {
  @Prop({ required: false })
  private paymentId!: number

  private errorMessage = ''

  private hideVoided = true
  private hideUsed = false
  private filters: IStringIndexed = {
    type: null
  }

  private yesNo = [{ text: 'No', value: true }, { text: 'Yes', value: false }]
  private paymentTypes = [{ text: 'All', value: null }].concat(mapObjectVuetifySelect(CustomerPayment.paymentTypes, true) as Array<{ text: string, value: any }>)

  private itemsPerPage = 10
  private page = 1

  private processingList: INumericDictionary<string> = {}

  private headers: Array<any> = [
    { text: 'ID', align: 'left', sortable: true, value: 'id' },
    { text: 'Pay Date', align: 'center', sortable: true, value: 'payDate' },
    { text: 'Type', align: 'center', sortable: true, value: 'typeLabel' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount' },
    { text: 'Pending', align: 'right', sortable: true, value: 'pendingBalance' },
    { text: 'Remaining', align: 'right', sortable: true, value: 'openBalance' },
    { text: 'Ref #', align: 'left', sortable: true, value: 'referenceNumber' },
    { text: 'Restriction', align: 'left', sortable: false, value: 'restriction' },
    { text: '', align: 'left', sortable: true, value: 'actions' }
  ]

  public rowSelected (item: CustomerPayment) {
    let css = ''
    if (item.id === this.paymentId) {
      css += 'red lighten-5 primary--text '
    }

    if (item.voidDate) {
      css += 'text-decoration-line-through '
    }

    if (item.voidDate) {
      css += 'grey--text text--lighten-1'
    }

    return css
  }

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  public get paginatedItems () {
    const pos = (this.page - 1) * this.itemsPerPage
    return this.filteredItems.slice(pos, pos + this.itemsPerPage)
  }

  public get filteredItems () {
    return this.customer.payments.filter(p => {
      let res = true

      if (this.hideVoided && p.voidDate) {
        res = false
      }

      if (this.hideUsed && p.openBalance === 0) {
        res = false
      }

      for (const f in this.filters) {
        if (this.filters[f] !== null && this.filters[f] !== p[f]) {
          res = false
          break
        }
      }

      return res
    })
  }

  private get canApplyPayment () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.CREATE')
  }

  private get canRefundPayment () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.REFUND')
  }

  private get canVoidPayment () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.VOID')
  }

  private get canViewApplications () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.APPLICATIONS.VIEW')
  }

  public processingAction (payment: CustomerPayment) {
    return this.processingList[payment.id] || false
  }

  private handleNewPayment () {
    EventBus.$emit('open-cust-dialog', { component: 'add-customer-payment', componentProps: { customer: this.customer } })
  }

  public handleApplyPayment (payment: CustomerPayment) {
    if (!payment.holdAsDeposit) {
      this.applyPayment(payment)
      return
    }
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Apply Pre-Payment',
        message: '<b>Are you sure you want to apply this pre-payment?</b>',
        onConfirm: () => this.applyPayment(payment)
      }
    })
  }

  public async applyPayment (payment: CustomerPayment) {
    try {
      Vue.set(this.processingList, payment.id, 'applying')
      // await new Promise(resolve => setTimeout(resolve, 2000))
      await CustomerState.ApplyPayment(payment)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, payment.id)
    }
  }

  public handleVoidPayment (payment: CustomerPayment) {
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Void',
        message: '<b>Are you sure you want to void this payment?</b><br/>This will remove the payment and all applications from the system.',
        onConfirm: () => this.voidPayment(payment)
      }
    })
  }

  public async voidPayment (payment: CustomerPayment) {
    try {
      Vue.set(this.processingList, payment.id, 'void')
      await CustomerState.VoidPayment(payment)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, payment.id)
    }
  }

  public handleRefundPayment (payment: CustomerPayment) {
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Refund',
        message: '<b>Are you sure you want to refund this payment?</b><br/><br/>After Refunding, please contact AP to issue the payment to the customer.',
        onConfirm: () => this.refundPayment(payment)
      }
    })
  }

  public async refundPayment (payment: CustomerPayment) {
    try {
      Vue.set(this.processingList, payment.id, 'refund')
      await CustomerState.RefundPayment(payment)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, payment.id)
    }
  }

  @Watch('loading.payments')
  private handleLoadingChange () {
    if (!this.loading.payments) {
      if (CustomerState.loading.payments === null) {
        CustomerState.LoadPayments()
      }

      if (CustomerState.loading.invoices === null) {
        CustomerState.LoadInvoices()
      }
    }
  }

  public async mounted () {
    if (CustomerState.loading.payments === null) {
      await CustomerState.LoadPayments()
    }

    if (CustomerState.loading.invoices === null) {
      await CustomerState.LoadInvoices()
    }

    if (this.paymentId && this.customer.payments.find(p => p.id === this.paymentId)?.voidDate) {
      this.hideVoided = false
    }
  }
}
