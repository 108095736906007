


































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import moment from 'moment'
import invoiceApi from '@/modules/finance/api/payments'
import dtExporter from '@/modules/shared/datatablehelper'
import { AchTransactionStatusEnum } from '@/modules/shared/enums'

export interface IFinanceAchPendingTrans {
  [key: string]: number|string;
  id: number;
  fileId: number;
  paymentId: number;
  statusLabel: string;
  transationDate: string;

  customerId: number;
  customerName: string;
  customerStatus: string;

  invoiceId: number;
  invoiceNumber: string;
  dueDate: string;
  amount: number;

  pmtInstitution: string;
  pmtAccountNumber: string;
}

@Component
export default class PendingAch extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private loading = false

  private processingList: INumericDictionary<string> = {}

  private headers: Array<IStringIndexed> = [
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName' },
    { text: 'Customer Status', align: 'left', sortable: true, value: 'customerStatus', width: 100 },
    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber', width: 150 },
    { text: 'Due Date', align: 'center', sortable: true, value: 'dueDate', width: 150, exportType: 'date' },
    { text: 'Bank', align: 'left', sortable: true, value: 'pmtInstitutionName' },
    { text: 'Account #', align: 'center', sortable: true, value: 'pmtAccountNumber' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount', width: 150, exportType: 'money' },
    { text: 'Sent', align: 'center', sortable: true, value: 'transactionDate', width: 150, exportType: 'date' },
    { text: 'Status', align: 'center', sortable: true, value: 'statusLabel', width: 180 },
    { text: 'Actions', align: 'center', sortable: true, value: 'actions' }
  ]

  private resultsSearch = ''

  private transactions: Array<IFinanceAchPendingTrans> = []

  public processingAction (transaction: IFinanceAchPendingTrans) {
    return this.processingList[transaction.id] || false
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true
      this.transactions = []
      this.transactions = await invoiceApi.loadPendingAch() as Array<IFinanceAchPendingTrans>
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private async handleCancelTransaction (transaction: IFinanceAchPendingTrans) {
    try {
      Vue.set(this.processingList, transaction.id, 'cancelling')
      const updatedTrans = await invoiceApi.updateAchTransaction(transaction.id, AchTransactionStatusEnum.CANCELLED) as IFinanceAchPendingTrans
      transaction.status = updatedTrans.status
      transaction.statusLabel = updatedTrans.statusLabel
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, transaction.id)
    }
  }

  private async handleApproveTransaction (transaction: IFinanceAchPendingTrans) {
    try {
      Vue.set(this.processingList, transaction.id, 'approving')
      const updatedTrans = await invoiceApi.updateAchTransaction(transaction.id, AchTransactionStatusEnum.APPROVED) as IFinanceAchPendingTrans
      transaction.status = updatedTrans.status
      transaction.statusLabel = updatedTrans.statusLabel
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, transaction.id)
    }
  }

  private async handleRejectTransaction (transaction: IFinanceAchPendingTrans) {
    try {
      Vue.set(this.processingList, transaction.id, 'rejecting')
      const updatedTrans = await invoiceApi.updateAchTransaction(transaction.id, AchTransactionStatusEnum.DENIED) as IFinanceAchPendingTrans
      transaction.status = updatedTrans.status
      transaction.statusLabel = updatedTrans.statusLabel
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, transaction.id)
    }
  }

  private handleExportList () {
    dtExporter(this.headers, this.transactions, 'Pending Transactions', 'Pending-ACH-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
