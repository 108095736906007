import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { ListBase } from '@/modules/shared/lists'

export enum RuleOperatorsEnum {
  ['EQUAL'] = '=',
  ['IN'] = 'IN',
 }

export class RuleOperators extends ListBase {
  readonly [RuleOperatorsEnum.EQUAL] = 'Is'
  readonly [RuleOperatorsEnum.IN] = 'Is One Of'
}

export interface IDocumentTemplateRule extends IStringIndexed {
  id: number;
  documentId: number;
  templateId: number;
  field: string
  operator: keyof RuleOperators;
  compare: string;
}

export default class DocumentTemplateRule extends Base implements IDocumentTemplateRule {
  static operators = new RuleOperators()

  public id = 0
  public documentId = 0
  public templateId = 0
  public field = ''
  public operator = RuleOperatorsEnum.EQUAL
  public compare = ''

  constructor (props?: Partial<IDocumentTemplateRule>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get operatorLabel () {
    return DocumentTemplateRule.operators[this.operator]
  }
}
