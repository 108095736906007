


























import { PaymentImportStatusEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class PaymentUploadDetail extends Vue {
  @Prop({ type: Object, required: true })
  private detail!: IStringIndexed

  public prettify (detail: IStringIndexed) {
    if (detail.status === PaymentImportStatusEnum.PENDING) return 'Pending'
    if (detail.status === PaymentImportStatusEnum.PROCESSED) return 'Success'
    return JSON.stringify(detail.importResult, null, 1)
  }
}
