import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { Contract } from '@/modules/customers/models/customer/contract'
import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'

export default class WelcomeLetter extends Base {
  public id: number = 0
  public status: number = 0
  public contractId: number = 0
  public serviceAccounts = {}
  public fulfillmentFileId = 0

  public contract?: Contract
  public billGroup?: BillGroup

  constructor (props: Partial<WelcomeLetter> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'contract':
            if (props[p]) {
              props[p] = new Contract(props[p])
            }
            break
          case 'billGroup':
            if (props[p]) {
              props[p] = new BillGroup(props[p])
            }
            break
          case 'id':
          case 'status':
          case 'contractId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
