import { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import settlementsClient from '@/api/clients/settlements'
import { AxiosResponse } from 'axios'
import { IStringIndexed } from '@/modules/shared/types'

export default class VolumesApi {
  public static async loadVolumes (type: string, customerId: number, flowStart: string, flowEnd: string): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await settlementsClient.get('settlement-volumes/' + type, {
        params: {
          customerId,
          flowStart,
          flowEnd,
          additional: ['settlementNumber', 'flowDate', 'version', 'zoneAlias', 'chargeTotal']
        }
      })

      if (response.status === 200 && response.data.records) {
        const recs: Array<IStringIndexed> = []

        for (const d of response.data.records) {
          if (type === 'RT') {
            d.calculatedDALoad = (d.realTimeAdjustedNetInterchange - d.adjustedNetInterchangeDeviation).toFixed(9)
          }
          recs.push(d)
        }

        return recs
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
