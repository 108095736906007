






























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import { Transaction } from '@/modules/customers/models/account/transaction'
import { TransactionsApi } from '@/modules/customers/api/transactions'

@Component({
  components: {
  }
})
export default class TransactionProcessLog extends Vue {
  @Prop({ default: false })
  private isDialog!: boolean

  @Prop({ required: true })
  private title!: string

  @Prop({ required: true })
  private transaction!: Transaction

  private localTransaction: Transaction = new Transaction()

  private loading: boolean = true
  private errorMessage = ''

  private handleClose () : void {
    this.$emit('dialog:close')
  }

  private async mounted () {
    try {
      this.localTransaction = await TransactionsApi.load(
        this.transaction.accountId, parseInt(this.transaction.id)
      )
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }
}
