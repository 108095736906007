import esalesClient, { checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi, { RequestParam } from '@/api/model'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { AccountInvoice } from '../models/account/invoice'
import { File as FileModel } from '@/modules/shared/models/file'
import { AccountInvoiceStatusEnum } from '@/modules/shared/enums'

export class InvoiceApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['customers', 'invoices']
    this.singular = 'invoice'
    this.plural = 'invoices'
    this.factory = (...args: any) => new CustomerInvoice(...args)
  }

  public async loadAll (customerId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>> | IStringIndexed>): Promise<{ total: number, items: Array<CustomerInvoice> }> {
    if (!config) {
      config = {
        additional: {
          0: 'invoices',
          invoices: ['lineItems']
        },
        exclude: {
          0: 'history'
        }
      }
    }

    return await this.loadAllSpecific(arguments, config) as { total: number, items: Array<CustomerInvoice> }
  }

  public async load (customerId: number, id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<CustomerInvoice> {
    return await this.loadSpecific(arguments, onlyProps, extraProps) as CustomerInvoice
  }

  public async save (customerId: number, invoice: CustomerInvoice) : Promise<CustomerInvoice> {
    const data: IStringIndexed = { ...invoice }

    return await this.saveSpecific(arguments, data) as CustomerInvoice
  }

  public async savePartial (invoice: CustomerInvoice, props: Array<string>) : Promise<CustomerInvoice> {
    const data: IStringIndexed = { ...invoice }

    return await this.savePartialSpecific([invoice.customerId, invoice.id], data, props)
  }

  public async delete (invoice: CustomerInvoice) {
    return await this.deleteSpecific([invoice.customerId, invoice.id])
  }

  public async generateCredits (invoice: CustomerInvoice): Promise<{ invoice: CustomerInvoice }> {
    try {
      const url = this.buildUrl([invoice.customerId, invoice.id])

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          generateCredits: 1
        }
      })

      if (response.status === 200 && response.data.invoice) {
        return { invoice: new CustomerInvoice(response.data.invoice) }
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async void (invoice: CustomerInvoice) {
    try {
      const url = this.buildUrl([invoice.customerId, invoice.id])

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          void: 1
        }
      })

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async voidAccount (invoice: CustomerInvoice, accountInvoice: AccountInvoice) {
    try {
      const url = '/customers/' + invoice.customerId + '/invoices/' + invoice.id + '/accounts/' + accountInvoice.id

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          void: 1
        }
      })

      if (response.status === 200 && response.data.invoice) {
        return {
          invoice: new CustomerInvoice(response.data.customerInvoice),
          accountInvoice: new AccountInvoice(response.data.invoice)
        }
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async dispute (invoice: CustomerInvoice, accountInvoice: AccountInvoice, disputeFlag: number) {
    try {
      const url = '/customers/' + invoice.customerId + '/invoices/' + invoice.id + '/accounts/' + accountInvoice.id

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'PUT',
        data: {
          dispute: disputeFlag // Purposely different than model prop
        }
      })

      if (response.status === 200 && response.data.invoice) {
        return {
          customerInvoice: new CustomerInvoice(response.data.customerInvoice),
          invoice: new AccountInvoice(response.data.invoice)
        }
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async getPdf (invoice: CustomerInvoice) : Promise<FileModel> {
    try {
      const response = await this.client.request({
        url: this.buildUrl([invoice.customerId, invoice.id]),
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: {
          with: 'pdf'
        }
      })

      if (response.status === 200 && response.data.pdf) {
        return new FileModel(response.data.pdf)
      } else if (response.data.message) {
        throw new Error(response.data.message)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const InvoicesApi = new InvoiceApi(esalesClient)
