
import { Component, Vue } from 'vue-property-decorator'
import { IStringIndexed } from '../types'

@Component
export default class DialogHost extends Vue {
  private dialog: boolean = false
  private dialogComponent = ''
  private dialogProps: IStringIndexed = {}
  private componentProps: IStringIndexed = {}
  private dialogCloseEvent: Function|null = null

  protected handleDialog (dialogConfig: IStringIndexed) {
    this.dialogComponent = dialogConfig.component
    this.componentProps = dialogConfig.props || {}
    this.dialogProps = dialogConfig.dialogProps || { maxWidth: '50%', minHeight: '80%' }

    this.dialog = true
  }

  protected handleCloseDialog () {
    if (this.dialogCloseEvent) {
      this.dialogCloseEvent(this.dialogComponent, this.componentProps)
    }
    this.dialog = false
    this.dialogComponent = ''
  }
}
