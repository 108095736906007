




























import { Component, Vue } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { File } from '@/modules/shared/models/file'
import FinancePaymentsApi from '@/modules/finance/api/payments'
import FileUploadForm from './FileUploadForm.vue'

@Component({
  components: {
    'file-upload-form': FileUploadForm
  }
})
export default class PaymentUploadForm extends Vue {
  private errorMessage = ''

  private uploading = false
  private fileDialog: boolean = false

  private handleAttachFile () {
    this.fileDialog = true
  }

  private async handleFileUpload (item: { reading: boolean; completed: number; file: File }) {
    if (this.uploading) {
      return
    }

    this.uploading = true
    const result = await FinancePaymentsApi.createPaymentImport(item.file, (e: ProgressEvent) => {
      item.reading = true
      item.completed = Math.round((e.loaded * 100) / e.total)
    })
      .catch((err: string) => {
        this.errorMessage = err
        EventBus.$emit('app-snack', {
          message: err,
          timeout: 10_000
        })
      })
      .finally(() => {
        this.uploading = false
      })

    this.$emit('upload:complete')
  }

  private beforeMount () {

  }
}
