import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import authRoutes from '@/modules/auth/routes'
import customerMgmtRoutes from '@/modules/customermanagement/routes'
import customerRoutes from '@/modules/customers/routes'
import billingRoutes from '@/modules/billing/routes'
import financeRoutes from '@/modules/finance/routes'
import wholesaleRoutes from '@/modules/wholesale/routes'
import searchRoutes from '@/modules/search/routes'
import salesRoutes from '@/modules/sales/routes'
import userRoutes from '@/modules/users/routes'
import dataservicesRoutes from '@/modules/dataservices/routes'
import { AuthState } from '@/modules/auth/store'

Vue.use(Router)

const baseRoutes: Array<any> = [
  {
    path: '/',
    name: 'home',
    components: {
      default: Home
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "errors" */ '@/components/NotFound.vue')
  },
  {
    path: '/Forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "errors" */ '@/components/Forbidden.vue')
  }
]

const routes = baseRoutes.concat(authRoutes, customerMgmtRoutes, customerRoutes, dataservicesRoutes, billingRoutes, financeRoutes, wholesaleRoutes, searchRoutes, salesRoutes, userRoutes)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  const login = {
    name: 'login',
    query: { redirect: to.query.redirect ? to.query.redirect : to.fullPath }
  }

  const forbidden = {
    name: 'Forbidden'
  }

  const perm = to.matched.filter(record => record.meta.requiresAuth)

  if (!perm.length) {
    return next()
  }

  if (!AuthState.isLoggedIn) {
    console.log('User Not Logged In')
    if (AuthState.refreshTokenValid) {
      try {
        await AuthState.RefreshLogin()

        if (!AuthState.isLoggedIn) {
          console.log('Failed Refresh Attempt')
          return next(login)
        }
      } catch {
        console.log('Refresh already invalid')
        return next(login)
      }
    } else {
      return next(login)
    }
  }

  for (const r of perm) {
    if (typeof r.meta.requiresAuth !== 'boolean' && !AuthState.user.isAllowed(r.meta.requiresAuth)) {
      console.log('failed perm check: ' + r.meta.requiresAuth)
      return next(forbidden)
    }
  }

  return next()
})

export default router
