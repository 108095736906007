import { CustomerAccountingStatuses, CustomerStatuses, CustomerTypes, ServiceAccountStatuses } from '@/modules/shared/lists'
import { CustomerAccountingStatusEnum, CustomerStatusEnum, CustomerTypeEnum, StateAbbreviationsEnum, StatesAbbrevType } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'

export interface ISearchContact extends IStringIndexed {
  id: number;

  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  street: string;
  city: string;
  state: StatesAbbrevType;
  zip: string;
  phoneNumbers: Array<string>;
  emailAddresses: Array<string>;
}

export interface ISearchServiceAccount extends IStringIndexed {
  id: number;
  accountNumber: string;
  status: keyof ServiceAccountStatuses;

  isActive: boolean;

  utilityId: number;
  utilityName: string;

  street: string;
  city: string;
  state: StatesAbbrevType;
  zip: string;
}
export interface ISearchResult extends IStringIndexed {
  id: number;
  status: keyof CustomerStatuses;
  statusLabel: string;
  accountingStatus: keyof CustomerAccountingStatuses;
  accountingstatusLabel: string;
  type: keyof CustomerTypes;

  fullName: string;
  businessName: string;
  dba: string;
  sic: string;

  street: string;
  city: string;
  state: StatesAbbrevType;
  zip: string;

  numAccounts: number;
  numActiveAccounts: number;
  p2cId: number;

  contacts: Array<ISearchContact>;
  serviceAccounts: Array<ISearchServiceAccount>;
  utility: Array<string>;

  createdAt: number;
  touched: number;
}

export default class SearchResult implements ISearchResult {
  public id = 0
  public status = CustomerStatusEnum.ACTIVE
  public statusLabel = ''
  public accountingStatus = CustomerAccountingStatusEnum.CURRENT
  public accountingstatusLabel = ''
  public type = CustomerTypeEnum.LARGE_COMM

  public fullName = ''
  public businessName = ''
  public dba = ''
  public sic = ''

  public street = ''
  public city = ''
  public state = StateAbbreviationsEnum.MA
  public zip = ''

  public numAccounts = 0
  public numActiveAccounts = 0
  public p2cId = 0

  public contacts = []
  public serviceAccounts = []
  public utility = []

  public createdAt = 0
  public touched = 0

  constructor (props: Partial<ISearchResult> = {}) {
    if (props) {
      Object.assign(this, props)
    }
  }

  public get icon (): string {
    switch (this.type) {
      case CustomerTypeEnum.LARGE_COMM:
      case CustomerTypeEnum.MED_COMM:
      case CustomerTypeEnum.SMALL_COMM:
        return 'business'
      default:
        return 'residential'
    }
  }

  public iconIs (size: string): boolean {
    if (this.type === CustomerTypeEnum.LARGE_COMM && size === 'large') {
      return true
    }

    if (this.type === CustomerTypeEnum.SMALL_COMM && size === 'small') {
      return true
    }

    return false
  }
}
