


































import { Component, Vue } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import SearchForm from './forms/Search.vue'
import AdvancedSearchForm from './forms/AdvancedSearch.vue'
import { SearchState } from '../store'

@Component({
  components: {
    'search-form': SearchForm,
    'advanced-search-form': AdvancedSearchForm
  }
})
export default class SideNav extends Vue {
  private drawer = true
  private mini = false
  private searchType = 0

  private get menu () {
    return CustomerState.customerMenu
  }

  private beforeMount () {
    if (SearchState.searchParam.filters) {
      this.searchType = 1
    } else {
      this.searchType = 0
    }

    if (SearchState.results.length) {
      this.mini = true
    }
  }
}
