
































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Contract } from '@/modules/customers/models/customer/contract'
import { ListsEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import Rules from '@/plugins/validations'
import SalesUserContract from '@/modules/sales/models/salesuser/contract'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  },
  directives: {
  }
})
export default class ContractSalesCommission extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true
  private menu = {
    from: false,
    to: false
  }

  protected commissionableSales = mapObjectVuetifySelect(SalesState.salesNames, true)

  @Prop({ required: true })
  private contract!: Contract

  @Prop({ default: () => new SalesUserContract() })
  private commission!: SalesUserContract

  private alreadyAssigned (item: SalesUserContract) {
    return this.contract.commissions.find(c => c.salesId === item.value) !== undefined
  }

  public get canEditCommissions () {
    if (this.commission.id) {
      return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.EDIT')
    }
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.CREATE')
  }

  public get canViewCommissions () {
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.VIEW')
  }

  @Watch('commission.effectiveFrom')
  private handleFromUpdate () {
    if ((this.commission.effectiveFrom || '').length === 7) {
      this.commission.effectiveFrom += '-01'
    }
  }

  @Watch('commission.effectiveTo')
  private handleToUpdate () {
    if ((this.commission.effectiveTo || '').length === 7) {
      this.commission.effectiveTo += '-01'
    }
  }

  public beforeMount () {
    if (!this.commission.contractId) {
      this.commission.contractId = this.contract.id
    }
    if (!this.commission.effectiveFrom.length) {
      this.commission.effectiveFrom = this.contract.dateSigned
    }

    if (!this.commissionableSales.find(cs => cs.value === this.commission.id)) {
      this.commission.salesId = 0
    }
  }
}
