export enum StateAbbreviationsEnum {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}

export type StatesAbbrevType = keyof typeof StateAbbreviationsEnum

export enum TimezonesEnum {
 ['America/Los_Angeles'] = 'Pacifc',
 ['America/Denver'] = 'Mountain',
 ['America/Chicago'] = 'Central',
 ['America/New_York'] = 'Eastern'
}

export type TimezoneType = keyof typeof TimezonesEnum

export enum AppUserTypeEnum {
 ['ADMIN'] = '0',
 ['USERADMIN'] = '1',
 ['USER'] = '2',
 ['SALES'] = '3'
}

export enum UserCustomerNavsEnum {
 ['BOTTOM_SHEET'] = 'Bottom Menu',
 ['TOP_TABS'] = 'Tabs'
}

export enum UserGroupsEnum {
  ['DEVELOPERS'] = 1,
  ['ACCOUNTS_PAYABLE'] = 2,
  ['ACCOUNTS_RECEIVABLE'] = 3,
  ['CONTRACT_ADMIN'] = 4,
  ['DATA_SERVICES'] = 5,
  ['BILLING'] = 6,
  ['WHOLESALE'] = 7
}

export enum ListsEnum {
 ['SYSTEM_SETTINGS'] = '4',
 ['SALES_FILE_CATEGORIES'] = '5',
 ['SALES_NOTE_CATEGORIES'] = '6',
 ['EMAIL_TYPES'] = '7',
//  ['BILL_METHODS'] = '9',
//  ['PAYMENT_METHODS'] = '10',
 ['CONTACT_TYPES'] = '11',
 ['SIC'] = '13',
 ['PHONE_TYPES'] = '14',
 ['NOTE_CATEGORIES'] = '15',
//  ['CUSTOMER_TYPES'] = '16',
 ['FILE_CATEGORIES'] = '20',
 ['PRODUCTS_ELECTRIC'] = 'products_1', // CommodityEnum.E
 ['ZONES'] = 'zones'
}

export enum CustomerTypeEnum {
  ['LARGE_COMM'] = 0,
  ['MED_COMM'] = 4,
  ['SMALL_COMM'] = 3,
  ['INDUSTRIAL'] = 2,
  ['RESI'] = 1
 }

export enum CustomerStatusEnum {
 ['NEW'] = 0,
 ['RESTARTING'] = 1,
 ['ACTIVE'] = 10,
 ['INACTIVE'] = 99
}

export enum CustomerAccountingStatusEnum {
  ['CREDITREVIEW'] = 0,
  ['CURRENT'] = 10,
  ['PASTDUE'] = 20,
  ['COLLECTIONS'] = 30,
  ['CLOSED'] = 99
 }

export enum CustomerFlagEnum {
  ['DISABLE_BILLING'] = 'disableBilling',
  ['DISABLE_PAYMENTS'] = 'disablePayments',
  ['DISABLE_COLLECTIONS'] = 'disableCollections',
  ['DISABLE_LATE_FEES'] = 'disableLateFees',
  ['HOLD_APPROVALS'] = 'holdApprovals'
}

export enum TaxCategoriesEnum {
  ['TAXCAT_RESIDENTIAL'] = 0,
  ['TAXCAT_COMMERCIAL'] = 1,
  ['TAXCAT_INDUSTRIAL'] = 2,
  ['TAXCAT_MANUFACTURING'] = 3,
  ['TAXCAT_AGRICULTURAL'] = 4,
  ['TAXCAT_MOBILE_HOME'] = 5,
  ['TAXCAT_HIGH_PEAK_DEMAND'] = 6,
  ['TAXCAT_SENIOR_CITIZEN'] = 7
}

export enum CommodityEnum {
 ['E'] = 1
}

export enum ServiceAccountStatusEnum {
 ['NEW'] = 0,
 ['PENDING_ENROLLMENT'] = 1,
 ['PENDING_START'] = 2,
 ['ACTIVE'] = 3,
 ['PENDING_DROP'] = 4,
 ['INACTIVE'] = 5,
 ['BAD'] = 6
}

export enum ServiceAccountHuStatusEnum {
 ['INACTIVE'] = 0,
 ['ACTIVE'] = 1,
 ['ERROR'] = 2,
}

export enum PeriodDetailFlowStatusEnum {
  ['NOT'] = 0,
  ['STARTED'] = 1,
  ['FLOWING'] = 2,
  ['STOPPED'] = 3
}

export enum MarketDetailStatusEnum {
  ['CURRENT'] = 0,
  ['HISTORIC'] = 1
}

export enum BillMethodEnum {
 ['DUAL'] = 0,
 ['RATE_READY'] = 1,
 ['SUPPLIER_CONSOLIDATED'] = 2,
 ['BILL_READY'] = 3
}

export enum ContactTypeEnum {
  ['PRIMARY'] = 116,
  ['BILLING'] = 117,
  ['OTHER'] = 175
}

export enum BillGroupDeliveryMethodEnum {
  ['EMAIL'] = 1,
  ['FAX'] = 2,
  ['MAIL'] = 4
}

export enum PaymentMethodEnum {
 ['ACH'] = 0,
 ['CHECK'] = 1,
 ['WIRE'] = 2,
 ['CREDIT_CARD'] = 3,
 ['ECHECK'] = 4,
 ['UTIL820'] = 5,
 ['CREDIT'] = 6,
 ['WRITEOFF'] = 7
}

export enum InvoiceAssemblyTypeEnum {
  ['IMMEDIATE'] = 0,
  ['DAILY'] = 1,
  ['DAY_OF_MONTH'] = 2,
  ['ALL_ACCOUNTS'] = 3
}

export enum CustomerInvoiceStatusEnum {
  ['STATUS_ASSEMBLING'] = 0,
  ['STATUS_TAXING'] = 1,
  ['STATUS_PENDING_APPROVAL'] = 2,
  ['STATUS_APPROVED'] = 3,
  ['STATUS_SENDING'] = 4,
  ['STATUS_SENT'] = 5
}

export enum AccountInvoiceStatusEnum {
  ['STATUS_PENDING'] = 0,
  ['STATUS_APPROVED'] = 1,
  ['STATUS_ISSUED'] = 2,
  ['STATUS_CURRENT'] = 3,
  ['STATUS_PASTDUE'] = 4,
  ['STATUS_PAID'] = 5,
  ['STATUS_WRITEOFF'] = 6,
  ['STATUS_VOID'] = 7
}

export enum ChargeCategoryEnum {
  ['CATEGORY_COMMODITY'] = 0,
  ['CATEGORY_CHARGE'] = 1,
  ['CATEGORY_TAX'] = 2,
  ['CATEGORY_SURCHARGE'] = 3
}

export enum ChargesEnum {
  ['ENERGY'] = 1,
  ['TAX'] = 8,
  ['SETTLEMENTS'] = 14
}

export enum CustomerPaymentTypeEnum {
  ['PAYMENT'] = 0,
  ['CREDIT'] = 1,
  ['WRITEOFF'] = 2
}

export enum CustomerPaymentApplicationTypeEnum {
  ['PAYMENT'] = 0,
  ['REVERSAL'] = 1,
  ['REFUND'] = 2
}

export enum CustomerPaymentApplicationStatusEnum {
  ['PENDING'] = 0,
  ['APPLIED'] = 1,
  ['REVERSED'] = 2
}

export enum AchTransactionStatusEnum {
  ['PENDING'] = 0,
  ['SENT'] = 1,
  ['APPROVED'] = 2,
  ['DENIED'] = 3,
  ['CANCELLED'] = 4
}

export enum BankAccountTypeEnum {
  ['CHECKING'] = 0,
  ['SAVINGS'] = 1
}

export enum UoMEnum {
 ['KWH'] = 0,
 ['KH'] = 1,
 ['KVA'] = 2
}

export enum UsageSourcesEnum {
  ['EPO'] = 1,
  ['UTIL867'] = 2,
  ['EYEDRO'] = 3,
  ['UTILITY_CUSTOMER_PORTAL'] = 4,
  ['UTILITY_SUPPLIER_PORTAL'] = 5,
  ['FORECASTED'] = 6,
  ['UTIL867_SUMMARY'] = 7,
  ['ESTIMATED'] = 8,
  ['PROFILED'] = 10,
  ['BEST_MATCH'] = 999999
}

export enum CreditCheckApprovalEnum {
  ['NEW'] = 0,
  ['APPROVED'] = 1,
  ['CONDITIONAL'] = 2,
  ['DENIED'] = 3
}

export enum CreditCheckStatusEnum {
  ['NEW'] = 0,
  ['INPROGRESS'] = 1,
  ['ONHOLD'] = 2,
  ['CLOSED'] = 10
}

export enum ContractSubmissionStatusEnum {
  ['NEW'] = 0,
  ['INPROGRESS'] = 1,
  ['ONHOLD'] = 2,
  ['APPROVED'] = 10,
  ['ENROLLED'] = 11,
  ['DENIED'] = 20,
  ['REMOVED'] = 21
}

export enum WelcomeLetterQueueStatusEnum {
  ['NEW'] = 0,
  ['INPROGRESS'] = 1,
  ['READY'] = 2,
  ['SENT'] = 3,
  ['CANCELED'] = 10
}

export enum EsgTransactionStatusEnum {
  ['NEW'] = 0,
  ['NORMALIZED'] = 1,
  ['VALIDATED'] = 2,
  ['PARTIAL'] = 5,
  ['PROCESSED'] = 10,
  ['ERROR_GENERAL'] = 20,
  ['ERROR_REQUIREDFIELDS'] = 21,
  ['ERROR_NO_ACCOUNT'] = 22,
  ['ERROR_DUPLICATE'] = 23
}

export enum TransactionRequestTypeEnum {
  ['HU'] = 0,
  ['ENROLLMENT'] = 1,
  ['CHANGE'] = 2,
  ['DROP'] = 3,
  ['INVOICE'] = 4,
  ['PAYMENT'] = 5
}

export enum TransactionRequestStatusEnum {
  ['NEW'] = 0,
  ['SCHEDULED'] = 1,
  ['READY'] = 4,
  ['QUEUED'] = 5,
  ['SENT'] = 6,
  ['ACCEPTED'] = 10,
  ['FINALIZED'] = 15,
  ['DUPLICATE'] = 16,
  ['REJECTED'] = 20,
  ['FAILED'] = 21,
  ['CANCELLED'] = 25,
  ['UNACKED'] = 30
}

export enum TaxExemptItemStatusEnum {
  ['NEW'] = 0,
  ['INPROGRESS'] = 1,
  ['ONHOLD'] = 2,
  ['APPROVED'] = 10,
  ['DENIED'] = 20,
  ['REMOVED'] = 21,
  ['EXPIRED'] = 30,
  ['RENEWED'] = 35,
}

export enum TaxExemptItemCchReasonEnum {
  ['FED_GOV'] = '01',
  ['STATE_LOCAL_GOV'] = '02',
  ['TRIBAL_GOV'] = '03',
  ['FOREIGN_DIPLOMAT'] = '04',
  ['CHARITABLE_ORG'] = '05',
  ['RELIGIOUS_ORG'] = '06',
  ['RESALE'] = '07',
  ['AGRICULTURAL'] = '08',
  ['INDUSTRIAL_OR_MANUFACTURING'] = '09',
  ['DIRECT_PAY'] = '10',
  ['DIRECT_MAIL'] = '11',
  ['OTHER'] = '12',
}
export enum TaxExemptItemCchTypeEnum {
  ['NONE'] = '00',
  ['FEDEXCISE'] = '01',
  ['STATE'] = '02',
  ['FFEDEXCISE_STATE'] = '03',
  ['LOCAL'] = '04',
  ['FEDEXCISE_LOCAL'] = '05',
  ['STATE_LOCAL'] = '06',
  ['FEDEXCISE_STATE_LOCAL'] = '07',
  ['STATE_'] = '08',
  ['ALL_FED'] = '09',
  ['ALL_UNITBASED'] = '10',
  ['UNIT_E911'] = '11',
  ['ONLY_UNIT'] = '12',
  ['RIGHTOFWAY'] = '13',
  ['ALLSTATE_LOCALE911'] = '14',
  ['USF_ONLY'] = '19',
  ['PUC_FEES'] = '20',
  ['ALL_STATE_LOCAL_GRT'] = '21',
  ['ALL_STATE_USF'] = '22',
  ['STATE_GRT_USF'] = '23',
  ['FED_USF'] = '24',
  ['FEDEXCISE_FEDUSF_STATE'] = '25',
  ['STATEEXCISE'] = '26',
  ['FEDEXCISE_FEDUSF_LOCAL'] = '27',
  ['FEDUSF_STATE_LOCAL'] = '28',
  ['FEDUSF_LOCAL'] = '29',
  ['STATEEXCISE_LOCAL_TELECOMM'] = '30',
  ['STATE_SALES'] = '31',
  ['STATE_LOCAL_SALES'] = '32',
  ['FEDEXCISE_FEDUSF_STATE_LOCAL'] = '33',
  ['FED_TRS'] = '35',
  ['VAT'] = '36',
  ['LOCAL_UUT'] = '41',
  ['STATEEXCISE_LOCAL_SUR'] = '42',
  ['CITY'] = '44',
  ['STATE_FRANCHISE'] = '45',
  ['LOCAL_FRANCHISE'] = '46',
  ['COUNTY'] = '47',
  ['LA_COMMUT'] = '61',
  ['NONUNIT_EXCEPT_E911'] = '62',
  ['NO_TAX_ON_TAX'] = '98',
  ['ALL'] = '99',
}
export enum FileCategories {
  ['TAX_EXEMPT_FORM'] = 210,
}
export enum ResettlementBillableStatusEnum {
  ['UNDECIDED'] = 0,
  ['BILLABLE'] = 1,
  ['BILLED'] = 2,
  ['WRITE_OFF'] = 5,
}

export enum InvoiceHoldStatusEnum {
  ['NONE'] = 0,
  ['HELD'] = 1,
  ['UNHELD'] = 2
}

export enum PaymentImportStatusEnum {
    ['PENDING'] = 0,
    ['PROCESSED'] = 10,
    ['FAILED'] = 20,
}
