
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Forbidden extends Vue {
  @Prop({ default: 'Permission Denied' })
  private message!: string
}
