import { Commodities } from '../lists'
import { CommodityEnum } from '../enums'
import { ElectricAccount } from './account/electric'
import { IAccount } from './interfaces/account'

export default class AccountFactory {
  public static getAccount (commodity: keyof Commodities) {
    if (commodity === CommodityEnum.E) {
      return new ElectricAccount()
    } else {
      throw new Error('Invalid commodity')
    }
  }

  public static getAccountFromJson (json: Partial<IAccount>) {
    if (json.commodity === CommodityEnum.E) {
      return new ElectricAccount(<Partial<ElectricAccount>>json)
    } else {
      throw new Error('Invalid commodity')
    }
  }
}
