
















































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import DocumentModel from '@/modules/customermanagement/models/document'
import documentApi from '@/modules/customermanagement/api/documentmanagement'
import Rules from '@/plugins/validations'
import esalesClient from '@/api/clients/esales'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'

@Component({
  components: {}
})
export default class DocumentForm extends Vue {
  @Prop({ default: false })
  private isDialog!: boolean

  @PropSync('document', {
    default: () => new DocumentModel(),
    required: true
  })
  private localDocument!: DocumentModel

  private errorMessage: string = ''
  private saving: boolean = false
  private valRules: Rules = Rules

  public get readOnly () {
    if (!this.localDocument.id) {
      return !AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.CREATE')
    } else {
      return !AuthState.user.isAllowed('CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.EDIT')
    }
  }

  public get mode () {
    return this.localDocument.id ? 'Edit' : 'Add'
  }

  public handleClose () : void {
    (this.$refs.form as Vue & { resetValidation: () => boolean }).resetValidation()
    this.$emit('dialog:close')
  }

  public async handleSubmit () : Promise<void> {
    if (this.readOnly || this.saving) {
      return
    }

    if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    const isNew: boolean = !(this.localDocument.id > 0)

    try {
      const document = await documentApi.saveDocument(this.localDocument)
      if (isNew) {
        this.$emit('itemAdded', document)
      } else {
        this.$emit('itemUpdated', document)
      }

      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public mounted () {
  }
}
