





















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import EventBus from '@/plugins/eventbus'
import { IStringIndexed } from '@/modules/shared/types'
import BillingManagementApi from '../../api/billing'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import moment from 'moment'
import { AppState } from '@/stores/appStore'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { CommodityEnum, ListsEnum } from '@/modules/shared/enums'

interface IGenericRequest {
  utilityId: number,
  zone: string,
  rateClass: string,
  capacityTag: number,
  annualConsumption: number,
  from: string,
  to: string,
  purpose: number,
  lmpType: string
}

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class GenericQuoteForm extends Vue {
  private fetching: boolean = false
  private noErrors: boolean = true
  private valRules: Rules = Rules

  private fields: IGenericRequest = {
    utilityId: 0,
    zone: '',
    rateClass: '',
    capacityTag: 0,
    annualConsumption: 0,
    from: moment().subtract(364, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    purpose: 0,
    lmpType: 'DA'
  };

  private menu = {
    from: false,
    to: false
  }

  private purposes = [
    { text: 'Pricing (Truelight)', value: 0 },
    { text: 'Costs (ISO)', value: 2 }
  ]

  private lmpTypes = [
    { text: 'Day Ahead', value: 'DA' },
    { text: 'Realtime', value: 'RT' }
  ]

  public get utilities () {
    const list: Array<{text: string; value: any}> = []

    for (const id in AppState.utilities) {
      list.push({
        text: AppState.utilities[id].displayName,
        value: AppState.utilities[id].id
      })
    }

    list.sort((a, b) => a.text.localeCompare(b.text))

    return list
  }

  public get zones () {
    if (!this.fields.utilityId) {
      return []
    }

    const util = AppState.utilities[this.fields.utilityId]
    const list = Object.values(AppState.listsByName[ListsEnum.ZONES] as IStringIndexed).filter(z => util.zoneIds.indexOf(z.id) !== -1).map(z => ({ text: z.name, value: z.name }))

    if (list.length === 1) {
      this.fields.zone = list[0].value
    }

    return list
  }

  public get rateClasses () {
    if (!this.fields.utilityId) {
      return []
    }

    return Object.values(AppState.utilities[this.fields.utilityId].rateClasses).map(r => ({
      text: r.rateClass + ' -' + r.description,
      value: r.rateClass
    })).sort((a, b) => a.text.localeCompare(b.text))
  }

  public get fromLabel () {
    return moment(this.fields.from).format('MMM DD, YYYY')
  }

  public get toLabel () {
    return moment(this.fields.to).format('MMM DD, YYYY')
  }

  public handleSubmit (): void {
    if (this.fetching) {
      return
    }

    if (!(this.$refs.genericForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.fetching = true
    this.$emit('quote:retrieved', null)
    this.$emit('utilityQuote:retrieved', null)

    BillingManagementApi.fetchGenericQuote(this.fields)
      .then((details: IStringIndexed) => {
        this.$emit('utilityQuote:retrieved', details)
        this.$emit('setTab', 1)
      })
      .catch(err => {
        EventBus.$emit('app-snack', {
          message: err
        })
      })
      .finally(() => {
        this.fetching = false
      })
  }

  public mounted () {

  }
}
