import { Base, IBaseModel } from '@/modules/shared/models/base'
import { AccountInvoice } from '../account/invoice'
import moment from 'moment'
import { AccountInvoiceStatusEnum, BillGroupDeliveryMethodEnum, ChargesEnum, CustomerInvoiceStatusEnum, InvoiceHoldStatusEnum } from '@/modules/shared/enums'
import { BillGroupDeliveryMethods, CustomerInvoiceStatuses } from '@/modules/shared/lists'
import Customer from '../customer'
import { BillGroup } from './billgroup'

export interface ICustomerInvoice extends IBaseModel {
  id: number;
  customerId: number;
  billGroupId: number;
  invoiceNumber: string;
  invoiceDate: string;
  dueDate: string;
  status: number;
  grandTotal: number;
  openBalance: number;
  p2cId: string;
  holdStatus: number;
}

export class CustomerInvoice extends Base {
  public static statuses = new CustomerInvoiceStatuses()
  public id = 0
  public customerId = 0
  public billGroupId = 0
  public invoiceNumber = ''
  public invoiceDate = ''
  public dueDate = ''
  public status = CustomerInvoiceStatusEnum.STATUS_ASSEMBLING
  public grandTotal = 0
  public openBalance = 0
  public p2cId = ''
  public holdStatus = InvoiceHoldStatusEnum.NONE

  public history: {
    assembledAt: string,
    assembleMessage: string|null,
    qaMessages: Array<string>,
    approvedAt: string,
    approvedBy: number,
    sent: Array<{
      at: string,
      by: number,
      method: keyof BillGroupDeliveryMethodEnum,
      fileId: number
    }>,
    assemblyLogs: Array<{
      at: string,
      messages: Array<string>
    }>,
  } = {
    assembledAt: '',
    assembleMessage: null,
    qaMessages: [],
    approvedAt: '',
    approvedBy: 0,
    sent: [],
    assemblyLogs: []
  }

  public invoices: Array<AccountInvoice> = []
  public customer?: Customer
  public billGroup?: BillGroup

  constructor (props?: Partial<ICustomerInvoice>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'customerId':
          case 'billGroupId':
          case 'holdStatus':
            props[p] = parseInt(props[p]!.toString())
            break
          case 'grandTotal':
          case 'openBalance':
            props[p] = parseFloat(props[p]!.toString())
            break
          case 'invoices':
            if (props[p]) {
              const objs: Array<AccountInvoice> = []
              for (const i in <Array<AccountInvoice>>props[p]) {
                objs.push(new AccountInvoice(props[p][i]))
              }

              props[p] = objs
            }
            break
          case 'customer':
            if (props[p]) {
              props[p] = new Customer(props[p])
            }
            break
          case 'billGroup':
            if (props[p]) {
              props[p] = new BillGroup(props[p])
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get statusLabel () {
    return CustomerInvoice.statuses[this.status]
  }

  public get isPastDue () {
    return this.openBalance > 0 && moment().hour(0).minute(0).second(0).isAfter(moment(this.dueDate))
  }

  public get isVoided () {
    return this.invoices.length === this.invoices.filter(i => i.status === AccountInvoiceStatusEnum.STATUS_VOID).length
  }

  public get hasCreditsToGenerate () {
    return this.invoices.some(i => i.openBalance < 0)
  }

  public get age () {
    const age = moment().diff(moment(this.dueDate), 'days')

    if (age < 0) {
      return 0
    }
    return age
  }

  public get hasSettlements () {
    return this.invoices.some(i => i.parentLineItem?.chargeId === ChargesEnum.SETTLEMENTS)
  }

  public get accountIds () {
    const ids = []
    for (const i of this.invoices) {
      if (ids.indexOf(i.accountId) === -1) {
        ids.push(i.accountId)
      }
    }
    return ids
  }

  public get rateRange () {
    if (!this.invoices.length) {
      return 'Unk'
    }

    if (this.invoices.length === 1) {
      const val = (this.invoices[0].commodityTotal / this.invoices[0].netConsumption)
      if (isNaN(val)) {
        return '-'
      }
      return val.toFixed(6)
    }
    const sorted = [...this.invoices].sort((a, b) => (a.commodityTotal / a.netConsumption) - (b.commodityTotal / b.netConsumption))

    const leftVal = (sorted[0].commodityTotal / sorted[0].netConsumption)
    const rightVal = (sorted[sorted.length - 1].commodityTotal / sorted[sorted.length - 1].netConsumption)

    return (isNaN(leftVal) ? 0 : leftVal).toFixed(6) + ' - ' + (isNaN(rightVal) ? 0 : rightVal).toFixed(6)
  }

  public get hasDeliveryDestination () {
    if (this.billGroup?.billDeliveryMethod === BillGroupDeliveryMethodEnum.EMAIL &&
      (!this.billGroup?.emailTo.length && !this.billGroup?.ccTo.length && !this.billGroup?.bccTo.length)
    ) {
      return false
    }

    if (this.billGroup?.billDeliveryMethod === BillGroupDeliveryMethodEnum.FAX && !this.localItem.billGroup.fax.length) {
      return false
    }

    return true
  }

  public get billingSystem () {
    return this.p2cId ? 'P2C' : 'CIS'
  }
}
