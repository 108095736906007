










































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import AddressLink from '@/modules/shared/components/AddressLink.vue'
import EventBus from '@/plugins/eventbus'
import { SalesState } from '@/modules/sales/store/index'
import { ICustomerContract } from '@/modules/customers/models/customer/contract'
import { AuthState } from '@/modules/auth/store'

@Component({
  components: {
    'address-link': AddressLink
  }
})
export default class ContractInfo extends Vue {
  @Prop({ required: true })
  private contract!: ICustomerContract

  private errorMessage = ''

  private getSalesName (salesId: number) {
    return SalesState.salesNames[salesId]
  }

  private get commissions () {
    return this.contract.commissions.filter(c => c.salesId > 0)
  }

  private get canEditContract () {
    return AuthState.user.isAllowed('CUSTOMER.CONTRACTS.EDIT')
  }

  public get canViewCommissions () {
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.VIEW')
  }

  private get canEditCommissions () {
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.EDIT')
  }

  private handleEditCommissions () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-contract-commissions', componentProps: { contract: this.contract } })
  }

  private handleEditContract () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-contract', componentProps: { contract: this.contract } })
  }
}
