import Vue from 'vue'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store, { priorState } from '@/stores'
import SearchResult, { ISearchResult } from '../../search/models/searchresult'
import SearchApi from '../../search/api/search'
import { IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'

interface ISearchParam {
  term?: string;
  filters?: IStringIndexed;
}

interface ISearch {
  searchParam: ISearchParam;
  results: Array<ISearchResult>;
}

interface ISearchState {
  searchParam: ISearchParam;
  results: Array<ISearchResult>;
  autocompleteCache: Array<ISearch>;
}

@Module({ dynamic: true, store, name: 'SearchState' })
class SearchStateDef extends VuexModule implements ISearchState {
  [key: string]: any

  public searchParam: ISearchParam = {
    term: ''
  }

  public results = <Array<ISearchResult>>[]
  public resultsExpire = moment()
  public cacheMins = 3

  public autocompleteCache: Array<ISearch> = []

  constructor (module: any) {
    super(module)

    if (priorState && priorState.SearchState) {
      for (const p in priorState.SearchState) {
        switch (p) {
          case 'results':
            if (priorState.SearchState[p].length) {
              for (const i in priorState.SearchState[p]) {
                priorState.SearchState[p][i] = new SearchResult(priorState.SearchState[p][i])
              }
            }
            this[p] = priorState.SearchState[p]
            break
          case 'autocompleteCache':
            if (priorState.SearchState[p].length) {
              for (const i in priorState.SearchState[p]) {
                priorState.SearchState[p][i] = new SearchResult(priorState.SearchState[p][i])
              }
            }
            this[p] = priorState.SearchState[p]
            break
          case 'resultsExpire':
            if (priorState.SearchState[p]) {
              this[p] = moment(priorState.SearchState[p])
            }
            break
          case 'cacheMins':
            this[p] = parseInt(priorState.SearchState[p])
            break
          default:
            this[p] = priorState.SearchState[p]
        }
      }
    }
  }

  @Mutation
  private SET_SEARCH (search: ISearchParam) {
    this.searchParam = search
  }

  @Mutation
  private SET_SEARCH_RESULTS (results: Array<ISearchResult>) {
    this.results = results
    this.resultsExpire = moment().add(this.cacheMins, 'minute')
  }

  @Mutation
  private CACHE_AUTOCOMPLETE (search: ISearchParam, results: Array<ISearchResult>) {
    this.autocompleteCache.push({
      searchParam: search,
      results: results
    })

    if (this.autocompleteCache.length > 10) {
      this.autocompleteCache = this.autocompleteCache.slice(1)
    }
  }

  @Mutation
  UPDATE_RESULT (result: IStringIndexed) {
    const orig = this.results.find(s => s.id === result.id)
    if (orig) {
      for (const p in orig) {
        orig[p] = result[p]
      }
    }
  }

  @Action({ rawError: true })
  public async Search (search: ISearchParam) {
    if (search.term === undefined) {
      throw new Error('Search term was empty')
    }

    if (search.term === this.searchParam.term && moment().isSameOrBefore(this.resultsExpire)) {
      return this.results
    }

    this.SET_SEARCH(search)

    const results: Array<ISearchResult> = await SearchApi.search(search.term)

    this.SET_SEARCH_RESULTS(results)

    return results
  }

  @Action({ rawError: true })
  public async AdvancedSearch (search: ISearchParam) {
    if (search.filters === undefined) {
      throw new Error('No filters entered')
    }

    this.SET_SEARCH(search)

    const results: Array<ISearchResult> = await SearchApi.advancedSearch(search.filters)

    this.SET_SEARCH_RESULTS(results)

    return results
  }

  @Action({ rawError: true })
  public async Autocomplete (search: ISearchParam): Promise<Array<ISearchResult>> {
    for (const s of this.autocompleteCache) {
      if (s.searchParam === search) {
        return s.results
      }
    }

    const results: Array<ISearchResult> = []
    this.CACHE_AUTOCOMPLETE(search, results)

    return results
  }

  @Action({ rawError: true })
  public updateResult (newResult: IStringIndexed) {
    this.UPDATE_RESULT(newResult)
  }
}

export const SearchState = getModule(SearchStateDef)
