

































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import EventBus from '@/plugins/eventbus'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import moment from 'moment'
import commissionApi from '@/modules/finance/api/commissions'
import dtExporter from '@/modules/shared/datatablehelper'
import { FinanceCommission, FinanceCommissionSummary } from '@/modules/finance/models/commission'

import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import SalesPersonModel from '@/modules/sales/models/salesuser'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class GlobalCommReport extends Vue {
  private errorMessage = ''
  private noErrors = true

  private paidType = '0'
  private targetUser = 0
  private state = []

  private showAllFields = false
  private saving = false
  private loading = false
  private loaded = false
  private emailing = false
  private emailResults: IStringDictionary<string> = {}

  private menu = {
    targetCycleFrom: false,
    targetCycleTo: false
  }

  private paidTypes = [{ text: 'Unpaid', value: '0' }, { text: 'Paid', value: '1' }]
  private states = mapObjectVuetifySelect(AppState.servedStates)

  private targetCycleFrom = moment().format('YYYY-MM-01')
  private targetCycleTo = moment().format('YYYY-MM-01')

  private headers: Array<IStringIndexed> = [
    { text: 'Sales ID', align: 'left', sortable: true, value: 'salesId' },
    { text: 'Sales Name', align: 'left', sortable: true, value: 'salesName' },

    { text: 'Period', align: 'center', sortable: true, value: 'paidOn', width: 110, exportType: 'date' },
    { text: 'State', align: 'center', sortable: true, value: 'state', width: 75 },

    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName' },
    { text: 'Is Active?', align: 'left', sortable: true, value: 'accountActive', width: 75 },
    { text: 'Account #', align: 'left', sortable: true, value: 'accountNumber', exportType: 'text' },

    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber', width: 150 },
    { text: 'Start', align: 'center', sortable: true, value: 'usageStart', width: 125, exportType: 'date' },
    { text: 'End', align: 'center', sortable: true, value: 'usageEnd', width: 125, exportType: 'date' },

    { text: 'Quantity', align: 'right', sortable: true, value: 'quantity', width: 125, exportType: 'quantity' },
    { text: 'Rate', align: 'right', sortable: true, value: 'rate', width: 125, exportType: 'rate' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount', width: 125, exportType: 'money' }
  ]

  private emailHeaders: Array<IStringIndexed> = [
    { text: 'Sales ID', align: 'left', sortable: true, value: 'salesId', width: 100 },
    { text: 'Sales Name', align: 'left', sortable: true, value: 'salesName' },
    { text: 'Quantity (kWh)', align: 'right', sortable: true, value: 'quantity', width: 150 },
    { text: 'Amount ($)', align: 'right', sortable: true, value: 'amount', width: 150 },
    { text: 'Email To', align: 'right', sortable: true, value: 'reportEmail', width: 350, exportable: false }
  ]

  private resultsSearch = ''

  private searchFilters: IStringIndexed = {}

  private commissions: Array<FinanceCommission> = []
  private selectedCommissions: Array<FinanceCommission> = []

  private reportEmails: INumericDictionary<string> = {}

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('MMM DD, YYYY')
  }

  public get assignableSales () {
    return Object.values(SalesState.salesUsers).map((u: SalesPersonModel) => {
      return {
        text: u.fullName,
        value: u.id
      }
    })
  }

  private colorizeRow (comm: FinanceCommission) {
    if (!SalesState.salesUsers[comm.salesId]?.enablePayments) {
      return 'red lighten-5'
    }
    return ''
  }

  private isEnabled (salesId: number) {
    return SalesState.salesUsers[salesId]?.enablePayments === 1
  }

  private emailName (salesId: string) {
    return this.summaryCommissions.find(s => s.salesId === parseInt(salesId))?.salesName
  }

  private get hasEmails () {
    return Object.values(this.reportEmails).find(i => i && i.length > 0)?.length > 0
  }

  public async handleSearch () {
    this.errorMessage = ''
    Vue.set(this, 'emailResults', {})
    this.emailing = false

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loaded = true
      this.loading = true
      this.commissions = []

      Vue.set(this, 'searchFilters', {
        paid: this.paidType,
        from: this.targetCycleFrom,
        to: this.targetCycleTo,
        state: this.state,
        salesId: this.targetUser
      })

      this.commissions = await commissionApi.loadGcr(this.searchFilters)

      Vue.set(this, 'reportEmails', {})
      this.commissions.forEach(c => {
        if (!this.reportEmails[c.salesId] && SalesState.salesUsers[c.salesId].enablePayments) {
          Vue.set(this.reportEmails, c.salesId, SalesState.salesUsers[c.salesId].reportEmail ? SalesState.salesUsers[c.salesId].reportEmail : SalesState.salesUsers[c.salesId].email)
        }
      })
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private async handleEmailReports () {
    try {
      this.saving = true

      const emails: INumericDictionary<string> = {}
      for (const u in this.reportEmails) {
        if (this.reportEmails[u]) {
          emails[u] = this.reportEmails[u]
        }
      }
      Vue.set(this, 'emailResults', await commissionApi.emailReports(emails, this.searchFilters))
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.saving = false
    }
  }

  private handleExportList () {
    dtExporter(this.headers, this.commissions, 'Commission Detail Export', 'GCR-Details-from-' + this.targetCycleFrom + '-to-' + this.targetCycleTo + '-' + moment().format('YYYYMMDD-HHmm'))
  }

  private handleExportSummaryList () {
    dtExporter(this.emailHeaders, this.summaryCommissions, 'Commission Summary Export', 'GCR-Summary-from-' + this.targetCycleFrom + '-to-' + this.targetCycleTo + '-' + moment().format('YYYYMMDD-HHmm'))
  }

  private get summaryCommissions () {
    const summaries: Array<FinanceCommissionSummary> = []

    for (const c of this.commissions.filter(c => this.isEnabled(c.salesId))) {
      const existing = summaries.find(s => s.salesId === c.salesId)
      if (existing) {
        existing.amount += c.amount
        existing.quantity += c.quantity
      } else {
        summaries.push(new FinanceCommissionSummary({
          salesId: c.salesId,
          salesName: c.salesName,
          salesCompany: c.salesCompany,
          quantity: c.quantity,
          amount: c.amount
        }))
      }
    }

    return summaries
  }

  @Watch('targetCycleFrom')
  private handleFromUpdate () {
    if ((this.targetCycleFrom || '').length === 7) {
      this.targetCycleFrom += '-01'
    }
  }

  @Watch('targetCycleTo')
  private handleToUpdate () {
    if ((this.targetCycleTo || '').length === 7) {
      this.targetCycleTo += '-01'
    }
  }

  private beforeMount () {
    if (parseInt(moment().format('DD')) > 15) {
      this.targetCycleTo = moment().add(1, 'month').format('YYYY-MM-01')
    }
    SalesState.UpdateUserList()
  }
}
