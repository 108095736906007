



































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import Customer from '@/modules/customers/models/customer'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import { BankAccountApi } from '@/modules/customers/api/bankaccounts'
import EventBus from '@/plugins/eventbus'

@Component({
  components: {
  }
})
export default class BankAccountList extends Vue {
  @Prop({ required: true })
  private customer!: Customer

  @Prop({ required: true })
  private accounts!: Array<BankAccount>

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Type', align: 'left', sortable: true, value: 'accountTypeLabel' },
    { text: 'Bank', align: 'left', sortable: true, value: 'bankName' },
    { text: 'Holder', align: 'left', sortable: true, value: 'accountHolder' },
    { text: 'Number', align: 'left', sortable: true, value: 'accountNumber' },
    { text: '', align: 'left', sortable: true, value: 'actions' }
  ]

  private loading: boolean = false

  private localItem: BankAccount = new BankAccount({ customerId: this.customer?.id })

  private get canAddAccount () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTMETHODS.BANK.CREATE')
  }

  private get canDeleteAccount () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTMETHODS.BANK.DELETE')
  }

  private get canViewSensitive () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTMETHODS.BANK.SENSITIVE')
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.value !== 'Actions' })
    }

    return headers
  }

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private handleEditAccount (item: BankAccount) {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-bankaccount', componentProps: { account: item } })
  }

  private handleDelete (item: BankAccount) {
    if (confirm('Are you sure you want to delete this account?')) {
      CustomerState.DeleteBankAccount(item)
    }
  }

  private async handleShowSensitiveLog (item: BankAccount) {
    EventBus.$emit('open-cust-dialog', { component: 'bankaccount-log', componentProps: { account: item } })
  }

  private async handleRetrieveSensitive (item: BankAccount) {
    const businessNeed = prompt('Please enter the business need for viewing the restricted information')
    if (businessNeed !== null) {
      try {
        this.loading = true

        const { accountNumber, routingNumber } = await BankAccountApi.fetchSensitive(item, businessNeed)
        const updatedItem = item.clone()
        updatedItem.accountNumber = accountNumber
        updatedItem.routingNumber = routingNumber

        CustomerState.UpdateBankAccountInMemory(updatedItem)
      } catch (err) {
        EventBus.$emit('app-snack', {
          message: err
        })
      } finally {
        this.loading = false
      }
    }
  }
}
