





































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import EventBus from '@/plugins/eventbus'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import moment from 'moment'
import invoiceApi from '@/modules/finance/api/payments'
import dtExporter from '@/modules/shared/datatablehelper'
import { FinanceInvoice } from '@/modules/finance/models/invoice'
import { PaymentMethodEnum } from '@/modules/shared/enums'

@Component
export default class AchNew extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private showAllFields = false
  private saving = false
  private loading = false
  private loaded = false

  private draftAmounts: INumericDictionary<number> = {}

  private menu = {
    from: false,
    to: false,
    ach: false
  }

  private from = moment().format('YYYY-MM-DD')
  private to = moment().format('YYYY-MM-DD')
  private achDate = moment().add(1, 'day').format('YYYY-MM-DD')

  private headers: Array<IStringIndexed> = [
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName' },
    // { text: 'Status', align: 'left', sortable: true, value: 'customerStatus', width: 100 },
    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber', width: 150 },
    { text: 'Date', align: 'center', sortable: true, value: 'invoiceDate', width: 125, exportType: 'date' },
    { text: 'Due Date', align: 'center', sortable: true, value: 'dueDate', width: 125, exportType: 'date' },

    { text: 'Amount', align: 'right', sortable: true, value: 'amount', width: 150, exportType: 'money' },
    { text: 'Open Balance', align: 'right', sortable: true, value: 'balance', width: 150, exportType: 'money' },

    // { text: 'Bank', align: 'left', sortable: true, value: 'pmtInstitutionName' },
    { text: 'Account #', align: 'center', sortable: true, value: 'pmtAccountNumber' },
    { text: 'Service Account(s)', align: 'center', sortable: true, value: 'serviceAccounts' },
    { text: 'Draft Amount', align: 'center', sortable: true, value: 'draft', width: 180, exportType: 'money' }
  ]

  private resultsSearch = ''

  private invoices: Array<FinanceInvoice> = []

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  private colorizeRow (invoice: FinanceInvoice) {
    if (invoice.errorMessage.length) {
      return 'red lighten-4'
    } else if (invoice.haltPayments) {
      return 'orange lighten-4'
    } else {
      return ''
    }
  }

  @Watch('draftAmounts', { deep: true })
  private handleDraftAmountChange () {
    for (const id in this.draftAmounts) {
      const inv = this.invoices.find(inv => inv.invoiceId === parseInt(id))
      const newVal = this.draftAmounts[id]?.toString()
      const amount = parseFloat(parseFloat(newVal).toFixed(2))

      if (newVal === undefined || !newVal.length) {
        Vue.delete(this.draftAmounts, id)
      } else if (amount > inv!.balance) {
        Vue.set(this.draftAmounts, id, inv!.balance)
      } else {
        Vue.set(this.draftAmounts, id, amount.toFixed(2))
      }
    }
  }

  private get hasTransactions () {
    return Object.keys(this.draftAmounts).length > 0
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loaded = true
      this.loading = true
      this.invoices = []
      this.invoices = await invoiceApi.loadPayable({ from: this.from, to: this.to, paymentMethod: PaymentMethodEnum.ACH })
      Vue.set(this, 'draftAmounts', {})
      this.invoices.forEach(i => {
        if (!i.haltPayments && !i.errorMessage) {
          Vue.set(this.draftAmounts, i.invoiceId, i.balance)
        }
      })
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private async handleQueueDraft () {
    try {
      this.saving = true
      const pmts = await invoiceApi.queueAchPayments(this.draftAmounts, this.achDate)
      this.handleSearch()
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.saving = false
    }
  }

  private handleExportList () {
    dtExporter(this.headers, this.invoices, 'Invoice Due Export', 'ACH-InvoiceDue-from-' + this.from + '-to-' + this.to + '-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
