






































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import Rules from '@/plugins/validations'
import { CreditCheck } from '@/modules/finance/models/creditcheck'
import { CreditChecksApi } from '@/modules/finance/api/creditchecks'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import { PaymentMethodEnum } from '@/modules/shared/enums'
import { PaymentMethods } from '@/modules/shared/lists'

@Component({
  components: {}
})
export default class FinalizeForm extends Vue {
  @Prop({ default: 'denial' })
  private finalizeType!: string

  @PropSync('item', {
    default: () => new CreditCheck(),
    required: true
  })
  private localItem!: CreditCheck

  private salesNote: string = ''
  private paymentMethod: string = ''

  private errorMessage: string = ''
  private saving: boolean = false
  private valRules: Rules = Rules

  public get mode () {
    switch (this.finalizeType) {
      case 'approval':
        return 'Finalize Credit Review - Approval'
      case 'info':
        return 'Requires More Info'
      default:
        return 'Finalize Credit Review - Denial'
    }
  }

  public get modeLabel () {
    switch (this.finalizeType) {
      case 'approval':
        return 'Approve'
      case 'info':
        return 'Request Info'
      default:
        return 'Deny'
    }
  }

  private get modeColor () {
    switch (this.finalizeType) {
      case 'approval':
        return 'green'
      case 'info':
        return 'orange'
      default:
        return 'red'
    }
  }

  public get paymentMethods () {
    const methods = new PaymentMethods()
    return [
      { text: 'None', value: '' },
      { text: methods[PaymentMethodEnum.ACH], value: PaymentMethodEnum.ACH },
      { text: methods[PaymentMethodEnum.CHECK], value: PaymentMethodEnum.CHECK },
      { text: methods[PaymentMethodEnum.WIRE], value: PaymentMethodEnum.WIRE }
    ]
  }

  public handleClose () : void {
    (this.$refs.form as Vue & { resetValidation: () => boolean }).resetValidation()
    this.$emit('dialog:close')
  }

  public async handleSubmit () : Promise<void> {
    if (this.saving) {
      return
    }

    if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    const isNew: boolean = !(this.localItem.id > 0)

    try {
      const updatedItem = await CreditChecksApi.finalize(this.localItem, this.finalizeType, this.salesNote, this.paymentMethod)

      this.$emit('item:finalized', updatedItem)
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public mounted () {
  }
}
