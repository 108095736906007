import axios, { AxiosRequestConfig } from 'axios'
import { AuthState } from '@/modules/auth/store'
import { validateJsonToken } from '@/api/app'

const billingEngineClient = axios.create({
  baseURL: process.env.VUE_APP_BILLINGENGINE_REST_URL
})

billingEngineClient.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const now = new Date()
  if (!AuthState.services.billingengine.token?.length || !validateJsonToken(AuthState.services.billingengine.refreshToken)) {
    await AuthState.ServiceLogin('quoter')
  }

  if ((<Date>AuthState.services.billingengine?.logoutAt).getTime() < (now.getTime() - 3)) {
    await AuthState.RefreshServiceLogin('quoter')
  }

  config.headers.Authorization = 'Bearer ' + AuthState.services.billingengine.token
  return config
})

export default billingEngineClient
