





















import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { InvoiceAssemblyTypeEnum } from '@/modules/shared/enums'

@Component
export default class BillGroupBillingFormFields extends Vue {
  @PropSync('billGroup', {
    required: true,
    default: () => new BillGroup()
  })
  private localBillGroup!: BillGroup

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  private assemblyTypes = mapObjectVuetifySelect(BillGroup.assemblyTypes!, true)

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      assemblyType: [Rules.required()],
      assemblyDayOfMonth: [Rules.requiredIf(this.localBillGroup.assemblyType === InvoiceAssemblyTypeEnum.DAY_OF_MONTH ? '1' : '')]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  private get monthDays () {
    const days = []

    for (let i = 1; i <= 31; i++) {
      days.push({
        text: i === 31 ? '31 (Last Day)' : i,
        value: i
      })
    }

    return days
  }

  public mounted () {
  }
}
