

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import Rules from '@/plugins/validations'
import BankAccountGeneralFormFields from './bankaccount/Fields.vue'
import BankAccountBankFormFields from './bankaccount/Bank.vue'

@Component({
  components: {
    general: BankAccountGeneralFormFields,
    bank: BankAccountBankFormFields
  }
})
export default class BankAccountForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true

  @Prop({ default: () => new BankAccount() })
  private account!: BankAccount

  private bankaccounts = CustomerState.customer.bankaccounts

  private localBankAccount = new BankAccount()

  @Watch('bankaccounts', { immediate: true, deep: true })
  private bankaccountsUpdated (newBankAccounts: Array<BankAccount>, oldBankAccounts: Array<BankAccount>) {
    const match = newBankAccounts.find(n => n.id === this.localBankAccount.id)

    if (match) {
      if (match.updatedAt !== this.localBankAccount.updatedAt) {
        this.errorMessage = 'WARNING: This bank account has been updated by someone else. And you may overwrite the changes'
      }
    }
  }

  public async handleSubmit () {
    if (this.saving || !this.canSave) {
      return
    }

    if (!(this.$refs.formRef as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    try {
      const account: BankAccount = await CustomerState.SaveBankAccount(this.localBankAccount)
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localBankAccount = new BankAccount()
  }

  public get mode () {
    return this.localBankAccount.id ? 'Edit' : 'Add'
  }

  public get canSave () {
    if (this.localBankAccount.id) {
      return AuthState.user.isAllowed('CUSTOMER.PAYMENTMETHODS.BANK.EDIT')
    }
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTMETHODS.BANK.CREATE')
  }

  public beforeMount () {
    this.localBankAccount = this.account.clone()

    if (!this.localBankAccount.id) {
      this.localBankAccount.customerId = CustomerState.customer.id
    }
  }
}
