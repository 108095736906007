









































































import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Rules from '@/plugins/validations'
import CostsApi from '@/modules/wholesale/api/costs'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import dtExporter from '@/modules/shared/datatablehelper'

@Component
export default class NormalizedCosts extends Vue {
  private errorMessage = ''
  private noErrors = true
  private loading: boolean = false

  private pullBy: string = 'flow'
  private costMonth: string = moment().subtract(1, 'month').format('YYYY-MM')
  private flowMonth: string = moment().subtract(3, 'month').format('YYYY-MM')
  private settlementNumber: number = 1

  private valRules: Rules = Rules

  private recs: Array<IStringIndexed> = []

  private menu = {
    costMonth: false,
    flowMonth: false
  }

  private pullBys = [
    { value: 'flow', text: 'Flow Month' },
    { value: 'bill', text: 'Bill Month' }
  ]

  private settlementNumbers = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 0, text: 'All' }
  ]

  public async handleFetchRecords () {
    if (this.loading) {
      return
    }

    Vue.set(this, 'recs', [])

    this.errorMessage = ''
    this.loading = true

    if (this.errorMessage) {
      return
    }

    try {
      this.loading = true

      this.recs = await CostsApi.loadCosts(this.pullBy === 'bill' ? this.costMonth + '-01' : '', this.pullBy === 'flow' ? this.flowMonth + '-01' : '', this.settlementNumber)
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private handleExportList () {
    const label = 'NormalizedCosts-by-' + (this.pullBy === 'flow' ? 'FlowMonth-' + this.flowMonth : 'BillMonth-' + this.costMonth) + '-' + moment().format('YYYYMMDD-HHmm')

    dtExporter(this.exportColumnHeaders, this.filteredRecs, 'Normalized Costs', label)
  }

  private get filteredRecs () {
    const recs = Array.from(this.recs)

    return recs
  }

  public get columnHeaders () {
    const headers = [
      { text: 'ISO Zone ID', align: 'center', sortable: true, value: 'isoZoneId' },
      { text: 'Zone Name', align: 'left', sortable: true, value: 'zoneName' },
      { text: 'Component Name', align: 'left', sortable: true, value: 'componentName' },
      { text: 'Period', align: 'center', sortable: true, value: 'period' },
      { text: 'Quantity', align: 'right', sortable: true, value: 'quantity' },
      { text: 'Rate', align: 'right', sortable: true, value: 'rate' },
      { text: 'Charge', align: 'right', sortable: true, value: 'charge' }
    ]

    return headers
  }

  public get exportColumnHeaders () {
    const headers = [
      { text: 'Zone ID', value: 'zoneId' },
      { text: 'ISO Zone ID', value: 'isoZoneId' },
      { text: 'Zone Name', value: 'zoneName' },
      { text: 'Component ID', value: 'componentId' },
      { text: 'Component Name', value: 'componentName' },
      { text: 'Period', value: 'period', exportType: 'datetime' },
      { text: 'Quantity', value: 'quantity', exportType: 'quantity' },
      { text: 'Rate', value: 'rate', exportType: 'rate' },
      { text: 'Charge', value: 'charge', exportType: 'rate' },
      { text: 'Bill Month', value: 'billMonth', exportType: 'date' },
      { text: 'Settlement #', value: 'settlementNumber' }
    ]

    return headers
  }
}
