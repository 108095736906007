import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import moment from 'moment'
import store, { priorState } from '@/stores'
import { AuthState } from '@/modules/auth/store'
import CisUser from '@/modules/auth/models/cisuser'
import appApi from '@/api/app'
import usersApi from '@/modules/users/api/users'
import utilitiesApi from '@/modules/shared/api/utilities'
import { ListsEnum, CommodityEnum, StatesAbbrevType, StateAbbreviationsEnum } from '@/modules/shared/enums'
import { Utility } from '@/modules/shared/models/utility'
import { Commodities } from '@/modules/shared/lists'
import { INumericDictionary, IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { CustomerState } from '@/modules/customers/store'
import EventBus from '@/plugins/eventbus'
import MenuItem from '@/modules/shared/menuitem'

export interface IViewedItem {
  id: number;
  name: string;
}
export interface IAppState {
  layout: string;
  enableTabNav: boolean;
  recentlyViewed: Array<IViewedItem>;
  menuItems: Array<MenuItem>;
  cisUsers: INumericDictionary<CisUser>;
  utilities: INumericDictionary<Utility>;
  servedStates: IStringDictionary<StatesAbbrevType>;
  cisUsersListExpire: string;
  listsByName: {
    [key in ListsEnum]?: INumericDictionary<string>
  };
  listsByDesc: {
    [key in ListsEnum]?: INumericDictionary<string>
  };
  appListExpire: string;
  utilityListExpire: string;
  settings: IStringIndexed;
}

type ISettings = IStringIndexed

@Module({ dynamic: true, store, name: 'AppState' })
export class AppStateDef extends VuexModule implements IAppState {
  [key: string]: any
  private maxRecentlyViewed = 10;

  public layout = 'bare'
  public enableTabNav = false
  public recentlyViewed = <Array<IViewedItem>>[]
  public menuItems: Array<MenuItem> = []
  public cisUsers: INumericDictionary<CisUser> = {}
  public utilities: INumericDictionary<Utility> = {}
  public servedStates: IStringDictionary<StatesAbbrevType> = {}
  public cisUsersListExpire = moment().toISOString()
  public listsByName: {[key in ListsEnum]?: INumericDictionary<string>} = {}
  public listsByDesc: {[key in ListsEnum]?: INumericDictionary<string>} = {}
  public appListExpire = moment().toISOString()
  public utilityListExpire = moment().toISOString()
  public settings: ISettings = {}

  private timerCisUserList = 0;
  private timerAppList = 0;
  private timerUtilities = 0;

  private _cisUserNames: INumericDictionary<string> = {}

  constructor (module: any) {
    super(module)

    let priorExpire: moment.Moment

    if (priorState && priorState.AppState) {
      for (const p in priorState.AppState) {
        switch (p) {
          case 'menuItems':
            break
          case 'cisUsers': {
            const objs: INumericDictionary<CisUser> = {}

            for (const id in priorState.AppState[p]) {
              objs[parseInt(id)] = new CisUser(priorState.AppState[p][id])
            }

            this[p] = objs
            break
          }
          case 'utilities': {
            const objs: INumericDictionary<Utility> = <INumericDictionary<Utility>> {}

            for (const id in priorState.AppState[p]) {
              objs[parseInt(id)] = new Utility(priorState.AppState[p][id])
            }

            this[p] = objs
            break
          }
          case 'cisUsersListExpire':
            priorExpire = moment(priorState.AppState[p])
            if (moment().isSameOrBefore(priorExpire)) {
              this.timerCisUserList = setTimeout(function () { AppState.UpdateCisUsers() }, priorExpire.diff(moment()))
            }
            break
          case 'appListExpire':
            priorExpire = moment(priorState.AppState[p])
            if (moment().isSameOrBefore(priorExpire)) {
              this.timerAppList = setTimeout(function () { AppState.UpdateAppLists() }, priorExpire.diff(moment()))
            }
            break
          case 'utilityListExpire':
            priorExpire = moment(priorState.AppState[p])
            if (moment().isSameOrBefore(priorExpire)) {
              this.timerUtilities = setTimeout(function () { AppState.UpdateUtilities() }, priorExpire.diff(moment()))
            }
            break
          default:
            this[p] = priorState.AppState[p]
        }
      }
    }

    this.CreateMenu()

    if (AuthState.isLoggedIn) {
      this.SetLayout('app')
    }
  }

  @Mutation
  private UPDATE_LAYOUT (newLayout: string) {
    this.layout = newLayout
  }

  @Mutation
  private TOGGLE_TAB_NAV (forceVal: boolean) {
    this.enableTabNav = forceVal
  }

  @Mutation
  private UPDATE_RECENTLY_VIEWED (newItem: IViewedItem) {
    if (!newItem.id) {
      return
    }

    this.recentlyViewed = this.recentlyViewed.filter(i => i.id !== newItem.id)

    this.recentlyViewed.unshift(newItem)

    if (this.recentlyViewed.length > this.maxRecentlyViewed) {
      this.recentlyViewed = this.recentlyViewed.slice(0, this.maxRecentlyViewed)
    }
  }

  @Mutation
  private SET_MENU_ITEMS (menuItems: Array<MenuItem>) {
    Vue.set(this, 'menuItems', menuItems)
  }

  @Mutation
  private SET_APP_USERS (users: INumericDictionary<CisUser>) {
    Vue.set(this, 'cisUsers', users)
    this._cisUserNames = {}

    if (Object.values(users).length) {
      const duration = moment.duration(4, 'h')
      const expire = moment().add(duration)
      this.cisUsersListExpire = expire.toISOString()

      const t = this
      this.timerCisUserList = setTimeout(function () { AppState.UpdateCisUsers() }, duration.asMilliseconds())
    } else {
      this.cisUsersListExpire = moment().toISOString()
      if (this.timerCisUserList) {
        clearTimeout(this.timerCisUserList)
        this.timerCisUserList = 0
      }
    }
  }

  @Mutation
  private ADD_APP_USER (user: CisUser) {
    if (user.id) {
      this.cisUsers[user.id] = user
      if (Object.keys(this._cisUserNames).length) {
        this._cisUserNames[user.id] = user.detailName
      }
    }
  }

  @Mutation
  private UPDATE_APP_USER (user: CisUser) {
    if (user.id && this.cisUsers[user.id]) {
      this.cisUsers[user.id] = user
      if (this._cisUserNames[user.id]) {
        this._cisUserNames[user.id] = user.detailName
      }
    }
  }

  @Mutation
  private SET_APP_LISTS (lists: {[key in ListsEnum]?: INumericDictionary<{ d: string; n: string }>}) {
    const listByName: {[key in ListsEnum]?: INumericDictionary<string>} = {}
    const listByDesc: {[key in ListsEnum]?: INumericDictionary<string>} = {}

    Object.entries(lists)
      .forEach(([listId, list]) => {
        listByName[listId as keyof typeof lists] = {}
        listByDesc[listId as keyof typeof lists] = {}
        Object.entries(list!)
          .forEach(([k, v]) => {
            if (v.n) {
              listByName[listId as keyof typeof lists]![parseInt(k)] = v.n
              listByDesc[listId as keyof typeof lists]![parseInt(k)] = v.d
            } else {
              listByName[listId as keyof typeof lists]![parseInt(k)] = v
              listByDesc[listId as keyof typeof lists]![parseInt(k)] = v
            }
          })
      })

    Vue.set(this, 'listsByName', listByName)
    Vue.set(this, 'listsByDesc', listByDesc)

    const duration = moment.duration(4, 'h')
    const expire = moment().add(duration)
    this.appListExpire = expire.toISOString()
    const t = this
    this.timerAppList = setTimeout(function () { AppState.UpdateAppLists() }, duration.asMilliseconds())
  }

  @Mutation
  private SET_SETTINGS (settings: ISettings) {
    Vue.set(this, 'settings', settings)
  }

  @Mutation
  private SET_UTILITIES (utilities: Array<Utility>) {
    const utilDict: INumericDictionary<Utility> = {}
    const servedStates: IStringDictionary<StatesAbbrevType> = {}
    utilities.forEach(i => {
      servedStates[i.state] = i.state
      utilDict[i.id] = i
    })
    Vue.set(this, 'utilities', utilDict)
    Vue.set(this, 'servedStates', servedStates)

    const duration = moment.duration(4, 'd')
    const expire = moment().add(duration)
    this.utilityListExpire = expire.toISOString()

    this.timerUtilities = setTimeout(function () { AppState.UpdateUtilities() }, duration.asMilliseconds())
  }

  @Action
  public SetLayout (newLayout: string) {
    this.UPDATE_LAYOUT(newLayout)
  }

  @Action ToggleTabNav (forceVal: boolean) {
    this.TOGGLE_TAB_NAV(forceVal)
  }

  @Action
  public AddRecentlyViewed (newItem: IViewedItem) {
    this.UPDATE_RECENTLY_VIEWED(newItem)
  }

  @Action
  public CreateMenu () {
    if (!AuthState.user.id) {
      return []
    }

    const menuItems: Array<MenuItem> = [
      <MenuItem>{
        icon: 'users',
        text: 'Customer Management',
        link: { path: '/customermanagement' },
        permission: AuthState.user.isAllowed('CUSTOMER_MGMT.ACCESS')
      },

      <MenuItem>{
        icon: 'billing',
        text: 'Billing Management',
        link: { name: 'billingroot' },
        permission: AuthState.user.isAllowed('BILLING.VIEW')
      },

      <MenuItem>{
        icon: 'dataservices',
        text: 'Data Services',
        link: { name: 'dataservicesroot' },
        permission: AuthState.user.isAllowed('DATA_SERVICES.VIEW')
      },

      <MenuItem>{
        icon: 'finance',
        text: 'Finance',
        link: { name: 'financeroot' },
        permission: AuthState.user.isAllowed('FINANCE.VIEW')
      },

      <MenuItem>{
        icon: 'collections',
        text: 'Collections',
        permission: AuthState.user.isAllowed('FINANCE.COLLECTIONS.VIEW')
      },

      <MenuItem>{
        icon: 'wholesale',
        text: 'Wholesale',
        link: { name: 'wholesaleroot' },
        permission: AuthState.user.isAllowed('WHOLESALE.VIEW')
      },

      <MenuItem>{
        icon: 'salesusers',
        text: 'Sales',
        link: { name: 'salesroot' },
        permission: AuthState.user.isAllowed('SALES.VIEW')
      },

      <MenuItem>{
        icon: 'cisusers',
        text: 'Users',
        link: { name: 'usersroot' },
        permission: AuthState.user.isAllowed('USERS.VIEW')
      },

      <MenuItem>{
        icon: 'itusers',
        text: 'IT',
        permission: AuthState.user.isAllowed('IT.VIEW') || AuthState.isImpersonating,
        children: [
          {
            icon: 'impersonate',
            text: 'Impersonate',
            click: () => {
              EventBus.$emit('open-app-dialog', 'impersonate')
            },
            permission: AuthState.user.isAllowed('IT.SHAPESHIFTER') && !AuthState.isImpersonating
          },
          {
            icons: [{ icon: ['far', 'clone'], transform: 'shrink-2 down-2 left-2' }, { icon: ['fas', 'times-circle'], transform: 'shrink-6 right-8 up-8' }],
            text: 'Stop Impersonating',
            click: () => {
              AuthState.StopImpersonation()
            },
            permission: AuthState.isImpersonating
          }
        ]
      },
      <MenuItem>{
        icon: 'usercircle',
        text: AuthState.user.firstName,
        permission: true,
        children: [
          { icon: 'usercog', text: 'Profile', link: { path: '/user/profile' }, permission: true },
          { icon: 'signout', text: 'Sign out', link: { name: 'logout' }, permission: true }
        ]
      }
    ]

    this.SET_MENU_ITEMS(menuItems)
  }

  @Action
  public async CacheAppData (ignoreCache = false) {
    this.CreateMenu()
    this.UpdateAppLists()
    this.UpdateSettings()
    this.UpdateCisUsers(ignoreCache)
    this.UpdateUtilities(ignoreCache)
  }

  @Action ClearCacheData () {
    this.SET_APP_USERS({})
  }

  @Action
  public async UpdateCisUsers (ignoreCache?: boolean) {
    const expire = moment(this.cisUsersListExpire)
    if (moment().isSameOrAfter(expire) || ignoreCache) {
      this.SET_APP_USERS(await usersApi.loadAll({
        userCategory: 'canSell'
      }))
    }
  }

  @Action AddSalesUser (user: CisUser) {
    this.ADD_APP_USER(user)
  }

  @Action UpdateSalesUser (user: CisUser) {
    this.UPDATE_APP_USER(user)
  }

  @Action
  public async UpdateAppLists () {
    const expire = moment(this.appListExpire)

    if (moment().isSameOrAfter(expire)) {
      this.SET_APP_LISTS(await appApi.loadAll())
    }
  }

  @Action public async UpdateSettings () {
    const settings = await appApi.loadSettings()
    for (const groupName in settings) {
      if (groupName === 'customer') {
        CustomerState.SetSettings(settings[groupName])
      } else {
        this.SET_SETTINGS(settings[groupName])
      }
    }
  }

  @Action
  public async UpdateUtilities (ignoreCache = false) {
    const expire = moment(this.utilityListExpire)

    if (ignoreCache || moment().isSameOrAfter(expire)) {
      this.SET_UTILITIES(await utilitiesApi.loadAll())
    }
  }

  public get cisUserNames () {
    if (Object.keys(this._cisUserNames).length === 0) {
      Object.entries(this.cisUsers).forEach(([id, u]) => { this._cisUserNames[parseInt(id)] = u.detailName })
    }

    return this._cisUserNames
  }

  public get showTabNav () {
    return this.enableTabNav && vuetify.framework.breakpoint.smAndDown
  }

  public get sicLabels () {
    return AppState.listsByDesc[ListsEnum.SIC]!
  }

  public utilitiesByCommodity (commodity: keyof Commodities) {
    const utils = []
    for (const u of Object.values(this.utilities)) {
      if (u.commodity & <number> commodity) {
        utils.push(u)
      }
    }
    return utils
  }

  public get dividedStateSelect () {
    const states: Array<IStringDictionary<string>> = [{ header: 'Served States' }]

    for (const state in this.servedStates) {
      states.push({
        text: state,
        value: state
      })
    }

    states.push({ divider: 'true' })
    states.push({ header: 'Other States' })

    for (const state in StateAbbreviationsEnum) {
      if (!states.some(s => s.value === state)) {
        states.push({
          text: state,
          value: state
        })
      }
    }

    return states
  }
}

export const AppState = getModule(AppStateDef)
