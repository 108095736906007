

















































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import { InvoiceQueuesApi } from '@/modules/billing/api/queue/invoicequeues'
import EventBus from '@/plugins/eventbus'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { CustomerInvoiceStatusEnum } from '@/modules/shared/enums'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import { InvoicesApi } from '@/modules/customers/api/invoices'
import moment from 'moment'

@Component
export default class SendInvoicesQueue extends Mixins(DownloadFile) {
  @Prop({ default: 0 })
  private itemId!: number

  private selectedInvoices: Array<CustomerInvoice> = []
  private sending = false

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'State', align: 'left', width: 85, sortable: true, value: 'customer.address.state' },
    { text: 'Customer', align: 'left', width: 'auto', sortable: true, value: 'customer.businessName' },
    { text: 'Invoiced', align: 'center', width: 110, sortable: true, value: 'invoiceDate' },
    { text: 'Total', align: 'center', width: 110, sortable: true, value: 'grandTotal' },
    { text: 'Send Via', align: 'center', width: 150, sortable: true, value: 'billGroup.deliveryMethodLabel' },
    { text: 'Recipient(s)', align: 'auto', width: 300, sortable: false, value: 'recipients' },
    { text: 'Preview', align: 'center', width: 90, sortable: false, value: 'actions' }
  ]

  private loading = false

  private items: Array<CustomerInvoice> = []

  private showFilters = false
  private listSearch: string = ''

  private processing: INumericDictionary<string> = {}

  private get filteredItems () {
    return this.items
  }

  public async handleDownloadPdf (invoice: CustomerInvoice) {
    try {
      Vue.set(this.processing, invoice.id, 'download')
      const file = await InvoicesApi.getPdf(invoice)
      this.downloadFile(file.name, file.binaryContents, 'application/pdf')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processing, invoice.id)
    }
  }

  public async handleMarkSent () {
    if (!this.selectedInvoices.length) {
      return
    }

    if (!confirm('Are you sure you want to remove these invoices from the queue without sending?')) {
      return
    }

    try {
      this.sending = true

      for (const invoice of this.selectedInvoices) {
        invoice.status = CustomerInvoiceStatusEnum.STATUS_SENT
        const updated = await InvoicesApi.savePartial(invoice, ['status'])

        const idx = this.items.findIndex(c => c.id === updated.id)
        if (idx !== -1) {
          this.items.splice(idx, 1)
        }
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.sending = false
    }
  }

  public async handleSendInvoices () {
    if (!this.selectedInvoices.length) {
      return
    }

    try {
      this.sending = true

      const resp = await InvoiceQueuesApi.sendInvoices(this.selectedInvoices.filter(i => i.hasDeliveryDestination))

      for (const invId in resp.statusUpdates) {
        const idx = this.items.findIndex(c => c.id === parseInt(invId))
        if (idx !== -1) {
          this.items.splice(idx, 1)
        }
      }

      if (resp.downloadFile) {
        this.downloadFile('SnailMail-' + moment().format('YYYY-MM-DD hh:mm') + '.pdf', resp.downloadFile.binaryContents, 'application/pdf')
      }

      if (resp.errorMessage) {
        EventBus.$emit('app-snack', {
          message: resp.errorMessage
        })
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.sending = false
    }
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  private beforeMount () {
    InvoiceQueuesApi.loadAll({
      status: {
        OR: {
          '=': CustomerInvoiceStatusEnum.STATUS_APPROVED.toString(),
          AND: {
            '=': CustomerInvoiceStatusEnum.STATUS_SENDING.toString(),
            '<': {
              property: 'updatedAt',
              value: moment.utc().subtract(5, 'minutes').format('YYYY-MM-DD HH:mm:ss')
            }
          }
        }
      }
    })
      .then(resp => {
        this.items = resp.items
      }).catch(err => (this.errorMessage = err))
  }
}
