


















import { Component, Vue } from 'vue-property-decorator'
// import { AuthState } from '@/modules/auth/store'
// import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'
import moment from 'moment'
import p2cApi from '@/modules/shared/api/p2c'
import { IStringIndexed } from '@/modules/shared/types'

@Component({
  components: {}
})
export default class UnbilledReportParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true

  private menu = {
    from: false,
    to: false
  }

  private from = ''
  private to = ''

  private searching = false

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.searching = true

    try {
      // const statements = await p2cApi.loadStatements()
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.searching = false
    }
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
