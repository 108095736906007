import Vue from 'vue'
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store, { priorState } from '@/stores'
import moment from 'moment'
import MenuItem from '@/modules/shared/menuitem'
import { SalesUserApi, SalesUsersTaxIdApi, SalesUsersAgreementApi, SalesUsersNoteApi, SalesUsersFileApi } from '../api/salesusers'
import SalesPersonModel from '@/modules/sales/models/salesuser'
import AgreementModel from '@/modules/sales/models/salesuser/agreement'
import FileModel from '@/modules/sales/models/salesuser/file'
import NoteModel from '@/modules/sales/models/salesuser/note'
import TaxModel from '@/modules/sales/models/salesuser/taxid'
import { INumericIndexed, IStringIndexed, IStringDictionary, INumericDictionary } from '@/modules/shared/types'
import EventBus from '@/plugins/eventbus'
import { Base } from '@/modules/shared/models/base'

export interface ISalesState extends IStringIndexed {
  users: INumericDictionary<SalesPersonModel>
  loading: IStringDictionary<boolean>;
  usersCacheExpire: string;
  navItems: Array<MenuItem>;
}

interface ListItemPayload {
  listName: string,
  item: Base
}

@Module({ dynamic: true, store, name: 'SalesState' })
class SalesStateDef extends VuexModule implements ISalesState {
  [key: string]: any

  public navItems: Array<MenuItem> = []

  public users: INumericDictionary<SalesPersonModel> = {}
  public loading: IStringDictionary<boolean> = {
    user: false,
    notes: false,
    files: false,
    agreements: false,
    taxIds: false
  }

  public usersCacheExpire = moment().subtract(1, 'second').toISOString()

  constructor (module: any) {
    super(module)

    if (priorState && priorState.SalesState) {
      for (const p in priorState.SalesState) {
        switch (p) {
          case 'users': {
            const list: INumericDictionary<SalesPersonModel> = {}
            for (const i in priorState.SalesState[p] as INumericDictionary<SalesPersonModel>) {
              list[priorState.SalesState[p][i].id] = new SalesPersonModel(priorState.SalesState[p][i])
            }
            priorState.SalesState[p] = list
            break
          }
          case 'usersCacheExpire': {
            const priorExpire = moment(priorState.SalesState[p])
            if (moment.utc().isSameOrAfter(priorExpire)) {
              setTimeout(() => SalesState.UpdateUserList(true), 1000)
            }
            this[p] = priorState.SalesState[p]
            break
          }
          default:
            this[p] = priorState.SalesState[p]
        }
      }
    }
  }

  @Mutation
  private RESET_LOADING (status = false) {
    for (const p in this.loading) {
      this.loading[p] = status
    }
  }

  @Mutation
  private UPDATE_LOADING (payload: {name: string; status: boolean}) {
    this.loading[payload.name] = payload.status
  }

  @Mutation
  private SET_USERS_LIST (users: INumericDictionary<SalesPersonModel>) {
    Vue.set(this, 'users', users)

    if (Object.keys(users).length) {
      this.usersCacheExpire = moment().add(5, 'minutes').toISOString()
    } else {
      this.usersCacheExpire = moment().subtract(5, 'minutes').toISOString()
    }

    this._salesNames = {}
  }

  @Mutation
  private ADD_OR_UPDATE_USERS (user: SalesPersonModel) {
    this.users[user.id] = user
  }

  @Mutation
  private SET_USER_LIST (payload: { salesId: number, listName: string, items: Array<Base> }): void {
    Vue.set(this.users[payload.salesId], payload.listName, payload.items)
  }

  @Mutation
  private ADD_USER_LIST_ITEM ({ listName, item }: ListItemPayload) {
    this.users[item.salesId][listName].unshift(item)
  }

  @Mutation
  private UPDATE_USER_LIST_ITEM ({ listName, item }: ListItemPayload) {
    const index = this.users[item.salesId][listName].findIndex((n: Base) => n.id === item.id)
    Vue.set(this.users[item.salesId][listName], index, item)
  }

  @Mutation
  private DELETE_USER_LIST_ITEM ({ listName, item }: ListItemPayload) {
    const index = this.users[item.salesId][listName].findIndex((n: Base) => n.id === item.id)
    this.users[item.salesId][listName].splice(index, 1)
  }

  @Action({ rawError: true })
  public async UpdateUserList (ignoreCache = false) {
    try {
      const expire = moment(this.usersCacheExpire)
      if (moment().isAfter(expire) || ignoreCache) {
        const users = await SalesUserApi.loadAll()
        this.SET_USERS_LIST(users)
      }
    } catch (err) {
      return Promise.resolve()
    }
  }

  @Action({ rawError: true })
  public async LoadUser (id: number) {
    this.RESET_LOADING(true)
    const user = await SalesUserApi.load(id, undefined, ['notes', 'agreements', 'files', 'taxIds'])
    this.ADD_OR_UPDATE_USERS(user)
    this.UPDATE_LOADING({ name: 'user', status: false })
    this.UPDATE_LOADING({ name: 'notes', status: false })
    this.UPDATE_LOADING({ name: 'agreements', status: false })
    this.UPDATE_LOADING({ name: 'taxIds', status: false })
    this.UPDATE_LOADING({ name: 'files', status: false })
  }

  @Action({ rawError: true })
  public async SaveUser (user: SalesPersonModel) {
    const person = await SalesUserApi.save(user)
    this.ADD_OR_UPDATE_USERS(person)
    return person
  }

  @Action({ rawError: true })
  public async LoadUserTaxIds ({ salesId, ignoreCache = false } : {salesId: number, ignoreCache: boolean }) {
    if (!this.users[salesId]) {
      return
    }

    if (ignoreCache || !this.users[salesId].taxIds.length || this.users[salesId].taxIds[0].id === 0) {
      this.UPDATE_LOADING({ name: 'taxIds', status: true })
      const taxIds = await SalesUsersTaxIdApi.loadAll(this.salesId)
      this.SET_USER_LIST({ salesId: salesId, listName: 'taxIds', items: taxIds })
    }
    this.UPDATE_LOADING({ name: 'taxIds', status: false })
  }

  @Action({ rawError: true })
  public async SaveUserTaxId (taxId: TaxModel) {
    const isNew = !(taxId.id > 0)

    const newTaxId = await SalesUsersTaxIdApi.save(taxId.salesId, taxId)
    if (isNew) {
      this.ADD_USER_LIST_ITEM({ listName: 'taxIds', item: newTaxId })
    } else {
      this.UPDATE_USER_LIST_ITEM({ listName: 'taxIds', item: newTaxId })
    }
  }

  @Action({ rawError: true })
  public async LoadUserNotes ({ salesId, ignoreCache = false } : {salesId: number, ignoreCache: boolean }) {
    if (!this.users[salesId]) {
      return
    }

    if (ignoreCache || !this.users[salesId].notes.length || this.users[salesId].notes[0].id === 0) {
      this.UPDATE_LOADING({ name: 'notes', status: true })
      const notes = await SalesUsersNoteApi.loadAll(salesId)
      this.SET_USER_LIST({ salesId: salesId, listName: 'notes', items: notes })
    }
    this.UPDATE_LOADING({ name: 'notes', status: false })
  }

  @Action({ rawError: true })
  public async SaveUserNote (note: NoteModel) {
    const isNew = !(note.id > 0)

    const newNote = await SalesUsersNoteApi.save(note.salesId, note)
    if (isNew) {
      this.ADD_USER_LIST_ITEM({ listName: 'notes', item: newNote })
    } else {
      this.UPDATE_USER_LIST_ITEM({ listName: 'notes', item: newNote })
    }
    return newNote
  }

  @Action({ rawError: true })
  public async DeleteUserNote (note: NoteModel) {
    const deleted = await SalesUsersNoteApi.delete(note)
    this.DELETE_USER_LIST_ITEM({ listName: 'notes', item: deleted })
  }

  @Action({ rawError: true })
  public async LoadUserAgreements ({ salesId, ignoreCache = false } : {salesId: number, ignoreCache: boolean }) {
    if (!this.users[salesId]) {
      return
    }

    if (ignoreCache || !this.users[salesId].agreements.length || this.users[salesId].agreements[0].id === 0) {
      this.UPDATE_LOADING({ name: 'agreements', status: true })
      const agreements = await SalesUsersAgreementApi.loadAll(salesId)
      this.SET_USER_LIST({ salesId: salesId, listName: 'agreements', items: agreements })
    }
    this.UPDATE_LOADING({ name: 'agreements', status: false })
  }

  @Action({ rawError: true })
  public async SaveUserAgreement (agreement: AgreementModel) {
    const isNew = !(agreement.id > 0)

    const newAgreement = await SalesUsersAgreementApi.save(agreement.salesId, agreement)
    if (isNew) {
      this.ADD_USER_LIST_ITEM({ listName: 'agreements', item: newAgreement })
    } else {
      this.UPDATE_USER_LIST_ITEM({ listName: 'agreements', item: newAgreement })
    }
    return newAgreement
  }

  @Action({ rawError: true })
  public async DeleteUserAgreement (agreement: AgreementModel) {
    const deleted = await SalesUsersAgreementApi.delete(agreement)
    this.DELETE_USER_LIST_ITEM({ listName: 'agreements', item: deleted })
  }

  @Action({ rawError: true })
  public async LoadUserFiles ({ salesId, ignoreCache = false } : {salesId: number, ignoreCache: boolean }) {
    if (!this.users[salesId]) {
      return
    }

    if (ignoreCache || !this.users[salesId].files.length || this.users[salesId].files[0].id === 0) {
      this.UPDATE_LOADING({ name: 'files', status: true })
      const files = await SalesUsersFileApi.loadAll(salesId)
      this.SET_USER_LIST({ salesId: salesId, listName: 'files', items: files })
    }
    this.UPDATE_LOADING({ name: 'files', status: false })
  }

  @Action({ rawError: true })
  public async SaveUserFile (payload: { file: FileModel, callback: Function }) {
    const isNew = !(payload.file.id > 0)

    const newFile = await SalesUsersFileApi.save(payload.file.salesId, payload.file, payload.callback)
    if (isNew) {
      this.ADD_USER_LIST_ITEM({ listName: 'files', item: newFile })
    } else {
      this.UPDATE_USER_LIST_ITEM({ listName: 'files', item: newFile })
    }
    return newFile
  }

  @Action({ rawError: true })
  public async DeleteUserFile (file: FileModel) {
    const deleted = await SalesUsersFileApi.delete(file)
    this.DELETE_USER_LIST_ITEM({ listName: 'files', item: deleted })
  }

  public get salesUsers () {
    return this.users
  }

  public get salesNames () {
    if (Object.keys(this.users).length === 0) {
      SalesState.UpdateUserList(true)
    }

    if (Object.keys(this._salesNames).length === 0) {
      Object.entries(this.users).forEach(([id, u]) => { this._salesNames[parseInt(id)] = (u as SalesPersonModel).detailName })
    }

    return this._salesNames
  }

  @Action({ rawError: true })
  public ClearCacheData () {
    this.SET_USERS_LIST({})
    this.SET_ACTIVE_USER(new SalesPersonModel())
  }
}

export const SalesState = getModule(SalesStateDef)
