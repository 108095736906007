export default function NumberAbbrev (v: number, precision: number, d: number): string {
  const magnitudes = ['', 'K', 'M', 'B', 'T']
  const l = Math.floor(v).toString().length - 1

  const m = Math.floor(l / 3)
  const ld = l % 3 + 1
  const fp = Math.pow(10, (Math.min(precision - ld, d || precision - ld)))

  const n = Math.round(v * fp / Math.pow(10, m * 3)) / fp

  if (isNaN(n)) {
    return '-'
  }

  return n + magnitudes[m]
}
