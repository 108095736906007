




























import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import EventBus from '@/plugins/eventbus'
import { CommodityEnum } from '../../../shared/enums'
import AccountFactory from '@/modules/shared/models/accountfactory'
import { IAccount } from '../../../shared/models/interfaces/account'
import Customer from '../../models/customer'
import { Contract } from '../../models/customer/contract'

@Component({
  components: {
  }
})
export default class Contracts extends Vue {
  public matchesFilter (contract: Contract) {
    return contract.accountIds.some(id => CustomerState.filteredAccounts.find(a => a.id === id))
  }

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  public get accounts () {
    return CustomerState.customer.accounts
  }

  public get utilities () {
    return AppState.utilities
  }

  @Watch('loading', { immediate: true, deep: true })
  private handleCustomerIdChange (newCust: Customer, oldCust: Customer) {
    if (this.loading.accounts === null) {
      CustomerState.LoadAccounts()
        .catch((err) => {
          EventBus.$emit('app-snack', {
            message: err
          })
        })
    }
  }

  public async mounted () {
    if (CustomerState.loading.contracts === null) {
      await CustomerState.LoadContracts()
    }

    if (this.customer.contracts.length && this.$route.name === 'customercontracts') {
      this.$router.replace({ name: 'customercontract', params: { contractId: this.customer.contracts[0].id.toString() } })
    }
  }
}
