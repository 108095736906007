import { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { Base as BaseModel } from '@/modules/shared/models/base'

export type RequestParam = IStringDictionary<string | Array<string|number>> | IStringIndexed
export type RequestConfig = IStringDictionary<RequestParam>
export default class ModelApi {
  protected client!: AxiosInstance
  protected urlStructure: Array<string> = []
  protected singular: string = ''
  protected plural: string = ''
  protected factory: Function = () => {}
  protected idCol: string = 'id'
  protected errHandlers: { [key: string]: null|Function } = {
    loadAll: null,
    load: null,
    create: null,
    update: null,
    updatePartial: null,
    delete: null
  }

  constructor (client: AxiosInstance) {
    this.client = client
  }

  protected async loadAllSpecific (args: IArguments|Array<any>, config?: RequestConfig): Promise<Array<BaseModel>|{ total: number, items: Array<BaseModel> }> {
    let response = null
    try {
      const arrArgs = Array.from(args)

      if (config !== undefined) {
        arrArgs.pop()
      }
      // Hit some kind of weird limit with the webpack dev server and query string length.
      // switched to a Post GET which the backend is able to handle
      response = await this.client.request({
        url: this.buildUrl(arrArgs),
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: config
      })

      if (response.status === 200 && response.data[this.plural]) {
        const list: Array<BaseModel> = []

        Object.values(response.data[this.plural]).forEach((i) => {
          list.push(this.factory(i))
        })

        if (response.data.total !== undefined) {
          return { total: response.data.total, items: list }
        }

        return list
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (this.errHandlers.loadAll) {
        return this.errHandlers.loadAll(err, response)
      }
      return checkMessageOrThrow(err)
    }
  }

  protected async loadSpecific (args: IArguments|Array<any>, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<BaseModel> {
    let response = null
    let url = ''
    try {
      const data: IStringIndexed = {}
      if (onlyProps !== undefined) {
        data.include = onlyProps
      }

      if (extraProps !== undefined) {
        data.additional = extraProps
      }

      const arrArgs = Array.from(args)
      if (onlyProps) {
        arrArgs.pop()
      }

      if (extraProps) {
        arrArgs.pop()
      }

      url = this.buildUrl(arrArgs)
      // Hit some kind of weird limit with the webpack dev server and query string length.
      // switched to a Post GET which the backend is able to handle
      response = await this.client.request({
        url: url,
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: data
      })

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (this.errHandlers.loadAll) {
        return this.errHandlers.loadAll(err, response)
      }

      return checkMessageOrThrow(err)
    }
  }

  protected async saveSpecific (args: IArguments|Array<any>, data: IStringIndexed) : Promise<BaseModel> {
    let response = null
    let method = 'POST'
    try {
      if (data[this.idCol]) {
        method = 'PUT'
      }

      const arrArgs = Array.from(args).slice(0, -1)

      response = await this.client.request({
        url: this.buildUrl(arrArgs),
        method: <Method> method,
        data: data
      })

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      const handlerName = method === 'POST' ? 'create' : 'update'
      if (this.errHandlers[handlerName]) {
        return this.errHandlers[handlerName]!(err, response)
      }
      return checkMessageOrThrow(err)
    }
  }

  protected async deleteSpecific (args: Array<any>) : Promise<BaseModel> {
    let response = null
    try {
      if (!args[this.urlStructure.length - 1]) {
        throw new Error('Missing ID while deleting ' + (typeof this))
      }

      const arrArgs = Array.from(args).slice(0, this.urlStructure.length)

      response = await this.client.delete(this.buildUrl(arrArgs))

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (this.errHandlers.delete) {
        return this.errHandlers.delete(err, response)
      }
      return checkMessageOrThrow(err)
    }
  }

  protected async savePartialSpecific (args: IArguments|Array<any>, data: IStringIndexed, onlyProps: Array<string>) {
    let response = null
    try {
      const arrArgs = Array.from(args)

      const reqObj: IStringIndexed = {}

      for (const p of onlyProps) {
        reqObj[p] = data[p]
      }

      response = await this.client.request({
        url: this.buildUrl(arrArgs),
        method: <Method> 'PATCH',
        data: reqObj
      })

      if (response.status === 200 && response.data[this.singular]) {
        return this.factory(response.data[this.singular])
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (this.errHandlers.savePartial) {
        return this.errHandlers.savePartial(err, response)
      }
      return checkMessageOrThrow(err)
    }
  }

  protected buildUrl (args: Array<string|number>): string {
    let url = ''

    for (let x = 0; x < this.urlStructure.length; x++) {
      url += (url.length ? '/' : '') + this.urlStructure[x]
      if (args[x]) {
        url += '/' + args[x]
      }
    }
    return url
  }
}
