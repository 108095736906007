import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { Method, AxiosResponse } from 'axios'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { IStringDictionary, IStringIndexed } from '../types'
import AccountFactory from '../models/accountfactory'

interface IAccountResponse {
  account: IAccount;
}

interface IMultiAccountResponse {
  accounts: Array<IAccount>;
}

export default class AccountsApi {
  public static async loadAll (filters?: IStringDictionary<string|IStringDictionary<string|IStringDictionary<string>>>): Promise<Array<IAccount>> {
    let query = ''
    if (filters) {
      query = filtersToQs(filters)
    }

    try {
      const response: AxiosResponse = await esalesClient.request({
        url: '/serviceaccounts' + query,
        method: <Method> 'GET'
      })

      if (response.status === 200 && response.data.accounts) {
        const accounts: Array<IAccount> = []

        response.data.accounts.forEach((i: IStringIndexed) => {
          accounts.push(AccountFactory.getAccountFromJson(i))
        })

        return accounts
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
