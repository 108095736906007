import { StatesAbbrevType } from '@/modules/shared/enums'
import { Base } from './base'
import { IStringIndexed, IStringDictionary } from '../types'

export interface IZipcode extends IStringIndexed {
  code: string;
  plus4?: string;
}

export class Zipcode extends Base implements IZipcode, IStringIndexed {
  public code = ''
  public plus4 = ''

  constructor (props?: Partial<IZipcode>) {
    super(props)
    Object.assign(this, props)
  }
}

export interface IAddress extends IStringIndexed {
  street: string;
  street2?: string | null;
  city: string;
  state: StatesAbbrevType | null;
  zip: IZipcode;
  type: number;
}

export class Address extends Base implements IAddress {
  public id: number = 0
  public street = ''
  public street2 = null
  public city = ''
  public state: StatesAbbrevType|null = null
  public zip = new Zipcode()
  public type = 0

  constructor (props?: Partial<IAddress>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'zip':
            props[p] = new Zipcode(props[p])
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
