










































































































































import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { CreditChecksApi } from '@/modules/finance/api/creditchecks'
import EventBus from '@/plugins/eventbus'
import FinalizeForm from './csq/FinalizeForm.vue'
import FileUploadForm from '@/modules/finance/components/forms/creditqueue/FileUpload.vue'
import { File } from '@/modules/shared/models/file'
import { AppState } from '@/stores/appStore'
import { ContractSubmissionStatusEnum, TransactionRequestStatusEnum, TransactionRequestTypeEnum, UserGroupsEnum } from '@/modules/shared/enums'
import CSQAssignStep from './csq/Assign.vue'
import CSQDocReviewStep from './csq/DocReview.vue'
import CSQCustomerInfoStep from './csq/CustomerInfo.vue'
import CSQContactsStep from './csq/Contacts.vue'
import CSQServiceAccountsStep from './csq/ServiceAccounts.vue'
import CSQBillGroupsStep from './csq/BillGroups.vue'
import CSQContractStep from './csq/Contract.vue'
import CSQCommissionsStep from './csq/Commissions.vue'
import CSQCreateRecordsStep from './csq/CreateRecord.vue'
import CSQEnrollAccountsStep from './csq/EnrollAccounts.vue'
import { ContractSubmissionsApi, IOnespan } from '../api/queue/contractsubmissions'
import { ContractSubmission } from '../models/queue/contractsubmission'
import AccountsApi from '@/modules/customers/api/accounts'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import CustomerApi from '@/modules/customers/api/customer'
import moment from 'moment'
import { PeriodDetail } from '@/modules/customers/models/account/perioddetail'
import CSQPaymentInfoStep from './csq/PaymentInfo.vue'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { Transaction } from '@/modules/customers/models/account/transaction'
import { TransactionRequestsApi } from '@/modules/customers/api/transactionrequests'

interface IStepConfig {
  label: string,
  component: typeof Vue,
  bindings: IStringIndexed,
  saveProps?: Array<string>,
  saveAction?: Function,
  nextLabel?: () => string|string
}

@Component({
  components: {
    'finalize-form': FinalizeForm,
    'upload-files': FileUploadForm
  }
})
export default class CSQItem extends Vue {
  @Prop({ required: true })
  private item!: ContractSubmission

  @Ref()
  private forms!: Array<Vue>

  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors: boolean = true
  private dialog: boolean = false
  private finalizeMode = ''

  private localItem: ContractSubmission = new ContractSubmission()

  private step = 0

  private fileDialog: boolean = false

  private noteSaving: boolean = false
  private newNoteBody: string = ''

  private loading = {
    meters: false,
    crmFiles: false,
    cisFiles: false,
    esignFiles: false,
    creating: false,
    enrolling: false
  }

  private crmFiles: Array<IStringIndexed> = []
  private accounts: Array<ElectricAccount> = []

  private cisFiles: Array<IStringIndexed> = []
  private esignRequest: IOnespan = {
    package: {},
    files: [],
    fields: []
  }

  private download (file: IStringIndexed) {
    const byteString = atob(file.content ? file.content : file.contents)
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    const blob = new Blob([ia], { type: file.mimeType })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = file.fileName ? file.fileName : file.name
    link.click()
  }

  private handleUnviewItem () {
    this.$router.push({ name: 'contractsubmissionqueue' })
    Vue.set(this, 'localItem', new ContractSubmission())
  }

  private async handleNext () {
    if (!this.stepValid(this.step)) {
      return
    }

    try {
      const config = this.allSteps[this.step]
      let updated = new ContractSubmission()

      if (config.saveAction) {
        updated = await config.saveAction(config)
      } else {
        const data = this.localItem.clone()
        if (this.step + 1 > data.step) {
          data.step = this.step + 1
        }
        updated = await ContractSubmissionsApi.savePartial(data, config.saveProps || ['step'])
      }

      if (updated) {
        Vue.set(this, 'localItem', updated)
        EventBus.$emit('csqitem:update', updated)
        this.step = updated.step
      }
    } catch (err) {
      this.errorMessage = err
    }
  }

  private async handleSaveNote () {
    try {
      this.noteSaving = true
      this.$emit('item:update', await ContractSubmissionsApi.saveNote(this.item, this.newNoteBody))
      this.newNoteBody = ''
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.noteSaving = false
    }
  }

  private handleFinalize (mode: string) {
    this.finalizeMode = mode
    this.dialog = true
  }

  private handleItemFinalized (item: ContractSubmission) {
    this.$emit('item:finalize', item)
  }

  private async handleDownloadCisFile (fileId: number) {
    try {
      const newFile = await CreditChecksApi.loadCisFile(this.item.id, fileId)

      this.download(newFile)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private handleDialogClose () {
    this.dialog = false
    this.finalizeMode = ''
  }

  private handleAttachFile () {
    this.fileDialog = true
  }

  private async handleFileUpload (item: { reading: boolean; completed: number; file: File }) {
    const newFile = await ContractSubmissionsApi.attachFile(this.item.id, item.file, (e: ProgressEvent) => {
      item.reading = true
      item.completed = Math.round((e.loaded * 100) / e.total)
    })

    const newItem = this.item.clone()
    newItem.data.cisFileIds.push(newFile.id)
    this.cisFiles.push(newFile)
    this.$emit('item:update', newItem)
  }

  private async handleCreateRecords () {
    try {
      if (this.localItem.status === ContractSubmissionStatusEnum.APPROVED) {
        this.localItem.step++
        return this.localItem
      }
      this.loading.creating = true
      this.errorMessage = ''

      const saving = this.localItem.clone()
      const updated = await ContractSubmissionsApi.finalize(saving, 'approve')

      // Need to reload accounts to pickup any changes.
      try {
        this.loading.meters = true
        Vue.set(this, 'accounts', await AccountsApi.loadAll({ id: this.item.data.accountIds }))
      } catch (err) {
        EventBus.$emit('app-snack', {
          message: err,
          timeout: 5000
        })
      } finally {
        this.loading.meters = false
      }

      return updated
    } catch (err) {
      this.errorMessage = err

      try {
        return await ContractSubmissionsApi.load(this.localItem.id)
      } catch (reloadErr) {
        this.errorMessage = 'An error occurred while attempting to reload the item, please exit the Queue entirely and try again.'
      }
    } finally {
      this.loading.creating = false
    }
  }

  private async handleEnrollments () {
    const form = this.forms.find(f => f.$el.id === 'CSQEnrollAccountsStep')
    if (!((form as Vue).$refs.form as Vue & { validate: () => boolean }).validate()) {
      return
    }

    let resp: Array<TransactionRequest> = []
    this.loading.enrolling = true
    try {
      const submittableRequests: Array<TransactionRequest> = []
      this.accounts.forEach(acct => {
        const req = acct.enrollmentRequest
        if (!req) {
          return
        }
        // retry errors if selected
        if (req.hasError && req.active) {
          req.id = 0
        }
        // dont send completed or inactive
        if (!req.isUnsent || !req.active) {
          return
        }
        submittableRequests.push(req)
      })

      if (submittableRequests.length) {
        resp = await ContractSubmissionsApi.enroll(this.localItem.id, submittableRequests)
        for (const req of resp) {
          const idx = this.accounts.findIndex(r => r.id === req.accountId)
          if (idx !== -1) {
            Vue.set(this.accounts[idx], 'enrollmentRequest', req)
          }
        }
      }

      const notFinished = this.accounts.filter(acct => {
        return acct.enrollmentRequest?.status === TransactionRequestStatusEnum.NEW
      })
      if (notFinished.length) {
        const data = this.localItem.clone()
        data.status = ContractSubmissionStatusEnum.ENROLLED
        const updated = await ContractSubmissionsApi.savePartial(data, ['status'])

        this.$emit('item:finalize', updated)
        EventBus.$emit('app-snack', {
          color: 'green',
          message: submittableRequests.length ? 'Enrollments queued successfully' : 'Contract processed successfully',
          timeout: 5000
        })
      }
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading.enrolling = false
    }
  }

  private handleAccountUpdate (account: ElectricAccount) {
    const idx = this.accounts.findIndex(a => a.id === account.id)

    if (idx !== -1) {
      Vue.set(this.accounts, idx, account)
    }
  }

  private preparePeriodDetails () {
    for (const id of this.localItem.customerShell?.accountIds || []) {
      const acct = this.accounts.find(a => a.id === id)
      if (acct) {
        let cp = this.localItem.customerShell!.accounts.find(a => a.id === id) as ElectricAccount
        if (!cp) {
          cp = new ElectricAccount({
            id: id,
            periodDetails: []
          })
          this.localItem.customerShell!.accounts.push(cp)
        }
        const pds = cp.periodDetails.filter(pd => moment(pd.period).isSameOrAfter(this.localItem.data.startMonth))
        if (pds.length) {
          pds.forEach(pd => {
            pd.contractId = this.localItem.customerShell!.contracts[0].id
            if (!this.localItem.customerShell?.billGroups.find(g => g.id === pd.billGroupId)) {
              pd.billGroupId = this.localItem.customerShell!.billGroups[0].id
            }
          })
        } else {
          cp.periodDetails.push(new PeriodDetail({
            id: -1,
            accountId: cp.id,
            contractId: this.localItem.customerShell?.contracts[0].id,
            billGroupId: this.localItem.customerShell?.billGroups[0].id,
            period: this.localItem.data.startMonth
          }))
        }
      } else {
        console.warn(id + ' in list but not in result from server')
      }
    }
  }

  @Watch('item.id', { immediate: true })
  private async handleItemChange (newId: number, oldId?: number) {
    if (this.item.id && newId !== oldId) {
      if (this.item.data.accountIds && this.item.data.accountIds.length) {
        this.loading.meters = true
        AccountsApi.loadAll({ id: this.item.data.accountIds })
          .then(accts => {
            Vue.set(this, 'accounts', accts)
            if (!this.accounts.length) {
              this.errorMessage = 'No Accounts passed over from CRM, please have sales resubmit'
            }
            if (this.accounts.length !== this.item.data.accountIds.length) {
              // this.errorMessage = 'The number of accounts on the quote in CRM does not match the number of accounts found in CIS.'
              // Move this check to the service accounts tab and load the missing account info from CRM. Show whatever errors there.
            }
            this.preparePeriodDetails()
          })
          .catch(err => {
            EventBus.$emit('app-snack', {
              message: err,
              timeout: 5000
            })
          }).finally(() => { this.loading.meters = false })
      } else {
        this.errorMessage = 'No Accounts passed over from CRM, please have sales resubmit'
      }

      if (this.item.data.crmFileIds && this.item.data.crmFileIds.length) {
        this.loading.crmFiles = true
        CreditChecksApi.loadCrmFiles(this.item.data.crmFileIds)
          .then(files => { Vue.set(this, 'crmFiles', files) })
          .catch(err => {
            EventBus.$emit('app-snack', {
              message: err,
              timeout: 5000
            })
          }).finally(() => { this.loading.crmFiles = false })
      }

      if (this.item.fileIds && this.item.fileIds.length) {
        this.loading.cisFiles = true
        ContractSubmissionsApi.loadCisFiles(this.item.id, this.item.fileIds)
          .then(files => { Vue.set(this, 'cisFiles', files) })
          .catch(err => {
            EventBus.$emit('app-snack', {
              message: err,
              timeout: 5000
            })
          }).finally(() => { this.loading.cisFiles = false })
      }

      this.localItem = this.item.clone()

      if (this.item.data.esignId && this.item.data.esignId.length) {
        this.loading.esignFiles = true
        ContractSubmissionsApi.loadEsignFiles(this.item.id, this.item.data.esignId)
          .then(resp => {
            Vue.set(this, 'esignRequest', resp)
            this.localItem.importBankFromEsign(resp.fields)
          })
          .catch(err => {
            EventBus.$emit('app-snack', {
              message: err,
              timeout: 5000
            })
          }).finally(() => { this.loading.esignFiles = false })
      }

      this.step = this.item.step || 0
    }
  }

  @Watch('localItem.customerId', { immediate: true })
  private handleCustomerIdChange (newId: number, oldId?: number) {
    if (!this.localItem?.customerId) {
      return
    }

    if (this.step > 2) {
      return
    }

    CustomerApi.load(this.localItem.customerId, ['bankaccounts'])
      .then(cust => {
        this.localItem.importFromExisting(cust)
        this.preparePeriodDetails()
      })
  }

  private stepValid (currStep: number) {
    if (Array.isArray(this.forms) && this.forms[currStep]) {
      if ((this.forms[currStep] as IStringIndexed).stepValid !== undefined) {
        return (this.forms[currStep] as IStringIndexed).stepValid
      }

      if ((this.forms[currStep]as Vue).$refs.form !== undefined) {
        return ((this.forms[currStep] as Vue).$refs.form as Vue & { validate: () => boolean }).validate()
      }
    }
    return true
  }

  private get nextLabel () {
    if (this.allSteps[this.step].nextLabel) {
      if (typeof this.allSteps[this.step].nextLabel === 'function') {
        // @ts-ignore
        return this.allSteps[this.step].nextLabel()
      } else {
        return this.allSteps[this.step].nextLabel
      }
    }
    return 'Continue'
  }

  private get allSteps (): Array<IStepConfig> {
    return [
      {
        label: 'Ownership',
        component: CSQAssignStep,
        bindings: {
          item: this.localItem
        },
        saveProps: ['userId', 'step', 'customerShell']
      },
      {
        label: 'Review Docs',
        component: CSQDocReviewStep,
        bindings: {
          item: this.localItem,
          files: this.crmFiles,
          esign: this.esignRequest,
          loading: this.loading.crmFiles
        },
        saveProps: ['step', 'customerShell']
      },
      {
        label: 'Customer Info',
        component: CSQCustomerInfoStep,
        bindings: {
          item: this.localItem
        },
        saveProps: ['step', 'customerId', 'customerShell']
      },
      {
        label: 'Contact Info',
        component: CSQContactsStep,
        bindings: {
          item: this.localItem
        },
        saveProps: ['step', 'customerShell']
      },
      {
        label: 'Service Accounts',
        component: CSQServiceAccountsStep,
        bindings: {
          item: this.localItem,
          accounts: this.accounts
        },
        saveProps: ['step', 'customerShell']
      },
      {
        label: 'Payment Info',
        component: CSQPaymentInfoStep,
        bindings: {
          item: this.localItem
        },
        saveProps: ['step', 'customerShell']
      },
      {
        label: 'Bill Groups',
        component: CSQBillGroupsStep,
        bindings: {
          item: this.localItem,
          accounts: this.accounts
        },
        saveProps: ['step', 'customerShell']
      },
      {
        label: 'Contract',
        component: CSQContractStep,
        bindings: {
          item: this.localItem
        },
        saveProps: ['step', 'customerShell']
      },
      {
        label: 'Commissions',
        component: CSQCommissionsStep,
        bindings: {
          item: this.localItem
        },
        saveProps: ['step', 'customerShell']
      },
      {
        label: 'Create',
        component: CSQCreateRecordsStep,
        bindings: {
          item: this.localItem,
          accounts: this.accounts
        },
        nextLabel: () => this.localItem.status < ContractSubmissionStatusEnum.APPROVED ? 'Create' : 'Next',
        saveAction: this.handleCreateRecords
      },
      {
        label: 'Enroll',
        component: CSQEnrollAccountsStep,
        bindings: {
          item: this.localItem,
          accounts: this.accounts,
          isP2c: this.isP2c,
          loading: this.loading.enrolling
        },
        nextLabel: () => this.accounts.filter(a => a.enrollmentRequest?.isUnset).length === 0 ? 'Finish' : (this.isP2c ? 'Send to P2C' : 'Send Enrollments'),
        saveAction: this.handleEnrollments
      }
    ]
  }

  private get isP2c () {
    // @note: possible to be a blend of both, but business policy will keep them separate
    return this.accounts[0]?.isP2c
  }

  private mounted () {
    this.localItem = this.item.clone()
    this.handleItemChange(0)
  }
}
