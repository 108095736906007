import Search from '../pages/Search.vue'
import SideNav from '../components/SideNav.vue'
import BottomNav from '../components/BottomNav.vue'

/** Not really used, Customer Management routes override I believe */
export default [
  {
    path: '/search',
    name: 'search',
    components: {
      SideNav: SideNav,
      BottomNav: BottomNav,
      default: Search
    },
    title: 'Search',
    meta: { requiresAuth: true }
  }
]
