import { INumericDictionary, IStringIndexed } from './types'
import moment from 'moment'
import XLSX, { WritingOptions } from 'xlsx'
import JsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export interface IDataTableHeaders {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
  groupable?: boolean;
  width?: 80;
  exportType: string;
  exportable?: boolean
}

export function exportDataTableToPDF (headers: Array<IStringIndexed>, data: Array<IStringIndexed>, fileName: string) {
  const rows: Array<Array<string|number>> = []
  const columns = headers.filter(h => h.exportable === undefined || h.exportable !== false)
  data.forEach(record => {
    const newRow: Array<string|number> = []
    columns.forEach(h => {
      let val = String(getNestedValue(record, h.value))

      switch (h.exportType) {
        case 'date':
          val = moment(val).format('M/D/YYYY')
          break
        case 'money':
          val = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(val))
          break
      }
      newRow.push(val)
    })
    rows.push(newRow)
  })
  const doc = new JsPDF()
  autoTable(doc, { head: [columns.map(h => h.text)], body: rows })
  doc.save(fileName + '.pdf')
}

export default function exportDataTable (headers: Array<IStringIndexed>, data: Array<IStringIndexed>, sheetName: string, fileName: string, opts?: WritingOptions) {
  const rows: Array<Array<string>> = []

  const header: Array<string> = []
  const keys: Array<string> = []
  headers.forEach(f => {
    if (f.exportable === undefined || f.exportable !== false) {
      header.push(f.text)
      keys.push(f.value)
    }
  })
  rows.push(header)

  const numberCols: INumericDictionary<string> = {}
  const bookType: string = opts?.bookType !== undefined ? opts.bookType : 'csv'
  data.forEach(s => {
    const row: Array<string> = []

    keys.forEach((k, i) => {
      const info = headers.find(h => h.value === k)
      const rawVal = String(getNestedValue(s, k))

      if (info?.exportType !== undefined && info.exportType === 'text') {
        if (bookType === 'xlsx') {
          row.push(rawVal)
        } else {
          row.push("'" + rawVal)
        }
      } else if (info?.exportType !== undefined && info.exportType === 'money') {
        numberCols[i] = '$#,##0.00'
        row.push(rawVal)
      } else if (info?.exportType !== undefined && info.exportType === 'rate') {
        numberCols[i] = '0.000000'
        row.push(rawVal)
      } else if (info?.exportType !== undefined && info.exportType === 'quantity') {
        numberCols[i] = '#,##0.00'
        row.push(rawVal)
      } else if (info?.exportType !== undefined && info.exportType === 'date') {
        row.push(moment(rawVal).format('MM/DD/YYYY'))
      } else if (info?.exportType !== undefined && info.exportType === 'datetime') {
        row.push(moment(rawVal).format('MM/DD/YYYY HH:mm:ss'))
      } else {
        row.push(rawVal)
      }
    })
    rows.push(row)
  })
  // Headers always present
  if (rows.length > 1) {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(rows)

    // for (const c of ['E', 'F', 'G', 'H', 'I']) {
    for (const colNum in numberCols) {
      const fmt = numberCols[colNum] // or '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)' or any Excel number format

      /* get worksheet range */
      const range = XLSX.utils.decode_range(ws['!ref']!)
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        /* find the data cell (range.s.r + 1 skips the header row of the worksheet) */
        const ref = XLSX.utils.encode_cell({ r: i, c: parseInt(colNum) })
        /* if the particular row did not contain data for the column, the cell will not be generated */
        if (!ws[ref]) {
          continue
        }
        /* `.t == "n"` for number cells */
        if (ws[ref].t !== 'n') {
          continue
        }
        /* assign the `.z` number format */
        ws[ref].z = fmt
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, sheetName)
    XLSX.writeFile(wb, fileName + '.' + bookType, opts)
  }
}

export function getNestedValue (item: any, valuePath: string): string|number {
  const parts = valuePath.split('.') // fetch nested properties
  let curItem = item
  for (const n of parts) {
    curItem = curItem[n]
    if (typeof curItem === 'string' || typeof curItem === 'number') {
      return curItem
    }
  }
  return ''
}
