































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { Address } from '@/modules/shared/models/address'
import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'
import { IStringIndexed } from '@/modules/shared/types'

@Component
export default class AddressFormFields extends Vue {
  @PropSync('address', {
    default: () => new Address(),
    required: true
  })
  private localAddress!: Address

  @Prop({ default: null })
  private validations!: IStringIndexed|null

  private states = AppState.dividedStateSelect

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      street: [],
      street2: [],
      city: [],
      state: [Rules.required()],
      zip: []
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {
  }
}
