var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Payment: ("+_vm._s(_vm.payment.id)+") "+_vm._s(_vm.payment.referenceNumber)+" - "+_vm._s(_vm._f("formatDate")(_vm.payment.payDate,'MM-DD-YYYY'))+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.applicationHeaders,"items":_vm.filteredApplications,"item-class":_vm.colorizeRow,"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"sort-by":"id","sort-desc":true,"loading-text":"Loading...please wait"},scopedSlots:_vm._u([{key:"header.actions",fn:function(ref){return [_c('v-icon',{attrs:{"color":_vm.showReversed ? 'primary': '',"title":"Toggle Reversed Filter"},on:{"click":function($event){$event.stopPropagation();_vm.showReversed = !_vm.showReversed}}},[_vm._v("$vuetify.icons.reversepmt")])]}},{key:"item.applicationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.applicationDate,'MM-DD-YYYY'))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.amount))+" ")]}},{key:"item.accountNum",fn:function(ref){
var item = ref.item;
return [(item.invoiceId)?_c('span',[_vm._v(_vm._s(_vm.getAccountNumber(_vm.getInvoice(item), item)))]):_vm._e()]}},{key:"item.invoiceId",fn:function(ref){
var item = ref.item;
return [(_vm.canViewAccountInvoices)?_c('router-link',{attrs:{"to":{ name: 'customerinvoicedetail', params: { invoiceId: _vm.getInvoice(item).id, detailId: item.invoiceId } },"title":item.invoiceId}},[_vm._v(_vm._s(_vm.getInvoice(item).invoiceNumber))]):[_vm._v(_vm._s(_vm.getInvoice(item).invoiceNumber))]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.processingAction(item) !== 'reverse')?[(_vm.canReverseApplication && item.isReversible)?_c('v-btn',{attrs:{"icon":"","title":"Reverse Application","disabled":_vm.processingAction(item) !== false},on:{"click":function($event){return _vm.handleReverseApplication(item)}}},[_c('v-icon',[_vm._v(" $vuetify.icons.reversepmt ")])],1):_vm._e()]:_c('v-progress-circular',{attrs:{"indeterminate":true}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }