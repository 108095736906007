












import { Component, Vue, Prop } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import { IBaseModel } from '@/modules/shared/models/base'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class AssignItemForm extends Vue {
  @Prop({ required: true })
  private item!: IBaseModel

  @Prop({ default: null })
  private assignPermission!: null|string

  @Prop({ default: -1 })
  private targetGroup!: number

  private assignedTo = AuthState.user.id

  public get assignableUsers () {
    if (this.assignPermission !== null && AuthState.user.isAllowed(this.assignPermission)) {
      const users = Object.values(AppState.cisUsers).filter(u => u.groupIds.indexOf(this.targetGroup) !== -1)
      return users.map(u => { return { value: u.id, text: u.lastName + ', ' + u.firstName } })
    }
    return [{ value: AuthState.user.id, text: AuthState.user.lastName + ', ' + AuthState.user.firstName }]
  }

  private async handleAssign () {
    try {
      this.item.assignedTo = this.assignedTo
      this.$emit('item:updated', this.item)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }
}
