import { IAddress, Address } from '@/modules/shared/models/address'
import { ICustomerContact, Contact } from './customer/contact'
import { CustomerTypes, Commodities, CustomerStatuses, CustomerAccountingStatuses } from '@/modules/shared/lists'
import { CustomerTypeEnum, CustomerStatusEnum, CustomerAccountingStatusEnum, CommodityEnum, TaxCategoriesEnum } from '@/modules/shared/enums'
import { INote, Note } from '@/modules/shared/models/note'
import { ICustomerInvoice, CustomerInvoice } from './customer/invoice'
import { ICustomerPayment, CustomerPayment } from './customer/payment'
import { Base } from '@/modules/shared/models/base'
import { IFile, File } from '@/modules/shared/models/file'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import AccountFactory from '@/modules/shared/models/accountfactory'
import { IStringIndexed } from '@/modules/shared/types'
import { BankAccount } from './customer/bankaccount'
import { ISearchResult } from '@/modules/search/models/searchresult'
import { BillGroup, IBillGroup } from './customer/billgroup'
import { Contract, ICustomerContract } from './customer/contract'
import { TaxExemptForm, ITaxExemptForm } from './customer/taxexemptform'

export interface ICustomerFlags {
  [key: string]: number
  disableBilling: number
  disablePayments: number
  disableCollections: number
  disableLateFees: number
  holdApprovals: number
}

export interface ICustomer extends IStringIndexed {
  id: number;
  businessName: string;
  dba: string;
  type: keyof CustomerTypes;
  status: keyof CustomerStatuses;
  accountingStatus: keyof CustomerAccountingStatuses;
  taxClassification: number;
  flags: ICustomerFlags;

  sic: string | null;
  address: IAddress;

  contacts: Array<ICustomerContact>
  billGroups: Array<IBillGroup>;
  contracts: Array<ICustomerContract>;
  notes: Array<INote>;
  invoices: Array<ICustomerInvoice>;
  payments: Array<ICustomerPayment>;
  files: Array<IFile>;
  accounts: Array<IAccount>;
  accountIds: Array<number>;
  taxExemptForms: Array<ITaxExemptForm>;
}

export default class Customer extends Base implements ICustomer {
  public static types = new CustomerTypes()
  public static statii = new CustomerStatuses()
  public static accountingStatii = new CustomerAccountingStatuses()

  public id = 0

  public businessName = ''
  public dba = ''
  public type = CustomerTypeEnum.MED_COMM
  public status = CustomerStatusEnum.NEW
  public accountingStatus = CustomerAccountingStatusEnum.CREDITREVIEW
  public taxClassification = TaxCategoriesEnum.TAXCAT_COMMERCIAL
  public flags: ICustomerFlags = {
    disableBilling: 0,
    disablePayments: 0,
    disableCollections: 0,
    disableLateFees: 0,
    holdApprovals: 0
  }

  public sic = null

  public address = new Address()

  public contacts = <Array<ICustomerContact>> []
  public billGroups = <Array<BillGroup>> []
  public contracts = <Array<Contract>> []
  public notes = <Array<Note>> []
  public invoices = <Array<CustomerInvoice>> []
  public payments = <Array<CustomerPayment>> []
  public files = <Array<File>> []
  public accountIds = <Array<number>> []
  public accounts = <Array<IAccount>> []
  public bankaccounts = <Array<BankAccount>> []
  public taxExemptForms = <Array<TaxExemptForm>> []

  constructor (props: Partial<ICustomer> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'type':
          case 'status':
          case 'accountingStatus':
          case 'taxClassification':
            props[p] = parseInt(props[p]?.toString() || '0')
            break
          case 'address':
            props[p] = new Address(props[p])
            break
          case 'contacts':
            if (props[p] && (<Array<Contact>>props[p]).length) {
              const objs: Array<Contact> = []
              for (const c of <Array<Contact>>props[p]) {
                objs.push(new Contact(c))
              }

              props[p] = objs
            }
            break
          case 'billGroups':
            if (props[p] && (<Array<BillGroup>>props[p]).length) {
              const objs: Array<BillGroup> = []
              for (const c of <Array<BillGroup>>props[p]) {
                objs.push(new BillGroup(c))
              }

              props[p] = objs
            }
            break
          case 'contracts':
            if (props[p] && (<Array<Contract>>props[p]).length) {
              const objs: Array<Contract> = []
              for (const c of <Array<Contract>>props[p]) {
                objs.push(new Contract(c))
              }

              props[p] = objs
            }
            break
          case 'notes':
            if (props[p] && (<Array<Note>>props[p]).length) {
              const objs: Array<Note> = []
              for (const c of <Array<Note>>props[p]) {
                objs.push(new Note(c))
              }

              props[p] = objs
            }
            break
          case 'invoices':
            if (props[p] && (<Array<CustomerInvoice>>props[p]).length) {
              const objs: Array<CustomerInvoice> = []
              for (const c of <Array<CustomerInvoice>>props[p]) {
                objs.push(new CustomerInvoice(c))
              }

              props[p] = objs
            }
            break
          case 'payments':
            if (props[p] && (<Array<CustomerPayment>>props[p]).length) {
              const objs: Array<CustomerPayment> = []
              for (const c of <Array<CustomerPayment>>props[p]) {
                objs.push(new CustomerPayment(c))
              }

              props[p] = objs
            }
            break
          case 'files':
            if (props[p] && (<Array<File>>props[p]).length) {
              const objs: Array<File> = []
              for (const c of <Array<File>>props[p]) {
                objs.push(new File(c))
              }

              props[p] = objs
            }
            break
          case 'accounts':
            if (props[p] && (<Array<IAccount>>props[p]).length) {
              const objs: Array<IAccount> = []
              for (const c of <Array<IAccount>>props[p]) {
                objs.push(AccountFactory.getAccountFromJson(c))
              }
              props[p] = objs
            }
            break
          case 'accountIds':
            if (props[p] && (<Array<number>>props[p]).length) {
              const ids: Array<number> = []
              for (const i of props[p]! as unknown as Array<string>) {
                ids.push(parseInt(i as string))
              }
              props[p] = ids
            }
            break
          case 'bankaccounts':
            if (props[p] && (<Array<BankAccount>>props[p]).length) {
              const objs: Array<BankAccount> = []
              for (const c of <Array<BankAccount>>props[p]) {
                objs.push(new BankAccount(c))
              }

              props[p] = objs
            }
            break
          case 'taxExemptForms':
            if (props[p] && (<Array<ITaxExemptForm>>props[p]).length) {
              const objs: Array<ITaxExemptForm> = []
              for (const c of <Array<ITaxExemptForm>>props[p]) {
                objs.push(new TaxExemptForm(c))
              }

              props[p] = objs
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get typeLabel (): string {
    return Customer.types[this.type]
  }

  public get statusLabel (): string {
    return Customer.statii[this.status] || Customer.statii[CustomerStatusEnum.NEW]
  }

  public get accountingStatusLabel (): string {
    return Customer.accountingStatii[this.accountingStatus]
  }

  public get icon (): string {
    switch (this.type) {
      case CustomerTypeEnum.LARGE_COMM:
      case CustomerTypeEnum.MED_COMM:
      case CustomerTypeEnum.SMALL_COMM:
        return 'business'
      case CustomerTypeEnum.INDUSTRIAL:
        return 'industrial'
      default:
        return 'residential'
    }
  }

  public iconIs (size: string): boolean {
    if (this.type === CustomerTypeEnum.LARGE_COMM && size === 'large') {
      return true
    }

    if (this.type === CustomerTypeEnum.SMALL_COMM && size === 'small') {
      return true
    }

    return false
  }

  public static convertToBasic (model: Customer, includeArrs: Array<string> = []): Partial<Customer> {
    const basicObj: IStringIndexed = {}

    for (const p in model) {
      if (!Array.isArray(model[p]) || includeArrs.includes(p)) {
        basicObj[p] = model[p]
      }
    }

    return basicObj
  }

  public static fromSearchResult (searchItem: ISearchResult) {
    const contacts: Array<Contact> = []
    const accounts: Array<IAccount> = []
    const taxExemptForms: Array<ITaxExemptForm> = []
    const accountIds: Array<number> = []

    searchItem.contacts.forEach(c => {
      contacts.push(new Contact({
        id: c.id,
        firstName: c.firstName,
        middleName: c.middleName,
        lastName: c.lastName,
        address: {
          type: 0,
          street: c.street,
          city: c.city,
          state: c.state,
          zip: {
            code: c.zip
          }
        }
      }))
    })

    searchItem.serviceAccounts.forEach(a => {
      accountIds.push(a.id)
      accounts.push(AccountFactory.getAccountFromJson({
        commodity: CommodityEnum.E,
        id: a.id,
        accountNumber: a.accountNumber,
        utilityId: a.utilityId,
        status: a.status,
        address: {
          type: 0,
          street: a.street,
          city: a.city,
          state: a.state,
          zip: {
            code: a.zip
          }
        }
      }))
    })

    return new Customer({
      id: searchItem.id,
      p2cId: searchItem.p2cId,
      businessName: searchItem.businessName,
      dba: searchItem.dba,
      type: searchItem.type,
      sic: searchItem.sic,
      status: searchItem.status,
      accountingStatus: searchItem.accountingStatus,
      address: {
        type: 0,
        street: searchItem.street,
        city: searchItem.city,
        state: searchItem.state,
        zip: {
          code: searchItem.zip
        }
      },
      contacts: contacts,
      accounts: accounts,
      accountIds: accountIds,
      taxExemptForms: taxExemptForms
    })
  }
}
