


































































































































































import { Component, Vue, PropSync } from 'vue-property-decorator'
import CustomerGeneralFormFields from '@/modules/customers/components/forms/fields/General.vue'
import AddressFormFields from '@/modules/customers/components/forms/fields/Address.vue'
import CustomerClassificationFormFields from '@/modules/customers/components/forms/fields/Classifications.vue'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import { ISearchResult } from '@/modules/search/models/searchresult'
import Rules from '@/plugins/validations'
import Eventbus from '@/plugins/eventbus'
import CustomerSearchApi from '@/modules/search/api/search'

@Component({
  components: {
    'customer-general-fields': CustomerGeneralFormFields,
    'customer-address-fields': AddressFormFields,
    'customer-classification-fields': CustomerClassificationFormFields
  }
})
export default class CSQCustomerInfoStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  private valRules = Rules
  private noErrors: boolean = true

  private tab = 0

  private searching = false

  private lastCompany = ''
  private companyName = ''
  private lastEmail = ''
  private email = ''

  private selectedList = 1

  private emails: Array<string> = []

  private accountList: Array<ISearchResult> = []
  private emailList: Array<ISearchResult> = []
  private nameList: Array<ISearchResult> = []

  private get stepValid () {
    if (this.tab > 0) {
      if (!this.localItem.customerId) {
        return false
      }
    } else {
      return (this.$refs.form as Vue & { validate: () => boolean }).validate()
    }

    return true
  }

  private async handleSearch () {
    try {
      if (this.searching) {
        return
      }

      if (this.$refs.searchForm && !(this.$refs.searchForm as Vue & { validate: () => boolean }).validate()) {
        return
      }

      this.searching = true

      if (this.lastCompany !== this.companyName && this.companyName?.length) {
        const results: Array<ISearchResult> = await CustomerSearchApi.advancedSearch({
          businessName: {
            term: this.companyName,
            startsWith: false,
            endsWith: false
          }
        })

        Vue.set(this, 'nameList', results)
        this.lastCompany = this.companyName
      }

      if (this.lastEmail !== this.email && this.email?.length) {
        const results: Array<ISearchResult> = await CustomerSearchApi.advancedSearch({
          'contacts.emailAddresses': {
            term: this.email,
            startsWith: true,
            endsWith: true
          }
        })
        Vue.set(this, 'emailList', results)
        this.lastEmail = this.email
      }
    } catch (err) {
      Eventbus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.searching = false
    }
  }

  private handleSelectCustomer (item: ISearchResult) {
    if (this.localItem.customerId === item.id) {
      this.localItem.customerId = 0
    } else {
      this.localItem.customerId = item.id
    }
  }

  private mounted () {
    this.companyName = this.localItem.customerShell?.businessName || ''
    for (const c of this.localItem.customerShell!.contacts) {
      this.emails.push(c.primaryEmail)
    }

    if (this.emails.length) {
      this.email = this.emails[0]
    }

    if (!this.localItem.customerId) {
      CustomerSearchApi.advancedSearch({
        'serviceAccounts.id': { term: this.localItem.data.accountIds }
      })
        .then((results) => {
          Vue.set(this, 'accountList', results)
        })
        .catch()
      this.handleSearch()
    } else {
      this.tab = 1
      CustomerSearchApi.advancedSearch({
        id: { term: this.localItem.customerId }
      })
        .then((results) => {
          Vue.set(this, 'nameList', results)
        })
        .catch()
    }
  }
}
