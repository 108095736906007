import { AppState } from '@/stores/appStore'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { ListsEnum } from '@/modules/shared/enums'
import moment from 'moment'

export interface IFinanceCommission extends IBaseModel {
  commissionId: number;
  customerId: number;
  customerName: string;
  accountId: number;
  accountNumber: string;
  meterNumber: string;
  accountAddress: string;
  accountActive: string;
  utilityId: number;
  utilityName: string;
  state: string;
  customerInvoiceId: number;
  invoiceNumber: string;
  salesId: number;
  salesName: string;
  salesCompany: string;
  paidOn: string;
  usageStart: string;
  usageEnd: string;
  quantity: number;
  rate: number;
  amount: number;
}

export class FinanceCommission extends Base implements IFinanceCommission {
  public commissionId = 0
  public customerId = 0
  public customerName = ''
  public accountId = 0
  public accountNumber = ''
  public meterNumber = ''
  public accountAddress = ''
  public accountActive = 'N'
  public utilityId = 0
  public utilityName = ''
  public state = ''
  public customerInvoiceId = 0
  public invoiceNumber = ''
  public salesId = 0
  public salesName = ''
  public salesCompany = ''
  public paidOn = ''
  public usageStart = ''
  public usageEnd = ''
  public quantity = 0
  public rate = 0.00
  public amount = 0.00

  constructor (props?: Partial<IFinanceCommission>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'commissionId':
          case 'customerId':
          case 'accountId':
          case 'utilityId':
          case 'customerInvoiceId':
          case 'salesId':
            props[p] = parseInt(props[p]!.toString())
            break
          case 'quantity':
          case 'rate':
          case 'amount':
            props[p] = parseFloat(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}

export interface IFinanceCommissionSummary extends IBaseModel {
  key: string;
  salesId: number;
  salesName: string;
  salesCompany: string;
  period: string;
  quantity: number;
  amount: number;
  commissionIds: Array<number>;
}

export class FinanceCommissionSummary extends Base implements IFinanceCommissionSummary {
  public key = ''
  public salesId = 0
  public salesName = ''
  public salesCompany = ''
  public period = ''
  public quantity = 0
  public amount = 0.00
  public commissionIds: Array<number> = []

  constructor (props?: Partial<IFinanceCommissionSummary>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'salesId':
            props[p] = parseInt(props[p]!.toString())
            break
          case 'quantity':
          case 'amount':
            props[p] = parseFloat(props[p]!.toString())
            break
          case 'commissionIds':
            if (props[p]) {
              const ids = []
              for (const i of props[p]!) {
                ids.push(parseInt(i.toString()))
              }
              props[p] = ids
            }
            break
          default:
        }
        props.key = FinanceCommissionSummary.getKey(props.salesId!, props.period!)
      }

      Object.assign(this, props)
    }
  }

  public static getKey (salesId: number, period: string) : string {
    return salesId + '|' + period
  }

  public static fromList (list: Array<FinanceCommission>) : Array<FinanceCommissionSummary> {
    const summaries: Array<FinanceCommissionSummary> = []

    for (const c of list) {
      const period = moment(c.paidOn).format('YYYY-MM-01')
      const key = FinanceCommissionSummary.getKey(c.salesId, period)
      const existing = summaries.find(s => s.key === key)
      if (existing) {
        existing.commissionIds.push(c.commissionId)
        existing.amount += c.amount
        existing.quantity += c.quantity
      } else {
        summaries.push(new FinanceCommissionSummary({
          salesId: c.salesId,
          salesName: c.salesName,
          salesCompany: c.salesCompany,
          period: period,
          quantity: c.quantity,
          amount: c.amount,
          commissionIds: [c.commissionId]
        }))
      }
    }

    return summaries
  }
}
