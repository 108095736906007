






































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { RatePlan } from '@/modules/customers/models/rateplan'
import { IStringDictionary } from '@/modules/shared/types'
import { TransactionRequestStatusEnum } from '@/modules/shared/enums'

@Component({
  components: {}
})
export default class EnrollAccountOptions extends Vue {
  @PropSync('enrollmentRequest', { required: true })
  private localReq!: TransactionRequest

  @Prop({ required: true, default: false })
  private saving!: boolean

  @Prop({ required: true })
  private detailsLoaded!: boolean

  @Prop({ required: true })
  private availableRatePlans!: Array<RatePlan>

  @Prop({ required: true })
  private enrollmentOptions!: IStringDictionary<Array<string> | string>

  @Prop({ default: false })
  private isP2c!: boolean

  private valRules: Rules = Rules

  private scheduleMenu: boolean = false

  private asIntervalOptions = [
    { value: 'N', text: 'No' },
    { value: 'Y', text: 'Yes' }
  ]

  private secondaryHuOptions = [
    { value: 'N', text: 'No' },
    { value: 'S', text: 'Summary' },
    { value: 'I', text: 'Interval' }
  ]

  protected get ratePlans () {
    return this.availableRatePlans.filter(r => r.serviceAccountIds.indexOf(this.localReq.accountId) !== -1)
      .map(r => ({ value: r.description, text: r.description + '  ' + r.descText }))
  }

  protected get delayable () {
    return !this.isP2c
  }

  protected set secondaryHuOption (selected: string) {
    this.localReq.details.transactionConfig.secondaryRequestHu = selected === 'N' ? 'N' : 'Y'
    this.localReq.details.transactionConfig.secondaryHuAsInterval = selected === 'I' ? 'Y' : 'N'
  }

  protected get secondaryHuOption () {
    if (!Object.prototype.hasOwnProperty.call(this.localReq.details.transactionConfig, 'secondaryRequestHu')) {
      return 'N'
    }
    if (this.localReq.details.transactionConfig.secondaryRequestHu === 'N') {
      return 'N'
    }
    if (this.localReq.details.transactionConfig.secondaryHuAsInterval === 'Y') {
      return 'I'
    }
    return 'S'
  }

  protected get showForm () {
    return this.status === 'Unsent' || this.status === 'Error'
  }

  protected get status () {
    if (this.saving) {
      return 'Saving'
    }
    if (this.localReq.requestTransactionId) {
      return 'Success'
    }
    if (this.localReq.hasError) {
      return 'Error'
    }
    if (this.localReq.status === TransactionRequestStatusEnum.SCHEDULED) {
      return 'Queued'
    }
    return 'Unsent'
  }

  protected allowedDates (val: string) {
    return this.enrollmentOptions?.skip.indexOf(val) === -1 &&
      val >= this.enrollmentOptions.earliest
  }

  public mounted () {
  }
}
