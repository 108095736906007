
































































































































































import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import Customer from '@/modules/customers/models/customer'
import EventBus from '@/plugins/eventbus'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { InvoicesApi } from '@/modules/customers/api/invoices'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { AppState } from '@/stores/appStore'
import { BillGroupDeliveryMethods } from '@/modules/shared/lists'
import { BillGroupDeliveryMethodEnum, CustomerInvoiceStatusEnum } from '@/modules/shared/enums'
import FilesApi from '@/modules/customers/api/files'
import { InvoiceLineItem } from '../../../models/account/invoice/lineitem'
import { AccountInvoice } from '../../../models/account/invoice'
import { CommodityAccount } from '../../../../shared/models/account/base'
import { IAccount } from '../../../../shared/models/interfaces/account'
import { MeterRead } from '@/modules/shared/models/utility/meterread'

@Component({
  components: {
  }
})
export default class SummaryInvoiceList extends Mixins(DownloadFile) {
  @Prop({ required: false })
  private targetInvoiceId!: number

  @Prop({ required: true })
  private customer!: Customer

  @Prop({ required: true })
  private invoices!: Array<CustomerInvoice>

  @Prop({ required: true })
  private totalInvoices!: number

  @Prop({ default: true })
  private showVoid!: boolean

  @Prop({ default: true })
  private showPay!: boolean

  @Prop({ default: true })
  private showGenerateCredits!: boolean

  @Prop({ default: false })
  private loading!: boolean

  private errorMessage = ''

  private sortBy = 'dueDate'
  private sortDesc = true
  private itemsPerPage = 5
  private page = 1

  private historyMenu: INumericDictionary<boolean> = {}
  private overflowMenu: INumericDictionary<boolean> = {}

  private processingList: IStringDictionary <string> = {};

  private headers: Array<any> = [
    { text: 'Invoice #', align: 'left', sortable: false, value: 'invoiceNumber' },
    { text: 'Date', align: 'center', sortable: false, value: 'invoiceDate' },
    { text: 'Due Date', align: 'center', sortable: false, value: 'dueDate' },
    { text: 'Total', align: 'right', sortable: false, value: 'grandTotal' },
    { text: 'Open Balance', align: 'right', sortable: false, value: 'openBalance' },
    { text: 'Age', align: 'center', sortable: false, value: 'age' },
    { text: 'Canceled', align: 'center', sortable: false, value: 'canceled' },
    { text: 'Rebills', align: 'center', sortable: false, value: 'rebills' },
    { text: '', align: 'left', sortable: false, value: 'actions' }
  ]

  public rowSelected (item: CustomerInvoice) {
    let rowClass = ''
    if (item.id === this.targetInvoiceId) {
      rowClass = 'red lighten-5 primary--text'
    }

    if (item.status === CustomerInvoiceStatusEnum.STATUS_PENDING_APPROVAL) {
      rowClass += 'info lighten-3'
    }

    if (CustomerState.filteredAccounts.length && item.accountIds.filter(id => CustomerState.filteredAccounts.filter(a => a.id === id).length).length) {
      rowClass += ' font-weight-bold'
    }

    if (item.isVoided) {
      rowClass += ' text-decoration-line-through'
    }

    return rowClass
  }

  public handleRowClick (item: CustomerInvoice) {
    this.$emit('invoice:click', item)
  }

  private get canVoidInvoice () {
    return AuthState.user.isAllowed('CUSTOMER.INVOICES.SUMMARY.VOID')
  }

  private get canAddPayment () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.CREATE')
  }

  private get canGenerateCredits () {
    return AuthState.user.isAllowed('CUSTOMER.INVOICES.GENERATECREDITS')
  }

  public get paginatedItems () {
    if (!this.invoices) {
      return []
    }
    const pos = (this.page - 1) * this.itemsPerPage
    return this.invoices.slice(pos, pos + this.itemsPerPage)
  }

  private getAppUserName (userId: number): string {
    return AppState.cisUsers[userId].detailName
  }

  private getBillDeliveryIcon (method: keyof BillGroupDeliveryMethods) {
    switch (method) {
      case BillGroupDeliveryMethodEnum.EMAIL:
        return 'envelope'
      case BillGroupDeliveryMethodEnum.FAX:
        return 'fax'
      case BillGroupDeliveryMethodEnum.MAIL:
        return 'address'
      default:
        return 'help'
    }
  }

  @Watch('page', { immediate: true })
  private async handlePageUpdate (newPage: number, oldPage: number) {
    if (newPage > oldPage) {
      if (newPage * this.itemsPerPage > this.invoices.length) {
        await CustomerState.LoadInvoices({ loadMore: true })
      }
    }
  }

  private handleSortUpdate () {
  }

  private handleOptionsUpdate () {
  }

  private formatSentTo (sentTo: IStringIndexed|string) {
    if (typeof sentTo === 'string') {
      return sentTo
    }
    const formattedList = []
    for (const name in sentTo) {
      formattedList.push(name + ' <' + sentTo[name] + '>')
    }
    return formattedList.join(', ')
  }

  private getCanceled (invoice: CustomerInvoice) {
    return invoice.invoices.filter((aInv: AccountInvoice) => (CustomerState.customer.accounts.find((a: IAccount) => a.id === aInv.accountId) ?? new CommodityAccount())
      .canceledReads.find((c: MeterRead) => c.id === aInv.parentLineItem?.usageId) !== undefined
    ).length > 0
      ? 'Y'
      : ''
  }

  private getRebills (invoice: CustomerInvoice) {
    return invoice.invoices.filter((aInv: AccountInvoice) => (CustomerState.customer.accounts.find((a: IAccount) => a.id === aInv.accountId) ?? new CommodityAccount())
      .rebillReads.find((c: MeterRead) => c.id === aInv.parentLineItem?.usageId) !== undefined
    ).length > 0
      ? 'Y'
      : ''
  }

  public handleVoidInvoice (invoice: CustomerInvoice) {
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Void',
        message: '<b>Are you sure you want to void this invoice?</b><br/><br/>This will reverse all payment applications and charges.',
        onConfirm: () => this.voidInvoice(invoice)
      }
    })
  }

  public async voidInvoice (invoice: CustomerInvoice) {
    try {
      Vue.set(this.processingList, invoice.id.toString(), 'void')
      await InvoicesApi.void(invoice)
      this.overflowMenu[invoice.id] = false
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id.toString())
    }
  }

  public async handleDownloadPdf (invoice: CustomerInvoice) {
    try {
      Vue.set(this.processingList, invoice.id.toString(), 'download')
      const file = await InvoicesApi.getPdf(invoice)
      this.downloadFile(file.name, file.binaryContents, 'application/pdf')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id.toString())
    }
  }

  private async downloadFileSnapshot (invoice: CustomerInvoice, fileId: number) {
    const key = invoice.id + '|' + fileId
    try {
      Vue.set(this.processingList, key, 'download')
      const newFile = await FilesApi.load(invoice.customerId, fileId)
      this.downloadFile(newFile.name, newFile.binaryContents, 'application/pdf')
    } catch (err) {
      this.$emit('error', err.message)
    } finally {
      Vue.delete(this.processingList, key)
    }
  }

  public handleAddPayment (invoice: CustomerInvoice) {
    EventBus.$emit('open-cust-dialog', { component: 'add-customer-payment', componentProps: { customer: this.customer, targetInvoiceId: invoice.id } })
  }

  public async handleGenerateCredits (invoice: CustomerInvoice) {
    try {
      Vue.set(this.processingList, invoice.id.toString(), 'generateCredits')
      await CustomerState.GenerateCredits(invoice)
      this.overflowMenu[invoice.id] = false
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id.toString())
    }
  }

  public async mounted () {
    if (this.targetInvoiceId) {
      const idx = this.invoices.findIndex(i => i.id === this.targetInvoiceId)
      if (idx > -1) {
        this.page = Math.floor((idx + 1) / this.itemsPerPage) + 1
      }
    }
  }
}
