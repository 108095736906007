

















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import FilesApi from '@/modules/customers/api/files'
import EventBus from '@/plugins/eventbus'
import { ListsEnum } from '@/modules/shared/enums'
import { File } from '@/modules/shared/models/file'
import FileList from '@/modules/customers/components/blocks/FileList.vue'
import { Contract } from '@/modules/customers/models/customer/contract'

@Component({
  components: {
    'file-list': FileList
  }
})
export default class ContractFiles extends Vue {
  @Prop({ required: true })
  private contractId!: number

  private errorMessage = ''

  private files: Array<File> = []
  private loading = true

  private get contract () {
    if (!this.contractId) {
      return new Contract({ customerId: CustomerState.customer.id })
    }
    const contract = CustomerState.customer.contracts.find(a => {
      return a.id === this.contractId
    })

    if (!contract) {
      return new Contract({ customerId: CustomerState.customer.id })
    }
    return contract
  }

  private handleAttachFile () {
    EventBus.$emit('open-cust-dialog', { component: 'upload-customer-file', componentProps: { customerId: this.contract.customerId, uploadCallback: this.handleFileUpload }, dialogProps: { width: 'auto' } })
  }

  private async handleFileUpload (customerId: number, item: { reading: boolean; completed: number; file: File }) {
    const newFile = await FilesApi.uploadToContract(customerId, this.contractId, item.file, (e: ProgressEvent) => {
      item.reading = true
      item.completed = Math.round((e.loaded * 100) / e.total)
    })

    if (this.contract.customerId === CustomerState.customer.id) {
      CustomerState.AddFile(newFile)
    }
    this.files.push(newFile)
  }

  @Watch('contractId', { immediate: true })
  private async handleContractUpdate () {
    try {
      this.loading = true
      Vue.set(this, 'files', await FilesApi.loadByContract(this.contract))
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private async mounted () {
  }
}
