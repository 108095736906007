import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { Method, AxiosResponse } from 'axios'
import { IStringDictionary, IStringIndexed } from '../types'

export default class P2CApi {
  public static async loadStatements (from: string, to: string): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-reporting-statements' + filtersToQs({ from, to }))

      if (response.status === 200 && response.data.statements) {
        const statements: Array<IStringIndexed> = []

        response.data.statements.forEach((i: IStringIndexed) => {
          statements.push(i)
        })

        return statements
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
