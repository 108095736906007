


























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { AccountInvoice } from '@/modules/customers/models/account/invoice'
import AccountInvoiceLineItems from './LineItems.vue'
import AccountInvoicePayments from './Payments.vue'
import { InvoiceLineItem } from '@/modules/customers/models/account/invoice/lineitem'
import LineItemChargeDetails from './ChargeDetails.vue'

@Component({
  components: {
    'invoice-line-items': AccountInvoiceLineItems,
    'invoice-payments': AccountInvoicePayments,
    'charge-details': LineItemChargeDetails
  }
})
export default class AccountInvoiceOverview extends Vue {
  @Prop({ required: true })
  private accountInvoice!: AccountInvoice

  private tabs = 0

  private selectedItem: InvoiceLineItem|null = null

  private handleSelectRow (item: InvoiceLineItem) {
    this.selectedItem = item
  }

  private get canViewPaymentApplications () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.APPLICATIONS.VIEW')
  }

  @Watch('accountInvoice.id')
  private handleInvoiceChange () {
    this.selectedItem = null
  }
}
