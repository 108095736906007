import { Base, IBaseModel } from '@/modules/shared/models/base'
import { BankAccountTypes } from '@/modules/shared/lists'

export interface IBankAccountLog extends IBaseModel {
  id: number;
  accountId: number;
  businessNeed: string;
}

export class BankAccountLog extends Base implements IBankAccountLog {
  public static accountTypes = new BankAccountTypes()

  public id = 0
  public accountId = 0
  public businessNeed = ''

  constructor (props?: Partial<IBankAccountLog>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p as string) {
          case 'id':
          case 'customerId':
            props[p] = parseInt(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
