import { Base } from './../base'
import { IStringIndexed } from '../../types'

export interface IMeterRead extends IStringIndexed {
  id: number;
  utilityId: number;
  cycleCode: string;
  period: string;
  readDate: string;
}

export class MeterRead extends Base implements IMeterRead {
  public id = 0
  public utilityId = 0
  public cycleCode = ''
  public period = ''
  public readDate = ''

  constructor (props?: Partial<IMeterRead>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'utilityId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
