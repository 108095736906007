










































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import { AppState } from '@/stores/appStore'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'
import BillingManagementApi from '../../api/billing'
import { RateClass } from '../../../shared/models/utility/rateclass'
import { Utility } from '../../../shared/models/utility'
import XLSX from 'xlsx'
import EventBus from '@/plugins/eventbus'

@Component({
  components: {}
})
export default class ProfileDownload extends Mixins(DownloadFile) {
  private errorMessage = ''

  private fetching = false
  private loading = false

  private selectedUtility: number|null = null
  private selectedRateClass: string|null = null

  private from = moment().subtract(13, 'months').format('YYYY-MM-DD')
  private to = moment().format('YYYY-MM-DD')

  private menu = {
    from: false,
    to: false
  }

  private async handleSubmit () {
    this.loading = true

    if (this.selectedUtility === null || this.selectedRateClass === null) {
      return
    }

    try {
      const profile = await BillingManagementApi.fetchUtilityProfile(this.selectedUtility, this.selectedRateClass, this.from, this.to)

      const workbook = XLSX.utils.book_new()
      const filename = 'profile-download-' + this.selectedRateClass
      const dataSheet = XLSX.utils.json_to_sheet(profile)
      XLSX.utils.book_append_sheet(workbook, dataSheet, filename.replace('/', ''))

      XLSX.writeFile(workbook, filename + '.xlsx')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err,
        timeout: 5000
      })
    } finally {
      this.loading = false
    }
  }

  public get utilityList (): Array<{text: string; value: any}> {
    return Object.values(AppState.utilities).map((u: Utility) => ({
      text: u.displayName,
      value: u.id
    })).sort((a, b) => a.text.localeCompare(b.text))
  }

  public get rateClassList (): Array<{text: string; value: any}> {
    if (!this.selectedUtility) {
      return []
    }
    const u: Utility = AppState.utilities[this.selectedUtility]
    return Object.values(u.rateClasses).map((r: RateClass) => ({
      text: r.rateClass + ' -' + r.description,
      value: r.rateClass
    })).sort((a, b) => a.text.localeCompare(b.text))
  }

  private beforeMount () {

  }
}
