var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"color":"inherit","background-color":"inherit"},attrs:{"headers":_vm.lineItemHeaders,"items":Object.values(_vm.invoice.lineItems),"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"loading-text":"Loading...please wait","item-class":_vm.rowSelected,"disable-default-footer":""},on:{"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"item.meterNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMeterNumber(item))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US').format(item.quantity))+" ")]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.rate).toFixed(6))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.total))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }