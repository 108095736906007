import { AppState } from '@/stores/appStore'
import { IAddress, Address } from '@/modules/shared/models/address'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { ListsEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'
import PhoneNumber from '@/filters/PhoneNumber'

export interface ICustomerContact extends IBaseModel {
  id: number;
  customerId: number;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  cellPhone: string;
  workPhone: string;
  workExtension: string;
  otherPhone: string;
  otherExtension: string;
  fax: string;
  primaryEmail: string;
  secondaryEmail: string;
  address: IAddress;
  type: number;
}

export class Contact extends Base implements ICustomerContact {
  public id = 0
  public customerId = 0
  public title = ''
  public firstName = ''
  public middleName = ''
  public lastName = ''
  public cellPhone = ''
  public workPhone = ''
  public workExtension = ''
  public otherPhone = ''
  public fax = ''
  public otherExtension = ''
  public primaryEmail = ''
  public secondaryEmail = ''
  public address = new Address()
  public type = 175

  constructor (props?: Partial<ICustomerContact>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'address':
            props[p] = new Address(props[p])
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get fullName () {
    let fullName = this.firstName
    if (fullName.length) {
      fullName += ' '
    }

    fullName += this.lastName

    if (!fullName.trim().length) {
      fullName = '<Missing Name>'
    }

    return fullName
  }

  public get typeLabel (): string {
    return AppState.listsByName[ListsEnum.CONTACT_TYPES]![this.type]
  }

  public get emailAddresses () {
    const emails: IStringIndexed = {}

    if (this.primaryEmail?.length) {
      emails.primary = this.primaryEmail
    }

    if (this.secondaryEmail?.length) {
      emails.secondary = this.secondaryEmail
    }

    return emails
  }

  public get phoneNumbers () {
    const phones: IStringIndexed = {}
    for (const p of ['cell', 'work', 'other']) {
      const n = p + 'Phone'
      const e = p + 'Extension'

      if (this[n]?.length) {
        phones[p] = { number: PhoneNumber(this[n]) }
      }
      if (this[e]?.length) {
        phones[p].extension = this[e]
      }
    }
    return phones
  }

  public get fullPhoneNumbers () {
    const phones: IStringIndexed = {}
    for (const p of ['cell', 'work', 'other']) {
      const n = p + 'Phone'
      const e = p + 'Extension'

      if (this[n]?.length) {
        phones[p] = PhoneNumber(this[n]) + (this[e] && this[e].length ? ' x' + this[e] : '')
      }
    }
    return phones
  }

  public get location () {
    if (!this.hasAddress()) {
      return ''
    }
    let location = this.city ?? ''
    if (location.length) {
      location += ' '
    }
    location += this.state ?? ''

    return location
  }

  public hasAddress () {
    if (!this.address) {
      return false
    }

    if (
      (this.address.street && this.address.street.length) ||
      // @ts-ignore
      (typeof this.address.street2 !== 'undefined' && this.address.street2 && this.address.street2.length) ||
      (this.address.city && this.address.city.length) ||
      // @ts-ignore
      (this.address.state && this.address.state.length) ||
      (this.address.zip && this.address.zip.code && this.address.zip.code.length)
    ) {
      return true
    }

    return false
  }
}
