var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(!_vm.itemId)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"dense":this.$vuetify.breakpoint.mdAndDown,"calculate-widths":true,"item-key":"id","loading":_vm.loading,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"search":_vm.listSearch,"sort-by":"name","show-select":"","selectable-key":"hasDeliveryDestination"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","short":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-right justify-end",attrs:{"cols":"4","offset":"4"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"$vuetify.icons.search","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.listSearch),callback:function ($$v) {_vm.listSearch=$$v},expression:"listSearch"}})],1),_c('v-col',{staticClass:"d-flex align-right justify-end",attrs:{"cols":"2","offset":"2"}},[_c('v-btn',{attrs:{"disabled":_vm.sending || !_vm.selectedInvoices.length,"loading":_vm.sending,"title":"Send","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleSendInvoices.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.fulfillmentqueue")]),_vm._v(" Send ")],1),_c('v-btn',{staticClass:"ml-6",attrs:{"disabled":_vm.sending || !_vm.selectedInvoices.length,"loading":_vm.sending,"title":"Mark Sent","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleMarkSent.apply(null, arguments)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.delete")]),_vm._v(" Mark Sent ")],1)],1)],1)],1)]},proxy:true},{key:"item.recipients",fn:function(ref){
var item = ref.item;
return [(item.billGroup.billDeliveryMethod === 1)?_c('div',[_c('div',{staticClass:"pl-4"},[_vm._v(_vm._s(item.billGroup.emailTo))]),(item.billGroup.ccTo)?_c('div',{staticClass:"pl-4"},[_vm._v("(CC:) "+_vm._s(item.billGroup.ccTo))]):_vm._e(),(item.billGroup.bccTo)?_c('div',{staticClass:"pl-4"},[_vm._v("(BCC:) "+_vm._s(item.billGroup.bccTo))]):_vm._e()]):(item.billGroup.billDeliveryMethod === 2)?_c('div',[_vm._v(_vm._s(item.billGroup.fax))]):(item.billGroup.billDeliveryMethod === 4)?_c('div',[_vm._v(" "+_vm._s(item.billGroup.contact.address.street)+", "),(item.billGroup.contact.address.street2)?[_vm._v(_vm._s(item.billGroup.contact.address.street2)+",")]:_vm._e(),_vm._v(" "+_vm._s(item.billGroup.contact.address.city)+", "+_vm._s(item.billGroup.contact.address.state)+", "+_vm._s(item.billGroup.contact.address.zip.code)+" ")],2):_vm._e()]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.invoiceDate,'MM/DD/YYYY'))+" ")]}},{key:"item.grandTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(parseFloat(item.grandTotal).toFixed(2))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"title":"Preview PDF - NO PAYMENTS","disabled":_vm.processing[item.id] !== undefined,"loading":_vm.processing[item.id] && _vm.processing[item.id] === 'download',"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDownloadPdf(item)}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("$vuetify.icons.pdf")])],1)]}},{key:"no-data",fn:function(){return [(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error","outlined":""}},[_c('p',[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()]},proxy:true}],null,false,1092977773),model:{value:(_vm.selectedInvoices),callback:function ($$v) {_vm.selectedInvoices=$$v},expression:"selectedInvoices"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }