















import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import Rules from '@/plugins/validations'
import { AppState } from '@/stores/appStore'
import { IStringIndexed } from '@/modules/shared/types'

@Component
export default class DropFields extends Vue {
  @PropSync('request', { required: true })
  public localRequest!: TransactionRequest

  @Prop({ required: true })
  public account!: IAccount

  private valRules = Rules

  private dropReasons = [
    { value: 'B38', text: 'B38: Customer Return to Standard Service' },
    { value: 'A13', text: 'A13: Other' }
  ]

  private get filteredDropReasons () {
    const utilCodes: IStringIndexed|null = AppState.utilities[this.account.utilityId].getSetting('drop_reason_codes') as IStringIndexed|null

    if (!utilCodes) {
      return this.dropReasons
    }

    if (utilCodes.list) {
      return this.dropReasons.filter(c => utilCodes.list.indexOf(c.value) !== -1)
    }

    if (utilCodes.exclude) {
      return this.dropReasons.filter(c => utilCodes.exclude.indexOf(c.value) === -1)
    }
    return this.dropReasons
  }

  private beforeMount () {

  }
}
