

































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import MenuItem from '@/modules/shared/menuitem'
import SearchWidget from '@/modules/search/pages/SearchWidget.vue'
import ServiceAccountDetail from '../modules/customers/components/blocks/ServiceAccountDetail.vue'
import EditServiceAccountForm from '@/modules/customers/components/forms/ServiceAccount.vue'
import AccountImportDialog from '@/modules/customers/components/forms/serviceaccount/Import.vue'
import ImpersonateForm from '@/modules/auth/components/forms/Impersonate.vue'
import CardList from '@/modules/shared/components/CardList.vue'
import EventBus from '@/plugins/eventbus'
import { IStringIndexed, IDialogConfig } from '../modules/shared/types'
import ConfirmDialog from '@/modules/shared/components/ConfirmDialog.vue'

interface ISnackConfig {
  show?: boolean,
  message?: string,
  color?: string,
  timeout?: number,
  snackProps?: IStringIndexed,
  action?: Function,
  actionText?: string
}

@Component({
  name: 'AppLayout',
  components: {
    'search-widget': SearchWidget,
    'service-account-detail': ServiceAccountDetail,
    'edit-service-account': EditServiceAccountForm,
    'import-service-accounts': AccountImportDialog,
    impersonate: ImpersonateForm,
    'card-list': CardList,
    'confirm-dialog': ConfirmDialog
  }
})
export default class extends Vue {
  private dialog = false
  private dialogComponent = ''
  private dialogProps: IStringIndexed = {}
  private componentProps: IStringIndexed = {}
  private dialogWidth = 'unset'
  private drawer = false
  private orientation = 'landscape'

  private defaultSnack: ISnackConfig = {
    color: 'red',
    timeout: 5000
  }

  private snack: ISnackConfig = {
    show: false,
    message: '',
    color: 'red',
    timeout: 5000
  }

  private snackQueue: Array<ISnackConfig> = []
  private snackProps: IStringIndexed = {}
  private snackAction: Function|null = null
  private snackActionText: string = ''

  public getRecentlyViewed () {
    return AppState.recentlyViewed
  }

  public get useTabNav () {
    return AppState.showTabNav
  }

  public get menuItems () {
    return AppState.menuItems
  }

  public get hasRecent () {
    return AppState.recentlyViewed.length
  }

  public get directionByOrientation () {
    if (this.$vuetify.breakpoint.smAndDown && this.orientation === 'landscape') {
      return 'left'
    } else {
      return 'top'
    }
  }

  private handleMenu (menuItem: MenuItem) {
    if (typeof menuItem.click !== 'undefined') {
      menuItem.click()
    } else if (typeof menuItem.link !== 'undefined') {
      this.$router.push(menuItem.link)
    }
  }

  private handleDialog (dialogConfig: IDialogConfig | string) {
    this.dialogWidth = 'unset'
    if (typeof dialogConfig === 'string') {
      this.dialogComponent = dialogConfig
    } else {
      this.dialogComponent = dialogConfig.component
      this.componentProps = dialogConfig.componentProps || {}
      this.dialogProps = dialogConfig.dialogProps || {}
    }

    this.dialog = true

    Vue.nextTick()
      .then(() => {
        // Can't get this working this.$vuetify.goTo(0, { container: '#appDialog' })
        const dialogElem = document.getElementById('appDialog')
        if (dialogElem) {
          dialogElem.scrollIntoView()
        }
      })
  }

  private handleSnack (snackConfig: ISnackConfig) {
    if (this.snack.show) {
      this.snackQueue.push({
        message: snackConfig.message,
        color: snackConfig.color || this.defaultSnack.color,
        timeout: snackConfig.timeout || this.defaultSnack.timeout,
        snackProps: snackConfig.snackProps || {},
        action: snackConfig.action,
        actionText: snackConfig.actionText
      })
    } else {
      this.snack.message = snackConfig.message
      this.snack.color = snackConfig.color || this.defaultSnack.color
      this.snack.timeout = snackConfig.timeout || this.defaultSnack.timeout
      this.snackProps = snackConfig.snackProps || {}
      this.snackAction = snackConfig.action || null
      this.snackActionText = snackConfig.actionText || ''
      this.snack.show = true
    }
  }

  @Watch('snack.show')
  private handleSnackQueue (show: boolean, oldShow: boolean) {
    if (show === true) {
      return
    }

    if (this.snackQueue.length > 0) {
      setTimeout(() => {
        const item = this.snackQueue.shift()
        this.snack.message = item!.message
        this.snack.color = item!.color
        this.snack.timeout = item!.timeout
        this.snackProps = item!.snackProps || {}
        this.snackAction = item!.action || null
        this.snackActionText = item!.actionText || ''
        this.snack.show = true
      }, 100)
    }
  }

  private handleCloseDialog () {
    this.dialog = false
    this.dialogComponent = ''
    if ((this.$refs.dialogComponent as IStringIndexed)?.handleReset) {
      (this.$refs.dialogComponent as IStringIndexed).handleReset()
    }
  }

  private updateOrientation () {
    this.orientation = screen.orientation.type.startsWith('landscape') ? 'landscape' : 'portrait'
  }

  public created () {
    this.updateOrientation()
    window.addEventListener('resize', this.updateOrientation)

    EventBus.$on('open-app-dialog', this.handleDialog)
    EventBus.$on('app-snack', this.handleSnack)
  }

  public beforeDestroy () {
    window.removeEventListener('resize', this.updateOrientation)
  }
}
