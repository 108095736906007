










import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import FilesApi from '@/modules/customers/api/files'
import EventBus from '@/plugins/eventbus'
import NameBar from '../../components/blocks/NameBar.vue'
import { ListsEnum } from '../../../shared/enums'
import Customer from '../../models/customer'
import { File } from '../../../shared/models/file'
import FileList from '@/modules/customers/components/blocks/FileList.vue'

@Component({
  components: {
    'file-list': FileList
  }
})
export default class Files extends Vue {
  private errorMessage = ''

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  private mounted () {
    if (CustomerState.loading.files === null) {
      CustomerState.LoadFiles()
    }
    CustomerState.LoadFiles(true)
  }
}
