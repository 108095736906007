import { Route } from 'vue-router'

const rootNav = {
  key: 'root',
  width: 225
}

export default [
  {
    name: 'salesroot',
    path: '/sales',
    components: {
      SideNav: () => import(/* webpackChunkName: "salesusers" */ '../components/sidenavs/Root.vue'),
      default: () => import(/* webpackChunkName: "salesusers" */ '../pages/SalesRoot.vue')
    },
    title: 'Sales',
    meta: {
      requiresAuth: 'SALES.VIEW'
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'salesusersmanage',
    path: '/sales/users/manage',
    components: {
      SideNav: () => import(/* webpackChunkName: "salesusers" */ '../components/sidenavs/Root.vue'),
      default: () => import(/* webpackChunkName: "salesusers" */ '../pages/SalesUsers.vue')
    },
    title: 'Manage Sales Users',
    meta: {
      requiresAuth: 'SALES.USERS.VIEW',
      navs: {
        root: {
          icon: 'billing'
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'viewsalesuser',
    path: '/sales/user/:id(\\d+)',
    components: {
      default: () => import(/* webpackChunkName: "salesusers" */ '../pages/SalesUser.vue')
    },
    title: 'Customer Fulfillment - Document',
    meta: {
      requiresAuth: 'SALES.USERS.VIEW',
      navs: {
      }
    },
    props: {
      default: (route: Route) => ({
        id: parseInt(route.params.id),
        user: route.params.user
      })
    }
  }
]
