import { Base, IBaseModel } from '@/modules/shared/models/base'
import { BankAccountTypeEnum, ContactTypeEnum, ContractSubmissionStatusEnum, PaymentMethodEnum } from '@/modules/shared/enums'
import { ContractSubmissionStatuses } from '@/modules/shared/lists'
import { IStringIndexed } from '@/modules/shared/types'
import Customer from '@/modules/customers/models/customer'
import { Contact } from '@/modules/customers/models/customer/contact'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { Contract } from '@/modules/customers/models/customer/contract'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'

export interface IContractSubmission extends IBaseModel {
  id: number;
  crmOppId: number;
  crmQuoteId: number;
  customerId: number;
  contractId: number;
  userId: number;
  submittedAt: string;
  status: keyof ContractSubmissionStatuses;
  step: number;
  data: {
    businessName: string,
    address: IStringIndexed,
    salesId: string,
    salesPerson: string,
    accountIds: Array<number>,
    crmFileIds: Array<number>,
    notes: Array<IStringIndexed>,
    customer: IStringIndexed,
    contacts: Array<IStringIndexed>,
    esignId?: string
  };
  customerShell: Customer|null;
  fileIds: Array<number>;
  notes: Array<IStringIndexed>;
}

export class ContractSubmission extends Base implements IContractSubmission {
  public static statuses = new ContractSubmissionStatuses()

  public id = 0
  public userId = 0
  public crmOppId = 0
  public crmQuoteId = 0
  public customerId = 0
  public contractId = 0
  public submittedAt = ''
  public status = ContractSubmissionStatusEnum.NEW
  public step = 0
  public notes: Array<IStringIndexed> = []
  public fileIds: Array<number> = []

  public data: {
    businessName: string,
    address: IStringIndexed,
    salesId: string,
    salesPerson: string,
    accountIds: Array<number>,
    crmFileIds: Array<number>,
    notes: Array<IStringIndexed>,
    customer: IStringIndexed,
    contacts: Array<IStringIndexed>,
    esignId?: string,
    startMonth: string,
    creationShell?: IStringIndexed
  } = {
    businessName: '',
    address: {},
    salesId: '',
    salesPerson: '',
    accountIds: [],
    crmFileIds: [],
    notes: [],
    customer: {},
    contacts: [],
    startMonth: ''
  }

  public customerShell: Customer|null = null

  constructor (props?: Partial<IContractSubmission>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'crmOppId':
          case 'crmQuoteId':
          case 'customerId':
          case 'contractId':
          case 'userId':
          case 'status':
          case 'step':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'customerShell':
            if (Array.isArray(props[p])) {
              props[p] = this.createShell(props!.data!)
            } else {
              props[p] = new Customer(props[p]!)
            }
            break
          default:
        }
      }

      Object.assign(this, props)
      if (!this.customerShell) {
        this.customerShell = new Customer()
      }
    }
  }

  private createShell (initialData: IStringIndexed) : Customer {
    const contacts: Array<Contact> = []
    for (const cdata of initialData.contacts) {
      if (cdata.type === ContactTypeEnum.PRIMARY || cdata.type === ContactTypeEnum.BILLING) {
        contacts.push(new Contact(cdata))
      }
    }

    const billingContact = contacts.find(c => c.type === ContactTypeEnum.BILLING) || contacts.find(c => c.type === ContactTypeEnum.PRIMARY)

    const bg = new BillGroup({
      id: -1,
      label: 'Default Bill Group',
      paymentMethodId: initialData.paymentMethod,
      billDeliveryMethod: initialData.deliveryMethod,
      contactId: billingContact?.id || 0,
      emailTo: billingContact?.primaryEmail,
      fax: billingContact?.fax
    })

    const contract = new Contract({
      id: -1,
      dateSigned: initialData.signedDate,
      startMonth: initialData.startMonth,
      productId: initialData.productId ?? 1
    })

    if (initialData.commissions) {
      for (const i in initialData.commissions as Array<IStringIndexed>) {
        contract.commissions[i].salesId = initialData.commissions[i].salesId
        contract.commissions[i].rate = initialData.commissions[i].rate
        contract.commissions[i].effectiveFrom = initialData.startMonth
      }
    }

    if (initialData.netDueDays) {
      contract.netDueDays = initialData.netDueDays
    }

    const bankaccounts: Array<BankAccount> = []
    if (initialData.paymentMethod === PaymentMethodEnum.ACH) {
      bankaccounts.push(new BankAccount({
        id: -1,
        type: BankAccountTypeEnum.CHECKING,
        accountHolder: initialData.businessName
      }))
    }

    return new Customer({
      id: this.customerId,
      businessName: initialData.businessName,
      type: initialData.type,
      address: initialData.address,
      accountIds: initialData.accountIds,
      contacts: contacts,
      contracts: [contract],
      billGroups: [bg],
      bankaccounts: bankaccounts
    })
  }

  public importFromExisting (customer: Customer) {
    customer.contracts = this.customerShell!.contracts
    customer.accounts = this.customerShell!.accounts
    customer.accountIds = this.customerShell!.accountIds

    const contacts = this.customerShell!.contacts.filter(c =>
      !customer.contacts.find(cc => cc.primaryEmail === c.primaryEmail || (cc.firstName.trim() === c.firstName.trim() && cc.lastName.trim() === c.lastName.trim()))
    )

    if (customer.contacts.find(c => c.type === ContactTypeEnum.PRIMARY)) {
      contacts.filter(c => c.type === ContactTypeEnum.PRIMARY).forEach(c => { c.type = ContactTypeEnum.OTHER })
    }

    const esignAccounts = this.customerShell!.bankaccounts.filter(a => a.accountNumber.length > 0)

    this.customerShell = customer
    if (contacts.length) {
      this.customerShell.contacts.push(...contacts)
    }

    if (esignAccounts.length) {
      for (const existingAccount of this.customerShell.bankaccounts) {
        const existingIdx = esignAccounts.findIndex(b => (b.accountNumber.substr(-4) === existingAccount.accountNumber.substr(-4) && b.routingNumber.substr(-4) === existingAccount.routingNumber.substr(-4)) || b.id === existingAccount.id)
        if (existingIdx !== -1) {
          esignAccounts.splice(existingIdx, 1)
        }
      }
      this.customerShell.bankaccounts.push(...esignAccounts)
    }
  }

  public importBankFromEsign (esignFields: Array<IStringIndexed>) {
    if (!this.customerShell) {
      return
    }

    const acctField = esignFields.find(f => f.fieldName === 'BankAccountNumber')
    if (!acctField) {
      return
    }
    let account = this.customerShell?.bankaccounts.find(b => b.accountNumber === acctField.fieldValue)

    if (!account) {
      const routingField = esignFields.find(f => f.fieldName === 'BankRoutingNumber')
      account = this.customerShell?.bankaccounts.find(b => b.accountNumber.substr(-4) === acctField.fieldValue.substr(-4) && b.routingNumber?.substr(-4) === routingField?.fieldValue.substr(-4))
    }

    if (!account) {
      account = this.customerShell?.bankaccounts.find(b => b.id === -1)
    }

    if (!account) {
      account = new BankAccount({
        id: -1,
        type: BankAccountTypeEnum.CHECKING,
        accountHolder: this.customerShell?.businessName
      })
      this.customerShell!.bankaccounts.push(account)
    }

    account.accountNumber = acctField.fieldValue
    account.routingNumber = esignFields.find(f => f.fieldName === 'BankRoutingNumber')?.fieldValue
    account.bankName = esignFields.find(f => f.fieldName === 'BankName')?.fieldValue

    if (this.customerShell!.billGroups[0].paymentMethodId === PaymentMethodEnum.ACH) {
      this.customerShell!.billGroups[0].bankAccountId = account.id
    }
  }

  public get statusLabel () {
    return ContractSubmission.statuses[this.status]
  }
}
