import esalesClient, { filtersToQs, checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { File, IFile } from '@/modules/shared/models/file'
import { TaxExemptForm } from '@/modules/customers/models/customer/taxexemptform'

interface IFileResponse {
  file: IFile;
}

export class TaxExemptFormApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['queue-tax-exempt']
    this.singular = 'taxExemptForm'
    this.plural = 'taxExemptForms'
    this.factory = (...args: any) => new TaxExemptForm(...args)
  }

  public async loadAll (config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<{ total: number, items: Array<TaxExemptForm> }> {
    return await this.loadAllSpecific(arguments, config) as { total: number, items: Array<TaxExemptForm> }
  }

  public async load (id: number, onlyProps?: Array<string>): Promise<TaxExemptForm> {
    return await this.loadSpecific(arguments, onlyProps) as TaxExemptForm
  }

  public async save (item: TaxExemptForm, noteText?: string) : Promise<TaxExemptForm> {
    const data: IStringIndexed = { ...item }

    if (noteText) {
      data.note = {
        source: 'CIS',
        body: noteText
      }
    }

    const args = Array.from(arguments).slice(0, -1)

    return await this.saveSpecific(args, data) as TaxExemptForm
  }

  public async savePartial (item: TaxExemptForm, props: Array<string>) : Promise<TaxExemptForm> {
    const data: IStringIndexed = { ...item }

    return await this.savePartialSpecific([item.id], data, props) as TaxExemptForm
  }

  public async finalize (item: TaxExemptForm, type: string, note?: string) : Promise<TaxExemptForm> {
    const data: IStringIndexed = { ...item }
    data.finalized = type

    const props = ['id']

    if (note) {
      data.note = { body: note }
      props.push('note')
    }

    return await this.savePartialSpecific([item.id], data, props) as TaxExemptForm
  }

  public async attachFile (item: TaxExemptForm, file: File, callback?: Function): Promise<TaxExemptForm> {
    try {
      const method = 'PUT'

      const data = { ...item, file: { ...file } }
      data.file.contents = btoa(file.contents)

      const response: AxiosResponse = await this.client.request(
        {
          url: 'queue-tax-exempt',
          method: <Method> method,
          data: data,
          onUploadProgress: (e) => {
            if (callback) {
              callback(e)
            }
          }
        }
      )

      if (response.status === 200 && response.data.taxExemptForm) {
        return new TaxExemptForm(response.data.taxExemptForm)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const TaxExemptFormsApi = new TaxExemptFormApi(esalesClient)
