






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import UserChip from '@/modules/shared/components/UserChip.vue'
import { Note } from '@/modules/customers/models/customer/note'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'

@Component({
  components: {
    'user-chip': UserChip
  }
})
export default class CustomerNote extends Vue {
  @Prop({ default: new Note() })
  private note!: Note

  private getAppUserName (userId: number): string {
    return AppState.cisUsers[userId].detailName
  }

  private handleEdit () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-note', componentProps: { note: this.note }, dialogProps: { width: '100%' } })
  }

  private handleDelete () {
    if (confirm('Are you sure you want to delete this note?')) {
      CustomerState.DeleteNote(this.note)
    }
  }
}
