

















































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { ElectricAccount } from '@/modules/shared/models/account/electric'

@Component
export default class BillGroupServiceAccountsForm extends Vue {
  @PropSync('billGroup', {
    required: true,
    default: () => new BillGroup()
  })
  private localBillGroup!: BillGroup

  @Prop({ default: () => [] })
  private value!: Array<number>

  @Prop({ default: () => [] })
  private accounts!: Array<ElectricAccount>

  private selectedAll: null|boolean = null
  private accountIds: Array<number> = []
  private originalAccountIds: Array<number> = []

  @Watch('localBillGroup.id')
  private handleBillGroupChange (oldId: number, newId: number) {
    Vue.set(this, 'originalAccountIds', this.value)
    Vue.set(this, 'accountIds', this.value)
  }

  @Watch('accountIds')
  private handleAssignmentChange () {
    this.$emit('input', this.accountIds)
  }

  private toggleSelectAll () {
    if (this.selectedAll) {
      this.accountIds = this.accounts.map(a => a.id)
    } else {
      this.accountIds = []
    }
  }

  public mounted () {
    Vue.set(this, 'originalAccountIds', this.value)
    Vue.set(this, 'accountIds', this.value)
  }
}
