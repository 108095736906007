


































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import ServiceAccountInfo from '@/modules/customers/components/blocks/serviceaccount/detail/Info.vue'
import UsageChart from '@/modules/customers/components/blocks/serviceaccount/detail/UsageChart.vue'
import MeterReadList from '@/modules/customers/components/blocks/serviceaccount/detail/MeterReadList.vue'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { CommodityEnum } from '@/modules/shared/enums'
import AccountFactory from '@/modules/shared/models/accountfactory'
import { monthListToRanges } from '@/modules/shared/helpers'

@Component({
  components: {
    'account-info': ServiceAccountInfo,
    'usage-chart': UsageChart,
    'meter-reads': MeterReadList
  }
})
export default class ContractAccountDetail extends Vue {
  private tabs = 0

  @Prop({ required: true })
  protected contractId!: number

  @Prop({ required: true })
  protected accountId!: number

  public handleReset (): void {
    this.$emit('dialog:close')
  }

  private get account () {
    if (!this.accountId) {
      this.$router.push({ name: 'customeraccounts' }).catch(() => {})
    }

    const acct = CustomerState.customer.accounts.find(a => {
      return a.id === this.accountId
    })

    if (!acct) {
      return AccountFactory.getAccount(CommodityEnum.E)
    }
    return acct
  }

  public get periodRanges () {
    return monthListToRanges(this.account.periodDetails, 'period')
  }

  public get customer () {
    return CustomerState.customer
  }

  @Watch('contractId', { immediate: true })
  private handleContractIdChange () {
    const contractHasAccount = CustomerState.customer.contracts.find(c => {
      return c.id === this.contractId
    })?.accountIds.indexOf(this.accountId) !== -1

    if (!contractHasAccount) {
      this.$router.push({ name: 'customercontractdetail' }).catch(() => {})
    }
  }
}
