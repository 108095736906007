import esalesClient from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance } from 'axios'
import { Contact } from '@/modules/customers/models/customer/contact'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'

export class ContactApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['customers', 'contacts']
    this.singular = 'contact'
    this.plural = 'contacts'
    this.factory = (...args: any) => new Contact(...args)
  }

  public async loadAll (customerId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<Contact>> {
    return await this.loadAllSpecific(arguments, config) as Array<Contact>
  }

  public async load (customerId: number, id: number, onlyProps?: Array<string>): Promise<Contact> {
    return await this.loadSpecific(arguments, onlyProps) as Contact
  }

  public async save (customerId: number, contact: Contact) : Promise<Contact> {
    const data: IStringIndexed = { ...contact }

    return await this.saveSpecific(arguments, data) as Contact
  }

  public async delete (contact: Contact) {
    return await this.deleteSpecific([contact.customerId, contact.id])
  }
}

export const ContactsApi = new ContactApi(esalesClient)
