import { PeriodDetailFlowStatuses } from '@/modules/shared/lists'
import { PeriodDetailFlowStatusEnum } from '@/modules/shared/enums'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import moment from 'moment'
import { IStringIndexed } from '@/modules/shared/types'

export interface IPeriodDetail extends IBaseModel {
  id: number;
  accountId: number;
  contractId: number;
  billGroupId: number;
  period: string;
  flowFlag: keyof PeriodDetailFlowStatuses;
  customerId: null|number;
  customerName: null|string;
  details: IStringIndexed;
}

export class PeriodDetail extends Base implements IPeriodDetail {
  public static flowStatuses = new PeriodDetailFlowStatuses()

  public id = 0
  public accountId = 0
  public contractId = 0
  public billGroupId = 0
  public period = ''
  public flowFlag = PeriodDetailFlowStatusEnum.NOT
  public customerId = null
  public customerName = null
  public details = {}

  constructor (props?: Partial<IPeriodDetail>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'accountId':
          case 'customerId':
          case 'contractId':
          case 'billGroupId':
          case 'flowFlag':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get flowStatusLabel () {
    return PeriodDetail.flowStatuses[this.flowFlag]
  }

  public get flowStatusIcon () {
    switch (this.flowFlag) {
      case PeriodDetailFlowStatusEnum.STARTED:
        return '$vuetify.icons.up'
      case PeriodDetailFlowStatusEnum.FLOWING:
        return '$vuetify.icons.forward'
      case PeriodDetailFlowStatusEnum.STOPPED:
        return '$vuetify.icons.down'
      default:
        return ''
    }
  }

  public get flowStatusIconColor () {
    switch (this.flowFlag) {
      case PeriodDetailFlowStatusEnum.STARTED:
        return 'green lighten-3'
      case PeriodDetailFlowStatusEnum.FLOWING:
        return 'green'
      case PeriodDetailFlowStatusEnum.STOPPED:
        return 'red lighten-3'
      default:
        return ''
    }
  }
}
