







































































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import { TransactionRequestStatusEnum, TransactionRequestTypeEnum } from '@/modules/shared/enums'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import EnrollAccountOptions from '../forms/EnrollAccountOptions.vue'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import AccountsApi from '@/modules/customers/api/accounts'
import { RatePlan } from '@/modules/customers/models/rateplan'
import { ContractSubmissionsApi } from '../../api/queue/contractsubmissions'
import { INumericDictionary, IStringDictionary } from '@/modules/shared/types'
import moment from 'moment'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { ElectricAccount } from '@/modules/shared/models/account/electric'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete,
    'account-options': EnrollAccountOptions
  }
})
export default class CSQEnrollAccountsStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  @Prop({ required: true, default: () => [] })
  private accounts!: Array<IAccount>

  @Prop({ required: true })
  private isP2c!: boolean

  @Prop({ required: true })
  private loading!: boolean

  private valRules: Rules = Rules

  private noErrors: boolean = true
  private errorMessage: string = ''
  private message: string = ''
  private optionsLoading: boolean = true
  private optionsLoaded: boolean = false

  private expandedRows: Array<ElectricAccount> = []

  private availableRatePlans: Array<RatePlan> = []
  private enrollmentOptionsCache: Map<number, IStringDictionary<Array<string> | string>> = new Map()
  private accountMessages: Map<number, string> = new Map()

  private headers = [
    { text: 'Account Number', value: 'accountNumber', width: '200' },
    { text: 'Enrollment Status', value: 'transactionId', width: '250' },
    { text: 'Message', value: 'message' },
    { text: '', value: 'data-table-expand' }
  ]

  @Watch('enrollmentOptionsCache', { immediate: true, deep: false })
  private handleCacheUpdate () {
    if (this.enrollmentOptionsCache && !this.isP2c) {
      this.accounts.forEach((acct: IAccount) => {
        const req: TransactionRequest = acct.enrollmentRequest
        if (req) {
          req.scheduledAt = this.earliestSendable(req.accountId)
        }
      })
    }
  }

  @Watch('accounts', { deep: true })
  private async redoFetch () {
    const len = this.localItem?.customerShell?.accountIds.length || 0

    if (len === 0 || len > this.accounts.length || this.optionsLoaded === true) {
      return // adding accts still in progress
    }

    this.optionsLoaded = false
    this.optionsLoading = true
    try {
      const enrollmentOptions = await ContractSubmissionsApi.getEnrollmentOptions(this.localItem.id)
      Vue.set(this, 'enrollmentOptionsCache', enrollmentOptions.options)
      Vue.set(this, 'accountMessages', enrollmentOptions.accountMessages)
      for (const [acctId, request] of enrollmentOptions.requests) {
        const acct = this.accounts.find(a => a.id === acctId)
        if (acct) {
          const update = acct.clone()
          update.enrollmentRequest = request

          if (!Object.keys(request.details.transactionConfig || {}).length) {
            request.details.transactionConfig = {
              asInterval: 'N',
              secondaryRequestHu: 'Y',
              secondaryHuAsInterval: 'N'
            }
          }

          if (this.isP2c && !request.details.transactionConfig?.masterAccountNumber) {
            const bgId = acct.periodDetails.find(pd => pd.contractId === this.localItem.contractId)?.billGroupId
            const groupAccts = this.accounts.filter(a => a.periodDetails.find(pd => pd.contractId === this.localItem.contractId)?.billGroupId === bgId)
            request.details.transactionConfig.masterAccountNumber = groupAccts.length ? groupAccts[0]?.accountNumber : ''
          }

          this.$emit('account:update', update)
        }
      }

      if (this.isP2c) {
        const accountIds: Array<number> = this.accounts.map((acct: IAccount) => acct.id)
        this.availableRatePlans = await AccountsApi.loadAvailableRatePlans(accountIds)
      }
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.optionsLoaded = true
      this.optionsLoading = false
    }
  }

  public handleClose () : void {
    this.$emit('dialog:close')
  }

  private mounted () {
  }

  protected enrollmentOptions (acctId: number) {
    return this.enrollmentOptionsCache.get(acctId)
  }

  protected get enrollmentRequests () {
    const reqs: Array<TransactionRequest> = []
    this.accounts!.forEach(acct => {
      reqs.push(acct.enrollmentRequest)
    })
    return reqs
  }

  protected getStatusOf (account: ElectricAccount) {
    if (this.loading) {
      return 'Saving'
    }
    if (!account.enrollmentRequest) {
      return account.statusLabel
    }
    return account.enrollmentRequest?.statusLabel ?? 'New'
  }

  protected getStatusColor (statusLabel: string) {
    switch (statusLabel) {
      case 'Saving':
      case 'New':
        return 'gray'
      case 'Queued':
      case 'Ready':
      case 'Scheduled':
      case 'Flowing':
        return 'green'
      case 'Rejected':
      case 'Ignored':
        return 'red'
      default:
        return 'info'
    }
  }

  protected getStatusMessage (account: ElectricAccount) {
    const req = account.enrollmentRequest

    if (this.accountMessages.has(account.id)) {
      return this.accountMessages.get(account.id)
    } else if (req?.status === TransactionRequestStatusEnum.QUEUED) {
      return 'Queued since ' + req.statusAt
    } else if (req?.status === TransactionRequestStatusEnum.SCHEDULED) {
      return 'Request ID: ' + req.id + ' queued until ' + req.scheduledAt
    } else if (req?.status === TransactionRequestStatusEnum.ACCEPTED) {
      return 'Sent ' + moment(req.sentAt).format('YYYY-MM-DD') + ', response ' + moment(req.responseAt).format('YYYY-MM-DD')
    } else if (req?.hasError) {
      return req.error
    }
    return ''
  }

  @Watch('enrollmentRequests')
  protected handleRowExpansion () {
    Vue.set(this, 'expandedRows', [])
    for (const a of this.accounts) {
      if (a.enrollmentRequest && a.enrollmentRequest.isUnsent) {
        this.expandedRows.push(a as ElectricAccount)
      }
    }
  }

  private earliestSendable (acctId: number): string {
    const checkDate = moment()
    let counter = 0
    const opts = this.enrollmentOptionsCache.get(acctId)

    while (counter++ < 20) {
      const checkDateYmd = checkDate.format('YYYY-MM-DD')

      if (opts === undefined) {
        console.log('sendable check:', acctId, typeof acctId, opts)
        return checkDateYmd
      }

      const sendable = opts!.skip.indexOf(checkDateYmd) === -1 &&
          checkDateYmd >= opts.earliest

      if (sendable) {
        return checkDateYmd
      }
      checkDate.add(1, 'days')
    }
    return 'bad'
    // fail: nothing in next 20d? (add err)
  }
}
