import esalesClient, { checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'

export class PaymentmodelApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['finance-payments']
    this.singular = 'payment'
    this.plural = 'payments'
    this.factory = (...args: any) => new CustomerPayment(...args)
  }

  public async loadAll (config?: IStringDictionary<Array<string|number>|IStringDictionary<string | Array<string|number> | IStringIndexed>>): Promise<Array<CustomerPayment>> {
    return await this.loadAllSpecific(arguments, config) as Array<CustomerPayment>
  }

  public async load (id: number, onlyProps?: Array<string>): Promise<CustomerPayment> {
    return await this.loadSpecific(arguments, onlyProps) as CustomerPayment
  }
}

export const PaymentsModelApi = new PaymentmodelApi(esalesClient)
