




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Contract } from '@/modules/customers/models/customer/contract'
import { ListsEnum, StateAbbreviationsEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import { SalesState } from '@/modules/sales/store'
import { CustomerState } from '@/modules/customers/store'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import Rules from '@/plugins/validations'
import ContractSalesCommission from './Commission.vue'
import SalesUserContract from '@/modules/sales/models/salesuser/contract'

@Component({
  components: {
    'commission-row': ContractSalesCommission
  },
  directives: {
  }
})
export default class ContractCommissionsForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true

  protected maxCommissionSlots = Contract.MAX_COMMISSIONS_SLOTS
  protected commissionableSales = mapObjectVuetifySelect(SalesState.salesNames, true)

  @Prop({ required: true })
  private contract!: Contract

  private localContract: Contract = new Contract()

  public async handleSubmit () {
    if (this.saving || !this.canSave) {
      return
    }

    if (!(this.$refs.commissionsForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    const activeComms = this.localContract.commissions.filter(c => c.salesId && (c.effectiveTo || '').length === 0)

    if (activeComms?.length > Contract.MAX_COMMISSIONS_SLOTS) {
      this.errorMessage = 'You may only have ' + Contract.MAX_COMMISSIONS_SLOTS + ' sales receiving Commissions at one time'
      return
    }

    this.saving = true
    this.errorMessage = ''

    try {
      const contract: Contract = await CustomerState.SaveContract({
        id: this.localContract.id,
        customerId: this.localContract.customerId,
        commissions: this.localContract.commissions
      } as Contract)
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public handleClose (): void {
    this.$emit('dialog:close')
  }

  public get mode () {
    return this.contract.id ? 'Edit' : 'Add'
  }

  public get canSave () {
    if (this.contract.id) {
      return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.EDIT')
    }
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.CREATE')
  }

  public get canViewCommissions () {
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.VIEW')
  }

  @Watch('localContract.commissions', { deep: true })
  public handleCommissionUpdate () {
    if (this.localContract.commissions.filter(c => c.salesId > 0).length === this.localContract.commissions.length) {
      this.localContract.commissions.push(new SalesUserContract({ contractId: this.localContract.id }))
    }
  }

  public beforeMount () {
    this.localContract = this.contract.clone()

    if (!this.localContract.customerId) {
      this.localContract.customerId = CustomerState.customer.id
    }
  }
}
