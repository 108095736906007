import { AppState } from '@/stores/appStore'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { ListsEnum, ChargesEnum, ResettlementBillableStatusEnum } from '@/modules/shared/enums'
import { INumericDictionary, INumericIndexed, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { InvoiceLineItem } from '@/modules/customers/models/account/invoice/lineitem'
import { AccountInvoice } from '@/modules/customers/models/account/invoice'
import { ResettlementBillableStatuses } from '@/modules/shared/lists'
import { MeterRead } from '@/modules/shared/models/account/meterread'
import { CommodityAccount } from '@/modules/shared/models/account/base'
import moment from 'moment'

export interface IUsageResettlement extends IBaseModel {
  id: number;
  serviceAccountUsageId: number;
  settlementNumber: number;
  status: number;
  lastAttemptedAt: string|null;
  errorMessage: string|null;
  billableStatus: keyof ResettlementBillableStatuses;
  totalCharge: number;
  energy: number;
  ancillaries: number;
  rps: number;
  aeFee: number;
  capacity: number;
  losses: number;
  serviceAccountUsage: MeterRead;
  usageLineItems: Array<InvoiceLineItem>;
  invoices: INumericDictionary<AccountInvoice>;
}

export class UsageResettlement extends Base implements IUsageResettlement {
  public static billableStatuses = new ResettlementBillableStatuses()

  public id = 0
  public serviceAccountUsageId = 0
  public settlementNumber = 0
  public status = 0
  public approvedAt = null
  public approvedBy = 0
  public lastAttemptedAt = ''
  public errorMessage = ''
  public billableStatus = ResettlementBillableStatusEnum.UNDECIDED
  public totalCharge = 0
  public resettlePrice = 0
  public energy = 0
  public ancillaries = 0
  public rps = 0
  public aeFee = 0
  public capacity = 0
  public losses = 0
  public serviceAccountUsage = new MeterRead()
  public account = new CommodityAccount()
  public usageLineItems = []
  public uliRaw: any = []
  public invoices = {}

  protected bucketMap: INumericDictionary<string> = {
    // ChargesEnum.ENERGY: 'energy',
    1: 'energy',
    2: 'ancillaries',
    3: 'rps',
    4: 'losses',
    5: 'capacity',
    6: 'aeFee'
  }

  constructor (props?: Partial<IUsageResettlement>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'totalCharge':
            props[p] = parseFloat(props[p]!.toString() ?? '0.00')
            props.resettlePrice = parseFloat(props[p]!.toString() ?? '0.00')
            break
          case 'energy':
          case 'ancillaries':
          case 'rps':
          case 'aeFee':
          case 'capacity':
          case 'losses':
            props[p] = parseFloat(props[p]!.toString() ?? '0.00')
            break
          case 'serviceAccountUsage':
            if (props[p]?.account !== undefined) {
              props.account = new CommodityAccount(props[p]?.account)
            }
            props[p] = new MeterRead(props[p])
            break
          case 'usageLineItems':
            if (props[p] && (<Array<InvoiceLineItem>>props[p]).length) {
              const objs: Array<InvoiceLineItem> = []
              for (const c of <Array<InvoiceLineItem>>props[p]) {
                objs.push(new InvoiceLineItem(c))
              }
              props.uliRaw = props[p]
              props[p] = objs
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get approvedByName () {
    return AppState.cisUsers[this.approvedBy]?.detailName || ''
  }

  public get billableStatusLabel () {
    return UsageResettlement.billableStatuses[this.billableStatus]
  }

  public get invoiceRaw () {
    if (this.uliRaw === null || this.uliRaw.length === 0) {
      return null
    }
    const li = this.uliRaw[0]

    if (li.invoice === null) {
      return null
    }

    return li.invoice
  }
}

export interface IFinanceUsageResettlementSummary extends IBaseModel {
  customerName: string;
  accountNumber: string;
  meterNumber: string;
  accountStatusLabel: string;
  usagePeriod: string;
  quantity: Number;
}

export class FinanceUsageResettlementSummary extends UsageResettlement implements IFinanceUsageResettlementSummary {
  public customerName = ''
  public accountNumber = ''
  public meterNumber = ''
  public accountStatusLabel = ''
  public usagePeriod = ''
  public quantity = 0.00

  constructor (props?: Partial<IFinanceUsageResettlementSummary>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get bucketsCompare () {
    const buckets: Array<{ resettlePrice: number, resettleDelta: number, lineItem: InvoiceLineItem }> = []
    for (const bucketId in this.bucketMap) {
      const bucketIdInt = parseInt(bucketId)
      const li = this.usageLineItems.find((l: InvoiceLineItem) => l.chargeId === bucketIdInt) ?? new InvoiceLineItem()

      buckets.push({
        resettlePrice: this[this.bucketMap[bucketId]],
        resettleDelta: this[this.bucketMap[bucketId]] - li.total,
        lineItem: li
      })
    }
    return buckets
  }

  public get resettleDelta () {
    if (this.invoiceRaw === null) {
      return 0
    }
    return (this.totalCharge - this.invoiceRaw.commodityTotal).toFixed(4)
  }

  public get deltaPercent () {
    if (this.invoiceRaw === null) {
      return 0
    }
    return (((this.totalCharge - this.invoiceRaw.commodityTotal) / this.invoiceRaw.commodityTotal) * 100).toFixed(4)
  }

  public get netCharged () {
    if (this.invoiceRaw === null) {
      return 0
    }
    return this.invoiceRaw.commodityTotal
  }

  public static fromList (list: Array<UsageResettlement>) : Array<FinanceUsageResettlementSummary> {
    const summaries: Array<FinanceUsageResettlementSummary> = []

    for (const c of list) {
      summaries.push(new FinanceUsageResettlementSummary({
        customerName: c.invoiceRaw.customer.businessName,
        accountNumber: c.account?.accountNumber,
        meterNumber: c.account?.meterNumber,
        accountStatusLabel: CommodityAccount.flowStatii[c.account?.status ?? 0],
        usagePeriod: c.serviceAccountUsage.startDate + ' to ' + c.serviceAccountUsage.endDate,
        quantity: c.serviceAccountUsage.quantity,
        ...c
      }))
    }

    return summaries
  }
}
