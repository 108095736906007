

























import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'

@Component({})
export default class ConfirmDialog extends Vue {
  @Prop({ default: 'Confirm' })
  private title!: string

  @Prop({ default: 'black' })
  private titleColor!: string

  @Prop({ default: 'primary' })
  private confirmColor!: string

  @Prop({ default: 'Yes' })
  private confirmLabel!: string

  @Prop({ default: null })
  private onConfirm!: Function|null

  @Prop({ default: '' })
  private message!: string

  private handleConfirm () {
    this.handleClose()

    if (this.onConfirm) {
      this.onConfirm()
    }
  }

  private handleClose () {
    this.$emit('dialog:close')
  }
}
