import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse } from 'axios'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { File as FileModel } from '@/modules/shared/models/file'

export default class FinanceReportsApi {
  public static async loadAgingDetails (filters?: IStringDictionary<any>): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-aging' + filtersToQs(filters))
      if (response.status === 200 && response.data.records) {
        return Object.values(response.data.records)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadAgingByCustomer (filters?: IStringDictionary<any>): Promise<Array<IStringIndexed>> {
    try {
      filters = filters || {}
      filters.summary = 1
      const response: AxiosResponse = await esalesClient.get('finance-aging' + filtersToQs(filters))
      if (response.status === 200 && response.data.records) {
        return Object.values(response.data.records)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadGlFeed (filters?: IStringDictionary<any>): Promise<Array<IStringIndexed>> {
    try {
      filters = filters || {}
      const response: AxiosResponse = await esalesClient.get('finance-gl' + filtersToQs(filters))
      if (response.status === 200 && response.data.records) {
        return Object.values(response.data.records)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadPayments (from: string, to: string, filters?: IStringDictionary<any>): Promise<Array<IStringIndexed>> {
    try {
      filters = filters || {}
      filters.from = from
      filters.to = to
      const response: AxiosResponse = await esalesClient.get('finance-reporting-payments' + filtersToQs(filters))
      if (response.status === 200 && response.data.records) {
        return Object.values(response.data.records)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchBillingRegister (from: string, to: string): Promise<FileModel> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-reporting-billing-register?from=' + from + '&to=' + to)
      if (response.status === 200) {
        return new FileModel(response.data)
      }

      throw new Error()
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchInvoiceOpenBalance (from: string, to: string): Promise<FileModel> {
    try {
      const response: AxiosResponse = await esalesClient.get('finance-reporting-invoice-open-balance?from=' + from + '&to=' + to)
      if (response.status === 200) {
        return new FileModel(response.data)
      }

      throw new Error()
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
