

































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import Customer from '@/modules/customers/models/customer'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import EventBus from '@/plugins/eventbus'
import { Contact } from '@/modules/customers/models/customer/contact'

@Component({
  components: {
  }
})
export default class BillGroupsList extends Vue {
  @Prop({ required: true })
  private customer!: Customer

  @Prop({ required: true })
  private billgroups!: Array<BillGroup>

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Label', align: 'left', sortable: true, value: 'label' },
    { text: 'Contact', align: 'left', sortable: true, value: 'contact' },
    { text: 'Delivery Method', align: 'left', sortable: true, value: 'deliveryMethodLabel' },
    { text: 'Payment Method', align: 'left', sortable: true, value: 'paymentMethodLabel' },
    { text: 'Assembly Method', align: 'left', sortable: true, value: 'assemblyTypeLabel' },
    { text: '', align: 'left', sortable: true, value: 'actions' }
  ]

  public loading: boolean = false
  public loadingAccounts: Array<number> = []

  private localItem: BillGroup = new BillGroup({ customerId: this.customer?.id })

  private get canAddBillGroup () {
    return AuthState.user.isAllowed('CUSTOMER.BILLGROUPS.CREATE')
  }

  private get canDeleteBillGroup () {
    return AuthState.user.isAllowed('CUSTOMER.BILLGROUPS.DELETE')
  }

  private get canViewBillGroupAccounts () {
    return AuthState.user.isAllowed('CUSTOMER.BILLGROUPS.ACCOUNTS.VIEW')
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.value !== 'Actions' })
    }

    return headers
  }

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private getContact (group: BillGroup) {
    const contact = this.customer.contacts.find(c => c.id === group.contactId)
    if (!contact) {
      return new Contact()
    }
    return contact
  }

  private handleEditAccounts (item: BillGroup) {
    if (CustomerState.customer.accounts.length > 0) {
      EventBus.$emit('open-cust-dialog', { component: 'edit-customer-billgroup-accounts', componentProps: { billGroup: item } })
    } else {
      this.loadingAccounts.push(item.id)
      CustomerState.LoadAccounts().then((a) => {
        EventBus.$emit('open-cust-dialog', { component: 'edit-customer-billgroup-accounts', componentProps: { billGroup: item } })
        const index = this.loadingAccounts.indexOf(item.id, 0)
        if (index > -1) {
          this.loadingAccounts.splice(index, 1)
        }
      })
    }
  }

  private handleEditBillGroup (item: BillGroup) {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-billgroup', componentProps: { billgroup: item } })
  }

  private handleDelete (group: BillGroup) {
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Delete',
        message: '<b>Are you sure you want to delete this bill group?</b>',
        onConfirm: () => { CustomerState.DeleteBillGroup(group) }
      }
    })
  }
}
