




















































































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { UtilityFieldConfig } from '@/modules/shared/models/utility/fieldconfig'
import { AppState } from '@/stores/appStore'
import { CustomerTypeEnum } from '@/modules/shared/enums'
import Rules from '@/plugins/validations'
import ServiceAccountGeneralFormFields from '@/modules/customers/components/forms/serviceaccount/General.vue'
import ServiceAccountAddressFormFields from '@/modules/customers/components/forms/serviceaccount/Address.vue'
import AccountsApi from '@/modules/customers/api/accounts'
import { ContractSubmissionsApi } from '../../api/queue/contractsubmissions'
import EventBus from '@/plugins/eventbus'
import moment from 'moment'

@Component({
  components: {
    'general-fields': ServiceAccountGeneralFormFields,
    'address-fields': ServiceAccountAddressFormFields
  }
})
export default class CSQServiceAccountsStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  @Prop({ required: true })
  private accounts!: Array<ElectricAccount>

  private noErrors: boolean = true
  private saving: boolean = false
  private errorMessage: string = ''
  private fieldConfig = UtilityFieldConfig.defaultFields
  private fieldErrors: IStringIndexed = {}

  private dialog: boolean = false
  private dialogDelete: boolean = false
  private dialogSplit: boolean = false

  private editAccount: ElectricAccount = new ElectricAccount()

  private splitList: Array<ElectricAccount> = []
  private splitNote: string = ''

  private headers = [
    { value: 'utilityName', text: 'Utility', align: 'start', sortable: false },
    { value: 'accountNumber', text: 'Account #', align: 'start', sortable: true },
    { value: 'meterNumber', text: 'Meter #', align: 'start', sortable: false },
    { value: 'address', text: 'Address', align: 'start', sortable: false },
    { value: 'keyName', text: 'Keyname', align: 'start', sortable: false },
    { value: 'annualHu', text: 'HU', align: 'end', sortable: true },
    { value: 'actions', text: 'Actions', align: 'center', sortable: false }
  ]

  public get filteredAccounts () {
    return this.accounts.filter((a) => this.localItem.customerShell?.accountIds.includes(a.id))
  }

  public get canSplit () {
    return !(this.splitList.length === 0 || this.splitList.length === this.filteredAccounts.length || this.filteredAccounts.length === 1)
  }

  private handleEditAccount (account: ElectricAccount) {
    this.dialog = true
    Vue.set(this, 'editAccount', account.clone())
  }

  private handleDeleteAccount (account: ElectricAccount) {
    this.dialogDelete = true
    Vue.set(this, 'editAccount', account.clone())
  }

  private handleCloseDialog () {
    this.dialog = false
    Vue.set(this, 'editAccount', new ElectricAccount())
  }

  private async handleSaveDialog () {
    if (this.saving) {
      return
    }

    if (!(this.$refs.serviceAccountForm as Vue & { validate: () => boolean }).validate()) {
      return
    }
    try {
      this.saving = true
      const resp = await AccountsApi.save(this.localItem.customerShell!.type, this.editAccount)
      this.$emit('account:update', resp.account)

      this.handleCloseDialog()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  private handleCloseDeleteDialog () {
    this.dialogDelete = false
    Vue.set(this, 'editAccount', new ElectricAccount())
  }

  private async handleSaveDeleteDialog () {
    try {
      this.saving = true
      this.localItem.customerShell!.accountIds.splice(this.localItem.customerShell!.accounts.findIndex(a => a.id === this.editAccount.id), 1)
      const updatedItem = await ContractSubmissionsApi.savePartial(this.localItem, ['customerShell'])
      EventBus.$emit('csqitem:update', updatedItem)
      Vue.set(this, 'localItem', updatedItem)

      this.handleCloseDeleteDialog()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  private handleSplitAccounts () {
    this.dialogSplit = true
  }

  private handleCloseSplitDialog () {
    this.dialogSplit = false
  }

  private async handleSaveSplitDialog () {
    try {
      this.saving = true

      const copy: ContractSubmission = this.localItem.clone()
      copy.id = 0
      copy.customerShell!.accountIds = this.splitList.map(a => a.id)
      copy.customerShell!.accounts = copy.customerShell!.accounts.filter(a => copy.customerShell!.accountIds.includes(a.id))
      copy.crmQuoteId = 0

      const payload = await ContractSubmissionsApi.split(copy, this.splitNote)

      EventBus.$emit('csqitem:create', payload.item)
      if (payload.items) {
        for (const i of payload.items) {
          EventBus.$emit('csqitem:update', i)
          if (i.id === this.localItem.id) {
            Vue.set(this.localItem, 'customerShell', i.customerShell)
          }
        }
      }
      Vue.set(this, 'splitList', [])

      this.handleCloseSplitDialog()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public getRules (fields: Array<string>) {
    const rules: IStringDictionary<Array<Function>> = {}

    for (const fieldName of fields) {
      rules[fieldName] = []
      if (!this.fieldConfig[fieldName] || !this.editAccount.utilityId) {
        continue
      }

      if (this.fieldConfig[fieldName].required) {
        rules[fieldName].push(Rules.required())
      }

      if (AppState.utilities[this.editAccount.utilityId].validations[this.editAccount.commodity as number] &&
        AppState.utilities[this.editAccount.utilityId].validations[this.editAccount.commodity as number][fieldName]
      ) {
        AppState.utilities[this.editAccount.utilityId].validations[this.editAccount.commodity as number][fieldName].forEach((r: { regex: string; message: string }) => {
          rules[fieldName].push(Rules.regex(r.regex, r.message))
        })
      }
    }
    return rules
  }

  @Watch('editAccount.utilityId', { immediate: true })
  public updateFieldConfig () {
    if (!this.editAccount.utilityId) {
      Vue.set(this, 'fieldConfig', UtilityFieldConfig.defaultFields)
      return
    }

    this.editAccount.address.state = AppState.utilities[this.editAccount.utilityId].state

    const customerType = (this.localItem.customerShell!.type === CustomerTypeEnum.LARGE_COMM || this.localItem.customerShell!.type === CustomerTypeEnum.MED_COMM) ? CustomerTypeEnum.LARGE_COMM : CustomerTypeEnum.RESI
    const key = customerType

    Vue.set(this, 'fieldConfig', AppState.utilities[this.editAccount.utilityId].configs[key].fields)
  }

  private mounted () {}
}
