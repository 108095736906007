

























import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { InvoiceLineItem } from '@/modules/customers/models/account/invoice/lineitem'

@Component
export default class LineItemChargeDetails extends Vue {
  @Prop({ required: true })
  private lineItem!: InvoiceLineItem
}
