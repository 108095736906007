import { ChargeCategories } from '@/modules/shared/lists'
import { ChargeCategoryEnum } from '@/modules/shared/enums'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { LineItemChargeDetail } from './chargedetail'

export interface IInvoiceLineItem extends IBaseModel {
  id: number;
  invoiceId: number;
  parentLineItemId: number|null;
  usageId: number;
  chargeId: number;
  glId: number;
  contractId: number;
  type: keyof ChargeCategories;
  startDate: string;
  endDate: string;
  quantity: number;
  rate: number;
  total: number;
  taxable: number;
  label: string;
  p2cId: string;
}

export class InvoiceLineItem extends Base {
  public id = 0
  public invoiceId = 0
  public parentLineItemId = null
  public usageId = 0
  public chargeId = 0
  public glId = 0
  public contractId = 0
  public type = ChargeCategoryEnum.CATEGORY_CHARGE
  public startDate = ''
  public endDate = ''
  public quantity = 0
  public rate = 0
  public total = 0
  public taxable = 0
  public label = ''
  public p2cId = ''

  public details: Array<LineItemChargeDetail> = []

  constructor (props?: Partial<IInvoiceLineItem>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'invoiceId':
          case 'parentLineItemId':
          case 'usageId':
          case 'chargeId':
          case 'glId':
          case 'contractId':
          case 'type':
          case 'taxable':
            if (props[p] !== null) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'quantity':
          case 'rate':
          case 'total':
            props[p] = parseFloat(props[p]!.toString())
            break
          case 'details':
            if (props[p] && (<Array<LineItemChargeDetail>>props[p]).length) {
              const objs: Array<LineItemChargeDetail> = []
              for (const c of <Array<LineItemChargeDetail>>props[p]) {
                objs.push(new LineItemChargeDetail(c))
              }

              props[p] = objs
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
