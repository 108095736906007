


































import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerTypeEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { mask } from 'vue-the-mask'
import { Address } from '@/modules/shared/models/address'
import { UtilityFieldConfig } from '@/modules/shared/models/utility/fieldconfig'
import { CustomerState } from '@/modules/customers/store'

@Component({
  directives: {
    mask
  }
})
export default class ServiceAccountAddressFormFields extends Vue {
  @Prop({ required: true })
  private account!: ElectricAccount

  @PropSync('address', {
    required: true,
    default: () => new Address()
  })
  private localAddress!: Address

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  @Prop({ default: {} })
  private errors!: IStringIndexed

  @Prop({ default: UtilityFieldConfig.defaultFields })
  private fieldConfig!: IStringIndexed

  @Prop({ default: true })
  private enableTips!: boolean

  // private states = mapObjectVuetifySelect(AppState.servedStates)
  private states = AppState.dividedStateSelect

  public getFieldErrors (name: string) {
    return this.errors[name] || []
  }

  public showTipIcon (fieldName: string) {
    if (!this.enableTips || !this.fieldConfig[fieldName] || !this.fieldConfig[fieldName].tip || !this.account.utilityId) {
      return ''
    }

    return '$vuetify.icons.help'
  }

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      street: [Rules.required()],
      street2: [],
      city: [Rules.required()],
      state: [Rules.required()],
      zip: [Rules.required(), Rules.minDigits(5, true, 'Zip code must be 5 digits')],
      plus4: [Rules.minDigits(4, true, 'Plus 4 must be 4 digits')]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p].concat(...this.validations[p])
      }
    }

    return rules
  }

  public mounted () {
  }
}
