import { Address } from '@/modules/shared/models/address'
import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { ListBase } from '@/modules/shared/lists'

import AgreementModel from '@/modules/sales/models/salesuser/agreement'
import FileModel from '@/modules/sales/models/salesuser/file'
import NoteModel from '@/modules/sales/models/salesuser/note'
import TaxModel from '@/modules/sales/models/salesuser/taxid'

export enum UserStatiiEnum {
  ['STATUS_NEW'] = 0,
  ['STATUS_ACTIVE'] = 1,
  ['STATUS_RETIRED'] = 2,
  ['STATUS_SUSPENDED'] = 3,
  ['STATUS_TERMINATED'] = 4
}

export class UserStatii extends ListBase {
  readonly [UserStatiiEnum.STATUS_NEW] = 'New'
  readonly [UserStatiiEnum.STATUS_ACTIVE] = 'Active'
  readonly [UserStatiiEnum.STATUS_RETIRED] = 'Retired'
  readonly [UserStatiiEnum.STATUS_SUSPENDED] = 'Suspended'
  readonly [UserStatiiEnum.STATUS_TERMINATED] = 'Terminated'
}

export interface ISalesUser extends IStringIndexed {
  id: number;
  reportsTo: number|null;
  status: keyof UserStatii;
  isBusiness: number;
  enablePayments: number;
  companyName: string;
  firstName: string;
  lastName: string;
  address: Address;
  landline: string;
  cell: string;
  fax: string;
  email: string;
  reportEmail: string;
  crmId: number;
  bankName: string;
  bankRouting: string;
  bankAccount: string;
}

export default class SalesUser extends Base implements ISalesUser {
  public static statii = new UserStatii()

  public id = 0
  public reportsTo: number|null = null
  public status = UserStatiiEnum.STATUS_NEW
  public isBusiness = 0
  public enablePayments = 0
  public companyName = ''
  public firstName = ''
  public lastName = ''
  public address = new Address()
  public landline = ''
  public cell = ''
  public fax = ''
  public email = ''
  public reportEmail = ''
  public crmId = 0
  public bankName = ''
  public bankRouting = ''
  public bankAccount = ''

  public taxIds: Array<TaxModel> = []
  public agreements: Array<AgreementModel> = []
  public notes: Array<NoteModel> = []
  public files: Array<FileModel> = []

  constructor (props: Partial<ISalesUser> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'status':
          case 'id':
          case 'crmId':
          case 'isBusiness':
          case 'enablePayments':
            props[p] = parseInt(props[p] as string)
            break
          case 'address':
            props[p] = new Address(props[p])
            break
          case 'taxIds': {
            const list = []
            for (const i in props[p]) {
              list.push(new TaxModel(props[p][i]))
            }
            props[p] = list
            break
          }
          case 'agreements': {
            const list = []
            for (const i in props[p]) {
              list.push(new AgreementModel(props[p][i]))
            }
            props[p] = list
            break
          }
          case 'notes': {
            const list = []
            for (const i in props[p]) {
              list.push(new NoteModel(props[p][i]))
            }
            props[p] = list
            break
          }
          case 'files': {
            const list = []
            for (const i in props[p]) {
              list.push(new FileModel(props[p][i]))
            }
            props[p] = list
            break
          }
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public toJSON () {
    return {
      ...this,
      landline: this.landline?.replace(/[^0-9]/g, ''),
      cell: this.cell?.replace(/[^0-9]/g, ''),
      fax: this.fax?.replace(/[^0-9]/g, '')
    }
  }

  public get statusLabel () {
    return SalesUser.statii[this.status]
  }

  public get fullName () {
    return this.firstName + ' ' + this.lastName
  }

  public get displayName () {
    return this.isBusiness ? this.companyName : this.fullName
  }

  public get detailName () {
    return (this.isBusiness ? '(' + this.companyName + ')' : '') + this.fullName
  }
}
