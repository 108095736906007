
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import StatementReportForm from '@/modules/finance/components/forms/reports/Statements.vue'
import PaymentReportForm from '@/modules/finance/components/forms/reports/Payments.vue'
import UnbilledReportForm from '@/modules/finance/components/forms/reports/Unbilled.vue'
import AniReportForm from '@/modules/finance/components/forms/reports/ANI.vue'
import BillingRegisterReportForm from '@/modules/finance/components/forms/reports/BillingRegister.vue'
import InvoiceOpenBalanceReportForm from '@/modules/finance/components/forms/reports/InvoiceOpenBalance.vue'
import { IStringIndexed } from '@/modules/shared/types'
import AgingDetailParametersForm from '../../components/forms/reports/AgingDetail.vue'
import AgingCustomerParametersForm from '../../components/forms/reports/AgingCustomer.vue'
import GlFeedParametersForm from '../../components/forms/reports/GlFeed.vue'
import PrepaymentsParametersForm from '../../components/forms/reports/Prepayments.vue'

@Component
export default class Reports extends Vue {
  @Prop({ default: '' })
  private reportName!: string

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Action', align: 'center', width: 50, sortable: false, value: 'action' }
  ]

  private reports = [
    { name: 'Aging - Customer', permission: 'FINANCE.REPORTS.AGING', component: AgingCustomerParametersForm },
    { name: 'Aging - Invoice Detail', permission: 'FINANCE.REPORTS.AGING', component: AgingDetailParametersForm },
    { name: 'ANI', permission: 'FINANCE.REPORTS.ANI', component: AniReportForm },
    { name: 'Billing Register', permission: 'FINANCE.REPORTS.BILLINGREGISTER', component: BillingRegisterReportForm },
    { name: 'Invoice Open Balance', permission: 'FINANCE.REPORTS.ANI', component: InvoiceOpenBalanceReportForm },
    { name: 'Cash Receipts', permission: 'FINANCE.REPORTS.PAYMENTS', component: PaymentReportForm },
    { name: 'GL Feed', permission: 'FINANCE.REPORTS.GLFEED', component: GlFeedParametersForm },
    { name: 'P2C Statements', permission: 'FINANCE.REPORTS.STATEMENTS', component: StatementReportForm },
    { name: 'Prepayments', permission: 'FINANCE.PREPAYMENTS.VIEW', component: PrepaymentsParametersForm },
    { name: 'Unbilled', permission: 'FINANCE.REPORTS.UNBILLED', component: UnbilledReportForm }
  ];

  private reportSearch: string = ''

  private get filteredReports () {
    return this.reports.filter(r => this.itemViewable(r))
  }

  private loadReport (item: IStringIndexed) {
    this.$router.push({ name: 'financereportviewer', params: { reportName: item.name } }).catch(() => {})
  }

  private itemViewable (item: IStringIndexed) : boolean {
    return item.permission ? AuthState.user.isAllowed(item.permission) : true
  }

  private itemViewedClass (item: IStringIndexed): string {
    if (item.name === this.reportName) {
      return 'blue lighten-3'
    }
    return ''
  }
}
