





























































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import dtExporter from '@/modules/shared/datatablehelper'
import { IStringIndexed } from '@/modules/shared/types'
import DataServicesReportsApi from '@/modules/dataservices/api/reports'

@Component({
  components: {}
})
export default class TransactionListFilterForm extends Vue {
  private errorMessage = ''
  private noErrors = true

  private params = {
    from: moment().subtract(5, 'day').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    customerName: ''
  }

  private menu = {
    from: false,
    to: false
  }

  private asOf = ''
  private loading = false

  public async handleSearch () {
    this.errorMessage = ''
    this.$emit('searchSubmit', this.params)
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
