




import '@/plugins/hooks.ts'
import { Component, Vue } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import AppLayout from '@/views/AppLayout.vue'
import BareLayout from '@/views/BareLayout.vue'
import EventBus from '@/plugins/eventbus'

@Component({
  name: 'App',
  components: {
    'app-layout': AppLayout,
    'bare-layout': BareLayout
  }
})
export default class extends Vue {
  private updateInProgress: boolean = false
  private serviceWorkerRegistration: ServiceWorkerRegistration|null = null

  public get layout () {
    return AppState.layout + '-layout'
  }

  private handleAppUpdate (e: Event) {
    const newReg = (e as CustomEvent).detail as ServiceWorkerRegistration
    if (!this.serviceWorkerRegistration || newReg.waiting !== null) {
      this.serviceWorkerRegistration = newReg
    }

    if (this.layout !== 'app-layout') {
      this.postMessage()
    } else {
      EventBus.$emit('app-snack', { message: 'CIS has a new version, click Update to install', color: 'red', timeout: -1, action: this.postMessage, actionText: 'Update', snackProps: { elevation: 24, centered: true, rounded: true } })
    }
  }

  private postMessage () {
    if (this.serviceWorkerRegistration && this.serviceWorkerRegistration.waiting) {
      this.serviceWorkerRegistration.waiting.postMessage('skipWaiting')
      return
    }

    window.location.reload()
  }

  private updateApplication () {
    if (this.updateInProgress) {
      return
    }

    this.updateInProgress = true
    window.location.reload()
  }

  public created () {
    document.addEventListener('applicationUpdated', this.handleAppUpdate, { once: true })

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', this.updateApplication)
    }
  }
}
