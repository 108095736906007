import { Note as BaseNote, INote as IBaseNote } from '@/modules/shared/models/note'

export interface ICustomerNote extends IBaseNote {
  customerId: number;
}

export class Note extends BaseNote implements ICustomerNote {
  public customerId = 0

  constructor (props?: Partial<ICustomerNote>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
