






















import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import Customer from '../../models/customer'
import { File } from '../../../shared/models/file'
import { TaxExemptForm, ITaxExemptForm } from '../../models/customer/taxexemptform'
import TaxExemptFormDetail from '../../components/blocks/TaxExemptFormDetail.vue'
import TaxExemptFormList from '../../components/blocks/TaxExemptFormList.vue'

@Component({
  components: {
    'tax-exempt-form': TaxExemptFormDetail,
    'tax-exempt-form-list': TaxExemptFormList
  }
})
export default class TaxExemptForms extends Vue {
  private errorMessage = ''

  private showSelectedFile = false

  public get customerTaxExemptForms (): ITaxExemptForm[] {
    return this.customer.taxExemptForms
  }

  public get forms (): TaxExemptForm[] {
    return this.customer.taxExemptForms
  }

  public get customer (): Customer {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  private showFormDetail (selectedForm: TaxExemptForm) {
    this.$router.push({ name: 'taxexemptformdetail', params: { taxExemptFormId: selectedForm.id.toString() } })
  }
}
