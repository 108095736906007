













import { Component, Vue, Prop } from 'vue-property-decorator'
import { Address } from '../models/address'

@Component({})
export default class AddressLink extends Vue {
  @Prop({ type: Address, default: new Address() })
  readonly address!: Address

  @Prop({ type: Boolean, default: true })
  readonly showIcon!: boolean
}
