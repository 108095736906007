import { Base } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'
import { ListBase } from '@/modules/shared/lists'

export enum AgreementStatiiEnum {
  ['STATUS_NEW'] = 0,
  ['STATUS_ACTIVE'] = 1,
  ['STATUS_SUSPENDED'] = 2,
  ['STATUS_PARTNER_TERMED'] = 3,
  ['STATUS_ACTUAL_TERMED'] = 4
}

export class AgreementStatii extends ListBase {
  readonly [AgreementStatiiEnum.STATUS_NEW] = 'New'
  readonly [AgreementStatiiEnum.STATUS_ACTIVE] = 'Active'
  readonly [AgreementStatiiEnum.STATUS_SUSPENDED] = 'Suspended'
  readonly [AgreementStatiiEnum.STATUS_PARTNER_TERMED] = 'Terminated by Partner'
  readonly [AgreementStatiiEnum.STATUS_ACTUAL_TERMED] = 'Terminated by Actual Energy'
}

export interface ISalesUserAgreement extends IStringIndexed {
  id: number;
  salesId: number;
  signedOn: string;
  version: string;
  fileId: number;
  status: keyof AgreementStatii;
  terminatedOn: string;
}

export default class SalesUserAgreement extends Base implements ISalesUserAgreement {
  public static statii = new AgreementStatii()

  public id = 0
  public salesId = 0
  public status = AgreementStatiiEnum.STATUS_NEW
  public fileId = 0
  public signedOn = ''
  public version = ''
  public terminatedOn = ''

  constructor (props: Partial<ISalesUserAgreement> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'status':
          case 'id':
          case 'salesId':
          case 'fileId':
            props[p] = parseInt(props[p] as string)
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get statusLabel () {
    return SalesUserAgreement.statii[this.status]
  }
}
