





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import { AccountInvoice } from '@/modules/customers/models/account/invoice'
import { InvoiceLineItem } from '@/modules/customers/models/account/invoice/lineitem'
import { FinanceUsageResettlementSummary, UsageResettlement } from '../../models/usageresettlement'

@Component({
  components: {
  }
})
export default class ResettledBucketCompare extends Vue {
  @Prop({ required: true })
  private usageResettlement!: FinanceUsageResettlementSummary

  private lineItemHeaders: Array<any> = [
    { text: 'Description', align: 'left', sortable: true, value: 'lineItem.label' },
    { text: 'Net Charged', align: 'right', sortable: true, value: 'lineItem.total' },
    { text: 'Resettled', align: 'right', sortable: true, value: 'resettlePrice' },
    { text: '$ Diff', align: 'right', sortable: true, value: 'resettleDelta' }
  ]

  private rowSelected (item: InvoiceLineItem) {
    return item.id === this.usageResettlement.id ? 'primary lighten-3' : ''
  }

  private mounted () {
  }
}
