import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse } from 'axios'
import { IStringDictionary } from '@/modules/shared/types'
import { File as FileModel } from '@/modules/shared/models/file'
import SettlementFile from '../models/settlement'

const baseUrl = 'settlement-files'

export default class SettlementFiles {
  public static async list (filters?: IStringDictionary<any>): Promise<Array<SettlementFile>> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + filtersToQs(filters) + '&limit=50')
      if (response.status === 200 && response.data.files) {
        const objs: Array<SettlementFile> = []
        for (const f of response.data.files) {
          objs.push(new SettlementFile(f))
        }
        return objs
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      console.log(err)
      return checkMessageOrThrow(err)
    }
  }

  public static async get (id: Number): Promise<FileModel> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + '/' + id)

      if (response.status === 200 && response.data.file) {
        response.data.file.name = response.data.file.name.replace('.gz', '')
        return new FileModel(response.data.file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
