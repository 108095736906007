


























































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { Contract } from '@/modules/customers/models/customer/contract'

@Component
export default class AccountOverview extends Vue {
  @Prop({ required: true })
  private account!: ElectricAccount

  @Prop()
  private contract!: Contract

  private get canEditAccount () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.EDIT')
  }

  public mounted () {
  }
}
