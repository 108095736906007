import { Base } from './base'
import { ListsEnum } from '@/modules/shared/enums'
import { AppState } from '@/stores/appStore'
import { IStringIndexed } from '../types'

export interface INote extends IStringIndexed {
  categoryId?: number;
  note: string;
}

export class Note extends Base implements INote {
  public id: number = 0
  public categoryId = 0
  public note = ''

  constructor (props?: Partial<INote>) {
    super(props)

    if (props) {
      Object.assign(this, props)
    }
  }

  public get categories () {
    return AppState.listsByName[ListsEnum.NOTE_CATEGORIES]
  }

  public get categoryLabel (): string {
    const cats = this.categories
    if (cats) {
      return cats[this.categoryId]
    }
    return ''
  }
}
