import esalesClient from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance } from 'axios'
import { Note } from '@/modules/customers/models/customer/note'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'

export class NoteApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['customers', 'notes']
    this.singular = 'note'
    this.plural = 'notes'
    this.factory = (...args: any) => new Note(...args)
  }

  public async loadAll (customerId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<Note>> {
    return await this.loadAllSpecific(arguments, config) as Array<Note>
  }

  public async load (customerId: number, id: number, onlyProps?: Array<string>): Promise<Note> {
    return await this.loadSpecific(arguments, onlyProps) as Note
  }

  public async save (customerId: number, note: Note) : Promise<Note> {
    const data: IStringIndexed = { ...note }

    return await this.saveSpecific(arguments, data) as Note
  }

  public async delete (note: Note) {
    return await this.deleteSpecific([note.customerId, note.id])
  }
}

export const NotesApi = new NoteApi(esalesClient)
