









































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { IStringIndexed } from '@/modules/shared/types'
import { Transaction } from '@/modules/customers/models/account/transaction'
import TransactionDetail from '@/modules/customers/components/blocks/serviceaccount/detail/TransactionDetail.vue'
import TransactionProcessLog from '@/modules/customers/components/blocks/serviceaccount/detail/TransactionProcessLog.vue'
import { TransactionsApi } from '@/modules/customers/api/transactions'
import { EsgTransactionStatusEnum } from '@/modules/shared/enums'

@Component({
  components: {
    'transaction-detail': TransactionDetail,
    'transaction-processlog': TransactionProcessLog
  }
})
export default class TransactionList extends Vue {
  @Prop({ required: true })
  private account!: IAccount

  private searchText = ''

  private errorMessage = ''
  private loading = false

  private transactions: Array<Transaction> = []

  private dialog: boolean = false
  private dialogComponent = 'transaction-request'
  private dialogProps: IStringIndexed = {}
  private componentProps: IStringIndexed = {}

  private headers: Array<any> = [
    { text: 'ID', align: 'left', width: 100, sortable: false, value: 'id' },
    { text: 'Transaction Date', width: 200, align: 'center', sortable: true, value: 'date' },
    { text: 'Action ID', width: 110, align: 'left', sortable: true, value: 'actionId' },
    { text: 'Transaction ID', width: 350, align: 'left', sortable: true, value: 'transactionId' },
    { text: 'Detail', align: 'left', sortable: true, value: 'keyMessage' },
    { text: 'Actions', align: 'center', width: 100, sortable: false, value: 'actions' }
  ]

  @Watch('account.id', { immediate: true })
  protected handleAccountChange () {
    this.loadTransactions()
  }

  protected async loadTransactions () {
    try {
      this.loading = true

      this.transactions = await TransactionsApi.loadAll(this.account.id)
    } catch (err) {
      EventBus.$emit('app-snack', { message: err })
    } finally {
      this.loading = false
    }
  }

  private async handleRowClick (item: Transaction) {
    if (item) {
      try {
        this.handleDialog({
          component: 'transaction-detail',
          props: {
            title: 'Transaction Detail: ' + item.transactionId,
            loadDetails: { accountId: this.account.id, transId: item.id },
            isDialog: true
          }
        })
      } catch (err) {
        EventBus.$emit('app-snack', { message: err })
      }
    }
  }

  public mounted () {
  }

  private handleDialog (dialogConfig: IStringIndexed) {
    this.dialogComponent = dialogConfig.component
    this.componentProps = dialogConfig.props || {}
    this.dialogProps = dialogConfig.dialogProps || { maxWidth: '50%', minHeight: '80%' }

    this.dialog = true
  }

  private handleCloseDialog () {
    this.dialog = false
    this.dialogComponent = ''
  }

  private handleViewProcessingLog (item: Transaction) {
    this.handleDialog({
      component: 'transaction-processlog',
      dialogProps: {
        width: '100%'
      },
      props: {
        title: 'Processing Log',
        transaction: item,
        isDialog: true
      }
    })
  }

  private rowClass (item: Transaction) {
    if (item.status >= EsgTransactionStatusEnum.ERROR_GENERAL) {
      return 'red lighten-3'
    }
    if (item.status === EsgTransactionStatusEnum.PARTIAL) {
      return 'yellow lighten-5'
    }

    if (item.status === EsgTransactionStatusEnum.PROCESSED) {
      return ''
    }

    return 'info lighten-5'
  }
}
