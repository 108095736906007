import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText, FontAwesomeIconProps } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)

library.add(fas)
library.add(far)

const FAIcons = {
  complete: 'check',
  cancel: 'times-circle',
  close: 'times',
  delete: ['fas', 'trash-alt'],
  clear: ['far', 'times-circle'],
  success: 'check-circle',
  info: 'info-circle',
  warning: 'exclamation',
  error: 'exclamation-triangle',
  prev: 'chevron-left',
  next: 'chevron-right',
  checkboxOn: 'check-square',
  checkboxOff: ['far', 'square'],
  checkboxIndeterminate: 'minus-square',
  delimiter: 'circle',
  sort: 'sort-up',
  expand: 'chevron-down',
  menu: 'bars',
  subgroup: 'caret-down',
  dropdown: 'caret-down',
  radioOn: ['far', 'dot-circle'],
  radioOff: ['far', 'circle'],
  edit: ['fas', 'pencil-alt'],
  ratingEmpty: ['far', 'star'],
  ratingFull: 'star',
  ratingHalf: 'star-half',
  loading: 'sync',
  first: 'step-backward',
  last: 'step-forward',
  unfold: 'arrows-alt-v',
  attachment: 'paperclip',
  plus: 'plus',
  minus: 'minus',
  // Custom
  up: ['far', 'arrow-alt-circle-up'],
  down: ['far', 'arrow-alt-circle-down'],
  forward: ['far', 'arrow-alt-circle-right'],
  back: 'arrow-left',
  users: 'users',
  search: 'search',
  envelope: ['fas', 'envelope'],
  hourglasshollow: ['far', 'hourglass'],
  userplus: 'user-plus',
  useredit: 'user-edit',
  usercircle: ['far', 'user-circle'],
  usercog: 'user-cog',
  userscog: 'users-cog',
  userdelete: 'user-minus',
  signin: 'sign-in-alt',
  signout: 'sign-out-alt',
  team: 'sitemap',
  chart: 'chart-bar',
  market: 'chart-line',
  billing: 'file-invoice-dollar',
  dataservices: 'satellite-dish',
  finance: 'money-check-alt',
  collections: 'poo-storm',
  commissions: 'comments-dollar',
  atlas: 'atlas',
  salesusers: 'user-tag',
  cisusers: 'user-tie',
  admin: 'user-shield',
  wholesale: 'atom',
  itusers: 'jedi',
  impersonate: ['far', 'clone'],
  folderclosed: ['fas', 'folder'],
  group: 'object-group',
  version: 'code-branch',
  link: 'link',
  externallink: 'external-link-alt',
  business: ['far', 'building'],
  industrial: 'industry',
  user: 'user',
  dollar: 'dollar-sign',
  payments: 'cash-register',
  statement: 'receipt',
  pdf: 'file-pdf',
  pay: 'money-bill-alt',
  creditcard: 'credit-card',
  void: 'ban',
  reversepmt: 'history',
  communication: 'satellite-dish',
  applypmt: 'project-diagram',
  dispute: 'head-side-cough',
  canceldispute: 'head-side-cough-slash',
  hu: 'tachometer-alt',
  gaac: ['fas', 'random'],

  fulfillmentqueue: 'mail-bulk',
  docmanager: 'file-code',
  editdocument: 'edit',
  file: 'file-alt',
  view: 'eye',

  report: ['far', 'list-alt'],
  import: 'file-upload',
  export: 'file-download',

  queue: 'list',
  split: 'share-alt',
  enroll: 'play-circle',
  drop: 'stop-circle',

  key: 'key',
  classify: 'sort-amount-down-alt',
  source: 'map-marker-alt',
  owner: 'user-tag',
  address: ['far', 'address-card'],
  phone: 'phone',
  pen: 'pen',
  retry: 'redo-alt',
  userbadge: ['far', 'id-badge'],
  contact: ['fas', 'address-book'],
  activity: ['fas', 'calendar-alt'],
  month: 'calendar',
  measuretime: 'stopwatch',
  notes: ['fas', 'comments'],
  note: ['far', 'comment'],
  meter: 'charging-station',
  opportunity: 'dice',
  retention: 'door-open',
  quote: 'search-dollar',
  files: ['fas', 'folder-open'],
  feed: 'rss',
  contract: 'file-contract',
  contractsigned: 'file-signature',
  additionalmenu: 'ellipsis-v',
  tasks: 'tasks',
  mapmarker: 'map-marker-alt',
  electricity: 'bolt',
  gas: 'burn',
  residential: 'home',
  inperson: 'handshake',
  followup: 'user-clock',
  title: 'briefcase',
  cell: 'mobile-alt',
  fax: 'fax',
  businessphone: 'pager',
  clock: ['far', 'clock'],
  activityadd: 'calendar-plus',
  activityday: 'calendar-day',
  activitytime: 'clock',
  noteadd: 'comment-medical',
  duration: 'history',
  undelete: 'trash-restore-alt',
  upload: 'file-upload',
  filter: 'filter',
  utility: 'atom',
  help: ['far', 'question-circle'],
  label: 'tag',
  hold: ['far', 'pause-circle'],
  unhold: ['far', 'play-circle'],
  transactions: ['fas', 'exchange-alt'],
  usagecancel: 'undo',
  usagerebill: 'redo',
  usagecanceled: 'unlink'
}

const FAIconsParsed = (() => {
  // Patch in the fa-svg icons until https://github.com/vuetifyjs/vuetify/pull/8092 is merged
  const icons = Object.entries(FAIcons)
  const component = 'fa-icon'
  const payload = {}

  for (const [key, icon] of icons) {
    (<any>payload)[key] = {
      component,
      props: {
        icon
      }
    }
  }

  return payload
})()

export default FAIconsParsed
