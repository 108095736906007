
import { Component } from 'vue-property-decorator'
import { Contact } from '@/modules/customers/models/customer/contact'
import EventBus from '@/plugins/eventbus'
import NamebarBase from './Base.vue'

@Component({
  components: {
  }
})
export default class CommunicationNamebar extends NamebarBase {
  protected pageIcon = 'communication'
  protected pageTitle = 'Communication'
  protected actionIcon = ''
  protected actionTitle = ''

  public mounted () {

  }
}
