


















import { Component, Vue, PropSync } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import Rules from '@/plugins/validations'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import { AppState } from '@/stores/appStore'
import { UserGroupsEnum } from '@/modules/shared/enums'
import { ContractSubmission } from '../../models/queue/contractsubmission'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class CSQAssignStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  private valRules: Rules = Rules
  private noErrors: boolean = true

  public get assignableUsers () {
    if (AuthState.user.isAllowed('CUSTOMER_MGMT.CSQ.ASSIGN')) {
      const users = Object.values(AppState.cisUsers).filter(u => u.groupIds.indexOf(UserGroupsEnum.CONTRACT_ADMIN) !== -1)
      return users.map(u => { return { value: u.id, text: u.lastName + ', ' + u.firstName } })
    }
    return [{ value: AuthState.user.id, text: AuthState.user.lastName + ', ' + AuthState.user.firstName }]
  }

  private mounted () {
  }
}
