import { Base, IBaseModel } from '@/modules/shared/models/base'
import { BankAccountTypes } from '@/modules/shared/lists'

export interface IBankAccount extends IBaseModel {
  id: number;
  customerId: number;
  accountType: keyof BankAccountTypes;
  bankName: string;
  accountHolder: string;
  accountNumber: string;
  routingNumber: string;
}

export class BankAccount extends Base implements IBankAccount {
  public static accountTypes = new BankAccountTypes()

  public id = 0
  public customerId = 0
  public accountType = 0
  public bankName = ''
  public accountHolder = ''
  public accountNumber = ''
  public routingNumber = ''

  constructor (props?: Partial<IBankAccount>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p as string) {
          case 'id':
          case 'customerId':
          case 'accountType':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get accountTypeLabel (): string {
    return BankAccount.accountTypes[this.accountType]
  }
}
