











































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { TransactionRequestsApi } from '@/modules/customers/api/transactionrequests'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import ScheduleTransactionField from './ScheduleTransactionField.vue'
import EnrollmentFields from './EnrollmentFields.vue'
import HistoricUsageFields from './HistoricalUsageFields.vue'
import ChangeFields from './ChangeFields.vue'
import { TransactionRequestStatusEnum } from '@/modules/shared/enums'
import { AuthState } from '@/modules/auth/store'
import moment from 'moment'
import DropFields from './DropFields.vue'

@Component({
  components: {
    'transaction-scheduler': ScheduleTransactionField,
    'enrollment-fields': EnrollmentFields,
    'historicusage-fields': HistoricUsageFields,
    'change-fields': ChangeFields,
    'drop-fields': DropFields
  }
})
export default class TransactionRequestForm extends Vue {
  @Prop({ default: false })
  private isDialog!: boolean

  @Prop({ required: true })
  public request!: TransactionRequest

  @Prop({ required: true })
  public account!: IAccount

  private localRequest: TransactionRequest = new TransactionRequest()

  private loading: boolean = false
  private saving: boolean = false
  private readOnly: boolean = false
  private errorMessage = ''

  private noErrors = true

  private get transactionType () {
    return TransactionRequest.getTransactionTypeName(this.localRequest.transactionType)
  }

  private handleClose () : void {
    this.errorMessage = ''
    this.$emit('dialog:close')
  }

  private handleDelete () : void {
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Delete',
        message: '<b>Are you sure you want to delete this EDI transaction request?</b><br />',
        onConfirm: this.confirmDelete
      }
    })
  }

  private async confirmDelete () {
    try {
      this.errorMessage = ''

      this.localRequest.status = TransactionRequestStatusEnum.CANCELLED
      this.localRequest.error = 'Canceled by ' + AuthState.user.fullname
      const respItem = await TransactionRequestsApi.savePartial(this.localRequest, ['status', 'error'])
      this.$emit('request:updated', respItem)
      this.handleClose()

      EventBus.$emit('app-snack', {
        color: 'green',
        message: 'Transaction Request Deleted',
        timeout: 5000
      })
    } catch (err) {
      this.errorMessage = err
    }
  }

  private async handleSubmit () : Promise<void> {
    this.saving = true
    this.errorMessage = ''

    try {
      if (!this.localRequest.id && (!this.localRequest.scheduledAt || moment().isSameOrAfter(moment(this.localRequest.scheduledAt, 'YYYY-MM-DD')))) {
        this.localRequest.status = TransactionRequestStatusEnum.READY
      }

      const transRequest = await TransactionRequestsApi.save(this.account.id, this.localRequest)

      this.$emit('request:' + (this.localRequest.id ? 'updated' : 'created'), transRequest)

      EventBus.$emit('app-snack', {
        color: 'green',
        message: 'Transaction Request ' + (this.localRequest.id ? 'Updated' : 'Created'),
        timeout: 5000
      })
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  private mounted () {
    this.localRequest = this.request.clone()
  }
}
