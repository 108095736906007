import { Base, IBaseModel } from '@/modules/shared/models/base'
import { CustomerPaymentApplicationTypeEnum, CustomerPaymentApplicationStatusEnum } from '@/modules/shared/enums'
import { CustomerPaymentApplicationTypes, CustomerPaymentApplicationStatuses } from '@/modules/shared/lists'

export interface ICustomerPaymentApplication extends IBaseModel {
  id: number;
  paymentId: number;
  relatedApplicationId: number|null;
  invoiceId: number|null;
  type: keyof CustomerPaymentApplicationTypes;
  status: keyof CustomerPaymentApplicationStatuses;
  glId: number|null;
  applicationDate: string;
  amount: number;
  referenceNumber: string;
}

export class CustomerPaymentApplication extends Base implements ICustomerPaymentApplication {
  public static types = new CustomerPaymentApplicationTypes()
  public static statii = new CustomerPaymentApplicationStatuses()

  public id = 0
  public paymentId = 0
  public relatedApplicationId = null
  public invoiceId: number|null = null
  public type = CustomerPaymentApplicationTypeEnum.PAYMENT
  public status = CustomerPaymentApplicationStatusEnum.PENDING
  public glId = null;
  public applicationDate = ''
  public amount = 0
  public referenceNumber = ''

  public relatedApplication: CustomerPaymentApplication|undefined

  constructor (props?: Partial<ICustomerPaymentApplication>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'paymentId':
          case 'invoiceId':
          case 'relatedApplicationId':
          case 'type':
          case 'status':
          case 'glId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'amount':
            props[p] = parseFloat(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get typeLabel () {
    return CustomerPaymentApplication.types[this.type]
  }

  public get statusLabel () {
    return CustomerPaymentApplication.statii[this.status]
  }

  public get isReversible () {
    if (this.type === CustomerPaymentApplicationTypeEnum.PAYMENT && this.status !== CustomerPaymentApplicationStatusEnum.REVERSED) {
      return true
    }
    if (this.type === CustomerPaymentApplicationTypeEnum.REFUND) {
      if (!this.relatedApplication || this.relatedApplication.type !== CustomerPaymentApplicationTypeEnum.REVERSAL) {
        return true
      }
    }

    return false
  }
}
