


























































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import Customer from '@/modules/customers/models/customer'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import CustomerApi from '@/modules/customers/api/customer'
import AccountsApi from '@/modules/customers/api/accounts'
import { Contract } from '@/modules/customers/models/customer/contract'
import { TransactionRequestStatusEnum, TransactionRequestTypeEnum, UserGroupsEnum } from '@/modules/shared/enums'
import { Note } from '@/modules/shared/models/note'
import TransactionList from '@/modules/dataservices/components/queues/TransactionList.vue'
import EditCustomerInfo from '@/modules/customers/components/forms/CustomerInfo.vue'
import EditCustomerNote from '@/modules/customers/components/forms/Note.vue'
import EditServiceAccountForm from '@/modules/customers/components/forms/ServiceAccount.vue'
import DialogHost from '@/modules/shared/mixins/DialogHost.vue'
import ConfirmDialog from '@/modules/shared/components/ConfirmDialog.vue'
import { TransactionRequestsApi } from '@/modules/customers/api/transactionrequests'
import TransactionRequestOverview from './queues/TransactionRequestOverview.vue'
import CustomerOverview from './queues/CustomerOverview.vue'
import AccountOverview from './queues/AccountOverview.vue'
import CustomerNotes from './queues/CustomerNotes.vue'
import AssignItemForm from './queues/AssignItemForm.vue'
import TransactionRequestForm from '@/modules/customers/components/forms/serviceaccount/TransactionRequestForm.vue'
import { AuthState } from '@/modules/auth/store'

@Component({
  components: {
    'request-overview': TransactionRequestOverview,
    'customer-overview': CustomerOverview,
    'account-overview': AccountOverview,
    'customer-notes': CustomerNotes,
    'transaction-list': TransactionList,
    'assign-worker': AssignItemForm,
    'edit-customer-info': EditCustomerInfo,
    'edit-customer-note': EditCustomerNote,
    'edit-service-account': EditServiceAccountForm,
    'edit-transaction': TransactionRequestForm,
    'confirm-dialog': ConfirmDialog
  }
})
export default class HuQueueItem extends Mixins(DialogHost) {
  @Prop({ required: true })
  private item!: TransactionRequest

  private customer: Customer = new Customer()
  private contract: Contract = new Contract()
  private account: ElectricAccount = new ElectricAccount()

  private get assignPermissionName () {
    return 'DATA_SERVICES.QUEUES.HISTORICUSAGE.ASSIGN'
  }

  private get dataservicesGroupId () {
    return UserGroupsEnum.DATA_SERVICES
  }

  private handleEditTransaction () {
    this.handleDialog({
      component: 'edit-transaction',
      dialogProps: {
        width: '50%'
      },
      props: {
        request: this.item.clone(),
        account: this.account,
        isDialog: true
      }
    })
  }

  private handleSendHu () {
    this.handleDialog({
      component: 'edit-transaction',
      dialogProps: {
        width: '50%'
      },
      props: {
        request: new TransactionRequest({
          transactionType: TransactionRequestTypeEnum.HU,
          serviceAccountId: this.account.id,
          details: {
            source: this.item.details.source,
            crm: this.item.details.crm,
            customer: this.item.details.customer,
            contract: this.item.details.contract
          }
        }),
        account: this.account,
        isDialog: true
      }
    })
  }

  private async handleVoidAccount () {
    this.handleDialog({
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      props: {
        title: 'Confirm Void',
        message: '<b>Are you sure you want to mark this account as Bad?</b><br />This action cannot be undone. It removes the account from all opportunities and quotes',
        onConfirm: () => alert('This account has not been voided yet - Please contact IT to finish')
      }
    })
  }

  private async handleCancelItem () {
    this.handleDialog({
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      props: {
        title: 'Confirm Item Removal',
        message: 'Are you sure you want to remove this item from the queue?',
        onConfirm: this.cancelItem
      }
    })
  }

  private async cancelItem () {
    try {
      const saveItem = this.item.clone()
      saveItem.status = TransactionRequestStatusEnum.CANCELLED
      saveItem.error = 'Removed by ' + AuthState.user.fullname
      this.$emit('item:finalized', await TransactionRequestsApi.savePartial(saveItem, ['status', 'error']))
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private handleCustomerUpdate (customer: Customer) {
    this.customer = customer
  }

  private handleAccountUpdate (account: ElectricAccount) {
    this.account = account
  }

  private handleNoteUpdate (note: Note) {
    this.customer.notes.unshift(note)
  }

  private handleItemEdited (newItem: TransactionRequest) {
    this.$emit('item:updated', newItem)
  }

  private async handleRequeued (newItem: TransactionRequest) {
    try {
      const saveItem = this.item.clone()
      saveItem.status = TransactionRequestStatusEnum.FINALIZED
      this.$emit('item:finalized', await TransactionRequestsApi.savePartial(saveItem, ['status']))
      this.$emit('item:created', newItem)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private handleEditCustomer () {
    this.handleDialog({
      component: 'edit-customer-info',
      dialogProps: {
        width: 'auto'
      },
      props: {
        customer: this.customer
      }
    })
  }

  private handleEditAccount () {
    this.handleDialog({
      component: 'edit-service-account',
      dialogProps: {
        width: 'auto'
      },
      props: {
        account: this.account,
        customerId: this.customer.id,
        customerType: this.customer.type
      }
    })
  }

  private handleAddNote () {
    this.handleDialog({
      component: 'edit-customer-note',
      dialogProps: {
        width: 'auto'
      },
      props: {
        note: new Note({ customerId: this.customer.id }),
        noteList: this.customer.notes,
        saveMethod: 'SaveNote'
      }
    })
  }

  @Watch('item.id', { immediate: true, deep: true })
  private async handleItemChange () {
    if (this.item.id) {
      if (this.item.details.source === 'CIS') {
        this.customer = await CustomerApi.load(this.item.details.customer.id, ['notes'])
        this.contract = this.customer.contracts.find(c => c.id === this.item.details.contract.id)!
      } else {
        this.customer = this.item.details.customer
        this.contract = this.item.details.contract
      }
      this.account = await AccountsApi.load(this.item.accountId) as ElectricAccount
    }
  }
}
