import esalesClient from '@/api/clients/esales'
import { AxiosInstance } from 'axios'
import ModelApi, { RequestConfig, RequestParam } from '@/api/model'
import { MeterRead } from '../models/utility/meterread'

export class MeterReadApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['meterreads']
    this.singular = 'read'
    this.plural = 'reads'
    this.factory = (...args: any) => new MeterRead(...args)
  }

  public async load (id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<MeterRead> {
    return await this.loadSpecific(arguments, onlyProps, extraProps) as MeterRead
  }

  public async loadAll (filters?: RequestConfig): Promise<Array<MeterRead>> {
    if (!filters) {
      filters = {}
    }
    return await this.loadAllSpecific(arguments, filters) as Array<MeterRead>
  }
}

export const MeterReadsApi = new MeterReadApi(esalesClient)
