























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import EventBus from '@/plugins/eventbus'
import AccountOverviews from './invoice/AccountOverviews.vue'
import { AccountInvoiceSummary } from '../../models/account/summary'
import { CustomerInvoice } from '../../models/customer/invoice'
import { AccountInvoice } from '../../models/account/invoice'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { INumericDictionary } from '@/modules/shared/types'
import InvoiceHourlyApi from '../../api/invoicehourly'
import XLSX from 'xlsx'
import { ChargesEnum } from '@/modules/shared/enums'

@Component({
  components: {
    'account-overviews': AccountOverviews
  }
})
export default class CustomerInvoiceDetail extends Vue {
  @Prop({ required: true })
  private invoiceId!: number

  @Prop({ default: () => 0 })
  private selectedAccountId!: number

  private selectedAccount!: AccountInvoiceSummary
  private processingList: INumericDictionary<string> = {}

  private accountInvoicesHeaders: Array<any> = [
    { text: 'Account ID', align: 'center', sortable: false, value: 'accountId' },
    { text: 'Account #', align: 'left', sortable: true, value: 'accountNumber' },
    { text: 'Net Consumption', align: 'right', sortable: true, value: 'netConsumption' },
    { text: 'Commodity', align: 'right', sortable: true, value: 'commodityTotal' },
    { text: 'Other', align: 'right', sortable: true, value: 'otherTotal' },
    { text: 'Taxes', align: 'right', sortable: true, value: 'taxTotal' },
    { text: 'Total', align: 'right', sortable: true, value: 'invoiceTotal' },
    { text: 'Open Bal.', align: 'right', sortable: true, value: 'openBalance' }
  ]

  private get canViewCharges () {
    return AuthState.user.isAllowed('CUSTOMER.INVOICES.ACCOUNT.VIEW')
  }

  private get canDisputeInvoice () {
    return AuthState.user.isAllowed('CUSTOMER.INVOICES.ACCOUNT.DISPUTE')
  }

  public async handleDispute (invoice: AccountInvoice) {
    try {
      Vue.set(this.processingList, invoice.id, 'disputing')
      await CustomerState.DisputeAccountInvoice({ invoice: this.invoice, accountInvoice: invoice })
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id)
    }
  }

  public async handleCancelDispute (invoice: AccountInvoice) {
    try {
      Vue.set(this.processingList, invoice.id, 'disputing')
      await CustomerState.CancelDisputeAccountInvoice({ invoice: this.invoice, accountInvoice: invoice })
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id)
    }
  }

  public async handleDownloadHourly (invoice: CustomerInvoice) {
    try {
      Vue.set(this.processingList, invoice.id, 'download')
      const hours = await InvoiceHourlyApi.loadAll(invoice.customerId, invoice.id)

      const workbook = XLSX.utils.book_new()
      for (const transId in hours) {
        const dataSheet = XLSX.utils.json_to_sheet(hours[transId])
        const matching = hours[transId][0].active === 1

        // nice-to-have: add a sum below every numeric column, (and freeze top row)
        // const sumsRow = hours[transId].length + 2
        // todo: why dont these work: XLSX.utils.sheet_set_array_formula(dataSheet, 'B' + sumsRow, 'SUM(B2:B' + (sumsRow - 1) + ')')
        // XLSX.utils.sheet_set_array_formula(dataSheet, 'B770', 'SUM(B2:B769)')

        // remove active col / alternatively, filter out of json before creating sheet
        // const lastCol = String.fromCharCode('A'.charCodeAt(0) + (hours[transId][0].length - 1))
        // const newlastCol = String.fromCharCode('A'.charCodeAt(0) + (hours[transId][0].length - 2))
        // if (dataSheet['!ref'] !== undefined) {
        //   dataSheet['!ref'] = dataSheet['!ref'].replace(lastCol, newlastCol)
        // }

        XLSX.utils.book_append_sheet(workbook, dataSheet, transId + (matching ? '' : '-DISCREP'))
      }
      XLSX.writeFile(workbook, 'hourly-' + invoice.invoiceNumber + '.xlsx')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, invoice.id)
    }
  }

  private get invoice () {
    const inv = CustomerState.customer.invoices.find(i => i.id === this.invoiceId)
    if (inv) {
      return inv
    }
    return new CustomerInvoice({ invoiceNumber: 'Not Found' })
  }

  private get accountInvoiceSummaries () {
    // Get invoice and summarize into AccountInvoiceSummary by accountId
    const summaries: AccountInvoiceSummary[] = []
    this.invoice.invoices.forEach((invoice: AccountInvoice) => {
      const acct = summaries.find((a: AccountInvoiceSummary) => a.accountId === invoice.accountId)
      if (acct) {
        acct.commodityTotal = (acct.commodityTotal || 0) + invoice.commodityTotal
        acct.otherTotal = (acct.otherTotal || 0) + invoice.otherTotal
        acct.taxTotal = (acct.taxTotal || 0) + invoice.taxTotal
        acct.invoiceTotal = (acct.invoiceTotal || 0) + invoice.invoiceTotal
        acct.openBalance = (acct.openBalance || 0) + invoice.openBalance
        if (invoice.parentLineItem?.chargeId === ChargesEnum.ENERGY) {
          acct.netConsumption = (acct.netConsumption || 0) + invoice.netConsumption
        }
        acct.invoices!.push(invoice)
      } else {
        const item: AccountInvoiceSummary = {
          accountId: invoice.accountId,
          accountNumber: this.getAccountNumber(invoice),
          customerInvoiceId: invoice.customerInvoiceId,
          commodityTotal: invoice.commodityTotal,
          otherTotal: invoice.otherTotal,
          taxTotal: invoice.taxTotal,
          invoiceTotal: invoice.invoiceTotal,
          openBalance: invoice.openBalance,
          netConsumption: invoice.netConsumption,
          invoices: []
        }
        item.invoices!.push(invoice)
        summaries.push(item)
      }
    })
    return summaries
  }

  public processingAction (invoice: AccountInvoice) {
    return this.processingList[invoice.id] || false
  }

  public rowClasses (item: AccountInvoice) {
    let rowClass = ''

    if (item.voidDate) {
      rowClass += ' text-decoration-line-through'
    }

    return rowClass
  }

  private getAccountNumber (invoice: AccountInvoice) {
    const acct = CustomerState.customer.accounts.find((a: IAccount) => a.id === invoice.accountId)
    if (acct) {
      return acct.accountNumber
    }
    return ''
  }

  @Watch('invoiceId')
  private handleInvoiceChangeView (newId: number, oldId: number) {
    if (newId !== oldId && this.canViewCharges && this.invoice.invoices.length === 1) {
      this.selectedAccount = this.accountInvoiceSummaries[0]
    }
  }

  private mounted () {
    if (this.canViewCharges) {
      if (this.selectedAccountId !== 0) {
        this.selectedAccount = this.accountInvoiceSummaries.find((a: AccountInvoiceSummary) => a.accountId === this.selectedAccountId)!
      } else {
        this.selectedAccount = this.accountInvoiceSummaries[0]
      }
    }
  }

  private rowSelected (item: AccountInvoiceSummary) {
    return item.accountId === this.selectedAccount.accountId ? 'primary lighten-3' : ''
  }

  private handleClickRow (item: AccountInvoiceSummary) {
    this.selectedAccount = item
  }
}
