import { AppState } from '@/stores/appStore'
import { Base, IBaseModel } from '../base'
import { UoMEnum } from '../../enums'
import { UoM } from '../../lists'
import moment from 'moment'

export interface IMeterRead extends IBaseModel {
  id: number;
  accountId: number;
  meterId: number|null;
  lineItemId: number|null;
  senderTransactionId: string;
  meterNumber: string;
  startDate: string;
  endDate: string;
  quantity: number;
  unitId: keyof UoM;
  isCancel: number;
  isInterval: number;
  isHistorical: number;
  p2cId: string;
}

export class MeterRead extends Base implements IMeterRead {
  public id = 0;
  public accountId = 0;
  public meterId = null;
  public lineItemId = null;
  public senderTransactionId = '';
  public meterNumber = '';
  public startDate = '';
  public endDate = '';
  public quantity = 0;
  public unitId = UoMEnum.KWH;
  public isCancel = 0;
  public isInterval = 0;
  public isHistorical = 0;
  public p2cId = '';

  constructor (props?: Partial<IMeterRead>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'accountId':
          case 'meterId':
          case 'lineItemId':
          case 'unitId':
          case 'isCancel':
          case 'isInterval':
          case 'isHistorical':
            if (props[p]) {
              props[p] = parseInt(props[p]! as string)
            }
            break
          case 'quantity':
            props[p] = parseFloat(props[p]!.toString())
            break
          case 'meterNumber':
            if (!props[p]) {
              props[p] = ''
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get start () {
    return moment(this.startDate, 'YYYY-MM-DD')
  }

  public get end () {
    return moment(this.endDate, 'YYYY-MM-DD')
  }

  public cancels (priorRead: MeterRead): boolean {
    return this.couldApplyTo(priorRead) &&
      priorRead.isCancel === 0 &&
      this.isCancel === 1
  }

  public rebills (priorRead: MeterRead): boolean {
    return this.couldApplyTo(priorRead) &&
      priorRead.isCancel === 0 &&
      this.isCancel === 0
  }

  // reuse filters
  protected couldApplyTo (priorRead: MeterRead): boolean {
    return this.isHistorical === 0 &&
      priorRead.isHistorical === 0 &&
      priorRead.createdAt < this.createdAt &&
      priorRead.startDate === this.startDate &&
      priorRead.meterNumber === this.meterNumber
  }
}
