








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import ServiceAccountInfo from '@/modules/customers/components/blocks/serviceaccount/detail/Info.vue'
import UsageChart from '@/modules/customers/components/blocks/serviceaccount/detail/UsageChart.vue'
import MeterReadList from '@/modules/customers/components/blocks/serviceaccount/detail/MeterReadList.vue'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { CommodityEnum } from '@/modules/shared/enums'
import AccountFactory from '@/modules/shared/models/accountfactory'
import ServiceAccountPeriodDetails from './serviceaccount/detail/PeriodDetails.vue'
import TransactionList from './serviceaccount/detail/TransactionList.vue'

@Component({
  components: {
    'account-info': ServiceAccountInfo,
    'usage-chart': UsageChart,
    'meter-reads': MeterReadList,
    'transaction-list': TransactionList,
    'period-details': ServiceAccountPeriodDetails
  }
})
export default class ServiceAccountDetail extends Vue {
  private tabs = 0
  private usageLoading = false
  private transactionsLoading = false

  @Prop({ required: true })
  protected accountId!: number

  public handleReset (): void {
    this.$emit('dialog:close')
  }

  private get account () {
    if (!this.accountId) {
      this.$router.push({ name: 'customeraccounts' }).catch(() => {})
    }
    const acct = CustomerState.customer.accounts.find(a => {
      return a.id === this.accountId
    })

    if (!acct) {
      return AccountFactory.getAccount(CommodityEnum.E)
    }
    return acct
  }

  private get customer () {
    return CustomerState.customer
  }

  @Watch('account', { immediate: true, deep: true })
  private async handleAccountIdChange (newAcct: IAccount, oldAcct: IAccount) {
    // Make sure it's a non-zero acct id, or "immediate" is being used
    if (newAcct.id && (!oldAcct || newAcct.id !== oldAcct.id!)) {
      try {
        this.usageLoading = true
        await CustomerState.LoadUsageHistory(newAcct)
        this.transactionsLoading = true
        await CustomerState.LoadTransactions(newAcct)
      } catch (err) {
        console.log(err)
        EventBus.$emit('app-snack', {
          message: err
        })
      } finally {
        this.usageLoading = false
        this.transactionsLoading = false
      }
    }
  }
}
