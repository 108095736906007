const rootNav = {
  key: 'root',
  width: 225
}

export default [
  {
    path: '/user/profile',
    name: 'profile',
    components: {
      default: () => import(/* webpackChunkName: "profile" */ '../pages/Profile.vue')
    },
    title: 'Update Profile',
    meta: { requiresAuth: true }
  },
  {
    name: 'usersroot',
    path: '/users',
    components: {
      SideNav: () => import(/* webpackChunkName: "cisusers" */ '../components/sidenavs/Root.vue'),
      default: () => import(/* webpackChunkName: "cisusers" */ '../pages/UsersRoot.vue')
    },
    title: 'Users',
    meta: {
      requiresAuth: 'USERS.VIEW'
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'usersmanage',
    path: '/users/manage',
    components: {
      SideNav: () => import(/* webpackChunkName: "cisusers" */ '../components/sidenavs/Root.vue'),
      default: () => import(/* webpackChunkName: "cisusers" */ '../pages/Users.vue')
    },
    title: 'Manage Users',
    meta: {
      requiresAuth: 'USERS.VIEW',
      navs: {
        root: {
          icon: 'billing'
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'usergroups',
    path: '/users/groups',
    components: {
      SideNav: () => import(/* webpackChunkName: "cisusers" */ '../components/sidenavs/Root.vue'),
      default: () => import(/* webpackChunkName: "cisusers" */ '../pages/Groups.vue')
    },
    title: 'Manage User Groups',
    meta: {
      requiresAuth: 'USERS.GROUPS.VIEW',
      navs: {
        root: {
          icon: 'billing'
        }
      }
    },
    props: {
      nav: rootNav
    }
  }
]
