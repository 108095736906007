import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import CustomerModel from '@/modules/customers/models/customer'
import { Method, AxiosResponse } from 'axios'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'

export default class CustomerApi {
  public static async load (id: number, additional?: Array<string>): Promise<CustomerModel> {
    try {
      let url = 'customers/' + id + '?additional[]=contacts&additional[]=billGroups&additional[]=contracts&additional[]=accountIds&additional[contracts][]=commissions&additional[]=taxExemptForms'
      if (additional) {
        for (const a of additional) {
          url += '&additional[]=' + a
        }
      }
      const response: AxiosResponse = await esalesClient.get(url)
      if (response.status === 200) {
        if (response.data.customer.contacts) {
          response.data.customer.contacts = Object.values(response.data.customer.contacts)
        }
        if (response.data.customer.billGroups) {
          response.data.customer.billGroups = Object.values(response.data.customer.billGroups)
        }
        if (response.data.customer.contracts) {
          response.data.customer.contracts = Object.values(response.data.customer.contracts)
        }
        if (response.data.customer.bankaccounts) {
          response.data.customer.bankaccounts = Object.values(response.data.customer.bankaccounts)
        }
        if (response.data.customer.taxExemptForms) {
          response.data.customer.taxExemptForms = Object.values(response.data.customer.taxExemptForms)
        }
        return new CustomerModel(response.data.customer)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadAll (filters?: IStringDictionary<any>): Promise<Array<CustomerModel>> {
    try {
      let query = ''
      let postGet = false
      if (filters) {
        if (Array.isArray(filters.id) && filters.id.length > 5) {
          postGet = true
        } else {
          query = filtersToQs(filters)
        }
      }

      let response: AxiosResponse
      if (postGet) {
        response = await esalesClient.request({
          url: 'customers',
          method: <Method> 'POST',
          headers: {
            'X-HTTP-Method-Override': 'GET'
          },
          data: { filters: filters }
        })
      } else {
        response = await esalesClient.get('customers/' + query)
      }

      if (response.status === 200 && response.data.results) {
        const custs: Array<CustomerModel> = []

        response.data.results.forEach((i: Partial<CustomerModel>) => {
          custs.push(new CustomerModel(i))
        })

        return custs
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async save (customer: CustomerModel, includeProps: Array<string> = []): Promise<CustomerModel> {
    try {
      let method = 'POST'
      if (customer.id) {
        method = 'PUT'
      }

      const data = CustomerModel.convertToBasic(customer, includeProps)

      const response: AxiosResponse = await esalesClient.request({
        url: 'customers' + (customer.id > 0 ? '/' + customer.id : ''),
        method: <Method> method,
        data: data
      })

      if (response.status === 200 && response.data.customer) {
        return new CustomerModel(response.data.customer)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async savePartial (customer: CustomerModel, saveProps: Array<string>) {
    try {
      const reqObj: IStringIndexed = {}

      for (const p of saveProps) {
        reqObj[p] = customer[p]
      }

      const response: AxiosResponse = await esalesClient.request({
        url: 'customers' + (customer.id > 0 ? '/' + customer.id : ''),
        method: <Method> 'PATCH',
        data: reqObj
      })

      if (response.status === 200 && response.data.customer) {
        return new CustomerModel(response.data.customer)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
