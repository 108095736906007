




































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { StatesAbbrevType, StateAbbreviationsEnum, CommodityEnum, CustomerTypeEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import Rules from '@/plugins/validations'
import { File as FileModel } from '@/modules/shared/models/file'
import FilesApi from '@/modules/customers/api/files'
import { Commodities } from '../../../../shared/lists'
import XLSX from 'xlsx'
import { INumericIndexed, IStringIndexed, IStringDictionary, INumericDictionary } from '../../../../shared/types'
import { UtilityFieldConfig } from '../../../../shared/models/utility/fieldconfig'
import AccountFactory from '../../../../shared/models/accountfactory'
import { IAccount } from '../../../../shared/models/interfaces/account'

@Component
export default class AccountImportForm extends Vue {
  private errorMessage = ''
  private saving = false
  private stage = 1
  private processingUpload = false
  private processingProgress = 0
  private importedAccounts: IStringIndexed = {}
  private commodity: keyof Commodities = CommodityEnum.E
  private state: StatesAbbrevType = CustomerState.customer.address.state!
  private utilityId = 0
  private valRules: Rules = Rules
  private utilitySearch = ''
  private utilRules: IStringDictionary<Array<Function>> = {}
  private columns: Array<string> = ['accountNumber', 'meterNumber', 'keyName', 'street', 'street2', 'city', 'zip', 'plus4']

  private headers = [
    { text: 'Row #', value: 'row', align: 'center' },
    { text: 'Account #', value: 'accountNumber', align: 'left' },
    { text: 'Meter #', value: 'meterNumber' },
    { text: 'Keyname', value: 'keyname' },
    { text: 'Address', value: 'address', sortable: false },
    { text: 'Status', value: 'errors', align: 'center' }
  ]

  private savingHeaders = [
    { text: 'Row #', value: 'row', align: 'center' },
    { text: 'Account #', value: 'accountNumber', align: 'left' },
    { text: 'Status', value: 'errors', align: 'center' },
    { text: '', value: 'data-table-expand' }
  ]

  private expanded = []

  private commodities = mapObjectVuetifySelect(new Commodities(), true)
  private states = mapObjectVuetifySelect(AppState.servedStates)

  public clearUtilitySearch () {
    this.$nextTick(() => {
      this.utilitySearch = ''
    })
  }

  @Watch('state')
  private handleStateChange () {
    const byState = CustomerState.accountsByUtility.filter(i => i.state === this.state)
    if (byState.length === 1) {
      this.utilityId = byState[0].id
    }
  }

  @Watch('utilityId')
  private handleUtilityChange () {
    this.resetParsing()

    if (!this.utilityId) {
      return
    }

    const customerType = (CustomerState.customer.type === CustomerTypeEnum.LARGE_COMM || CustomerState.customer.type === CustomerTypeEnum.MED_COMM) ? CustomerTypeEnum.LARGE_COMM : CustomerTypeEnum.RESI
    const key = customerType + '-' + this.commodity

    const fieldConfig = AppState.utilities[this.utilityId].configs[key].fields

    for (const col of this.columns) {
      if (!fieldConfig[col]) {
        continue
      }

      this.utilRules[col] = []

      if (fieldConfig[col].required) {
        this.utilRules[col].push(Rules.required(undefined, fieldConfig[col].label + ' is required'))
      }

      if (AppState.utilities[this.utilityId].validations[this.commodity as number] &&
        AppState.utilities[this.utilityId].validations[this.commodity as number][col]
      ) {
        AppState.utilities[this.utilityId].validations[this.commodity as number][col].forEach((r: { regex: string; message: string }) => {
          this.utilRules[col].push(Rules.regex(r.regex, r.message))
        })
      }
    }

    this.utilRules.street = [Rules.required(undefined, 'Address is required')]
    this.utilRules.city = [Rules.required(undefined, 'City is required')]
    this.utilRules.zip = [Rules.required(undefined, 'Zipcode is required')]
  }

  public get utilityList () {
    const list: Array<{header?: string; divider?: boolean; text?: string; value?: any}> = []

    const byUtil = CustomerState.accountsByUtility
    if (byUtil) {
      list.push({ header: 'Current' })
    }

    for (const g of byUtil) {
      if (g.commodities.includes(this.commodity as number) &&
        AppState.utilities[g.id].state === this.state
      ) {
        list.push({
          text: AppState.utilities[g.id].displayName,
          value: g.id
        })
      }
    }

    if (list.length) {
      list.push({ divider: true })
    }

    const byState = []

    for (const id in AppState.utilities) {
      if ((AppState.utilities[id].commodity & this.commodity as number) &&
        AppState.utilities[id].state === this.state &&
        !list.find(i => i.value && i.value.toString() === id)
      ) {
        byState.push({
          text: AppState.utilities[id].displayName,
          value: id
        })
      }
    }

    if (byState.length) {
      list.push({ header: 'State' })
    }

    byState.sort((a, b) => a.text.localeCompare(b.text))
    list.push(...byState)

    return list
  }

  private get importedAccountsArray () {
    return Object.values(this.importedAccounts)
  }

  private processFileSelection () {
    this.resetParsing()

    const chosenFiles: FileList = (this.$refs.fileInput as HTMLInputElement).files!

    if (chosenFiles.length === 0) {
      return
    }

    for (let i = 0; i < chosenFiles.length; i++) {
      this.readFile(chosenFiles[i])
    }
  }

  private readFile (f: File) {
    const reader = new FileReader()

    reader.onloadstart = (e) => {
      this.processingUpload = true
    }

    reader.onprogress = (e) => {
      if (!e.lengthComputable) {
        return
      }

      this.processingProgress = Math.round((e.loaded / e.total) * 100)
    }

    reader.onloadend = (e) => {
      this.processingUpload = false
      this.parseSpreadsheet(reader.result as string)
    }

    reader.readAsBinaryString(f)
  }

  private parseSpreadsheet (contents: string) {
    try {
      const wb = XLSX.read(contents, { type: 'binary' })
      const ws = wb.Sheets[wb.SheetNames[0]]
      const rows = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false })
      const expectedHeaders = ['ACCOUNT #', 'METER #', 'KEY NAME', 'ADDRESS LINE 1', 'ADDRESS LINE 2', 'CITY', 'ZIP CODE', '+4']
      const headers = rows.shift() as Array<string>

      if (expectedHeaders.toString() !== headers.toString()) {
        this.errorMessage = 'Unexpected template format'
        return
      }

      this.stage = 2

      for (const idx in rows) {
        const r = rows[idx] as INumericIndexed

        if (!r[0].toString().trim().length) {
          continue
        }

        const item: IStringIndexed = {
          row: +idx + 1,
          accountNumber: r[0],
          meterNumber: r[1] ? r[1] : '',
          keyName: r[2] ? r[2] : '',
          street: r[3] ? r[3] : '',
          street2: r[4] ? r[4] : '',
          city: r[5] ? r[5] : '',
          zip: r[6] ? r[6] : '',
          plus4: r[7] ? r[7] : '',
          hasErrors: false,
          errors: {} as IStringIndexed,
          saving: false,
          savingStatus: 0,
          savingErrors: [] as Array<string>,
          savingWarnings: [] as Array<string>
        }

        for (const col of this.columns) {
          item.errors[col] = this.validateField(col, item[col].toString())
          if (item.errors[col].length) {
            item.hasErrors = true
          }
        }
        Vue.set(this.importedAccounts, item.accountNumber, item)
      }
    } catch (err) {
      this.errorMessage = 'Unable to process file, please double check that it\'s a valid Excel file and try again'
    }
  }

  private validateField (name: string, value: string) {
    const msgs: Array<string> = []

    if (!this.utilRules[name]) {
      return msgs
    }

    for (const v of this.utilRules[name]) {
      const res = v(value)
      if (res !== true) {
        msgs.push(res)
      }
    }

    return msgs
  }

  public async handleSubmit () {
    if (this.saving) {
      return
    }

    this.saving = true
    this.stage = 3
    this.errorMessage = ''

    const saveAccounts = Object.values(this.importedAccounts).filter(i => !i.hasErrors)

    await Promise.all(saveAccounts.map(a => {
      a.saving = true
      a.savingStatus = 0
      a.savingErrors = []
      a.savingWarnings = []

      const singleAccount = AccountFactory.getAccount(this.commodity)
      singleAccount.accountNumber = a.accountNumber
      singleAccount.meterNumber = a.meterNumber
      singleAccount.keyName = a.keyName
      singleAccount.utilityId = this.utilityId
      singleAccount.address.street = a.street
      singleAccount.address.street2 = a.street2
      singleAccount.address.city = a.city
      singleAccount.address.state = this.state
      singleAccount.address.zip.code = a.zip
      singleAccount.address.zip.plus4 = a.plus4
      singleAccount.isImported = true

      return CustomerState.SaveAccount({ customerId: CustomerState.customer.id, customerType: CustomerState.customer.type, saveAccount: singleAccount })
        .then((result: { account: IAccount; warnings: Array<string> }) => {
          if (result.warnings.length) {
            a.savingStatus = 2
            a.savingWarnings = result.warnings
          } else {
            a.savingStatus = 1
          }
        })
        .catch((err: Error) => {
          a.savingStatus = 3
          if (err.name === '422') {
            for (const f in err.message as unknown as IStringIndexed) {
              for (const n in err.message[f as any] as unknown as Array<string>) {
                a.savingErrors.push(err.message[f as any][n])
              }
            }
            this.errorMessage = 'Please review the Errors and retry'
          } else {
            this.errorMessage = err.message
          }
        })
        .finally(() => {
          a.saving = false
        })
    }))

    this.saving = false
    if (!this.errorMessage && !Object.values(this.importedAccounts).some(i => i.savingStatus !== 1)) {
      this.handleClose()
      this.resetParsing()
    }
  }

  public handleClose (): void {
    this.$emit('dialog:close')
  }

  private resetParsing () {
    Vue.set(this, 'importedAccounts', {})
    this.errorMessage = ''
  }

  public mounted () {
    if (this.utilityList.length === 1) {
      this.utilityId = this.utilityList[0].value
    } else if (CustomerState.accountsByUtility) {
      const byState = CustomerState.accountsByUtility.filter(i => i.state === this.state)
      byState.sort((a, b) => {
        if (a.accounts.length === b.accounts.length) {
          return a.name.localeCompare(b.name)
        }

        if (a.accounts.length > b.accounts.length) {
          return 1
        }
        return 0
      })
      if (byState.length) {
        this.utilityId = byState[0].id
      }
    }
  }
}
