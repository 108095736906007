import { AppState } from '@/stores/appStore'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { TaxExemptItemCchReasons, TaxExemptItemCchTypes, TaxExemptItemStatuses } from '@/modules/shared/lists'

export interface ITaxExemptForm extends IBaseModel {
  id: number;
  customerId: number;
  userId: number|null;
  fileId: number|null;
  status: number;
  effective: string;
  expires: string;
  percentExempt: number;
  cchTypes: Array<string>;
  cchReason: string;
  serviceAccounts: Array<number>;
}

export class TaxExemptForm extends Base implements ITaxExemptForm {
  public static statuses = new TaxExemptItemStatuses()
  public static cchReasonList = new TaxExemptItemCchReasons()
  public static cchTypeList = new TaxExemptItemCchTypes()

  public id = 0
  public customerId = 0
  public userId: number|null = null
  public status = 0
  public fileId: number|null = 0
  public effective = ''
  public expires = ''
  public percentExempt = 0.00
  public cchTypes: Array<string> = []
  public cchReason = ''
  public serviceAccounts: Array<number> = []

  constructor (props?: Partial<TaxExemptForm>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'customerId':
          case 'userId':
          case 'status':
          case 'fileId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get statusLabel () {
    return TaxExemptForm.statuses[this.status] || ''
  }

  public get cchReasonLabel () {
    return TaxExemptForm.cchReasonList[this.cchReason] || ''
  }

  public getCchTypeLabel (type: string) {
    return TaxExemptForm.cchTypeList[type] || ''
  }

  public get cchTypesLabels () {
    return this.cchTypes.map(r => this.getCchTypeLabel(r))
  }

  public set assignedTo (to: number) {
    this.userId = to
  }

  public get assignedTo () {
    return this.userId || 0
  }

  public get assignedToName () {
    if (!this.assignedTo) {
      return ''
    }
    return AppState.cisUsers[this.assignedTo]?.detailName || ''
  }
}
