


































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'

@Component
export default class TransactionRequestOverview extends Vue {
  @Prop({ required: true })
  private request!: TransactionRequest

  public mounted () {
  }
}
