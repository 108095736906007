import { AxiosInstance } from 'axios'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { Transaction } from '@/modules/customers/models/account/transaction'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import ModelApi from '@/api/model'
import esalesClient from '@/api/clients/esales'
import { ElectricAccount } from '@/modules/shared/models/account/electric'

export class TransactionRequestApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['serviceaccounts', 'transactionrequests']
    this.singular = 'request'
    this.plural = 'requests'
    this.factory = (...args: any) => new TransactionRequest(...args)
  }

  public async loadAll (accountId: number, config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<TransactionRequest>> {
    return await this.loadAllSpecific(arguments, config) as Array<TransactionRequest>
  }

  public async load (accountId: number, id: number, onlyProps?: Array<string>): Promise<TransactionRequest> {
    return await this.loadSpecific(arguments, onlyProps) as TransactionRequest
  }

  public async save (accountId: number, transactionRequest: TransactionRequest) : Promise<TransactionRequest> {
    const data: IStringIndexed = { ...transactionRequest }

    return await this.saveSpecific([transactionRequest.accountId, transactionRequest.id], data) as TransactionRequest
  }

  public async savePartial (item: TransactionRequest, props: Array<string>) : Promise<TransactionRequest> {
    const data: IStringIndexed = { ...item }
    return await this.savePartialSpecific([item.accountId, item.id], data, props) as TransactionRequest
  }
}

export const TransactionRequestsApi = new TransactionRequestApi(esalesClient)
