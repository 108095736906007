





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import { AccountInvoice } from '@/modules/customers/models/account/invoice'
import { InvoiceLineItem } from '@/modules/customers/models/account/invoice/lineitem'

@Component({
  components: {
  }
})
export default class AccountInvoiceLineItems extends Vue {
  @Prop({ required: true })
  private invoice!: AccountInvoice

  @Prop({ default: () => 0 })
  private selectedItemId!: number

  private lineItemHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Description', align: 'left', sortable: true, value: 'label' },
    { text: 'Meter #', align: 'left', sortable: true, value: 'meterNumber' },
    { text: 'Start', align: 'left', sortable: true, value: 'startDate' },
    { text: 'End', align: 'left', sortable: true, value: 'endDate' },
    { text: 'Quantity', align: 'left', sortable: true, value: 'quantity' },
    { text: 'Rate', align: 'center', sortable: true, value: 'rate' },
    { text: 'Total', align: 'right', sortable: true, value: 'total' }
  ]

  private getMeterNumber (item: InvoiceLineItem) {
    if (!this.account) {
      return ''
    }
    return this.account.reads.find(r => r.id === item.usageId)?.meterNumber
  }

  private get account () {
    return CustomerState.customer.accounts.find(a => a.id === this.invoice.accountId)
  }

  private rowSelected (item: InvoiceLineItem) {
    return item.id === this.selectedItemId ? 'primary lighten-3' : ''
  }

  private handleClickRow (item: InvoiceLineItem) {
    this.$emit('item:select', item)
  }

  private mounted () {
    if (this.account && !this.account?.reads.length) {
      CustomerState.LoadSummaryReads(this.account)
    }
  }
}
