

















































































































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import Rules from '@/plugins/validations'
import { AppState } from '@/stores/appStore'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import { ElectricAccount } from '@/modules/shared/models/account/electric'

@Component
export default class CSQCreateStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  @Prop({ required: true })
  private accounts!: Array<ElectricAccount>

  @Prop({ default: false })
  private creating!: boolean

  private valRules: Rules = Rules
  private noErrors: boolean = true
  private errorMessage: string = ''

  private getAccounts (groupId: number) {
    if (!this.accounts.length) {
      return [{
        id: -1,
        accountNumber: 'Loading...'
      }]
    }
    const bgMap = this.localItem.customerShell?.accounts.map(a => a.periodDetails[0].billGroupId === groupId ? a.id : null).filter(a => a !== null)

    return this.accounts.filter(a => bgMap!.includes(a.id) || false)
  }

  private mounted () {
  }
}
