var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"color":"inherit","background-color":"inherit"},attrs:{"headers":_vm.lineItemHeaders,"items":_vm.usageResettlement.bucketsCompare,"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"loading-text":"Loading...please wait","hide-default-footer":true,"item-class":_vm.rowSelected},scopedSlots:_vm._u([{key:"item.lineItem.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.lineItem.total))+" ")]}},{key:"item.lineItem.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US').format(item.lineItem.quantity))+" ")]}},{key:"item.resettlePrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.resettlePrice))+" ")]}},{key:"item.resettleDelta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.resettleDelta))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }