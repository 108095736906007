import esalesClient, { filtersToQs, checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { TransactionRequest } from '@/modules/customers/models/account/transactionrequest'
import { TransactionRequestsApi } from '@/modules/customers/api/transactionrequests'

export class TransactionRequestQueueApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['dataservices-transaction-requests']
    this.singular = 'request'
    this.plural = 'requests'
    this.factory = (...args: any) => new TransactionRequest(...args)
  }

  public async loadAll (config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<{ total: number, items: Array<TransactionRequest> }> {
    if (!config) {
      config = {
        additional: {
          0: 'account'
        },
        include: { account: ['accountNumber'] }
      }
    }
    return await this.loadAllSpecific(arguments, config) as { total: number, items: Array<TransactionRequest> }
  }

  public async load (id: number, onlyProps?: Array<string>): Promise<TransactionRequest> {
    return await this.loadSpecific(arguments, onlyProps) as TransactionRequest
  }

  public async save (item: TransactionRequest, noteText?: string) : Promise<TransactionRequest> {
    const data: IStringIndexed = { ...item }

    if (noteText) {
      data.note = {
        source: 'CIS',
        body: noteText
      }
    }

    const args = Array.from(arguments).slice(0, -1)

    return await this.saveSpecific(args, data) as TransactionRequest
  }

  public async savePartial (item: TransactionRequest, props: Array<string>) : Promise<TransactionRequest> {
    const data: IStringIndexed = { ...item }

    return await this.savePartialSpecific([item.id], data, props) as TransactionRequest
  }

  public async finalize (item: TransactionRequest, type: string, note?: string) : Promise<TransactionRequest> {
    const data: IStringIndexed = { ...item }
    data.finalized = type

    const props = ['id', 'step', 'customerShell', 'finalized']

    if (note) {
      data.note = { body: note }
      props.push('note')
    }

    return await this.savePartialSpecific([item.id], data, props) as TransactionRequest
  }
}

export const TransactionRequestsQueueApi = new TransactionRequestQueueApi(esalesClient)
