import Login from '../pages/Login.vue'
import Logout from '../pages/Logout.vue'

export default [
  {
    path: '/login',
    name: 'login',
    components: {
      default: Login
    },
    title: 'Login'
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    title: 'Logout'
  }
]
