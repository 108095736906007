





































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import EventBus from '@/plugins/eventbus'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import moment from 'moment'
import commissionApi from '@/modules/finance/api/commissions'
import dtExporter from '@/modules/shared/datatablehelper'
import { FinanceCommissionSummary, IFinanceCommissionSummary } from '@/modules/finance/models/commission'

import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import SalesPersonModel from '@/modules/sales/models/salesuser'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class PayCommissions extends Vue {
  private errorMessage = ''
  private noErrors = true

  private paidType = '0'
  private targetUser = 0
  private state = []

  private showAllFields = false
  private saving = false
  private loading = false
  private loaded = false

  private menu = {
    targetCycle: false,
    payPeriod: false
  }

  private yesNoOptions = [{ text: 'Yes', value: '0' }, { text: 'No', value: '1' }]
  private states = mapObjectVuetifySelect(AppState.servedStates)

  private targetCycle = moment().format('YYYY-MM-01')
  private payPeriod = moment().format('YYYY-MM-01')
  private includeDisabled = '1'
  private invoiceList = ''
  private accountList = ''

  private headers: Array<IStringIndexed> = [
    { text: 'Sales ID', align: 'left', sortable: true, value: 'salesId', width: 100 },
    { text: 'Sales Name', align: 'left', sortable: true, value: 'salesName' },

    { text: 'Period', align: 'center', sortable: true, value: 'period', width: 120, exportType: 'date' },

    { text: 'Quantity (kWh)', align: 'right', sortable: true, value: 'quantity', width: 150, exportType: 'quantity' },
    { text: 'Amount ($)', align: 'right', sortable: true, value: 'amount', width: 150, exportType: 'money' }
  ]

  private resultsSearch = ''

  private commissions: Array<FinanceCommissionSummary> = []
  private selectedSummaries: Array<IFinanceCommissionSummary> = []

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('MMM DD, YYYY')
  }

  public get assignableSales () {
    return Object.values(SalesState.salesUsers).map((u: SalesPersonModel) => {
      return {
        text: u.fullName,
        value: u.id
      }
    })
  }

  private colorizeRow (comm: FinanceCommissionSummary) {
    if (!SalesState.salesUsers[comm.salesId].enablePayments) {
      return 'red lighten-4'
    } else {
      return ''
    }
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loaded = true
      this.loading = true
      this.commissions = []

      this.commissions = await commissionApi.loadUnpaid({
        to: this.targetCycle,
        salesId: this.targetUser,
        enablePayments: this.includeDisabled === '1' ? 0 : 1,
        invoiceList: this.invoiceList,
        accountList: this.accountList
      })

      Vue.set(this, 'selectedSummaries', [])
      this.commissions.forEach(c => {
        if (SalesState.salesUsers[c.salesId].enablePayments === 1) {
          this.selectedSummaries.push(c)
        }
      })
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private async handlePayComms () {
    try {
      this.saving = true

      const ids: Array<number> = []
      for (const s of this.selectedSummaries) {
        ids.push(...s.commissionIds)
      }

      await commissionApi.pay(this.payPeriod, ids)
      EventBus.$emit('app-snack', {
        message: 'Payments created successfully',
        color: 'green'
      })
      this.handleSearch()
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.saving = false
    }
  }

  private handleExportList () {
    dtExporter(this.headers, this.commissions, 'Commission Detail Export', 'Commission-Payable-' + this.targetCycle + '-' + moment().format('YYYYMMDD-HHmm'))
  }

  @Watch('targetCycle')
  private handleTargetCycleUpdate () {
    if ((this.targetCycle || '').length === 7) {
      this.targetCycle += '-01'
    }
  }

  @Watch('payPeriod')
  private handlePayPeriodUpdate () {
    if ((this.payPeriod || '').length === 7) {
      this.payPeriod += '-01'
    }
  }

  private beforeMount () {
    if (parseInt(moment().format('DD')) > 10) {
      this.targetCycle = moment().add(1, 'month').format('YYYY-MM-01')
      this.payPeriod = this.targetCycle
    }
    SalesState.UpdateUserList(true)
  }
}
