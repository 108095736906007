import { Base } from './../base'
import { IStringIndexed } from '../../types'

export interface ILossFactor extends IStringIndexed {
  id: number;
  utilityId: number;
  lossFactor: number;
  periodEnd: string;
  periodStart: string;
  snapshotStartDate: string;
  snapshotEndDate: string;
  voltage: string;
}

export class LossFactor extends Base implements ILossFactor {
  public id = 0
  public utilityId = 0
  public lossFactor = 1.0
  public periodStart = ''
  public periodEnd = ''
  public snapshotStartDate = ''
  public snapshotEndDate = ''
  public voltage = ''

  constructor (props?: Partial<ILossFactor>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'utilityId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'lossFactor':
            props[p] = parseFloat(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
