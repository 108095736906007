import { AppUserTypeEnum, CustomerTypeEnum, CommodityEnum, BillMethodEnum, CustomerPaymentTypeEnum, CustomerPaymentApplicationStatusEnum, AccountInvoiceStatusEnum, ChargeCategoryEnum, PaymentMethodEnum, UoMEnum, CustomerStatusEnum, CustomerAccountingStatusEnum, TaxCategoriesEnum, ServiceAccountStatusEnum, ServiceAccountHuStatusEnum, BankAccountTypeEnum, CustomerFlagEnum, CustomerPaymentApplicationTypeEnum, BillGroupDeliveryMethodEnum, PeriodDetailFlowStatusEnum, MarketDetailStatusEnum, CreditCheckApprovalEnum, CreditCheckStatusEnum, ContractSubmissionStatusEnum, InvoiceAssemblyTypeEnum, CustomerInvoiceStatusEnum, WelcomeLetterQueueStatusEnum, TransactionRequestTypeEnum, TransactionRequestStatusEnum, TaxExemptItemStatusEnum, TaxExemptItemCchReasonEnum, TaxExemptItemCchTypeEnum, UsageSourcesEnum, ResettlementBillableStatusEnum } from './enums'
import { IStringDictionary } from './types'

export class ListBase implements IStringDictionary<string> {
  [key: string]: string

  static types: any

  private static reverseMap: object | null = null

  static getType (type: string) {
    if (!this.reverseMap) {
      this.reverseMap = {}
      for (const [key, val] of Object.entries(new this())) {
        (<any> this.reverseMap)[val] = key
      }
    }

    if (!Object.hasOwnProperty.call(this.reverseMap, type)) {
      throw new Error('List does not contain value: ' + type)
    }

    return (<any> this.reverseMap)[type]
  }
}

export class AppUserTypes extends ListBase {
  readonly [AppUserTypeEnum.ADMIN] = 'Admin'
  readonly [AppUserTypeEnum.USERADMIN] = 'User Admin'
  readonly [AppUserTypeEnum.USER] = 'CIS User'
  readonly [AppUserTypeEnum.SALES] = 'Sales'
}

export class CustomerTypes extends ListBase {
  readonly [CustomerTypeEnum.LARGE_COMM] = 'Large Commercial'
  readonly [CustomerTypeEnum.MED_COMM] = 'Medium Commercial'
  readonly [CustomerTypeEnum.SMALL_COMM] = 'Small Commercial'
  readonly [CustomerTypeEnum.INDUSTRIAL] = 'Industrial'
  readonly [CustomerTypeEnum.RESI] = 'Residential'
}
export class CustomerFlags extends ListBase {
  readonly [CustomerFlagEnum.DISABLE_BILLING] = 'Disable Billing'
  readonly [CustomerFlagEnum.DISABLE_PAYMENTS] = 'Disable Auto Payments'
  readonly [CustomerFlagEnum.DISABLE_COLLECTIONS] = 'Disable Collection Notices'
  readonly [CustomerFlagEnum.DISABLE_LATE_FEES] = 'Disable Late Fees'
  readonly [CustomerFlagEnum.HOLD_APPROVALS] = 'Hold Approvals'
}

export class CustomerTypeToUtilityConfig {
  readonly [CustomerTypeEnum.LARGE_COMM] = CustomerTypeEnum.LARGE_COMM
  readonly [CustomerTypeEnum.MED_COMM] = CustomerTypeEnum.LARGE_COMM
  readonly [CustomerTypeEnum.SMALL_COMM] = CustomerTypeEnum.RESI
  readonly [CustomerTypeEnum.INDUSTRIAL] = CustomerTypeEnum.LARGE_COMM
  readonly [CustomerTypeEnum.RESI] = CustomerTypeEnum.RESI
}

export class CustomerStatuses extends ListBase {
  readonly [CustomerStatusEnum.NEW] = 'New'
  readonly [CustomerStatusEnum.RESTARTING] = 'Re-engaging'
  readonly [CustomerStatusEnum.ACTIVE] = 'Active'
  readonly [CustomerStatusEnum.INACTIVE] = 'Inactive'
}

export class CustomerAccountingStatuses extends ListBase {
  readonly [CustomerAccountingStatusEnum.CREDITREVIEW] = 'In Credit Review'
  readonly [CustomerAccountingStatusEnum.CURRENT] = 'Current'
  readonly [CustomerAccountingStatusEnum.PASTDUE] = 'Past Due'
  readonly [CustomerAccountingStatusEnum.COLLECTIONS] = 'In Collections'
  readonly [CustomerAccountingStatusEnum.CLOSED] = 'Closed'
}

export class TaxCategories extends ListBase {
  readonly [TaxCategoriesEnum.TAXCAT_RESIDENTIAL] = 'Residential'
  readonly [TaxCategoriesEnum.TAXCAT_COMMERCIAL] = 'Commercial'
  readonly [TaxCategoriesEnum.TAXCAT_INDUSTRIAL] = 'Industrial'
  readonly [TaxCategoriesEnum.TAXCAT_AGRICULTURAL] = 'Agricultural'
  readonly [TaxCategoriesEnum.TAXCAT_MANUFACTURING] = 'Manufacturer'
  readonly [TaxCategoriesEnum.TAXCAT_MOBILE_HOME] = 'Mobile Home'
  readonly [TaxCategoriesEnum.TAXCAT_HIGH_PEAK_DEMAND] = 'High Peak Demand'
  readonly [TaxCategoriesEnum.TAXCAT_SENIOR_CITIZEN] = 'Senior Citizen'
}

export class SearchScopes extends ListBase {
  readonly '0' = 'Mine'
  readonly '1' = 'Team'
  readonly '3' = 'Global'
}

export class TimezoneTypes extends ListBase {
  readonly 'America/Los_Angeles': 'Pacifc'
  readonly 'America/Denver': 'Mountain'
  readonly 'America/Chicago': 'Central'
  readonly 'America/New_York': 'Eastern'
}

export class Commodities extends ListBase {
  [key: number]: string
  readonly [CommodityEnum.E] = 'Electricity'
}

export class ServiceAccountStatuses extends ListBase {
  [key: number]: string
  readonly [ServiceAccountStatusEnum.NEW] = 'New'
  readonly [ServiceAccountStatusEnum.PENDING_ENROLLMENT] = 'Pending Enrollment'
  readonly [ServiceAccountStatusEnum.PENDING_START] = 'Pending Start'
  readonly [ServiceAccountStatusEnum.ACTIVE] = 'Flowing/Active'
  readonly [ServiceAccountStatusEnum.PENDING_DROP] = 'Pending Drop'
  readonly [ServiceAccountStatusEnum.INACTIVE] = 'Inactive/Dropped'
  readonly [ServiceAccountStatusEnum.BAD] = 'Invalid Account'
}

export class ServiceAccountHuStatuses extends ListBase {
  [key: number]: string
  readonly [ServiceAccountHuStatusEnum.INACTIVE] = 'Inactive'
  readonly [ServiceAccountHuStatusEnum.ACTIVE] = 'Active'
  readonly [ServiceAccountHuStatusEnum.ERROR] = 'Error'
}

export class MarketDetailStatuses extends ListBase {
  [key:number]: string
  readonly [MarketDetailStatusEnum.CURRENT] = 'Current'
  readonly [MarketDetailStatusEnum.HISTORIC] = 'Historic'
}

export class PeriodDetailFlowStatuses extends ListBase {
  [key:number]: string
  readonly [PeriodDetailFlowStatusEnum.NOT] = 'Not Flowing'
  readonly [PeriodDetailFlowStatusEnum.STARTED] = 'Started'
  readonly [PeriodDetailFlowStatusEnum.FLOWING] = 'Flowing'
  readonly [PeriodDetailFlowStatusEnum.STOPPED] = 'Stopped'
}

export class BillMethods extends ListBase {
  readonly [BillMethodEnum.DUAL] = 'Dual'
  readonly [BillMethodEnum.RATE_READY] = 'Rate Ready'
  readonly [BillMethodEnum.SUPPLIER_CONSOLIDATED] = 'Supplier Consolidated'
  readonly [BillMethodEnum.BILL_READY] = 'Bill Ready'
}

export class BillGroupDeliveryMethods extends ListBase {
  readonly [BillGroupDeliveryMethodEnum.EMAIL] = 'E-mail'
  readonly [BillGroupDeliveryMethodEnum.FAX] = 'Fax'
  readonly [BillGroupDeliveryMethodEnum.MAIL] = 'Mail'
}

export class InvoiceAssemblyTypes extends ListBase {
  readonly [InvoiceAssemblyTypeEnum.IMMEDIATE] = 'Immediate'
  readonly [InvoiceAssemblyTypeEnum.DAILY] = 'Daily (3PM)'
  readonly [InvoiceAssemblyTypeEnum.DAY_OF_MONTH] = 'Day of Month'
  readonly [InvoiceAssemblyTypeEnum.ALL_ACCOUNTS] = 'All Accounts'
}

export class GreenPercents extends ListBase {
  readonly ['50'] = '50% Green'
  readonly ['100'] = '100% Green'
}

export class PaymentMethods extends ListBase {
  readonly [PaymentMethodEnum.ACH] = 'ACH'
  readonly [PaymentMethodEnum.WIRE] = 'Wire'
  readonly [PaymentMethodEnum.CHECK] = 'Check'
  readonly [PaymentMethodEnum.CREDIT_CARD] = 'Credit Card'
  readonly [PaymentMethodEnum.ECHECK] = 'E-Check'
  readonly [PaymentMethodEnum.UTIL820] = 'Utility 820'
  readonly [PaymentMethodEnum.CREDIT] = 'Credit'
  readonly [PaymentMethodEnum.WRITEOFF] = 'Write-Off'
}

export class CustomerPaymentTypes extends ListBase {
  readonly [CustomerPaymentTypeEnum.PAYMENT] = 'Payment'
  readonly [CustomerPaymentTypeEnum.CREDIT] = 'Credit'
  readonly [CustomerPaymentTypeEnum.WRITEOFF] = 'Write-Off'
}

export class CustomerPaymentApplicationTypes extends ListBase {
  readonly [CustomerPaymentApplicationTypeEnum.PAYMENT] = 'Payment'
  readonly [CustomerPaymentApplicationTypeEnum.REVERSAL] = 'Reversal'
  readonly [CustomerPaymentApplicationTypeEnum.REFUND] = 'Refund'
}

export class CustomerPaymentApplicationStatuses extends ListBase {
  readonly [CustomerPaymentApplicationStatusEnum.PENDING] = 'Pending'
  readonly [CustomerPaymentApplicationStatusEnum.APPLIED] = 'Applied'
  readonly [CustomerPaymentApplicationStatusEnum.REVERSED] = 'Reversed'
}

export class CustomerInvoiceStatuses extends ListBase {
  readonly [CustomerInvoiceStatusEnum.STATUS_ASSEMBLING] = 'Assembling'
  readonly [CustomerInvoiceStatusEnum.STATUS_TAXING] = 'Taxing'
  readonly [CustomerInvoiceStatusEnum.STATUS_PENDING_APPROVAL] = 'Pending Approval'
  readonly [CustomerInvoiceStatusEnum.STATUS_APPROVED] = 'Approved'
  readonly [CustomerInvoiceStatusEnum.STATUS_SENDING] = 'Sending'
  readonly [CustomerInvoiceStatusEnum.STATUS_SENT] = 'Sent'
}

export class AccountInvoiceStatuses extends ListBase {
  readonly [AccountInvoiceStatusEnum.STATUS_PENDING] = 'Pending'
  readonly [AccountInvoiceStatusEnum.STATUS_APPROVED] = 'Approved'
  readonly [AccountInvoiceStatusEnum.STATUS_ISSUED] = 'Sent'
  readonly [AccountInvoiceStatusEnum.STATUS_CURRENT] = 'Current'
  readonly [AccountInvoiceStatusEnum.STATUS_PASTDUE] = 'Past Due'
  readonly [AccountInvoiceStatusEnum.STATUS_PAID] = 'Paid'
  readonly [AccountInvoiceStatusEnum.STATUS_WRITEOFF] = 'Written Off'
  readonly [AccountInvoiceStatusEnum.STATUS_VOID] = 'Voided'
}

export class ChargeCategories extends ListBase {
  readonly [ChargeCategoryEnum.CATEGORY_COMMODITY] = 'Commodity'
  readonly [ChargeCategoryEnum.CATEGORY_CHARGE] = 'Charge'
  readonly [ChargeCategoryEnum.CATEGORY_TAX] = 'Tax'
  readonly [ChargeCategoryEnum.CATEGORY_SURCHARGE] = 'Surcharge'
}

export class BankAccountTypes extends ListBase {
  readonly [BankAccountTypeEnum.CHECKING] = 'Checking'
  readonly [BankAccountTypeEnum.SAVINGS] = 'Savings'
}

export class UoM extends ListBase {
  readonly [UoMEnum.KWH] = 'kWh'
  readonly [UoMEnum.KH] = 'kh'
  readonly [UoMEnum.KVA] = 'kva'
}

export class CreditCheckApprovals extends ListBase {
  readonly [CreditCheckApprovalEnum.NEW] = 'New'
  readonly [CreditCheckApprovalEnum.APPROVED] = 'Approved'
  readonly [CreditCheckApprovalEnum.CONDITIONAL] = 'Conditional Approval'
  readonly [CreditCheckApprovalEnum.DENIED] = 'Denied'
}

export class CreditCheckStatuses extends ListBase {
  readonly [CreditCheckStatusEnum.NEW] = 'New'
  readonly [CreditCheckStatusEnum.INPROGRESS] = 'In Progress'
  readonly [CreditCheckStatusEnum.ONHOLD] = 'On Hold'
  readonly [CreditCheckStatusEnum.CLOSED] = 'Closed'
}

export class ContractSubmissionStatuses extends ListBase {
  readonly [ContractSubmissionStatusEnum.NEW] = 'New'
  readonly [ContractSubmissionStatusEnum.INPROGRESS] = 'In Progress'
  readonly [ContractSubmissionStatusEnum.ONHOLD] = 'On Hold'
  readonly [ContractSubmissionStatusEnum.APPROVED] = 'Approved'
  readonly [ContractSubmissionStatusEnum.DENIED] = 'Denied'
  readonly [ContractSubmissionStatusEnum.REMOVED] = 'Removed'
}

export class WelcomeLetterStatuses extends ListBase {
  readonly [WelcomeLetterQueueStatusEnum.NEW] = 'New'
  readonly [WelcomeLetterQueueStatusEnum.INPROGRESS] = 'In Progress'
  readonly [WelcomeLetterQueueStatusEnum.READY] = 'Ready'
  readonly [WelcomeLetterQueueStatusEnum.SENT] = 'Sent'
  readonly [WelcomeLetterQueueStatusEnum.CANCELED] = 'Canceled'
}

export class TransactionRequestTypes extends ListBase {
  readonly [TransactionRequestTypeEnum.HU] = 'Historic Usage'
  readonly [TransactionRequestTypeEnum.ENROLLMENT] = 'Enrollment'
  readonly [TransactionRequestTypeEnum.CHANGE] = 'Change'
  readonly [TransactionRequestTypeEnum.DROP] = 'Drop'
  readonly [TransactionRequestTypeEnum.INVOICE] = 'Invoice'
  readonly [TransactionRequestTypeEnum.PAYMENT] ='Payment'
}

export class TransactionRequestStatuses extends ListBase {
  readonly [TransactionRequestStatusEnum.NEW] = 'New'
  readonly [TransactionRequestStatusEnum.SCHEDULED] = 'Scheduled'
  readonly [TransactionRequestStatusEnum.READY] = 'Ready to Send'
  readonly [TransactionRequestStatusEnum.QUEUED] = 'Queued to Send'
  readonly [TransactionRequestStatusEnum.FAILED] = 'Failed'
  readonly [TransactionRequestStatusEnum.SENT] = 'Sent'
  readonly [TransactionRequestStatusEnum.ACCEPTED] = 'Accepted'
  readonly [TransactionRequestStatusEnum.FINALIZED] = 'Finalized'
  readonly [TransactionRequestStatusEnum.DUPLICATE] = 'Duplicate'
  readonly [TransactionRequestStatusEnum.REJECTED] = 'Rejected'
  readonly [TransactionRequestStatusEnum.CANCELLED] = 'Canceled'
  readonly [TransactionRequestStatusEnum.UNACKED] = 'Unacknowledged'
}

export class TaxExemptItemStatuses extends ListBase {
  readonly [TaxExemptItemStatusEnum.NEW] = 'New'
  readonly [TaxExemptItemStatusEnum.INPROGRESS] = 'In Progress'
  readonly [TaxExemptItemStatusEnum.ONHOLD] = 'On Hold'
  readonly [TaxExemptItemStatusEnum.APPROVED] = 'Approved'
  readonly [TaxExemptItemStatusEnum.DENIED] = 'Denied'
  readonly [TaxExemptItemStatusEnum.REMOVED] = 'Removed'
  readonly [TaxExemptItemStatusEnum.EXPIRED] = 'Expired'
  readonly [TaxExemptItemStatusEnum.RENEWED] = 'Renewed'
}

export class TaxExemptItemCchReasons extends ListBase {
  readonly [TaxExemptItemCchReasonEnum.FED_GOV] = 'Fed gov'
  readonly [TaxExemptItemCchReasonEnum.STATE_LOCAL_GOV] = 'State local gov'
  readonly [TaxExemptItemCchReasonEnum.TRIBAL_GOV] = 'Tribal gov'
  readonly [TaxExemptItemCchReasonEnum.FOREIGN_DIPLOMAT] = 'Foreign diplomat'
  readonly [TaxExemptItemCchReasonEnum.CHARITABLE_ORG] = 'Charitable org'
  readonly [TaxExemptItemCchReasonEnum.RELIGIOUS_ORG] = 'Religious org'
  readonly [TaxExemptItemCchReasonEnum.RESALE] = 'Resale'
  readonly [TaxExemptItemCchReasonEnum.AGRICULTURAL] = 'Agricultural'
  readonly [TaxExemptItemCchReasonEnum.INDUSTRIAL_OR_MANUFACTURING] = 'Industrial or manufacturing'
  readonly [TaxExemptItemCchReasonEnum.DIRECT_PAY] = 'Direct pay'
  readonly [TaxExemptItemCchReasonEnum.DIRECT_MAIL] = 'Direct mail'
  readonly [TaxExemptItemCchReasonEnum.OTHER] = 'Other'
}

export class TaxExemptItemCchTypes extends ListBase {
  readonly [TaxExemptItemCchTypeEnum.NONE] = 'No Exemptions'
  readonly [TaxExemptItemCchTypeEnum.FEDEXCISE] = 'Federal Excise Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATE] = 'State Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.FFEDEXCISE_STATE] = 'Federal Excise Tax and State Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.LOCAL] = 'Local Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.FEDEXCISE_LOCAL] = 'Federal Excise Tax and Local Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATE_LOCAL] = 'State and Local Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.FEDEXCISE_STATE_LOCAL] = 'Federal Excise Tax and State and Local Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATE_] = 'State Admin - State and Local Sales, and Use Taxes'
  readonly [TaxExemptItemCchTypeEnum.ALL_FED] = 'All Federal Level Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.ALL_UNITBASED] = 'All Unit Based Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.UNIT_E911] = 'Unit based E911 Exempt'
  readonly [TaxExemptItemCchTypeEnum.ONLY_UNIT] = 'Only apply Unit Based Fees'
  readonly [TaxExemptItemCchTypeEnum.RIGHTOFWAY] = 'Local Right of Way Fees (ROW) Exempt'
  readonly [TaxExemptItemCchTypeEnum.ALLSTATE_LOCALE911] = 'All State and Local E911s Exempt'
  readonly [TaxExemptItemCchTypeEnum.USF_ONLY] = 'Only Apply Federal Universal Service Fund (USF)'
  readonly [TaxExemptItemCchTypeEnum.PUC_FEES] = 'PUC Fees'
  readonly [TaxExemptItemCchTypeEnum.ALL_STATE_LOCAL_GRT] = 'All State and Local Gross Receipts Tax (GRT) Exempt'
  readonly [TaxExemptItemCchTypeEnum.ALL_STATE_USF] = 'All State Universal Service Fund (USF) Fees Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATE_GRT_USF] = 'State Gross Receipts Tax and Universal Service Fund Exempt'
  readonly [TaxExemptItemCchTypeEnum.FED_USF] = 'Federal Universal Service Fund (USF) Exempt'
  readonly [TaxExemptItemCchTypeEnum.FEDEXCISE_FEDUSF_STATE] = 'Federal Excise, Federal Universal Service Fund (USF) and State Sales Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATEEXCISE] = 'State Excise Tax Exempt (includes FL CST)'
  readonly [TaxExemptItemCchTypeEnum.FEDEXCISE_FEDUSF_LOCAL] = 'Federal Excise, Federal USF and Local Sales Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.FEDUSF_STATE_LOCAL] = 'Federal USF and State Local Sales Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.FEDUSF_LOCAL] = 'Federal USF and Local Sales Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATEEXCISE_LOCAL_TELECOMM] = 'State Excise and Local Telecommunications Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATE_SALES] = 'State Level Sales Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATE_LOCAL_SALES] = 'State and Local Sales Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.FEDEXCISE_FEDUSF_STATE_LOCAL] = 'Federal Excise, Federal USF, State and Local Sales Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.FED_TRS] = 'Federal TRS (Federal Regulatory Assessment Fee) Exempt'
  readonly [TaxExemptItemCchTypeEnum.VAT] = 'Value Added Tax (VAT)'
  readonly [TaxExemptItemCchTypeEnum.LOCAL_UUT] = 'Local Utility Users Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATEEXCISE_LOCAL_SUR] = 'State Excise Tax Exempt and Local Surcharge Exempt'
  readonly [TaxExemptItemCchTypeEnum.CITY] = 'City Level Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.STATE_FRANCHISE] = 'State Franchise Fee'
  readonly [TaxExemptItemCchTypeEnum.LOCAL_FRANCHISE] = 'Local Franchise Fee'
  readonly [TaxExemptItemCchTypeEnum.COUNTY] = 'County Level Taxes Exempt'
  readonly [TaxExemptItemCchTypeEnum.LA_COMMUT] = 'Los Angeles Communications Users Tax Exempt'
  readonly [TaxExemptItemCchTypeEnum.NONUNIT_EXCEPT_E911] = 'Non-unit taxes exempt except for E911'
  readonly [TaxExemptItemCchTypeEnum.NO_TAX_ON_TAX] = 'No Tax on Tax'
  readonly [TaxExemptItemCchTypeEnum.ALL] = 'All Taxes Exempt – Apply no tax or fees'
}

export class UsageSources extends ListBase {
  readonly [UsageSourcesEnum.EPO] = 'EPO'
  readonly [UsageSourcesEnum.UTIL867] = 'Interval 867'
  readonly [UsageSourcesEnum.EYEDRO] = 'Eyedro'
  readonly [UsageSourcesEnum.UTILITY_CUSTOMER_PORTAL] = 'Util. Customer Portal'
  readonly [UsageSourcesEnum.UTILITY_SUPPLIER_PORTAL] = 'Util. Supplier Portal'
  readonly [UsageSourcesEnum.FORECASTED] = 'Forecasted'
  readonly [UsageSourcesEnum.UTIL867_SUMMARY] = 'Summary 867'
  readonly [UsageSourcesEnum.ESTIMATED] = 'Estimated'
  readonly [UsageSourcesEnum.BEST_MATCH] = 'Best Match'
  readonly [UsageSourcesEnum.PROFILED] = 'Profiled'
}

export class ResettlementBillableStatuses extends ListBase {
  readonly [ResettlementBillableStatusEnum.UNDECIDED] = 'Pending'
  readonly [ResettlementBillableStatusEnum.BILLABLE] = 'Approved'
  readonly [ResettlementBillableStatusEnum.WRITE_OFF] = 'Write-off'
  readonly [ResettlementBillableStatusEnum.BILLED] = 'Billed'
}
