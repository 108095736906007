import { Base, IBaseModel } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'
import { TransactionRequest } from './transactionrequest'

export interface ITransaction extends IBaseModel {
  id: string;
  accountId: number;
  transactionDateTime: number;
  actionId: string;
  transactionId: string;
  status: number;
  json: {};
}

export class Transaction extends Base {
  public id = ''
  public accountId = 0
  public transactionDateTime = 0
  public actionId = ''
  public transactionId = ''
  public status = 0
  public json = {}
  public linkedReq?: TransactionRequest

  constructor (props?: Partial<ITransaction>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'accountId':
          case 'status':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get date () {
    return moment.unix(this.transactionDateTime).toISOString()
  }
}

export class DataServicesTransaction extends Transaction {
  public customerName: String = ''
  public accountNumber: String = ''
  public normalized: IStringIndexed = []

  constructor (props?: Partial<ITransaction>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get direction () {
    return this.normalized.direction === 'Request' ? 'Out' : 'In'
  }

  public get dateTime () {
    return this.normalized.transactionDateTime
  }

  public get keyMessage () {
    return this.normalized.description
  }

  public get responseType () {
    if (this.direction === 'Out' || this.actionId.charAt(0) === 'U') {
      return null
    }
    return this.normalized.responseType ?? null
  }

  public get rejectReasons () {
    return this.normalized.rejectReasons ? this.normalized.rejectReasons.join('/') : null
  }

  public get startDate () {
    return this.normalized.flowStart ?? null
  }

  public get endDate () {
    return this.normalized.flowEnd ?? null
  }

  public get icap () {
    return this.normalized.capacityTag ? this.normalized.capacityTag[0] : null
  }

  public get rateClass () {
    return this.normalized.rateClass ? this.normalized.rateClass[0] : null
  }

  public get readCycle () {
    return this.normalized.readCycle ? this.normalized.readCycle[0] : null
  }

  public get zone () {
    return this.normalized.zone ? this.normalized.zone[0] : null
  }

  public get netMeter () {
    return this.normalized.netMeter ?? null
  }
}
