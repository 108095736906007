import { Base, IBaseModel } from '@/modules/shared/models/base'
import moment from 'moment'
import { INumericIndexed, IStringIndexed } from '@/modules/shared/types'

export interface IRatePlan extends IBaseModel {
  id: number;
  p2cId: number;
  description: string;
  rateCode: string;
  descText: string;
}

export class RatePlan extends Base {
  public id: number = 0
  public p2cId: number = 0
  public description: string = ''
  public rateCode: string = ''
  public descText: string = ''
  public serviceAccountIds: Array<number> = []

  constructor (props?: Partial<IRatePlan>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'p2cId':
            props[p] = parseInt(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
