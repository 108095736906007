



















import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import FilesApi from '@/modules/customers/api/files'
import NameBar from '../../components/blocks/NameBar.vue'
import { File } from '../../../shared/models/file'
import { TaxExemptForm } from '../../models/customer/taxexemptform'

@Component({
  components: {
    'name-bar': NameBar
  }
})
export default class TaxExemptFormList extends Vue {
  @Prop({ required: true })
  private customerId!:number

  @Prop({ required: true })
  private forms!: Array<TaxExemptForm>

  @Prop({ default: false })
  private loading!: boolean

  private fileSearch = ''

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Status', align: 'left', sortable: true, filterable: false, value: 'status' },
    { text: 'Accounts', align: 'left', sortable: true, value: 'serviceAccounts' },
    { text: 'Codes', align: 'left', sortable: true, value: 'cchTypes' },
    { text: 'Uploaded On', align: 'left', sortable: true, value: 'created' }
  ]

  private handleClick (form: TaxExemptForm) {
    this.$emit('taxExemptForm:selected', form)
  }
}
