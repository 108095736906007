

































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import Rules from '@/plugins/validations'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import { ContractSubmissionsApi } from '../../api/queue/contractsubmissions'

@Component({
  components: {}
})
export default class FinalizeForm extends Vue {
  @Prop({ default: 'denial' })
  private finalizeType!: string

  @PropSync('item', {
    default: () => new ContractSubmission(),
    required: true
  })
  private localItem!: ContractSubmission

  private salesNote: string = ''

  private errorMessage: string = ''
  private saving: boolean = false
  private valRules: Rules = Rules

  public get mode () {
    switch (this.finalizeType) {
      case 'info':
        return 'Requires More Info'
      default:
        return 'Deny Contract Submission'
    }
  }

  public get modeLabel () {
    switch (this.finalizeType) {
      case 'info':
        return 'Request Info'
      default:
        return 'Deny'
    }
  }

  private get modeColor () {
    switch (this.finalizeType) {
      case 'info':
        return 'orange'
      default:
        return 'red'
    }
  }

  public handleClose () : void {
    (this.$refs.form as Vue & { resetValidation: () => boolean }).resetValidation()
    this.$emit('dialog:close')
  }

  public async handleSubmit () : Promise<void> {
    if (this.saving) {
      return
    }

    if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    const isNew: boolean = !(this.localItem.id > 0)

    try {
      const updatedItem = await ContractSubmissionsApi.finalize(this.localItem, this.finalizeType, this.salesNote)

      this.$emit('item:finalized', updatedItem)
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public mounted () {
  }
}
