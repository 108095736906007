import { Base } from '@/modules/shared/models/base'
import { IStringIndexed } from '@/modules/shared/types'

export interface ISalesUserNote extends IStringIndexed {
  id: number;
  salesId: number;
  categoryId: number;
  note: string;
}

export default class SalesUserNote extends Base implements ISalesUserNote {
  public id = 0
  public salesId = 0
  public categoryId = 0
  public note = ''

  constructor (props: Partial<ISalesUserNote> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'salesId':
          case 'categoryId':
            props[p] = parseInt(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
