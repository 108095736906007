






















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ListsEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AppState } from '@/stores/appStore'
import { File as FileModel } from '@/modules/shared/models/file'

@Component
export default class FileUploadForm extends Vue {
  @Prop({ required: true })
  private uploadCallback!: Function

  private errorMessage = ''
  private saving = false

  private files: Array<{ reading: boolean; completed: number; file: FileModel }> = []

  private removeFile (index: number) {
    this.files.splice(index, 1)
  }

  private processFileSelection () {
    const chosenFiles: FileList = (this.$refs.fileInput as HTMLInputElement).files!

    if (chosenFiles.length === 0) {
      this.files = this.files.splice(0, this.files.length)
      return
    }

    for (let i = 0; i < chosenFiles.length; i++) {
      this.readFile(chosenFiles[i])
    }

    (this.$refs.fileInput as HTMLInputElement).value = ''
  }

  private readFile (f: File) {
    const reader = new FileReader()

    const newItem: { reading: boolean; completed: number; file: FileModel } = {
      reading: false,
      completed: 0,
      file: new FileModel({ name: f.name })
    }

    reader.onloadstart = (e) => {
      newItem.reading = true

      this.files.push(newItem)
    }

    reader.onprogress = (e) => {
      if (!e.lengthComputable) {
        return
      }

      newItem.completed = Math.round((e.loaded / e.total) * 100)
    }

    reader.onerror = (e) => {
      newItem.reading = false
      newItem.file.contents = ''
      this.errorMessage = e.toString()
    }

    reader.onloadend = (e) => {
      newItem.reading = false
      newItem.file.contents = reader.result as string
    }

    reader.readAsBinaryString(f)
  }

  public async handleSubmit () {
    if (this.saving || !this.files.length || this.files.some(i => i.reading)) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    await this.files.forEach(async (i) => {
      try {
        this.uploadCallback(i)
      } catch (err) {
        this.errorMessage = err.message
      }
    })

    this.saving = false
    Vue.set(this, 'files', [])

    if (!this.errorMessage.length) {
      this.handleClose()
    }
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    Vue.set(this, 'files', [])
  }
}
