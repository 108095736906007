
















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { IStringIndexed } from '@/modules/shared/types'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { TaxExemptForm } from '@/modules/customers/models/customer/taxexemptform'
import { TaxExemptFormsApi } from '@/modules/finance/api/queue/taxexemptqueue'
import { TaxExemptItemStatusEnum } from '@/modules/shared/enums'

@Component
export default class TaxExemptQueue extends Vue {
  @Prop({ default: 0 })
  private itemId!: number

  private statusFilters: Array<number> = [
    TaxExemptItemStatusEnum.NEW,
    TaxExemptItemStatusEnum.INPROGRESS,
    TaxExemptItemStatusEnum.ONHOLD,
    TaxExemptItemStatusEnum.EXPIRED
  ]

  private selectedItem: TaxExemptForm = new TaxExemptForm()

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'Customer', align: 'left', sortable: true, value: 'businessName' },
    { text: 'Assigned To', align: 'center', width: 50, sortable: true, value: 'assignedToName' },
    { text: 'Status', align: 'center', width: 50, sortable: true, value: 'statusLabel' },
    { text: 'Date Queued', align: 'center', width: 120, sortable: true, value: 'created' }
  ]

  private loading = false

  private items: Array<TaxExemptForm> = []

  public statusLabels = mapObjectVuetifySelect(TaxExemptForm.statuses, true)

  private showFilters = true

  private listSearch: string = ''

  private get filteredItems () {
    return this.items.filter(r => this.statusFilters.includes(r.status))
  }

  private handleClickRow (item: TaxExemptForm) {
    this.selectedItem = item
    this.$router.push({ name: 'taxexemptqitem', params: { itemId: item.id.toString() } }).catch(() => {})
  }

  private handleItemCreated (item: TaxExemptForm) {
    this.items.push(item)
  }

  private handleItemUpdated (item: TaxExemptForm) {
    const idx = this.items.findIndex(c => c.id === item.id)

    if (idx !== -1) {
      Vue.set(this.items, idx, item)
    }

    const idxFilt = this.filteredItems.findIndex(c => c.id === item.id)

    if (idxFilt !== -1) {
      this.selectedItem = item
    } else {
      this.selectedItem = new TaxExemptForm()
    }
  }

  private handleItemFinalize (item: TaxExemptForm) {
    if (this.statusFilters.indexOf(item.status) !== -1) {
      this.handleItemUpdated(item)
    } else {
      const idx = this.items.findIndex(c => c.id === item.id)
      if (idx !== -1) {
        this.items.splice(idx, 1)
        this.selectedItem = new TaxExemptForm()
      }
    }
    EventBus.$emit('app-snack', {
      color: 'green',
      message: 'Transaction Request Resolved',
      timeout: 2000
    })
  }

  private itemViewedClass (item: TaxExemptForm): string {
    if (item.id === this.itemId) {
      return 'blue lighten-3'
    }
    return ''
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  @Watch('statusFilters', { immediate: true, deep: true })
  private handleStatusFilterChange () {
    this.handleLoadItems()
  }

  private async handleLoadItems () {
    try {
      this.loading = true
      this.items = []

      this.errorMessage = ''

      if (!this.statusFilters.length) {
        // throw new Error('Please select at least 1 status') - doesnt need to be an error- maybe as a hint though
      }

      const config: IStringIndexed = {
        filters: {
          status: { IN: this.statusFilters },
          active: 1
        },
        additional: {
          0: 'customer',
          1: 'file',
          file: ['name']
        }
      }

      const items = await TaxExemptFormsApi.loadAll(config)

      this.items = items.items

      if (this.itemId) {
        this.selectedItem = this.items.find(i => i.id === this.itemId) || new TaxExemptForm()
        if (this.selectedItem.id !== this.itemId) {
          this.$router.push({ name: 'taxexemptqitem' }).catch(() => {})
        }
      }
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }

  private mounted () {
  }
}
