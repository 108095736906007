

































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import EventBus from '@/plugins/eventbus'
import { AccountInvoice } from '@/modules/customers/models/account/invoice'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import { CustomerPaymentApplication } from '@/modules/customers/models/customer/payment/application'
import { INumericDictionary } from '@/modules/shared/types'
import { CustomerPaymentApplicationStatusEnum, CustomerPaymentApplicationTypeEnum } from '@/modules/shared/enums'

@Component({
  components: {
  }
})
export default class AccountInvoicePayments extends Vue {
  @Prop({ required: true })
  private invoice!: AccountInvoice

  private paymentHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: true, value: 'id' },
    { text: 'Payment ID', align: 'left', sortable: true, value: 'paymentId' },
    { text: 'Type', align: 'left', sortable: true, value: 'typeLabel' },
    { text: 'Status', align: 'center', sortable: true, value: 'statusLabel' },
    { text: 'Pay Date', align: 'right', sortable: true, value: 'paymentDate' },
    { text: 'Applied Date', align: 'right', sortable: true, value: 'applicationDate' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount' },
    { text: 'Reference #', align: 'right', sortable: true, value: 'referenceNumber' },
    { text: '', align: 'center', sortable: false, value: 'actions' }
  ]

  private processingList: INumericDictionary<string> = {}

  private showReversed = false
  private yesNo = [{ text: 'Yes', value: true }, { text: 'No', value: false }]

  private get payments () {
    const payments = []

    for (const cp of CustomerState.customer.payments as Array<CustomerPayment>) {
      for (const ap of cp.applications as Array<CustomerPaymentApplication>) {
        if (ap.invoiceId === this.invoice.id) {
          if (this.showReversed || (ap.type !== CustomerPaymentApplicationTypeEnum.REVERSAL && ap.status !== CustomerPaymentApplicationStatusEnum.REVERSED)) {
            payments.push(ap)
          }
        }
      }
    }

    return payments
  }

  private getCustomerPayment (ap: CustomerPaymentApplication): CustomerPayment|undefined {
    return CustomerState.customer.payments.find((cp: CustomerPayment) => cp.id === ap.paymentId)
  }

  private get canReverseApplication () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.APPLICATIONS.REVERSE')
  }

  public handleReverseApplication (item: CustomerPaymentApplication) {
    EventBus.$emit('open-app-dialog', {
      component: 'confirm-dialog',
      dialogProps: {
        width: '50%'
      },
      componentProps: {
        title: 'Confirm Application Reversal',
        message: '<b>Are you sure you want to reverse this payment application?</b><br/><br/>This will increase the open balance of the payment so that it may be applied to other invoices.',
        onConfirm: () => this.reverseApplication(item)
      }
    })
  }

  private async reverseApplication (item: CustomerPaymentApplication) {
    try {
      Vue.set(this.processingList, item.id, 'reverse')
      const payment = CustomerState.customer.payments.find(p => p.id === item.paymentId)
      if (!payment) {
        throw new Error('Unable to match payment header record')
      }
      await CustomerState.ReversePaymentApplication({ payment: payment, application: item })
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processingList, item.id)
    }
  }

  public processingAction (payment: CustomerPayment) {
    return this.processingList[payment.id] || false
  }

  private colorizeRow (item: CustomerPaymentApplication) {
    if (item.type === CustomerPaymentApplicationTypeEnum.REVERSAL || item.status === CustomerPaymentApplicationStatusEnum.REVERSED) {
      return 'grey--text text--lighten-1'
    }
    return 'black--text'
  }
}
