/**
 * Created by Tom on 3/14/2017.
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

Vue.use(Vuex)

export interface IRootState {
  strict: boolean;
}

const debug = process.env.NODE_ENV !== 'production'

const modList: any = { auth: 'AppUser', app: 'AppState', cust: 'CustomerState', datasvcs: 'DataServicesState', sales: 'SalesState' }
const pluginList = []
export const priorState: any = {}
const ls = new SecureLS({ isCompression: false })

for (const k in modList) {
  const storageKey = process.env.VUE_APP_STORAGE_KEY + '-' + k
  const serializedState = ls.get(storageKey)

  if (serializedState) {
    priorState[modList[k]] = JSON.parse(ls.get(storageKey))[modList[k]]
  }

  pluginList.push(createPersistedState({
    key: storageKey,
    paths: [modList[k]],
    storage: {
      getItem: (key: string) => ls.get(key),
      setItem: (key: string, value: any) => {
        try {
          ls.set(key, value)
        } catch (err) {
          console.log(err)
          console.log(value)
        }
      },
      removeItem: (key: string) => ls.remove(key)
    }
  }))
}

const store = new Vuex.Store<IRootState>({
  strict: debug,
  plugins: pluginList
})

export default store
