
import { Component } from 'vue-property-decorator'
import { Note } from '@/modules/shared/models/note'
import EventBus from '@/plugins/eventbus'
import NamebarBase from './Base.vue'
import { CustomerState } from '@/modules/customers/store'

@Component({
  components: {
  }
})
export default class NotesNamebar extends NamebarBase {
  protected pageIcon = 'notes'
  protected pageTitle = 'Notes'
  protected actionIcon = 'noteadd'
  protected actionTitle = 'Create Note'

  protected handleAction () {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-note', componentProps: { note: new Note(), noteList: CustomerState.customer.notes, saveMethod: 'SaveNote' }, dialogProps: { width: '100%' } })
  }

  public mounted () {

  }
}
