

















































import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import ContactGeneralFormFields from '@/modules/customers/components/forms/contact/General.vue'
import ContactCommunicationFormFields from '@/modules/customers/components/forms/contact/Communication.vue'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import { Contact } from '@/modules/customers/models/customer/contact'
import Customer from '@/modules/customers/models/customer'
import { IStringIndexed } from '@/modules/shared/types'
import { ContactTypeEnum, ListsEnum } from '@/modules/shared/enums'
import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'

@Component({
  components: {
    'contact-general-fields': ContactGeneralFormFields,
    'contact-communication-fields': ContactCommunicationFormFields
  }
})
export default class CSQContactsStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  private noErrors: boolean = true
  private errorMessage = ''

  private selectedContact: Contact|null = null

  private validations: IStringIndexed = {
    type: {
      label: 'Type',
      rules: [Rules.required()]
    },
    firstName: {
      label: 'First Name',
      rules: [Rules.required()]
    },
    lastName: {
      label: 'Last Name',
      rules: [Rules.required()]
    },
    primaryEmail: {
      label: 'Primary Email',
      rules: [Rules.required(), Rules.email()]
    }
  }

  private handleAddContact (id: number) {
    if (id > 0) {
      return
    }
    const idx = this.localItem.customerShell?.contacts.findIndex(c => c.id === id)
    if (idx === -1) {
      const cdata = this.localItem.data.contacts.find(c => c.id === id)
      if (cdata) {
        this.localItem.customerShell!.contacts.push(new Contact(cdata))
      }
      Vue.set(this, 'selectedContact', null)
      Vue.set(this, 'selectedContact', this.localItem.customerShell!.contacts[this.localItem.customerShell!.contacts.length - 1])
    }
  }

  private handleRemoveContact (id: number) {
    const idx = this.localItem.customerShell!.contacts.findIndex(c => c.id === id)
    if (idx !== -1) {
      this.localItem.customerShell!.contacts.splice(idx, 1)
    }
    if (id === this.selectedContact?.id) {
      Vue.set(this, 'selectedContact', null)
    }
  }

  private handleEditContact (id: number) {
    // Weird bug with v-mask. If the form isn't destroyed and re-created by setting selectedContact to null
    // Then the workPhone from one contact bleeds over to others and corrupts them
    // Without nextTick the replacement happens without redrawing the form
    Vue.set(this, 'selectedContact', null)
    Vue.nextTick()
      .then(() => {
        Vue.set(this, 'selectedContact', this.localItem.customerShell?.contacts.find(c => c.id === id))
      })
  }

  private handleReloadContact (id: number) {
    const idx = (this.localItem.customerShell as Customer).contacts.findIndex(c => c.id === id)
    const initialIdx = (this.localItem.data.contacts as Array<IStringIndexed>).findIndex(c => c.id === id)
    if (idx !== -1 && initialIdx !== -1) {
      Vue.set((this.localItem.customerShell as Customer).contacts, idx, new Contact(this.localItem.data.contacts[initialIdx]))
    }
  }

  @Watch('selectedContact', { deep: true, immediate: true })
  private handleContactSelect () {
    Vue.nextTick()
      .then(() => {
        if (this.$refs.contactForm) {
          (this.$refs.contactForm as Vue & { validate: () => boolean }).validate()
        }
      })
  }

  private get contacts () {
    const contacts: Array<IStringIndexed> = this.localItem.customerShell!.contacts.map(cc => ({
      id: cc.id,
      typeLabel: cc.typeLabel,
      fullName: cc.fullName,
      email: cc.primaryEmail,
      title: cc.title,
      isImporting: true,
      obj: cc
    }))

    contacts.push(...(this.localItem.data.contacts as Array<IStringIndexed>).filter(p =>
      !contacts.find(c => c.id === p.id || c.email === p.primaryEmail || c.fullName === p.fullName)
    ).map((p) => ({
      id: p.id,
      typeLabel: AppState.listsByName[ListsEnum.CONTACT_TYPES]![p.type],
      fullName: (p.firstName.length ? p.firstName + ' ' : '') + p.lastName,
      title: p.title,
      isImporting: false,
      obj: p
    })))

    return contacts
  }

  private get formValidations () {
    const rules: IStringIndexed = {}
    for (const p in this.validations) {
      rules[p] = this.validations[p].rules
    }
    return rules
  }

  private contactIsValid (contact: Contact) {
    for (const p in this.validations) {
      const val = contact[p]

      for (const r of this.validations[p].rules) {
        if (r(val) !== true) {
          return this.validations[p].label + ': ' + r(val)
        }
      }
    }
    return true
  }

  private getContactClasses (contact: IStringIndexed) {
    let classes = contact.isImporting && this.contactIsValid(contact.obj) !== true ? 'red lighten-3' : ''

    if (!classes.length && this.selectedContact?.id === contact.id) {
      classes += ' primary lighten-4'
    }

    if (contact.id <= 0) {
      classes += ' clickable'
    }
    return classes
  }

  private get stepValid () {
    this.errorMessage = ''
    if (!this.localItem.customerShell?.contacts.find(c => c?.type === ContactTypeEnum.PRIMARY)) {
      this.errorMessage = 'A primary contact is required'
      return false
    }
    for (const c of this.contacts) {
      if (c.isImporting) {
        const isValid = this.contactIsValid(c.obj)
        if (isValid !== true) {
          this.errorMessage = c.fullName + ' has Errors - ' + isValid
          Vue.set(this, 'selectedContact', c.obj)
          return false
        }
      }
    }

    return true
  }

  private mounted () {
  }
}
