














































































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import dtExporter, { exportDataTableToPDF } from '@/modules/shared/datatablehelper'
import { IStringIndexed } from '@/modules/shared/types'
import { PaymentsModelApi } from '@/modules/finance/api/paymentsmodel'

@Component({
  components: {}
})
export default class PrepaymentsParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true

  private menu = {
    from: false,
    to: false
  }

  private from = ''
  private to = ''
  private resultsSearch = ''
  private records: Array<IStringIndexed> = []
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'Payment ID', align: 'left', sortable: true, value: 'id' },
    { text: 'Customer ID', align: 'left', sortable: true, value: 'customerId' },
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customer.businessName', exportType: 'text' },
    { text: 'Payment Date', align: 'left', sortable: true, value: 'payDate', exportType: 'date' },
    { text: 'Prepayment', align: 'left', sortable: true, value: 'amount', exportType: 'money' }
  ]

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true
      this.records = await PaymentsModelApi.loadAll({
        filters: {
          holdAsDeposit: '1',
          payDate: {
            AND: {
              '>=': (this.from === '' ? '2000-01-01' : this.from),
              '<=': (this.to === '' ? '2099-12-31' : this.to)
            }
          }
        },
        additional: ['customer']
      })
      this.filteredItems = this.records
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private filteredItems: Array<IStringIndexed> = []

  private updateFiltered (list: Array<IStringIndexed>) {
    this.filteredItems = list
  }

  private exportList () {
    dtExporter(this.headers, this.filteredItems, 'Prepayments Export', 'Prepayments-' + moment().format('YYYYMMDD-HHmm'), { bookType: 'xlsx' })
  }

  private exportPDF () {
    exportDataTableToPDF(this.headers, this.filteredItems, 'Prepayments-' + moment().format('YYYYMMDD-HHmm'))
  }

  private totalAmount () {
    return this.records.reduce((a, b) => a + (b.amount || 0), 0)
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
