





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import Customer from '@/modules/customers/models/customer'
import { Contact } from '@/modules/customers/models/customer/contact'
import EventBus from '@/plugins/eventbus'

@Component({
  components: {
  }
})
export default class ContactsList extends Vue {
  @Prop({ required: true })
  private customer!: Customer

  @Prop({ required: true })
  private contacts!: Array<Contact>

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Type', align: 'left', sortable: true, value: 'typeLabel' },
    { text: 'Name', align: 'left', sortable: true, value: 'fullName' },
    { text: 'Location', align: 'left', sortable: true, value: 'location' },
    { text: 'Phones', align: 'left', sortable: true, value: 'phones' },
    { text: 'Emails', align: 'left', sortable: true, value: 'emails' },
    { text: '', align: 'left', sortable: true, value: 'actions' }
  ]

  private loading: boolean = false

  private localItem: Contact = new Contact({ customerId: this.customer?.id })

  private get canAddContact () {
    return AuthState.user.isAllowed('CUSTOMER.CONTACTS.CREATE')
  }

  private get canDeleteContact () {
    return !AuthState.user.isAllowed('CUSTOMER.CONTACTS.DELETE')
  }

  private get getHeaders () {
    let headers = this.headers

    if (this.isDense) {
      headers = headers.filter(i => { return i.value !== 'Actions' })
    }

    return headers
  }

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private handleEditContact (item: Contact) {
    EventBus.$emit('open-cust-dialog', { component: 'edit-customer-contact', componentProps: { contact: item } })
  }

  private handleDelete (contact: Contact) {
    if (confirm('Are you sure you want to delete this contact?')) {
      CustomerState.DeleteContact(contact)
    }
  }
}
