
























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import EventBus from '@/plugins/eventbus'
import { INumericDictionary, IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import dtExporter from '@/modules/shared/datatablehelper'
import moment from 'moment'
import commissionApi from '@/modules/finance/api/commissions'
import { FinanceInvoice } from '@/modules/finance/models/invoice'
import { PaymentMethodEnum } from '@/modules/shared/enums'
import CommissionPayment from '@/modules/sales/models/salesuser/commissionpayment'

@Component
export default class PaymentHistory extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private showAllFields = false
  private saving = false
  private loading = false
  private loaded = false

  private selectedPeriod = ''

  private periodHeaders: Array<IStringIndexed> = [
    { text: 'Pay Period', align: 'left', sortable: true, value: 'payPeriod', exportType: 'date' },
    { text: 'Total Paid', align: 'right', sortable: true, value: 'totalAmount', width: 180, exportType: 'money' },
    { text: '', align: 'center', sortable: false, value: 'actions', width: 40 }
  ]

  private headers: Array<IStringIndexed> = [
    { text: 'Sales ID', align: 'left', sortable: true, value: 'salesId', width: 80 },
    { text: 'Sales Name', align: 'left', sortable: true, value: 'salesName' },
    { text: 'Pay Period', align: 'left', sortable: true, value: 'payPeriod', width: 150, exportType: 'date' },
    { text: 'Amount Paid', align: 'center', sortable: true, value: 'totalPayment', width: 220, exportType: 'money' }
  ]

  private resultsSearch = ''

  private payments: Array<CommissionPayment> = []

  public async handleSearch () {
    this.errorMessage = ''

    try {
      this.loaded = true
      this.loading = true
      this.payments = await commissionApi.loadHistory()
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private getSalesName (salesId:number) {
    return SalesState.salesNames[salesId]
  }

  private colorizeSummaryRow (item: IStringIndexed) {
    if (item.payPeriod === this.selectedPeriod) {
      return 'red lighten-4'
    }
    return ''
  }

  private handlePeriodSelect (item: CommissionPayment) {
    this.selectedPeriod = item.payPeriod
  }

  private handleExportSummaryList () {
    dtExporter(this.periodHeaders, this.summaryPayments, 'Commission Payment Details', 'CommissionPayments-Detail-' + this.selectedPeriod + '-' + moment().format('YYYYMMDD-HHmm'))
  }

  private handleExportDetailList () {
    dtExporter(this.headers, this.selectedPayments, 'Commission Payment Summary', 'CommissionPayments-Summary-' + moment().format('YYYYMMDD-HHmm'))
  }

  private get summaryPayments () {
    if (this.loading) {
      return []
    }
    const summary: Array<{
      [key: string]: any
      payPeriod: string,
      totalAmount: number
    }> = []

    this.payments.forEach(p => {
      const existing = summary.find(s => s.payPeriod === p.payPeriod)
      if (existing) {
        existing.totalAmount += p.totalPayment
        return
      }
      summary.push({
        payPeriod: p.payPeriod,
        totalAmount: p.totalPayment
      })
    })

    return summary
  }

  private get selectedPayments () {
    if (this.loading) {
      return []
    }
    const summary: Array<{
      [key: string]: any
      salesId: number,
      salesName: string,
      payPeriod: string,
      totalPayment: number
    }> = []

    this.payments.filter(p => p.payPeriod === this.selectedPeriod).forEach(p => {
      const existing = summary.find(s => s.salesId === p.salesId)
      if (existing) {
        existing.totalPayment += p.totalPayment
        return
      }
      summary.push({
        salesId: p.salesId,
        salesName: this.getSalesName(p.salesId),
        payPeriod: p.payPeriod,
        totalPayment: p.totalPayment
      })
    })

    return summary
  }

  private beforeMount () {
    this.errorMessage = ''
    this.handleSearch()
  }
}
