import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import CisUser from '@/modules/auth/models/cisuser'
import { AxiosResponse, Method } from 'axios'
import { IStringDictionary, IStringIndexed, INumericDictionary } from '@/modules/shared/types'
import AppUser from '@/modules/auth/models/user'
import Permission from '../models/permission'

interface IErrorResponse {
  errorMessage: string;
}

interface IUserResponse {
  user: CisUser;
}

interface IMultiUserResponse {
  users: Array<CisUser>;
}

const baseUrl = 'app-users'

export default class UsersApi {
  public static async loadAll (filters?: IStringDictionary<string | Array<string|number>>): Promise<INumericDictionary<CisUser>> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + filtersToQs(filters))

      if (response.status === 200 && response.data.users) {
        const users: INumericDictionary<CisUser> = {}

        Object.entries(response.data.users).forEach(([id, i]) => {
          users[parseInt(id)] = new CisUser(<Partial<CisUser>>i)
        })

        return users
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async load (id: number): Promise<AppUser> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + '/' + id.toString())
      if (response.status === 200) {
        return new AppUser(response.data.user)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async save (salesPerson: CisUser) : Promise<CisUser> {
    try {
      let method = 'POST'
      if (salesPerson.id) {
        method = 'PUT'
      }

      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl,
        method: <Method> method,
        data: salesPerson
      })

      if (response.status === 200 && response.data.user) {
        return new CisUser((<IUserResponse> response.data).user)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async saveGroups (data: IStringIndexed) : Promise<CisUser> {
    try {
      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + data.id,
        method: 'PATCH',
        data: data
      })

      if (response.status === 200 && response.data.user) {
        return new CisUser((<IUserResponse> response.data).user)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadPermissions (userId: number, includeGroup: boolean = false): Promise<Array<string>> {
    try {
      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + userId + '/permissions?includeGroup=' + includeGroup,
        method: 'GET'
      })

      if (response.status === 200 && response.data.permissions) {
        return response.data.permissions
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async savePermissions (userId: number, permissions: Array<string>): Promise<boolean> {
    try {
      const response: AxiosResponse = await esalesClient.request({
        url: baseUrl + '/' + userId + '/permissions',
        method: 'PATCH',
        data: {
          permissions: permissions
        }
      })

      if (response.status === 200 && response.data.permissions) {
        return true
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadAllPermissions (): Promise<Array<Permission>> {
    try {
      const response: AxiosResponse = await esalesClient.get('permissions')
      if (response.status === 200 && response.data.permissions) {
        const permissions: Array<Permission> = []

        Object.entries(response.data.permissions).forEach(([id, i]) => {
          permissions.push(new Permission(<Partial<Permission>>i))
        })

        return permissions
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
