import { IMeterRead, MeterRead } from '@/modules/shared/models/account/meterread'
import Customer from '@/modules/customers/models/customer'
import { CommodityAccount } from '@/modules/shared/models/account/base'
import moment from 'moment'

export interface IBillingMeterRead extends IMeterRead {
 customer: Customer|null;
 account: CommodityAccount|null;
}

export class BillingMeterRead extends MeterRead implements IBillingMeterRead {
  public customer = null;
  public account = null;

  constructor (props?: Partial<IMeterRead>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'customer':
            props[p] = new Customer(props[p])
            break
          case 'account':
            props[p] = new CommodityAccount(props[p])
            break
          default:
        }
      }
      Object.assign(this, props)
    }
  }

  public get received (): string {
    return this.created.format('YYYY-MM-DD')
  }

  public get receivedAge (): number {
    return moment().diff(this.created, 'day')
  }

  public get billingSystem (): string {
    return this.p2cId === null ? 'CIS' : 'P2C'
  }

  public get status (): string {
    if (this.lineItemId !== null) {
      return 'In Stage'
    }
    return 'Unstaged'
  }
}
