































import { Component, Vue, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import { Transaction } from '@/modules/customers/models/account/transaction'
import { TransactionsApi } from '@/modules/customers/api/transactions'

@Component({
  components: {
  }
})
export default class TransactionDetail extends Vue {
  @Prop({ default: false })
  private isDialog!: boolean

  @Prop({ required: true })
  private title!: string

  @Prop({ required: true })
  private loadDetails!: IStringIndexed

  private transaction: Transaction = new Transaction()

  private loading: boolean = true
  private errorMessage = ''
  private search = ''

  private headers: IStringIndexed = {
    2000: 'Transaction Information',
    4000: 'Customer Information',
    5000: 'Account Information',
    6000: 'Meter Information',
    5500: 'Exception Information',
    6500: 'Exception Information'
  }

  public skipBoring (row: IStringIndexed): IStringIndexed {
    const boring = ['recordIndicator', 'senderTransactionId', 'senderDuns', 'receiverDuns', 'actionId']
    var cleaned: IStringIndexed = {}
    Object.keys(row).forEach((key: string) => {
      if (!boring.includes(key) && row[key]?.length) {
        cleaned[key] = row[key]
      }
    })

    return cleaned
  }

  private handleClose () : void {
    this.$emit('dialog:close')
  }

  private async mounted () {
    try {
      this.transaction = await TransactionsApi.load(
        this.loadDetails.accountId, this.loadDetails.transId
      )
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.loading = false
    }
  }
}
