
























import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import FilesApi from '@/modules/customers/api/files'
import EventBus from '@/plugins/eventbus'
import NameBar from '../../components/blocks/NameBar.vue'
import { ListsEnum } from '../../../shared/enums'
import Customer from '../../models/customer'
import { File } from '../../../shared/models/file'

@Component({
  components: {
    'name-bar': NameBar
  }
})
export default class FileList extends Vue {
  @Prop({ required: true })
  private customerId!:number

  @Prop({ required: true })
  private files!: Array<File>

  @Prop({ default: false })
  private loading!: boolean

  private fileSearch = ''

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Category', align: 'left', sortable: true, filterable: false, value: 'categoryLabel' },
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Uploaded On', align: 'left', sortable: true, value: 'created' }
  ]

  private async downloadFile (file: File) {
    try {
      const newFile = await FilesApi.load(this.customerId, file.id)

      const blob = new Blob([newFile.binaryContents], { type: newFile.mimeType })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = file.name
      link.click()
      document.removeChild(link)
    } catch (err) {
      this.$emit('error', err.message)
    }
  }

  private handleClick (file: File) {
    this.$emit('file:selected', file)
  }
}
