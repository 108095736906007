



































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { mask } from 'vue-the-mask'
import { BillGroupDeliveryMethodEnum } from '@/modules/shared/enums'

@Component({
  directives: {
    mask
  }
})
export default class BillGroupCommunicationFormFields extends Vue {
  @PropSync('billGroup', {
    required: true,
    default: () => new BillGroup()
  })
  private localBillGroup!: BillGroup

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  // private fieldRules: IStringIndexed = {}

  private billDeliveryMethods = mapObjectVuetifySelect(BillGroup.deliveryMethods!, true)

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      billDeliveryMethods: [Rules.required()],
      attnOverride: [],
      emailTo: [Rules.email(), Rules.requiredIf(this.localBillGroup.billDeliveryMethod === BillGroupDeliveryMethodEnum.EMAIL ? '1' : '')],
      ccTo: [Rules.email()],
      bccTo: [Rules.email()],
      fax: [Rules.requiredIf(this.localBillGroup.billDeliveryMethod === BillGroupDeliveryMethodEnum.FAX ? '1' : '')]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {

  }
}
