
































import { Component, Vue, Prop, Watch, Provide } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store/index'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { CommodityEnum, CustomerTypeEnum } from '@/modules/shared/enums'
import { LineSeries, Legend, DateTime, Zoom, ScrollBar } from '@syncfusion/ej2-vue-charts'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import moment from 'moment'
import XLSX from 'xlsx'

@Component({
  components: {
  },
  provide: {
    chart: [LineSeries, DateTime, Legend, Zoom, ScrollBar]
  }
})
export default class HistoricalUsageChart extends Vue {
  @Prop({ required: true })
  private account!: IAccount

  @Prop({ required: true })
  private data!: IStringIndexed

  @Prop({ default: 'Historical Usage' })
  private chartTitle!: string

  @Prop({ default: false })
  private loading!: boolean

  private errorMessage = ''
  private interval = 'YYYY-MM-DD 00:00:00'
  private intervals = [
    { value: 'YYYY-MM-DD HH:mm:00', text: 'Hourly' },
    { value: 'YYYY-MM-DD 00:00:00', text: 'Daily' },
    { value: 'YYYY-MM-01 00:00:00', text: 'Monthly' }
  ]

  private primaryXAxis = {
    valueType: 'DateTime',
    labelFormat: 'MMM yy h:mm'//,
    // zoomFactor: 0.25, // @TODO Need to scale these based on date range in series
    // zoomPosition: 0.75
  }

  private primaryYAxis = {
    title: 'kWh',
    rangePadding: 'None',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 }
  }

  private zoom = {
    mode: 'X',
    enableMouseWheelZooming: true,
    enablePinchZooming: true,
    enableSelectionZooming: true,
    enablePan: true,
    enableScrollbar: true,
    enableautointervalonzooming: true
  }

  private legend = { visible: false }

  private get canDownloadUsage () {
    return AuthState.user.isAllowed('SERVICEACCOUNTS.USAGE.DOWNLOAD')
  }

  private get series () {
    const map: IStringDictionary<number> = {}
    const series: Array<IStringIndexed> = []

    for (const d in this.data) {
      const key = moment(d, 'YYYY-MM-DD HH:mm:ss').format(this.interval)
      if (!map[key]) {
        map[key] = 0
      }
      map[key] += parseFloat(this.data[d])
    }

    for (const d in map) {
      series.push({
        x: new Date(d),
        y: map[d]
      })
    }

    return series.sort((r, l) => r.x - l.x)
  }

  private handleExport () {
    const keys: Array<string> = []

    if (!this.series.length) {
      return
    }

    const hrs: IStringIndexed = {}
    const pivotHeaders = []
    for (let i = 0; i < 24; i++) {
      const k = (i < 10 ? '0' : '') + i.toString() + ':00'
      hrs[k] = 0
      pivotHeaders.push(k)

      if (i === 2) {
        hrs['02:59'] = 0
        pivotHeaders.push('02:59')
      }
    }

    const rows: Array<Array<string|number>> = [['Date', 'Time', 'Quantity']]

    const pivoted: IStringDictionary<IStringDictionary<number>> = {}

    this.series.forEach(s => {
      const m = moment(s.x)

      rows.push([m.format('MM/DD/YYYY'), m.format('HH:mm'), s.y])

      const mo = m.format('YYYY-MM-DD')
      if (!pivoted[mo]) {
        pivoted[mo] = Object.assign({}, hrs)
      }
      pivoted[mo][m.format('HH:mm')] += s.y
    })

    const pivotRows: Array<Array<string|number>> = [['Date', ...pivotHeaders]]
    for (const d in pivoted) {
      const row = []
      row.push(d)
      for (const q of pivotHeaders) {
        row.push(pivoted[d][q] || '-')
      }
      pivotRows.push(row)
    }

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(rows)
    XLSX.utils.book_append_sheet(wb, ws, 'Interval Data')

    if (this.interval === 'YYYY-MM-DD HH:mm:00') {
      const wsp = XLSX.utils.aoa_to_sheet(pivotRows)
      XLSX.utils.book_append_sheet(wb, wsp, 'Interval Data - Pivoted')
    }

    XLSX.writeFile(wb, this.account.accountNumber + '-Interval-Export-' + moment().format('YYYYMMDD-HHmm') + '.xlsx')
  }
}
