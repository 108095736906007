














































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import EventBus from '@/plugins/eventbus'
import { IStringIndexed } from '@/modules/shared/types'
import BillingManagementApi from '../../api/billing'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import moment from 'moment'
import AccountsApi from '@/modules/customers/api/accounts'
import { ElectricAccount } from '@/modules/shared/models/account/electric'
import { MeterRead } from '@/modules/shared/models/account/meterread'

interface IAccountRequest {
  accountNumber: string,
  capacityTag: number|null,
  annualConsumption: number|null,
  from: string,
  to: string,
  purpose: number,
  lmpType: string,
  includeUtilityPtc: number,
  includeUtilityShape: number
}

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class AccountQuoteForm extends Vue {
  private fetching: boolean = false
  private noErrors: boolean = true
  private valRules: Rules = Rules
  private errorMessage: string = ''

  private fields: IAccountRequest = {
    accountNumber: '',
    capacityTag: null,
    annualConsumption: null,
    from: moment().subtract(364, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    purpose: 0,
    lmpType: 'DA',
    includeUtilityPtc: 1,
    includeUtilityShape: 1
  };

  private menu = {
    from: false,
    to: false
  }

  private purposes = [
    { text: 'Pricing (Truelight)', value: 0 },
    { text: 'Costs (ISO)', value: 2 }
  ]

  private lmpTypes = [
    { text: 'Day Ahead', value: 'DA' },
    { text: 'Realtime', value: 'RT' }
  ]

  private accountSearching = false
  private account: ElectricAccount|null = null
  private usageSearching = false
  private meterReads: Array<MeterRead> = []
  private meterReadHeaders: Array<any> = [
    { text: 'Start Date', align: 'center', sortable: true, value: 'startDate' },
    { text: 'End Date', align: 'center', sortable: true, value: 'endDate' },
    { text: 'Quantity', align: 'right', sortable: true, value: 'quantity' },
    { text: 'Historical?', align: 'center', sortable: true, value: 'isHistorical' }
  ]

  public get fromLabel () {
    return moment(this.fields.from).format('MMM DD, YYYY')
  }

  public get toLabel () {
    return moment(this.fields.to).format('MMM DD, YYYY')
  }

  public handleAccountChange () {
    if (!this.fields.accountNumber.length) {
      return
    }

    this.accountSearching = false
    this.errorMessage = ''
    Vue.set(this, 'account', null)

    AccountsApi.loadAll({ accountNumber: this.fields.accountNumber })
      .then(accts => {
        if (!accts.length) {
          this.errorMessage = 'Account Not Found'
          return
        }

        // @TODO need to add a method with length > 1
        Vue.set(this, 'account', accts[0])
      }).catch(err => {
        this.errorMessage = err
      }).finally(() => {
        this.accountSearching = false
      })
  }

  public handleSubmit (): void {
    if (this.fetching) {
      return
    }

    if (!(this.$refs.accountForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.fetching = true
    this.$emit('quote:retrieved', null)
    this.$emit('utilityQuote:retrieved', null)

    BillingManagementApi.fetchAccountQuote(this.fields)
      .then((details: IStringIndexed) => {
        this.$emit('quote:retrieved', details.quote)
        this.$emit('utilityQuote:retrieved', details.utilityQuote)
        this.$emit('setTab', 0)
      })
      .catch(err => {
        EventBus.$emit('app-snack', {
          message: err
        })
      })
      .finally(() => {
        this.fetching = false
      })
  }

  @Watch('account', { deep: true })
  private handleAccountLoad () {
    Vue.set(this, 'meterReads', [])

    if (!this.account || !this.account.id) {
      return
    }

    this.usageSearching = true
    AccountsApi.loadSummaryReads(this.account.id)
      .then(reads => {
        Vue.set(this, 'meterReads', reads)

        if (reads.length) {
          reads.sort((a, b) => a.startDate < b.startDate ? 1 : -1)

          this.fields.to = reads[0].endDate

          this.fields.from = reads.length >= 11 ? reads[10].startDate : reads[reads.length - 1].startDate
        }
      }).catch(err => {
        this.errorMessage = err
      }).finally(() => {
        this.usageSearching = false
      })
  }

  public mounted () {

  }
}
