import { Route, Location } from 'vue-router'
import MenuItem from '@/modules/shared/menuitem'
import { IStringIndexed } from '@/modules/shared/types'
import { AuthState } from '@/modules/auth/store'

export default function routeToMenu (navName: string, routes: Array<IStringIndexed>) : Array<MenuItem> {
  const menu: Array<MenuItem & { order?: number }> = []

  routes.forEach(i => {
    if (i.meta.navs && i.meta.navs[navName]) {
      if (!i.meta.requiresAuth || (typeof i.meta.requiresAuth !== 'boolean' && !AuthState.user.isAllowed(i.meta.requiresAuth))) {
        return
      }
      const navConfig: IStringIndexed = i.meta.navs[navName]

      const item: MenuItem & { order?: number } = {
        text: navConfig.title || i.title
      }

      if (navConfig.order >= 0) {
        item.order = navConfig.order
      }

      if (navConfig.click) {
        item.click = navConfig.click
      } else {
        item.link = i.name ? { name: i.name } : <Location> { to: i.path }
      }

      if (navConfig.icon) {
        item.icon = navConfig.icon
      }

      if (navConfig.icons) {
        item.icons = navConfig.icons
      }

      if (i.children) {
        item.children = routeToMenu(navName, i.children)
      }

      menu.push(item)
    } else if (i.children) {
      const childMatches = routeToMenu(navName, i.children)
      if (childMatches.length) {
        menu.push(...childMatches)
      }
    }
  })

  return menu.sort((l, r) => l.text.localeCompare(r.text))
    .sort((l, r) => {
      const lOrder = l.order ?? 1000
      const rOrder = r.order ?? 1000

      if (lOrder === rOrder) {
        return 0
      }
      return lOrder > rOrder ? 1 : -1
    })
}
