


















import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import { Contract } from '@/modules/customers/models/customer/contract'

@Component({
  components: {
  }
})
export default class ContractAccounts extends Vue {
  @Prop({ required: true })
  private contractId!: number

  public get customer () {
    return CustomerState.customer
  }

  private get contract () {
    if (!this.contractId) {
      this.$router.push({ name: 'customercontracts' }).catch(() => {})
    }
    const contract = CustomerState.customer.contracts.find(a => {
      return a.id === this.contractId
    })

    if (!contract) {
      return new Contract({ customerId: CustomerState.customer.id })
    }
    return contract
  }

  public get loading () {
    return CustomerState.loading
  }

  public get accounts () {
    if (this.loading.accounts === null) {
      return []
    }

    return CustomerState.customer.accounts.filter(a => this.contract.accountIds.indexOf(a.id) !== -1)
  }

  public get utilities () {
    return AppState.utilities
  }
}
