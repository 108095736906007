var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_vm._v(" Invoice: "+_vm._s(_vm.invoice.invoiceNumber)+" ")]),_c('v-col',{staticClass:"d-flex align-right justify-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":"","title":"Download Hourly Detail","loading":_vm.processingList[_vm.invoice.id] === 'download',"disabled":_vm.processingList[_vm.invoice.id] !== undefined},on:{"click":function($event){return _vm.handleDownloadHourly(_vm.invoice)}}},[_c('v-icon',[_vm._v("$vuetify.icons.clock")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.accountInvoicesHeaders,"items":Object.values(_vm.accountInvoiceSummaries),"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"sort-by":"accountNumber","show-expand":_vm.canViewCharges,"expanded":_vm.accountInvoiceSummaries,"item-class":_vm.rowClasses,"loading-text":"Loading...please wait"},scopedSlots:_vm._u([{key:"item.netConsumption",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US').format(item.netConsumption))+" ")]}},{key:"item.commodityTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.commodityTotal))+" ")]}},{key:"item.otherTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.otherTotal))+" ")]}},{key:"item.taxTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.taxTotal))+" ")]}},{key:"item.invoiceTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.invoiceTotal))+" ")]}},{key:"item.openBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.openBalance))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"20"}},[_c('account-overviews',{attrs:{"invoiceId":_vm.invoiceId,"selectedSummary":item}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }