import { Base, IBaseModel } from '@/modules/shared/models/base'
import { CustomerPaymentTypeEnum, PaymentMethodEnum } from '@/modules/shared/enums'
import { CustomerPaymentTypes, PaymentMethods } from '@/modules/shared/lists'
import { CustomerPaymentApplication } from './payment/application'
import { CustomerInvoice } from './invoice'
import Customer from '../customer'

export interface ICustomerPayment extends IBaseModel {
  id: number;
  customerId: number;
  accountId: number|null;
  billGroupId: number|null;
  holdAsDeposit: number|null;
  relatedPaymentId: number|null;
  type: keyof CustomerPaymentTypes;
  glId: number|null;
  paymentMethodId: keyof PaymentMethods;
  payDate: string;
  voidDate: string;
  amount: number;
  pendingBalance: number;
  openBalance: number;
  referenceNumber: string;
  p2cId: string
}

export class CustomerPayment extends Base implements ICustomerPayment {
  public static paymentTypes = new CustomerPaymentTypes()
  public static paymentMethods = new PaymentMethods()

  public id = 0
  public customerId = 0
  public accountId = null
  public billGroupId: number|null = null
  public holdAsDeposit: number|null = null
  public relatedPaymentId = null
  public type = CustomerPaymentTypeEnum.PAYMENT
  public glId = null;
  public paymentMethodId = PaymentMethodEnum.ACH
  public payDate = ''
  public voidDate = ''
  public amount = 0
  public pendingBalance = 0
  public openBalance = 0
  public referenceNumber = ''
  public p2cId = ''

  public applications: Array<CustomerPaymentApplication> = []
  public customer!: Customer

  constructor (props?: Partial<ICustomerPayment>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'customerId':
          case 'accountId':
          case 'billGroupId':
          case 'holdAsDeposit':
          case 'relatedPaymentId':
          case 'paymentMethodId':
          case 'glId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'amount':
          case 'pendingBalance':
          case 'openBalance':
            props[p] = parseFloat(props[p]!.toString())
            break
          case 'applications':
            if (props[p]) {
              const objs: Array<CustomerPaymentApplication> = []
              for (const i in <Array<CustomerPaymentApplication>>props[p]) {
                objs.push(new CustomerPaymentApplication(props[p][i]))
              }

              props[p] = objs
            }
            break
          case 'customer':
            if (props[p]) {
              props[p] = new Customer(props[p])
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }

    if (this.applications.length) {
      for (const ap of this.applications) {
        if (ap.relatedApplicationId) {
          const rel = this.applications.find(a => a.id === ap.relatedApplicationId)
          if (rel) {
            rel.relatedApplication = ap
          }
        }
      }
    }
  }

  public get typeLabel () {
    return CustomerPayment.paymentTypes[this.type]
  }

  public get paymentMethodLabel () {
    return CustomerPayment.paymentMethods[this.paymentMethodId]
  }

  public get isPayment () {
    return this.type === CustomerPaymentTypeEnum.PAYMENT
  }

  public get isCredit () {
    return this.type === CustomerPaymentTypeEnum.CREDIT
  }

  public get isWriteOff () {
    return this.type === CustomerPaymentTypeEnum.WRITEOFF
  }

  public get isAppliable () {
    return this.openBalance > 0 && !this.voidDate
  }

  public get isPrepayment () {
    return ((this.holdAsDeposit ?? 0) !== 0) && !this.voidDate
  }

  public get isVoidable () {
    return !this.voidDate
  }

  public get isRefundable () {
    return this.openBalance > 0 && !this.voidDate
  }
}
