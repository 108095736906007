import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { AuthState } from '@/modules/auth/store'
import router from '@/router'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'

const esalesClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
})

esalesClient.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const now = new Date()
  if ((<Date>AuthState.logoutAt).getTime() < (now.getTime() - 3)) {
    await AuthState.RefreshLogin()
  }

  config.headers.Authorization = 'Bearer ' + AuthState.authToken
  return config
})

esalesClient.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const method = config.method ? <string> config.method.toUpperCase() : 'GET'

  if (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT' || method.toUpperCase() === 'DELETE') {
    if (process.env.VUE_APP_NOSAVES) {
      // throw new axios.Cancel('Saving not allowed right now')
    }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

export default esalesClient

// Must use return type any to ensure compiler doens't think Error is the
// actual return type and say it can't be assigned to whatever the happy
// path was
export function checkMessageOrThrow (err: AxiosError): any {
  const finalErr: Error = new Error(err.message)
  // ('Unexpected response format' + (AuthState.user.userType === AppUserTypes.getType('Admin') ? response.data : '')))
  if (err.response) {
    if (err.response.status === 404) {
      finalErr.name = '404'
      router.replace({ name: 'NotFound' })
    }
    if (err.response.status === 403) {
      finalErr.name = '403'
      const route = { name: 'Forbidden', props: { message: err.response.data.message || 'Permission Denied' } }
      router.replace(route)
    }
    if (err.response.data && err.response.data.message) {
      finalErr.name = (err.response.status || 'Unknown').toString()
      finalErr.message = err.response.data.message
    } else {
      finalErr.message = 'Unknown application error'
    }
  }

  throw finalErr
}

export function filtersToQs (params?: IStringDictionary<string | Array<string|number> | IStringIndexed>, keyName = 'filters') {
  let queryStr = ''
  if (params) {
    const query = filterParts(params, keyName)

    queryStr = '?' + query.join('&')
  }
  return queryStr
}

function filterParts (params: IStringDictionary<string | Array<string|number> | IStringIndexed>, keyName = 'filters') {
  const query: Array<string> = []
  Object.entries(params)
    .forEach(([prop, val], i) => {
      if (Array.isArray(val)) {
        val.forEach((v) => {
          query.push(keyName + '[' + encodeURIComponent(prop) + '][]=' + encodeURIComponent(v))
        })
      } else if (typeof val === 'object' && !!val) {
        // filters[customerName][term]=1234
        // filters[customerName][term][foo]=1234
        if (!isNaN(parseInt(prop))) {
          filterParts(val, keyName + '[]').forEach(v => query.push(v))
        } else {
          filterParts(val, keyName + '[' + encodeURIComponent(prop) + ']').forEach(v => query.push(v))
        }
      } else {
        if (!isNaN(parseInt(prop))) {
          query.push(keyName + '[]=' + encodeURIComponent(val))
        } else {
          query.push(keyName + '[' + encodeURIComponent(prop) + ']=' + encodeURIComponent(val))
        }
      }
    })
  return query
}
