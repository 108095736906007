




















import { Component, Vue, PropSync } from 'vue-property-decorator'
import ContractGeneralFormFields from '@/modules/customers/components/forms/contract/General.vue'
import ContractBillingFormFields from '@/modules/customers/components/forms/contract/Billing.vue'
import { ContractSubmission } from '../../models/queue/contractsubmission'

@Component({
  components: {
    'contract-general-fields': ContractGeneralFormFields,
    'contract-billing-fields': ContractBillingFormFields
  }
})
export default class CSQContractStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  private noErrors: boolean = true

  private mounted () {
  }
}
