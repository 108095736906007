



























import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import { CustomerPayment } from '@/modules/customers/models/customer/payment'
import { PaymentMethodEnum } from '@/modules/shared/enums'

@Component
export default class BillGroupPaymentFormFields extends Vue {
  @PropSync('billGroup', {
    required: true,
    default: () => new BillGroup()
  })
  private localBillGroup!: BillGroup

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  @Prop({ default: () => [] })
  private bankAccounts!: Array<BankAccount>

  @Prop({ default: () => [] })
  private creditCards!: Array<IStringIndexed>

  private paymentMethods = mapObjectVuetifySelect(CustomerPayment.paymentMethods!, true)

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      paymentMethodId: [Rules.required()],
      bankAccountId: [Rules.requiredIf(this.localBillGroup.paymentMethodId === PaymentMethodEnum.ACH ? '1' : '')],
      creditCardId: [Rules.requiredIf(this.localBillGroup.paymentMethodId === PaymentMethodEnum.CREDIT_CARD ? '1' : '')]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {
  }
}
