







import { Component, Vue, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import FinancePaymentsApi from '../../api/payments'
import PaymentUploadForm from '../../components/payments/uploads/PaymentUploadForm.vue'
import PaymentUploadList from '../../components/payments/uploads/PaymentUploadList.vue'
import { PaymentImportStatusEnum } from '@/modules/shared/enums'

@Component({
  components: {
    'payment-upload-form': PaymentUploadForm,
    'payment-upload-list': PaymentUploadList
  }
})
export default class PaymentUploads extends Vue {
  data: Array<IStringIndexed> = []

  private async handleUploadComplete () {
    await this.refresh()
  }

  async mounted () {
    await this.refresh()
  }

  private async refresh () {
    this.data = await FinancePaymentsApi.getPaymentImports()
  }
}
