



































import { Component, Vue } from 'vue-property-decorator'
import SearchForm from './forms/Search.vue'
import AdvancedSearchForm from './forms/AdvancedSearch.vue'

@Component({
  components: {
    'search-form': SearchForm,
    'advanced-search-form': AdvancedSearchForm
  }
})
export default class BottomNav extends Vue {
  private sheet = false
  private searchType = 0
}
