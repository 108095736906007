




















import { Component, Vue } from 'vue-property-decorator'
import { WholesaleState } from '../store'

@Component({
  components: {
  }
})
export default class WholesaleRoot extends Vue {
  private errorMessage = ''

  public mounted () {

  }

  public created () {
  }

  public activated () {
  }

  public updated () {
  }

  public destroyed () {
  }

  public deactivated () {

  }
}
