

























































































































import { IStringIndexed } from '@/modules/shared/types'
import { Component, Vue, Watch } from 'vue-property-decorator'
import BillingManagementApi from '../api/billing'

@Component({
  components: {
  }
})
export default class BillingLandPage extends Vue {
  private loading = false
  private usageStats: IStringIndexed = {}
  private errorMessage = ''

  private windows = [1, 7, 14, 30, 365]
  private window = 1

  private handleWindowUpdate (newWindow: number) {
    this.window = newWindow
  }

  @Watch('window', { immediate: true })
  private async handleWindowChange (newWindow: number, oldWindow: number) {
    if (newWindow !== oldWindow) {
      try {
        this.loading = true
        this.usageStats = await BillingManagementApi.getStatistics({ window: this.window.toString() /* , ignoreCache: '1' */ })
      } catch (err) {
        this.errorMessage = err
      } finally {
        this.loading = false
      }
    }
  }

  public async mounted () {

  }

  public created () {
  }

  public activated () {
  }

  public updated () {
  }

  public destroyed () {
  }

  public deactivated () {

  }
}
