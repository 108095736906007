import esalesClient, { filtersToQs, checkMessageOrThrow } from '@/api/clients/esales'
import ModelApi from '@/api/model'
import crmClient from '@/api/clients/crm'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import { CreditCheck } from '@/modules/finance/models/creditcheck'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import { IFile, File } from '@/modules/shared/models/file'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons'
import { PaymentMethods } from '@/modules/shared/lists'

interface IFileResponse {
  file: IFile;
}

export class CreditCheckApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['creditchecks']
    this.singular = 'check'
    this.plural = 'checks'
    this.factory = (...args: any) => new CreditCheck(...args)
  }

  public async loadAll (config?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<CreditCheck>> {
    return await this.loadAllSpecific(arguments, config) as Array<CreditCheck>
  }

  public async load (id: number, onlyProps?: Array<string>): Promise<CreditCheck> {
    return await this.loadSpecific(arguments, onlyProps) as CreditCheck
  }

  public async save (check: CreditCheck, noteText?: string) : Promise<CreditCheck> {
    const data: IStringIndexed = { ...check }

    if (noteText) {
      data.note = {
        source: 'CIS',
        body: noteText
      }
    }

    const args = Array.from(arguments).slice(0, -1)

    return await this.saveSpecific(args, data) as CreditCheck
  }

  public async finalize (check: CreditCheck, type: string, noteText?: string, paymentMethod?: keyof PaymentMethods) : Promise<CreditCheck> {
    const data: IStringIndexed = { ...check }

    if (noteText) {
      data.note = {
        source: 'CIS',
        body: noteText
      }
    }

    data.finalized = type

    if (paymentMethod) {
      data.restrictPaymentMethod = paymentMethod
    }

    const args = Array.from(arguments).slice(0, -3)

    return await this.saveSpecific(args, data) as CreditCheck
  }

  public async attachFile (checkId: number, file: File, callback?: Function): Promise<File> {
    try {
      const method = 'POST'

      const data = { ...file }
      data.contents = btoa(file.contents)

      const response: AxiosResponse = await this.client.request(
        {
          url: 'creditchecks/' + checkId + '/files',
          method: <Method> method,
          data: data,
          onUploadProgress: (e) => {
            if (callback) {
              callback(e)
            }
          }
        }
      )

      if (response.status === 200 && response.data.file) {
        response.data.file.contents = ''
        return new File((<IFileResponse> response.data).file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCrmMeters (accountIds: Array<number>): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await crmClient.get('meters' + filtersToQs({ id: accountIds }))
      if (response.status === 200) {
        return response.data.meters
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCrmFiles (fileIds: Array<number>): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await crmClient.get('files' + filtersToQs({ id: fileIds }) + '&additional[]=categoryLabel')
      if (response.status === 200) {
        return response.data.files
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCisFiles (itemId: number, fileIds: Array<number>): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await this.client.get('creditchecks/' + itemId + '/files' + filtersToQs({ id: fileIds }) + '&additional[]=categoryLabel')
      if (response.status === 200) {
        const files = []
        for (const d of response.data.files) {
          files.push(new File(d))
        }
        return files
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCrmFile (fileId: number): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await crmClient.get('files/' + fileId.toString())
      if (response.status === 200) {
        return response.data
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async loadCisFile (checkId: number, fileId: number): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await this.client.get('creditchecks/' + checkId + '/files/' + fileId.toString())
      if (response.status === 200) {
        return response.data.file
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const CreditChecksApi = new CreditCheckApi(esalesClient)
