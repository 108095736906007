





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BillGroup } from '@/modules/customers/models/customer/billgroup'
import { BillGroupDeliveryMethodEnum, ContactTypeEnum } from '@/modules/shared/enums'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import BillGroupGeneralFormFields from './billgroup/General.vue'
import BillGroupCommunicationFormFields from './billgroup/Communication.vue'
import BillGroupPaymentFormFields from './billgroup/Payment.vue'
import { BankAccount } from '../../models/customer/bankaccount'
import BillGroupBillingFormFields from './billgroup/Billing.vue'

@Component({
  components: {
    'general-fields': BillGroupGeneralFormFields,
    'communication-fields': BillGroupCommunicationFormFields,
    'billing-fields': BillGroupBillingFormFields,
    'payment-fields': BillGroupPaymentFormFields
  }
})
export default class BillGroupForm extends Vue {
  private errorMessage = ''
  private saving = false
  private noErrors = true

  @Prop({ default: () => new BillGroup() })
  private billgroup!: BillGroup

  @Prop({ default: false })
  private readonly!: boolean

  private billgroups = CustomerState.customer.billGroups

  private localBillGroup = new BillGroup()

  @Watch('billgroups', { immediate: true, deep: true })
  private billgroupUpdated (newBillGroups: Array<BillGroup>, oldBillGroups: Array<BillGroup>) {
    const match = newBillGroups.find(n => n.id === this.localBillGroup.id)

    if (match) {
      if (match.updatedAt !== this.localBillGroup.updatedAt) {
        this.errorMessage = 'WARNING: This bill group has been updated by someone else. And you may overwrite the changes'
      }
    }
  }

  public async handleSubmit () {
    if (this.saving || !this.canSave) {
      return
    }

    if (!(this.$refs.billgroupForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    if (this.localBillGroup.billDeliveryMethod === BillGroupDeliveryMethodEnum.EMAIL && this.localBillGroup.emailTo.length === 0) {
      this.errorMessage = 'You must specify an email address if the bill delivery method is Email'
      return
    }

    if (this.localBillGroup.billDeliveryMethod === BillGroupDeliveryMethodEnum.FAX && this.localBillGroup.fax.length === 0) {
      this.errorMessage = 'You must specify an fax number if the bill delivery method is Fax'
      return
    }

    const saveBillGroup: BillGroup = this.localBillGroup.clone()

    if (saveBillGroup.fax?.length) {
      saveBillGroup.fax = saveBillGroup.fax.replaceAll(/[^0-9]/g, '')
    }

    this.saving = true
    this.errorMessage = ''

    try {
      const billgroup: BillGroup = await CustomerState.SaveBillGroup(saveBillGroup)
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public handleClose (): void {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localBillGroup = new BillGroup()
  }

  public get contacts () {
    return CustomerState.customer.contacts
  }

  public get bankAccounts () {
    return Object.values(CustomerState.customer.bankaccounts).map((b: BankAccount) => {
      return {
        text: b.bankName + ' - ' + b.accountNumber,
        value: b.id
      }
    })
  }

  public get mode () {
    return this.localBillGroup.id ? 'Edit' : 'Add'
  }

  public get canSave () {
    if (this.readonly) {
      return false
    }

    if (this.localBillGroup.id) {
      return AuthState.user.isAllowed('CUSTOMER.BILLGROUPS.EDIT')
    }
    return AuthState.user.isAllowed('CUSTOMER.BILLGROUPS.CREATE')
  }

  public beforeMount () {
    this.localBillGroup = this.billgroup.clone()

    if (CustomerState.loading.banking === null) {
      CustomerState.LoadBankAccounts()
    }

    if (!this.localBillGroup.id) {
      this.localBillGroup.contactId = CustomerState.customer.contacts.find(c => c.type === ContactTypeEnum.PRIMARY)?.id ?? 0
    }

    if (!this.localBillGroup.customerId) {
      this.localBillGroup.customerId = CustomerState.customer.id
    }
  }
}
