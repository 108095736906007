



































































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import FinanceReportsApi from '@/modules/finance/api/reports'
import dtExporter from '@/modules/shared/datatablehelper'
import { IStringIndexed } from '@/modules/shared/types'
import EventBus from '@/plugins/eventbus'

@Component({
  components: {}
})
export default class GlFeedParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true

  private menu = {
    from: false,
    to: false
  }

  private from = moment().format('YYYY-MM-01')
  private to = moment().format('YYYY-MM-DD')
  private resultsSearch = ''
  private records: Array<IStringIndexed> = []
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'State', align: 'left', sortable: true, value: 'state' },
    { text: 'Customer Id', align: 'left', sortable: true, value: 'customerId' },
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName' },
    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber' },
    { text: 'LDC #', align: 'left', sortable: true, value: 'accountNumber', exportType: 'text' },
    { text: 'Item #', align: 'left', sortable: true, value: 'itemId' },
    { text: 'Date', align: 'left', sortable: true, value: 'financeDate', exportType: 'date' },
    { text: 'GL #', align: 'left', sortable: true, value: 'glAccount' },
    { text: 'Account', align: 'left', sortable: true, value: 'account' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount', exportType: 'money' },
    { text: 'Type', align: 'right', sortable: true, value: 'type' },
    { text: 'Consumption', align: 'right', sortable: true, value: 'consumption' }
  ]

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true
      this.records = await FinanceReportsApi.loadGlFeed({ from: this.from, to: this.to })

      if (!this.records.length) {
        EventBus.$emit('app-snack', {
          message: 'No Records Found',
          color: 'info'
        })
      }
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private exportList () {
    dtExporter(this.headers, this.records, 'GL Feed Export', 'GL-Feed-' + moment().format('YYYYMMDD-HHmm'))
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
