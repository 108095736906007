import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse, Method } from 'axios'
import { IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import billingEngineClient from '@/api/clients/billingengine'
import crmClient from '@/api/clients/crm'
import AccountsApi from '@/modules/shared/api/accounts'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { File as FileModel } from '@/modules/shared/models/file'

export default class BillingManagementApi {
  public static async getStatistics (filters?: IStringDictionary<string | Array<string|number>>): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await esalesClient.get('billing' + filtersToQs(filters, 'config'))

      if (response.status === 200 && response.data) {
        return response.data
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchGenericQuote (request: {
    utilityId: number,
    zone: string,
    rateClass: string,
    capacityTag: number,
    annualConsumption: number,
    from: string,
    to: string,
    purpose: number
  }) {
    try {
      const response: AxiosResponse = await billingEngineClient.request({
        url: 'genericquotes',
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: request
      })

      if (response.status === 200 && response.data) {
        return response.data.quote
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchAccountQuote (request: {
    accountNumber: string,
    capacityTag: number|null,
    annualConsumption: number|null,
    from: string,
    to: string,
    purpose: number
  }) {
    try {
      const response: AxiosResponse = await billingEngineClient.request({
        url: 'accountquotes',
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: request
      })

      if (response.status === 200 && response.data) {
        return response.data
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchMultiQuote (
    accounts: Array<IAccount>,
    from: string,
    to: string,
    purpose: number
  ) {
    try {
      const response: AxiosResponse = await billingEngineClient.request({
        url: 'multi-quotes',
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: {
          accounts: accounts,
          from: from,
          to: to
        }
      })

      if (response.status === 200 && response.data) {
        response.data.file = new FileModel({ contents: response.data.excelQuote })
        return response.data
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchOpportunityAccounts (custId: number, oppId: number) {
    const urlParams = '?additional[]=meters&include[]=meters'
    try {
      const response: AxiosResponse = await crmClient.get('customers/' + custId + '/opportunities/' + oppId + urlParams)

      if (response.status === 200 && response.data && response.data.opportunity && response.data.opportunity.meters) {
        const accountIds = response.data.opportunity.meters.map((m: IAccount) => m.externalSystemId)
        return AccountsApi.loadAll({ id: accountIds })
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchEpoChannelReport (accountIds: number, report: string, startDate: string, endDate: string) {
    try {
      const response: AxiosResponse = await esalesClient.get(
        'profiles/' + report,
        { params: { accountIds: accountIds, startDate: startDate, endDate: endDate } }
      )
      if (response.status === 200 && response.data) {
        return { file: new FileModel({ contents: response.data.contents }) }
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async fetchUtilityProfile (utilityId: number, rateClass: string, startDate: string, endDate: string) {
    try {
      const response: AxiosResponse = await billingEngineClient.get(
        'utility-profiles',
        { params: { utilityId: utilityId, rateClass: rateClass, from: startDate, to: endDate } }
      )
      if (response.status === 200 && response.data) {
        return response.data.profiles
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
