









import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'

@Component({})
export default class UserChip extends Vue {
  @Prop()
  private userId!: number

  private get getAppUserName (): string {
    return AppState.cisUsers[this.userId].detailName
  }
}
