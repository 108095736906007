import { Base } from './base'
import { IStringIndexed } from '../types'

export interface IEmail extends IStringIndexed{
  address: string;
  type?: number | null;
  priority: number;
}

export class Email extends Base implements IEmail {
  public id: number = 0
  public address = ''
  public type = null
  public priority = 0

  constructor (props?: Partial<IEmail>) {
    super(props)

    Object.assign(this, props)
  }
}
