import { AppUserTypes } from '@/modules/shared/lists'
import { AppUserTypeEnum } from '@/modules/shared/enums'
import { Base } from '@/modules/shared/models/base'

export default class CisUser extends Base {
  public id: number = 0
  public firstName: string | null = ''
  public lastName: string | null = ''
  public emailAddress: string | null = ''
  public companyName: string | null = ''
  public reportsToId!: number | null
  public userType!: keyof AppUserTypes | null
  public groupIds: Array<number> = []

  constructor (props: Partial<CisUser> = {}) {
    super(props)

    if (props) {
      if (typeof props.userType === 'number') {
        props.userType = props.userType.toString()
      }
      if (props.groupIds?.length) {
        props.groupIds = props.groupIds.map((i: number|string) => {
          if (typeof i === 'string') {
            return parseInt(i)
          }
          return i
        })
      }

      Object.assign(this, props)
    }
  }

  public get fullName (): string {
    return this.firstName + ' ' + this.lastName
  }

  public get detailName (): string {
    return this.fullName
  }
}
