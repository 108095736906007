var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.accountHeaders,"items":Object.values(_vm.selectedSummary.invoices),"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"sort-by":"accountNumber","show-expand":_vm.canViewCharges,"expanded":_vm.selectedDetail,"item-class":_vm.rowClasses,"loading-text":"Loading...please wait"},scopedSlots:_vm._u([{key:"item.accountNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAccountNumber(item))+" ")]}},{key:"item.billMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billMethodLabel)+" ")]}},{key:"item.waiveLateFees",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.waiveLateFees ? 'Y' : 'N')+" ")]}},{key:"item.netConsumption",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US').format(item.netConsumption))+" ")]}},{key:"item.cancelStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCancelStatus(item))+" ")]}},{key:"item.commodityTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.commodityTotal))+" ")]}},{key:"item.otherTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.otherTotal))+" ")]}},{key:"item.taxTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.taxTotal))+" ")]}},{key:"item.invoiceTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.invoiceTotal))+" ")]}},{key:"item.openBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.openBalance))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","title":"Download Hourly Detail","loading":_vm.processingList[_vm.invoice.id] === 'download',"disabled":_vm.processingList[_vm.invoice.id] !== undefined},on:{"click":function($event){return _vm.handleDownloadHourly(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.clock")])],1),(_vm.processingAction(item) !== 'disputing')?[(item.isDisputable && !item.disputed && _vm.canDisputeInvoice)?_c('v-btn',{attrs:{"icon":"","title":"Dispute"},on:{"click":function($event){return _vm.handleDispute(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.dispute")])],1):_vm._e(),(item.isDisputable && item.disputed && _vm.canDisputeInvoice)?_c('v-btn',{attrs:{"icon":"","title":"Cancel Dispute"},on:{"click":function($event){return _vm.handleCancelDispute(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.canceldispute")])],1):_vm._e()]:_c('v-progress-circular',{attrs:{"indeterminate":true}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"20"}},[_c('account-invoice-overview',{attrs:{"accountInvoice":item}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }