












import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'

@Component({})
export default class FscAutocomplete extends Vue {
  @Prop({ required: true })
  private value: any

  @Prop({ required: true })
  private items!: any

  @Prop({ default: () => { return [] } })
  private rules!: Array<Function>

  @Prop({ default: false })
  private chips!: boolean

  @Prop({ default: false })
  private hideDetails!: boolean

  @Prop({ default: '' })
  private menuProps!: string

  @Prop({ default: true })
  private clearable!: boolean

  @Prop({ default: true })
  private outlined!: boolean

  private search = ''

  private clearSearch () {
    this.$nextTick(() => {
      this.search = ''
    })
  }
}
