import esalesClient, { checkMessageOrThrow } from './clients/esales'
import { AxiosResponse } from 'axios'
import { ListsEnum } from '@/modules/shared/enums'
import { IStringDictionary, INumericDictionary, IStringIndexed } from '@/modules/shared/types'

interface IErrorResponse {
  errorMessage: string;
}

interface IListResponse {
  lists: {
    [key in ListsEnum]?: INumericDictionary<{ d: string; n: string }>
  };
}

export default class AppApi {
  /* This really returns a promise that's typed to an object */
  public static async loadAll (filters?: IStringDictionary<string>): Promise<{[key in ListsEnum]?: INumericDictionary<{ d: string; n: string }>}> {
    try {
      const response: AxiosResponse = await esalesClient.get('lists/', { params: filters })
      if (response.status === 200 && response.data.lists) {
        return response.data.lists
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public static async loadSettings (): Promise<IStringIndexed> {
    return Promise.resolve({
      app: {},
      customer: {
        maxTerm: 48
      }
    })
  }
}

export function validateJsonToken (tokenString: string): boolean {
  if (!tokenString || !tokenString.length || tokenString.indexOf('.') === -1) {
    return false
  }

  const token = JSON.parse(atob(tokenString.split('.')[1]))
  const now = new Date()
  const expire = new Date(token.exp * 1000)

  return now < expire
}
