







































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { ListsEnum } from '@/modules/shared/enums'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { Contact } from '@/modules/customers/models/customer/contact'

@Component
export default class ContactGeneralFormFields extends Vue {
  @PropSync('contact', {
    required: true,
    default: () => new Contact()
  })
  private localContact!: Contact

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  private contactTypes = mapObjectVuetifySelect(AppState.listsByName[ListsEnum.CONTACT_TYPES]!, true)

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      firstName: [Rules.requiredIfNot(this.localContact.firstName, 'Either first name or last name is required.')],
      middleName: [],
      lastName: [Rules.requiredIfNot(this.localContact.firstName, 'Either first name or last name is required.')],
      type: [Rules.required()],
      title: []
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {
  }
}
