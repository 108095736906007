















































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { IStringIndexed } from '@/modules/shared/types'
import { CreditCheck } from '../models/creditcheck'
import Rules from '@/plugins/validations'
import { CreditChecksApi } from '../api/creditchecks'
import EventBus from '@/plugins/eventbus'
import FinalizeForm from './forms/creditqueue/FinalizeForm.vue'
import FileUploadForm from './forms/creditqueue/FileUpload.vue'
import { File } from '@/modules/shared/models/file'

@Component({
  components: {
    'finalize-form': FinalizeForm,
    'upload-files': FileUploadForm
  }
})
export default class CreditQueueItem extends Vue {
  @Prop({ required: true })
  private item!: CreditCheck

  private errorMessage = ''
  private valRules: Rules = Rules
  private dialog: boolean = false
  private finalizeMode = ''

  private fileDialog: boolean = false

  private newNoteBody: string = ''

  private crmFiles: Array<IStringIndexed> = []
  private crmAccounts: Array<IStringIndexed> = []

  private cisFiles: Array<IStringIndexed> = []

  private fieldConfig = {
    liens: {
      label: 'Liens'
    },
    dnbRating: {
      label: 'DNB Rating'
    },
    highCredit: {
      label: 'High Credit'
    },
    paydexScore: {
      label: 'Paydex Score'
    },
    openJudgement: {
      label: 'Open Judgements'
    },
    viabilityScore: {
      label: 'Viability Score'
    },
    yearsInBusiness: {
      label: 'Years In Business'
    },
    tradeExperiences: {
      label: 'Trade Experiences'
    },
    financialStressScore: {
      label: 'Financial Stress Score'
    },
    commercialCreditScore: {
      label: 'Commercial Credit Score'
    },
    actualRating: {
      label: 'Actual Energy Rating'
    }
  }

  private download (file: IStringIndexed) {
    const byteString = atob(file.content ? file.content : file.contents)
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    const blob = new Blob([ia], { type: file.mimeType })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = file.fileName ? file.fileName : file.name
    link.click()
  }

  private async handleSave () {
    try {
      this.$emit('item:update', await CreditChecksApi.save(this.item, this.newNoteBody))
      this.newNoteBody = ''
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private handleFinalize (mode: string) {
    this.finalizeMode = mode
    this.dialog = true
  }

  private handleItemFinalized (item: CreditCheck) {
    this.$emit('item:finalize', item)
  }

  private async handleDownloadCrmFile (fileId: number) {
    try {
      const newFile = await CreditChecksApi.loadCrmFile(fileId)

      this.download(newFile)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private async handleDownloadCisFile (fileId: number) {
    try {
      const newFile = await CreditChecksApi.loadCisFile(this.item.id, fileId)

      this.download(newFile)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private handleDialogClose () {
    this.dialog = false
    this.finalizeMode = ''
  }

  private handleAttachFile () {
    this.fileDialog = true
  }

  private async handleFileUpload (item: { reading: boolean; completed: number; file: File }) {
    const newFile = await CreditChecksApi.attachFile(this.item.id, item.file, (e: ProgressEvent) => {
      item.reading = true
      item.completed = Math.round((e.loaded * 100) / e.total)
    })

    const newItem = this.item.clone()
    newItem.data.cisFileIds.push(newFile.id)
    this.cisFiles.push(newFile)
    this.$emit('item:update', newItem)
  }

  @Watch('item.id', { immediate: true, deep: true })
  private handleItemChange () {
    if (this.item.id) {
      if (this.item.data.crmAccountIds && this.item.data.crmAccountIds.length) {
        CreditChecksApi.loadCrmMeters(this.item.data.crmAccountIds)
          .then(accts => { Vue.set(this, 'crmAccounts', accts) })
      } else {
        this.errorMessage = 'No Accounts passed over from CRM, please have sales resubmit'
      }

      if (this.item.data.crmFileIds && this.item.data.crmFileIds.length) {
        CreditChecksApi.loadCrmFiles(this.item.data.crmFileIds)
          .then(files => { Vue.set(this, 'crmFiles', files) })
      }

      if (this.item.data.cisFileIds && this.item.data.cisFileIds.length) {
        CreditChecksApi.loadCisFiles(this.item.id, this.item.data.cisFileIds)
          .then(files => { Vue.set(this, 'cisFiles', files) })
      }
    }
  }
}
