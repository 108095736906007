import esalesClient, { filtersToQs, checkMessageOrThrow } from '@/api/clients/esales'
import { AxiosInstance, AxiosResponse, Method } from 'axios'
import ModelApi, { RequestParam } from '@/api/model'
import { INumericDictionary, IStringDictionary, IStringIndexed } from '@/modules/shared/types'
import WelcomeLetter from '../../models/queue/welcomeletter'
import { File as FileModel } from '@/modules/shared/models/file'

const loadAllParams = {
  additional: {
    // 0: 'contract',
    // contract: {
    //   0: 'customer',
    //   customer: ['contacts']
    // }
    0: 'customer',
    customer: ['contacts']
  }
}

export class WelcomeLetterApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['queue-welcome-letters']
    this.singular = 'welcomeLetter'
    this.plural = 'welcomeLetters'
    this.factory = (...args: any) => new WelcomeLetter(...args)
  }

  public async load (customerId: number, id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<WelcomeLetter> {
    return await this.loadSpecific(arguments, onlyProps, extraProps) as WelcomeLetter
  }

  public async savePartial (letter: WelcomeLetter, props: Array<string>) : Promise<WelcomeLetter> {
    const data: IStringIndexed = { ...letter }

    return await this.savePartialSpecific([letter.id], data, props)
  }

  public async loadAll (filters?: RequestParam): Promise<Array<WelcomeLetter>> {
    try {
      const url = 'queue-welcome-letters'

      const response: AxiosResponse = await esalesClient.request({
        url: url,
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: { filters: filters, ...loadAllParams }
      })

      if (response.status === 200 && response.data.welcomeLetters) {
        const list: Array<WelcomeLetter> = []

        Object.values(response.data.welcomeLetters as INumericDictionary<IStringIndexed>).forEach((i: IStringIndexed) => {
          list.push(new WelcomeLetter(i))
        })

        return list
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async performAction (welcomeLetter: WelcomeLetter, doAction: string, data ?: IStringIndexed): Promise<WelcomeLetter> {
    try {
      const url = 'queue-welcome-letters/' + welcomeLetter.id

      if (!data) {
        data = {}
      }

      data.do = doAction
      data.snapshotAt = welcomeLetter.updatedAt

      const response: AxiosResponse = await esalesClient.put(url, { ...data, ...loadAllParams })

      if (response.status === 205) {
        // return {
        //   welcomeLetter: new WelcomeLetter(await WelcomeLetterApi.load(welcomeLetter.id)),
        //   message: 'Invoice was updated, please review and retry your action as appropriate'
        // }
      } else if (response.status === 200 && response.data.welcomeLetter) {
        return new WelcomeLetter(response.data.welcomeLetter)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async sendLetter (welcomeLetter: WelcomeLetter): Promise<{ statusUpdates: IStringDictionary<string>, downloadFile?: FileModel, errorMessage?: string }> {
    try {
      const url = 'queue-welcome-letters'

      // const response: AxiosResponse = await esalesClient.put(url, data)
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async sendLetters (welcomeLetters: Array<WelcomeLetter>): Promise<{ statusUpdates: IStringDictionary<string>, downloadFile?: FileModel, errorMessage?: string }> {
    try {
      const url = 'queue-welcome-letters'
      const foo: Array<number> = []

      const data = {
        welcomeLetterIds: welcomeLetters.reduce((c : Array<number>, i: WelcomeLetter) => { c.push(i.id); return c }, [] as Array<number>)
      }

      const response: AxiosResponse = await esalesClient.put(url, data)

      if (response.status === 200) {
        const resp: { statusUpdates: IStringDictionary<string>, downloadFile?: FileModel, errorMessage?: string } = {
          statusUpdates: response.data.statusUpdates
        }

        if (response.data.downloadDocument) {
          resp.downloadFile = new FileModel({ contents: response.data.downloadDocument })
        }

        if (response.data.error) {
          resp.errorMessage = response.data.error
        }

        return resp
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async getPdf (welcomeLetter: WelcomeLetter) : Promise<FileModel> {
    try {
      const response = await esalesClient.request({
        url: this.buildUrl([welcomeLetter.id]),
        method: <Method> 'POST',
        headers: {
          'X-HTTP-Method-Override': 'GET'
        },
        data: {
          with: 'pdf'
        }
      })

      if (response.status === 200 && response.data.pdf) {
        return new FileModel(response.data.pdf)
      } else if (response.data.message) {
        throw new Error(response.data.message)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async getStatistics (filters?: IStringDictionary<string | Array<string|number>>): Promise<Array<IStringIndexed>> {
    try {
      const response: AxiosResponse = await esalesClient.get('queue-statistics/WelcomeLetters' + filtersToQs(filters, 'config'))

      if (response.status === 200 && response.data) {
        return response.data.statistics
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export const WelcomeLettersApi = new WelcomeLetterApi(esalesClient)
