
















import { Component, Vue, Prop } from 'vue-property-decorator'
import MenuItem from '@/modules/shared/menuitem'

@Component({
  components: {}
})
export default class StandardSizeNav extends Vue {
  private drawer = true

  @Prop({ required: true })
  private menu!: Array<MenuItem>;

  @Prop({ default: 176 })
  private width!: number;

  private get filteredItems () {
    return this.menu.filter(i => i.permission)
  }

  private get mini () {
    return this.$vuetify.breakpoint.smAndDown
  }
}
