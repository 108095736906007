import WholesaleRoot from '../pages/Wholesale.vue'
import SettlementFiles from '../pages/subpages/SettlementFiles.vue'
import Volumes from '../pages/subpages/Volumes.vue'
import NormalizedCosts from '../pages/subpages/NormalizedCosts.vue'
import Lmps from '../pages/subpages/Lmps.vue'
import RootSideNav from '../components/sidenavs/Root.vue'
import { Route } from 'vue-router'

const rootNav = {
  key: 'root',
  width: 200
}

export default [
  {
    name: 'wholesaleroot',
    path: '/wholesale',
    components: {
      SideNav: RootSideNav,
      default: WholesaleRoot
    },
    title: 'Wholesale',
    meta: { requiresAuth: 'WHOLESALE.VIEW' },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'wholesalesettlementfiles',
    path: '/wholesale/settlement-files',
    components: {
      SideNav: RootSideNav,
      default: SettlementFiles
    },
    title: 'Settlement Files',
    meta: {
      requiresAuth: 'WHOLESALE.SETTLEMENTS.VIEW',
      navs: {
        root: {
          icon: 'upload'
        }
      }
    },
    props: {
      nav: {
        key: 'root'
      }
    }
  },
  {
    name: 'financelmps',
    path: '/finance/lmps',
    components: {
      SideNav: RootSideNav,
      default: Lmps
    },
    title: 'LMPs',
    meta: {
      requiresAuth: 'WHOLESALE.LMPS.VIEW',
      navs: {
        root: {
          icon: 'billing'
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'wholesalevolumes',
    path: '/wholesale/volumes',
    components: {
      SideNav: RootSideNav,
      default: Volumes
    },
    title: 'Volumes',
    meta: {
      requiresAuth: 'WHOLESALE.VOLUMES.VIEW',
      navs: {
        root: {
          icon: 'market'
        }
      }
    },
    props: {
      nav: {
        key: 'root'
      }
    }
  },
  {
    name: 'wholesalecosts',
    path: '/wholesale/costs',
    components: {
      SideNav: RootSideNav,
      default: NormalizedCosts
    },
    title: 'Costs',
    meta: {
      requiresAuth: 'WHOLESALE.COSTS.VIEW',
      navs: {
        root: {
          icon: 'dollar'
        }
      }
    },
    props: {
      nav: {
        key: 'root'
      }
    }
  }
]
