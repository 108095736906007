










































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import LineItems from './LineItems.vue'
import { INumericDictionary } from '@/modules/shared/types'
import { CustomerInvoice } from '@/modules/customers/models/customer/invoice'
import { AccountInvoice } from '@/modules/customers/models/account/invoice'
import LineItemChargeDetails from '@/modules/customers/components/blocks/invoice/ChargeDetails.vue'
import { InvoiceLineItem } from '@/modules/customers/models/account/invoice/lineitem'
import { InvoiceQueuesApi } from '@/modules/billing/api/queue/invoicequeues'
import EventBus from '@/plugins/eventbus'
import { AuthState } from '@/modules/auth/store'

@Component({
  components: {
    'line-items': LineItems,
    'charge-details': LineItemChargeDetails
  }
})
export default class InvoiceDetail extends Vue {
  @Prop({ required: true, default: () => new CustomerInvoice() })
  private invoice!: CustomerInvoice

  @Prop({ required: false })
  private detailId!: number

  private selectedCharge: InvoiceLineItem|null = null
  private selectedDetail: Array<AccountInvoice> = []
  private processingList: INumericDictionary<string> = {}

  private invoiceHeaders: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Account #', align: 'left', sortable: true, value: 'accountNumber' },
    { text: 'Method', align: 'left', sortable: true, value: 'billMethod' },
    { text: 'Charges', align: 'left', sortable: true, value: 'chargesDescription' },
    { text: 'Net Consumption', align: 'right', sortable: true, value: 'netConsumption' },
    { text: 'Commodity', align: 'right', sortable: true, value: 'commodityTotal' },
    { text: 'Other', align: 'right', sortable: true, value: 'otherTotal' },
    { text: 'Taxes', align: 'right', sortable: true, value: 'taxTotal' },
    { text: 'Total', align: 'right', sortable: true, value: 'invoiceTotal' },
    { text: 'Rate.', align: 'right', sortable: true, value: 'rate' },
    { text: 'Actions', align: 'left', sortable: true, value: 'actions' },
    { text: '', align: 'left', sortable: true, value: 'data-table-expand' }
  ]

  public processingAction (invoice: AccountInvoice) {
    return this.processingList[invoice.id] || false
  }

  private get canEditQueueItem () {
    return AuthState.user.isAllowed('BILLING.QUEUES.INVOICEAPPROVAL.EDIT')
  }

  private canRemoveInvoice (invoice: AccountInvoice) {
    if (this.invoice.invoices.length === 1) {
      return false
    }
    return true
  }

  public rowClasses (item: AccountInvoice) {
    let rowClass = ''

    if (item.voidDate) {
      rowClass += ' text-decoration-line-through'
    }

    return rowClass
  }

  private handleSelectCharge (charge: InvoiceLineItem) {
    this.selectedCharge = charge
  }

  @Watch('invoice.id')
  private handleInvoiceChangeView (newId: number, oldId: number) {
    if (newId !== oldId && this.invoice.invoices.length === 1) {
      this.selectedDetail.push(this.invoice.invoices[0])
    }
  }

  private async performAction (action: string, invoice: AccountInvoice) {
    try {
      this.processingList[invoice.id] = action

      const resp = await InvoiceQueuesApi.performAction(this.invoice, action, { invoiceId: invoice.id })

      this.$emit('item:update', resp.invoice)
      if (resp.message) {
        EventBus.$emit('app-snack', {
          message: resp.message,
          color: 'secondary',
          timeout: 5000
        })
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err,
        timeout: 5000
      })
    } finally {
      Vue.delete(this.processingList, invoice.id)
    }
  }

  private async handleRebillRead (invoice: AccountInvoice) {
    return this.performAction('rebill', invoice)
  }

  private async handleRemoveInvoice (invoice: AccountInvoice) {
    return this.performAction('remove', invoice)
  }

  private async handleVoidCharge (invoice: AccountInvoice) {
    this.performAction('void', invoice)
  }

  private mounted () {
    if (this.detailId) {
      this.selectedDetail.push(this.invoice.invoices.find(i => i.id === this.detailId)!)
    } else {
      this.selectedDetail.push(this.invoice.invoices[0])
    }
  }
}
