




































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Contact } from '@/modules/customers/models/customer/contact'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import AddressFormFields from './fields/Address.vue'
import ContactGeneralFormFields from './contact/General.vue'
import ContactCommunicationFormFields from './contact/Communication.vue'

@Component({
  components: {
    'general-fields': ContactGeneralFormFields,
    'address-fields': AddressFormFields,
    'communication-fields': ContactCommunicationFormFields
  }
})
export default class ContactForm extends Vue {
  private errorMessage = ''
  private saving = false
  private noErrors = true

  @Prop({ default: () => new Contact() })
  private contact!: Contact

  private contacts = CustomerState.customer.contacts

  private localContact = new Contact()

  @Watch('contacts', { immediate: true, deep: true })
  private contactUpdated (newContacts: Array<Contact>, oldContacts: Array<Contact>) {
    const match = newContacts.find(n => n.id === this.localContact.id)

    if (match) {
      if (match.updatedAt !== this.localContact.updatedAt) {
        this.errorMessage = 'WARNING: This contact has been updated by someone else. And you may overwrite the changes'
      }
    }
  }

  public async handleSubmit () {
    if (this.saving || !this.canSave) {
      return
    }

    if (!(this.$refs.contactForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    if (Object.values(this.localContact.phoneNumbers).length === 0 && Object.values(this.localContact.emailAddresses).length === 0) {
      this.errorMessage = 'At least one contact method needs to be specified'
      return
    }

    const saveContact: Contact = this.localContact.clone()

    if (saveContact.workPhone?.length) {
      saveContact.workPhone = saveContact.workPhone.replaceAll(/[^0-9]/g, '')
    }

    if (saveContact.otherPhone?.length) {
      saveContact.otherPhone = saveContact.otherPhone.replaceAll(/[^0-9]/g, '')
    }

    if (saveContact.fax?.length) {
      saveContact.fax = saveContact.fax.replaceAll(/[^0-9]/g, '')
    }

    this.saving = true
    this.errorMessage = ''

    try {
      const contact: Contact = await CustomerState.SaveContact(saveContact)
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localContact = new Contact()
  }

  public get mode () {
    return this.localContact.id ? 'Edit' : 'Add'
  }

  public get canSave () {
    if (this.localContact.id) {
      return AuthState.user.isAllowed('CUSTOMER.CONTACTS.EDIT')
    }
    return AuthState.user.isAllowed('CUSTOMER.CONTACTS.CREATE')
  }

  public beforeMount () {
    this.localContact = this.contact.clone()

    if (!this.localContact.id) {
      this.localContact.address = CustomerState.customer.address.clone()
    }
    if (!this.localContact.customerId) {
      this.localContact.customerId = CustomerState.customer.id
    }
  }
}
