











































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import router from '@/router'
import { SearchState } from '../store'
import { ISearchResult } from '../models/searchresult'

@Component({})
export default class SearchResults extends Vue {
  @Prop({ required: true })
  private searchResults!: Array<ISearchResult>

  private selectAll = false
  private resultsFilter = ''
  private localResults: Array<ISearchResult> = []

  private headers: Array<any> = [
    { text: 'ID', align: 'center', sortable: false, value: 'id' },
    { text: 'Company Name', align: 'left', sortable: false, value: 'fullName' },
    { text: 'Primary Contact', align: 'left', sortable: false, value: 'primaryContact' },
    { text: 'Location', align: 'left', sortable: false, value: 'location' },
    { text: '# Accounts', align: 'center', sortable: false, value: 'numAccounts' },
    { text: 'Utilities', align: 'center', sortable: false, value: 'utility' }
  ]

  private errorMessage = ''

  private get isDense () {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get results () {
    return this.localResults
  }

  private get searchTerm () {
    return SearchState.searchParam.term
  }

  public get lastSearch () {
    return SearchState.resultsExpire.clone().subtract(SearchState.cacheMins, 'minute').format('LT')
  }

  private isViewable (ownerIds: Array<number>) {
    return AuthState.user.isAllowed('CUSTOMER_MGMT.CUSTOMER.VIEW')
  }

  private gotoCustomer (item: ISearchResult) {
    router.push({ name: 'customer', params: { id: item.id.toString() } })
  }

  private rowClass (item: ISearchResult) {
    return 'allowed' // : 'blocked'
  }

  @Watch('searchResults', { immediate: true, deep: true })
  private handleNewResults () {
    this.localResults = this.searchResults.slice()
  }

  public beforeMount () {

  }
}
