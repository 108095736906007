import { Base } from '@/modules/shared/models/base'
import { ListsEnum } from '@/modules/shared/enums'
import { AppState } from '@/stores/appStore'
import { IStringIndexed } from '../types'

export interface IFile extends IStringIndexed {
  categoryId?: number;
  name: string;
  contents: string;
  mimeType: string;
}

export class File extends Base implements IFile {
  public id: number = 0
  public categoryId = 0
  public name = ''
  public contents = ''
  public mimeType = ''

  constructor (props?: Partial<IFile>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'categoryId':
            this[p] = parseInt(props[p]?.toString() as string)
            break
          default:
            this[p] = props[p]
        }
      }
    }
  }

  public get categories () {
    return AppState.listsByName[ListsEnum.FILE_CATEGORIES]
  }

  public get categoryLabel (): string {
    const cats = this.categories

    if (cats) {
      return cats[this.categoryId]
    }
    return ''
  }

  public get binaryContents () {
    const byteString = atob(this.contents)
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return ia
  }
}
