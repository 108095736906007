import { AppState } from '@/stores/appStore'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import SalesUserContract from '@/modules/sales/models/salesuser/contract'
import { BillMethodEnum, ListsEnum } from '@/modules/shared/enums'
import Rules from '@/plugins/validations'
import { IStringDictionary } from '@/modules/shared/types'
import { BillMethods } from '@/modules/shared/lists'

export interface ICustomerContract extends IBaseModel {
  id: number;
  customerId: number;
  productId: number;
  billMethodId: keyof BillMethods;
  dateSigned: string;
  startMonth: string;
  term: number;
  netDueDays: number;
  retailAdder: number|null;
  p2cId: string|null;
  commissions: Array<SalesUserContract>;
  accountIds: Array<number>;
}

export class Contract extends Base implements ICustomerContract {
  public static MAX_COMMISSIONS_SLOTS = 3
  public static MIN_RETAIL_ADDER = 0.0025
  public static MAX_RETAIL_ADDER = 0.021

  public static billMethods = new BillMethods()

  public id = 0
  public customerId = 0
  public productId = 0
  public billMethodId = BillMethodEnum.DUAL
  public dateSigned = ''
  public startMonth = ''
  public term = 0
  public netDueDays = 10
  public retailAdder: number|null = null
  public p2cId = null
  public accountIds: Array<number> = []

  public commissions: Array<SalesUserContract> = []

  constructor (props?: Partial<ICustomerContract>) {
    super(props)

    if (props) {
      for (const p in props) {
        if (props[p] === undefined) {
          continue
        }

        switch (p) {
          case 'customerId':
          case 'productId':
          case 'billMethodId':
          case 'term':
          case 'netDueDays':
            props[p] = parseInt(props[p]!.toString())
            break
          case 'retailAdder':
            if (props[p]) {
              props[p] = parseFloat(props[p]!.toString())
            }
            break
          case 'accountIds':
            if (props[p] && props[p]!.length) {
              const objs: Array<number> = []
              for (const acctId of props[p]!) {
                objs.push(parseInt(acctId.toString()))
              }
              props[p] = objs
            }
            break
          case 'commissions':
            if (props[p] && Object.values(<Array<SalesUserContract>>props[p]).length) {
              const objs = []
              for (const c in props[p]!) {
                objs.push(new SalesUserContract(props[p]![c]))
              }
              props[p] = objs
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }

    if (this.commissions.length < Contract.MAX_COMMISSIONS_SLOTS) {
      for (let p = this.commissions.length; p < Contract.MAX_COMMISSIONS_SLOTS; p++) {
        this.commissions.push(new SalesUserContract({ contractId: this.id }))
      }
    }
  }

  public get productLabel () {
    return (AppState.listsByName[ListsEnum.PRODUCTS_ELECTRIC]![this.productId])
  }

  public get billMethodLabel () {
    return Contract.billMethods[this.billMethodId]
  }

  public get termLabel () {
    return this.term === 0 ? 'M2M' : this.term.toString() + ' mos.'
  }

  public get description () {
    return this.termLabel + ' ' + this.productLabel
  }

  public get validations () : IStringDictionary<Array<Function>> {
    switch (this.productId) {
      case 2:
      case 1:
        return {
          retailAdder: [Rules.required(), Rules.greaterThan(Contract.MIN_RETAIL_ADDER), Rules.lessThan(Contract.MAX_RETAIL_ADDER)]
        }
        break
      default:
        return {}
    }
  }
}
