

















































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { TaxExemptItemCchTypeEnum, TaxExemptItemStatusEnum, UserGroupsEnum } from '@/modules/shared/enums'
import DialogHost from '@/modules/shared/mixins/DialogHost.vue'
import { TaxExemptForm } from '@/modules/customers/models/customer/taxexemptform'
import FilesApi from '@/modules/customers/api/files'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'

@Component({
  components: {}
})
export default class TaxExemptFormForm extends Mixins(DialogHost, DownloadFile) {
  @Prop({ required: true, default: () => new TaxExemptForm() })
  private item!: TaxExemptForm

  @Prop({ default: 'finance' })
  private context!: string

  private loadingFile = false
  private loadingAccounts = false
  private effectiveMenu = false
  private expireMenu = false
  private errorMessage = ''

  public statuses = mapObjectVuetifySelect(TaxExemptForm.statuses, true)
  public cchReasons = mapObjectVuetifySelect(TaxExemptForm.cchReasonList, false)
  public cchTypes = mapObjectVuetifySelect(TaxExemptForm.cchTypeList, false)

  public get listCchTypes () {
    return this.cchTypes.sort((a, b) => (a.text <= b.text) ? -1 : 1)
  }

  public get selectedTypesLength () {
    return this.item.cchTypes.length
  }

  private get dataservicesGroupId () {
    return UserGroupsEnum.DATA_SERVICES
  }

  private async handleItemEdited () {
    this.$emit('item:updated', this.item)
  }

  private async handleFileDownload () {
    this.loadingFile = true
    const item = this.item

    try {
      if (item.fileId) {
        const file = await FilesApi.load(item.customerId, item.fileId)
        this.downloadFile(file.name, file.binaryContents, 'application/pdf')
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.loadingFile = false
    }
  }
}
