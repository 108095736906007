var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"color":"inherit","background-color":"inherit"},attrs:{"headers":_vm.paymentHeaders,"items":_vm.payments,"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"sort-by":"id","sort-desc":true,"item-class":_vm.colorizeRow,"loading-text":"Loading...please wait"},scopedSlots:_vm._u([{key:"header.actions",fn:function(ref){return [_c('v-icon',{attrs:{"color":_vm.showReversed ? 'primary': '',"title":"Toggle Reversed Filter"},on:{"click":function($event){$event.stopPropagation();_vm.showReversed = !_vm.showReversed}}},[_vm._v("$vuetify.icons.reversepmt")])]}},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.getCustomerPayment(item).payDate,'MM/DD/YYYY'))+" ")]}},{key:"item.applicationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.applicationDate,'MM/DD/YYYY'))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.amount))+" ")]}},{key:"item.paymentId",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'customerpaymentdetail', params: { paymentId: item.paymentId } }}},[_vm._v(_vm._s(item.paymentId))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.processingAction(item) !== 'reverse')?[(_vm.canReverseApplication && item.isReversible)?_c('v-btn',{attrs:{"icon":"","title":"Reverse Application","disabled":_vm.processingAction(item) !== false},on:{"click":function($event){return _vm.handleReverseApplication(item)}}},[_c('v-icon',[_vm._v(" $vuetify.icons.reversepmt ")])],1):_vm._e()]:_c('v-progress-circular',{attrs:{"indeterminate":true}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }