
























































import { Component, Vue, Prop } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { CustomerState } from '@/modules/customers/store/index'
import { CustomerAccountingStatusEnum, CustomerStatusEnum, CustomerTypeEnum } from '../../../shared/enums'
import moment from 'moment'

@Component({
  inheritAttrs: true
})
export default class NameBar extends Vue {
  @Prop({ default: true })
  private app!: boolean

  @Prop({ default: 48 })
  private extensionHeight!: number

  @Prop({ default: false })
  private flat!: boolean

  @Prop({ default: false })
  private allowEdit!: boolean

  private intervalTracker: number = 0
  private currentTime = new Date()

  public get loading () {
    return CustomerState.loading
  }

  public get customer () {
    return CustomerState.customer
  }

  public get lastLoaded () {
    return CustomerState.customerCachedAt
  }

  public get isOldData () {
    return moment(CustomerState.customerCacheExpire).isSameOrBefore(this.currentTime)
  }

  public handleReloadCustomer () {
    CustomerState.Load({ id: this.customer.id, ignoreCache: true })
  }

  public get statusColor () {
    switch (this.customer.status) {
      case CustomerStatusEnum.NEW:
        return 'info'
      case CustomerStatusEnum.RESTARTING:
        return 'teal'
      case CustomerStatusEnum.ACTIVE:
        return 'green'
      case CustomerStatusEnum.INACTIVE:
        return 'grey'
    }
  }

  public get accountingStatusColor () {
    switch (this.customer.accountingStatus) {
      case CustomerAccountingStatusEnum.CREDITREVIEW:
        return 'yellow'
      case CustomerAccountingStatusEnum.CURRENT:
        return 'green'
      case CustomerAccountingStatusEnum.PASTDUE:
        return 'red'
      case CustomerAccountingStatusEnum.COLLECTIONS:
        return 'brown'
      case CustomerAccountingStatusEnum.CLOSED:
        return 'grey'
    }
  }

  public get iconSize () {
    let size = 'medium'
    if (this.customer.iconIs('small')) {
      size = 'small'
    } else if (this.customer.iconIs('large')) {
      size = 'large'
    }

    if (this.$vuetify.breakpoint.xs) {
      switch (size) {
        case 'small':
          size = 'xs'
          break
        case 'large':
          size = 'medium'
          break
        default:
          size = 'small'
      }
    }

    return {
      [size]: true
    }
  }

  private mounted () {
    this.intervalTracker = setInterval(() => {
      this.currentTime = new Date()
    })
  }

  private destroyed () {
    if (this.intervalTracker) {
      clearInterval(this.intervalTracker)
      this.intervalTracker = 0
    }
  }
}
