
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { IStringIndexed } from '@/modules/shared/types'
import ActiveCustomerParametersForm from '../../components/forms/reports/ActiveCustomer.vue'
import IdrLogParametersForm from '../../components/forms/reports/IdrApiLog.vue'

@Component
export default class Reports extends Vue {
  @Prop({ default: '' })
  private reportName!: string

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'Name', align: 'left', sortable: true, value: 'name' },
    { text: 'Action', align: 'center', width: 50, sortable: false, value: 'action' }
  ]

  private reports = [
    { name: 'Active Customers', permission: 'FINANCE.REPORTS.AGING', component: ActiveCustomerParametersForm },
    { name: 'IDR API Log', permission: 'FINANCE.REPORTS.AGING', component: IdrLogParametersForm }
  ];

  private reportSearch: string = ''

  private get filteredReports () {
    return this.reports.filter(r => this.itemViewable(r))
  }

  private loadReport (item: IStringIndexed) {
    this.$router.push({ name: 'dataservicesreportviewer', params: { reportName: item.name } }).catch(() => {})
  }

  private itemViewable (item: IStringIndexed) : boolean {
    return item.permission ? AuthState.user.isAllowed(item.permission) : true
  }

  private itemViewedClass (item: IStringIndexed): string {
    if (item.name === this.reportName) {
      return 'blue lighten-3'
    }
    return ''
  }
}
