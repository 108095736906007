







































































import { Component, Vue } from 'vue-property-decorator'
// import { AuthState } from '@/modules/auth/store'
// import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'
import moment from 'moment'
import p2cApi from '@/modules/shared/api/p2c'
import { IStringIndexed } from '@/modules/shared/types'
import dtExporter from '@/modules/shared/datatablehelper'

@Component({
  components: {}
})
export default class StatementsReportParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private showAllFields = false

  private menu = {
    from: false,
    to: false
  }

  private from = moment().format('YYYY-MM-DD')
  private to = moment().format('YYYY-MM-DD')
  private onlyOpenBalances = 1
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customer_name' },
    { text: 'LDC #', align: 'left', sortable: true, value: 'ldc_account_num', exportType: 'text' },
    { text: 'Billing Pkg', align: 'left', sortable: true, value: 'billing_token', exportType: 'text' },
    { text: 'Statement Date', align: 'left', sortable: true, value: 'stmt_date', exportType: 'date' },
    { text: 'Due Date', align: 'left', sortable: true, value: 'delinquent_date', exportType: 'date' },

    { text: 'Charges', align: 'right', sortable: true, value: 'charges', exportType: 'money' },
    { text: 'Taxes', align: 'right', sortable: true, value: 'taxes', exportType: 'money' },
    { text: 'Credits', align: 'right', sortable: true, value: 'credits', exportType: 'money' },
    { text: 'Existing Payments', align: 'right', sortable: true, value: 'payments', exportType: 'money' },
    { text: 'Open Balance', align: 'right', sortable: true, value: 'open_balance', exportType: 'money' }
  ]

  private resultsSearch = ''
  private statements: Array<IStringIndexed> = []

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true

      this.statements = []
      this.statements = await p2cApi.loadStatements(this.from, this.to)
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private exportList () {
    dtExporter(this.headers, this.statements, 'Statement Export', 'P2C-StatementList-' + moment().format('YYYYMMDD-HHmm'))
  }

  private get statementList () {
    return this.statements.filter(i => {
      if (this.onlyOpenBalances && parseFloat(i.open_balance) <= 0) {
        return false
      }
      return true
    })
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
