


























































import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import Customer from '@/modules/customers/models/customer'
import { IStringIndexed } from '@/modules/shared/types'
import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'
import BankAccountGeneralFormFields from '@/modules/customers/components/forms/bankaccount/Fields.vue'
import BankAccountBankFormFields from '@/modules/customers/components/forms/bankaccount/Bank.vue'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import { BankAccountTypeEnum } from '@/modules/shared/enums'

@Component({
  components: {
    'banking-general-fields': BankAccountGeneralFormFields,
    'banking-bank-fields': BankAccountBankFormFields
  }
})
export default class CSQPaymentInfoStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  private noErrors: boolean = true
  private errorMessage = ''

  private selectedAccount: BankAccount|null = null
  private newId = -2

  private validations: IStringIndexed = {
    accountType: {
      label: 'Account Type',
      rules: [Rules.required()]
    },
    bankName: {
      label: 'Bank Name',
      rules: [Rules.required()]
    },
    accountHolder: {
      label: 'Account Holder',
      rules: [Rules.required()]
    },
    accountNumber: {
      label: 'Account #'
    },
    routingNumber: {
      label: 'Routing #'
    }
  }

  private handleAddBankAccount () {
    this.localItem.customerShell!.bankaccounts.push(new BankAccount({ id: this.newId--, type: BankAccountTypeEnum.CHECKING, accountHolder: this.localItem.customerShell?.businessName }))
    Vue.set(this, 'selectedAccount', this.localItem.customerShell!.bankaccounts[this.localItem.customerShell!.bankaccounts.length - 1])
  }

  private handleRemoveBankAccount (id: number) {
    const idx = this.localItem.customerShell!.bankaccounts.findIndex(ba => ba.id === id)
    if (idx !== -1) {
      this.localItem.customerShell!.bankaccounts.splice(idx, 1)
    }
    if (id === this.selectedAccount?.id) {
      Vue.set(this, 'selectedAccount', null)
    }
  }

  private handleEditBankAccount (id: number) {
    Vue.set(this, 'selectedAccount', this.localItem.customerShell?.bankaccounts.find(ba => ba.id === id))
  }

  @Watch('selectedAccount', { deep: true, immediate: true })
  private handleBankAccountSelect () {
    Vue.nextTick()
      .then(() => {
        if (this.$refs.bankaccountForm) {
          (this.$refs.bankaccountForm as Vue & { validate: () => boolean }).validate()
        }
      })
  }

  private get formValidations () {
    const rules: IStringIndexed = {}
    for (const p in this.validations) {
      if (this.validations[p].rules) {
        rules[p] = this.validations[p].rules
      }
    }
    return rules
  }

  private bankAccountIsValid (account: BankAccount) {
    for (const p in this.validations) {
      if (this.validations[p].rules) {
        const val = account[p]

        for (const r of this.validations[p].rules) {
          if (r(val) !== true) {
            return this.validations[p].label + ': ' + r(val)
          }
        }
      }
    }
    return true
  }

  private getBankAccountClasses (account: BankAccount) {
    let classes = ''

    if (this.selectedAccount?.id === account.id) {
      classes += ' primary lighten-4'
    }

    if (this.bankAccountIsValid(account) !== true) {
      if (!classes.length) {
        classes += ' red lighten-3'
      } else {
        classes += ' red--text'
      }
    }

    classes += ' clickable'

    return classes
  }

  public get stepValid () {
    this.errorMessage = ''

    for (const b of this.localItem.customerShell!.bankaccounts) {
      const isValid = this.bankAccountIsValid(b)

      if (isValid !== true) {
        this.errorMessage = b.bankName + ' has Errors - ' + isValid
        Vue.set(this, 'selectedAccount', b)
        return false
      }
    }

    return true
  }

  private mounted () {
  }
}
