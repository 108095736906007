var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.invoiceHeaders,"items":Object.values(_vm.invoice.invoices),"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"sort-by":"accountNumber","show-expand":true,"expanded":_vm.selectedDetail,"item-class":_vm.rowClasses,"loading-text":"Loading...please wait"},scopedSlots:_vm._u([{key:"item.accountNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.account ? item.account.accountNumber : 'Loading...')+" ")]}},{key:"item.billMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.billMethodLabel)+" ")]}},{key:"item.waiveLateFees",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.waiveLateFees ? 'Y' : 'N')+" ")]}},{key:"item.netConsumption",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US').format(item.netConsumption))+" ")]}},{key:"item.commodityTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.commodityTotal))+" ")]}},{key:"item.otherTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.otherTotal))+" ")]}},{key:"item.taxTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.taxTotal))+" ")]}},{key:"item.invoiceTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.invoiceTotal))+" ")]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.commodityTotal / item.netConsumption).toFixed(6))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditQueueItem)?[(_vm.processingAction(item) !== 'rebill')?[_c('v-btn',{attrs:{"disabled":_vm.processingAction(item) !== false || _vm.invoice.p2cId,"icon":"","title":"Rebill Read"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRebillRead(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.reversepmt")])],1)]:_c('v-progress-circular',{attrs:{"indeterminate":true}}),(_vm.canRemoveInvoice(item))?[(_vm.processingAction(item) !== 'remove')?[_c('v-btn',{attrs:{"disabled":_vm.processingAction(item) !== false || _vm.invoice.p2cId,"icon":"","title":"Remove from Summary Invoice"},on:{"click":function($event){$event.stopPropagation();return _vm.handleRemoveInvoice(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.void")])],1)]:_c('v-progress-circular',{attrs:{"indeterminate":true}})]:_vm._e(),(_vm.processingAction(item) !== 'void')?[_c('v-btn',{attrs:{"disabled":_vm.processingAction(item) !== false || _vm.invoice.p2cId,"icon":"","title":"Void and Remove Invoice"},on:{"click":function($event){$event.stopPropagation();return _vm.handleVoidCharge(item)}}},[_c('v-icon',[_vm._v("$vuetify.icons.delete")])],1)]:_c('v-progress-circular',{attrs:{"indeterminate":true}})]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"20"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11","lg":"8","xl":"8"}},[_c('line-items',{attrs:{"invoice":item,"selectedItemId":_vm.selectedCharge ? _vm.selectedCharge.id : 0},on:{"item:select":_vm.handleSelectCharge}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"4"}},[(_vm.selectedCharge)?_c('charge-details',{attrs:{"lineItem":_vm.selectedCharge}}):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }