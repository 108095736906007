


































































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import moment from 'moment'
import Rules from '@/plugins/validations'
import SettlementFilesApi from '@/modules/wholesale/api/settlementfiles'
import { File } from '../../../shared/models/file'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import SettlementFile from '../../models/settlement'

@Component
export default class SettlementFiles extends Mixins(DownloadFile) {
  private errorMessage = ''

  private from: string = moment().subtract('30', 'd').format('YYYY-MM-DD')
  private to: string = moment().format('YYYY-MM-DD')
  private reportGroup: string = 'All Files'
  private isoAccount = 'Actual'

  private filters = []
  private hasLoaded = false
  private loading: boolean = false
  private dailyResults: Array<SettlementFile> = []

  private selectedTypes: Array<string> = []
  private selectAll = 1

  private valRules: Rules = Rules

  private menu = {
    from: false,
    to: false
  }

  private headers: Array<any> = [
    { text: 'Date', align: 'center', sortable: true, value: 'date' },
    { text: 'Customer', align: 'left', sortable: true, filterable: false, value: 'customer' },
    { text: 'Name', align: 'left', sortable: true, groupable: false, value: 'name' },
    { text: 'Type', sortable: true, value: 'type' },
    { text: 'Version Date', sortable: true, groupable: false, value: 'version' },
    { text: 'Settlement', sortable: true, groupable: false, value: 'settlementPeriod' },
    { text: 'Actions', align: 'center', sortable: false, filterable: false, groupable: false, value: 'action' }
  ]

  private isoAccounts = [
    { text: 'Actual', value: 'Actual' },
    { text: 'Truelight', value: 'Truelight' },
    { text: 'All', value: '' }
  ]

  private reportGroups = [
    'Finance Custom',
    'All Files',
    { value: 'AD', text: '(AD) Adjustment Detail' },
    { value: 'BL', text: '(BL) Bill Detail' },
    { value: 'FA', text: '(FA) Financial Assurance' },
    { value: 'FI', text: '(FI) Forecast Information' },
    { value: 'OI', text: '(OI) Operation Information' },
    { value: 'SD', text: '(SD) Settlement Details' },
    { value: 'SP', text: '(SP) Special' },
    { value: 'SR', text: '(SR) Settlement Report' },
    { value: 'SS', text: '(SS) Standard Settlement' },
    { value: 'TR', text: '(TR) Tariff Report' },
    { value: 'WW', text: '(WW) World Wide Web' }
  ]

  private get types () {
    return this.dailyResults.map(f => f.type).filter((t, i, s) => s.indexOf(t) === i)
  }

  private get filteredFiles () {
    if (!this.selectedTypes.length) {
      return this.dailyResults
    }
    const selected = this.types.filter((t, i) => this.selectedTypes.indexOf(t) !== -1)
    return this.dailyResults.filter(f => selected.indexOf(f.type) !== -1)
  }

  private get allItemsSelected () {
    if (!this.selectedTypes.length) {
      return false
    }

    return this.selectedTypes.length !== this.types.length
  }

  public mounted () {

  }

  private handleSelectAll () {
    if (this.selectAll) {
      Vue.set(this, 'selectedTypes', this.types)
    } else {
      Vue.set(this, 'selectedTypes', [])
    }
  }

  public async onSubmit () {
    this.errorMessage = ''

    if (moment(this.to).isBefore(this.from)) {
      const temp = this.from
      this.from = this.to
      this.to = temp
    }

    if (!moment(this.from).isAfter('2020-01-01')) {
      this.errorMessage = 'Actual Energy has no data prior to 2020'
    }

    if (this.errorMessage.length) {
      return
    }

    this.hasLoaded = true
    this.loading = true
    Vue.set(this, 'selectedTypes', [])
    Vue.set(this, 'dailyResults', [])

    const filters = {
      from: this.from,
      to: this.to,
      customerId: this.isoAccount,
      reportGroup: this.reportGroup
    }

    try {
      this.dailyResults = await SettlementFilesApi.list(filters)
      Vue.set(this, 'selectedTypes', this.types)
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  public async getFile (id: number) {
    this.errorMessage = ''
    try {
      const file = await SettlementFilesApi.get(id)
      this.downloadFile(file.name, file.binaryContents, 'application/pdf')
    } catch (err) {
      this.errorMessage = err.message
    }
  }

  @Watch('selectedTypes', { immediate: true, deep: true })
  private maintainSelectAll () {
    if (this.selectedTypes.length === this.types.length) {
      this.selectAll = 1
    } else {
      this.selectAll = 0
    }
  }
}
