

























import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'

@Component
export default class BankAccountGeneralFormFields extends Vue {
  @PropSync('account', {
    required: true,
    default: () => new BankAccount()
  })
  private localBankAccount!: BankAccount

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  private accountTypes = mapObjectVuetifySelect(BankAccount.accountTypes, true)

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      accountType: [Rules.required()],
      bankName: [Rules.required()],
      accountHolder: [Rules.required()]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public mounted () {

  }
}
