import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { AuthState } from '@/modules/auth/store'
import { validateJsonToken } from '@/api/app'

const abClient = axios.create({
  baseURL: process.env.VUE_APP_ACCOUNTBROKER_REST_URL
})

abClient.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const now = new Date()

  if (!AuthState.services.accountbroker.token?.length || !validateJsonToken(AuthState.services.accountbroker.refreshToken)) {
    await AuthState.ServiceLogin('accountbroker')
  }

  if ((<Date>AuthState.services.accountbroker?.logoutAt).getTime() < (now.getTime() - 3)) {
    await AuthState.RefreshServiceLogin('accountbroker')
  }

  config.headers.Authorization = 'Bearer ' + AuthState.services.accountbroker.token
  return config
})

export default abClient
