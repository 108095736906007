






import { Component, Vue, Prop } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import NotFound from '@/components/NotFound.vue'
import Forbidden from '@/components/Forbidden.vue'
import { IStringIndexed } from '@/modules/shared/types'

@Component
export default class Reports extends Vue {
  private errorMessage = ''

  @Prop({ required: true })
  private reports!: Array<{ name: string, permission: string, component: Vue }>

  @Prop({ required: true })
  private reportName!: string

  private get report () {
    return this.reports.find(r => r.name === this.reportName)
  }

  private get currentReportComponent () {
    if (!this.report) {
      return NotFound
    } else if (AuthState.user.isAllowed(this.report.permission)) {
      return this.report.component
    } else {
      return Forbidden
    }
  }

  private itemViewable (item: IStringIndexed) : boolean {
    return AuthState.user.isAllowed(item.permission)
  }
}
