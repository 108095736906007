















import { Component, Vue } from 'vue-property-decorator'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import { Contact } from '@/modules/shared/models/contact'
import CustomerContacts from '@/modules/customers/components/blocks/Contacts.vue'
import BillGroups from '@/modules/customers/components/blocks/BillGroups.vue'
import EventBus from '@/plugins/eventbus'

@Component({
  components: {
    'customer-contacts': CustomerContacts,
    billgroups: BillGroups
  }
})
export default class CustomerCommunication extends Vue {
  private get canViewContacts () {
    return AuthState.user.isAllowed('CUSTOMER.CONTACTS.VIEW')
  }

  private get canViewBillGroups () {
    return AuthState.user.isAllowed('CUSTOMER.BILLGROUPS.VIEW')
  }

  public get customer () {
    return CustomerState.customer
  }

  public get loading () {
    return CustomerState.loading
  }

  private handleNewContact () {
    EventBus.$emit('open-app-dialog', { component: 'edit-customer-contact', componentProps: { contact: new Contact() } })
  }

  public mounted () {
  }
}
