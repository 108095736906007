import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'

export interface IDocument extends IStringIndexed {
  id: number;
  name: string;
  slug: string;
  description: string;
}

export default class Document extends Base implements IDocument {
  public id = 0
  public name = ''
  public description = ''
  public slug = ''

  constructor (props: Partial<IDocument> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
