













import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class NamebarBase extends Vue {
  protected pageIcon = ''
  protected pageTitle = ''
  protected actionIcon = ''
  protected actionTitle = ''
}
