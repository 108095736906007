


































import { Component, Vue } from 'vue-property-decorator'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import Rules from '@/plugins/validations'

@Component
export default class ImpersonateForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true

  private users = mapObjectVuetifySelect(AppState.cisUserNames, true)
  private userId = 0
  private userSearch = ''

  public handleSubmit (): void {
    if (this.saving) {
      return
    }

    if (!(this.$refs.impersonateForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    AuthState.Impersonate(this.userId)
      .then(() => {
        this.handleClose()
      })
      .catch((err: string) => {
        this.errorMessage = err
      })
      .finally(() => {
        this.saving = false
      })
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.userId = 0
  }

  public clearUserSearch () {
    this.$nextTick(() => {
      this.userSearch = ''
    })
  }

  public beforeMount () {
    if (!AuthState.user.isAdmin) {
      this.handleClose()
    }
  }
}
