import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import { AxiosResponse } from 'axios'
import { IStringDictionary } from '@/modules/shared/types'
import { File as FileModel } from '@/modules/shared/models/file'

const baseUrl = 'lmps'

export default class LmpsApi {
  public static async fetch (filters?: IStringDictionary<any>): Promise<FileModel> {
    try {
      const response: AxiosResponse = await esalesClient.get(baseUrl + filtersToQs(filters))
      if (response.status === 200) {
        return new FileModel(response.data)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}
