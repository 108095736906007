import esalesClient, { checkMessageOrThrow, filtersToQs } from '@/api/clients/esales'
import ModelApi, { RequestParam } from '@/api/model'
import crmClient from '@/api/clients/crm'
import { AxiosResponse, AxiosInstance } from 'axios'
import { IStringDictionary, IStringIndexed, INumericDictionary } from '@/modules/shared/types'
import SalesUserModel from '@/modules/sales/models/salesuser'
import TaxIdModel from '@/modules/sales/models/salesuser/taxid'
import AgreementModel from '@/modules/sales/models/salesuser/agreement'
import NoteModel from '@/modules/sales/models/salesuser/note'
import FileModel from '@/modules/sales/models/salesuser/file'
import { SalesState } from '../store'

export class SalesUsersApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['sales-users']
    this.singular = 'salesuser'
    this.plural = 'salesusers'
    this.factory = (...args: any) => new SalesUserModel(...args)
  }

  public async loadAll (filters?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<INumericDictionary<SalesUserModel>> {
    const list = await this.loadAllSpecific([filters], filters) as Array<SalesUserModel>

    return list.reduce((dict: INumericDictionary<SalesUserModel>, model) => { dict[model.id] = model; return dict }, {} as SalesUserModel)
  }

  public async load (id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<SalesUserModel> {
    return await this.loadSpecific([id, onlyProps, extraProps], onlyProps, extraProps) as SalesUserModel
  }

  public async save (salesPerson: SalesUserModel) : Promise<SalesUserModel> {
    return await this.saveSpecific([salesPerson], salesPerson) as SalesUserModel
  }

  public async getCrmUser (email: string): Promise<IStringIndexed> {
    try {
      const response: AxiosResponse = await crmClient.get('app-users')
      if (response.status === 200) {
        const u = Object.values(response.data.users as INumericDictionary<IStringIndexed>).find((u: IStringIndexed) => u.emailAddress === email)
        if (u) {
          return u
        }
        return { id: 0 }
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async createCrmUser (user: SalesUserModel): Promise<SalesUserModel> {
    try {
      let bossId = null
      if (SalesState.salesUsers[user.reportsTo || -1] && SalesState.salesUsers[user.reportsTo!].crmId) {
        bossId = SalesState.salesUsers[user.reportsTo!].crmId
      }
      const response: AxiosResponse = await crmClient.post('sales-people', {
        companyName: user.companyName,
        firstName: user.firstName,
        lastName: user.lastName,
        emailAddress: user.email,
        userType: user.email.indexOf('actualenergy.com') === -1 ? 4 : 3,
        timezone: 'America/New_York',
        reportsToId: bossId ?? 0,
        primaryState: user.address.state
      })

      if (response.status === 200 && response.data.user) {
        user.crmId = response.data.user.id
        return user
      }
      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }
}

export class SalesUsersAgreementsApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['sales-users', 'agreements']
    this.singular = 'agreement'
    this.plural = 'agreements'
    this.factory = (...args: any) => new AgreementModel(...args)
  }

  public async loadAll (salesId: number, filters?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<AgreementModel>> {
    return await this.loadAllSpecific([salesId, filters], filters) as Array<AgreementModel>
  }

  public async load (salesId: number, id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<AgreementModel> {
    return await this.loadSpecific([salesId, id, onlyProps, extraProps], onlyProps, extraProps) as AgreementModel
  }

  public async save (salesId: number, agreement: AgreementModel, file?: FileModel) : Promise<AgreementModel> {
    const data: IStringIndexed = { ...agreement }
    if (file) {
      data.filename = file.name
      data.contents = btoa(file.contents)
    }

    return await this.saveSpecific([salesId, agreement.id, data], data) as AgreementModel
  }

  public async delete (agreement: AgreementModel) {
    return await this.deleteSpecific([agreement.salesId, agreement.id])
  }
}

export class SalesUsersTaxIdsApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['sales-users', 'taxIds']
    this.singular = 'taxId'
    this.plural = 'taxIds'
    this.factory = (...args: any) => new TaxIdModel(...args)
  }

  public async loadAll (salesId: number, filters?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<TaxIdModel>> {
    return await this.loadAllSpecific([salesId, filters], filters) as Array<TaxIdModel>
  }

  public async load (salesId: number, id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<TaxIdModel> {
    return await this.loadSpecific([salesId, id, onlyProps, extraProps], onlyProps, extraProps) as TaxIdModel
  }

  public async save (salesId: number, taxId: TaxIdModel) : Promise<TaxIdModel> {
    return await this.saveSpecific([salesId, taxId.id], taxId) as TaxIdModel
  }

  public async delete (taxId: TaxIdModel) {
    return await this.deleteSpecific([taxId.salesId, taxId.id])
  }
}

export class SalesUsersNotesApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['sales-users', 'notes']
    this.singular = 'note'
    this.plural = 'notes'
    this.factory = (...args: any) => new NoteModel(...args)
  }

  public async loadAll (salesId: number, filters?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<NoteModel>> {
    return await this.loadAllSpecific([salesId, filters], filters) as Array<NoteModel>
  }

  public async load (salesId: number, id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<NoteModel> {
    return await this.loadSpecific([salesId, id, onlyProps, extraProps], onlyProps, extraProps) as NoteModel
  }

  public async save (salesId: number, note: NoteModel) : Promise<NoteModel> {
    return await this.saveSpecific([salesId, note.id], note) as NoteModel
  }

  public async delete (note: NoteModel) {
    return await this.deleteSpecific([note.salesId, note.id])
  }
}

export class SalesUsersFilesApi extends ModelApi {
  constructor (client: AxiosInstance) {
    super(client)

    this.urlStructure = ['sales-users', 'files']
    this.singular = 'file'
    this.plural = 'files'
    this.factory = (...args: any) => new FileModel(...args)
  }

  public async loadAll (salesId: number, filters?: IStringDictionary<IStringDictionary<string | Array<string|number>>>): Promise<Array<FileModel>> {
    return await this.loadAllSpecific([salesId, filters], filters) as Array<FileModel>
  }

  public async load (salesId: number, id: number, onlyProps?: Array<string>, extraProps?: RequestParam): Promise<FileModel> {
    return await this.loadSpecific([salesId, id, onlyProps, extraProps], onlyProps, extraProps) as FileModel
  }

  public async save (salesId: number, file: FileModel, callback?: Function) : Promise<FileModel> {
    try {
      const data = { ...file }
      data.contents = btoa(file.contents)

      const response: AxiosResponse = await esalesClient.request(
        {
          url: 'sales-users/' + salesId + '/files',
          method: 'POST',
          data: data,
          onUploadProgress: (e) => {
            if (callback) {
              callback(e)
            }
          }
        }
      )

      if (response.status === 200 && response.data.file) {
        response.data.file.contents = ''
        return new FileModel(response.data.file)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      return checkMessageOrThrow(err)
    }
  }

  public async delete (file: FileModel) {
    return await this.deleteSpecific([file.salesId, file.id])
  }
}

export const SalesUserApi = new SalesUsersApi(esalesClient)
export const SalesUsersAgreementApi = new SalesUsersAgreementsApi(esalesClient)
export const SalesUsersTaxIdApi = new SalesUsersTaxIdsApi(esalesClient)
export const SalesUsersNoteApi = new SalesUsersNotesApi(esalesClient)
export const SalesUsersFileApi = new SalesUsersFilesApi(esalesClient)
