












































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { PaymentMethodEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import Rules from '@/plugins/validations'
import { CustomerPayment } from '../../models/customer/payment'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import moment from 'moment'
import Customer from '../../models/customer'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete
  }
})
export default class CustomerPaymentForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true
  private dayMenu = false

  @Prop({ required: true })
  private customer!: Customer

  @Prop({ default: () => new CustomerPayment() })
  private payment!: CustomerPayment

  @Prop()
  private targetInvoiceId!: number|undefined

  private localPayment = new CustomerPayment()

  private states = AppState.dividedStateSelect
  private paymentTypes = mapObjectVuetifySelect(CustomerPayment.paymentTypes, true)
  private paymentMethods = mapObjectVuetifySelect(CustomerPayment.paymentMethods, true)

  public async handleSubmit () {
    if (this.saving || !this.canSave) {
      return
    }

    if (!(this.$refs.paymentForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''

    try {
      await CustomerState.SavePayment({ payment: this.localPayment, targetInvoiceId: this.targetInvoiceId })
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  @Watch('localPayment.type')
  private handleTypeChange () {
    if (this.localPayment.isPayment) {
      this.localPayment.paymentMethodId = 0
    } else if (this.localPayment.isCredit) {
      this.localPayment.paymentMethodId = PaymentMethodEnum.CREDIT
    } else {
      this.localPayment.paymentMethodId = PaymentMethodEnum.WRITEOFF
    }
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localPayment = new CustomerPayment()
  }

  public get contacts () {
    return this.customer.contacts
  }

  public get targetInvoice () {
    if (!this.targetInvoiceId) {
      return undefined
    }
    const inv = this.customer.invoices.find(i => i.id === this.targetInvoiceId)

    return inv
  }

  public get mode () {
    return this.localPayment.id ? 'Edit' : 'Add'
  }

  public get targetName () {
    if (this.targetInvoiceId && this.targetInvoice) {
      return ' - Targeting Invoice ' + this.targetInvoice.invoiceNumber
    }
    return ''
  }

  public get filteredMethods () {
    if (this.localPayment.isPayment) {
      return this.paymentMethods.filter(v => v.value !== PaymentMethodEnum.CREDIT && v.value !== PaymentMethodEnum.WRITEOFF)
    } else if (this.localPayment.isCredit) {
      return this.paymentMethods.filter(v => v.value === PaymentMethodEnum.CREDIT)
    } else {
      return this.paymentMethods.filter(v => v.value === PaymentMethodEnum.WRITEOFF)
    }
  }

  public get billGroups () {
    return this.customer.billGroups.map(g => {
      return {
        text: g.label + ' | ' + g.paymentMethodLabel + ' (' + g.id + ')',
        value: g.id
      }
    })
  }

  public get serviceAccounts () {
    return this.customer.accounts.map(a => {
      return {
        text: a.accountNumber + (a.meterNumber.length ? '-' + a.meterNumber : '') + ' | ' + AppState.utilities[a.utilityId].name + ' (' + a.id + ')',
        value: a.id
      }
    })
  }

  public get dueDayLabel () {
    return moment(this.localPayment.payDate).format('ddd MMM DD, YYYY')
  }

  public get canSave () {
    return AuthState.user.isAllowed('CUSTOMER.PAYMENTS.CREATE')
  }

  public async mounted () {
    this.localPayment = this.payment.clone()

    if (!this.localPayment.payDate) {
      this.localPayment.payDate = moment().format('YYYY-MM-DD')
    }

    if (!this.localPayment.customerId) {
      this.localPayment.customerId = this.customer.id
    }

    if (CustomerState.loading.accounts === null) {
      await CustomerState.LoadAccounts()
        .catch((err) => {
          this.errorMessage = err.message
        })
    }

    if (this.targetInvoice && !this.localPayment.amount) {
      this.localPayment.amount = parseFloat(this.targetInvoice.openBalance.toFixed(2))
      this.localPayment.billGroupId = this.targetInvoice.billGroupId
    }
  }
}
