import { BillMethods, AccountInvoiceStatuses } from '@/modules/shared/lists'
import { BillMethodEnum, AccountInvoiceStatusEnum, ChargesEnum } from '@/modules/shared/enums'
import { Base, IBaseModel } from '@/modules/shared/models/base'
import { InvoiceLineItem } from './invoice/lineitem'
import moment from 'moment'

export interface IAccountInvoice extends IBaseModel {
  id: number;
  accountId: number;
  customerInvoiceId: number;
  status: keyof AccountInvoiceStatuses;
  billMethod: keyof BillMethods;
  commodityTotal: number;
  otherTotal: number;
  taxTotal: number;
  invoiceTotal: number;
  openBalance: number;
  netConsumption: number;
  invoiceDate: string;
  dueDate: string;
  paidDate: string;
  voidDate: string;
  waiveLateFees: number;
  p2cId: string;
}

export class AccountInvoice extends Base {
  public static statuses = new AccountInvoiceStatuses()
  public static billMethods = new BillMethods()

  public id = 0
  public accountId = 0
  public customerInvoiceId = 0
  public status = AccountInvoiceStatusEnum.STATUS_PENDING
  public billMethod = BillMethodEnum.DUAL
  public commodityTotal = 0
  public otherTotal = 0
  public taxTotal = 0
  public invoiceTotal = 0
  public openBalance = 0
  public netConsumption = 0

  public invoiceDate = ''
  public dueDate = ''
  public paidDate = ''
  public voidDate = ''
  public waiveLateFees = 0
  public p2cId = ''

  public lineItems: Array<InvoiceLineItem> = []

  constructor (props?: Partial<IAccountInvoice>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'accountId':
          case 'customerInvoiceId':
          case 'status':
          case 'billMethod':
          case 'waiveLateFees':
            props[p] = parseInt(props[p]!.toString())
            break
          case 'commodityTotal':
          case 'otherTotal':
          case 'taxTotal':
          case 'invoiceTotal':
          case 'openBalance':
          case 'netConsumption':
            props[p] = parseFloat(props[p]!.toString())
            break
          case 'lineItems':
            if (props[p] && (<Array<InvoiceLineItem>>props[p]).length) {
              const objs: Array<InvoiceLineItem> = []
              for (const c of <Array<InvoiceLineItem>>props[p]) {
                objs.push(new InvoiceLineItem(c))
              }

              props[p] = objs
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get isPastDue () {
    return this.openBalance > 0 && moment().hour(0).minute(0).second(0).isBefore(moment(this.dueDate))
  }

  public get billMethodLabel () {
    return AccountInvoice.billMethods[this.billMethod]
  }

  public get isVoid () {
    return this.voidDate?.length > 0
  }

  public get isDisputable () {
    return this.openBalance > 0 && !this.isVoid
  }

  public get statusLabel () {
    return AccountInvoice.statuses[this.status]
  }

  public get chargesDescription () {
    const parent = this.parentLineItem
    if (!parent) {
      return 'Unknown Charge Type'
    }

    let type = parent!.label
    if (![ChargesEnum.SETTLEMENTS, ChargesEnum.ENERGY].includes(parent.chargeId)) {
      return type
    }

    // Get the minimum start and end dates from line item and append them to type
    let minStartDate = moment(this.lineItems[0].startDate).format('M/D/YY')
    let maxEndDate = moment(this.lineItems[0].endDate).format('M/D/YY')
    for (let i = 1; i < this.lineItems.length; i++) {
      const startDate = moment(this.lineItems[i].startDate).format('M/DD/YY')
      const endDate = moment(this.lineItems[i].endDate).format('MM/D/YY')
      if (startDate < minStartDate) {
        minStartDate = startDate
      }
      if (endDate > maxEndDate) {
        maxEndDate = endDate
      }
    }
    type = type + ' (' + minStartDate + ' - ' + maxEndDate + ')'
    return type
  }

  public get parentLineItem () {
    return this.lineItems.find((li) => li.parentLineItemId === null)
  }

  public get invoiceDateMoment () {
    return moment(this.invoiceDate)
  }
}
