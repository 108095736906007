












































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import EventBus from '@/plugins/eventbus'
import { ContractSubmission } from '../../models/queue/contractsubmission'
import { ContractSubmissionsApi } from '../../api/queue/contractsubmissions'
import { IStringIndexed } from '@/modules/shared/types'
import { CreditChecksApi } from '@/modules/finance/api/creditchecks'
import { File } from '@/modules/shared/models/file'
import moment from 'moment'

@Component({
  components: {
  }
})
export default class CSQDocReviewStep extends Vue {
  @PropSync('item', { required: true })
  private localItem!: ContractSubmission

  @Prop({ required: true })
  private files!: Array<IStringIndexed>

  @PropSync('esign', { default: () => { return { package: {}, files: [] } } })
  private esignPackage!: { package: IStringIndexed, files: Array<File> }

  @Prop({ default: false })
  private loading!: boolean

  private docLoading = false
  private esigning = false

  private viewedFile: IStringIndexed|null = null

  private async handleDownloadCrmFile (fileId: number) {
    try {
      if (fileId !== this.viewedFile?.id) {
        this.viewedFile = null
        this.viewedFile = await CreditChecksApi.loadCrmFile(fileId)
        this.viewedFile!.id = fileId
      }
      this.download(this.viewedFile!)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private async handleViewCrmFile (fileId: number) {
    try {
      if (fileId !== this.viewedFile?.id) {
        this.docLoading = true

        this.viewedFile = null
        this.viewedFile = await CreditChecksApi.loadCrmFile(fileId)
        this.viewedFile.id = fileId
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.docLoading = false
    }
  }

  private async handleDownloadEsignFile (file: IStringIndexed) {
    try {
      this.viewedFile = null
      this.viewedFile = file
      this.viewedFile.fileName = file.name

      this.download(this.viewedFile!)
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    }
  }

  private async handleViewEsignFile (file: IStringIndexed) {
    try {
      this.viewedFile = null
      this.viewedFile = file
      this.viewedFile.fileName = file.name
      this.viewedFile.content = file.contents
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.docLoading = false
    }
  }

  private async handleSignPackage () {
    try {
      this.esigning = true

      const pkg = await ContractSubmissionsApi.esignPackage(this.localItem.id, this.esignPackage.package.id)
      Vue.set(this.esignPackage, 'package', pkg)
      this.localItem.customerShell!.contracts[0].dateSigned = moment(pkg.completed).local().format('YYYY-MM-DD')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.esigning = false
    }
  }

  public get stepValid () {
    if (this.localItem.data.esignId) {
      if (!this.esignPackage.files.length) {
        return false
      }
      if (this.esignPackage.files.length && this.esignPackage?.package?.status !== 'COMPLETED') {
        return false
      }
    }
    return true
  }

  public get viewedMimeType () {
    if (!this.viewedFile) {
      return ''
    }
    const ext = this.viewedFile.fileName.split('.').slice(-1)[0]

    switch (ext) {
      case 'pdf':
        return 'application/pdf'
      case 'jpg':
      case 'jpeg':
        return 'image/jpg'
      case 'png':
        return 'image/png'
      case 'gif':
        return 'image/gif'
      default:
        EventBus.$emit('app-snack', {
          message: 'Unable to preview this file, please download it instead',
          actionText: 'Download',
          action: () => this.download(this.viewedFile || {}),
          timeout: 5000
        })
        return ''
    }
  }

  private download (file: IStringIndexed) {
    const byteString = atob(file.content ? file.content : file.contents)
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    const blob = new Blob([ia], { type: file.mimeType })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = file.fileName ? file.fileName : file.name
    link.click()
  }

  private mounted () {
  }
}
