



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractInfo from '@/modules/customers/components/blocks/contract/Info.vue'
import { CustomerState } from '@/modules/customers/store/index'
import { Contract } from '../../models/customer/contract'
import { Route } from '@/../node_modules/vue-router/types'

@Component({
  components: {
    'contract-info': ContractInfo
  }
})
export default class ContractDetail extends Vue {
  private tabs = ''

  @Prop({ required: true })
  protected contractId!: number

  public handleReset (): void {
    this.$emit('dialog:close')
  }

  private get contract () {
    const contract = CustomerState.customer.contracts.find(a => {
      return a.id === this.contractId
    })

    if (!contract) {
      return new Contract({ customerId: CustomerState.customer.id })
    }
    return contract
  }

  private beforeRouteUpdate (to: Route, from: Route, next: Function) {
    if (to.name === 'customercontract') {
      if (to.params.contractId !== from.params.contractId && to.name !== from.name) {
        next({ name: from.name, params: to.params })
      } else {
        next(false)
      }
    } else {
      next()
    }
  }

  private async mounted () {
    if (!this.tabs.length && this.$route.name === 'customercontract') {
      try {
        await this.$router.replace({ name: 'customercontractdetail', params: { contractId: this.contractId.toString() } })
      } catch {}
    }
  }
}
