












import { Component, Vue } from 'vue-property-decorator'
import { CustomerState } from '@/modules/customers/store'
import BankAccounts from '@/modules/customers/components/blocks/BankAccounts.vue'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import EventBus from '@/plugins/eventbus'

@Component({
  components: {
    'bank-accounts': BankAccounts
  }
})
export default class PaymentMethods extends Vue {
  public get customer () {
    return CustomerState.customer
  }

  public get isLoading () {
    return CustomerState.loading.banking
  }

  public mounted () {
    if (CustomerState.loading.banking === null) {
      CustomerState.LoadBankAccounts(true)
    }
  }
}
