










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CommodityEnum, CustomerTypeEnum } from '@/modules/shared/enums'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AppState } from '@/stores/appStore'
import { CustomerState } from '@/modules/customers/store'
import Rules from '@/plugins/validations'
import AccountFactory from '@/modules/shared/models/accountfactory'
import { IAccount } from '@/modules/shared/models/interfaces/account'
import { UtilityFieldConfig, TipConfig } from '@/modules/shared/models/utility/fieldconfig'
import { IStringDictionary, IStringIndexed } from '../../../shared/types'
import ServiceAccountGeneralFormFields from './serviceaccount/General.vue'
import ServiceAccountAddressFormFields from './serviceaccount/Address.vue'
import AccountsApi from '../../api/accounts'
import { CustomerTypes } from '@/modules/shared/lists'

@Component({
  components: {
    'general-fields': ServiceAccountGeneralFormFields,
    'address-fields': ServiceAccountAddressFormFields
  }
})
export default class ServiceAccountForm extends Vue {
  private errorColor = 'error'
  private errorMessage = ''
  private saving = false
  private noErrors = true
  private fieldConfig = UtilityFieldConfig.defaultFields
  private fieldErrors: IStringIndexed = {}
  private tipImages: Array<string> = []
  private tooltipActive = false
  private tooltipImage = ''
  private tooltip: TipConfig = new TipConfig()

  @Prop({ required: true })
  private account!: IAccount

  @Prop({ required: true })
  private customerId!: number

  @Prop({ required: true })
  private customerType!: keyof CustomerTypes

  private localAccount = AccountFactory.getAccount(CommodityEnum.E)

  public async handleSubmit () {
    if (this.saving) {
      return
    }

    this.fieldErrors = []

    if (!(this.$refs.serviceAccountForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    this.saving = true
    this.errorMessage = ''
    try {
      const result: { account: IAccount; warnings: Array<string> } = await CustomerState.SaveAccount({ customerId: this.customerId, customerType: this.customerType, saveAccount: this.localAccount })

      if (!result.warnings.length) {
        this.handleClose()
      } else {
        this.errorColor = 'error'
        this.errorMessage = result.warnings.join('<br />')
      }
      this.$emit('account:updated', result.account)
    } catch (err) {
      this.errorColor = 'error'
      if (err.name === '409') {
        this.errorMessage = 'Account already exists'
        this.$emit('account:confict', { account: this.localAccount, exists: AccountFactory.getAccountFromJson(err.message) })
      } else if (err.name === '422') {
        this.fieldErrors = err.message as unknown as IStringIndexed
        for (const n in err.message as unknown as IStringIndexed) {
          if (!this.$refs[n]) {
            console.log('Error for field: ' + n + ' received but no corresponding field to show it under')
          }
        }
        this.errorMessage = 'Please review the Errors and retry'
      } else {
        this.errorMessage = err.message
      }
    } finally {
      this.saving = false
    }
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  @Watch('localAccount.utilityId', { immediate: true })
  public updateFieldConfig () {
    if (!this.localAccount.utilityId) {
      Vue.set(this, 'fieldConfig', UtilityFieldConfig.defaultFields)
      return
    }

    this.localAccount.address.state = AppState.utilities[this.localAccount.utilityId].state

    const customerType = (CustomerState.customer.type === CustomerTypeEnum.LARGE_COMM || CustomerState.customer.type === CustomerTypeEnum.MED_COMM) ? CustomerTypeEnum.LARGE_COMM : CustomerTypeEnum.RESI
    const key = customerType

    Vue.set(this, 'fieldConfig', AppState.utilities[this.localAccount.utilityId].configs[key].fields)
    Vue.set(this, 'tipImages', AppState.utilities[this.localAccount.utilityId].configs[key].images)
  }

  public get mode () {
    return this.localAccount.id ? 'Edit' : 'Add'
  }

  public get rateClasses () {
    if (!this.localAccount.utilityId) {
      return []
    }

    return mapObjectVuetifySelect(AppState.utilities[this.localAccount.utilityId].rateClasses[this.localAccount.commodity as number])
  }

  public get zones () {
    if (!this.localAccount.utilityId) {
      return []
    }
    return mapObjectVuetifySelect(AppState.utilities[this.localAccount.utilityId].zones[this.localAccount.commodity as number])
  }

  public beforeMount () {
    this.localAccount = this.account.clone()
  }

  public getRules (fields: Array<string>) {
    const rules: IStringDictionary<Array<Function>> = {}

    for (const fieldName of fields) {
      rules[fieldName] = []
      if (!this.fieldConfig[fieldName] || !this.localAccount.utilityId) {
        continue
      }

      if (this.fieldConfig[fieldName].required) {
        rules[fieldName].push(Rules.required())
      }

      if (AppState.utilities[this.localAccount.utilityId].validations[this.localAccount.commodity as number] &&
        AppState.utilities[this.localAccount.utilityId].validations[this.localAccount.commodity as number][fieldName]
      ) {
        AppState.utilities[this.localAccount.utilityId].validations[this.localAccount.commodity as number][fieldName].forEach((r: { regex: string; message: string }) => {
          rules[fieldName].push(Rules.regex(r.regex, r.message))
        })
      }
    }
    return rules
  }

  public tooltipIcon (fieldName: string) {
    if (!this.fieldConfig[fieldName] || !this.fieldConfig[fieldName].tip || !this.localAccount.utilityId) {
      return ''
    }

    return '$vuetify.icons.help'
  }

  public handleTooltip (fieldName: string) {
    if (!this.fieldConfig[fieldName] || !this.fieldConfig[fieldName].tip || !this.localAccount.utilityId) {
      return
    }
    this.tooltipImage = this.tipImages[this.fieldConfig[fieldName].tip.p]
    this.tooltip = this.fieldConfig[fieldName].tip
    this.tooltipActive = true

    // Vue.nextTick not working
    setTimeout(() => {
      if (this.$refs.highlighter) {
        (this.$refs.highlighter as HTMLElement).scrollIntoView({ block: 'center' })
      }
    }, 1000)
  }

  protected scaleVal (val: number) {
    if (this.$vuetify.breakpoint.width < 500) {
      val *= (this.$vuetify.breakpoint.width / 500)
    }
    return val
  }
}
