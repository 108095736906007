import { Base } from '@/modules/shared/models/base'
import { IStringIndexed, IStringDictionary } from '@/modules/shared/types'
import { ListBase } from '@/modules/shared/lists'
import DocumentTemplateRule from './templaterule'

export enum TemplateBuildMethodsEnum {
  ['BUILD_METHOD_HTML'] = 0,
  ['BUILD_METHOD_PDF'] = 1,
  ['BUILD_METHOD_PDF_FILLED'] = 2,
  ['BUILD_METHOD_PHYSICAL_FILE'] = 3,
  ['BUILD_METHOD_FOP'] = 4
 }

export class TemplateBuildMethods extends ListBase {
  readonly [TemplateBuildMethodsEnum.BUILD_METHOD_HTML] = 'HTML'
  readonly [TemplateBuildMethodsEnum.BUILD_METHOD_PDF] = 'PDF'
  readonly [TemplateBuildMethodsEnum.BUILD_METHOD_PDF_FILLED] = 'PDF with Form Fields'
  readonly [TemplateBuildMethodsEnum.BUILD_METHOD_PHYSICAL_FILE] = 'Static File'
  readonly [TemplateBuildMethodsEnum.BUILD_METHOD_FOP] = 'Apache FOP'
}

export interface IDocumentTemplate extends IStringIndexed {
  id: number;
  documentId: number;
  buildMethod: keyof TemplateBuildMethods;
  name: string;
  content: string;
  config: IStringIndexed;
  version: string;
}

export default class DocumentTemplate extends Base implements IDocumentTemplate {
  static buildMethods = new TemplateBuildMethods()

  public id = 0
  public documentId = 0
  public name = ''
  public content = ''
  public config = {}
  public version = ''
  public buildMethod = TemplateBuildMethodsEnum.BUILD_METHOD_HTML
  public rules: Array<DocumentTemplateRule> = []

  constructor (props?: Partial<IDocumentTemplate>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'buildMethod':
            props[p] = parseInt(props[p]! as string)
            break
          case 'rules':
            if (Object.values(props[p]).length) {
              const rules = []
              for (const i of Object.values(props[p])) {
                rules.push(new DocumentTemplateRule(i as Partial<DocumentTemplateRule>))
              }
              props[p] = rules
            }
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }

  public get buildMethodLabel () {
    return DocumentTemplate.buildMethods[this.buildMethod]
  }
}
