



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Contract } from '@/modules/customers/models/customer/contract'
import { mapObjectVuetifySelect } from '@/modules/shared/helpers'
import { AuthState } from '@/modules/auth/store'
import { SalesState } from '@/modules/sales/store'
import { CustomerState } from '@/modules/customers/store'
import FscAutocomplete from '@/modules/shared/components/Autocomplete.vue'
import Rules from '@/plugins/validations'
import ContractGeneralFormFields from './contract/General.vue'
import ContractBillingFormFields from './contract/Billing.vue'

@Component({
  components: {
    'fsc-autocomplete': FscAutocomplete,
    'general-fields': ContractGeneralFormFields,
    'billing-fields': ContractBillingFormFields
  },
  directives: {
  }
})
export default class ContractForm extends Vue {
  private errorMessage = ''
  private saving = false
  private valRules: Rules = Rules
  private noErrors = true

  protected maxCommissionSlots = Contract.MAX_COMMISSIONS_SLOTS
  protected commissionableSales = mapObjectVuetifySelect(SalesState.salesNames, true)

  @Prop({ default: () => new Contract() })
  private contract!: Contract

  private contracts = CustomerState.customer.contracts

  private localContract = new Contract()

  @Watch('contracts', { immediate: true, deep: true })
  private contractUpdated (newContracts: Array<Contract>, oldContracts: Array<Contract>) {
    if (!newContracts) {
      return
    }
    const match = newContracts.find(n => n.id === this.localContract.id)

    if (match) {
      if (match.updatedAt !== this.localContract.updatedAt) {
        this.errorMessage = 'WARNING: This contract has been updated by someone else. And you may overwrite the changes'
      }
    }
  }

  public async handleSubmit () {
    if (this.saving || !this.canSave) {
      return
    }

    if (!(this.$refs.contractForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    for (const c of this.localContract.commissions) {
      c.effectiveFrom = this.localContract.dateSigned
    }

    this.saving = true
    this.errorMessage = ''

    try {
      const contract: Contract = await CustomerState.SaveContract(this.localContract)
      this.handleClose()
    } catch (err) {
      this.errorMessage = err
    } finally {
      this.saving = false
    }
  }

  public handleClose () {
    this.$emit('dialog:close')
  }

  public handleReset (): void {
    this.localContract = new Contract()
  }

  public get mode () {
    return this.localContract.id ? 'Edit' : 'Add'
  }

  public get canSave () {
    if (this.localContract.id) {
      return AuthState.user.isAllowed('CUSTOMER.CONTRACTS.EDIT')
    }
    return AuthState.user.isAllowed('CUSTOMER.CONTRACTS.CREATE')
  }

  public get canEditCommissions () {
    if (this.localContract.id) {
      return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.EDIT')
    }
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.CREATE')
  }

  public get canViewCommissions () {
    return AuthState.user.isAllowed('SALES.USERS.COMMISSIONS.VIEW')
  }

  public beforeMount () {
    this.localContract = this.contract.clone()

    if (!this.localContract.customerId) {
      this.localContract.customerId = CustomerState.customer.id
    }
  }
}
