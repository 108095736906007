



























import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { IStringIndexed } from '@/modules/shared/types'
import Rules from '@/plugins/validations'
import { BankAccount } from '@/modules/customers/models/customer/bankaccount'
import { mask } from 'vue-the-mask'

@Component({
  directives: {
    mask
  }
})
export default class BankAccountBankFormFields extends Vue {
  @PropSync('account', {
    required: true,
    default: () => new BankAccount()
  })
  private localBankAccount!: BankAccount

  @Prop({ default: () => { return null } })
  private validations!: IStringIndexed|null

  private accountNumberVerify = ''

  private get fieldRules () : IStringIndexed {
    const rules: IStringIndexed = {
      bankName: [Rules.required()],
      routingNumber: !this.localBankAccount.routingNumber.length || this.localBankAccount.routingNumber[0] !== 'X' ? [Rules.required(), Rules.minDigits(9), Rules.maxDigits(9), BankAccountBankFormFields.validateRouting()] : [Rules.required(), Rules.regex(/^X{5}\d{4}/, 'Please re-enter the full routing number, or cancel your changes')],
      accountNumber: !this.localBankAccount.routingNumber.length || this.localBankAccount.routingNumber[0] !== 'X' ? [Rules.required()] : [],
      accountNumberVerify: [Rules.requiredIf(this.localBankAccount.accountNumber), Rules.match(this.localBankAccount.accountNumber, 'Account Numbers must match')]
    }

    if (this.validations !== null) {
      for (const p in this.validations) {
        rules[p] = this.validations[p]
      }
    }

    return rules
  }

  public static validateRouting (msg = ''): (v: string) => string|boolean {
    if (!msg.length) {
      msg = 'Invalid Routing #'
    }

    return (v: string) => {
      if (typeof v !== 'string') {
        v = (v as any).toString()
      }

      let temp = 0
      let index = 0
      for (let i = 0; i < 3; i++) {
        index = 3 * i
        temp += 3 * parseInt(v[index]) +
                  7 * parseInt(v[index + 1]) +
                    parseInt(v[index + 2])
      }

      if ((temp % 10) !== 0) {
        return msg
      }

      return true
    }
  }

  public mounted () {

  }
}
