import { ChargeCategories } from '@/modules/shared/lists'
import { ChargeCategoryEnum } from '@/modules/shared/enums'
import { Base, IBaseModel } from '@/modules/shared/models/base'

export interface ILineItemChargeDetail extends IBaseModel {
  id: number;
  lineItemId: number|null;
  glId: number;
  quantity: number;
  rate: number;
  total: number;
  description: string;
  p2cId: string;
}

export class LineItemChargeDetail extends Base {
  public id = 0
  public lineItemId = null
  public glId = 0
  public quantity = 0
  public rate = 0
  public total = 0
  public description = ''
  public p2cId = ''

  constructor (props?: Partial<ILineItemChargeDetail>) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'id':
          case 'lineItemId':
          case 'glId':
            if (props[p]) {
              props[p] = parseInt(props[p]!.toString())
            }
            break
          case 'quantity':
          case 'rate':
          case 'total':
            props[p] = parseFloat(props[p]!.toString())
            break
          default:
        }
      }

      Object.assign(this, props)
    }
  }
}
