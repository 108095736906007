var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Contacts "),_c('v-spacer'),(_vm.canAddContact)?_c('v-icon',{attrs:{"title":"Add Contact","color":"primary"},on:{"click":function($event){return _vm.handleEditContact(_vm.localItem)}}},[_vm._v(" $vuetify.icons.userplus ")]):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"row-navigation",attrs:{"headers":_vm.getHeaders,"items":_vm.contacts,"dense":this.$vuetify.breakpoint.mdAndDown,"mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds.sm,"sort-by":"name","loading":_vm.loading,"loading-text":"Loading...please wait"},on:{"click:row":_vm.handleEditContact},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullName)+" ")]}},{key:"item.phones",fn:function(ref){
var item = ref.item;
return _vm._l((item.fullPhoneNumbers),function(n,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(n)+" ")])})}},{key:"item.emails",fn:function(ref){
var item = ref.item;
return _vm._l((item.emailAddresses),function(e,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(e)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canDeleteContact)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$vuetify.icons.delete")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }