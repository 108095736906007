import { ListsEnum } from '@/modules/shared/enums'
import { IFile, File } from '@/modules/shared/models/file'
import { AppState } from '@/stores/appStore'
export interface ISalesUserFile extends IFile {
  salesId: number;
}

export default class SalesUserFile extends File implements ISalesUserFile {
  public salesId = 0

  constructor (props: Partial<ISalesUserFile> = {}) {
    super(props)

    if (props) {
      for (const p in props) {
        switch (p) {
          case 'salesId':
            this[p] = parseInt(props[p]?.toString() as string)
            break
          default:
        }
      }
    }
  }

  public get categories () {
    return AppState.listsByName[ListsEnum.SALES_FILE_CATEGORIES]
  }
}
