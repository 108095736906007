import ManagementRoot from '../pages/Management.vue'
import Fulfillment from '../pages/subpages/Fulfillment.vue'
import DocManager from '../pages/subpages/DocumentManager.vue'
import ContractSubmissionQueue from '../pages/subpages/ContractSubmissionQueue.vue'
import CSQItem from '../components/CSQItem.vue'
import ViewDocument from '../pages/subpages/Document.vue'
import WelcomeLettersQueue from '../pages/subpages/queues/WelcomeLetters.vue'
import ManagementSideNav from '../components/sidenavs/Root.vue'
import Search from '@/modules/search/pages/Search.vue'
import SearchSideNav from '@/modules/search/components/SideNav.vue'
import SearchBottomNav from '@/modules/search/components/BottomNav.vue'
import { Route } from 'vue-router'
import { WelcomeLetterQueueStatusEnum } from '@/modules/shared/enums'

const rootNav = {
  key: 'root'
}

const fulfillmentNav = {
  key: 'fulfillment',
  width: 250
}

const outboundNav = {
  key: 'outbound',
  width: 250
}
const welcomeLettersNav = {
  key: 'welcomeletterqueue',
  width: 250
}

export default [
  {
    name: 'customermanagementroot',
    path: '/customermanagement',
    components: {
      SideNav: ManagementSideNav,
      default: ManagementRoot
    },
    title: 'Customer Management',
    meta: {
      requiresAuth: 'CUSTOMER_MGMT.ACCESS',
      navs: {
        fulfillment: {
          icon: 'up',
          title: 'Back'
        }
      }
    },
    props: {
      nav: rootNav
    }
  },
  {
    name: 'customersearch',
    path: '/search',
    components: {
      SideNav: SearchSideNav,
      BottomNav: SearchBottomNav,
      default: Search
    },
    title: 'Search',
    meta: {
      requiresAuth: true,
      navs: {
        root: {
          icon: 'search',
          order: 0
        }
      }
    }
  },
  {
    name: 'customerfulfillment',
    path: '/customermanagement/fulfillment',
    components: {
      SideNav: ManagementSideNav,
      default: Fulfillment
    },
    title: 'Fulfillment',
    meta: {
      requiresAuth: 'CUSTOMER_MGMT.ACCESS',
      navs: {
        root: {
          icon: 'fulfillmentqueue'
        },
        outbound: {
          icon: 'up',
          title: 'Back'
        }
      }
    },
    props: {
      nav: fulfillmentNav
    }
  },
  {
    name: 'fulfillmentqueues',
    path: '/customermanagement/fulfillment/outbound',
    components: {
      SideNav: ManagementSideNav,
      default: Fulfillment
    },
    title: 'Customer Fulfillment - Queues',
    meta: {
      requiresAuth: 'CUSTOMER_MGMT.FULFILLMENT.QUEUES.VIEW',
      navs: {
        fulfillment: {
          title: 'Outbound Queues',
          icon: 'fulfillmentqueue'
        },
        welcomeletterqueue: {
          icon: 'up',
          title: 'Back'
        }
      }
    },
    props: {
      nav: outboundNav
    }
  },
  {
    name: 'welcomeletterqueue',
    path: '/customermanagement/fulfillment/outbound/welcomeletter/:status(\\d+)?',
    components: {
      SideNav: ManagementSideNav,
      default: WelcomeLettersQueue
    },
    title: 'Customer Fulfillment - Welcome Letter Queue',
    meta: {
      requiresAuth: 'CUSTOMER_MGMT.FULFILLMENT.QUEUES.VIEW',
      navs: {
        outbound: {
          title: 'Welcome Letters',
          icon: 'fulfillmentqueue'
        }
      }
    },
    props: {
      default: (route: Route) => ({
        openToStatus: route.params.status
      }),
      nav: welcomeLettersNav
    }
  },
  {
    name: 'customerfulfillmentdocument',
    path: '/customermanagement/fulfillment/documents',
    components: {
      SideNav: ManagementSideNav,
      default: DocManager
    },
    title: 'Customer Fulfillment - Document Manager',
    meta: {
      requiresAuth: 'CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.VIEW',
      navs: {
        fulfillment: {
          title: 'Manage Documents',
          icon: 'docmanager'
        }
      }
    },
    props: {
      nav: fulfillmentNav
    }
  },
  {
    name: 'managedocument',
    path: '/customermanagement/fulfillment/documents/manage/:documentId(\\d+)',
    components: {
      default: ViewDocument
    },
    title: 'Customer Fulfillment - Document',
    meta: {
      requiresAuth: 'CUSTOMER_MGMT.FULFILLMENT.DOCUMENT.VIEW',
      navs: {
      }
    },
    props: {
      default: (route: Route) => ({
        documentId: parseInt(route.params.documentId),
        document: route.params.document
      })
    }
  },
  {
    name: 'contractsubmissionqueue',
    path: '/customermanagement/contract-submission-queue',
    components: {
      default: ContractSubmissionQueue
    },
    title: 'CSQ',
    meta: {
      requiresAuth: 'CUSTOMER_MGMT.QUEUES.CSQ.VIEW',
      navs: {
        root: {
          icon: 'queue'
        }
      }
    },
    props: {
      default: (route: Route) => ({
        itemId: parseInt(route.params.itemId) || 0
      })
    },
    children: [
      {
        name: 'csqitem',
        path: ':itemId(\\d+)',
        components: {
          default: CSQItem
        },
        meta: {
        },
        props: { }
      }
    ]
  }
]
