



































































import { Component, Vue } from 'vue-property-decorator'
import Rules from '@/plugins/validations'
import moment from 'moment'
import { IStringIndexed } from '@/modules/shared/types'
import dtExporter from '@/modules/shared/datatablehelper'
import FinanceReportsApi from '@/modules/finance/api/reports'

@Component({
  components: {}
})
export default class PaymentsReportParametersForm extends Vue {
  private errorMessage = ''
  private valRules: Rules = Rules
  private noErrors = true
  private showAllFields = false

  private menu = {
    from: false,
    to: false
  }

  private from = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
  private to = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
  private loading = false

  private headers: Array<IStringIndexed> = [
    { text: 'Customer Name', align: 'left', sortable: true, value: 'customerName' },
    { text: 'Territory Code', align: 'left', sortable: true, value: 'territoryCode', exportType: 'text' },
    { text: 'Invoice #', align: 'left', sortable: true, value: 'invoiceNumber', exportType: 'text' },
    { text: 'LDC #', align: 'left', sortable: true, value: 'ldcAccountNumber', exportType: 'text' },
    { text: 'Amount', align: 'right', sortable: true, value: 'amount', exportType: 'money' },
    { text: 'Payment Date', align: 'left', sortable: true, value: 'paymentDate', exportType: 'date' },
    { text: 'Process Date', align: 'left', sortable: true, value: 'processDate', exportType: 'date' },
    { text: 'Payment Type', align: 'left', sortable: true, value: 'paymentType', exportType: 'text' },
    { text: 'Ref #', align: 'left', sortable: true, value: 'refNumber', exportType: 'text' }
  ]

  private resultsSearch = ''
  private payments: Array<IStringIndexed> = []

  private dateLabel (date: string) {
    if (!date.trim()) {
      return ''
    }
    return moment(date).format('ddd MMM DD, YYYY')
  }

  public async handleSearch () {
    this.errorMessage = ''

    if (!(this.$refs.filterForm as Vue & { validate: () => boolean }).validate()) {
      return
    }

    try {
      this.loading = true

      this.payments = []
      this.payments = await FinanceReportsApi.loadPayments(this.from, this.to)
    } catch (err) {
      this.errorMessage = err.message
    } finally {
      this.loading = false
    }
  }

  private exportList () {
    dtExporter(this.headers, this.payments, 'Payment Export', 'CashReceipts-' + moment().format('YYYYMMDD-HHmm'), { bookType: 'xlsx' })
  }

  private get paymentList () {
    return this.payments.filter(i => {
    //   if (this.onlyOpenBalances && parseFloat(i.open_balance) <= 0) {
    //     return false
    //   }
      return true
    })
  }

  private beforeMount () {
    this.errorMessage = ''
  }
}
