













































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import { INumericDictionary, INumericIndexed, IStringIndexed } from '@/modules/shared/types'
import { WelcomeLettersApi } from '@/modules/customermanagement/api/queue/welcomeletters'
import EventBus from '@/plugins/eventbus'
import WelcomeLetter from '@/modules/customermanagement/models/queue/welcomeletter'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'
import moment from 'moment'
import { ICustomerContact } from '@/modules/customers/models/customer/contact'
import { WelcomeLetterQueueStatusEnum } from '@/modules/shared/enums'
import { WelcomeLetterStatuses } from '@/modules/shared/lists'

@Component
export default class WelcomeLettersQueue extends Mixins(DownloadFile) {
  @Prop({ default: 2 })
  private openToStatus!: number

  private selectedLetters: Array<WelcomeLetter> = []
  private sending = false

  private errorMessage = ''

  private headers: Array<IStringIndexed> = [
    { text: 'ID', align: 'center', width: 90, sortable: false, value: 'id' },
    { text: 'Customer', align: 'center', width: 90, sortable: false, value: 'customer.businessName' },
    { text: '# of Accounts', align: 'center', width: 110, sortable: true, value: 'serviceAccounts.length' },
    // { text: 'Send Via', align: 'center', width: 150, sortable: true, value: 'billGroup.deliveryMethodLabel' },
    // { text: 'Recipient(s)', align: 'auto', width: 300, sortable: false, value: 'recipients' },
    { text: 'Contact', align: 'center', width: 90, sortable: false, value: 'contact' },
    { text: 'Preview', align: 'center', width: 90, sortable: false, value: 'actions' }
  ]

  private loading = false

  private items: Array<WelcomeLetter> = []

  private showFilters = true
  private statusLabels = { 0: 'New', 1: 'In Progress', 2: 'Ready', 3: 'Sent', 10: 'Canceled' }
  // could not get chip selection to be int
  private statusFilter: undefined|string = '2'

  private listSearch: string = ''

  private processing: INumericDictionary<string> = {}

  private get filteredItems () {
    return this.items.filter(r => {
      return this.statusFilter === undefined ||
        r.status === parseInt(this.statusFilter)
    })
  }

  public async handleDownloadPdf (invoice: WelcomeLetter) {
    try {
      Vue.set(this.processing, invoice.id, 'download')
      const file = await WelcomeLettersApi.getPdf(invoice)
      this.downloadFile(file.name, file.binaryContents, 'application/pdf')
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      Vue.delete(this.processing, invoice.id)
    }
  }

  public async handleCancel () {
    if (!this.selectedLetters.length) {
      return
    }

    if (!confirm('Are you sure you want to remove these letters from the queue without sending?')) {
      return
    }

    try {
      this.sending = true

      for (const letter of this.selectedLetters) {
        letter.status = WelcomeLetterQueueStatusEnum.CANCELED
        const updated = await WelcomeLettersApi.savePartial(letter, ['status'])

        const idx = this.items.findIndex(c => c.id === updated.id)
        Vue.set(this.items, idx, updated)
      }
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.sending = false
    }
  }

  public async handleSendLetters () {
    if (!this.selectedLetters.length) {
      return
    }

    try {
      this.sending = true

      // @todo: for multi send, group by bg or cust and make 1 post earch

      this.selectedLetters /* .filter(i => i.hasDeliveryDestination)) */
        .forEach(letter => {
          const updateResponse = WelcomeLettersApi.performAction(letter, 'send')

          const idx = this.items.findIndex(c => c.id === letter.id)
          Vue.set(this.items, idx, updateResponse)
        })
    } catch (err) {
      EventBus.$emit('app-snack', {
        message: err
      })
    } finally {
      this.sending = false
    }
  }

  public getPrimary (item: WelcomeLetter) {
    const list: Array<ICustomerContact> = item.customer?.contacts || {}
    const pc = list.find(c => c.type === 175)
    if (pc !== undefined) {
      return pc.primaryEmail
    }
  }

  private handleShowFilters () {
    this.showFilters = !this.showFilters
  }

  private beforeMount () {
    WelcomeLettersApi.loadAll({
      // non-p2c markets, non-sent or canceled unless special..
      // status: {
      //   OR: {
      //     '=': CustomerInvoiceStatusEnum.STATUS_APPROVED.toString(),
      //     AND: {
      //       '=': CustomerInvoiceStatusEnum.STATUS_SENDING.toString(),
      //       '<': {
      //         property: 'updatedAt',
      //         value: moment.utc().subtract(5, 'minutes').format('YYYY-MM-DD HH:mm:ss')
      //       }
      //     }
      //   }
      // }
    })
      .then(resp => {
        this.items = resp
      }).catch(err => (this.errorMessage = err))
  }

  private mounted () {
    this.statusFilter = this.openToStatus.toString()
  }
}
