












































import { Component, Vue, Mixins } from 'vue-property-decorator'
import moment from 'moment'
import Rules from '@/plugins/validations'
import LmpsApi from '@/modules/wholesale/api/lmps'
import DownloadFile from '@/modules/shared/mixins/DownloadFile.vue'

@Component
export default class Lmps extends Mixins(DownloadFile) {
  private errorMessage = ''
  private from: string = moment().subtract('10', 'd').format('YYYY-MM-DD')
  private to: string = moment().format('YYYY-MM-DD')
  private type: string = 'DA'
  private fromMenu = false
  private toMenu = false

  private valRules: Rules = Rules

  public get lmpTypes () {
    return [
      { text: 'Day Ahead', value: 'DA' },
      { text: 'Real Time', value: 'RT' }
    ]
  }

  public mounted () {
    // this.onSubmit(this.$route.params.filters)
  }

  public async onSubmit () {
    this.errorMessage = ''

    if (!moment(this.to).isAfter(this.from)) {
      this.errorMessage = '"To" date must be later than "From" date'
    }

    if (!moment(this.from).isAfter('2020-01-01')) {
      this.errorMessage = 'Actual Energy has no data prior to 2020'
    }

    const filters = {
      from: this.from,
      to: this.to,
      type: this.type
    }

    LmpsApi.fetch(filters)
      .then(file => {
        this.downloadFile(file.name, file.binaryContents, file.mimeType)
      })
      .catch(err => {
        this.errorMessage = err.message
      })
  }
}
