import authClient from './client'
import { AxiosResponse } from 'axios'

const baseUrl = 'user'

export interface IAuthLoginResponse {
  token: string;
  refreshToken: string;
  user: any;
}

export default class AuthApi {
  public static async login (username: string, password: string): Promise<IAuthLoginResponse> {
    try {
      const response = await authClient.post('authentication', { grantType: 'new', authType: 'user', username: username, password: password })

      if (response.status === 200) {
        return response.data as IAuthLoginResponse
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.message) {
          throw new Error(err.response.data.message)
        } else {
          throw new Error('Unknown application error,  unable to login')
        }
      } else {
        throw err
      }
    }
  }

  public static async refresh (token: string): Promise<IAuthLoginResponse> {
    try {
      const response: AxiosResponse = await authClient.post('authentication', { grantType: 'refresh', authType: 'user', assertion: token })
      if (response.status === 200) {
        return (response.data as IAuthLoginResponse)
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      console.log(err) // avoid no-useless-catch lint
      throw err
    }
  }

  public static async impersonate (userId: number): Promise<IAuthLoginResponse> {
    try {
      const response = await authClient.get('impersonate/' + userId)

      if (response.status === 200) {
        return response.data as IAuthLoginResponse
      }

      throw new Error('Unexpected response format')
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.message) {
          throw new Error(err.response.data.message)
        } else {
          throw new Error('Unknown application error,  unable to login')
        }
      } else {
        throw err
      }
    }
  }
}
