import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores'
import './plugins/ej2'

import vuetify from './plugins/vuetify'
import Moment from './plugins/moment'

import './registerServiceWorker'

import NumAbbrev from '@/filters/NumberAbbrev'
import PhoneNum from '@/filters/PhoneNumber'

Vue.config.productionTip = false

if (process.env.VUE_APP_NOSAVES) {
  console.warn('WARNING: API WRITES CURRENTLY DISABLED')
}

Vue.filter('numabbrev', NumAbbrev)
Vue.filter('phone', PhoneNum)

Vue.use(new Moment())

Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  // @ts-ignore
  vuetify,
  // @ts-ignore
  render: h => h(App)
}).$mount('#app')
