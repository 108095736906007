















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AppState } from '@/stores/appStore'
import { AuthState } from '@/modules/auth/store'
import { CustomerState } from '@/modules/customers/store'
import { UserCustomerNavsEnum } from '../../shared/enums'
import EventBus from '@/plugins/eventbus'
import { Route } from 'vue-router'
import { IDialogConfig, IStringIndexed } from '@/modules/shared/types'
import NameBar from '@/modules/customers/components/blocks/NameBar.vue'
import EditCustomerInfo from '@/modules/customers/components/forms/CustomerInfo.vue'
import EditCustomerNote from '@/modules/customers/components/forms/Note.vue'
import EditCustomerContact from '@/modules/customers/components/forms/Contact.vue'
import EditCustomerBillGroup from '@/modules/customers/components/forms/BillGroup.vue'
import EditCustomerBillGroupAccounts from '@/modules/customers/components/forms/billgroup/ServiceAccounts.vue'
import EditCustomerContract from '@/modules/customers/components/forms/Contract.vue'
import EditContractCommissions from '@/modules/customers/components/forms/contract/Commissions.vue'
import UploadCustomerFileForm from '@/modules/customers/components/forms/FileUpload.vue'
import BankAccountForm from '../components/forms/BankAccount.vue'
import CustomerPaymentForm from '../components/forms/Payment.vue'
import BankAccountLog from '@/modules/customers/components/blocks/bankaccounts/ViewLog.vue'
import ServiceAccountForm from '../components/forms/ServiceAccount.vue'

@Component({
  components: {
    'name-bar': NameBar,
    'edit-customer-info': EditCustomerInfo,
    'edit-customer-note': EditCustomerNote,
    'edit-customer-contact': EditCustomerContact,
    'edit-customer-billgroup': EditCustomerBillGroup,
    'edit-customer-billgroup-accounts': EditCustomerBillGroupAccounts,
    'edit-customer-contract': EditCustomerContract,
    'edit-contract-commissions': EditContractCommissions,
    'upload-customer-file': UploadCustomerFileForm,
    'edit-customer-bankaccount': BankAccountForm,
    'add-customer-payment': CustomerPaymentForm,
    'bankaccount-log': BankAccountLog,
    'edit-serviceaccount': ServiceAccountForm
  }
})
export default class Customer extends Vue {
  private dialog = false
  private dialogComponent = ''
  private dialogProps: IStringIndexed = {}
  private componentProps: IStringIndexed = {}
  private dialogWidth = 'unset'

  @Prop({ required: true, type: Number })
  private id!: number

  private handleDialog (dialogConfig: IDialogConfig | string) {
    this.dialogWidth = 'unset'
    if (typeof dialogConfig === 'string') {
      this.dialogComponent = dialogConfig
    } else {
      this.dialogComponent = dialogConfig.component
      this.componentProps = dialogConfig.componentProps || {}
      this.dialogProps = dialogConfig.dialogProps || {}
    }

    this.dialog = true

    Vue.nextTick()
      .then(() => {
        // Can't get this working this.$vuetify.goTo(0, { container: '#appDialog' })
        const dialogElem = document.getElementById('appDialog')
        if (dialogElem) {
          dialogElem.scrollIntoView()
        }
      })
  }

  private handleCloseDialog () {
    this.dialog = false
    this.dialogComponent = ''
    if ((this.$refs.dialogComponent as IStringIndexed).handleReset) {
      (this.$refs.dialogComponent as IStringIndexed).handleReset()
    }
  }

  public mounted () {
    if (AuthState.user.preferences.customer.nav === UserCustomerNavsEnum.TOP_TABS && !AppState.enableTabNav) {
      AppState.ToggleTabNav(true)
    }
  }

  public created () {
    EventBus.$on('open-cust-dialog', this.handleDialog)
  }

  public activated () {
  }

  public updated () {
  }

  public destroyed () {
    AppState.ToggleTabNav(false)
    EventBus.$off('open-cust-dialog', this.handleDialog)
  }

  public deactivated () {

  }

  @Watch('id', { immediate: true })
  public async handleIdChange () {
    if (CustomerState.customer.id !== this.id) {
      try {
        await CustomerState.Load({ id: Number(this.$route.params.id) })
        AppState.AddRecentlyViewed({ id: CustomerState.customer.id, name: CustomerState.customer.businessName })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
